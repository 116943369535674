import {BaseIndexScreen, DeleteModal, Modal} from 'src/design-system';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Searchbar} from 'react-native-paper';
import DocumentForm from 'src/modules/patients/components/document-form';
import Spinner from 'react-native-loading-spinner-overlay';
import FileViewer from 'src/design-system/file-viewer';
import {FlatList, ScrollView, Text, View} from 'react-native';
import {Typography} from 'src/styles';
import DocumentCard from 'src/modules/patients/components/document-card';
import NoResults from 'src/common-components/noResults';
import React, {useMemo, useState} from 'react';
import {useStyle} from 'src/providers/style';
import {useDimensions} from '@react-native-community/hooks';
import {fuzzy} from 'src/common-utils/fuzzy';

const Documents = ({
  accessToken,
  canAdd,
  canEdit,
  canDelete,
  starredDocuments,
  documents,
  uploadFile,
}: any) => {
  const dimensions = useDimensions();
  const styles = useStyle();

  const [searchFilter, setSearchFilter] = useState('');
  const [defaultValues, setDefaultValues] = useState({
    fileName: '',
    file: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState<any | boolean>(false);
  const [showView, setShowView] = useState(false);

  const viewCallback = (document: any) => {
    setShowView(document);
  };
  const editCallback = (document: any) => {
    setShowUpdate(document);
  };
  const deleteCallback = (document: any) => {
    setShowDelete(document);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (document: any) => {
    document.delete();
    setShowDelete(false);
  };

  const handleSubmit = async (values: any) => {
    if (!showUpdate) {
      setIsLoading(true);
      await uploadFile(values);
      setIsLoading(false);
    } else {
      showUpdate.updateEntity({
        fileName: values.fileName,
      });
    }
    handleCancel();
  };

  const handleCancel = () => {
    setShowUpload(false);
    setShowUpdate(false);
    setDefaultValues({
      fileName: '',
      file: null,
    });
  };

  const searchedStarredDocuments = useMemo(() => {
    return starredDocuments?.filter((item: any) => {
      return fuzzy(item?.fileName, searchFilter);
    });
  }, [starredDocuments, searchFilter]);

  const searchedDocuments = useMemo(() => {
    return documents?.filter((item: any) => {
      return fuzzy(item?.fileName, searchFilter);
    });
  }, [documents, searchFilter]);

  const numInRow =
    dimensions.window.width > 1200 ? 6 : dimensions.window.width > 768 ? 4 : 2;

  return (
    <BaseIndexScreen
      title={'Documents & Media'}
      canAdd={canAdd}
      addButton={
        <RHButton
          onPress={() => {
            setDefaultValues({
              fileName: '',
              file: null,
            });
            setShowUpload(true);
          }}
          mode={'contained'}
          secondary
          icon="upload">
          UPLOAD DOCUMENT
        </RHButton>
      }
      search={
        <Searchbar
          value={searchFilter}
          onChangeText={setSearchFilter}
          placeholder={'Search'}
        />
      }>
      <Modal show={[showUpload, setShowUpload]} title={'Upload Document'}>
        <DocumentForm
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          defaultValues={defaultValues}
        />
      </Modal>
      <Modal show={[showUpdate, setShowUpdate]} title={'Rename Document'}>
        <DocumentForm
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          defaultValues={{
            id: showUpdate.id,
            fileName: showUpdate.fileName,
          }}
        />
      </Modal>
      <Spinner visible={isLoading} />
      <DeleteModal
        model={'Document'}
        text={showDelete ? `${showDelete?.fileName}` : null}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      {showView ? (
        <FileViewer
          file={showView}
          show={[showView, setShowView]}
          accessToken={accessToken}
        />
      ) : (
        <></>
      )}
      <ScrollView style={[styles.flex, styles.paddingLTop]}>
        <Text
          style={[
            Typography.H5,
            styles.textColorPrimary,
            styles.marginLBottom,
            styles.paddingHorizontal,
          ]}>
          Starred
        </Text>
        {searchedStarredDocuments.length > 0 ? (
          <FlatList
            key={`starred-document-${numInRow}`}
            data={searchedStarredDocuments}
            renderItem={({item}: any) => (
              <DocumentCard
                show={[showView, setShowView]}
                item={item}
                canAdd={canAdd}
                viewCallback={viewCallback}
                canEdit={canEdit}
                editCallback={editCallback}
                canDelete={canDelete}
                deleteCallback={deleteCallback}
              />
            )}
            keyExtractor={item => `starred-document-${item.id}`}
            numColumns={numInRow}
            style={[styles.cardPaddingHorizontal]}
          />
        ) : (
          <NoResults
            iconName={'star-outline'}
            secondaryMessage={
              'This client does not have any starred documents yet.'
            }
          />
        )}
        <View style={[styles.paddingVertical, styles.paddingHorizontal]}>
          <View style={[styles.separator]} />
        </View>
        <Text
          style={[
            Typography.H5,
            styles.textColorPrimary,
            styles.marginLBottom,
            styles.paddingHorizontal,
          ]}>
          Documents
        </Text>
        {searchedDocuments.length > 0 ? (
          <FlatList
            key={`document-${numInRow}`}
            data={searchedDocuments}
            renderItem={({item}: any) => {
              return (
                <DocumentCard
                  item={item}
                  canAdd={canAdd}
                  viewCallback={viewCallback}
                  canEdit={canEdit}
                  editCallback={editCallback}
                  canDelete={canDelete}
                  deleteCallback={deleteCallback}
                />
              );
            }}
            keyExtractor={item => `document-${item.id}`}
            numColumns={numInRow}
            style={[styles.paddingLHorizontal]}
          />
        ) : (
          <NoResults
            iconName={'file-outline'}
            secondaryMessage={'This client does not have any documents yet.'}
          />
        )}
      </ScrollView>
    </BaseIndexScreen>
  );
};

export default Documents;
