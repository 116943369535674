import React from 'react';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Text, View} from 'react-native';
import moment from 'moment';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';

const ClaimEvents = ({events}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.column, styles.padding]}>
      <View style={[styles.row, styles.marginLBottom]}>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Type
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Description
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            User
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Date
          </Text>
        </View>
      </View>
      {events.map((event: any) => {
        return (
          <View style={[styles.row, styles.marginLBottom]}>
            <View style={[styles.flex]}>
              <Text style={[Typography.P3, styles.textColorPrimary]}>
                {event.type}
              </Text>
            </View>
            <View style={[styles.flex]}>
              <Text style={[Typography.P3, styles.textColorPrimary]}>
                {event.description}
              </Text>
            </View>
            <View style={[styles.flex]}>
              <Text style={[Typography.P3, styles.textColorPrimary]}>
                {event.user}
              </Text>
            </View>
            <View style={[styles.flex]}>
              <Text style={[Typography.P3, styles.textColorPrimary]}>
                {moment(event.timestamp).format(
                  'dddd, MMMM Do YYYY, h:mm:ss a',
                )}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['claim'], ({claim}: any) => ({
    events: claim.claimEvents,
  })),
)(ClaimEvents);
