import React, {Dispatch, SetStateAction} from 'react';
import {View, Text, ScrollView} from 'react-native';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import {Typography, Colors} from 'src/styles';
import {FileImage} from 'src/design-system';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import FileUpload from 'src/hook-form/file-upload';
import {Payer, Role} from 'src/models';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import Tin from 'src/hook-form-inputs/tin';
import {useStyle} from 'src/providers/style';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import Collapsible from 'react-native-collapsible';
import {NPI} from 'src/hook-form-inputs';
import InputGroup from 'src/design-system/input-group';
import {
  Input as TaxonomyInput,
  Display as TaxonomyDisplay,
} from 'src/hook-form-inputs/taxonomy';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import CredentialPayerItem, {
  CredentialPayerItemDisplay,
} from 'src/modules/users/components/credential-payer-item';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import addressFormat from 'src/common-utils/address-format';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface Props {
  edit: boolean;
  organization: any;
  role: Role;
  cancel: () => void;
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  editMode: [boolean, Dispatch<SetStateAction<boolean>>];
  payers: Payer[];
}

const OrganizationSummary = ({
  edit,
  organization,
  role,
  cancel,
  setUnsavedChanges,
  editMode: [editMode, setEditMode],
  payers,
}: Props) => {
  const styles = useStyle();
  const database = useDatabase();
  const validationSchema = Yup.object({
    npi: NPI.validation(),
  });
  const methods = useForm({
    defaultValues: {
      name: organization?.name,
      dba: organization?.dba,
      address: organization?.address,
      billingAddress: organization?.billingAddress,
      tin: organization?.tin || '',
      npi: organization?.npi || '',
      actualTime: organization?.actualTime || false,
      singleAddress: organization?.singleAddress || true,
      taxonomy: organization?.taxonomy || [],
      payers: payers.length
        ? payers.map((payer: Payer) => ({
            _id: payer.id,
            legacyId: payer.legacyId,
          }))
        : [],
      useClientIdentifier: organization?.useClientIdentifier || false,
    },
    resolver: yupResolver(validationSchema),
  });

  const showBillingAddress = useWatch({
    control: methods.control,
    name: 'singleAddress',
  });

  const {fields: payerFields} = useFieldArray({
    control: methods.control,
    name: 'payers',
  });

  const {selectedGroup} = useSelector(state => state.authentication);
  const onSubmit = async (data: any) => {
    await organization.updateEntity({
      name: data.name,
      dba: data.dba,
      address: data.address,
      singleAddress: data.singleAddress,
      billingAddress: data.billingAddress,
      logo: data.logo,
      tin: data.tin,
      npi: data.npi,
      taxonomy: data.taxonomy,
      actualTime: data.actualTime,
      useClientIdentifier: data.useClientIdentifier,
    });
    for (const payer of data.payers) {
      const entity = await database.get(Payer.table).find(payer._id);
      await entity.updateEntity({
        legacyId: payer.legacyId,
        taxonomy: payer.taxonomy,
      });
    }
    setEditMode(false);
  };

  const onError = (tabStatusErrors: any) => {
    // TODO: Navigate to tab and validation failure
    console.log(tabStatusErrors);
  };

  return (
    <>
      <ScrollView
        style={[
          styles.flex,
          styles.backgroundColorWhite,
          styles.paddingHorizontal,
          styles.borderRadius,
        ]}
        contentContainerStyle={[styles.paddingVertical]}>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignCenter,
            styles.marginBottom,
          ]}>
          <Text style={[Typography.H4]}>Summary</Text>
          <>
            {role?.organizationEdit ? (
              <View style={[]}>
                {!editMode ? (
                  <RHButton
                    mode="outlined"
                    icon="pencil"
                    textColor={Colors.RAVEN_BLACK}
                    onPress={() => {
                      setEditMode(true);
                    }}>
                    Edit
                  </RHButton>
                ) : (
                  <View style={[styles.row]}>
                    <RHButton
                      mode="outlined"
                      textColor={Colors.RAVEN_BLACK}
                      style={[]}
                      onPress={() => {
                        if (
                          Object.keys(methods.formState.dirtyFields).some(
                            field => field,
                          )
                        ) {
                          setUnsavedChanges(true);
                        } else {
                          cancel();
                        }
                      }}>
                      Cancel
                    </RHButton>
                    <RHSeparator width={10} />
                    <RHButton
                      mode="contained"
                      icon="content-save"
                      color={Colors.TERTIARY_TEAL}
                      onPress={methods.handleSubmit(onSubmit, onError)}>
                      SAVE
                    </RHButton>
                  </View>
                )}
              </View>
            ) : (
              <></>
            )}
          </>
        </View>
        {edit ? (
          <FormProvider {...methods}>
            <View style={[styles.marginLVertical]}>
              <HookFormInput
                name={'name'}
                label={'Organization Name'}
                required={true}
              />
            </View>
            <HookFormInput name={'dba'} label={'DBA (Doing Business As)'} />
            <View>
              <Text style={[[Typography.P3_BOLD], styles.marginLVertical]}>
                Organization Address
              </Text>
              <AddressAutocompleteInput name={'address'} label={'Address'} />
            </View>
            <View>
              <Text style={[[Typography.P3_BOLD], styles.marginLVertical]}>
                Billing Address
              </Text>
              <HookFormSwitchInput
                name={'singleAddress'}
                label={'Same as Organization Address'}
              />
              <Collapsible collapsed={showBillingAddress}>
                <AddressAutocompleteInput
                  name={'billingAddress'}
                  label={'Billing Address'}
                />
              </Collapsible>
            </View>
            <HookFormSwitchInput
              name={'actualTime'}
              label={'Session is Actual Time'}
            />
            <HookFormSwitchInput
              name={'useClientIdentifier'}
              label={'Display Client Identifier in Session'}
            />
            <InputGroup right={<Tin />} left={<NPI.Input />} />
            <TaxonomyInput />
            {payers.length ? (
              <View style={[styles.marginLVertical, styles.paddingHorizontal]}>
                <Text style={[Typography.P3_BOLD]}>Assigned Payers</Text>
                <View style={[styles.marginLVertical]}>
                  <View
                    style={[
                      styles.row,
                      styles.borderBottom,
                      styles.alignCenter,
                      styles.justifyCenter,
                      styles.flex,
                    ]}>
                    <View
                      style={[
                        styles.paddingL,
                        styles.borderRight,
                        styles.flex,
                        styles.alignCenter,
                        styles.justifyCenter,
                      ]}>
                      <View style={[styles.width]}>
                        <Text style={[Typography.P3]}>Payer</Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.paddingL,
                        styles.borderRight,
                        styles.flex,
                        styles.alignCenter,
                        styles.justifyCenter,
                      ]}>
                      <View style={[styles.width]}>
                        <Text style={[Typography.P3]}>ID #</Text>
                      </View>
                    </View>
                  </View>
                  {payerFields.map((payer: any, index: number) => {
                    return (
                      <CredentialPayerItem
                        key={`staff-payer-${payer._id}-${index}`}
                        payerId={payer._id}
                        index={index}
                      />
                    );
                  })}
                </View>
              </View>
            ) : null}
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>Organization Logo</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your organizations logo that will appear in the app's menu
              </Text>
              <Controller
                control={methods.control}
                rules={{}}
                render={({field: {onChange, value, ref}}) => (
                  <FileUpload
                    path={`${selectedGroup}/organizations/${organization.id}`}
                    formRef={ref}
                    onChange={onChange}
                    value={value}
                    error={methods.formState.errors.logo}
                  />
                )}
                name="logo"
                defaultValue={organization?.logo}
              />
            </View>
          </FormProvider>
        ) : (
          <>
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>Organization Name</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your organization's name
              </Text>
              <Text
                style={[
                  Typography.INPUT,
                  styles.textColorPrimary,
                  styles.marginSMVertical,
                ]}>
                {organization?.name}
              </Text>
            </View>
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>DBA (Doing Business As)</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your organization's trade name
              </Text>
              <Text
                style={[
                  Typography.INPUT,
                  styles.textColorPrimary,
                  styles.marginSMVertical,
                ]}>
                {organization?.dba}
              </Text>
            </View>
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>
                Organization Account Number
              </Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your organization's account number
              </Text>
              <Text
                style={[
                  Typography.INPUT,
                  styles.textColorPrimary,
                  styles.marginSMVertical,
                ]}>
                {organization?.accountNumber}
              </Text>
            </View>
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>Organization Address</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your organization's address
              </Text>
              <Text
                style={[
                  Typography.INPUT,
                  styles.textColorPrimary,
                  styles.marginSMVertical,
                ]}>
                {addressFormat(organization?.address)}
              </Text>
            </View>
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>Billing Address</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your billing address
              </Text>
              <Text
                style={[
                  Typography.INPUT,
                  styles.textColorPrimary,
                  styles.marginSMVertical,
                ]}>
                {addressFormat(organization.billingAddress)}
              </Text>
            </View>
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>Session Time</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your organization's session time will be displayed as the
              </Text>
              <Text
                style={[
                  Typography.INPUT,
                  styles.textColorPrimary,
                  styles.marginSMVertical,
                ]}>
                {organization?.actualTime ? (
                  <Text>Actual Time</Text>
                ) : (
                  <Text>Scheduled Time</Text>
                )}
              </Text>
            </View>
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>Client Display</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Data collection sessions will display the client's
              </Text>
              <Text
                style={[
                  Typography.INPUT,
                  styles.textColorPrimary,
                  styles.marginSMVertical,
                ]}>
                {organization?.useClientIdentifier ? (
                  <Text>Identifier</Text>
                ) : (
                  <Text>Full Name</Text>
                )}
              </Text>
            </View>
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>
                Tax Identification Number
              </Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your TIN
              </Text>
              <Text
                style={[
                  Typography.INPUT,
                  styles.textColorPrimary,
                  styles.marginSMVertical,
                ]}>
                {organization?.tin}
              </Text>
            </View>
            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>
                National Provider Identifier
              </Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your NPI
              </Text>
              <Text
                style={[
                  Typography.INPUT,
                  styles.textColorPrimary,
                  styles.marginSMVertical,
                ]}>
                {organization?.npi}
              </Text>
            </View>
            <View style={[styles.marginLVertical]}>
              <TaxonomyDisplay value={organization?.taxonomy} />
            </View>

            {payers?.length ? (
              <View style={[styles.marginLVertical]}>
                <Text style={[Typography.P3_BOLD]}>Assigned Payers</Text>
                <View style={[styles.marginLVertical]}>
                  <View
                    style={[
                      styles.row,
                      styles.borderBottom,
                      styles.alignCenter,
                      styles.justifyCenter,
                      styles.flex,
                    ]}>
                    <View
                      style={[
                        styles.paddingL,
                        styles.borderRight,
                        styles.flex,
                        styles.alignCenter,
                        styles.justifyCenter,
                      ]}>
                      <View style={[styles.width]}>
                        <Text style={[Typography.P3]}>Payer</Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.paddingL,
                        styles.borderRight,
                        styles.flex,
                        styles.alignCenter,
                        styles.justifyCenter,
                      ]}>
                      <View style={[styles.width]}>
                        <Text style={[Typography.P3]}>ID #</Text>
                      </View>
                    </View>
                  </View>
                  {payers?.map((payer: Payer, index: number) => {
                    return (
                      <CredentialPayerItemDisplay
                        key={`staff-payer-${payer.id}-${index}`}
                        value={payer}
                      />
                    );
                  })}
                </View>
              </View>
            ) : (
              <></>
            )}

            <View style={[styles.marginLVertical]}>
              <Text style={[Typography.P3_BOLD]}>Organization Logo</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Your organization's logo that will appear in the app's menu
              </Text>
              {_.isEmpty(organization?.logo) ? (
                <Text
                  style={[
                    Typography.INPUT,
                    styles.textColorPrimary,
                    styles.marginSMVertical,
                  ]}>
                  Upload a Logo
                </Text>
              ) : (
                <View
                  style={[
                    styles.width200,
                    styles.height200,
                    styles.positionRelative,
                    styles.alignCenter,
                    styles.justifyCenter,
                    styles.borderRadiusSM,
                  ]}>
                  <FileImage styles={styles.round} src={organization.logo} />
                </View>
              )}
            </View>
          </>
        )}
      </ScrollView>
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    payers: database
      .get(Payer.table)
      .query(
        Q.where('_partition', authentication.selectedGroup),
        Q.where('deleted_at', null),
      ),
  })),
)(OrganizationSummary);
