import React, {useEffect, useState} from 'react';
import {FlatList} from 'react-native';
import ListItemSeparator from 'src/common-components/separator';
import NoResults from 'src/common-components/noResults';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import {useSelector} from 'react-redux';
import {mergeMap, of} from 'rxjs';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import _ from 'lodash';
import DiagnosisInput from 'src/hook-form-inputs/diagnosis-code';
import {InstanceDiagnosis} from 'src/models/instance-diagnosis';
import withState from 'src/redux/wrapper';
import {sortedDiagnosisList} from 'src/common-utils/diagnosisCodes';
import {BaseIndexScreen, Modal} from 'src/design-system';
import DiagnosisListItem from 'src/modules/organization/components/diagnosis-list-item';
import DiagnosisForm from 'src/modules/organization/components/diagnosis-form';

interface Props {
  diagnoses: any[];
  instance: any;
  role: any;
}

const DiagnosesTab = ({diagnoses, instance, role}: Props) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const [show, setShow] = useState(false);
  const [diagnosisState, setDiagnosisState] = useState(null);

  const title = diagnosisState ? 'Edit Diagnosis' : 'Create Diagnosis';

  const addCallback = () => {
    setDiagnosisState(null);
    setShow(true);
  };

  const methods = useForm({
    defaultValues: {
      diagnosis: diagnoses.map(
        (diagnosis: InstanceDiagnosis) => diagnosis.diagnosis,
      ),
    },
  });

  const individualDiagnosis = useWatch({
    control: methods.control,
    name: 'diagnosis',
  });

  useEffect(() => {
    methods.reset({
      diagnosis: diagnoses.map(
        (diagnosis: InstanceDiagnosis) => diagnosis.diagnosis,
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnoses]);

  const deletingDiagnosis = async diagnosis => {
    const fetchedDiag = await database
      .get(InstanceDiagnosis.table)
      .find(diagnosis);
    fetchedDiag.delete();
  };

  useEffect(() => {
    const deleteDiagnosis = _.differenceWith(
      diagnoses,
      individualDiagnosis,
      (value1: any, value2: string) => {
        return value1.diagnosis === value2;
      },
    );

    const differenceDiagnosis = _.differenceWith(
      individualDiagnosis,
      diagnoses,
      (value1: string, value2: any) => {
        return value1 === value2.diagnosis;
      },
    );

    for (const diag of differenceDiagnosis) {
      onAssign(diag);
    }

    for (const diag of deleteDiagnosis) {
      deletingDiagnosis(diag.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualDiagnosis]);

  const onAssign = async (diagnosis: any) => {
    const name = sortedDiagnosisList().find(
      item => item.value === diagnosis,
    )?.label;
    await database.write(async () => {
      await database.get(InstanceDiagnosis.table).create(entity => {
        entity.partition = selectedGroup;
        entity.diagnosis = diagnosis;
        entity.name = name;
        entity.instanceId = instance;
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
    });
  };

  const handleModalSubmit = async (formData: {
    diagnosis: string;
    name: string;
  }) => {
    const {diagnosis, name} = formData;
    await database.write(async () => {
      await database.get(InstanceDiagnosis.table).create(entity => {
        entity.partition = selectedGroup;
        entity.diagnosis = diagnosis;
        entity.name = `${diagnosis} - ${name}`;
        entity.instanceId = instance;
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
    });
    setShow(false);
  };

  return (
    <BaseIndexScreen
      title={translations('diagnoses')}
      search={
        <>
          {role?.organizationDiagnosisCreate ? (
            <FormProvider {...methods}>
              <DiagnosisInput multiple={true} />
            </FormProvider>
          ) : null}
        </>
      }
      add={addCallback}
      canAdd={role?.organizationDiagnosisCreate}>
      <Modal show={[show, setShow]} title={title}>
        <DiagnosisForm onAssign={handleModalSubmit} />
      </Modal>
      {diagnoses?.length ? (
        <FlatList
          data={diagnoses}
          scrollEnabled={true}
          keyExtractor={item => item?.id?.toString()}
          renderItem={({item}) => {
            return (
              <DiagnosisListItem
                item={item}
                canDelete={role?.organizationDiagnosisDelete}
                deleteCallback={deletingDiagnosis}
              />
            );
          }}
          ItemSeparatorComponent={ListItemSeparator}
          style={[styles.flex]}
        />
      ) : (
        <NoResults
          iconName="account-supervisor"
          message={translations('no_diagnoses')}
        />
      )}
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of({}),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({database, authentication}: any) => {
    return {
      instance: database
        ?.get('instances')
        .query(Q.where('_partition', authentication.selectedGroup))
        .observe()
        .pipe(mergeMap(x => x)),
      diagnoses: database
        .get('instance_diagnoses')
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
        ),
    };
  }),
)(DiagnosesTab);
