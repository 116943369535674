import React, {useEffect, useRef, useState} from 'react';
import {Text, View, FlatList, TouchableOpacity} from 'react-native';
import {IconButton} from 'react-native-paper';
import {useDimensions} from '@react-native-community/hooks';
import TaskAnalysisTarget from 'src/modules/session/components/task-analysis/target';
import TaskAnalysisTargetEdit from 'src/modules/session/components/task-analysis/target-edit';
import {ProgramMethodAvatar} from 'src/modules/programs/components';
import {Colors, Typography} from 'src/styles';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Event, Set} from 'src/models';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {of} from 'rxjs';
import {useSelector} from 'react-redux';
import ProgramMenu from 'src/design-system/program-menu';
import Modal from 'src/design-system/modal';
import ProgramProgress from 'src/modules/programs/components/program-progress-legacy';
import {TaskAnalysisTable} from 'src/modules/session/components';
import ProgramInstructionModal from '../program-instruction-modal';
import {useStyle} from 'src/providers/style';
import SetComplete from 'src/modules/session/components/set-complete';
import ProgramTooltip from '../program-tooltip';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';
import {useTranslations} from 'src/providers/translation';

const getNumColumns = (width: number) => {
  if (width > 1920) {
    return 4;
  } else if (width > 1440) {
    return 3;
  } else if (width > 1024) {
    return 2;
  } else if (width > 768) {
    return 1;
  } else {
    return 1;
  }
};

const TaskAnalysisProgram = ({
  program,
  session,
  enabledTargets,
  prompts,
  sets,
  events,
  targets,
}: any) => {
  const database = useDatabase();
  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const dimensions = useDimensions();
  const scrollView = useRef();
  const styles = useStyle();

  const numColumns = getNumColumns(dimensions.window.width);
  const [open, setOpen] = useState(false);
  const [eventId, setEventId] = useState('');
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [programTargets, setTargets] = useState(enabledTargets);

  const translations = useTranslations();
  const viewInstructions = () => {
    setInstructionsModal(!instructionsModal);
  };
  const viewHistory = () => {
    setHistoryModal(!historyModal);
  };
  const viewData = () => {
    setDataModal(!dataModal);
  };

  const currentSet = sets?.[0] || {
    startTimestamp: null,
    endTimestamp: null,
  };
  useEffect(() => {
    const filteredTargets = enabledTargets.filter(
      (target: any, index: number) => {
        return (
          index ===
          enabledTargets.findIndex(obj => target.targetId === obj.targetId)
        );
      },
    );

    setTargets(filteredTargets);
  }, [enabledTargets]);

  const onValue = async (
    idx: number,
    target: any,
    value: string,
    prompt: any,
  ) => {
    if (!currentSet || currentSet.startTimestamp === null) {
      await database?.write(async () => {
        const set = await database?.get(Set.table).create(entity => {
          entity.partition = selectedGroup;
          entity.session.id = session.id;
          entity.target.id = null;
          entity.program.id = program.id;
          entity.startTimestamp = new Date();
          entity.endTimestamp =
            events?.length + 1 >= programTargets.length ? new Date() : null;
          entity.numberOfTrials = programTargets.length;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
        await database.get(Event.table).create(entity => {
          entity.partition = selectedGroup;
          entity.set.id = set.id;
          entity.target.id = target.id;
          entity.value = value;
          entity.prompts = prompt ? [prompt] : [];
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
        return set;
      });
    } else {
      await database?.write(async () => {
        await database.get(Event.table).create(entity => {
          entity.partition = selectedGroup;
          entity.set.id = currentSet.id;
          entity.target.id = target.id;
          entity.value = value;
          entity.prompts = prompt ? [prompt] : [];
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
      if (events?.length + 1 >= programTargets.length) {
        await currentSet.endSet(programTargets.length);
      }
    }
    try {
      scrollView.current?.scrollToIndex({animated: true, index: idx + 1});
    } catch (e) {}
  };

  const addSet = async () => {
    await database?.write(async () => {
      return await database?.get(Set.table).create(entity => {
        entity.partition = selectedGroup;
        entity.session.id = session.id;
        entity.target.id = null;
        entity.program.id = program.id;
        entity.startTimestamp = new Date();
        entity.endTimestamp = null;
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
    });
  };

  const undo = async () => {
    const orderedEvents = await currentSet.orderedEvents.fetch();
    if (currentSet.endTimestamp !== null) {
      currentSet.updateEntity({
        endTimestamp: null,
      });
    }
    if (orderedEvents.length > 1) {
      const event = orderedEvents[0];
      event.delete();
      try {
        scrollView.current?.scrollToIndex({
          animated: true,
          index: orderedEvents.length - 1,
        });
      } catch (e) {}
    } else {
      const event = orderedEvents?.[0];
      if (event) {
        event.delete();
      }
      currentSet.delete();
      try {
        scrollView.current?.scrollToIndex({animated: true, index: 0});
      } catch (e) {}
    }
  };

  return (
    <>
      {instructionsModal ? (
        <ProgramInstructionModal
          show={[instructionsModal, setInstructionsModal]}
          program={program}
        />
      ) : null}
      {historyModal ? (
        <Modal
          show={[historyModal, setHistoryModal]}
          title={'Progress'}
          footer={
            <InformationTooltip
              value={translations('program_progress_month')}
            />
          }>
          <ProgramProgress program={program} />
        </Modal>
      ) : null}
      {dataModal ? (
        <Modal show={[dataModal, setDataModal]} title={`${program.name} Data`}>
          <TaskAnalysisTable
            program={program}
            targets={targets}
            type={'edit-modal'}
            session={session}
            sets={sets}
            setEventId={id => setEventId(id)}
          />
          {eventId ? (
            <TaskAnalysisTargetEdit
              eventId={eventId}
              program={program}
              sets={sets}
              enabledTargets={programTargets}
              setEventId={setEventId}
            />
          ) : (
            <></>
          )}
        </Modal>
      ) : null}
      <TouchableOpacity
        style={[
          styles.row,
          styles.justifySpaceBetween,
          styles.marginTop,
          styles.marginHorizontal,
          styles.zIndex100,
        ]}
        onPress={() => setOpen(!open)}>
        <View style={[styles.row, styles.flex, styles.alignCenter]}>
          <ProgramMethodAvatar type={program?.method} />
          <View style={[styles.column, styles.marginLeft, styles.flex]}>
            <ProgramTooltip name={program?.name} />
            <Text style={[Typography.CAPTION, styles.textColorSecondary]}>
              Set {sets?.length || 1}
            </Text>
          </View>
          {sets.length > 0 ? (
            <IconButton
              onPress={undo}
              icon={'undo'}
              type={'icon'}
              color={Colors.RAVEN_BLACK}
            />
          ) : (
            <></>
          )}
        </View>
        <ProgramMenu
          viewInstructions={viewInstructions}
          viewHistory={viewHistory}
          viewData={viewData}
        />
        <IconButton
          color={Colors.RAVEN_BLACK}
          icon={!open ? 'chevron-right' : 'chevron-down'}
          onPress={() => setOpen(!open)}
        />
      </TouchableOpacity>
      {programTargets?.length ? (
        <Text
          style={[Typography.P3, styles.marginTop, styles.marginHorizontal]}>
          {program?.stimulusDescription}
        </Text>
      ) : null}
      {programTargets?.length && events?.length >= programTargets?.length ? (
        <View
          style={[
            styles.border,
            styles.borderColorPrimary300,
            styles.borderRadiusSM,
            styles.padding,
            styles.margin,
            styles.width300,
            styles.alignCenter,
          ]}>
          <SetComplete
            fill={
              events?.length === 0
                ? 100
                : Math.ceil(
                    (events?.filter((event: any) => event.value === 'success')
                      .length /
                      events?.length) *
                      100,
                  )
            }
            addSet={addSet}
          />
        </View>
      ) : (
        <FlatList
          ref={scrollView}
          key={open ? numColumns : 1}
          numColumns={open ? numColumns : 1}
          horizontal={!open}
          data={programTargets}
          contentContainerStyle={[styles.paddingM]}
          keyExtractor={item => item.id}
          initialNumToRender={1}
          onEndReachedThreshold={0.5}
          columnWrapperStyle={
            !open ? null : numColumns === 1 ? null : styles.columnWrapperStyle
          }
          persistentScrollbar={true}
          renderItem={({item, index}) => (
            <TaskAnalysisTarget
              index={index}
              currentTarget={events?.length}
              total={programTargets?.length}
              enabledTarget={item}
              prompts={prompts}
              value={events?.[index]?.value || null}
              onValue={onValue}
            />
          )}
        />
      )}
    </>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({program}: any) => ({
    targets: program.enabledTargets,
    prompts: program.prompts,
  })),
  withObservables([], ({database, targets, sessionProgram}: any) => {
    const target_id = targets.map((target: any) => target.id);
    return {
      enabledTargets: database
        .get('session_targets')
        .query(
          Q.where('enabled', true),
          Q.where('session_program_id', sessionProgram.id),
          Q.on('targets', Q.and(Q.where('id', Q.oneOf(target_id)))),
          Q.sortBy('rank', Q.asc),
        ),
    };
  }),
  withObservables([], ({session, program, database}: any) => ({
    targets: program?.enabledTargets,
    sets: database
      .get('sets')
      .query(
        Q.and(
          Q.where('program_id', program.id),
          Q.where('session_id', session.id),
          Q.where('deleted_at', null),
        ),
        Q.sortBy('created_at', Q.desc),
      ),
  })),
  withObservables(['sets'], ({sets}: any) => {
    if (sets.length !== 0) {
      return {
        events: sets?.[0]?.ascendingEvents,
      };
    }
    return {
      events: of([]),
    };
  }),
)(TaskAnalysisProgram);
