import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import moment from 'moment';
import {IDeveloperField} from 'dromo-uploader-react';
import TimeInput from 'src/hook-form-wrapper/time-input';

interface DisplayProps {
  value: string;
}

interface Props {
  label?: string;
  name?: string;
  date?: Date | undefined;
}

export const Import = (
  key: string = 'endTime',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'End Time (HH:mm)',
  key,
  validators: [
    {
      validate: 'regex_match',
      regex: '^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$',
      errorMessage: 'Must be a valid time.',
    },
  ],
});

const resolveDisplayValue = (value: string) => {
  return moment(value).format('ll');
};

const EndTimeDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>End Time</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const EndTimeInput = ({name, label = 'End Time', date}: Props) => {
  return (
    <TimeInput
      name={(name ? name + '.' : '') + 'endTime'}
      label={label}
      date={date}
      required={true}
    />
  );
};

export const validation = () => Yup.string().required('End time is required');

export const Display = EndTimeDisplay;
export const Input = EndTimeInput;
export const Value = resolveDisplayValue;

export default EndTimeInput;
