import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import DateInput from 'src/hook-form/date-input';

interface Props {
  rules?: any;
  name: string;
  label: string;
  helper?: string;
  placeholder: string;
  required?: boolean;
  minimumDate?: Date | undefined;
  maximumDate?: Date | undefined;
  defaultValue?: Date;
  disabled?: boolean;
  showHelper?: boolean;
}

const DateLayout = (props: Props) => {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      rules={props.rules}
      render={({field, fieldState: {error}}) => (
        <DateInput formRef={field.ref} {...props} {...field} error={error} />
      )}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
};

export default DateLayout;
