import React, {useState} from 'react';
import BaseAuthScreen from 'src/modules/authentication/screens/base-auth-screen';
import {SignupForm} from 'src/modules/authentication/components';
import {Text, TouchableOpacity, View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {Typography} from 'src/styles';
import {BASE_AUTH_URL} from 'react-native-dotenv';
import {useTranslations} from 'src/providers/translation';
import {useStyle} from 'src/providers/style';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import RHButton from '../../../../common-components/custom-ui-helpers/RHButton';
import {OfflineNotification} from 'src/design-system';

const SignupScreen = () => {
  const navigation = useNavigation();
  const translations = useTranslations();
  const styles = useStyle();

  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: {
      name: '',
      email: '',
      mobilePhone: '',
      title: '',
    },

    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required('Please enter a valid name.'),
        email: Yup.string()
          .email('Invalid email')
          .required('Please enter a valid email address.'),
      }),
    ),
  });

  const onSubmit = async (payload: any) => {
    setLoading(true);
    try {
      await fetch(`${BASE_AUTH_URL}/lead`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Transfer-Encoding': 'chunked',
        },
        body: JSON.stringify({
          name: payload.name,
          email: payload.email,
          phoneNumber: payload.mobilePhone,
          title: payload.title,
        }),
      });
      setSubmitted(!submitted);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const login = () => {
    navigation.navigate('Login');
  };

  return (
    <BaseAuthScreen loading={loading}>
      {submitted ? (
        <Text
          style={[
            Typography.P2,
            styles.paddingVertical,
            styles.textAlignCenter,
          ]}>
          {translations('auth_screen_thank_you')}
        </Text>
      ) : (
        <Text
          style={[
            Typography.H6,
            styles.paddingVertical,
            styles.textAlignCenter,
            styles.textColorSecondary,
          ]}>
          {translations('auth_screen_demo')}
        </Text>
      )}
      {!submitted ? (
        <FormProvider {...methods}>
          <SignupForm />
          <View style={[styles.paddingVertical]}>
            <RHButton mode="contained" onPress={methods.handleSubmit(onSubmit)}>
              SIGN UP
            </RHButton>
            <OfflineNotification
              type="text"
              message="Please connect to an internet connection to sign in"
            />
          </View>
        </FormProvider>
      ) : null}
      <View style={[styles.paddingVertical]}>
        <TouchableOpacity onPress={login}>
          <Text
            style={[
              Typography.TERTIARY_BUTTON,
              styles.textColorPrimary,
              styles.textAlignCenter,
            ]}>
            RETURN TO LOGIN
          </Text>
        </TouchableOpacity>
      </View>
    </BaseAuthScreen>
  );
};

export default SignupScreen;
