import React, {useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {IconButton} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import Collapsible from 'react-native-collapsible';
import {useStyle} from 'src/providers/style';

const CollapsibleFilterItem = ({title, children}: any) => {
  const styles = useStyle();

  const [open, setOpen] = useState(true);

  return (
    <View style={[styles.paddingSMBottom]}>
      <TouchableOpacity
        onPress={() => setOpen(!open)}
        style={[
          styles.row,
          styles.justifySpaceBetween,
          styles.alignCenter,
          styles.marginSMTop,
        ]}>
        <View style={[styles.flex, styles.flexWrap]}>
          <Text style={[Typography.LABEL, styles.textColorPrimary700]}>
            {title}
          </Text>
        </View>
        <IconButton
          color={Colors.RAVEN_BLACK}
          icon={!open ? 'chevron-up' : 'chevron-down'}
          onPress={() => setOpen(!open)}
          rippleColor={'transparent'}
        />
      </TouchableOpacity>
      <Collapsible collapsed={open}>{children}</Collapsible>
    </View>
  );
};

export default CollapsibleFilterItem;
