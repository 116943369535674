import React from 'react';
import {Avatar} from 'react-native-paper';

interface Props {
  label: string | undefined;
  size: number | undefined;
  color?: string | undefined;
  textColor?: string | undefined;
}

const RHAvatar = ({label = 'RH', color, textColor, size = 24}: Props) => {
  return (
    <Avatar.Text
      size={size}
      color={color}
      labelStyle={{color: textColor}}
      label={label.toUpperCase()}
      theme={{
        colors: {
          primary: color,
        },
      }}
    />
  );
  // TODO: return Avatar.Image on presence of img source:
  // return <Avatar.Image source={source} />
};

export default RHAvatar;
