import React from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import moment from 'moment';
import _ from 'lodash';
import {useStyle} from 'src/providers/style';
import PromptsDisplay from '../prompts-display';

const DurationTooltipEdit = ({
  method,
  set,
  instance,
  setEvents,
  setInstance,
  setRow,
  rate,
  row = '',
}: any) => {
  const styles = useStyle();
  const duration = moment.duration(
    moment(set?.endTimestamp).diff(moment(set?.startTimestamp)),
  );
  const hour = duration.hours();
  const min = duration.minutes();
  const sec = duration.seconds();

  return (
    <TouchableOpacity
      onPress={() => {
        setEvents(set);
        setInstance(instance);
        setRow(row);
      }}>
      <View style={[styles.column, styles.alignCenter, styles.justifyCenter]}>
        {method === 'duration' ? (
          <View style={styles.padding}>
            {row === 'total' ? (
              <Text>
                {hour > 0 && hour + ' hr '}
                {min > 0 && min + ' min '}
                {sec > 0 && sec + ' sec'}
              </Text>
            ) : row === 'intensity' ? (
              <Text>{_.capitalize(set?.intensity)}</Text>
            ) : row === 'end_time' ? (
              <Text>{moment(set?.endTimestamp).format('hh:mm:ss A')}</Text>
            ) : null}
          </View>
        ) : (
          <View style={styles.padding}>
            {row === 'intensity' ? (
              <Text>{_.capitalize(set?.intensity)}</Text>
            ) : row === 'rate' ? (
              <View>
                <Text>{rate}</Text>
                {set?.prompts?.length ? (
                  <PromptsDisplay prompts={set?.prompts} />
                ) : (
                  <></>
                )}
              </View>
            ) : row === 'duration' ? (
              <Text>
                {hour > 0 && hour + ' hr '}
                {min > 0 && min + ' min '}
                {sec > 0 && sec + ' sec'}
              </Text>
            ) : null}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default DurationTooltipEdit;
