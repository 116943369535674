export const resourceUrls: any = {
  client_summary: '/public/Dashboards/Program_Summary',

  task_analysis:
    '/public/Dashboards/Skill_Acquisition___Program_Success_by_Task',

  task: '/public/Dashboards/Skill_Acquisition___Task',

  trial_by_trial:
    '/public/Dashboards/Skill_Acquisition___Program_Success_by_Target',

  target: '/public/Dashboards/Skill_Acquisition___Target',

  frequency: '/public/Dashboards/Behavior___Frequency_Program_Dashboard',

  frequency_no_intensity:
    '/public/Dashboards/Behavior___Frequency_Program_No_Intensity_Dashboard',

  interval: '/public/Dashboards/Behavior___Interval_Program_Dashboard',

  interval_no_intensity:
    '/public/Dashboards/Behavior___Interval_Program_No_Intensity_Dashboard',

  duration: '/public/Dashboards/Behavior___Duration_Program_Dashboard',

  duration_no_intensity:
    '/public/Dashboards/Behavior___Duration_Program_No_Intensity_Dashboard',

  rate: '/public/Dashboards/Behavior___Rate_Program_Dashboard',

  rate_no_intensity:
    '/public/Dashboards/Behavior___Rate_Program_No_Intensity_Dashboard',

  task_analysis_per_session:
    '/public/Dashboards/Per_Session___Task_Analysis_Program_Dashboard',

  trial_by_trial_per_session:
    '/public/Dashboards/Per_Session___Trial_By_Trial_Program_Dashboard',

  target_per_session: '/public/Dashboards/Per_Session___Target_Dashboard',

  frequency_per_session:
    '/public/Dashboards/Per_Session___Frequency_Program_Dashboard',

  duration_per_session:
    '/public/Dashboards/Per_Session___Duration_Program_Dashboard',

  rate_per_session: '/public/Dashboards/Per_Session___Rate_Program_Dashboard',
};
