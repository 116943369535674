import React, {useEffect, useRef, useState} from 'react';
import {Animated, TouchableOpacity, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useStyle} from 'src/providers/style';
import {Colors} from 'src/styles';
import {useDimensions} from '@react-native-community/hooks';

const RetractingDrawer = ({
  type = 'persistent',
  children,
  opening = () => {},
  closing = () => {},
}: any) => {
  const styles = useStyle();
  const dimensions = useDimensions();

  const [drawerOpen, setDrawerOpen] = useState(type === 'persistent');

  const openDrawer = () => {
    setDrawerOpen(true);
    opening();
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
    closing();
  };

  const drawerWidth = useRef(new Animated.Value(272)).current;
  const drawerLeft = useRef(
    new Animated.Value(type === 'persistent' ? 0 : -272),
  ).current;
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (type === 'persistent') {
      Animated.timing(drawerWidth, {
        toValue: drawerOpen ? 272 : 76,
        duration: 500,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.parallel([
        Animated.timing(drawerLeft, {
          toValue: drawerOpen ? 0 : -272,
          duration: 500,
          useNativeDriver: false,
        }),
        Animated.timing(opacity, {
          toValue: drawerOpen ? 1 : 0,
          duration: 250,
          useNativeDriver: false,
        }),
      ]).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <>
      {drawerOpen && type === 'overlay' ? (
        <TouchableOpacity
          onPress={() => closeDrawer()}
          style={[
            styles.positionAbsolute,
            styles.top0,
            styles.left0,
            styles.right0,
            styles.bottom0,
            styles.zIndex,
          ]}>
          <Animated.View
            style={[
              styles.positionAbsolute,
              styles.top0,
              styles.left0,
              styles.right0,
              styles.bottom0,
              styles.zIndex,
              styles.alignCenter,
              styles.justifyCenter,
              styles.backgroundColorOverlay,
              {opacity: opacity},
            ]}
          />
        </TouchableOpacity>
      ) : null}
      <Animated.View
        style={[
          type === 'persistent'
            ? styles.positionRelative
            : styles.positionAbsolute,
          styles.row,
          styles.zIndex,
          styles.height,
          {transform: [{translateX: drawerLeft}]},
        ]}
        onMouseLeave={
          dimensions.window.width < 1024 ? () => closeDrawer() : () => {}
        }
        onMouseEnter={openDrawer}>
        <Animated.View
          style={[
            styles.column,
            styles.backgroundColorWhite,
            styles.borderRight,
            {width: drawerWidth},
          ]}>
          {type === 'persistent' ? (
            <Icon
              name={
                drawerOpen
                  ? 'subdirectory-arrow-left'
                  : 'subdirectory-arrow-right'
              }
              size={14}
              color={Colors.TEXT_DISABLED}
              style={[
                styles.marginMBottom,
                styles.marginMTop,
                styles.paddingHorizontal,
              ]}
              onPress={() => (drawerOpen ? closeDrawer() : openDrawer())}
            />
          ) : null}
          <ScrollView contentContainerStyle={[styles.paddingHorizontal]}>
            {children}
          </ScrollView>
        </Animated.View>
        {type === 'overlay' ? (
          <View style={[styles.column]}>
            <TouchableOpacity
              onPress={() => (drawerOpen ? closeDrawer() : openDrawer())}>
              <View
                style={[
                  styles.marginSMTop,
                  styles.backgroundColorWhite,
                  styles.height40,
                  styles.width20,
                  styles.alignCenter,
                  styles.justifyCenter,
                  styles.borderTopRightRadius20,
                  styles.borderBottomRightRadius20,
                  styles.borderRight,
                ]}>
                <Icon
                  name={
                    drawerOpen ? 'keyboard-arrow-left' : 'keyboard-arrow-right'
                  }
                  size={20}
                  color={Colors.RAVEN_BLACK}
                />
              </View>
            </TouchableOpacity>
          </View>
        ) : null}
      </Animated.View>
    </>
  );
};

export default RetractingDrawer;
