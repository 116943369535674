import React, {useState} from 'react';
import {View, Text} from 'react-native';
import {NestableScrollContainer} from 'react-native-draggable-flatlist';
import {Typography} from 'src/styles';
import {DeleteModal, Modal} from 'src/design-system';
import * as Yup from 'yup';
import PromptForm from '../prompt-form';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import PromptTypeList from 'src/modules/organization/components/prompt-type-list';
import {Prompt} from 'src/models';
import {useStyle} from 'src/providers/style';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useTranslations} from 'src/providers/translation';

const Prompts = ({role}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const promptTypes = ['Prompts', 'Antecedents', 'Consequences'];

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [showDelete, setShowDelete] = useState<string | boolean>(false);
  const [show, setShow] = useState(false);
  const [prompt, setPrompt] = useState(null);

  const addCallback = () => {
    setPrompt(null);
    setShow(true);
  };
  const editCallback = (entity: any) => {
    setPrompt(entity);
    setShow(true);
  };

  const deleteCallback = (item: any) => {
    setShowDelete(item);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = async (item: any) => {
    item.delete();
    setShowDelete(false);
  };

  const title = prompt ? 'Edit Prompt' : 'Create Prompt';

  const validationSchema = Yup.object({
    promptType: Yup.string().required('Prompt Type is required'),
    name: Yup.string().required('A name is required'),
  });

  const navigateBack = (formik: any) => {
    formik.resetForm();
    setShow(false);
  };

  const handleSubmit = async (values: any, formik: any) => {
    if (prompt) {
      prompt.updateEntity(values);
    } else {
      await database.write(async () => {
        await database.get(Prompt.table).create(entity => {
          entity.partition = selectedGroup;
          Object.assign(entity, values);
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
    navigateBack(formik);
  };

  return (
    <>
      <Modal show={[show, setShow]} title={title}>
        <PromptForm
          enableReinitialize={!!prompt}
          handleSubmit={handleSubmit}
          cancelled={navigateBack}
          prompt={prompt}
          validationSchema={validationSchema}
        />
      </Modal>
      <DeleteModal
        model={'Prompt'}
        text={showDelete ? `${showDelete?.name}` : null}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <NestableScrollContainer
        style={[styles.flex, styles.backgroundColorWhite, styles.borderRadius]}
        contentContainerStyle={[styles.paddingVertical]}
        persistentScrollbar={true}>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignCenter,
            styles.marginBottom,
            styles.paddingHorizontal,
          ]}>
          <Text style={[Typography.H4]}>{translations('prompts')}</Text>
          {role?.promptCreate ? (
            <RHButton icon={'plus'} mode="contained" onPress={addCallback}>
              {translations('add')}
            </RHButton>
          ) : (
            <></>
          )}
        </View>
        <View style={[]}>
          {promptTypes.map((promptType: any) => (
            <PromptTypeList
              key={`prompt-type-${promptType}`}
              promptType={promptType}
              canEdit={role?.promptEdit}
              editCallback={editCallback}
              canDelete={role?.promptDelete}
              deleteCallback={deleteCallback}
            />
          ))}
        </View>
      </NestableScrollContainer>
    </>
  );
};

export default Prompts;
