import React, {useState} from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import PaperMenu from 'src/design-system/paper-menu';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

const InformationTooltip = ({value}: any) => {
  const styles = useStyle();
  const [visible, setVisible] = useState(false);
  const openToolTip = () => setVisible(true);
  const closeTooltip = () => setVisible(false);

  return (
    <PaperMenu
      visible={visible}
      onDismiss={closeTooltip}
      anchor={
        <TouchableOpacity
          onPress={openToolTip}
          style={[styles.justifyCenter, styles.alignCenter]}>
          <Icon
            name={'info'}
            size={15}
            style={[styles.marginBottom, styles.paddingBottom]}
          />
        </TouchableOpacity>
      }>
      <View style={[styles.padding]}>
        <Text style={Typography.P4}>{value}</Text>
      </View>
    </PaperMenu>
  );
};

export default InformationTooltip;
