import React, {useEffect, useState} from 'react';
import {Text, View} from 'react-native';
import * as Yup from 'yup';
import ListItemSeparator from 'src/common-components/separator';
import {Typography} from 'src/styles';
import {CollapsibleFilterItem, Filters} from 'src/design-system';
import {Separator} from 'src/common-components/atoms';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {Q} from '@nozbe/watermelondb';
import Form from 'src/hook-form/form';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import DateInput from 'src/hook-form/date-input';
import {endOfWeek, startOfWeek} from 'date-fns';
import ClientCheckbox from 'src/hook-form-inputs/client-checkbox';
import StaffCheckbox from 'src/hook-form-inputs/staff-checkbox';
import CptCheckbox from 'src/hook-form-inputs/cpt-checkbox';
import LocationCheckbox from 'src/hook-form-inputs/location-checkbox';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import StatusCheckbox from 'src/hook-form-inputs/status-checkbox';

const TimesheetFilterMenu = ({
  filters,
  applyFilters,
  patients = [],
  users = [],
  showStaff = true,
  assignedPatientsOnly = false,
  user = null,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [visible, setVisible] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: filters,
  });

  useEffect(() => {
    methods.reset(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const validationSchema = Yup.object({
    startDate: Yup.date().max(
      Yup.ref('endDate'),
      translations('start_date_end_date_validation'),
    ),
    endDate: Yup.date().min(
      Yup.ref('startDate'),
      translations('end_date_start_date_validation'),
    ),
  });

  const notificationNumberCount = () => {
    const selectedFilter = filters;
    let result = 0;
    for (const key of Object.keys(selectedFilter)) {
      if (Array.isArray(selectedFilter[key])) {
        result += selectedFilter[key].length;
      }
    }
    return result;
  };

  const filterSubmit = (newFilters: any) => {
    applyFilters(newFilters);
    setVisible(false);
  };

  const filterCancelled = () => {
    methods.reset();
    applyFilters({
      startDate: startOfWeek(new Date()),
      endDate: endOfWeek(new Date()),
      allTime: [],
      staff: [],
      clients: [],
      locations: [],
      types: [],
      status: [],
    });
    setVisible(false);
  };

  const closeMenu = () => {
    setVisible(false);
  };

  return (
    <Filters
      open={visible}
      setOpen={setVisible}
      notificationNumberCount={notificationNumberCount}
      onDismiss={closeMenu}>
      <FormProvider {...methods}>
        <Form
          handleSubmit={methods.handleSubmit(filterSubmit, filterCancelled)}
          cancelled={filterCancelled}
          initialValues={filters}
          validationSchema={validationSchema}
          cancelText={translations('reset')}
          saveText={translations('apply')}>
          <Text
            style={[
              Typography.P1_MEDIUM,
              styles.textColorPrimary,
              styles.marginSMBottom,
            ]}>
            Date Range
          </Text>
          <Separator height={10} />
          <View style={[styles.flex, styles.container]}>
            <Controller
              control={methods.control}
              rules={{}}
              render={({field: {onChange, value}}) => (
                <DateInput
                  label={translations('start_date')}
                  value={value}
                  onChange={onChange}
                  required={false}
                  style={styles.flex}
                />
              )}
              name="startDate"
            />
            <Separator width={10} />
            <Controller
              control={methods.control}
              rules={{}}
              render={({field: {onChange, value}}) => (
                <DateInput
                  label={translations('end_date')}
                  value={value}
                  onChange={onChange}
                  required={false}
                  style={styles.flex}
                />
              )}
              name="endDate"
            />
          </View>
          <Separator height={10} />
          <ListItemSeparator />
          {patients.length !== 0 ? (
            <>
              <CollapsibleFilterItem title={translations('client')}>
                <ClientCheckbox
                  allowSelectAll={true}
                  search={true}
                  assignedPatientsOnly={assignedPatientsOnly}
                  user={user}
                />
              </CollapsibleFilterItem>
              <ListItemSeparator />
            </>
          ) : (
            <></>
          )}
          {showStaff && users.length !== 0 ? (
            <>
              <CollapsibleFilterItem title={translations('staff')}>
                <StaffCheckbox allowSelectAll={true} search={true} />
              </CollapsibleFilterItem>
              <ListItemSeparator />
            </>
          ) : (
            <></>
          )}
          <CollapsibleFilterItem title={translations('cpt')}>
            <CptCheckbox allowSelectAll={true} search={true} />
          </CollapsibleFilterItem>
          <ListItemSeparator />
          <CollapsibleFilterItem title={translations('place_of_service')}>
            <LocationCheckbox allowSelectAll={true} search={true} />
          </CollapsibleFilterItem>
          <ListItemSeparator />
          <CollapsibleFilterItem title={translations('status')}>
            <StatusCheckbox allowSelectAll={false} search={false} />
          </CollapsibleFilterItem>
        </Form>
      </FormProvider>
    </Filters>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({database, role, profile, authentication}: any) => {
    return {
      patients: !role?.patientAssignedOnly
        ? database
            .get('patients')
            .query(
              Q.where('deleted_at', null),
              Q.where('_partition', authentication.selectedGroup),
            )
        : profile.patients,
      users: database
        .get('users')
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
        )
        .observe(),
    };
  }),
)(TimesheetFilterMenu);
