import React from 'react';
import JaspersoftDashboard from '../../components/jaspersoft-reports/index.web';
import {resourceUrls} from 'src/common-utils/resourceUrls';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';

const JaspersoftProgramReport = ({
  program,
  startDate,
  endDate,
  patient,
  objectiveEnabled,
  formatDisplay,
}: any) => {
  const objective =
    program?.objective && objectiveEnabled ? program?.objective : '';

  return (
    <JaspersoftDashboard
      resource={
        // program?.type === 'behavior' && !program?.intensity
        //   ? resourceUrls[`${program?.method}_no_intensity`]
        //   : resourceUrls[program?.method]
        formatDisplay
          ? resourceUrls[`${program?.method}_per_session`]
          : resourceUrls[program?.method]
      }
      params={{
        start_datetime_1_1: [startDate],
        start_datetime_1_2: [endDate],
        program_id_1: [program?.id],
        name_1: [program?.name],
        objective: [objective],
        first_name_1: [patient?.firstName],
        last_name_1: [patient?.lastName],
      }}
      container={'program'}
    />
  );
};

export default React.memo(
  compose(
    withDatabase,
    withState,
    withObservables(['programId'], ({programId, database}: any) => ({
      program: database.get('programs').findAndObserve(programId),
    })),
    withObservables(['program'], ({program}: any) => ({
      patient: program.patient,
    })),
  )(JaspersoftProgramReport),
);
