import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import {Colors, Typography} from 'src/styles';

interface Props {
  items: any[];
  selected: number;
  setSelected: (select: number) => void;
  style?: any;
  value?: string;
}

const NumberPicker = ({
  items = [],
  value,
  selected = 0,
  setSelected,
  style,
}: Props) => {
  return (
    <View style={style}>
      <Picker
        style={styles.picker}
        itemStyle={styles.item}
        selectedValue={selected}
        onValueChange={itemValue => {
          setSelected(parseInt(itemValue, 10));
        }}>
        {items.map(item => (
          <Picker.Item
            label={item.toString()}
            value={parseInt(item, 10)}
            key={`${value}-${item}`}
          />
        ))}
      </Picker>
    </View>
  );
};

const styles = StyleSheet.create({
  picker: {
    borderColor: Colors.PRIMARY_300,
    borderRadius: 4,
    borderWidth: 1,
    backgroundColor: 'transparent',
    color: Colors.TEXT_PRIMARY,
    paddingVertical: 13,
    paddingLeft: 10,
    ...Typography.P2_MEDIUM,
  },
  item: {
    ...Typography.P2_MEDIUM,
  },
});

export default NumberPicker;
