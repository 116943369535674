export const relationshipList = [
  {value: '01', label: '01 - Spouse'},
  {value: '04', label: '04 - Grandfather or Grandmother'},
  {value: '05', label: '05 - Grandson or Granddaughter'},
  {value: '07', label: '07 - Nephew or Niece'},
  {value: '10', label: '10 - Foster Child'},
  {value: '15', label: '15 - Ward of the Court'},
  {value: '17', label: '17 - Stepson or Stepdaughter'},
  {value: '18', label: '18 - Self'},
  {value: '19', label: '19 - Child'},
  {value: '20', label: '20 - Employee'},
  {value: '21', label: '21 - Unknown'},
  {value: '22', label: '22 - Handicapped/Dependent'},
  {value: '23', label: '23 - Sponsored Dependent'},
  {value: '24', label: '24 - Dependent of Minor Dependent'},
  // {value: '26', label: '26 - Guardian'},
  {value: '29', label: '29 - Significant Other'},
  // {
  //   value: '30',
  //   label:
  //     '30 - Both Parents - The legal custody of the student is with both parents',
  // },
  // {value: '31', label: '31 - Court Appointed Guardian'},
  {value: '32', label: '32 - Mother'},
  {value: '33', label: '33 - Father'},
  {value: '36', label: '36 - Emancipated Minor'},
  {value: '39', label: '39 - Organ Donor'},
  {value: '40', label: '40 - Cadaver Donor'},
  {value: '41', label: '41 - Injured Plaintiff'},
  {
    value: '43',
    label: '43 - Child Where Insured Has No Financial Responsibility',
  },
  {value: '53', label: '53 - Life Partner'},
  {value: 'G8', label: 'G8 - Other Relationship'},
];
