import {immutableRelation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class CareTeamParticipant extends Base {
  static table = 'care_team_participants';
  static associations = {
    users: {type: 'belongs_to', key: 'user_id'},
    patients: {type: 'belongs_to', key: 'patient_id'},
  };

  @immutableRelation('users', 'user_id') user;
  @immutableRelation('patients', 'patient_id') patient;

  @text('patient_id') patientId;
}
