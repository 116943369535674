import React, {useEffect, useReducer, useState} from 'react';
import {Colors, Typography} from 'src/styles';
import {Text, TouchableOpacity, View, Pressable} from 'react-native';
import _ from 'lodash';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {useStopwatch} from 'react-timer-hook';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {Set} from 'src/models';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import ProgramMenu from 'src/design-system/program-menu';
import ProgramInstructionModal from '../program-instruction-modal';
import {Modal} from 'src/design-system';
import ProgramProgress from 'src/modules/programs/components/program-progress-legacy';
import {useStyle} from 'src/providers/style';
import BehaviorABC from '../behavior-abc';
import DurationTotalEdit from './total-edit';
import BehaviorProgramTooltip from '../behavior-program-tooltip';
import DurationTable from './table';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';
import {useTranslations} from 'src/providers/translation';

const intensityArray = [
  {title: 'Mild', value: 'mild'},
  {title: 'Moderate', value: 'moderate'},
  {title: 'Severe', value: 'severe'},
];

const Duration = ({
  program,
  session,
  sets,
  programPrompts,
  currentSet,
}: any) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const database = useDatabase();
  const styles = useStyle();
  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const [expandABCView, setExpandABCView] = useState(false);
  const [intensity, setIntensity] = useState<string>('mild');
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [events, setEvents] = useState('');
  const [instanceNumber, setInstance] = useState('');
  const [rowEdit, setRow] = useState('');
  const translations = useTranslations();
  const {seconds, minutes, hours, isRunning, start, pause, reset} =
    useStopwatch({
      autoStart: false,
    });

  const prompts = _.groupBy(programPrompts, 'promptType') || [];

  useEffect(() => {
    if (sets && sets?.length > 0) {
      let discrepancy = 0;
      for (const set of sets) {
        discrepancy += (set.endTimestamp ?? new Date()) - set.startTimestamp;
      }
      const set = sets[0];
      const stopwatchOffset = new Date();
      if (
        set.startTimestamp !== null &&
        (set.endTimestamp === null || set.endTimestamp === undefined)
      ) {
        stopwatchOffset.setSeconds(
          stopwatchOffset.getSeconds() + discrepancy / 1000,
        );
        reset(stopwatchOffset);
      } else if (set.startTimestamp !== null && set.endTimestamp !== null) {
        stopwatchOffset.setSeconds(
          stopwatchOffset.getSeconds() + discrepancy / 1000,
        );
        reset(stopwatchOffset);
        pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sets?.length]);

  useEffect(() => {
    if (
      currentSet.startTimestamp === null ||
      currentSet.endTimestamp !== null
    ) {
      pause();
    } else {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSet.id,
    currentSet.startTimestamp,
    currentSet.endTimestamp,
    currentSet.updatedAt,
  ]);

  const onValue = async () => {
    if (
      currentSet === undefined ||
      currentSet?.startTimestamp === null ||
      currentSet?.endTimestamp !== null
    ) {
      await database?.write(async () => {
        return await database?.get(Set.table).create(entity => {
          entity.partition = selectedGroup;
          entity.session.id = session.id;
          entity.program.id = program.id;
          entity.startTimestamp = new Date();
          entity.endTimestamp = null;
          entity.intensity = program?.intensity ? 'mild' : '';
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    } else {
      currentSet.endSet();
      setExpandABCView(true);
    }
    setIntensity('mild');
    forceUpdate();
  };

  const checkPrompt = (id: any) => {
    const index = [...currentSet?.prompts].findIndex((v: any) => {
      return v === id;
    });

    if (index === -1) {
      const values = [...currentSet?.prompts];
      values.push(id);
      currentSet.updateEntity({
        prompts: values,
      });
    } else {
      const values = [...currentSet?.prompts];
      values.splice(index, 1);
      currentSet.updateEntity({
        prompts: values,
      });
    }
    forceUpdate();
  };

  const undo = async () => {
    if (currentSet.endTimestamp !== null) {
      currentSet.updateEntity({
        endTimestamp: null,
      });
      let discrepancy = 0;
      const stopwatchOffset = new Date();
      discrepancy += new Date() - currentSet.startTimestamp;
      stopwatchOffset.setSeconds(
        stopwatchOffset.getSeconds() + discrepancy / 1000,
      );
      reset(stopwatchOffset);
      start();
      setExpandABCView(false);
    } else {
      if (currentSet.startTimestamp !== null) {
        if (sets?.length > 1) {
          const previousSetIntensity = sets[1]?.intensity;
          setIntensity(previousSetIntensity);
        } else {
          setIntensity('mild');
        }
        currentSet.delete();
        reset();
      }
    }
  };

  const leadingZero = (value: number) => {
    return /^\d$/.test(value) ? '0' + value : value;
  };

  const showPrompts = () => {
    if (sets.length > 0) {
      setExpandABCView(!expandABCView);
    }
  };

  const changeIntensity = (val: string) => {
    setIntensity(val);
    currentSet.updateEntity({
      intensity: val,
    });
  };

  const viewInstructions = () => {
    setInstructionsModal(!instructionsModal);
  };
  const viewHistory = () => {
    setHistoryModal(!historyModal);
  };
  const viewData = () => {
    setDataModal(!dataModal);
  };

  return (
    <View style={[styles.flex]}>
      <View
        style={[
          styles.borderRadius,
          styles.border,
          styles.borderColorGray,
          styles.marginLBottom,
          styles.backgroundColorGray,
          styles.width300,
        ]}>
        <Pressable onPress={showPrompts}>
          <View style={[styles.padding]}>
            <View style={[styles.row]}>
              <View style={[styles.flex]}>
                <View
                  style={[
                    styles.row,
                    styles.alignCenter,
                    styles.marginSMBottom,
                  ]}>
                  <BehaviorProgramTooltip name={program?.name} />
                  {sets.length > 0 ? (
                    <MaterialCommunityIcons
                      name={'undo'}
                      size={22}
                      color={Colors.RAVEN_WHITE}
                      onPress={undo}
                      style={[styles.marginLLeft]}
                    />
                  ) : (
                    <></>
                  )}
                </View>
                <Text style={[Typography.CAPTION, styles.textColorSecondary]}>
                  Duration
                </Text>
              </View>
              <ProgramMenu
                viewInstructions={viewInstructions}
                viewHistory={viewHistory}
                viewData={viewData}
                color={Colors.RAVEN_WHITE}
              />
            </View>
            <View style={[styles.column, styles.marginTop, styles.zIndex]}>
              <View
                style={[styles.row, styles.justifyStart, styles.alignCenter]}>
                <TouchableOpacity
                  style={[styles.alignCenter]}
                  onPress={onValue}>
                  <View
                    style={[
                      styles.width40,
                      styles.height40,
                      styles.borderRadius20,
                      styles.alignCenter,
                      styles.justifyCenter,
                      styles.backgroundColorBlack,
                      styles.border,
                      !(sets?.length === 0)
                        ? styles.borderColorBlue
                        : styles.borderColorWhite,
                    ]}>
                    {sets?.length === 0 ? (
                      <MaterialIcon
                        name="timer"
                        size={22}
                        color={Colors.RAVEN_WHITE}
                      />
                    ) : (
                      <Ionicons
                        name={isRunning ? 'stop' : 'play'}
                        size={22}
                        color={Colors.RAVEN_WHITE}
                      />
                    )}
                  </View>
                </TouchableOpacity>
                {!(sets?.length === 0) ? (
                  <Text
                    style={[
                      Typography.P2,
                      styles.textColorWhite,
                      styles.paddingLHorizontal,
                    ]}>
                    {leadingZero(hours)}:{leadingZero(minutes)}:
                    {leadingZero(seconds)}
                  </Text>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
        </Pressable>
        <BehaviorABC
          program={program}
          expandABCView={expandABCView}
          setExpandABCView={setExpandABCView}
          intensity={intensity}
          changeIntensity={changeIntensity}
          prompts={prompts}
          checkPrompt={checkPrompt}
          currentSet={currentSet}
        />
      </View>
      {instructionsModal ? (
        <ProgramInstructionModal
          show={[instructionsModal, setInstructionsModal]}
          program={program}
        />
      ) : null}
      {historyModal ? (
        <Modal
          show={[historyModal, setHistoryModal]}
          title={'Progress'}
          footer={
            <InformationTooltip
              value={translations('program_progress_month')}
            />
          }>
          <ProgramProgress program={program} />
        </Modal>
      ) : null}
      {dataModal ? (
        <Modal show={[dataModal, setDataModal]} title={`${program.name} Data`}>
          <DurationTable
            type={'edit'}
            setEvents={chosenSet => {
              setEvents(chosenSet);
            }}
            setRow={r => {
              setRow(r);
            }}
            setInstance={idx => {
              setInstance(idx);
            }}
            program={program}
            sets={sets.sort((a: Set, b: Set) => a?.createdAt - b?.createdAt)}
          />
          {events ? (
            <DurationTotalEdit
              setEvents={show => {
                setEvents(show);
              }}
              row={rowEdit}
              intensityArray={intensityArray}
              instance={instanceNumber}
              set={events}
              prompts={prompts}
              session={session}
              program={program}
            />
          ) : (
            <></>
          )}
        </Modal>
      ) : null}
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({session, program, database}: any) => ({
    sets: database
      .get('sets')
      .query(
        Q.and(
          Q.where('program_id', program.id),
          Q.where('session_id', session.id),
          Q.where('deleted_at', null),
        ),
        Q.sortBy('created_at', Q.desc),
      ),
    programPrompts: program?.prompts,
  })),
  withObservables(['sets'], ({sets}: any) => {
    if (sets.length !== 0) {
      return {
        currentSet: sets?.[0],
      };
    }
    return {
      currentSet: of({
        startTimestamp: null,
        endTimestamp: null,
      }),
    };
  }),
)(Duration);
