import React from 'react';
import {DataItem, SelectInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';

export const modifierList: any[] = [
  {label: "HM - Less than a bachelor's degree", value: 'HM'},
  {label: "HN - Bachelor's Degree Level", value: 'HN'},
  {label: "HO - Master's Degree Level", value: 'HO'},
  {label: 'HP - Psychologist or Doctorate Level', value: 'HP'},
];

interface DisplayProps {
  value: string;
}

interface InputProps {
  valueKey: string;
}

export const Import = (
  key: string = 'modifier',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Modifier (${modifierList.map(value => value.label).join(', ')})`,
  key,
  type: 'select',
  selectOptions: modifierList,
});

const resolveDisplayValue = (value: string) => {
  return modifierList.find(item => item.value === value)?.label;
};

const ModifierDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Modifier</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const ModifierInput = ({valueKey}: InputProps) => {
  return (
    <SelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'modifier'}
      name={'Modifier'}
      required={false}
      items={modifierList}
    />
  );
};

export const validation = () => Yup.string().required('Modifier is required');

export const Display = ModifierDisplay;
export const Input = ModifierInput;
export const Value = resolveDisplayValue;

export default ModifierInput;
