import React, {useState} from 'react';
import {View} from 'react-native';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import * as Yup from 'yup';

import CheckboxArrayInput from 'src/design-system/checkbox-array-input';
import ListItemSeparator from 'src/common-components/separator';
import {Form, Filters} from 'src/design-system';
import withState from 'src/redux/wrapper';
import {useTranslations} from 'src/providers/translation';

export const programTypeArray = [
  {value: 'skill', title: 'Skill Acquisition'},
  {value: 'behavior', title: 'Behavior'},
];

export const programStatusArray = [
  {value: 'inprogress', title: 'In Progress'},
  {value: 'onhold', title: 'On Hold'},
  {value: 'completed', title: 'Completed'},
  {value: 'discontinued', title: 'Discontinued'},
];

export const programStateArray = [{value: 'archived', title: 'Archived'}];

const ProgramFilterMenu = ({applyFilters, tags, filter}: any) => {
  const translations = useTranslations();

  const tagTypes = ['Assessments', 'Skills', 'Behaviors', 'Related Services'];
  const validationSchema = Yup.object({});
  const initialValues = filter;

  const [visible, setVisible] = useState<boolean>(false);

  const notificationNumberCount = () => {
    let result = 0;
    if (filter === undefined) {
      return 0;
    }
    for (const key in filter) {
      result += filter[key].length;
    }
    return result;
  };

  const filterSubmit = (payload: any) => {
    applyFilters(payload);
    setVisible(false);
  };

  const filterCancelled = (formik: any) => {
    formik.resetForm();
    applyFilters({
      programType: [],
      programStatus: [],
      programState: [],
      assessments: [],
      skills: [],
      behaviors: [],
      relatedServices: [],
    });
    setVisible(false);
  };

  const closeMenu = () => {
    setVisible(false);
  };
  return (
    <Filters
      open={visible}
      setOpen={setVisible}
      notificationNumberCount={notificationNumberCount}
      onDismiss={closeMenu}>
      <Form
        handleSubmit={filterSubmit}
        cancelled={filterCancelled}
        initialValues={initialValues}
        validationSchema={validationSchema}
        cancelText={translations('reset')}
        saveText={translations('apply')}>
        <CheckboxArrayInput
          valueKey={'programType'}
          title={translations('program_type')}
          items={programTypeArray}
        />
        <ListItemSeparator />
        <CheckboxArrayInput
          valueKey={'programState'}
          title={translations('state')}
          items={programStateArray}
        />
        {tagTypes.map((tagType: string) => {
          const tagsByType = (tags || [])
            .filter((tag: any) => tag?.tagType === tagType)
            .map((tag: any) => ({
              value: tag.id,
              title: tag.name,
            }));
          if (tagsByType?.length > 0) {
            return (
              <View key={tagType}>
                <ListItemSeparator />
                <CheckboxArrayInput
                  valueKey={
                    tagType === 'Related Services'
                      ? 'relatedServices'
                      : tagType.toLowerCase()
                  }
                  title={tagType}
                  items={tagsByType}
                />
              </View>
            );
          } else {
            return <View key={tagType} />;
          }
        })}
      </Form>
    </Filters>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('rank', Q.asc),
      ),
  })),
)(ProgramFilterMenu);
