import React from 'react';
import {Text, View} from 'react-native';
import {HelperText, RadioButton} from 'react-native-paper';
import {FastField} from 'formik';
import {Colors, Typography} from 'src/styles';
import EStyleSheet from 'react-native-extended-stylesheet';
import {AppointmentRepeatOccurrences, Date} from 'src/form-inputs';

const RepeatEndModeInput = () => {
  const styles = getStyles();
  return (
    <FastField name="repeatEndMode">
      {({field, form: {setFieldValue}, meta: {error, touched}}: any) => {
        return (
          <>
            <Text
              style={[
                styles.label,
                {
                  color: error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY,
                },
              ]}>
              Ends*
            </Text>
            <RadioButton.Group
              onValueChange={field.onChange(field.name)}
              value={field.value}>
              <View style={styles.radioButton}>
                <RadioButton.Android
                  value="never"
                  color={error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY}
                  uncheckedColor={
                    error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY
                  }
                />
                <Text
                  style={{
                    ...Typography.INPUT,
                    color:
                      error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY,
                  }}
                  onPress={() => setFieldValue(field.name, 'never')}>
                  Never
                </Text>
              </View>

              <View style={styles.radioContainer}>
                <View style={styles.radioButton}>
                  <RadioButton.Android
                    value="on"
                    color={
                      error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY
                    }
                    uncheckedColor={
                      error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY
                    }
                  />
                  <Text
                    style={{
                      ...Typography.INPUT,
                      color:
                        error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY,
                    }}
                    onPress={() => setFieldValue(field.name, 'on')}>
                    On
                  </Text>
                </View>
                {field.value === 'on' && <Date.Input valueKey="repeatUntil" />}
              </View>

              <View style={styles.radioContainer}>
                <View style={styles.radioButton}>
                  <RadioButton.Android
                    value="after"
                    color={
                      error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY
                    }
                    uncheckedColor={
                      error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY
                    }
                  />
                  <Text
                    style={{
                      ...Typography.INPUT,
                      color:
                        error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY,
                    }}
                    onPress={() => setFieldValue(field.name, 'after')}>
                    After
                  </Text>
                </View>

                {field.value === 'after' && (
                  <AppointmentRepeatOccurrences.Input />
                )}
              </View>
            </RadioButton.Group>
            <HelperText type="error" visible={true} style={styles.helper}>
              {error && touched && 'ⓧ ' + error}
            </HelperText>
          </>
        );
      }}
    </FastField>
  );
};

const getStyles = () =>
  EStyleSheet.create({
    radioButton: {
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 200,
      height: 40,
    },
    label: {
      ...Typography.LABEL,
      color: Colors.TEXT_SECONDARY,
      paddingBottom: 10,
    },
    radioContainer: {
      flexDirection: 'row',
      marginVertical: 10,
    },
    '@media (max-width: 768) and (orientation: portrait)': {
      radioContainer: {
        flexDirection: 'column',
        marginVertical: 10,
      },
    },
    helper: {},
  });

export const Input = RepeatEndModeInput;

export default RepeatEndModeInput;
