import React from 'react';
import {SafeAreaView, ScrollView, View} from 'react-native';
import {Screen} from 'react-native-screens';
import Spinner from 'react-native-loading-spinner-overlay';
import {Error, Separator, Success} from 'src/common-components/atoms';
import SVG from 'src/styles/svg';
import {useStyle} from 'src/providers/style';

interface BaseAuthScreenProps {
  children?: any;
  loading?: boolean;
  toast?: any;
}

const BaseAuthScreen = ({
  children,
  loading = false,
  toast = null,
}: BaseAuthScreenProps) => {
  const styles = useStyle();

  return (
    <Screen
      style={[
        styles.flex,
        styles.width,
        styles.height,
        styles.backgroundColorWhite,
        styles.column,
        styles.alignCenter,
        styles.justifyCenter,
      ]}>
      <SafeAreaView
        style={[
          styles.flex,
          styles.width,
          styles.height,
          styles.backgroundColorWhite,
          styles.column,
          styles.alignCenter,
          styles.justifyCenter,
        ]}>
        <Spinner visible={loading} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={[styles.width, styles.maxWidth540]}
          contentContainerStyle={[styles.width, styles.maxWidth540]}
          bounces={false}>
          <View
            style={[
              styles.borderRadius,
              styles.column,
              styles.paddingHorizontal,
              styles.paddingVertical,
              styles.width,
              styles.maxWidth540,
            ]}>
            <View
              style={[
                styles.width,
                styles.alignCenter,
                styles.justifyCenter,
                styles.paddingBottom,
              ]}>
              <SVG height={45} name={'logo'} />
            </View>
            {toast && toast.success ? (
              <Success title={toast.title} message={toast.message} />
            ) : toast && !toast.success ? (
              <Error title={toast.title} message={toast.message} />
            ) : (
              <></>
            )}
            {toast ? <Separator height={20} /> : <></>}
            {children}
          </View>
        </ScrollView>
      </SafeAreaView>
    </Screen>
  );
};

export default BaseAuthScreen;
