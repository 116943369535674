import React, {useEffect, useState} from 'react';
import BaseAuthScreen from 'src/modules/authentication/screens/base-auth-screen';
import {ResetPasswordForm} from 'src/modules/authentication/components';
import {BASE_AUTH_URL} from 'react-native-dotenv';
import {useTokenMutation} from 'src/services/auth';

const ResetPasswordScreen = ({navigation, route}: any) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState<null | any>(null);

  const [token] = useTokenMutation();

  useEffect(() => {
    let unmounted = false;
    if (unmounted) {
      return;
    }
    if (route.params && route.params.code) {
      setCode(route.params.code);
    }
    return () => {
      unmounted = true;
    };
  }, [route.params]);

  const handleSubmit = async (payload: any, formik: any) => {
    setLoading(true);
    try {
      const uri = `${BASE_AUTH_URL}/reset-password`;
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Transfer-Encoding': 'chunked',
        },
        body: JSON.stringify({
          code,
          password: payload.password,
          passwordConfirmation: payload.password,
        }),
      };

      let response = await (await fetch(uri, config)).json();

      if (response.success) {
        const formData = new FormData();
        formData.append('username', response.email);
        formData.append('password', payload.password);
        formData.append('grant_type', 'password');
        token(formData).then(() => {
          setLoading(false);
          formik.resetForm();
          setToast(null);
          navigation.navigate('OrganizationSelection');
        });
      } else {
        switch (response.status) {
          case 422:
            setToast({
              success: false,
              title: 'An error has occurred.',
              message:
                'The code you are using is more than two days old or has been consumed already. Please request a new code.',
            });
            break;
          case 500:
            setToast({
              success: false,
              title: 'An error has occurred.',
              message:
                'Please use a different password than your currently set password.',
            });
            break;
        }
      }
    } catch (err) {
      setToast({
        success: false,
        title: 'An error has occurred.',
        message: 'Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const navigateLogin = () => {
    navigation.navigate('Login');
  };

  return (
    <BaseAuthScreen loading={loading} toast={toast}>
      <ResetPasswordForm handleSubmit={handleSubmit} login={navigateLogin} />
    </BaseAuthScreen>
  );
};

export default ResetPasswordScreen;
