import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {useTranslations} from 'src/providers/translation';
import HookFormRadioInput from 'src/hook-form-wrapper/radio-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const intervalTypes = [
  {
    value: 1,
    label: 'Whole Interval',
    title: 'Whole Interval',
  },
  {
    value: 2,
    label: 'Partial Interval',
    title: 'Partial Interval',
  },
  {
    value: 3,
    label: 'Momentary Interval',
    title: 'Momentary Interval',
  },
];

export const Import = (
  key: string = 'interval',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Interval Type',
  key,
  type: 'select',
  selectOptions: intervalTypes.map(item => ({
    ...item,
    value: item.value.toString(),
  })),
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return intervalTypes.find(item => item.value.toString() === value)?.label;
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('interval_type')}</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  const translations = useTranslations();

  return (
    <HookFormRadioInput
      name={(name ? name + '.' : '') + 'interval'}
      label={translations('interval_type')}
      required={false}
      items={intervalTypes}
    />
  );
};

export const validation = () =>
  Yup.string().required('Interval Type are required');

export default Input;
