import React, {useState} from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import PaperMenu from 'src/design-system/paper-menu';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import moment from 'moment';
import {of} from 'rxjs';
import _ from 'lodash';
import {Typography, Colors} from 'src/styles';
import {Prompt} from 'src/models';
import IconMaterial from 'react-native-vector-icons/MaterialCommunityIcons';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import PromptsDisplay from '../prompts-display';

const IntervalTableTooltip = ({
  setEditEvents,
  setInstance,
  setEditModule,
  edit = '',
  promptItems,
  set,
  instance,
  intensity,
  event,
  collector,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const promptTypes = _.groupBy(promptItems, 'promptType');
  const [visible, setVisible] = useState(false);
  const openToolTip = () => setVisible(true);
  const closeTooltip = () => setVisible(false);
  const duration = moment.duration(
    moment(set?.endTimestamp).diff(moment(set?.startTimestamp)),
  );
  const hour = duration.hours();
  const min = duration.minutes();
  const sec = duration.seconds();

  if (edit === 'intensity') {
    return (
      <View>
        {event.value === 'Y' ? (
          <TouchableOpacity
            onPress={() => {
              setEditEvents(event);
              setInstance(instance);
              setEditModule('intensity');
            }}
            style={[styles.justifyCenter, styles.alignCenter]}>
            <View
              style={[styles.column, styles.alignCenter, styles.justifyCenter]}>
              <Text style={styles.cellBoldTxt}>
                {_.capitalize(event?.intensity ? event?.intensity : '-')}
              </Text>
            </View>
          </TouchableOpacity>
        ) : (
          <View style={[styles.justifyCenter, styles.alignCenter]}>
            <View
              style={[styles.column, styles.alignCenter, styles.justifyCenter]}>
              <Text style={styles.cellBoldTxt}>
                {_.capitalize(event?.intensity ? event?.intensity : '-')}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  } else if (edit === 'occurence') {
    return (
      <TouchableOpacity
        onPress={() => {
          setEditEvents(event);
          setInstance(instance);
          setEditModule('occurence');
        }}
        style={[styles.justifyCenter, styles.alignCenter]}>
        <View style={[styles.column, styles.alignCenter, styles.justifyCenter]}>
          <View style={styles.valuePadding}>
            {event?.value === 'Y' ? (
              <IconMaterial
                name="check"
                size={18}
                color={Colors.TERTIARY_SPRING_GREEN}
              />
            ) : event?.value === 'N' ? (
              <IconMaterial
                name="close"
                size={18}
                color={Colors.ELECTRIC_SALMON}
              />
            ) : event?.value === '-' ? (
              <IconMaterial
                name="minus"
                size={12}
                color={Colors.TEXT_SECONDARY}
              />
            ) : (
              <></>
            )}
          </View>
          {event?.prompts?.length ? (
            <PromptsDisplay prompts={event?.prompts} />
          ) : (
            <></>
          )}
        </View>
      </TouchableOpacity>
    );
  } else if (edit === 'duration') {
    return (
      <TouchableOpacity
        onPress={() => {
          setEditEvents(event);
          setInstance(instance);
          setEditModule(edit);
        }}
        style={[styles.justifyCenter, styles.alignCenter]}>
        <View style={[styles.column, styles.alignCenter, styles.justifyCenter]}>
          <View style={styles.valuePadding}>
            <Text>
              {hour > 0 && hour + ' hr '}
              {min > 0 && min + ' min '}
              {sec > 0 && sec + ' sec'}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  } else {
    return (
      <PaperMenu
        visible={visible}
        onDismiss={closeTooltip}
        anchor={
          <TouchableOpacity
            onPress={openToolTip}
            style={[styles.justifyCenter, styles.alignCenter]}>
            <Text style={[Typography.CAPTION_MEDIUM]}>
              {moment(set?.startTimestamp).format('ll')}
            </Text>
          </TouchableOpacity>
        }>
        <View style={styles.paddingM}>
          <Text style={[Typography.CAPTION_MEDIUM]}>
            {translations('date')}
          </Text>
          <Text style={Typography.CAPTION}>
            {moment(set?.startTimestamp).format('LL')}
          </Text>

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
            {translations('instance')}
          </Text>
          <Text style={[Typography.CAPTION]}>{instance}</Text>

          {collector && (
            <>
              <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
                {translations('collector')}
              </Text>
              <Text
                style={[
                  Typography.CAPTION,
                ]}>{`${collector?.firstName} ${collector?.lastName}`}</Text>
            </>
          )}

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
            {translations('start_time')}
          </Text>
          <Text style={[Typography.CAPTION]}>
            {moment(set?.startTimestamp).format('hh:mm:ss A')}
          </Text>

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
            {translations('end_time')}
          </Text>
          <Text style={[Typography.CAPTION]}>
            {moment(set?.endTimestamp).format('hh:mm:ss A')}
          </Text>

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
            {translations('behavior_occurrence')}
          </Text>
          <Text style={[Typography.CAPTION]}>
            {event?.value === 'Y' ? 'Yes' : event?.value === 'N' ? 'No' : ''}
          </Text>

          {intensity !== '' && (
            <>
              <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
                {translations('intensity')}
              </Text>
              <Text style={[Typography.CAPTION]}>{intensity}</Text>
            </>
          )}
          {promptItems?.length > 0 ? (
            <>
              <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
                {translations('abc')}
              </Text>
              {Object.keys(promptTypes).map((promptType: string) => {
                return (
                  <View key={`promptType-key-${promptType}`}>
                    <Text
                      style={[
                        Typography.CAPTION_MEDIUM,
                        styles.marginSMBottom,
                      ]}>
                      {promptType}
                    </Text>
                    <View>
                      {promptTypes[promptType].map((prompt: any) => (
                        <Text
                          style={[Typography.CAPTION]}
                          key={`prompt-${prompt?.id}`}>
                          {prompt?.name}
                        </Text>
                      ))}
                    </View>
                  </View>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </View>
      </PaperMenu>
    );
  }
};

export default compose(
  withDatabase,
  withObservables([], ({database, event}: any) => {
    const collector =
      event?.createdBy &&
      event?.createdBy !== '' &&
      event?.createdBy !== 'System'
        ? database.get('users').findAndObserve(event.createdBy)
        : of(undefined);
    return {
      promptItems: event?.prompts
        ? database
            .get(Prompt.table)
            .query(Q.where('id', Q.oneOf(event?.prompts)))
        : of([]),
      collector,
    };
  }),
)(IntervalTableTooltip);
