import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {useTranslations} from 'src/providers/translation';
import HookFormRadioInput from 'src/hook-form-wrapper/radio-input';

interface DisplayProps {
  value: string;
  month?: any;
  day?: any;
}

interface Props {
  name?: string;
}

const cadenceArray = [
  {label: 'Daily', value: 'daily'},
  {label: 'Weekly', value: 'weekly'},
  {label: 'Monthly', value: 'monthly'},
];

const weeklyArray = [
  {value: 'Sunday', label: 'Sunday'},
  {value: 'Monday', label: 'Monday'},
  {value: 'Tuesday', label: 'Tuesday'},
  {value: 'Wednesday', label: 'Wednesday'},
  {value: 'Thursday', label: 'Thursday'},
  {value: 'Friday', label: 'Friday'},
  {value: 'Saturday', label: 'Saturday'},
];

export const Import = (
  key: string = 'maintenanceCadence',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Cadence',
  key,
  type: 'select',
  selectOptions: cadenceArray.map(item => ({
    ...item,
    value: item.value.toString(),
  })),
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return cadenceArray.find(item => item.value.toString() === value)?.label;
};
export const Day = (value: string) => {
  return weeklyArray.find(item => item.value.toString() === value)?.label;
};

export const Display = ({value, day}: DisplayProps) => {
  const translations = useTranslations();
  const maintenanceCadence =
    value === 'weekly'
      ? `${Value(value)}, starting ${Day(day)}`
      : value === 'monthly'
      ? `${Value(value)}`
      : Value(value);
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('cadence')}</Text>
      <Text style={[Typography.P3]}>{maintenanceCadence}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  return (
    <HookFormRadioInput
      name={(name ? name + '.' : '') + 'maintenanceCadence'}
      label={''}
      required={false}
      items={cadenceArray}
    />
  );
};

export const validation = () => Yup.string().required('Cadence is required');

export default Input;
