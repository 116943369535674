import React from 'react';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';

const DataItem = ({children}: any) => {
  const styles = useStyle();

  return <View style={[styles.paddingLVertical]}>{children}</View>;
};

export default DataItem;
