import {Text, TouchableOpacity, View} from 'react-native';
import {HelperText} from 'react-native-paper';
import React from 'react';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';

interface Props {
  rules?: any;
  name: string;
  label: string;
  required?: boolean;
  defaultValue?: string;
  helper?: string;
  items: {value: string; label: string}[];
  onChange: (value: string[]) => void;
  value: string[];
  error?: string;
  touched?: boolean;
}
const ToggleInput = ({
  label,
  required,
  items,
  onChange,
  value,
  error,
  touched,
}: Props) => {
  const styles = useStyle();

  return (
    <>
      <Text
        style={[
          Typography.LABEL,
          styles.textColorSecondary,
          styles.marginSMBottom,
        ]}>
        {label + (required ? '*' : '')}
      </Text>
      <View style={[styles.row, styles.flexWrap]}>
        {items.map(item => (
          <TouchableOpacity
            key={`toggle-input-${item?.value}`}
            onPress={() => {
              if (value.includes(item.value)) {
                onChange(
                  value.filter((valueItem: string) => valueItem !== item.value),
                );
              } else {
                onChange([...value, item.value]);
              }
            }}
            style={[
              styles.iconButton,
              styles.border,
              styles.borderPrimary,
              value?.includes(item.value)
                ? styles.backgroundColorPrimary900
                : {},
              styles.marginSMVertical,
              styles.marginSMRight,
            ]}>
            <Text
              key={`byweekday-key-${item.value}`}
              style={[
                value?.includes(item.value)
                  ? styles.textColorWhite
                  : styles.textColorPrimary,
              ]}>
              {item.label}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      <HelperText type="error" visible={true}>
        {error && touched && 'ⓧ ' + error}
      </HelperText>
    </>
  );
};

export default ToggleInput;
