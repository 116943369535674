import {date, json, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Insurance extends Base {
  static table = 'insurances';
  static associations = {
    patients: {type: 'belongs_to', key: 'patient_id'},
    payers: {type: 'belongs_to', key: 'payer_id'},
    payer_plans: {type: 'belongs_to', key: 'payer_plan_id'},
  };

  @relation('patients', 'patient_id') patient;
  @relation('payers', 'payer_id') payer;
  @relation('payer_plans', 'plan_id') plan;

  @text('member_id') memberId;
  @text('group_number') groupNumber;
  @text('patient_id') patientId;
  @text('payer_id') payerId;
  @text('plan_id') planId;
  @text('authorization_id') authorizationId;
  @date('start_date') startDate;
  @date('end_date') endDate;
  @text('rank') rank;
  @json('subscriber', value => value) subscriber;
}
