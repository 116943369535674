import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import FormInput from 'src/hook-form-wrapper/form-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'name',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Name',
  key,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('name')}</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  const translations = useTranslations();
  return (
    <FormInput
      name={(name ? name + '.' : '') + 'name'}
      label={translations('name')}
      placeholder={translations('name')}
      helper={translations('program_description')}
      required={false}
    />
  );
};

export const validation = () =>
  Yup.string().required('Program name is required');

export default Input;
