import React from 'react';
import {Text} from 'react-native';
import HookFormDateInput from 'src/hook-form-wrapper/date-input';
import {Colors, Typography} from 'src/styles';
import {
  InputProps,
  OutputProps,
  PreviewProps,
} from 'src/modules/form-builder/utils/types';
import {DatePickerInput} from 'react-native-paper-dates';
import moment from 'moment';
import {DataItem} from 'src/design-system';
import {useStyle} from 'src/providers/style';

export const Details = ({required, label}: any) => {
  const styles = useStyle();

  return (
    <DatePickerInput
      style={[styles.width]}
      withModal={false}
      disabled={true}
      locale="en"
      label={required ? label + '*' : label}
      value={new Date()}
      onChange={() => {}}
      inputMode="start"
      mode="outlined"
      theme={{colors: {primary: Colors.BLUE_RIBBON}}}
    />
  );
};

export const Definition = () => ({
  name: 'Date Picker',
  label: '',
  placeholder: '',
  componentType: 'DATE',
  icon: 'calendar-blank',
  options: [],
  helper: '',
  required: false,
});

export const Input = ({item, name}: InputProps) => {
  return (
    <HookFormDateInput
      name={name}
      label={item?.label}
      required={item?.required}
      // defaultValue={new Date()}
      rules={
        item.required
          ? {
              required: 'This field is required.',
            }
          : {}
      }
    />
  );
};

export const Output = ({item, value}: OutputProps) => (
  <DataItem>
    <Text style={[Typography.H6]}>{item.label}</Text>
    <Text style={[Typography.P3]}>{moment(value).format('ll')}</Text>
  </DataItem>
);

export const Preview = ({item}: PreviewProps) => (
  <DatePickerInput
    withModal={false}
    disabled={true}
    locale="en"
    label={item.label || ''}
    value={new Date()}
    inputMode="start"
    mode="outlined"
    theme={{colors: {primary: Colors.BLUE_RIBBON}}}
  />
);
