import {Q} from '@nozbe/watermelondb';
import {asc} from '@nozbe/watermelondb/QueryDescription';
import {
  children,
  date,
  relation,
  text,
  lazy,
  json,
  field,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';
import {sanitizeStrings} from 'src/common-utils/sanitize-strings';

export class Session extends Base {
  static table = 'sessions';
  static associations = {
    appointments: {type: 'belongs_to', foreignKey: 'appointment_id'},
    claims: {type: 'belongs_to', foreignKey: 'claim_id'},
    users: {type: 'belongs_to', key: 'user_id'},
    patients: {type: 'belongs_to', key: 'patient_id'},
    sets: {type: 'has_many', foreignKey: 'session_id'},
    session_programs: {type: 'has_many', foreignKey: 'session_id'},
    note_templates: {
      type: 'belongs_to',
      foreignKey: 'note_template_id',
    },
    note_template_versions: {
      type: 'belongs_to',
      foreignKey: 'note_template_version_id',
    },
    signatures: {type: 'has_many', foreignKey: 'entity_id'},
  };

  @relation('appointments', 'appointment_id') appointment;
  @relation('patients', 'patient_id') patient;
  @relation('users', 'user_id') user;
  @relation('note_templates', 'note_template_id') noteTemplate;
  @relation('note_template_versions', 'note_template_version_id')
  noteTemplateVersion;

  @children('sets') sets;

  @children('signatures') signatures;

  @lazy
  sessionSignatures = this.collections
    .get('signatures')
    .query(
      Q.where('entity_id', this.id),
      Q.where('entity', 'session'),
      Q.where('deleted_at', null),
      Q.sortBy('created_at', asc),
    );

  @lazy
  supervisionSignatures = this.collections
    .get('signatures')
    .query(
      Q.where('entity_id', this.id),
      Q.where('entity', 'supervision'),
      Q.where('deleted_at', null),
      Q.sortBy('created_at', asc),
    );

  @lazy
  programs = this.collections
    .get('programs')
    .query(Q.on('session_programs', 'session_id', this.id));

  @lazy
  sessionPrograms = this.collections
    .get('session_programs')
    .query(Q.where('session_id', this.id));

  @lazy
  currentStaffParticipant = (userId: string) =>
    this.collections
      .get('participants')
      .query(
        Q.where('appointment_id', this.appointmentId),
        Q.where('user_id', userId),
        Q.where('deleted_at', null),
      );

  @lazy activeNotes = this.collections
    .get('notes')
    .query(
      Q.where('entity_id', this.id),
      Q.where('entity', 'session'),
      Q.where('deleted_at', null),
      Q.sortBy('created_at', Q.asc),
    );

  @text('appointment_id') appointmentId;
  @text('_partition') partition;
  @text('user_id') userId;
  @text('supervising_id') supervisingId;
  @text('patient_id') patientId;
  @text('claim_id') claimId;
  @text('rendering_id') renderingId;
  @text('type') type;
  @text('location') location;
  @date('date') date;
  @json('cpt', sanitizeStrings) cpt;
  @text('session_date') sessionDate;
  @date('start_timestamp') startTimestamp;
  @date('end_timestamp') endTimestamp;
  @date('edited_start_timestamp') editedStartTimestamp;
  @date('edited_end_timestamp') editedEndTimestamp;
  @text('note') note;
  @date('note_edited_timestamp') noteEditedTimestamp;
  @text('note_edited_by') noteEditedBy;
  @field('show_authors_and_timestamps') showAuthorsAndTimestamps;
  @text('therapist_signature') therapistSignature;
  @text('therapist_signature_2') therapistSignature2;
  @text('supervision_signature') supervisionSignature;
  @text('parent_signature_1') parentSignature1;
  @text('parent_signature_2') parentSignature2;
  @date('attestation_timestamp') attestationTimestamp;
  @date('submission_timestamp') submissionTimestamp;
  @json('post_session_emotions', value => value) postSessionEmotions;
  @text('post_session_impressions') postSessionImpressions;
  @text('note_template_id') noteTemplateId;
  @text('note_template_version_id') noteTemplateVersionId;
  @json('additional_fields', value => value) additionalFields;
  @text('address') address;
  @json('custom_address', value => value) customAddress;
}
