import {Tooltip} from 'src/design-system';
import {StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {Typography} from 'src/styles';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {Prompt} from 'src/models';
import _ from 'lodash';
import {of} from 'rxjs';

const PromptTooltip = ({promptItems}: any) => {
  const items = _.groupBy(promptItems, 'promptType');

  return (
    <Tooltip anchor={<Text style={styles.padding}>ABC</Text>}>
      {Object.keys(items).map((promptType: string) => {
        return (
          <View key={`promptType-key-${promptType}`}>
            <Text style={[Typography.H6, styles.paddingBottom]}>
              {promptType}
            </Text>
            <View>
              {items[promptType].map((prompt: any) => (
                <Text style={[Typography.CAPTION]} key={`prompt-${prompt?.id}`}>
                  {prompt?.name}
                </Text>
              ))}
            </View>
          </View>
        );
      })}
    </Tooltip>
  );
};

const styles = StyleSheet.create({
  padding: {
    padding: 12,
  },
  paddingTop: {},
  paddingBottom: {
    paddingBottom: 12,
  },
});

export default compose(
  withDatabase,
  withObservables([], ({database, prompts}: any) => {
    return {
      promptItems: prompts
        ? database.get(Prompt.table).query(Q.where('id', Q.oneOf(prompts)))
        : of([]),
    };
  }),
)(PromptTooltip);
