import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {endOfDay, startOfDay, subMonths} from 'date-fns';

export const setFilters = createAsyncThunk(
  'claimFilters/setFilters',
  async (args: any) => {
    return args;
  },
);

const claimFilters = createSlice({
  name: 'claimFilters',
  initialState: {
    selectedFilters: {
      startDate: startOfDay(subMonths(new Date(), 3)).getTime(),
      endDate: endOfDay(new Date()).getTime(),
      locations: [],
      payerId: [],
      types: [],
      clients: [],
      staff: [],
      addresses: [],
      status: ['unsubmitted'],
      hideEmptyClients: true,
      showExcludedSessions: false,
    },
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setFilters.fulfilled, (state, {payload}) => {
      Object.assign(state, {selectedFilters: payload});
    });
  },
});

export default claimFilters.reducer;
