import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const navigate = createAsyncThunk(
  'session/navigate',
  async (args: any) => {
    return args;
  },
);

export const setOffset = createAsyncThunk(
  'session/setOffset',
  async (args: any) => {
    return args;
  },
);

export const updateOffset = createAsyncThunk(
  'session/updateOffset',
  async (args: any) => {
    return args;
  },
);

export const setNote = createAsyncThunk(
  'session/setNote',
  async (args: any) => {
    return args;
  },
);

const session = createSlice({
  name: 'session',
  initialState: {
    index: null,
    type: null,
    offset: [],
    notes: {},
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(navigate.fulfilled, (state, {payload}) => {
      Object.assign(state, payload);
    });
    builder.addCase(setOffset.fulfilled, (state, {payload}) => {
      const updatedOffset = Array.from(state.offset);
      updatedOffset[payload.idx] = payload.top;
      Object.assign(state, {
        offset: updatedOffset,
      });
    });
    builder.addCase(updateOffset.fulfilled, (state, {payload}) => {
      const updatedOffset = [...state.offset];
      const top = state.offset[payload.from];
      updatedOffset.splice(payload.from, 1);
      updatedOffset.splice(payload.to, 0, top);
      Object.assign(state, {
        offset: updatedOffset,
      });
    });
    builder.addCase(setNote.fulfilled, (state, {payload}) => {
      Object.assign(state, {
        notes: {
          ...state.notes,
          [payload.sessionId]: payload.note,
        },
      });
    });
  },
});

export default session.reducer;
