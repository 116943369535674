import React from 'react';
import {Payer} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {useTranslations} from 'src/providers/translation';

interface Props {
  name: string;
  label?: string;
  items: any[];
  selectAll?: boolean;
  multiple?: boolean;
}

const ConfiguredPayers = ({
  name,
  label,
  items,
  selectAll = false,
  multiple = false,
}: Props) => {
  const translations = useTranslations();

  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'payerId'}
      label={label ? label : translations('payer')}
      required={false}
      multiple={multiple}
      selectAll={selectAll}
      items={items}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    payers: database
      .get(Payer.table)
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('name', Q.asc),
      ),
  })),
  withObservables([], ({payers}: any) => ({
    items: of(
      payers.map((payer: Payer) => {
        return {
          label: payer.name,
          value: payer.id,
        };
      }),
    ),
  })),
)(ConfiguredPayers);
