import {Set} from 'src/models';
import _ from 'lodash';
import moment from 'moment/moment';
import {Q} from '@nozbe/watermelondb';

export default ({sessionSets, eventsBySetId, programTargets, program}: any) => {
  const analysisState = [];
  const analysis: any = {};

  for (const sessionId of Object.keys(sessionSets)) {
    const sessionSet = sessionSets[sessionId];

    const average =
      sessionSet.reduce((a: number, b: Set) => {
        const setEvents = eventsBySetId[b.id] || [];
        return (
          a +
          setEvents.reduce((setPercentage: number, event: any) => {
            return (
              setPercentage +
              (event.value === 'success' ? 1 : 0) *
                (100 / programTargets?.length)
            );
          }, 0)
        );
      }, 0) / sessionSet.length;

    let state;

    if (analysisState[analysisState.length - 1]?.state === 'in-treatment') {
      if (average >= program?.masteryValue) {
        let staffMinimum = false;
        let consecutiveSessions = false;
        for (let i = analysisState.length - 1; i >= 0; i--) {
          if (analysisState[i].average < program?.masteryValue) {
            break;
          }

          if (analysisState.length - i > program?.masterySessions) {
            consecutiveSessions = true;
            break;
          }

          // TODO: check if it's greater than the number of staff minimum
          staffMinimum = true;
        }
        if (staffMinimum && consecutiveSessions) {
          if (program?.maintenance) {
            if (average >= program?.maintenanceValue) {
              state = 'in-maintenance';
            } else {
              state = 'in-treatment';
            }
          } else {
            state = 'completed';
          }
        } else {
          state = 'in-treatment';
        }
      } else {
        state = 'in-treatment';
      }
    } else if (
      analysisState[analysisState.length - 1]?.state === 'in-maintenance'
    ) {
      if (average >= program?.maintenanceValue) {
        state = 'in-maintenance';
      } else {
        state = 'in-treatment';
      }
    } else if (analysisState[analysisState.length - 1]?.state === 'completed') {
      state = 'completed';
    } else {
      state = 'in-treatment';
    }

    const sessionObject = {
      sessionId,
      average: average,
      state,
    };

    analysisState.push(sessionObject);
    analysis[sessionId] = sessionObject;
  }

  return {
    analysis,
    analysisState,
  };
};

export const exportTaskAnalysis = async (
  database: any,
  sets: any[],
  sortedTargets: any[],
) => {
  let _exportData = [
    [
      'Date',
      'Time Stamp',
      'Set',
      ...sortedTargets.map(
        (target: any, index: number) => `${target?.name}-${index + 1}`,
      ),
      'Score',
    ],
  ];
  for (const set of sets) {
    const index: number = sets.indexOf(set);
    const _events = await database
      .get('events')
      .query(Q.where('set_id', set?.id))
      .fetch();
    const eventsByTargetId = _.groupBy(_events, 'target.id');
    let column = [
      moment(set?.startTimestamp).format('MM/DD/YY'),
      moment(set?.startTimestamp).format('HH:mm:ss') +
        ' - ' +
        (set?.endTimestamp
          ? moment(set?.endTimestamp).format('HH:mm:ss')
          : 'Incomplete'),
      `Set ${index + 1}`,
    ];
    for (const target of sortedTargets) {
      let icon: string;
      if (eventsByTargetId[target.id]?.length > 0) {
        const event = eventsByTargetId[target.id][0];
        if (event?.value === 'success') {
          icon = 'O';
        } else if (event?.value === 'error') {
          icon = 'X';
          for (const promptId of event?.prompts) {
            const prompt = await database.get('prompts').find(promptId);
            icon += '\n' + prompt?.acronym;
          }
        } else {
          icon = '-';
        }
      } else {
        icon = '-';
      }
      column.push(icon);
    }
    column.push(
      Math.ceil(
        (_events?.filter((event: any) => event.value === 'success').length /
          _events?.length) *
          100,
      ) + '%',
    );
    _exportData.push(column);
  }

  return _exportData;
};
