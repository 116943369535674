import React from 'react';
import {Text, View, StyleSheet} from 'react-native';
import _ from 'lodash';

import {Colors, Typography} from 'src/styles';

const ProgramCardMeasurement = ({program}: any) => {
  return (
    <View style={styles.container}>
      <Text style={styles.headerTxt}>Measurement</Text>

      {program?.baseline ? (
        <View>
          <Text style={styles.titleTxt}>Baseline</Text>
          <Text style={styles.descriptionTxt}>
            Correct responses needed to prove mastery and skill treatment.
          </Text>

          <Text style={styles.subTitleTxt}>Test Probes</Text>
          <Text style={styles.descriptionTxt}>{program?.baselineProbes}</Text>

          <Text style={styles.subTitleTxt}>Correct Probes</Text>
          <Text style={styles.descriptionTxt}>
            {program?.baselineCorrectProbes}
          </Text>
        </View>
      ) : (
        <></>
      )}

      <View>
        <Text style={styles.titleTxt}>Mastery Criteria</Text>
        <Text style={styles.descriptionTxt}>
          {program?.method === 'duration'
            ? 'Time threshold that indicates a reduction in behavior.'
            : program?.method === 'frequency'
            ? 'Number of occurrences that indicate a reduction in behavior.'
            : program?.method === 'task_analysis'
            ? 'Percentage of targets where the client has responded independently.'
            : program?.method === 'trial_by_trial'
            ? 'Percentage correct over consecutive sessions to prove knowledge of target.'
            : ''}
        </Text>
        {program?.method === 'duration' ? (
          <>
            <Text style={styles.subTitleTxt}>Time Recorded</Text>
            <Text style={styles.descriptionTxt}>
              {program?.masteryValue?.hour > 0 &&
                program?.masteryValue?.hour + ' hour '}
              {program?.masteryValue?.minute > 0 &&
              program?.masteryValue?.minute > 1
                ? program?.masteryValue?.minute + ' minutes '
                : '1 minute '}
              {program?.masteryValue?.second > 0 &&
              program?.masteryValue?.second > 1
                ? program?.masteryValue?.second + ' seconds'
                : '1 second'}
            </Text>
          </>
        ) : program?.method === 'frequency' ? (
          <>
            <Text style={styles.subTitleTxt}>Number of Instances</Text>
            <Text style={styles.descriptionTxt}>{program?.masteryValue}</Text>
          </>
        ) : program?.method === 'task_analysis' ? (
          <>
            <Text style={styles.subTitleTxt}>Percent Independent</Text>
            <Text style={styles.descriptionTxt}>{program?.masteryValue}%</Text>
          </>
        ) : program?.method === 'trial_by_trial' ? (
          <>
            <Text style={styles.subTitleTxt}>Percent Correct</Text>
            <Text style={styles.descriptionTxt}>{program?.masteryValue}%</Text>
          </>
        ) : (
          <></>
        )}
        <Text style={styles.subTitleTxt}>Consecutive Sessions</Text>
        <Text style={styles.descriptionTxt}>{program?.masterySessions}</Text>
        {(program?.method === 'trial_by_trial' ||
          program?.method === 'task_analysis') &&
        program?.masteryStaff ? (
          <>
            <Text style={styles.subTitleTxt}>Staff Minimum</Text>
            <Text style={styles.descriptionTxt}>
              {program?.masteryStaffMinimum}
            </Text>
          </>
        ) : (
          <></>
        )}
      </View>

      {program?.maintenance && (
        <View>
          <Text style={styles.titleTxt}>Maintenance Criteria</Text>
          <Text style={styles.descriptionTxt}>
            {program?.method === 'duration' || program?.method === 'frequency'
              ? 'Prompted to confirm the behavior did not occur over mastery threshold.'
              : program?.method === 'task_analysis'
              ? 'Testing the entire program again to ensure client retention.'
              : program?.method === 'trial_by_trial'
              ? 'Mastered targets expectations to ensure client retention.'
              : ''}
          </Text>

          {program?.method === 'duration' || program?.method === 'frequency' ? (
            <>
              <Text style={styles.subTitleTxt}>Number of Sessions</Text>
              <Text style={styles.descriptionTxt}>
                {program?.maintenanceSessions}
              </Text>
            </>
          ) : program?.method === 'task_analysis' ? (
            <>
              <Text style={styles.subTitleTxt}>Percent Independent</Text>
              <Text style={styles.descriptionTxt}>
                {program?.maintenanceValue}
              </Text>
            </>
          ) : program?.method === 'trial_by_trial' ? (
            <>
              <Text style={styles.subTitleTxt}>Percent Correct</Text>
              <Text style={styles.descriptionTxt}>
                {program?.maintenanceValue}
              </Text>
            </>
          ) : (
            <></>
          )}

          <Text style={styles.subTitleTxt}>Cadence</Text>
          <Text style={styles.descriptionTxt}>
            {_.capitalize(program?.maintenanceCadence)}
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 15,
    paddingBottom: 30,
  },
  headerTxt: {
    ...Typography.P2_MEDIUM,
    color: Colors.TEXT_DISABLED,
  },
  titleTxt: {
    ...Typography.P3_BOLD,
    color: Colors.TEXT_DISABLED,
    marginTop: 20,
  },
  subTitleTxt: {
    ...Typography.P2_MEDIUM,
    color: Colors.TEXT_DISABLED,
    marginTop: 10,
  },
  descriptionTxt: {
    ...Typography.CAPTION,
    color: Colors.TEXT_DISABLED,
    marginVertical: 5,
  },
  labelTxt: {
    ...Typography.TAB,
    color: Colors.TEXT_DISABLED,
  },
  borderBox: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.PRIMARY_300,
    paddingHorizontal: 10,
    marginTop: 10,
  },
});

export default ProgramCardMeasurement;
