import React, {useState} from 'react';
import {HelperText, TextInput} from 'react-native-paper';
import {Colors} from 'src/styles';
import DraggableFlatList from 'react-native-draggable-flatlist';
import {compareRank, incrementChar, updateRank} from 'src/common-utils/rank';
import TargetItem from './target-item';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import {Q} from '@nozbe/watermelondb';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Target} from 'src/models';
import {useFormContext} from 'react-hook-form';
import {useStyle} from 'src/providers/style';
import {DeleteModal} from 'src/design-system';

interface Props {
  programId: any;
  inputRef: any;
  targets: any[];
  changeTargets: (value: any) => void;
  error?: any;
  setError: (name: string, error: {type: string; message: string}) => void;
  clearErrors: (name?: string | string[]) => void;
}

const AddTargets = ({
  programId,
  inputRef,
  targets = [],
  changeTargets,
  error,
  clearErrors,
}: Props) => {
  const database = useDatabase();
  const styles = useStyle();

  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const {setError} = useFormContext();
  const [newTarget, setNewTarget] = useState<string>('');
  const [distance, setDistance] = useState<number>(100);
  const [showDelete, setShowDelete] = useState<any | undefined>(undefined);

  const onSubmit = async () => {
    clearErrors('targets');
    if (newTarget !== '') {
      let targetId = '';
      const namedTargets = await database
        .get('targets')
        .query(
          Q.where('name', newTarget),
          Q.where('program_id', Q.eq(programId)),
        );

      if (namedTargets.length > 0) {
        const target = namedTargets[0];
        if (target.deletedAt !== null) {
          target.updateEntity({
            deletedAt: null,
          });
          setNewTarget('');
        } else {
          setError('targets', {
            type: 'manual',
            message: 'Already exists!',
          });
        }
      } else {
        await database.write(async () => {
          targetId = await database.get(Target.table).create(entity => {
            entity.partition = selectedGroup;
            entity.program.id = programId;
            entity.rank = incrementChar(
              targets.length
                ? [...(targets || [])].sort(compareRank)[targets.length - 1]
                    .rank
                : '',
            );
            entity.name = newTarget;
            entity.enabled = true;
            entity.deletedAt = null;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        });
        setNewTarget('');
      }
      changeTargets([...targets.map(target => target.id), targetId.id]);
    }
  };

  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = async (item: any) => {
    item.delete();
    changeTargets([...targets.filter(target => target.id !== item.id)]);
    setShowDelete(false);
    changeTargets([...targets.filter(target => target.id !== item.id)]);
  };

  return (
    <>
      <DeleteModal
        model={'Target'}
        text={showDelete ? `${showDelete?.name}` : null}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <TextInput
        ref={inputRef}
        style={styles.input}
        theme={{colors: {primary: Colors.TEXT_SECONDARY}}}
        value={newTarget}
        onChangeText={text => setNewTarget(text)}
        mode="outlined"
        placeholder="Add Targets"
        label="Targets"
        error={!!error}
        onSubmitEditing={onSubmit}
        returnKeyType="next"
        blurOnSubmit={false}
        right={<TextInput.Icon name={'plus'} size={20} onPress={onSubmit} />}
      />
      <HelperText
        type={error ? 'error' : 'info'}
        visible={true}
        style={styles.helper}>
        {error
          ? 'ⓧ ' + error.message
          : 'Individual data cards that with the goal of becoming mastered'}
      </HelperText>
      <DraggableFlatList
        activationDistance={distance}
        data={targets}
        keyExtractor={(item, index) => `target-key-${item?.name}-${index}`}
        renderItem={({item, drag, index, isActive}) => {
          return (
            <TargetItem
              target={item}
              drag={drag}
              isActive={isActive}
              index={index}
              setTarget={setShowDelete}
              validName={async (id: string, name: string) => {
                const namedTargets = await database
                  .get('targets')
                  .query(
                    Q.and(
                      Q.where('name', name),
                      Q.where('id', Q.notEq(id)),
                      Q.where('program_id', Q.eq(programId)),
                    ),
                  );
                return namedTargets.length === 0;
              }}
            />
          );
        }}
        onDragBegin={() => setDistance(10)}
        onDragEnd={({data}) => {
          updateRank(database, data);
          setDistance(100);
        }}
      />
    </>
  );
};

export default compose(
  withDatabase,
  withObservables(['programId'], ({programId, database}: any) => ({
    program: database.get('programs').findAndObserve(programId),
  })),
  withObservables(['program'], ({program}: any) => ({
    targets: program.activeTargets,
  })),
)(AddTargets);
