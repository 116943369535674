import React from 'react';
import {Patient, User} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import CheckboxInput from 'src/hook-form-wrapper/checkbox-input';

interface Props {
  name: string;
  items: any[];
  allowSelectAll: boolean;
  search: boolean;
}

const ClientCheckbox = ({
  name,
  items,
  allowSelectAll,
  search = false,
}: Props) => {
  return (
    <CheckboxInput
      name={(name ? name + '.' : '') + 'clients'}
      items={items}
      allowSelectAll={allowSelectAll}
      search={search}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(
    [],
    ({
      profile,
      database,
      authentication,
      role,
      assignedPatientsOnly = false,
      user = null,
    }: any) => ({
      patients:
        assignedPatientsOnly && user
          ? user.patients
          : !role?.patientAssignedOnly
          ? database
              .get(Patient.table)
              .query(
                Q.where('deleted_at', null),
                Q.where('_partition', authentication.selectedGroup),
                Q.sortBy('first_name', Q.asc),
                Q.sortBy('last_name', Q.asc),
              )
          : profile.patients,
    }),
  ),
  withObservables([], ({patients}: any) => ({
    items: of(
      patients.map((patient: Patient) => {
        return {
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.id,
        };
      }),
    ),
  })),
)(ClientCheckbox);
