import React, {useEffect, useState} from 'react';
import {Screen} from 'react-native-screens';
import {Modal} from 'src/design-system';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {ScrollView, Text, View} from 'react-native';
import Stepper from 'src/design-system/stepper';
import {FormProvider, useForm} from 'react-hook-form';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {yupResolver} from '@hookform/resolvers/yup';
import BackButton from 'src/navigation/components/back-button';
import {useNavigation} from '@react-navigation/native';

const StepForm = ({
  onSubmit,
  defaultSteps,
  defaultValues,
  validationSchema,
  navigateBack,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const navigation = useNavigation();

  const [currentStep, setCurrentStep] = useState(0);
  const [confirmation, setConfirmation] = useState(false);
  const [errors, setErrors] = useState<boolean | any>(false);
  const [steps, setSteps] = useState(defaultSteps);

  const changeTitle = () => {
    navigation.setOptions({
      // eslint-disable-next-line react/no-unstable-nested-components
      headerLeft: () => <BackButton customPress={() => validate(true)} />,
    });
  };

  useEffect(() => {
    changeTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  methods.formState.isDirty;

  const validate = (shouldValidate: boolean = false) => {
    if (shouldValidate && methods.formState.isDirty) {
      setConfirmation(true);
    } else {
      navigateBack();
    }
  };
  const onError = async (values: any) => {
    setErrors(values);
  };
  const evaluateStep = async (stepIndex: number) => {
    let validated = true;
    const step = steps[stepIndex];

    if (step.fields.length > 0) {
      for (const fieldName of step.fields) {
        const fieldValidation = await methods.trigger(fieldName);
        if (!fieldValidation) {
          validated = false;
          break;
        }
      }
    }

    const copy = [...steps];
    copy[stepIndex] = {
      ...step,
      validated,
      touched: true,
    };
    setSteps(copy);
  };

  const handleErrorText = () => {
    return Object.keys(errors).map(error => {
      if (Array.isArray(errors[error])) {
        return (
          <View style={[styles.paddingLBottom]}>
            <Text style={[Typography.P2, styles.paddingSMBottom]}>
              {translations(error)}
            </Text>
            {errors[error].map((errorObj: any) => {
              for (const key in errorObj) {
                return (
                  <Text style={[Typography.P3, styles.paddingLBottom]}>
                    {errorObj[key].message}
                  </Text>
                );
              }
            })}
          </View>
        );
      } else {
        return (
          <View style={[styles.paddingLBottom]}>
            <Text style={[Typography.P2, styles.paddingSMBottom]}>
              {translations(error)}
            </Text>
            <Text style={[Typography.P3]}>{errors[error].message}</Text>
          </View>
        );
      }
    });
  };

  const handleSubmit = async (values: any) => {
    const result = await onSubmit(values);
    if (result) {
      setErrors(result);
    }
  };

  const FormPart = steps[currentStep].form;

  return (
    <Screen style={[styles.flex]}>
      <Modal
        show={[confirmation, setConfirmation]}
        title={'Unsaved Changes'}
        footer={
          <>
            <RHButton
              secondary
              mode="outlined"
              textColor={Colors.RAVEN_BLACK}
              onPress={() => navigateBack()}>
              Exit & Lose Changes
            </RHButton>
            <RHSeparator width={16} />
            <RHButton
              secondary
              mode="contained"
              onPress={() => setConfirmation(false)}>
              Resume Editing
            </RHButton>
          </>
        }>
        <Text style={[Typography.P1]}>
          Warning - Any changes made will be lost unless the form is saved.
        </Text>
      </Modal>
      <Stepper
        currentStep={[currentStep, setCurrentStep]}
        steps={steps}
        onClickCallback={step => {
          evaluateStep(currentStep);
          setCurrentStep(step);
        }}
      />
      <ScrollView
        style={[
          styles.flex,
          styles.backgroundColorWhite,
          styles.paddingBottom,
          styles.marginBottom,
        ]}
        contentContainerStyle={[styles.paddingBottom, styles.marginBottom]}>
        <FormProvider {...methods}>
          <FormPart
            key={steps[currentStep].step}
            values={methods.getValues()}
            {...(steps[currentStep]?.extras || {})}
          />
        </FormProvider>
      </ScrollView>
      <View
        style={[
          styles.affix,
          styles.paddingHorizontal,
          styles.row,
          styles.justifySpaceBetween,
          styles.elevation,
        ]}>
        <View style={[styles.column, styles.justifyCenter]}>
          <RHButton
            mode="contained"
            onPress={() => {
              if (currentStep === 0) {
                validate(true);
              } else {
                evaluateStep(currentStep);
                setCurrentStep(prevStep => prevStep - 1);
              }
            }}>
            {currentStep === 0 ? (
              translations('cancel_button')
            ) : (
              <>
                <Icon name="arrow-left" size={16} style={styles.marginMRight} />
                {translations('back')}
              </>
            )}
          </RHButton>
        </View>

        <View style={[styles.row, styles.alignCenter]}>
          {currentStep !== steps.length - 1 ? (
            <RHButton
              style={styles.marginLRight}
              secondary
              onPress={() => {
                evaluateStep(currentStep);
                setCurrentStep(prevStep => prevStep + 1);
              }}
              textColor={Colors.RAVEN_BLACK}
              color="black"
              mode="outlined">
              <>
                {translations('next')}
                <Icon name="arrow-right" size={16} style={styles.marginMLeft} />
              </>
            </RHButton>
          ) : (
            <></>
          )}
          <View style={[styles.column, styles.justifyCenter]}>
            <RHButton
              mode="contained"
              onPress={methods.handleSubmit(handleSubmit, onError)}>
              {translations('save_button')}
            </RHButton>
          </View>
        </View>
      </View>
      <Modal show={[errors, setErrors]} title={'Error'}>
        {handleErrorText()}
      </Modal>
    </Screen>
  );
};

export default StepForm;
