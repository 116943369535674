import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Credential} from 'src/models';
import {TouchableOpacity} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import ListItemSeparator from 'src/common-components/separator';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

export const PayerStaffItemDisplay = compose(
  withDatabase,
  withObservables(['value'], ({value, database}: any) => ({
    credential: database
      .get(Credential.table)
      .findAndObserve(value?.id)
      .pipe(catchError(() => of({}))),
  })),
  withObservables([], ({credential}: any) => ({
    user: Object.keys(credential).length ? credential.user : of({}),
  })),
)(({value, user}: any) => {
  const styles = useStyle();

  return (
    <View
      style={[
        styles.row,
        styles.borderBottom,
        styles.alignCenter,
        styles.justifyCenter,
        styles.flex,
      ]}>
      <View
        style={[
          styles.paddingL,
          styles.borderRight,
          styles.flex,
          styles.alignCenter,
          styles.justifyCenter,
        ]}>
        <View style={[styles.width]}>
          <Text style={[Typography.P3, styles.textColorPrimary]}>
            {user?.firstName || ''} {user?.lastName || ''}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.paddingL,
          styles.borderRight,
          styles.flex,
          styles.alignCenter,
          styles.justifyCenter,
        ]}>
        <View style={[styles.width]}>
          <Text style={[Typography.P3, styles.textColorPrimary]}>
            {value.legacyId}
          </Text>
        </View>
      </View>
    </View>
  );
});

const PayerStaffItem = ({user, onClose, index}: any) => {
  const styles = useStyle();

  return user ? (
    <View style={[styles.alignCenter]}>
      <View
        style={[
          styles.row,
          styles.width,
          styles.justifySpaceBetween,
          styles.alignCenter,
          styles.flex,
          styles.paddingL,
        ]}>
        <View style={[styles.flex]}>
          <Text style={[Typography.P2_MEDIUM, styles.textColorPrimary]}>
            {user?.firstName || ''} {user?.lastName || ''}
          </Text>
        </View>
        <View style={[styles.flex]}>
          <HookFormInput
            name={`assignedCredentials.${index}.legacyId`}
            label={'ID #'}
            helper=""
            showHelper={false}
          />
        </View>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.alignCenter,
            styles.flex,
            styles.justifyEnd,
          ]}>
          <TouchableOpacity style={[]} onPress={onClose}>
            <Icon name="trash-can" size={20} />
          </TouchableOpacity>
        </View>
      </View>
      <ListItemSeparator />
    </View>
  ) : null;
};

export default compose(
  withDatabase,
  withObservables(['id'], ({id, database}: any) => ({
    credential: database
      .get(Credential.table)
      .findAndObserve(id)
      .pipe(catchError(() => of({}))),
  })),
  withObservables([], ({credential}: any) => ({
    user: Object.keys(credential).length ? credential.user : of({}),
  })),
)(PayerStaffItem);
