import React from 'react';
import {View, Text, Platform} from 'react-native';
import Slider from '@react-native-community/slider';
import {Typography, Colors} from 'src/styles';
import {useStyle} from 'src/providers/style';

interface Props {
  label: string;
  value: number;
  onChange: (value: number) => void;
}

const isAndroid = Platform.OS === 'android';

const PercentageSlider = ({label, value = 0, onChange}: Props) => {
  const styles = useStyle();

  return (
    <View>
      <View
        style={[
          styles.width,
          styles.border,
          styles.borderColorPrimary300,
          styles.borderRadiusSM,
          styles.maxSliderWidth,
          styles.marginLBottom,
          styles.positionRelative,
        ]}
        pointerEvents="box-none">
        <Text
          style={[
            Typography.LABEL,
            styles.textColorPrimary,
            styles.paddingTop,
            styles.paddingHorizontal,
            styles.paddingLBottom,
          ]}>
          {label}
        </Text>
        <Text
          style={[
            Typography.H4,
            styles.textColorPrimary,
            styles.paddingBottom,
            styles.paddingHorizontal,
          ]}>
          {value}%
        </Text>
      </View>
      <Slider
        onResponderGrant={() => isAndroid}
        minimumValue={0}
        maximumValue={100}
        step={1}
        value={value}
        onValueChange={onChange}
        thumbTintColor={Colors.GRAY_600}
        minimumTrackTintColor={Colors.GRAY_800}
        maximumTrackTintColor={Colors.GRAY_100}
        style={[
          styles.paddingLVertical,
          styles.width,
          styles.maxSliderWidth,
          styles.slider,
        ]}
      />
    </View>
  );
};

export default PercentageSlider;
