import React, {useState} from 'react';
import {Text, ScrollView, View, FlatList} from 'react-native';
import {BaseIndexScreen, DeleteModal, IconButton} from 'src/design-system';
import {Colors, Typography} from 'src/styles';
import {exportCsv} from 'src/common-utils/export-csv';
import {IconButton as PaperIconButton} from 'react-native-paper';
import {endOfDay, startOfDay} from 'date-fns';
import {appointmentLocations} from 'src/form-inputs/appointment-location-input';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import moment from 'moment';
import StaffHistoricalSessions from '../staff-historical-sessions';
import StaffHistoryMenu from '../staff-history-menu';
import {useStyle} from 'src/providers/style';
import {Appointment, Event, Participant, Set} from 'src/models';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {cptCodesFilter} from 'src/hook-form-inputs/cpt';
import {statusArray} from 'src/hook-form-inputs/status-checkbox';
import {rrulestr} from 'rrule';
import {useSelector} from 'react-redux';

const StaffHistory = ({patients, user}: any) => {
  const styles = useStyle();
  const database = useDatabase();

  const defaultFilters = {
    startDate: startOfDay(moment().subtract(5, 'days').toDate()),
    endDate: endOfDay(new Date()),
    patients: [],
    locations: [],
    types: [],
    status: ['completed', 'missing-values'],
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [filters, setFilters] = useState<any>(defaultFilters);
  const [exportItems, setExportItems] = useState([]);
  const [showDelete, setShowDelete] = useState<string | boolean>(false);
  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const initiateCsvExport = async (name: string, content: string[][]) => {
    setLoading(true);
    let _exportData = [
      [
        'Staff Name',
        'Client Name',
        'Session Date',
        'Session Start Time',
        'Session Edited Start Time ',
        'Session End Time',
        'Session Edited End Time',
        'Session Note',
      ],
    ];
    for (const session of content) {
      const patient = await session.patient.fetch();
      const notes = await database
        .get('notes')
        .query(
          Q.and(
            Q.where('entity_id', session?.id),
            Q.where('deleted_at', Q.eq(null)),
          ),
        );
      _exportData.push([
        `${user.firstName} ${user.lastName}`,
        `${patient?.firstName || ''} ${patient?.lastName || ''}`,
        `${moment(session.date).format('YYYY-MM-DD')}`,
        `${
          session.startTimestamp
            ? moment(session.startTimestamp).format('HH:MM:SS A')
            : ''
        }`,
        `${
          session.editedStartTimestamp
            ? moment(session.editedStartTimestamp).format('HH:MM:SS A')
            : ''
        }`,
        `${
          session.endTimestamp
            ? moment(session.endTimestamp).format('HH:MM:SS A')
            : ''
        }`,
        `${
          session.editedEndTimestamp
            ? moment(session.editedEndTimestamp).format('HH:MM:SS A')
            : ''
        }`,
        `${session.note} ${
          notes.length ? notes.map((note: any) => note?.description) : ''
        }`,
      ]);
    }
    await exportCsv(name.toLowerCase() + '_session_history', _exportData);
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const getData = () => {
    let result = [];
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        result.push(...filters[key]);
      }
    }
    return result;
  };

  const deleteTag = (tag: any) => {
    let result: any = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        result[key] = filters[key].filter((item: any) => item !== tag);
      } else {
        result[key] = filters[key];
      }
    }
    setFilters(result);
  };

  const getName = (collection: any[], tag: any) => {
    const selectedPatient = collection.find(member => member.id === tag);
    return `${selectedPatient.firstName} ${selectedPatient.lastName}`;
  };

  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = async (session: any) => {
    const appointment = await session.appointment.fetch();
    if (appointment?.rrule) {
      const rruleSet = rrulestr(appointment.rrule, {
        forceset: true,
      });
      const todaysDate = session.date;
      rruleSet.exdate(
        new Date(
          todaysDate.getFullYear(),
          todaysDate.getMonth(),
          todaysDate.getDate(),
          0,
          0,
          0,
          0,
        ),
      );
      appointment.updateEntity({
        rrule: rruleSet.toString(),
      });

      await database?.write(async () => {
        const createAppointment = await database
          ?.get(Appointment.table)
          .create(entity => {
            entity.partition = selectedGroup;
            entity.location = appointment.location;
            entity.type = appointment.type;
            entity.date = session.date;
            entity.start = appointment.start;
            entity.end = appointment.end;
            entity.noteTemplateId = appointment.noteTemplateId;
            entity.deletedBy = userId;
            entity.deletedAt = new Date();
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        for (const participant of await appointment.participants) {
          await database?.get(Participant.table).create(entity => {
            entity.partition = selectedGroup;
            entity.appointment.id = createAppointment.id;
            entity.userId = participant.userId;
            entity.patientId = participant.patientId;
            entity.billable = participant.billable;
            entity.supervision = participant.supervision;
            entity.startTime = participant.startTime;
            entity.endTime = participant.endTime;
            entity.deletedBy = participant.deletedBy;
            entity.deletedAt = participant.deletedAt;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        }
      });
    } else {
      appointment.delete();
    }
    await session.delete();

    let setIds = [];
    const sets = await database
      .get(Set.table)
      .query(Q.where('session_id', session.id))
      .fetch();
    for (const set of sets) {
      await set.delete();
      setIds.push(set.id);
    }

    const events = await database
      .get(Event.table)
      .query(Q.where('set_id', Q.oneOf(setIds)))
      .fetch();
    for (const event of events) {
      await event.delete();
    }

    setShowDelete(false);
  };

  return (
    <BaseIndexScreen
      title={'History'}
      exportBut={
        <View style={[styles.row, styles.justifyEnd, styles.alignSelfCenter]}>
          <View style={[styles.paddingHorizontal]}>
            <IconButton
              loading={loading}
              disabled={loading || success}
              icon={success ? 'check' : 'download'}
              type={'outlined'}
              color={success ? Colors.SUCCESS : Colors.RAVEN_BLACK}
              onPress={() =>
                initiateCsvExport(
                  `${user.firstName}_${user.lastName}`,
                  exportItems,
                )
              }
            />
          </View>
          <StaffHistoryMenu
            applyFilters={setFilters}
            filters={filters}
            defaultFilters={defaultFilters}
          />
        </View>
      }>
      <DeleteModal
        model={'Session'}
        customText={
          'You are about to delete a previously completed session that will delete the session note and collected data. Are you sure you want to continue?'
        }
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <View
        style={[styles.width, styles.paddingHorizontal, styles.paddingBottom]}>
        <ScrollView style={[styles.maxWidth]}>
          <FlatList
            horizontal
            data={getData()}
            renderItem={({item: selectedTag}) => (
              <View
                style={[
                  styles.backgroundColorBlack,
                  styles.textColorWhite,
                  styles.borderRadiusSM,
                  styles.marginSMVertical,
                  styles.marginLRight,
                  styles.row,
                ]}
                key={`filter-tag-key-${selectedTag}`}>
                <Text
                  style={[
                    Typography.P2,
                    styles.textColorWhite,
                    styles.alignSelfCenter,
                    styles.marginLHorizontal,
                  ]}>
                  {appointmentLocations.findIndex(
                    location => location.value === selectedTag,
                  ) !== -1
                    ? appointmentLocations[
                        appointmentLocations.findIndex(
                          location => location.value === selectedTag,
                        )
                      ].label
                    : cptCodesFilter.findIndex(
                        type => type.value === selectedTag,
                      ) !== -1
                    ? cptCodesFilter[
                        cptCodesFilter.findIndex(
                          type => type.value === selectedTag,
                        )
                      ].value
                    : statusArray.findIndex(
                        type => type.value === selectedTag,
                      ) !== -1
                    ? statusArray[
                        statusArray.findIndex(
                          type => type.value === selectedTag,
                        )
                      ].label
                    : getName(patients, selectedTag)}
                </Text>
                <PaperIconButton
                  size={15}
                  icon="close"
                  color="white"
                  onPress={() => deleteTag(selectedTag)}
                />
              </View>
            )}
          />
        </ScrollView>
      </View>
      <ScrollView>
        <StaffHistoricalSessions
          user={user}
          filters={filters}
          setExportItems={setExportItems}
          deleteCallback={setShowDelete}
        />
      </ScrollView>
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => {
    return {
      users: database
        .get('users')
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
        )
        .observe(),
    };
  }),
  withObservables([], ({database, authentication}: any) => {
    return {
      patients: database
        .get('patients')
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
        )
        .observe(),
    };
  }),
)(StaffHistory);
