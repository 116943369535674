import React from 'react';
import {HelperText, TextInput as PaperInput} from 'react-native-paper';
import {KeyboardTypeOptions, StyleSheet, View} from 'react-native';
import {BLUE_RIBBON} from 'src/styles/colors';
import {FastField} from 'formik';
import {RenderProps} from 'react-native-paper/lib/typescript/components/TextInput/types';

interface Props {
  valueKey: string;
  name: string;
  keyboardType?: KeyboardTypeOptions;
  required?: boolean;
  secureTextEntry?: boolean;
  right?: () => void;
  render?: (props: RenderProps) => React.ReactNode;
  style?: any;
  numberOfLines?: number;
}

const TextInput = ({
  valueKey,
  name,
  keyboardType = 'default',
  required = false,
  secureTextEntry = false,
  right = () => {},
  render = undefined,
  style = {},
  numberOfLines = 1,
}: Props) => {
  return (
    <View style={styles.container}>
      <FastField name={valueKey}>
        {({field, meta: {error, touched}}: any) => {
          return (
            <>
              <PaperInput
                onChangeText={field.onChange(field.name)}
                onBlur={field.onBlur(field.name)}
                keyboardType={keyboardType}
                value={field.value}
                placeholder={name}
                label={name + (required ? '*' : '')}
                error={!!(error && touched)}
                secureTextEntry={secureTextEntry}
                theme={{colors: {primary: BLUE_RIBBON}}}
                right={right}
                mode={'outlined'}
                style={[styles.input, style]}
                render={render}
                multiline={numberOfLines > 1}
                numberOfLines={numberOfLines}
              />
              <HelperText type="error" visible={true} style={styles.helper}>
                {error && touched && 'ⓧ ' + error}
              </HelperText>
            </>
          );
        }}
      </FastField>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  input: {
    backgroundColor: 'white',
  },
  helper: {
    height: 24,
  },
});

export default TextInput;
