import {useEffect, useState} from 'react';
import {Suggestion} from './types/Suggestion';
import {Address} from './types/address';
import {
  GOOGLE_API_KEY,
  PROXY_URL,
  GOOGLE_MAPS_API_URL,
} from 'react-native-dotenv';

interface AddressComponent {
  types: string[];
  short_name: string;
  long_name: string;
}

const getLongFieldValue = (
  addressComponents: AddressComponent[],
  fields: string[],
): string => {
  return fields
    .map(f => {
      return addressComponents.find(addressComponent =>
        addressComponent.types.includes(f),
      )?.long_name;
    })
    .join(' ');
};

const getShortFieldValue = (
  addressComponents: AddressComponent[],
  fields: string[],
): string => {
  return fields
    .map(f => {
      return addressComponents.find(addressComponent =>
        addressComponent.types.includes(f),
      )?.short_name;
    })
    .join(' ');
};

const fetchSuggestions = async (
  search: string,
): Promise<[Suggestion[], string | undefined]> => {
  const encodedAmpersand = encodeURIComponent('&');

  const encodedParams = `${encodedAmpersand}input=${search}${encodedAmpersand}types=address`;

  const url = `${PROXY_URL}?url=${GOOGLE_MAPS_API_URL}/autocomplete/json?key=${GOOGLE_API_KEY}${encodedParams}`;

  const response = await (
    await fetch(url, {
      mode: 'cors',
      method: 'GET',
    })
  ).json();

  if (response.status && response.status === 'OK') {
    const {predictions} = response;

    const suggestions = predictions.map(
      (prediction: {place_id: string; description: string}) => {
        return {
          id: prediction.place_id,
          fullAddress: prediction.description,
        };
      },
    );

    return [suggestions, undefined];
  } else if (response.status && response.status === 'ZERO_RESULTS') {
    return [[], 'Invalid address'];
  }

  return [[], undefined];
};

export const fetchAddress = async (
  placeId: string,
): Promise<Address | undefined> => {
  const encodedAmpersand = encodeURIComponent('&');

  const encodedParams = `${encodedAmpersand}place_id=${placeId}&fields=address_component%2Cgeometry`;

  const url = `${PROXY_URL}?url=${GOOGLE_MAPS_API_URL}/details/json?key=${GOOGLE_API_KEY}${encodedParams}`;

  const response = await (
    await fetch(url, {
      method: 'GET',
      mode: 'cors',
    })
  ).json();

  if (response.status && response.status === 'OK') {
    const {
      address_components,
      geometry: {location},
    } = response.result;

    const streetLine = getLongFieldValue(address_components, [
      'street_number',
      'route',
    ]);

    const secondLine = getLongFieldValue(address_components, ['subpremise']);

    const city = getLongFieldValue(address_components, ['locality']);

    const state = getShortFieldValue(address_components, [
      'administrative_area_level_1',
    ]);

    const country = getShortFieldValue(address_components, ['country']);

    const postalCode = getShortFieldValue(address_components, ['postal_code']);

    return {
      location: {latitude: location.lat, longitude: location.lng},
      streetLine,
      secondLine,
      state,
      city,
      country,
      postalCode,
    };
  }

  return undefined;
};

const useAutoCompleteSuggestions = (
  debouncedSearchTerm: string,
): [Suggestion[], string | undefined] => {
  const [suggestions, setSuggestions] = useState<
    [Suggestion[], string | undefined]
  >([[], undefined]);

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchTerm) {
        const nextSuggestions = await fetchSuggestions(debouncedSearchTerm);

        setSuggestions(nextSuggestions);
      } else {
        setSuggestions([[], undefined]);
      }
    };

    fetchData();
  }, [debouncedSearchTerm]);

  return suggestions;
};

export const useAutoComplete = () => {
  return {
    getSuggestions: useAutoCompleteSuggestions,
  };
};

export default useAutoComplete;
