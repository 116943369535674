import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import moment from 'moment';
import _ from 'lodash';
import {Colors, Typography} from 'src/styles';
import {startOfWeek} from 'date-fns';
import {useStyle} from 'src/providers/style';

const CurrentWeekView = ({markedDates, selectedDate, setSelectedDate}: any) => {
  const styles = useStyle();

  const momentSelectedDate = moment(selectedDate);

  return (
    <View style={[]}>
      <View style={[styles.row]}>
        {_.range(0, 7).map((day: number) => {
          const date = moment(startOfWeek(selectedDate)).add(day, 'day');
          const dateItems =
            markedDates[`${date.format('YYYY-MM-DD')}`]?.length || 0;
          const range = dateItems >= 5 ? 5 : dateItems;
          return (
            <TouchableOpacity
              key={`${date.format('YYYY-MM-DD')}-day`}
              style={[
                styles.flex,
                styles.paddingLVertical,
                styles.justifyCenter,
                styles.alignCenter,
              ]}
              onPress={() => {
                setSelectedDate(
                  moment(`${date.format('YYYY-MM-DD')}`).toDate(),
                );
              }}>
              <View
                style={[
                  styles.justifyCenter,
                  styles.alignCenter,
                  styles.date,
                  `${date.format('YYYY-MM-DD')}` ===
                  `${momentSelectedDate.format('YYYY-MM-DD')}`
                    ? styles.backgroundColorSecondary700
                    : {},
                ]}>
                <Text
                  style={[
                    Typography.P3,
                    styles.textAlignCenter,
                    `${date.format('YYYY-MM-DD')}` ===
                    `${momentSelectedDate.format('YYYY-MM-DD')}`
                      ? styles.textColorWhite
                      : {},
                  ]}>
                  {`${date.format('D')}`}
                </Text>
              </View>
              <View
                style={[
                  styles.row,
                  styles.justifyCenter,
                  styles.alignCenter,
                  styles.paddingSM,
                ]}>
                <>
                  {_.range(0, range).map(() => (
                    <View
                      style={[
                        styles.dot,
                        styles.marginXS,
                        {
                          backgroundColor: Colors.RAVEN_BLACK,
                        },
                      ]}
                    />
                  ))}
                </>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

export default CurrentWeekView;
