import React, {useState} from 'react';
import {Modal} from 'src/design-system';
import {Image, Text, View} from 'react-native';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useNavigation} from '@react-navigation/native';

const WebOnlyModal = () => {
  const styles = useStyle();
  const navigation = useNavigation();
  const [showModal, setShowModal] = useState(true);
  const navigateBack = () => {
    setShowModal(false);
    navigation.goBack();
  };
  return (
    <Modal
      show={[showModal, setShowModal]}
      closeButton={false}
      title={'Login to the web app to access this feature'}
      footer={
        <RHButton
          secondary
          mode="outlined"
          textColor={Colors.RAVEN_BLACK}
          onPress={navigateBack}>
          BACK
        </RHButton>
      }>
      <View style={[styles.column]}>
        <Text style={[Typography.P3, styles.paddingBottom]}>
          Some elements of this feature can be more complex and difficult to
          complete on a smaller device. Please login on the web app at
          app.ravenhealth.com
        </Text>
      </View>
      <View style={[styles.alignCenter, styles.paddingTop]}>
        <Image
          source={require('../../../assets/web-only-graphic.png')}
          resizeMode="cover"
        />
      </View>
    </Modal>
  );
};

export default WebOnlyModal;
