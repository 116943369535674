import {ListItem} from 'src/design-system';
import {Text} from 'react-native';
import React from 'react';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

const TagListItem = ({
  tag,
  drag,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
  isActive,
}: any) => {
  const styles = useStyle();

  const toggleCallback = () => {
    if (tag?.enabled) {
      tag.updateEntity({
        enabled: false,
      });
    } else {
      tag.updateEntity({
        enabled: true,
      });
    }
  };

  const text =
    tag?.name + ' ' + (tag?.acronym !== '' ? '(' + tag?.acronym + ')' : '');
  return (
    <ListItem
      content={
        <Text style={[Typography.P3_MEDIUM, styles.textColorPrimary]}>
          {text}
        </Text>
      }
      enabled={tag?.enabled}
      toggle={() => toggleCallback(tag)}
      drag={drag}
      isActive={isActive}
      canEdit={canEdit}
      editCallback={editCallback}
      canDelete={canDelete}
      deleteCallback={deleteCallback}
    />
  );
};

export default TagListItem;
