import React from 'react';
import {View} from 'react-native';
import {Typography} from 'src/styles';
import SessionNoteTemplatePreview from 'src/modules/session/components/session-note-template-preview';
import {Text} from 'react-native';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useStyle} from 'src/providers/style';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useTranslations} from 'src/providers/translation';
import {useFormContext, useWatch} from 'react-hook-form';
import NoteTemplateVersionInput from 'src/hook-form-inputs/note-template-version';
import {NoteTemplateVersion} from 'src/models';

const SessionNoteTemplateModal = ({session}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();
  const {control} = useFormContext();
  const watchedTemplate = useWatch({control, name: 'noteTemplateVersionId'});

  const startClk = async () => {
    const templateModel = await database
      .get(NoteTemplateVersion.table)
      .find(watchedTemplate);
    await session.updateEntity({
      startTimestamp: new Date(),
      noteTemplateId: templateModel.noteTemplateId,
      noteTemplateVersionId: watchedTemplate,
      note: templateModel?.template,
    });
  };

  return (
    <View
      style={[
        styles.positionAbsolute,
        styles.top0,
        styles.bottom0,
        styles.right0,
        styles.left0,
        styles.alignCenter,
        styles.justifyCenter,
        styles.zIndex,
        styles.backgroundColorOverlay,
        styles.flex,
      ]}>
      <View
        style={[
          styles.backgroundColorWhite,
          styles.borderRadius,
          styles.padding,
          styles.maxModalWidth,
          styles.width,
        ]}>
        <Text style={[Typography.H2, styles.paddingLBottom]}>
          {translations('start_session')}
        </Text>
        <Text style={[Typography.P3, styles.paddingBottom]}>
          {translations('session_template_select_description')}
        </Text>
        <NoteTemplateVersionInput />
        <View style={[styles.marginBottom]}>
          <SessionNoteTemplatePreview
            session={session}
            templateId={watchedTemplate}
          />
        </View>
        <View style={[styles.width150, styles.alignSelfEnd]}>
          <RHButton
            onPress={startClk}
            mode={'contained'}
            secondary
            disabled={!watchedTemplate}>
            {translations('start')}
          </RHButton>
        </View>
      </View>
    </View>
  );
};

export default SessionNoteTemplateModal;
