import React from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import {compose} from 'recompose';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';

const Map = compose(
  withScriptjs,
  withGoogleMap,
)(({location}: any) => {
  return (
    <GoogleMap
      defaultZoom={18}
      center={{lat: location.latitude, lng: location.longitude}}
      options={{
        zoomControl: false,
        gestureHandling: 'none',
        fullscreenControl: false,
        keyboardShortcuts: false,
        draggable: false,
      }}>
      <Marker position={{lat: location.latitude, lng: location.longitude}} />
    </GoogleMap>
  );
});

const WrappedMap = (props: any) => {
  const styles = useStyle();

  return (
    <Map
      {...props}
      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<View style={[styles.height]} />}
      containerElement={<View style={[styles.height400]} />}
      mapElement={<View style={[styles.height]} />}
    />
  );
};

export default WrappedMap;
