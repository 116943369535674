import React, {useState} from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  ScrollView,
} from 'react-native';
import {Field} from 'formik';
import {Checkbox, HelperText, IconButton, Searchbar} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import Collapsible from 'react-native-collapsible';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import {fuzzy} from 'src/common-utils/fuzzy';

let {width} = Dimensions.get('window');

interface Props {
  valueKey: string;
  name: string;
  required?: boolean;
  items: {
    label: string;
    value: string | number;
  }[];
}

const MultiSelectInput = ({
  valueKey,
  name,
  required = false,
  items = [],
}: Props) => {
  const [open, setOpen] = useState(false);
  const [searchStr, setSearchStr] = useState('');

  const selectItem = (selectedItems: string[], value: string) => {
    let newItems = [...selectedItems];
    const index = newItems.findIndex(selectedItem => {
      return selectedItem === value;
    });
    if (index !== -1) {
      newItems.splice(index, 1);
    } else {
      newItems = [...newItems, value];
    }
    return newItems;
  };

  const resolveDisplayValue = (value: string) => {
    return items.find(item => item.value === value)?.label;
  };

  return (
    <>
      <View style={styles.container}>
        <Field name={valueKey}>
          {({field, meta: {error, touched}, form: {setFieldValue}}: any) => {
            return (
              <>
                <TouchableOpacity
                  onPress={() => setOpen(!open)}
                  style={styles.nameContainer}>
                  <View style={styles.row}>
                    <View style={styles.selectedTagContainer}>
                      {field.value?.length === 0 ? (
                        <Text style={styles.titleText}>
                          {name + (required ? '*' : '')}
                        </Text>
                      ) : (
                        <>
                          {field.value?.map((selectedItem: string) => (
                            <View
                              style={styles.selectedTagDiv}
                              key={`tag-key-${selectedItem}`}>
                              <Text style={styles.selectedTagTxt}>
                                {resolveDisplayValue(selectedItem)}
                              </Text>
                              <IconButton
                                size={15}
                                icon="close"
                                color="white"
                                onPress={() =>
                                  setFieldValue(
                                    field.name,
                                    selectItem(field.value, selectedItem),
                                  )
                                }
                              />
                            </View>
                          ))}
                        </>
                      )}
                    </View>
                    <IconButton
                      color={Colors.RAVEN_BLACK}
                      icon={!open ? 'chevron-up' : 'chevron-down'}
                    />
                  </View>
                </TouchableOpacity>
                <Collapsible collapsed={open}>
                  <Searchbar
                    value={searchStr}
                    onChangeText={text => setSearchStr(text)}
                    placeholder="Search"
                  />
                  <RHSeparator height={20} />
                  {error ? (
                    <HelperText
                      type="error"
                      visible={true}
                      style={styles.helper}>
                      {error && touched && 'ⓧ ' + error}
                    </HelperText>
                  ) : (
                    <></>
                  )}
                  <ScrollView style={styles.maxHeight}>
                    {(items || []).map((item: any) => {
                      if (fuzzy(item?.label, searchStr)) {
                        return (
                          <TouchableOpacity
                            key={`tag-key-${item?.value}-${item?.label}`}
                            onPress={() =>
                              setFieldValue(
                                field.name,
                                selectItem(field.value, item.value),
                              )
                            }
                            style={styles.singleContainer}>
                            <Checkbox.Android
                              status={
                                field.value?.some(
                                  (selectedItem: string) =>
                                    selectedItem === item?.value,
                                )
                                  ? 'checked'
                                  : 'unchecked'
                              }
                              onPress={() =>
                                setFieldValue(
                                  field.name,
                                  selectItem(field.value, item.value),
                                )
                              }
                              color={Colors.RAVEN_BLACK}
                              uncheckedColor={Colors.RAVEN_BLACK}
                            />
                            <Text style={Typography.P2}>{item?.label}</Text>
                          </TouchableOpacity>
                        );
                      }
                    })}
                  </ScrollView>
                </Collapsible>
                {error ? (
                  <HelperText type="error" visible={true} style={styles.helper}>
                    {error && touched && 'ⓧ ' + error}
                  </HelperText>
                ) : (
                  <></>
                )}
              </>
            );
          }}
        </Field>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: 0,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    paddingHorizontal: 14,
    overflow: 'scroll',
  },
  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  nameText: {
    ...Typography.H5,
    color: Colors.RAVEN_BLACK,
  },
  checkRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  titleText: {
    ...Typography.INPUT,
    color: 'rgb(119, 123, 123)',
    paddingVertical: 10,
  },
  lineSep: {
    width: '100%',
    height: 1,
    backgroundColor: '#E9E9E9',
  },
  singleContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'center',
    marginVertical: 5,
  },
  filterSpecify: {
    ...Typography.P2_MEDIUM,
    alignSelf: 'flex-start',
    textAlign: 'left',
  },
  selectedTagContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 5,
    width: width - 180,
  },
  selectedTagDiv: {
    backgroundColor: Colors.TERTIARY_TEAL,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 5,
    marginRight: 10,
  },
  selectedTagTxt: {
    ...Typography.P2,
    color: Colors.RAVEN_WHITE,
    alignSelf: 'center',
    marginHorizontal: 10,
  },
  helper: {
    height: 24,
  },
  maxHeight: {
    maxHeight: 300,
  },
});

export default MultiSelectInput;
