import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {TouchableOpacity, Text, View} from 'react-native';
import {FormInput, OfflineNotification} from 'src/design-system';
import {USERNAME} from 'react-native-dotenv';
import {Typography} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

interface Props {
  handleSubmit: (payload: any, formik: any) => void;
  login: () => void;
}

const ForgotPasswordForm = ({handleSubmit, login}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <Formik
      initialValues={{
        email: USERNAME,
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .required(translations('email_required'))
          .email(translations('email_required')),
      })}
      onSubmit={handleSubmit}>
      {({
        errors,
        // setErrors,
        values,
        setValues,
        touched,
        setTouched,
        // resetForm,
        submitForm,
      }) => (
        <>
          <FormInput
            valueKey={'email'}
            text={'Email'}
            required={true}
            errors={errors}
            values={values}
            setValues={setValues}
            touched={touched}
            setTouched={setTouched}
          />
          <View style={[styles.paddingLVertical]}>
            <RHButton onPress={submitForm} mode={'contained'} secondary>
              {translations('reset')}
            </RHButton>
            <OfflineNotification
              type="text"
              message={translations('auth_form_internet_connection')}
            />
            <View style={[styles.row, styles.justifyCenter, styles.paddingTop]}>
              <TouchableOpacity onPress={login}>
                <Text
                  style={[
                    Typography.TERTIARY_BUTTON,
                    styles.textColorPrimary,
                    styles.textTransformUppercase,
                  ]}>
                  {translations('auth_form_back_to_login')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
