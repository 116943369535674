import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Screen} from 'react-native-screens';
import {
  SessionCollect,
  SessionHeader,
  SupervisionSessionCollect,
} from 'src/modules/session/components';
import {compose} from 'recompose';
import {useSelector} from 'react-redux';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Platform, View} from 'react-native';
import {Q} from '@nozbe/watermelondb';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {SessionProgram} from 'src/models/session-program';
import * as mudder from 'mudder';
import {Program, Session, SessionTarget} from 'src/models';
import withState from 'src/redux/wrapper';
import {
  activateKeepAwake,
  deactivateKeepAwake,
} from '@sayem314/react-native-keep-awake';
import {useStyle} from 'src/providers/style';
import {Instance} from 'src/models';
import {mergeMap} from 'rxjs';

const SessionCollectScreen = ({
  appointment,
  session,
  patient,
  skills,
  behaviors,
  sessionPrograms,
  organization,
}: any) => {
  const isSupervision = session.type === 'supervision';

  const database = useDatabase();
  const styles = useStyle();
  const [isLoading, setIsLoading] = useState(!isSupervision);
  const {selectedGroup, userId} = useSelector(state => state.authentication);

  useEffect(() => {
    activateKeepAwake();

    return () => {
      deactivateKeepAwake();
    };
  }, []);

  useLayoutEffect(() => {
    if (!isSupervision) {
      const createSessionPrograms = async () => {
        const skillRanks = mudder.alphabet.mudder(skills.length);
        for (const [i, skill] of skills.entries()) {
          const index = sessionPrograms.findIndex(
            (sessionProgram: any) => sessionProgram.program.id === skill.id,
          );
          let sessionProgram: any = null;
          if (index === -1) {
            sessionProgram = await database?.write(async () => {
              return await database.get(SessionProgram.table).create(entity => {
                entity.partition = selectedGroup;
                entity.session.id = session.id;
                entity.program.id = skill.id;
                entity.rank = skillRanks[i];
                entity.enabled = true;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            });
          } else {
            sessionProgram = sessionPrograms[index];
          }
          const targets = await skill.activeTargets;
          const sessionTargets = await sessionProgram.sessionTargets;
          for (const [, target] of targets.entries()) {
            const targetIndex = sessionTargets.findIndex(
              (sessionTarget: any) => sessionTarget.target.id === target.id,
            );
            if (targetIndex === -1) {
              await database?.write(async () => {
                await database.get(SessionTarget.table).create(entity => {
                  entity.partition = selectedGroup;
                  entity.sessionProgram.id = sessionProgram.id;
                  entity.rank = target.rank;
                  entity.enabled = target.enabled;
                  entity.target.id = target.id;
                  entity.createdBy = userId;
                  entity.updatedBy = userId;
                });
              });
            } else {
              await sessionTargets[targetIndex].updateEntity({
                rank: target.rank,
              });
            }
          }
        }

        const behaviorRanks = mudder.alphabet.mudder(behaviors.length);
        for (const [i, behavior] of behaviors.entries()) {
          const index = sessionPrograms.findIndex(
            (sessionProgram: any) => sessionProgram.program.id === behavior.id,
          );
          if (index === -1) {
            await database?.write(async () => {
              await database.get(SessionProgram.table).create(entity => {
                entity.partition = selectedGroup;
                entity.session.id = session.id;
                entity.program.id = behavior.id;
                entity.rank = behaviorRanks[i];
                entity.enabled = true;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            });
          }
        }
        setIsLoading(false);
      };
      createSessionPrograms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen
      style={[
        styles.windowHeight,
        styles.windowWidth,
        Platform.OS === 'web' ? styles.overflowHidden : {},
      ]}>
      {!isLoading ? (
        <>
          <SessionHeader
            patient={patient}
            session={session}
            organization={organization}
          />
          <View style={[styles.flex]}>
            {!isSupervision ? (
              <SessionCollect
                appointment={appointment}
                session={session}
                skills={skills}
                behaviors={behaviors}
              />
            ) : (
              <SupervisionSessionCollect session={session} />
            )}
          </View>
        </>
      ) : null}
    </Screen>
  );
};

export default compose(
  withState,
  withDatabase,
  withObservables([], ({route, database}: any) => ({
    session: database.get(Session.table).findAndObserve(route.params?.id),
  })),
  withObservables([], ({session}: any) => ({
    appointment: session.appointment,
  })),
  withObservables([], ({session}: any) => ({
    patient: session.patient,
    sessionPrograms: session.sessionPrograms,
  })),
  withObservables([], ({patient}: any) => ({
    skills: patient.skills,
    behaviors: patient.behaviors,
  })),
  withObservables([], ({database, behaviors, skills, session}: any) => {
    const behavior_id = behaviors.map((behavior: any) => behavior.id);
    const skill_id = skills.map((skill: any) => skill.id);
    return {
      sortedBehaviors: database
        .get(SessionProgram.table)
        .query(
          Q.where('session_id', session.id),
          Q.on(Program.table, Q.and(Q.where('id', Q.oneOf(behavior_id)))),
          Q.where('enabled', true),
          Q.sortBy('rank', Q.asc),
        ),
      sortedSkills: database
        .get(SessionProgram.table)
        .query(
          Q.where('session_id', session.id),
          Q.on(Program.table, Q.and(Q.where('id', Q.oneOf(skill_id)))),
          Q.where('enabled', true),
          Q.sortBy('rank', Q.asc),
        ),
    };
  }),
  withObservables(['authentication'], ({authentication, database}: any) => ({
    organization: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
)(SessionCollectScreen);
