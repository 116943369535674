import React, {useState} from 'react';
import FormSectionHeader from 'src/design-system/form-section-header';
import {ScrollView, Text, View} from 'react-native';
import {Modal} from 'src/design-system';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {Q} from '@nozbe/watermelondb';
import {Claim, Patient, ServiceLine, Session, User} from 'src/models';
import {Separator} from 'src/common-components/atoms';
import PatientClaim from 'src/modules/billing/components/patient-claim';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {useTranslations} from 'src/providers/translation';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import {Colors, Typography} from 'src/styles';
import moment from 'moment';
import {useNavigation} from '@react-navigation/native';
import _ from 'lodash';

const CreateClaims = ({patients, filters}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();
  const navigation = useNavigation();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [selectedItems, setSelectedItems] = useState([]);
  const [showExclude, setShowExclude] = useState<boolean>(false);
  const [invalidClaim, setInvalidClaim] = useState<boolean>(false);

  const validateClaim = async () => {
    const sessions = await database
      .get(Session.table)
      .query(Q.where('id', Q.oneOf(selectedItems)));
    const groupedSessions = _.groupBy(sessions, 'patientId');
    return Object.keys(groupedSessions).length <= 1;
  };

  const createClaim = async () => {
    const isValid = await validateClaim();
    if (!isValid) {
      setInvalidClaim(true);
      return;
    }
    const initialSession = await database
      .get(Session.table)
      .find(selectedItems[0]);

    const patient = await initialSession.patient;

    const insurances = await patient.activeInsurances;

    const primaryInsurance = insurances?.[0];

    let plan = null;
    if (primaryInsurance) {
      plan = await primaryInsurance.plan;
    }

    const feeSchedule = plan?.feeSchedule || [
      {
        cpt: '',
        description: '',
        modifier1: '',
        modifier2: '',
        modifier3: '',
        modifier4: '',
        rate: '0',
      },
    ];

    const claim = await database.write(async () => {
      return await database.get(Claim.table).create(entity => {
        entity.partition = selectedGroup;
        entity.patientId = initialSession.patientId;
        entity.status = 'unsubmitted';
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
    });

    for (const sessionId of selectedItems) {
      const session = await database.get(Session.table).find(sessionId);

      await session.updateEntity({
        claimId: claim.id,
      });

      const startTime = moment(session.editedStartTimestamp);
      const startDateMod = moment(session.sessionDate);
      startDateMod.set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: 0,
        millisecond: 0,
      });
      const startDate = startDateMod.toDate();

      const endTime = moment(session.editedEndTimestamp);
      const endDateMod = moment(session.sessionDate);
      endDateMod.set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: 0,
        millisecond: 0,
      });
      const endDate = endDateMod.toDate();

      const durationInMins = moment(session.editedEndTimestamp).diff(
        session.editedStartTimestamp,
        'minutes',
      );
      const units =
        session?.cpt.length &&
        session?.cpt.length === 1 &&
        session?.cpt[0] === 'H2012'
          ? Math.floor(durationInMins / 60)
          : Math.round(durationInMins / 15);

      for (const cpt of session?.cpt || []) {
        const serviceLine = feeSchedule?.find((line: any) => line.cpt === cpt);

        await database.write(async () => {
          await database.get(ServiceLine.table).create(entity => {
            entity.partition = selectedGroup;
            entity.claimId = claim.id;
            entity.sessionId = session.id;
            entity.userId = session.renderingId || session.userId;
            entity.startDate = startDate;
            entity.endDate = endDate;
            entity.cpt = cpt;
            entity.description = serviceLine?.description || '';
            entity.modifier1 = '';
            entity.modifier2 = '';
            entity.modifier3 = '';
            entity.modifier4 = '';
            entity.location = '';
            entity.diagnosisPointer = '1';
            entity.units = `${units}`;
            entity.amount = `${
              parseFloat(serviceLine?.billingRate || '0', 10) * units
            }`;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        });
      }
    }
    setSelectedItems([]);
    navigation.navigate('ClaimProfile', {
      id: claim.id,
    });
  };

  const confirmExclude = async () => {
    for (const sessionId of selectedItems) {
      const session = await database.get(Session.table).find(sessionId);

      await session.updateEntity({
        claimId: 'exclude',
      });
    }
    setSelectedItems([]);
    setShowExclude(false);
  };

  const reincludeSessions = async () => {
    for (const sessionId of selectedItems) {
      const session = await database.get(Session.table).find(sessionId);
      await session.updateEntity({
        claimId: '',
      });
    }
    setSelectedItems([]);
  };

  return (
    <View
      style={[
        styles.flex,
        styles.column,
        styles.positionRelative,
        styles.paddingLeft,
      ]}>
      <Modal
        show={[showExclude, setShowExclude]}
        title={'EXCLUDE SESSIONS'}
        footer={
          <>
            <RHButton
              secondary
              mode="outlined"
              textColor={Colors.RAVEN_BLACK}
              onPress={() => setShowExclude(false)}>
              CANCEL
            </RHButton>
            <RHSeparator width={16} />
            <RHButton
              secondary
              mode="contained"
              onPress={async () => confirmExclude()}>
              YES, EXCLUDE
            </RHButton>
          </>
        }>
        <Text style={[Typography.P1]}>
          Are you sure you want to exclude these Sessions?
        </Text>
      </Modal>
      <Modal
        show={[invalidClaim, setInvalidClaim]}
        title={'Invalid Claim'}
        footer={
          <>
            <RHSeparator width={16} />
            <RHButton
              secondary
              mode="contained"
              onPress={() => setInvalidClaim(false)}>
              OK
            </RHButton>
          </>
        }>
        <Text style={[Typography.P1]}>
          Please select Sessions for only one Client
        </Text>
      </Modal>
      <FormSectionHeader title={translations('create_claim')} />
      <View
        style={[styles.row, styles.marginLVertical, styles.paddingHorizontal]}>
        <View style={[styles.width36]} />
        <View
          style={[
            styles.row,
            styles.flex,
            styles.paddingLVertical,
            styles.backgroundColorPrimary50,
            styles.borderRadius,
            styles.marginLeft,
            styles.paddingHorizontal,
          ]}>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
              Service Date
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
              Time
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
              Billing Codes
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
              Location
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
              Units
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
              Est. Amount
            </Text>
          </View>
          <View style={[styles.width36]} />
        </View>
      </View>
      <ScrollView
        style={[
          styles.flex,
          styles.backgroundColorWhite,
          styles.paddingBottom,
          styles.marginBottom,
          styles.padding,
        ]}
        contentContainerStyle={[
          styles.paddingBottom,
          styles.marginBottom,
          styles.affixPadding,
        ]}>
        <View style={[styles.flex]}>
          {patients.map(item => (
            <PatientClaim
              key={`patient-claim-${item.id}`}
              item={item}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              filters={filters}
              showExcludedSessions={filters?.showExcludedSessions}
            />
          ))}
        </View>
      </ScrollView>

      <View
        style={[
          styles.affix,
          styles.paddingHorizontal,
          styles.row,
          styles.justifySpaceBetween,
          styles.elevation,
        ]}>
        <View style={[styles.column, styles.justifyCenter]}>
          {/*<RHButton*/}
          {/*  mode="contained"*/}
          {/*  onPress={() => {*/}
          {/*    if (currentStep === 0) {*/}
          {/*      validate(true);*/}
          {/*    } else {*/}
          {/*      evaluateStep(currentStep);*/}
          {/*      setCurrentStep(prevStep => prevStep - 1);*/}
          {/*    }*/}
          {/*  }}>*/}
          {/*  {currentStep === 0 ? (*/}
          {/*    translations('cancel_button')*/}
          {/*  ) : (*/}
          {/*    <>*/}
          {/*      <Icon name="arrow-left" size={16} style={styles.marginMRight} />*/}
          {/*      {translations('back')}*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*</RHButton>*/}
        </View>

        <View style={[styles.row, styles.alignCenter]}>
          <View style={[styles.column, styles.justifyCenter]}>
            <RHButton
              disabled={!selectedItems.length}
              onPress={async () =>
                filters?.showExcludedSessions
                  ? reincludeSessions()
                  : setShowExclude(true)
              }
              textColor={
                !selectedItems.length ? Colors.RAVEN_WHITE : Colors.RAVEN_BLACK
              }
              color={
                !selectedItems.length ? Colors.RAVEN_WHITE : Colors.RAVEN_BLACK
              }
              mode={!selectedItems.length ? 'contained' : 'outlined'}>
              {filters?.showExcludedSessions
                ? translations('reinclude_sessions')
                : translations('exclude_sessions')}
            </RHButton>
          </View>
          {!filters?.showExcludedSessions ? (
            <>
              <Separator width={10} />
              <View style={[styles.column, styles.justifyCenter]}>
                <RHButton
                  disabled={!selectedItems.length}
                  mode="contained"
                  onPress={() => {
                    createClaim();
                  }}>
                  {translations('create_claim')}
                </RHButton>
              </View>
            </>
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(['filters'], ({database, authentication, filters}: any) => {
    const filter = [];
    if (filters.clients.length) {
      filter.push(Q.where('id', Q.oneOf(filters.clients)));
    }

    return {
      patients: database
        .get(Patient.table)
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
          ...filter,
          Q.sortBy('first_name', Q.asc),
        ),
    };
  }),
)(CreateClaims);
