import EStyleSheet from 'react-native-extended-stylesheet';
import {memoize} from 'lodash';
import {Colors} from 'src/styles/index';
import {boxShadow} from 'src/styles/mixins';
import {Typography} from 'src/styles/index';

const getNumColumns = width => {
  if (width > 1920) {
    return 5;
  } else if (width > 1440) {
    return 4;
  } else if (width > 1024) {
    return 3;
  } else if (width > 768) {
    return 2;
  } else {
    return 1;
  }
};

const getStyles = memoize((width, height) =>
  EStyleSheet.create({
    cardColumn: {flex: 1 / getNumColumns(width)},
    card: {
      borderRadius: 10,
      backgroundColor: '#FFFFFF',
      ...boxShadow(Colors.RAVEN_BLACK, {height: 6, width: 0}, 4, 0.08),
      minHeight: 60,
      marginBottom: 20,
      marginHorizontal: 20,
    },
    blackCard: {
      borderRadius: 10,
      backgroundColor: Colors.RAVEN_BLACK,
      ...boxShadow(Colors.RAVEN_BLACK, {height: 6, width: 0}, 4, 0.08),
      minHeight: 60,
      marginBottom: 20,
      marginHorizontal: 20,
    },
    cardFlat: {
      borderRadius: 10,
      backgroundColor: '#FFFFFF',
      minHeight: 60,
    },
    input: {backgroundColor: 'white'},
    helper: {height: 24},
    image: {
      width: '100%',
      height: '100%',
      resizeMode: 'stretch',
      backgroundColor: 'transparent',
    },
    iconButton: {
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      height: 44,
      maxWidth: 44,
      minWidth: 44,
      width: 44,
      borderRadius: 22,
    },
    radioButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    well: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      backgroundColor: Colors.GRAY_50,
      borderRadius: 10,
    },
    tabActive: {
      borderBottomWidth: 5,
      borderBottomColor: 'black',
    },
    separator: {
      width: '100%',
      height: 1,
      backgroundColor: Colors.PRIMARY_200,
    },
    graphLabels: {fontSize: 10, fill: Colors.GRAY_500},
    circle: {
      display: 'flex',
      position: 'absolute',
      zIndex: 30,
      top: 0,
      right: 0,
      paddingHorizontal: 4,
      borderRadius: 10,
      backgroundColor: 'red',
    },
    slider: {
      marginTop: -38,
      height: 50,
    },
    cardBody: {height: 200},
    cursorText: {
      cursor: 'text',
    },
    date: {
      borderRadius: 16,
      height: 32,
      width: 32,
    },
    dot: {
      backgroundColor: Colors.BLUE_700,
      borderRadius: 2,
      height: 4,
      width: 4,
    },
    dotL: {
      borderRadius: 6,
      height: 12,
      width: 12,
    },
    knobStyle: {
      height: 5,
      width: 100,
      backgroundColor: '#C4C4C4',
      borderRadius: 30,
    },
    affix: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'white',
      minHeight: 86,
    },
    affixPadding: {
      paddingBottom: 100,
    },
    emptyContainer: {
      minHeight: 300,
      alignItems: 'center',
      justifyContent: 'center',
    },
    emptyTitle: {
      ...Typography.P3,
      color: Colors.TEXT_PRIMARY,
      marginTop: 8,
    },
    border: {borderWidth: 1},
    borderL: {borderWidth: 4},
    borderTopWidth: {borderTopWidth: 1},
    borderLeftWidth: {borderLeftWidth: 1},
    borderRightWidth: {borderRightWidth: 1},
    borderBottomWidth: {borderWidth: 1},
    borderBottomWidth0: {borderBottomWidth: 0},
    borderColorPrimary50: {borderColor: Colors.PRIMARY_50},
    borderColorPrimary200: {borderColor: Colors.PRIMARY_200},
    borderColorPrimary300: {borderColor: Colors.PRIMARY_300},
    borderPrimary: {borderColor: Colors.RAVEN_BLACK},
    borderColorBlue: {borderColor: '#4C9EFF'},
    borderColorGray: {borderColor: '#393A42'},
    borderColorWhite: {borderColor: Colors.RAVEN_WHITE},
    borderColorSuccess: {borderColor: Colors.SUCCESS},
    borderColorSuccessDark: {borderColor: Colors.SUCCESS_DARK},
    borderColorError: {borderColor: Colors.ERROR},
    borderColorErrorDark: {borderColor: Colors.ERROR_DARK},
    borderColorGray400: {borderColor: Colors.GRAY_400},
    borderColorSecondary800: {borderColor: Colors.SECONDARY_800},
    borderTop: {
      borderTopWidth: 1,
      borderColor: '#E5E5E5',
    },
    borderBottom: {
      borderBottomWidth: 1,
      borderColor: '#E5E5E5',
    },
    borderRadiusSM: {borderRadius: 4},
    borderRadiusM: {borderRadius: 8},
    borderRadius: {borderRadius: 12},
    borderRadius20: {borderRadius: 20},
    borderRadius50: {borderRadius: 50},
    borderRadius100: {borderRadius: 100},
    pillBorderRadius: {borderRadius: 24},
    borderTopRightRadius20: {borderTopRightRadius: 20},
    borderTopLeftRadius20: {borderTopLeftRadius: 20},
    borderBottomRightRadius20: {borderBottomRightRadius: 20},
    borderBottomLeftRadius20: {borderBottomLeftRadius: 20},
    borderTopRightRadius: {borderTopRightRadius: 10},
    borderTopLeftRadius: {borderTopLeftRadius: 10},
    borderBottomRightRadius: {borderBottomRightRadius: 10},
    borderBottomLeftRadius: {borderBottomLeftRadius: 10},
    elevation: {
      ...boxShadow(Colors.RAVEN_BLACK),
    },
    elevationSM: {
      ...boxShadow(Colors.RAVEN_BLACK, {width: 0, height: 2}, 4, 0.3),
    },
    elevation0: {
      shadowOffset: {width: 0, height: 0},
      shadowRadius: 0,
    },
    flex13: {flex: 1.3},
    flex: {flex: 1},
    flex0: {flex: 0},
    flex03: {flex: 0.3},
    flex05: {flex: 0.5},
    flex07: {flex: 0.7},
    flex2: {flex: 2},
    flex3: {flex: 3},
    flexWrap: {flexWrap: 'wrap'},
    flexNoWrap: {flexWrap: 'no-wrap'},
    flexShrink: {flexShrink: 1},
    flexShrink0: {flexShrink: 0},
    flexGrow: {flexGrow: 1},
    column: {flexDirection: 'column'},
    row: {flexDirection: 'row'},
    container: {flexDirection: 'row'},
    '@media (max-width: 768)': {
      container: {flexDirection: 'column'},
    },
    tabletContainer: {flexDirection: 'row'},
    '@media (max-width: 1024)': {
      tabletContainer: {flexDirection: 'column'},
    },
    toggleButton: {
      ...Typography.P3,
      color: Colors.RAVEN_BLACK,
      paddingHorizontal: 15,
      alignSelf: 'flex-start',
      borderRadius: 50,
      height: 20,
      alignContent: 'center',
    },
    toggleButtonSelected: {
      color: Colors.RAVEN_WHITE,
      backgroundColor: Colors.RAVEN_BLACK,
    },
    overflowHidden: {overflow: 'hidden'},
    overflowScroll: {overflow: 'scroll'},
    overflowAuto: {overflow: 'auto'},
    overflowVisible: {overflow: 'visible'},
    textOverflowEllipsis: {textOverflow: 'ellipsis'},
    textOverflowClip: {textOverflow: 'clip'},
    justifySpaceBetween: {justifyContent: 'space-between'},
    justifySpaceAround: {justifyContent: 'space-around'},
    justifyCenter: {justifyContent: 'center'},
    justifyStart: {justifyContent: 'flex-start'},
    justifyEnd: {justifyContent: 'flex-end'},
    justifyEvenly: {justifyContent: 'space-evenly'},
    alignCenter: {alignContent: 'center', alignItems: 'center'},
    alignEnd: {alignItems: 'flex-end'},
    alignStart: {alignItems: 'flex-start'},
    alignStretch: {alignItems: 'stretch'},
    alignSelfStart: {alignSelf: 'flex-start'},
    alignSelfCenter: {alignSelf: 'center'},
    alignSelfEnd: {alignSelf: 'flex-end'},
    textAlignCenter: {textAlign: 'center'},
    textAlignLeft: {textAlign: 'left'},
    textAlignRight: {textAlign: 'right'},
    textAlignTop: {textAlignVertical: 'top'},
    positionAbsolute: {position: 'absolute'},
    positionRelative: {position: 'relative'},
    backgroundColorPrimary50: {backgroundColor: Colors.PRIMARY_50},
    backgroundColorPrimary100: {backgroundColor: Colors.PRIMARY_100},
    backgroundColorPrimary200: {backgroundColor: Colors.PRIMARY_200},
    backgroundColorPrimary300: {backgroundColor: Colors.PRIMARY_300},
    backgroundColorPrimary800: {backgroundColor: Colors.PRIMARY_800},
    backgroundColorPrimary900: {backgroundColor: Colors.PRIMARY_900},
    backgroundColorSecondary200: {backgroundColor: Colors.SECONDARY_200},
    backgroundColorSecondary300: {backgroundColor: Colors.SECONDARY_300},
    backgroundColorSecondary400: {backgroundColor: Colors.SECONDARY_400},
    backgroundColorSecondary600: {backgroundColor: Colors.SECONDARY_600},
    backgroundColorSecondary700: {backgroundColor: Colors.SECONDARY_700},
    backgroundColorSecondary800: {backgroundColor: Colors.SECONDARY_800},
    backgroundColorGray300: {backgroundColor: Colors.GRAY_300},
    backgroundColorArctic: {backgroundColor: Colors.ARCTIC},
    backgroundColorTeal: {backgroundColor: Colors.TEAL_500},
    backgroundColorTeal300: {backgroundColor: Colors.TEAL_300},
    backgroundColorRuby: {backgroundColor: Colors.RUBY_500},
    backgroundColorLime: {backgroundColor: Colors.LIME_500},
    backgroundColorOrange: {backgroundColor: Colors.ORANGE},
    backgroundColorTorchRedLight: {backgroundColor: Colors.TORCH_RED_LIGHT},
    backgroundColorViolet: {backgroundColor: Colors.VIOLET_500},
    backgroundColorWhite: {backgroundColor: Colors.RAVEN_WHITE},
    backgroundColorBlack: {backgroundColor: Colors.RAVEN_BLACK},
    backgroundColorOverlay: {backgroundColor: 'rgba(0, 0, 0, 0.7)'},
    backgroundColorBlue: {backgroundColor: '#4C9EFF'},
    backgroundColorGray: {backgroundColor: '#25262F'},
    backgroundColorCompleted: {backgroundColor: '#0569F7'},
    backgroundColorRequired: {backgroundColor: '#F20F4B'},
    backgroundColorTransparent: {backgroundColor: 'transparent'},
    backgroundColorSuccessDark: {backgroundColor: Colors.SUCCESS_DARK},
    backgroundColorSuccess: {backgroundColor: Colors.SUCCESS},
    backgroundColorErrorDark: {backgroundColor: Colors.ERROR_DARK},
    backgroundColorError: {backgroundColor: Colors.ERROR},
    resizeModeContain: {resizeMode: 'contain'},
    top0: {top: 0},
    top64: {top: 64},
    left0: {left: 0},
    right0: {right: 0},
    bottom0: {bottom: 0},
    textColorWhite: {color: Colors.RAVEN_WHITE},
    textColorBlack: {color: Colors.TEXT_BLACK},
    textColorPrimary: {color: Colors.TEXT_PRIMARY},
    textColorPrimary300: {color: Colors.PRIMARY_300},
    textColorPrimary700: {color: Colors.PRIMARY_700},
    textColorPrimary900: {color: Colors.PRIMARY_900},
    textColorSecondary: {color: Colors.TEXT_SECONDARY},
    textColorSecondaryColor: {color: Colors.SECONDARY_800},
    textColorDisabled: {color: Colors.TEXT_DISABLED},
    textColorSuccess: {color: Colors.SUCCESS},
    textColorLink: {color: Colors.LINK},
    textColorError: {color: Colors.ERROR},
    errorTextColor: {color: Colors.ERROR},
    linkTextColor: {color: '#3144F3'},
    mildColor: {color: '#4FF5AF'},
    moderateColor: {color: '#F7AA4F'},
    severeColor: {color: '#F12E5D'},
    noResultsColor: {color: '#909093'},
    textTransformCapitalize: {textTransform: 'capitalize'},
    textTransformUppercase: {textTransform: 'uppercase'},
    textTransformNone: {textTransform: 'none'},
    width20: {width: 20},
    width36: {width: 36},
    width40: {width: 40},
    width42: {width: 42},
    width50: {width: 50},
    width80: {width: 80},
    width100: {width: 100},
    width109: {width: 109},
    width150: {width: 150},
    width200: {width: 200},
    width300: {width: 300},
    menuMinWidth: {minWidth: 350},
    windowWidth: {width},
    windowHeight: {height},
    windowHeight75: {height: height * 0.75},
    windowHeight90: {height: height * 0.9},
    maxSliderWidth: {width: 380},
    maxHeight500: {maxHeight: 500},
    maxHeight250: {maxHeight: 250},
    maxHeight100: {maxHeight: 100},
    maxSkillCardWidth: {maxWidth: 400},
    maxWidth540: {maxWidth: 540},
    menuMaxWidth: {maxWidth: 420},
    maxModalWidth: {maxWidth: 720},
    width: {width: '100%'},
    maxWidth: {width: '100%'},
    maxWindowWidth90: {maxWidth: width * 0.9},
    height: {height: '100%'},
    height6: {height: 6},
    height14: {height: 14},
    height40: {height: 40},
    height50: {height: 50},
    height76: {height: 76},
    height200: {height: 200},
    height220: {height: 220},
    height300: {height: 300},
    height400: {height: 400},
    maxHeight: {maxHeight: '100%'},
    maxHeight50: {maxHeight: '50%'},
    maxHeight25: {maxHeight: '25%'},
    maxHeight200: {maxHeight: 200},
    maxHeight400: {maxHeight: 400},
    paddingXS: {padding: 2},
    paddingXSTop: {paddingTop: 2},
    paddingXSBottom: {paddingBottom: 2},
    padding0: {padding: 0},
    padding0Horizontal: {paddingHorizontal: 0},
    padding0Vertical: {paddingVertical: 0},
    padding0Top: {paddingTop: 0},
    padding0Bottom: {paddingBottom: 0},
    padding0Left: {paddingLeft: 0},
    padding0Right: {paddingRight: 0},
    padding: {padding: 20},
    paddingHorizontal: {paddingHorizontal: 20},
    paddingVertical: {paddingVertical: 20},
    paddingTop: {paddingTop: 20},
    paddingBottom: {paddingBottom: 20},
    paddingLeft: {paddingLeft: 20},
    paddingRight: {paddingRight: 20},
    paddingSM: {padding: 4},
    paddingSMHorizontal: {paddingHorizontal: 4},
    paddingSMVertical: {paddingVertical: 4},
    paddingSMTop: {paddingTop: 4},
    paddingSMBottom: {paddingBottom: 4},
    paddingSMLeft: {paddingLeft: 4},
    paddingSMRight: {paddingRight: 4},
    paddingM: {padding: 8},
    paddingMHorizontal: {paddingHorizontal: 8},
    paddingMVertical: {paddingVertical: 8},
    paddingMTop: {paddingTop: 8},
    paddingMBottom: {paddingBottom: 8},
    paddingMLeft: {paddingLeft: 8},
    paddingMRight: {paddingRight: 8},
    paddingL: {padding: 12},
    paddingLHorizontal: {paddingHorizontal: 12},
    paddingLVertical: {paddingVertical: 12},
    paddingLTop: {paddingTop: 12},
    paddingLBottom: {paddingBottom: 12},
    paddingLLeft: {paddingLeft: 12},
    paddingLRight: {paddingRight: 12},
    padding10Bottom: {paddingBottom: '10%'},
    margin0: {margin: 0},
    margin0Horizontal: {marginHorizontal: 0},
    margin0Vertical: {marginVertical: 0},
    margin0Top: {marginTop: 0},
    margin0Bottom: {marginBottom: 0},
    margin0Left: {marginLeft: 0},
    margin0Right: {marginRight: 0},
    margin: {margin: 20},
    marginHorizontal: {marginHorizontal: 20},
    marginVertical: {marginVertical: 20},
    marginTop: {marginTop: 20},
    marginBottom: {marginBottom: 20},
    marginLeft: {marginLeft: 20},
    marginRight: {marginRight: 20},
    marginXS: {margin: 1},
    marginXSHorizontal: {marginHorizontal: 1},
    marginXSVertical: {marginVertical: 1},
    marginXSTop: {marginTop: 1},
    marginXSBottom: {marginBottom: 1},
    marginXSLeft: {marginLeft: 1},
    marginXSRight: {marginRight: 1},
    marginSM: {margin: 4},
    marginSMHorizontal: {marginHorizontal: 4},
    marginSMVertical: {marginVertical: 4},
    marginSMTop: {marginTop: 4},
    marginSMBottom: {marginBottom: 4},
    marginSMLeft: {marginLeft: 4},
    marginSMRight: {marginRight: 4},
    marginM: {margin: 8},
    marginMHorizontal: {marginHorizontal: 8},
    marginMVertical: {marginVertical: 8},
    marginMTop: {marginTop: 8},
    marginMBottom: {marginBottom: 8},
    marginMLeft: {marginLeft: 8},
    marginMRight: {marginRight: 8},
    marginL: {margin: 12},
    marginLHorizontal: {marginHorizontal: 12},
    marginLVertical: {marginVertical: 12},
    marginLTop: {marginTop: 12},
    marginLBottom: {marginBottom: 12},
    marginLLeft: {marginLeft: 12},
    marginLRight: {marginRight: 12},
    marginXLTop: {marginTop: 16},
    marginXXLTop: {marginTop: 24},
    marginAuto: {margin: 'auto'},
    marginAutoHorizontal: {marginHorizontal: 'auto'},
    marginAutoVertical: {marginVertical: 'auto'},
    marginAutoTop: {marginTop: 'auto'},
    marginAutoBottom: {marginBottom: 'auto'},
    marginAutoLeft: {marginLeft: 'auto'},
    marginAutoRight: {marginRight: 'auto'},
    scrollPadding: {paddingBottom: 300},
    zIndex: {zIndex: 1},
    zIndex100: {zIndex: 100},
    show: {display: 'flex'},
    hide: {display: 'none'},
    showMobile: {display: 'none'},
    hideMobile: {
      display: 'flex',
      flexDirection: 'row',
    },
    '@media (max-width: 768) and (orientation: portrait)': {
      showMobile: {
        display: 'flex',
        flexDirection: 'row',
      },
      hideMobile: {display: 'none'},
    },
  }),
);

export default getStyles;
