import React from 'react';
import {View} from 'react-native';

interface props {
  height?: string | number;
  width?: string | number;
}
const Separator = ({height, width}: props) => {
  let style = {width, height};
  return <View style={style} />;
};

export default Separator;
