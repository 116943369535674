import React from 'react';
// import {Tooltip} from 'src/design-system';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
// import {useDimensions} from '@react-native-community/hooks';

const ProgramTooltip = ({name}: any) => {
  const styles = useStyle();
  // const dimensions = useDimensions();
  // const [width, setWidth] = useState(dimensions.window.width);
  // const overflow = width / name.length;

  // const onLayout = (event: any) => {
  const onLayout = () => {
    // setWidth(event.nativeEvent.layout.width);
  };

  return (
    <View onLayout={onLayout}>
      {/*{overflow <= 14 ? (*/}
      {/*  <Tooltip*/}
      {/*    anchor={*/}
      {/*      <Text*/}
      {/*        style={[Typography.P2_MEDIUM, styles.textColorPrimary]}*/}
      {/*        numberOfLines={1}*/}
      {/*        ellipsizeMode="tail">*/}
      {/*        {name}*/}
      {/*      </Text>*/}
      {/*    }>*/}
      {/*    <View>*/}
      {/*      <Text style={[Typography.CAPTION]}>{name}</Text>*/}
      {/*    </View>*/}
      {/*  </Tooltip>*/}
      {/*) : (*/}
      <Text
        style={[Typography.P2_MEDIUM, styles.textColorPrimary]}
        numberOfLines={1}>
        {name}
      </Text>
      {/*)}*/}
    </View>
  );
};

export default ProgramTooltip;
