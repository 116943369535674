import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
import {useStyle} from 'src/providers/style';
import {Input as FirstNameInput} from 'src/hook-form-inputs/first-name';
import {Input as MiddleNameInput} from 'src/hook-form-inputs/middle-name';
import {Input as LastNameInput} from 'src/hook-form-inputs/last-name';
import {Input as PhoneNumberInput} from 'src/hook-form-inputs/phone-number';
import {Input as EmailInput} from 'src/hook-form-inputs/email';
import {Separator} from 'src/common-components/atoms';
import {NPI} from 'src/hook-form-inputs/';
import TINInput from 'src/hook-form-inputs/tin';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Payer} from 'src/models';
import {Q} from '@nozbe/watermelondb';
import {Typography} from 'src/styles';
import CredentialPayerItem from 'src/modules/users/components/credential-payer-item';
import {useFormContext} from 'react-hook-form';

const CredentialForm = ({
  name,
  box,
  entity,
  extraFields = true,
  payers,
  showTaxonomy = false,
  shouldRerender = false,
}: any) => {
  const styles = useStyle();
  const {setValue, trigger} = useFormContext();

  useEffect(() => {
    if (shouldRerender) {
      setValue(`${name}`, entity);
      trigger(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, shouldRerender, name, setValue]);

  return (
    <>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex]}>
          <FirstNameInput
            name={name}
            label={box ? `First Name ${box}` : 'First Name'}
          />
        </View>
        <Separator width={20} height={20} />
        <View style={[styles.flex]}>
          <MiddleNameInput
            name={name}
            label={box ? `Middle Name ${box}` : 'Middle Name'}
          />
        </View>
        <Separator width={20} height={20} />
        <View style={[styles.flex]}>
          <LastNameInput
            name={name}
            label={box ? `Last Name ${box}` : 'Last Name'}
          />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex]}>
          <NPI.Input name={name} label={box ? `NPI ${box}` : 'NPI'} />
        </View>
        <Separator width={20} height={20} />
        <View style={[styles.flex]}>
          <TINInput name={name} />
        </View>
      </View>
      {payers && payers.length > 0 ? (
        <View style={[styles.marginLVertical, styles.paddingHorizontal]}>
          <Text style={[Typography.P3_BOLD]}>Assigned Payers</Text>
          <View style={[styles.marginLVertical]}>
            <View
              style={[
                styles.row,
                styles.borderBottom,
                styles.alignCenter,
                styles.justifyCenter,
                styles.flex,
              ]}>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width]}>
                  <Text style={[Typography.P3]}>Payer</Text>
                </View>
              </View>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                  styles.container,
                ]}>
                <View style={[styles.flex]}>
                  <Text style={[Typography.P3]}>ID #</Text>
                </View>
                {showTaxonomy ? (
                  <View style={[styles.flex]}>
                    <Text style={[Typography.P3]}>Taxonomy</Text>
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </View>
            {payers.map((payer: any, index: number) => {
              return (
                <CredentialPayerItem
                  key={`credential-payer-${payer.id}-${index}`}
                  payerId={payer.id}
                  credentialId={entity.id}
                  index={index}
                  showTaxonomy={showTaxonomy}
                  name={name}
                />
              );
            })}
          </View>
        </View>
      ) : null}
      {extraFields ? (
        <>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex]}>
              <PhoneNumberInput name={name} />
            </View>
            <Separator width={20} height={20} />
            <View style={[styles.flex]}>
              <EmailInput name={name} />
            </View>
          </View>
          <View style={[]}>
            <AddressAutocompleteInput
              name={`${name}.address`}
              label={'Work Address'}
            />
          </View>
        </>
      ) : null}
    </>
  );
};

export default compose(
  withDatabase,
  withObservables(['payers'], ({database, payers = []}: any) => {
    return {
      payers: database
        .get(Payer.table)
        .query(Q.where('id', Q.oneOf(payers)), Q.where('staff_ids', true)),
    };
  }),
)(CredentialForm);
