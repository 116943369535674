import {ActivityIndicator, StyleSheet, View} from 'react-native';
import React from 'react';
import {Colors} from 'src/styles';

interface Props {
  loading: boolean;
  children: JSX.Element | JSX.Element[];
}

const LoaderContainer = ({loading, children}: Props) => {
  return !loading ? (
    <View style={styles.flex}>{children}</View>
  ) : (
    <View style={styles.flex}>
      <ActivityIndicator
        color={Colors.RAVEN_BLACK}
        animating={true}
        size="large"
        style={styles.activityIndicator}
      />
      <View style={styles.opacity} pointerEvents="none">
        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  flex: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
  opacity: {
    opacity: 0.3,
  },
  activityIndicator: {
    position: 'absolute',
    alignSelf: 'center',
  },
});

export default LoaderContainer;
