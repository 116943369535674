import React from 'react';
import {Text, View} from 'react-native';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

const DisplayUserDataSnippet = (props: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const {firstName, lastName, resourceType, gender, age} = props;

  const firstInitial = firstName.length > 0 ? firstName[0].toUpperCase() : 'N';
  const lastInitial = lastName.length > 0 ? lastName[0].toUpperCase() : 'N';
  const initials = firstInitial + lastInitial;
  const isPractitioner = resourceType === 'Practitioner';

  const ageAndGender =
    age && gender ? `${translations('age')} ${age || 10}, ${gender}` : '';

  return (
    <>
      <View style={[styles.row, styles.paddingLRight, styles.marginLVertical]}>
        <View style={[styles.column, styles.alignCenter, styles.justifyCenter]}>
          <RHAvatar
            label={initials}
            color={Colors.RAVEN_BLACK}
            textColor={Colors.RAVEN_WHITE}
            size={32}
          />
        </View>
        <View style={[styles.paddingLLeft, styles.justifyCenter]}>
          <Text
            style={[
              Typography.P3,
              styles.textColorPrimary,
            ]}>{`${firstName} ${lastName}`}</Text>
          {!isPractitioner ? (
            <Text style={[Typography.P3, styles.textColorSecondary]}>
              {ageAndGender}
            </Text>
          ) : (
            <></>
          )}
        </View>
      </View>
    </>
  );
};

export default DisplayUserDataSnippet;
