import React from 'react';
import {BaseScreen, TabBar} from 'src/design-system';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import SessionHistoryTab from '../../components/session-history';

const Navigator = createMaterialTopTabNavigator();

function AuditScreen({route}: any) {
  return (
    <BaseScreen header={false}>
      <TabBar navigator={Navigator} location={'Audit'} route={route}>
        <Navigator.Screen name="Sessions">
          {() => <SessionHistoryTab />}
        </Navigator.Screen>
      </TabBar>
    </BaseScreen>
  );
}

export default AuditScreen;
