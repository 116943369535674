import React from 'react';
import {appointmentLocations} from 'src/form-inputs/appointment-location-input';
import CheckboxInput from 'src/hook-form-wrapper/checkbox-input';

interface Props {
  name?: string;
  allowSelectAll?: boolean;
  search?: boolean;
}

const LocationCheckbox = ({name, allowSelectAll, search = false}: Props) => {
  return (
    <CheckboxInput
      name={(name ? name + '.' : '') + 'locations'}
      items={appointmentLocations}
      allowSelectAll={allowSelectAll}
      search={search}
    />
  );
};

export default LocationCheckbox;
