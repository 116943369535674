export default {
  make_primary: 'Assign as Primary Provider',
  yes_delete: 'YES, DELETE ',
  delete_changes: 'Are you sure you want to delete',
  yes_edit: 'YES, EDIT ',
  appointment: 'Appointment',
  reset: 'Reset',
  apply: 'Apply',
  document_preview_coming_soon: 'Document Preview for Android Coming Soon!',
  down_the_file: 'Download the File on your Device to View.',
  invalid_request: 'Invalid Request',
  npi: 'NPI',
  time_format: 'Use 24hr Format',
  time_format_placeholder:
    'Toggle ON to use 24 hour time format by default when displaying time on claims that full under this insurance. You can also adjust the time format in the claim itself.',
  rounding_rules: 'Rounding Rules',
  rounding_rules_placeholder:
    'Select when to round up to the nearest unit when a user runs over or under time. Each unit is equivalent to 15 minutes. (E.g. If 8 minutes is selected, when a user runs a session for 38 minutes, it will round up to 3 units used instead of 2 units used.)',
  search_input: 'Search...',
  street_address: 'Street Address',
  address: 'Address',
  birth_date: 'Birth Date',
  event_repeats: 'Event Repeats',
  session_location: 'Session Location',
  all: 'ALL',
  location: 'Location',
  add_taxonomy: 'Add Taxonomy',
  taxonomy: 'Taxonomy',
  none: 'None',
  save: 'Save',
  cancel: 'Cancel',
  add_more: 'Add More',
  session_type: 'Session Type',
  appt_type: 'Appointment Type',
  view_by: 'View By',
  appointment_type: 'Appointment Type',
  direct_therapy: 'Direct Therapy',
  caregiver_guidance: 'Caregiver Guidance',
  indirect_therapy: 'Indirect Therapy',
  non_billable: 'Non-Billable',
  repeat_on: 'Repeat On*',
  ends: 'Ends*',
  never: 'Never',
  on: 'On',
  after: 'After',
  birth_date_input: 'Birth Date (MM/DD/YYYY)',
  city: 'City',
  date_input: 'Date (MM/DD/YYYY)',
  diagnoses: 'Diagnoses',
  undiagnosed: 'Undiagnosed',
  first_name: 'First Name',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  identifier: 'Identifier',
  student_id: 'Student ID',
  intake_date_input: 'Intake Date (MM/DD/YYYY)',
  intake_date: 'Intake Date',
  last_name: 'Last Name',
  multi_client_input: 'Client(s)',
  select_multi_client: 'Select Client(s)',
  multi_staff_input: 'Staff Member(s)',
  select_staff_client: 'Select Staff',
  billable: 'Billable',
  permission_level: 'Permission Level',
  permission: 'Permission',
  phone_number_input: 'Phone Number (###) ###-####',
  phone_number: 'Phone Number',
  drag_to_reorder: 'Drag to Reorder',
  mobile: 'Mobile',
  phone_type: 'Phone Type',
  relationship: 'Relationship',
  position: 'Position',
  postal_code: 'Postal Code',
  credential: 'Credentials',
  cba: 'CBA - Certified Behavior Analyst',
  rbt: 'RBT - Registered Behavioral Technician',
  bcaba: 'BCaBa - Board Certified Assistant Behavior Analyst',
  bcba: 'BCBA - Board Certified Behavior Analyst',
  bcbad: 'BCBA-D - Board Certified Behavior Analyst-Doctoral',
  license_number: 'License Number',
  time_displayed: 'Time (HH:mm)',
  state: 'State',
  active: 'Active',
  inactive: 'Inactive',
  program_builder: 'Program Builder',
  choose_file_button: 'Choose File',
  lowercase_cancel: 'Cancel',
  lowercase_Save: 'Save',
  select_prompts: 'Select Prompts',
  auth_form_button_reset: 'Reset',
  auth_form_password: 'Password',
  or: 'or',
  email: 'Email',
  password: 'Password',
  offline_required: 'Please connect to an internet connection to sign in.',
  email_required: 'Please enter a valid email address.',
  email_invalid: 'Invalid Email.',
  password_valid: 'Please enter a valid password.',
  auth_form_internet_connection:
    'Please connect to an internet connection to sign in',
  auth_form_back_to_login: 'Back to Login',
  confirm_new_email: 'Confirm New Email',

  //Authentication>Components>Login-Form
  auth_form_forgot_password: 'Forgot Password',
  auth_form_button: 'Login',
  auth_form_no_account: "Don't Have An Account?",
  auth_form_sign_up: 'Sign Up',
  auth_form_email: 'Email',
  new_email_alert:
    'A verification email has been sent to the updated email address. Please check your inbox to validate the changes.',

  //Authentication>Components>Reset-Password-Form
  auth_form_new_password: 'New Password',
  auth_form_min_length_message: 'Password must have at least 8 characters.',
  auth_form_lowercase_message:
    'Password must have at least one lower-case letter.',
  auth_form_uppercase_message:
    'Password must have at least one upper-case letter.',
  auth_form_confirm_password: 'Confirm Password',
  password_symbol: 'Password must have at least one symbol.',
  password_confirmation_required: 'Must confirm previous password.',
  password_confirmation: 'Must match the previously entered password.',
  auth_form_phone_number: 'Phone #',
  auth_form_title: 'Title',
  auth_screen_password_reset: 'Please enter your email to reset your password.',
  auth_screen_username_reset: 'Please confirm to reset your email.',
  error_occurred: 'An error has occurred.',
  please_try_again: 'Please try again.',
  reset_password_title: 'Your password reset request has been sent.',
  reset_password_message_start: 'If an account exists for',
  reset_password_message_end:
    'you will receive an email with instructions on how to reset your password shortly. Please check your spam folder if you did not see the email in your inbox.',
  auth_screen_organizations: 'Organizations',
  title: 'Title',
  auth_screen_select_organization_to_access:
    'Select an organization to access.',
  auth_screen_select_organization: 'Select an Organization',
  auth_screen_no_organization:
    'You currently do not belong to any organizations. Use the form below to create one.',
  auth_screen_organization_access:
    'These are organizations you have access to:',
  auth_screen_not_seeing_organization:
    'Not seeing your organization in this list?',
  auth_screen_different_email: 'Try a different email.',
  auth_screen_sync_error:
    "Sync failure we're attempting to resync, if it does not resolve itself in a few seconds, please logout and log back in.",
  auth_screen_thank_you:
    'Thank you for signing up! A member of our team will reach out to you shortly.',
  auth_screen_demo: 'Sign up now to book your demo.',
  hello: 'Hello',
  notes_due: 'Notes Due',
  no_clients: 'You have no assigned clients.',
  view_client_list: 'VIEW CLIENT LIST >',
  age: 'Age',
  age_in_months: 'Display Age as Years & Months',
  unassign_client: 'Unassign Client',
  view_client_profile: 'View Client Profile',
  sessions_today_one: 'You have',
  sessions_today_two: 'sessions today',
  session_progress: 'Today’s Session Progress',
  completed: 'Completed',
  session_notes_due: 'Session Notes Due',
  not_started: 'Not Started',
  locked_session: 'Locked Session',
  today_sessions: 'Today’s Sessions',
  no_session: 'No sessions today',
  view_schedule: 'VIEW SCHEDULE >',
  no_notes_due: 'No session notes due.',
  no_diagnoses: 'No Diagnoses have been enabled for your Organization',
  remove_from_list: 'Remove from list',
  no_locations_found: 'No locations found',
  locations: 'Locations',
  session_information: 'Session Information',
  clinical_note: 'Clinical Note',
  show_authors_and_timestamps: 'Display Note Authors and Time Stamps',
  session_data: 'Session Data',
  add_additional_fields: 'Add Additional Fields',
  additional_fields: 'Additional Fields',

  //Organization>Components>Note-Template
  last_edited: 'Last Edited',
  published: 'Published',
  draft: 'Draft',
  antecedents: 'Antecedents',
  consequences: 'Consequences',
  prompts: 'Prompts',
  name: 'Name',
  acronym: 'Acronym',
  add: 'ADD',
  required_role: 'Role name is required.',
  feature: 'Feature',
  create: 'Create',
  edit: 'Edit',
  delete: 'Delete',
  view: 'View',
  view_session: 'View Session',
  assigned: 'Assigned',
  assigned_permissions: 'Assigned Only Permissions',
  assigned_entities:
    'Users can only view the associated entities that are assigned to them.',
  assigned_only: 'Assigned Only',
  roles_and_permissions: 'Roles & Permissions',
  add_role: 'ADD ROLE',
  no_role: 'No Role is selected.',
  tag: 'Tag',
  role: 'Role',
  tags: 'Tags',
  client: 'Client',
  treatment_plan: 'Treatment Plan',
  authorizations: 'Authorizations',
  documents: 'Documents',
  assigned_clients: 'Assigned Clients',
  report: 'Report',
  program: 'Program',
  edit_role: 'Edit Role',
  new_role: 'New Role',
  client_information: 'Client Information',
  summary: 'Summary',
  overview: 'Overview',
  required_organization: 'Organization Name is required.',
  organization_name: 'Organization Name',
  organization_logo: 'Organization Logo',
  logo_appearing: "Your organizations logo that will appear in the app's menu",
  your_organization_name: 'Your Organization Name',
  upload_logo: 'Upload a Logo',
  assessments: 'Assessments',
  skills: 'Skills',
  behaviors: 'Behaviors',
  related_services: 'Related Services',
  tag_type: 'Tag Type',
  add_tag: 'Add Tag',
  edit_tag: 'Edit Tag',
  create_tag: 'Create Tag',
  timesheet: 'Timesheet',
  cpt: 'Procedure Code (CPT)',
  delivery: 'Delivery',
  service_date: 'Service Date',
  service_time: 'Service Time',
  staff_name: 'Staff Name',
  client_name: 'Client Name',
  student_name: 'Student Name',
  student_attendance: 'Student Attendance',
  attended: 'Attended',
  pcd: 'Procedure Code & Description',
  pcn: 'PCN',
  billing_code_rate: 'Billing Code Rate',
  time_worked_hrs: 'Time Worked (Hrs)',
  time_worked_mins: 'Time Worked (Mins)',
  units_of_service: 'Units of Service',
  empty_timesheet_message: 'No client timesheet available at this time.',
  provider_charges: 'Provider Charges',
  provider_rates: 'Provider Rates',
  payment_date: 'Payment Date',
  charged_amount: 'Charged Amount',
  adjusted_amount: 'Adjusted Amount',
  allowed_amount: 'Allowed Amount',
  patient_responsibility: 'Patient Responsibility',
  paid_to_provider: 'Paid to Provider',
  empty_timesheet_message_staff: 'No staff timesheet available at this time.',
  empty_timesheet_message_school: 'No school timesheet available at this time.',
  totals: 'Totals',
  save_button: 'SAVE',
  claim: 'Claim',
  billing_entity: 'Billing Entity',
  patient_entity: 'Patient Entity',
  insurance: 'Insurance',
  service: 'Service',
  services: 'Services',
  payer_information: 'Payer Information',
  additional_claim_information: 'Additional Claim Information',
  create_claim: 'Create Claim',
  submit_claim: 'Submit Claim',
  important_fields:
    'Important fields are missing values - Are you sure you want to submit the claim?',
  exclude_sessions: 'EXCLUDE SESSIONS',
  reinclude_sessions: 'RE-INCLUDE SESSIONS',
  unsubmitted: 'Claims to Submit',
  submitted: 'Submitted',
  approved: 'Approved',
  patient_control_number: 'Patient Control Number',
  add_sessions: 'Add Sessions',
  denied: 'Denied',
  cancel_button: 'CANCEL',
  back: 'BACK',
  yes_revert: 'YES, REVERT',
  yes: 'Yes',
  no: 'No',
  unsaved_changes: 'You have unsaved changes',
  revert_changes: 'Are you sure you want to revert your changes?',
  title_unsaved_changes: 'Unsaved Changes',
  contact_information: 'Contact Information',
  clinician: 'Clinician',
  empty_client_message: 'No results for clients found',
  next_session: 'Next Session',
  schedule_session: 'SCHEDULE A SESSION',
  technicians: 'Technicians',
  search: 'Search',
  start_date: 'Start Date',
  start_date_end_date_validation: 'Start Date must be after End Date',
  end_date_start_date_validation: 'End Date must be after Start Date',
  end_date: 'End Date',
  units: 'Units',
  97151: '97151 - ',
  assessment: 'Assessment',
  97152: '97152 - ',
  supporting_assessment: 'Supporting Assessment',
  '0362T': '0362T - ',
  assessment_multi_tech: 'Assessment > 1 Technician',
  97153: '97153 -',
  direct: 'Direct 1:1',
  97154: '971534 -',
  direct_group_therapy: 'Direct Group',
  97155: '97155 -',
  supervision: 'Supervision',
  '0373T': '0373T -',
  supervision_multi_tech: 'Supervision > 1 Technician',
  97156: '97156 -',
  97157: '97157 -',
  caregiver_guidance_group: 'Group Caregiver Guidance',
  payer: 'Payer',
  add_payer: 'Add Payer',
  add_payer_plans: 'Add Payer Plans',
  payer_name: 'Payer Name',
  payer_id: 'Payer ID',
  total_authorized_units: 'Total Authorized Units: ',
  hrs: 'hrs',
  add_authorization: 'ADD AUTHORIZATION',
  edit_authorization: 'Edit Authorization',
  new_authorization: 'Add Authorization',
  no_authorizations: 'Client has no authorizations',
  form_save: 'Save',
  form_cancel: 'Cancel',
  required_file_name: 'File Name is required.',
  file_name: 'File Name',
  client_notes: 'Client Notes',
  case_notes: 'Case Notes',
  edit_note: 'Edit Note',
  add_note: 'Add Note',
  write_change: 'Write an Environmental Change description ',
  write_factor: 'Write an Environmental Factor description ',
  description: 'Description',
  factor_definition:
    'Definition: A one-time data point collected via interview of caregiver or client and/or observed by clinical team that may have an impact on therapy session.\n\nExamples: Poor sleep/tired, low appetite, tantrum on way to session, crying on entry, etc.',
  change_definition:
    'Definition: A life event that occurs at one point in time that can have ongoing influence on behavior and programming data.\n\nExamples:  New medication or change in dosage, change in family status, change in school setting, co-morbid diagnosis, etc.',
  change_placeholder: 'E.g. Medication Change',
  factor_placeholder: 'E.g. Could not Sleep',
  short_description: 'Short Description',
  add_environmental_factor: 'ADD ENVIRONMENTAL FACTOR',
  update_environmental_factor: 'Update Environmental Factor',
  new_environmental_factor: 'Add Environmental Factor',
  no_environmental_factors: 'Client has no recorded environmental factors',
  environmental_factors: 'Environmental Factors',
  type: 'Type',
  export: 'Export',
  date: 'Date',
  client_id: 'Client Id:',
  clinic_information: 'Clinic Information',
  guardian_information: 'Guardian Information',
  create_new_program: 'Create New Program',
  data_cards_mastered:
    'Individual data cards that with the goal of becoming mastered',
  targets: 'Targets',
  target: 'Target',
  collector: 'Collector',
  set: 'Set',
  time: 'Time',
  value: 'Value',
  add_targets: 'Add Targets',
  error: 'Error',
  info: 'Info',
  introduction_date: 'Introduction Date',
  program_type: 'Type of Program',
  program_description: 'Displayed as the title on all program cards',
  skill_acquisition: 'Skill Acquisition',
  in_progress: 'In Progress',
  missing_values: 'Missing Values',
  on_hold: 'On Hold',
  status: 'Status',
  discontinued: 'Discontinued',
  behavior: 'Behavior',
  trial_by_trial: 'Trial By Trial',
  task_analysis: 'Task Analysis',
  frequency: 'Frequency',
  rate: 'Rate',
  duration: 'Duration',
  collection: 'Collection',
  skill_acquisition_methods: 'Skill Acquisition Methods',
  individual_data_points: 'Individual data points to be collected on',
  data_collection_method: 'Data collection method used for the program.',
  prompt_list: 'Prompt List',
  additional_response: 'Additional response options if not independent',
  created: 'Created',
  measurement: 'Measurement',
  baseline: 'Baseline',
  correct_responses_needed:
    'Correct responses needed to prove mastery and skill treatment.',
  correct_probes: 'Correct Probes',
  test_probes: 'Test Probes',
  mastery_criteria: 'Mastery Criteria',
  time_recorded: 'Time Recorded',
  hour: ' hour ',
  minutes: ' minutes ',
  one_min: '1 minute ',
  seconds: ' seconds',
  one_sec: '1 second',
  number_of_instances: 'Number of Instances',
  occurrences_per: 'Occurrences Per',
  percent_independent: 'Percent Independent',
  correct: 'Correct',
  incorrect: 'Incorrect',
  percent_correct: 'Percent Correct',
  consecutive_sessions: 'Consecutive Sessions',
  staff_min: 'Staff Minimum',
  maintenance_criteria: 'Maintenance Criteria',
  number_of_sessions: 'Number of Sessions',
  day_of_week: 'Day Of Week',
  day_of_month: 'Day Of Month',
  frequency_baseline: 'Frequency of a behavior prior to intervention.',
  duration_baseline: 'Baseline duration of behavior prior to intervention.',
  interval_baseline: 'Average occurrences of behavior prior to intervention.',
  prompted_to_confirm:
    'Prompted to confirm the behavior did not occur over mastery threshold.',
  testing_entire_program:
    'Testing the entire program again to ensure client retention.',
  mastered_targets:
    'Expected percentage correct over particular cadence to ensure client retention.',
  number_correct: 'Number Correct',
  cadence: 'Cadence',
  discriminative_stimulus: 'Specify the discriminative stimulus',
  objective: 'Objective',
  display_objective: 'Display Program Objective',
  overall_goal:
    'The overall purpose and/or goal to be accomplished in this program',
  instructions: 'Instructions',
  detailed_instructions: 'Detailed teaching instructions',
  no_programs_found: 'No programs found.',
  select_tags: 'Select Tags',
  select_all: 'Select All',
  minutes_cap: 'Minutes',
  hours_cap: 'Hours',
  seconds_cap: 'Seconds',
  data_display: 'Data Display',
  data_display_description:
    'Data can be displayed in reporting over specified unit.',
  recently_updated: 'Balls ',
  all_time: 'All Time',
  start_from_scratch: 'Start from Scratch',
  select_program: 'Select The Type Of Program You’d Like To Create',
  skill_targets:
    'Typically best suited for when you need to group targets together. Measure these skill-oriented targets collectively or individually, customize response data, and control collection methods when members use them during Sessions.',
  helpful_collecting: 'Helpful for collecting data such as:',
  discrete_dbt: 'Discrete Trial by Trial (DTT)',
  tta: 'Total Task Analysis (TTA)',
  behavior_tracking: 'Behavior Tracking',
  easiest_way_to_track:
    'The easiest way to track every instance of a behavior. With a simple way to track the duration and frequency of any behavior, criteria can be set up during the building process or after establishing a baseline with the patient.',
  choose_from_a_template: 'Choose from a Template',
  existing_treatment:
    'Start with existing treatment description and instructions',
  clear_section: 'Clear Selection',
  selected: 'Selected',
  next: 'Next',
  acquisition_methods: 'Acquisition Methods',
  tracking_methods: 'Tracking Methods',
  total_trials: 'Total Number of Trials',
  total_trials_programs:
    'Total Number of trials for each target in this program',
  abc_data: 'ABC Data',
  progress: 'Progress',
  csv_downloaded: '.csv was downloaded.',
  percentage_of_instances:
    'Percentage of total instances to indicate a reduction or increase in behavior.',
  desired_behavior: 'Desired Percentage of Behavior',
  baseline_behavior: 'Baseline Percentage of Behavior',
  unit: 'Unit',
  behavior_adjustment: 'Behavior Adjustment',
  rate_adjustment:
    'Determine the intention of the behavior to increase or decrease over time.',
  avg_instances: 'How the program will average instances',
  number_of_occurrences:
    'Number of occurrences that indicate a reduction or increase in behavior',
  num_of_occurrences: 'Number of Occurrences',
  time_threshold_indicates:
    'Time threshold that indicates a reduction or increase in behavior.',
  num_correct_sessions:
    'Percentage correct over consecutive sessions to prove knowledge of target.',
  percentage_correct:
    'Percentage of targets where the client has responded independently.',
  members_before_mastery:
    'Members needed to collect and verify before mastery.',
  interval_type: 'Interval Type',
  select_interval_type: 'Select Interval Type',
  fixed_occurrences: 'Fixed Occurrences',
  fixed: 'Fixed',
  unlimited: 'Unlimited',
  toggle_for_fixed_occurrences:
    'Toggle for a fixed number of interval occurrences in a session.',
  total_number_occurrences: 'Total Number of Occurrences*',
  this_is_required: 'This is required',
  field_is_required: 'This field is required',
  intensity_tracker: 'Intensity Tracker',
  intensity_tracker_description:
    'Turning Intensity Tracking on shows mild, moderate or severe behavior data options for each frequency',
  single_target_is_required: 'At least a single target is required.',
  interval_occurs: 'Occurs For',
  interval_automation: 'Automation',
  interval_automation_description:
    'Toggle whether the interval loops automatically once started.',
  interval_fixed_occurrences: 'Fixed Occurrences',
  interval_fixed_occurrences_description:
    'Toggle for a fixed number of interval occurrences in a session.',
  interval_recurrences: 'Total Number of Occurrences',
  interval_recurrences_description:
    'Toggle for a fixed number of interval occurrences in a session.',
  unit_description: 'How the program will measure rate of an event.',
  rate_mastery_criteria:
    'Minimum occurrences per unit over consecutive sessions to prove mastery.',
  add_a_program: 'Add a Program',
  total_sessions: 'Total Sessions',
  progress_over_time: 'Program Progress Over Time',
  no_data_collected: 'No data collected',
  please_select_client: 'Please select a client',
  select_client: 'Select Client',
  objective_disclaimer: 'Program Objective is limited to 150 characters',
  use_chrome:
    'MacOS users: For an optimal reporting user experience please use Chrome browser',
  date_range: 'Date Range',
  to: 'To',
  from: 'From',
  select: 'Select',
  total_duration: 'Total Duration',
  percentage_of_behavior: 'Percentage of Behavior',
  behavior_tracker: 'Behavior Tracker',
  pdf_downloaded: '.pdf downloaded successfully',
  program_progress_month: 'Program History to display past 5 days.',
  skill_programs: 'Skill Programs',
  behavior_trackers: 'Behavior Trackers',
  programs_in_maintenance: 'Programs In Maintenance',
  programs_mastered: 'Programs Mastered',
  scheduled: 'Scheduled',
  in_session: 'In Session',
  per_session: 'Display Over Sessions',
  display_daily: 'Display Daily Average',
  aggregate: 'Display Aggregate',
  session: 'SESSION',
  baseline_criteria: 'Baseline Criteria',
  program_status: 'Program Status',
  finish_note: 'FINISH NOTE',
  view_recap: 'VIEW RECAP',
  assigned_staff: 'Assigned Staff',
  home: 'Home',
  virtual: 'Virtual',
  office: 'Office',
  community: 'Community',
  school: 'School',
  telehealth: 'Telehealth',
  editing_appointment: 'Editing Appointment',
  mon: 'Mon',
  monday: 'Monday',
  tue: 'Tue',
  tuesday: 'Tuesday',
  wed: 'Wed',
  wednesday: 'Wednesday',
  thur: 'Thur',
  thursday: 'Thursday',
  fri: 'Fri',
  friday: 'Friday',
  sat: 'Sat',
  saturday: 'Saturday',
  sun: 'Sun',
  sunday: 'Sunday',
  no_appts: 'There are no appointments on ',
  today: 'TODAY',
  month: 'MONTH',
  week: 'WEEK',
  day: 'DAY',
  list: 'LIST',
  remove_recurring_event: 'Remove Recurring Event',
  this_event: 'This Event',
  this_and_following_events: 'This and Following Events',
  all_events: 'All Events',
  session_delete_reason:
    'Are you sure you want to delete session? If so please provide a reason.',
  entity_note:
    "Today's @session.type session took place at the @appointment.location on @appointment.date between @appointment.startTime to @appointment.endTime. It was conducted by @staffmember.",
  create_session: 'Create Session',
  create_new_session: 'Create New Session',
  appts_created: 'Appointment(s) Created',
  schedule_now: 'Schedule Now',
  schedule_later: 'Schedule Later',
  please_indicate_a_reason_for_removal: 'Please indicate a reason for removal:',
  more_information: 'More Information',
  description_optional: 'Description (Optional)',
  edit_appt: 'Edit Appointment',
  edit_appointment: 'Edit Appointment',
  create_appt: 'Create Appointment',
  schedule_by: 'Schedule By',
  no_skills: 'There are no enabled skills.',
  total: 'Total',
  finished: 'FINISHED',
  update: 'UPDATE',
  instance: 'Instance',
  timestamp: 'Timestamp',
  intensity: 'Intensity',
  occurrence: 'Occurrence',
  occur_timespan: 'Did this behavior occur for the whole timespan of',
  occur_anytime: 'Did this behavior occur anytime during the span of',
  occur_now: 'Is this behavior occurring now?',
  behavior_occurrence: 'Behavior Occurrence',
  time_of_occurrence: 'Time of Occurence',
  abc: 'ABC',
  times: 'Times',
  notifications: 'Notifications',
  mark_all_read: 'Mark all as Read',
  no_new_notifications: 'No new notifications',
  per_seconds: ' per seconds',
  duration_time: 'Duration Time',
  count: 'Count',
  finish: 'finish',
  interval: 'Interval',
  start_button: 'START',
  end: 'END',
  end_session_confirm: 'YES, END SESSION',
  end_session: 'End Session',
  end_session_prompt: 'Are you sure you want to end this session?',
  record_as_zero: 'Unrecorded Behavior Programs',
  record_as_zero_prompt:
    'The following active duration and frequency programs did not record a data point during the session. Would you like to record these as a zero frequency or duration?',
  close: 'CLOSE',
  more_info:
    'More Info: Substitution parameters (@targets) will be replaced with the correct data upon the completion of this session. (E.g. @appointment.date will be replaced with today’s date.)',
  required_note: 'Session note is required',
  '75_words': '/75 words',
  return_to_session: 'Return to Session',
  enable_session_programs:
    'Enable/Disable Programs and Targets, Reorder, or Navigate directly to a program.',
  no_behavior_programs: 'No behavior programs found for this session.',
  no_skill_programs: 'No skill programs found for this session.',
  session_in_progress: 'Session in Progress',
  no_programs_found_session: 'No programs found for this session.',
  start_time: 'Start Time',
  end_time: 'End Time',
  delete_signature: 'DELETE SIGNATURE',
  add_signature: 'ADD SIGNATURE',
  draw_signature: 'Draw Signature',
  staff_required: 'Staff (Required)',
  additional_signature: 'Additional Signature',
  add_new: 'ADD NEW',
  print_name: 'Print Name',
  task: 'Task',
  score: 'Score',
  average_score: 'Average score',
  incomplete: 'Incomplete',
  independent: 'Independent',
  prompt: 'Prompt',
  baseline_set: 'Baseline | Set',
  add_a_set: 'Add a Set',
  fail: 'Fail',
  pass: 'Pass',
  note_template: 'Note Template',
  note_templates: 'Note Templates',
  edit_note_template: 'Edit Note Template',
  no_templates_found: 'No templates found.',
  save_as_draft: 'Save as Draft',
  review: 'Review',
  edit_template: 'Edit Template',
  publish: 'Publish',
  start: 'Start',
  preview: 'Preview',
  start_session: 'Start Session',
  session_template_select_description:
    'Select a Session Note Template to begin your session. If your organization has not created any templates, a default template will be used. You can edit the session note at the end of this session.',
  selected_template: 'Selected Template',
  no_template_selected: 'No Template Selected',
  staff_initiated: 'Staff Initiated',
  client_initiated: 'Client Initiated',
  no_show: 'No Show',
  absent: 'Student Absent',
  submit_session_note_question: 'Submit Session Note?',
  submit_note: 'YES, SUBMIT NOTE',
  once_you_submit_note: 'Once you submit the session note you will',
  no_longer_be_able_note: 'no longer be able to edit the note.',
  scheduled_DOS: 'Scheduled Date Of Service',
  session_start_time: 'Session Start Time',
  session_end_time: 'Session End Time',
  attestation: 'I attest that the information shown is correct.',
  user_attestation: 'User attestation is required.',
  note: 'Note',
  min_75: '75 words minimum',
  sigs: 'Signatures',
  required_therapist: 'Therapist signature is required.',
  therapist_sig: 'Therapist Signature (required)',
  therapist_sig_2: 'Second Therapist Signature',
  parent_sig: 'Parent Signature',
  parent_sig_2: 'Second Parent Signature',
  submit_session_note: 'Submit Session Note',
  billing_address: 'Billing Address',
  session_staff: 'Session Staff',
  ssn: 'Social Security Number',
  yes_submit_note: 'YES, SUBMIT NOTE',
  supervision_summary: 'Supervision Summary',
  edit_start_time: 'Edit Start Time',
  edit_end_time: 'Edit End Time',
  required_supervision: 'Supervisor signature is required.',
  supervisor_sig: 'Supervisor Signature (required)',
  pdf: 'PDF',
  csv: 'CSV',
  export_recap: 'EXPORT RECAP',
  finalize_session: 'Finalize Session',
  data: 'Data',
  therapist_signature: 'Therapist Signature (required)',
  personal_info: 'Personal Information',
  empty_staff_message: 'No result for staff found',
  assign: 'ASSIGN',
  unassign: 'UNASSIGN',
  user_id: 'User Id:',
  status_permission: 'Status & Permissions',
  edit_staff: 'Edit Staff',
  create_staff: 'Create Staff',
  clients_assigned: 'Clients Assigned',
  no_clients_assigned: 'There are no clients assigned.',
  unassign_staff: 'Unassign staff',
  view_staff_profile: 'View staff profile',
  staff_assigned: 'Staff Members assigned',
  no_staff_assigned: 'There are no staff assigned.',
  no_items: 'No items match your search',
  account: 'Account',
  coming_soon: 'Coming Soon',
  beta: 'Beta',
  schedule: 'Schedule',
  clients: 'Clients',
  noteTemplate: 'Note Template',
  selectedStaff: 'Staff',
  participant_times:
    'Staff Participant times must align with designated appointment times.',
  staff: 'Staff',
  reports: 'Reports',
  programs: 'Programs',
  profile: 'Profile',
  organization: 'Organization',
  practice_management: 'Practice Management',
  admin: 'Admin',
  more: 'More',
  support: 'Support',
  logout: 'Logout',
  settings: 'Settings',
  clinical_execution: 'Clinical Execution',
  launchpad: 'Launchpad',
  reset_password: 'Reset Password',
  forgot_password: 'Forgot Password',
  client_form: 'Client Form',
  client_profile: 'Client Profile',
  staff_member_form: 'Staff Member Form',
  staff_member_profile: 'Staff Member Profile',
  message: 'Message',
  recap: 'Recap',
  schedule_appointment: 'Schedule Appointment',
  toggle_on_graphed_data:
    'Toggle on to display graphed data by session instead of by day',
  billing_codes: 'Billing Codes',
  location_address: 'Location Address',
  place_of_service: 'Place of Service',
  diagnosis_pointer: 'Diagnosis Pointer',
  payers: 'Payers',
  middle_name: 'Middle Name',
  service_start_date: 'Service Start Date',
  service_end_date: 'Service End Date',
  rendering_provider: 'Rendering Provider',
  tin: 'Tax Identification Number',
  tin_abbreviated: 'TIN',
  claim_review: 'Claim Review',
  template_change_warning:
    'Changing the selected Note Template will result in lost text entered in your Automated Template',
  billing_code: 'Billing Code',
  added_in_error: 'Added in Error',
  claims_list: 'Claims List',
  claim_status: 'Claim Status',
  session_history: 'Session History',
  audit: 'Audit',
  session_audit: 'Session Audit',
  delete_session: 'Delete Session',
  import_date: 'Import Date',
  payment_amount: 'Payment Amount',
  check_eft_no: 'Check EFT No',
  number_of_claims: 'Number of Claims',
  remits: 'Remits',
};
