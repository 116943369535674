import React from 'react';
import {DataItem, DateInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import moment from 'moment';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

const resolveDisplayValue = (value: string) => {
  return moment().diff(value, 'years');
};

const AgeDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Age</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const AgeInput = ({valueKey}: Props) => {
  return (
    <DateInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'birthDate'}
      name={'Birth Date'}
      required={true}
      maximumDate={new Date()}
    />
  );
};

export const validation = () => Yup.string().required('Age is required');

export const Display = AgeDisplay;
export const Input = AgeInput;
export const Value = resolveDisplayValue;

export default AgeInput;
