import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import AddressAutocomplete from 'src/hook-form/address-input';

interface Props {
  rules?: any;
  name: string;
  label: string;
  horizontal?: boolean;
  map?: boolean;
  required?: boolean;
  defaultValue?: string;
  fullAddressPreview?: boolean;
  disabled?: boolean;
  showHelper?: boolean;
}

const AddressAutocompleteInput = (props: Props) => {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      rules={props.rules}
      render={({field, fieldState: {error}}) => (
        <AddressAutocomplete
          formRef={field.ref}
          {...props}
          {...field}
          error={error}
        />
      )}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
};

export default AddressAutocompleteInput;
