export const SCALE_120 = 120;
export const SCALE_104 = 104;
export const SCALE_96 = 96;
export const SCALE_80 = 80;
export const SCALE_72 = 72;
export const SCALE_64 = 64;
export const SCALE_48 = 48;
export const SCALE_40 = 40;
export const SCALE_32 = 32;
export const SCALE_24 = 24;
export const SCALE_20 = 20;
export const SCALE_16 = 16;
export const SCALE_12 = 12;
export const SCALE_8 = 8;
export const SCALE_4 = 4;
