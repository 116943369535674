import React from 'react';
import {Display as PersonalInformationDisplay} from 'src/modules/users/components/personal-information-form';
import {Display as PermissionsDisplay} from 'src/modules/users/components/permissions-form';
import {Display as RosterCredentialsDisplay} from 'src/modules/users/components/roster-credentials-form';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';

const ReviewPage = ({values, ...extras}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.column, styles.paddingHorizontal]}>
      <PersonalInformationDisplay values={values} />
      <View style={[styles.separator, styles.marginVertical]} />
      <PermissionsDisplay values={values} />
      <View style={[styles.separator, styles.marginVertical]} />
      <RosterCredentialsDisplay values={values} billing={extras.billing} />
    </View>
  );
};

export default ReviewPage;
