import React, {useState} from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text, TouchableOpacity, View} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import FormInput from 'src/hook-form-wrapper/form-input';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {TextInputMask} from 'react-native-masked-text';
import {useStyle} from 'src/providers/style';
import {useAnalytics} from 'src/providers/segment';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  disabled?: boolean;
}

export const Import = (
  key: string = 'ssn',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Social Security Number',
  key,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  const styles = useStyle();
  const analytics = useAnalytics();
  const [hideSSN, setHideSSN] = useState(true);
  const toggleSSNVisibility = () => {
    analytics.track('Toggle SSN Visibility', {
      hideSSN: !hideSSN,
    });
    setHideSSN(!hideSSN);
  };
  const maskedSSN = (ssn: string) => {
    if (hideSSN) {
      return '***-**-****' + ssn.substring(ssn.length);
    }
    return ssn;
  };
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Social Security Number</Text>
      <View style={[styles.row, styles.alignCenter]}>
        <Text style={[Typography.P3]}>{maskedSSN(value)}</Text>
        <TouchableOpacity onPress={toggleSSNVisibility}>
          <View style={[styles.marginSMLeft]}>
            <Icon name={hideSSN ? 'eye-off' : 'eye'} />
          </View>
        </TouchableOpacity>
      </View>
    </DataItem>
  );
};

export const Input = ({name, disabled = false}: Props) => {
  return (
    <FormInput
      name={(name ? name + '.' : '') + 'ssn'}
      label={'Social Security Number'}
      placeholder={'Social Security Number'}
      helper={''}
      required={false}
      disabled={disabled}
      render={props => (
        <TextInputMask
          type={'custom'}
          {...props}
          options={{
            mask: '999-99-9999',
          }}
        />
      )}
    />
  );
};

export const validation = () =>
  Yup.string().required('Social Security Number is required');

export default Input;
