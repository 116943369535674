import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormInput from 'src/hook-form-wrapper/form-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'License Number',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'License Number',
  key,
});

const resolveDisplayValue = (value: string) => {
  return value;
};

const LicenseDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>License Number</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const LicenseInput = ({name}: Props) => {
  return (
    <HookFormInput
      name={(name ? name + '.' : '') + 'licenseNumber'}
      label={'License Number'}
      required={false}
    />
  );
};

export const validation = () =>
  Yup.string().required('License Number is required');

export const Display = LicenseDisplay;
export const Input = LicenseInput;
export const Value = resolveDisplayValue;

export default LicenseInput;
