import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {DataTable} from 'react-native-paper';
import {useDimensions} from '@react-native-community/hooks';
import moment from 'moment';
import _ from 'lodash';
import {useTranslations} from 'src/providers/translation';
import {Colors, Typography} from 'src/styles';
import {PromptsDisplay} from 'src/modules/session/components';
import DurationTableTooltip from 'src/modules/session/components/duration-table-tooltip';
import DurationTooltipEdit from '../../duration-tooltip-edit';

const DurationTable = ({
  sets,
  program,
  type = undefined,
  setEvents,
  setInstance,
  setRow,
}: any) => {
  const dimensions = useDimensions();
  const translations = useTranslations();
  const width = dimensions.window.width;
  const countInRow = width > 1024 ? 10 : width > 768 ? 5 : width > 480 ? 3 : 2;
  const tableCount = Math.floor(sets?.length / countInRow);
  const restCount = countInRow - (sets?.length % countInRow);

  if (type) {
    return (
      <DataTable style={styles.table}>
        {_.times(tableCount + 1, i => {
          if (i < tableCount || (i === tableCount && restCount < countInRow)) {
            return (
              <View key={`duration-table-${i}`}>
                <DataTable.Header
                  style={[
                    styles.head,
                    styles.ph_0,
                    i === 0 && styles.firstRow,
                  ]}>
                  <DataTable.Title
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.titleTxt}>
                    {translations('instance')}
                  </DataTable.Title>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, index: number) => (
                      <DataTable.Title
                        style={[
                          styles.justifyCenter,
                          index !== countInRow - 1 && index !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.titleTxt}
                        key={`duration-set-${set?.id}-${index}`}>
                        {i * countInRow + index + 1}
                      </DataTable.Title>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Header>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('date')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <View
                        style={[
                          styles.justifyCenter,
                          styles.alignCenter,
                          styles.flex,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`duration-date-${set?.id}-${idx}`}>
                        <DurationTableTooltip
                          method={program?.method}
                          unit={program?.unit}
                          set={set}
                          instance={i * countInRow + idx + 1}
                          intensity={
                            program?.intensity
                              ? _.capitalize(set?.intensity)
                              : ''
                          }
                        />
                        <View style={styles.cornerTab} />
                      </View>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('start_time')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`duration-starttime-${set?.id}-${idx}`}>
                        <View
                          style={[
                            styles.column,
                            styles.alignCenter,
                            styles.justifyCenter,
                          ]}>
                          <Text style={styles.cellTxt}>
                            {moment(set?.startTimestamp).format('hh:mm:ss A')}
                          </Text>
                        </View>
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('end_time')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.cellBoldTxt}
                        key={`duration-endtime-${set?.id}-${idx}`}>
                        <Text>
                          {moment(set?.endTimestamp).format('hh:mm:ss A')}
                        </Text>
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                {program?.intensity && (
                  <DataTable.Row style={styles.ph_0}>
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.cellBoldTxt}>
                      {translations('intensity')}
                    </DataTable.Cell>
                    {sets
                      .slice(i * countInRow, (i + 1) * countInRow)
                      .map((set: any, idx: number) => {
                        return (
                          <View
                            style={[
                              styles.justifyCenter,
                              styles.alignCenter,
                              styles.flex,
                              idx !== countInRow - 1 && idx !== sets?.length - 1
                                ? styles.borderRight
                                : {},
                            ]}
                            key={`duration-intensity-${set?.id}-${idx}`}>
                            <DurationTooltipEdit
                              setEvents={setEvents}
                              setRow={setRow}
                              method={program?.method}
                              set={set}
                              setInstance={setInstance}
                              row={'intensity'}
                              instance={i * countInRow + idx + 1}
                              intensity={
                                program?.intensity
                                  ? _.capitalize(set?.intensity)
                                  : ''
                              }
                            />
                            <View style={styles.cornerTab} />
                          </View>
                        );
                      })}
                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Title />)}
                  </DataTable.Row>
                )}
                <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('total')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      return (
                        <View
                          style={[
                            styles.justifyCenter,
                            styles.alignCenter,
                            styles.flex,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`duration-total-${set?.id}-${idx}`}>
                          <DurationTooltipEdit
                            setEvents={setEvents}
                            setRow={setRow}
                            method={program?.method}
                            set={set}
                            setInstance={setInstance}
                            row={'total'}
                            instance={i * countInRow + idx + 1}
                          />
                          <View style={styles.cornerTab} />
                          {set?.prompts?.length ? (
                            <PromptsDisplay prompts={set?.prompts} />
                          ) : (
                            <></>
                          )}
                        </View>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
              </View>
            );
          }
        })}
      </DataTable>
    );
  } else if (
    sets?.length === 1 &&
    sets[0]?.startTimestamp === sets[0]?.endTimestamp
  ) {
    return null;
  } else {
    return (
      <DataTable style={styles.table}>
        {_.times(tableCount + 1, i => {
          if (i < tableCount || (i === tableCount && restCount < countInRow)) {
            return (
              <View key={`duration-table-${i}`}>
                <DataTable.Header
                  style={[
                    styles.head,
                    styles.ph_0,
                    i === 0 && styles.firstRow,
                  ]}>
                  <DataTable.Title
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.titleTxt}>
                    {translations('instance')}
                  </DataTable.Title>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, index: number) => (
                      <DataTable.Title
                        style={[
                          styles.justifyCenter,
                          index !== countInRow - 1 && index !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.titleTxt}
                        key={`duration-set-${set?.id}-${index}`}>
                        {i * countInRow + index + 1}
                      </DataTable.Title>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Header>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('date')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <View
                        style={[
                          styles.justifyCenter,
                          styles.alignCenter,
                          styles.flex,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`duration-date-${set?.id}-${idx}`}>
                        <DurationTableTooltip
                          method={program?.method}
                          unit={program?.unit}
                          set={set}
                          instance={i * countInRow + idx + 1}
                          intensity={
                            program?.intensity
                              ? _.capitalize(set?.intensity)
                              : ''
                          }
                        />
                        <View style={styles.cornerTab} />
                      </View>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('start_time')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`duration-starttime-${set?.id}-${idx}`}>
                        <View
                          style={[
                            styles.column,
                            styles.alignCenter,
                            styles.justifyCenter,
                          ]}>
                          <Text style={styles.cellTxt}>
                            {moment(set?.startTimestamp).format('hh:mm:ss A')}
                          </Text>
                        </View>
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('end_time')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.cellBoldTxt}
                        key={`duration-endtime-${set?.id}-${idx}`}>
                        <Text>
                          {moment(set?.endTimestamp).format('hh:mm:ss A')}
                        </Text>
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                {program?.intensity && (
                  <DataTable.Row style={styles.ph_0}>
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.cellBoldTxt}>
                      {translations('intensity')}
                    </DataTable.Cell>
                    {sets
                      .slice(i * countInRow, (i + 1) * countInRow)
                      .map((set: any, idx: number) => (
                        <DataTable.Cell
                          style={[
                            styles.justifyCenter,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          textStyle={styles.cellBoldTxt}
                          key={`duration-intensity-${set?.id}-${idx}`}>
                          {_.capitalize(set?.intensity)}
                        </DataTable.Cell>
                      ))}
                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Title />)}
                  </DataTable.Row>
                )}
                <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('total')}
                  </DataTable.Cell>

                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      const duration = moment.duration(
                        moment(set?.endTimestamp).diff(
                          moment(set?.startTimestamp),
                        ),
                      );
                      const hours = duration.hours();
                      const min = duration.minutes();
                      const sec = duration.seconds();
                      return (
                        <DataTable.Cell
                          style={[
                            styles.justifyCenter,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`duration-total-${set?.id}-${idx}`}>
                          <View
                            style={[
                              styles.column,
                              styles.alignCenter,
                              styles.justifyCenter,
                            ]}>
                            <View style={styles.valuePadding}>
                              <Text>
                                {hours > 0 && hours + ' hr '}
                                {min > 0 && min + ' min '}
                                {sec >= 0 && sec + ' sec'}
                              </Text>
                            </View>
                            {set?.prompts?.length ? (
                              <PromptsDisplay prompts={set?.prompts} />
                            ) : (
                              <></>
                            )}
                          </View>
                        </DataTable.Cell>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
              </View>
            );
          }
        })}
      </DataTable>
    );
  }
};

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
  },
  valuePadding: {
    paddingTop: 20,
    paddingHorizontal: 20,
    paddingBottom: 10,
  },
  promptPadding: {
    paddingTop: 10,
    paddingHorizontal: 20,
    paddingBottom: 0,
  },
  iconContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    position: 'absolute',
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    borderColor: Colors.SECONDARY_800,
    borderWidth: 1,
    left: 40,
    bottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconText: {
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.SECONDARY_800,
    fontSize: 10,
  },
  table: {
    borderWidth: 1,
    borderColor: Colors.PRIMARY_300_SESSION_PLAN,
    borderRadius: 10,
  },
  head: {
    height: 50,
    backgroundColor: Colors.PRIMARY_50,
  },
  firstRow: {
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  lastRow: {
    borderBottomStartRadius: 10,
    borderBottomEndRadius: 10,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  alignCenter: {
    alignItems: 'center',
  },
  titleTxt: {
    ...Typography.LABEL_MEDIUM,
    color: Colors.PRIMARY_900,
    alignSelf: 'center',
    textAlign: 'center',
    width: 100,
    flexWrap: 'wrap',
  },
  cellBoldTxt: {
    ...Typography.CAPTION_MEDIUM,
    color: Colors.PRIMARY_900,
  },
  cellTxt: {
    ...Typography.CAPTION,
    color: Colors.PRIMARY_900,
  },
  spaceTop: {
    marginTop: 5,
  },
  borderRight: {
    borderRightWidth: 1,
    borderColor: Colors.PRIMARY_300_SESSION_PLAN,
  },
  flex: {
    flex: 1,
  },
  cornerTab: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 8,
    borderTopWidth: 8,
    borderRightColor: 'transparent',
    borderTopColor: '#B5B5B8',
    transform: [{rotate: '90deg'}],
    position: 'absolute',
    top: 0,
    right: 0,
  },
  ph_0: {
    paddingHorizontal: 0,
  },
});

export default DurationTable;
