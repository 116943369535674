import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {Text, View} from 'react-native';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import ListItemSeparator from 'src/common-components/separator';
import {DataItem, FormSectionHeader} from 'src/design-system';
import ConfiguredPayers from 'src/hook-form-inputs/configured-payers';
import {default as PayerPlansInput} from 'src/hook-form-inputs/payer-plans';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {
  Input as FirstName,
  Display as FirstNameDisplay,
} from 'src/hook-form-inputs/first-name';
import {
  Input as MiddleName,
  Display as MiddleNameDisplay,
} from 'src/hook-form-inputs/middle-name';
import {
  Input as LastName,
  Display as LastNameDisplay,
} from 'src/hook-form-inputs/last-name';
import {default as HookDateInput} from 'src/hook-form-wrapper/date-input';
import {
  Input as BirthDateInput,
  Display as BirthDateDisplay,
} from 'src/hook-form-inputs/birth-date';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
import {
  Input as PhoneNumber,
  Display as PhoneNumberDisplay,
} from 'src/hook-form-inputs/phone-number';
import {
  Input as SSNInput,
  Display as SSNDisplay,
} from 'src/hook-form-inputs/social-security-number';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {Authorization, Payer, PayerPlans} from 'src/models';
import moment from 'moment';
import {Separator} from 'src/common-components/atoms';
import Relationship, {
  Display as RelationshipDisplay,
} from 'src/hook-form-inputs/relationship';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {useTranslations} from 'src/providers/translation';
import addressFormat from 'src/common-utils/address-format';
import {Tooltip} from 'src/design-system';
import Icon from 'react-native-vector-icons/MaterialIcons';

export const getInsuranceTitle = (index: number) => {
  const titles = ['Primary', 'Secondary', 'Tertiary'];
  if (index < titles.length) {
    return `Insurance - ${titles[index]}`;
  }
  return `Insurance Provider ${index + 1}`;
};

const InsuranceItemDisplay = compose(
  withDatabase,
  withObservables(['value'], ({value, database}: any) => {
    return {
      authorization: value.authorizationId
        ? database
            .get(Authorization.table)
            .findAndObserve(value.authorizationId)
        : of(null),
      payer: database.get(Payer.table).findAndObserve(value?.payerId),
      payerPlan: value.planId
        ? database.get(PayerPlans.table).findAndObserve(value?.planId)
        : of(''),
    };
  }),
)(({payer, value, payerPlan, index, authorization}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <>
      <FormSectionHeader title={getInsuranceTitle(index)} />
      <DataItem>
        <Text style={[Typography.H6]}>Insurer</Text>
        <Text style={[Typography.P3]}>{payer.name}</Text>
      </DataItem>
      <DataItem>
        <Text style={[Typography.H6]}>Plan</Text>
        <Text style={[Typography.P3]}>
          {payerPlan.name || '(No Plan Name)'}
        </Text>
      </DataItem>
      <DataItem>
        <Text style={[Typography.H6]}>Member ID#</Text>
        <Text style={[Typography.P3]}>{value.memberId}</Text>
      </DataItem>
      <DataItem>
        <Text style={[Typography.H6]}>Group Number</Text>
        <Text style={[Typography.P3]}>{value.groupNumber}</Text>
      </DataItem>
      <DataItem>
        <Text style={[Typography.H6]}>Start Date</Text>
        <Text style={[Typography.P3]}>
          {value?.startDate > 0 ? moment(value?.startDate).format('ll') : ''}
        </Text>
      </DataItem>
      <DataItem>
        <Text style={[Typography.H6]}>End Date</Text>
        <Text style={[Typography.P3]}>
          {value?.endDate > 0 ? moment(value?.endDate).format('ll') : ''}
        </Text>
      </DataItem>
      <View style={[styles.paddingLVertical]}>
        <Text style={[Typography.P3_BOLD]}>Subscriber Information</Text>
      </View>
      <DataItem>
        <Text style={[Typography.H6]}>Member ID#</Text>
        <Text style={[Typography.P3]}>{value.memberId}</Text>
      </DataItem>
      <DataItem>
        <Text style={[Typography.H6]}>Prior Authorization</Text>
        <Text style={[Typography.P3]}>
          {authorization
            ? `${authorization.authorizationNumber}, ${moment(
                authorization.startDate,
              ).format('L')}-${moment(authorization.endDate).format('L')}`
            : ''}
        </Text>
      </DataItem>

      <FirstNameDisplay value={value.subscriber.firstName} />
      <MiddleNameDisplay value={value.subscriber.middleName} />
      <LastNameDisplay value={value.subscriber.lastName} />
      <RelationshipDisplay value={value.subscriber.relationship} />
      <BirthDateDisplay value={value?.subscriber.birthDate} />
      <DataItem>
        <Text style={[Typography.H6]}>{translations('address')}</Text>
        <Text style={[Typography.P3]}>
          {addressFormat(value.subscriber.address)}
        </Text>
      </DataItem>
      <PhoneNumberDisplay value={value.subscriber.mobilePhone} />
      <SSNDisplay value={value.subscriber.ssn || ''} />
      <View style={[styles.paddingVertical]}>
        <ListItemSeparator />
      </View>
    </>
  );
});

const InsuranceItem = ({
  item,
  index,
  deleteCallback,
  authorizationItems,
  showAuthorizations = false,
  showBoxNumbers = false,
}: any) => {
  const styles = useStyle();
  const {control, setValue} = useFormContext();
  const watchedPayer = useWatch({
    control,
    name: `insurances.${index}.payerId`,
  });

  useEffect(() => {
    if (item.payerId !== watchedPayer) {
      setValue(`insurances.${index}.planId`, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedPayer]);

  useEffect(() => {
    if (
      showAuthorizations &&
      authorizationItems.length &&
      !item.authorizationId
    ) {
      setValue(
        `insurances.${index}.authorizationId`,
        authorizationItems[0].value,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAuthorizations]);

  return (
    <View key={item.id}>
      <View style={[styles.row]}>
        <FormSectionHeader title={getInsuranceTitle(index)} />
        <RHButton
          icon="minus-circle-outline"
          mode="outlined"
          color={Colors.RAVEN_WHITE}
          textColor={Colors.PRIMARY_300}
          onPress={() => {
            deleteCallback(item, index);
          }}>
          REMOVE
        </RHButton>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex]}>
          <ConfiguredPayers
            name={`insurances.${index}`}
            label={'Payer (Box 11c)'}
          />
        </View>
        <Separator height={20} width={20} />
        <View style={[styles.flex]}>
          <PayerPlansInput
            name={`insurances.${index}`}
            id={watchedPayer}
            required={watchedPayer}
          />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex]}>
          <HookFormInput
            name={`insurances.${index}.memberId`}
            label={showBoxNumbers ? 'Member ID# (Box 1a)' : 'Member ID#'}
            disabled={!watchedPayer}
          />
        </View>
        <Separator height={20} width={20} />
        <View style={[styles.flex]}>
          <HookFormInput
            name={`insurances.${index}.groupNumber`}
            label={showBoxNumbers ? 'Group Number (Box 11)' : 'Group Number'}
            disabled={!watchedPayer}
          />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        {showAuthorizations ? (
          <>
            <View style={[styles.flex]}>
              <HookFormSelectInput
                name={`insurances.${index}.authorizationId`}
                items={authorizationItems}
                label={showBoxNumbers ? 'Prior Auth (Box 23)' : 'Prior Auth'}
                placeholder={
                  showBoxNumbers ? 'Prior Auth (Box 23)' : 'Prior Auth'
                }
                helper={''}
                searchable={false}
              />
            </View>
            <Separator height={20} width={20} />
          </>
        ) : (
          <></>
        )}
        <View style={[styles.flex]}>
          <HookDateInput
            name={`insurances.${index}.startDate`}
            label={'Start Date'}
            placeholder={'Start Date'}
            helper={''}
            disabled={!watchedPayer}
          />
        </View>
        <Separator height={20} width={20} />
        <View style={[styles.flex]}>
          <HookDateInput
            name={`insurances.${index}.endDate`}
            label={'End Date'}
            placeholder={'End Date'}
            helper={''}
            disabled={!watchedPayer}
          />
        </View>
      </View>
      <View style={[styles.marginLVertical]}>
        <Text style={[Typography.P3_BOLD]}>Subscriber Information</Text>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex]}>
          <FirstName
            name={`insurances.${index}.subscriber`}
            label={showBoxNumbers ? 'First Name (Box 4)' : undefined}
            disabled={!watchedPayer}
          />
        </View>
        <Separator height={20} width={20} />
        <View style={[styles.flex]}>
          <MiddleName
            name={`insurances.${index}.subscriber`}
            label={showBoxNumbers ? 'Middle Name (Box 4)' : undefined}
            disabled={!watchedPayer}
          />
        </View>
        <Separator height={20} width={20} />
        <View style={[styles.flex]}>
          <LastName
            name={`insurances.${index}.subscriber`}
            label={showBoxNumbers ? 'Last Name (Box 4)' : undefined}
            disabled={!watchedPayer}
          />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.row, styles.flex]}>
          <View style={[styles.flex]}>
            <Relationship
              name={`insurances.${index}.subscriber`}
              label={showBoxNumbers ? 'Relationship (Box 6)' : undefined}
              disabled={!watchedPayer}
              subscriberRelationShipList={true}
            />
          </View>
          <Tooltip
            anchor={
              <Icon
                name={'info'}
                style={[styles.marginBottom, styles.paddingBottom]}
              />
            }>
            <Text style={[Typography.P3]}>
              How the client relates to the insurance subscriber
            </Text>
          </Tooltip>
        </View>
        <Separator height={20} width={20} />
        <View style={[styles.flex]}>
          <BirthDateInput
            name={`insurances.${index}.subscriber`}
            label={showBoxNumbers ? 'Birth Date (Box 11a)' : undefined}
            disabled={!watchedPayer}
          />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex]}>
          <PhoneNumber
            name={`insurances.${index}.subscriber`}
            label={showBoxNumbers ? 'Phone Number (Box 7)' : undefined}
            disabled={!watchedPayer}
          />
        </View>
        <Separator height={20} width={20} />
        <View style={[styles.flex]}>
          <SSNInput
            name={`insurances.${index}.subscriber`}
            disabled={!watchedPayer}
          />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex]}>
          <AddressAutocompleteInput
            name={`insurances.${index}.subscriber.address`}
            label={showBoxNumbers ? 'Address (Box 7)' : 'Address'}
            disabled={!watchedPayer}
          />
        </View>
      </View>
    </View>
  );
};

export const Display = InsuranceItemDisplay;

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['authentication', 'item'],
    ({authentication, database, item}: any) => ({
      authorizations:
        item?.payerId && item?.patientId
          ? database
              .get(Authorization.table)
              .query(
                Q.where('payer_id', item.payerId),
                Q.where('patient_id', item.patientId),
                Q.where('_partition', authentication.selectedGroup),
                Q.where('deleted_at', null),
                Q.sortBy('end_date', Q.desc),
              )
          : of([]),
    }),
  ),
  withObservables(['authorizations'], ({authorizations}: any) => ({
    authorizationItems: of(
      authorizations.map((authorization: Authorization) => ({
        value: authorization.id,
        label: `${authorization.authorizationNumber}, ${moment(
          authorization.startDate,
        ).format('L')}-${moment(authorization.endDate).format('L')}`,
      })),
    ),
  })),
)(InsuranceItem);
