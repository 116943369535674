import React, {useReducer, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Checkbox, RadioButton} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import _ from 'lodash';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useTranslations} from 'src/providers/translation';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {useStyle} from 'src/providers/style';
import TimerComponent from 'src/hook-form/timer-input';
import moment from 'moment';

const IntervalEdit = ({
  setEditModule,
  previousValue,
  instance,
  prompts,
  event,
  set,
  module = '',
}: any) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const translations = useTranslations();
  const styles = useStyle();
  const editableIntensity = event?.intensity;
  const [intensity] = useState<string>(editableIntensity);
  const [previousPrompts] = useState<string>(event?.prompts);
  const intensityArray = [
    {title: 'Mild', value: 'mild'},
    {title: 'Moderate', value: 'moderate'},
    {title: 'Severe', value: 'severe'},
  ];
  const duration = Math.round(
    (set?.endTimestamp - set?.startTimestamp) * 0.001,
  );
  const [durationEdit, setDuration] = useState(duration);

  const changeIntensity = (val: string) => {
    event.updateEntity({
      intensity: val,
    });
    set.updateEntity({});
    forceUpdate();
  };

  const onSave = () => {
    if (duration !== durationEdit) {
      set.updateEntity({
        endTimestamp: moment(set?.startTimestamp).add(durationEdit, 's'),
      });
    }
    setEditModule('');
  };

  const onCancel = () => {
    event.updateEntity({
      prompts: previousPrompts,
      intensity: intensity,
      value: previousValue,
    });
    setEditModule('');
  };

  const checkPrompt = (id: any) => {
    const index = [...event?.prompts].findIndex((v: any) => {
      return v === id;
    });

    if (index === -1) {
      const values = [...event?.prompts];
      values.push(id);
      event.updateEntity({
        prompts: values,
      });
    } else {
      const values = [...event?.prompts];
      values.splice(index, 1);
      event.updateEntity({
        prompts: values,
      });
    }
    forceUpdate();
  };

  const onValue = async (value: string) => {
    if (value === 'N') {
      event.updateEntity({
        value: value,
        prompts: [],
        intensity: '',
      });
    } else {
      event.updateEntity({
        value: value,
      });
    }
    set.updateEntity({});
    forceUpdate();
  };

  return (
    <View>
      {module === 'occurence' ? (
        <View>
          <View
            style={[
              styles.border,
              styles.borderColorPrimary300,
              styles.borderRadiusSM,
              styles.padding,
              styles.marginLVertical,
            ]}>
            <View
              style={[
                styles.flex,
                styles.row,
                styles.justifySpaceBetween,
                styles.alignCenter,
              ]}>
              <Text style={[styles.marginSMVertical, Typography.P2]}>
                {translations('occurrence')} {translations('instance')} -
                {` ${instance}`}
              </Text>
            </View>
            <View
              style={[
                styles.row,
                styles.justifySpaceBetween,
                styles.paddingTop,
              ]}>
              <TouchableOpacity
                style={[
                  styles.iconButton,
                  styles.border,
                  {
                    backgroundColor:
                      event?.value === 'N' ? Colors.ERROR : Colors.RAVEN_WHITE,
                  },
                ]}
                onPress={() => onValue('N')}>
                <MaterialCommunityIcon
                  size={20}
                  name="close"
                  color={
                    event?.value === 'N' ? Colors.RAVEN_WHITE : Colors.ERROR
                  }
                />
              </TouchableOpacity>

              <View
                style={[
                  styles.flex,
                  styles.row,
                  styles.justifySpaceAround,
                  styles.alignCenter,
                  styles.paddingLHorizontal,
                ]}>
                <Text style={[Typography.P2_MEDIUM]}>
                  {translations('occurrence')}
                </Text>
              </View>

              <TouchableOpacity
                style={[
                  styles.iconButton,
                  styles.border,
                  {
                    backgroundColor:
                      event?.value === 'Y'
                        ? Colors.SUCCESS_DARK
                        : Colors.RAVEN_WHITE,
                  },
                ]}
                onPress={() => onValue('Y')}>
                <MaterialCommunityIcon
                  size={20}
                  name="check"
                  color={
                    event?.value === 'Y'
                      ? Colors.RAVEN_WHITE
                      : Colors.SUCCESS_DARK
                  }
                />
              </TouchableOpacity>
            </View>
            <View>
              {prompts ? (
                <View style={styles.paddingTop}>
                  {Object.keys(prompts).map(
                    (promptType: any, index: number) => {
                      return (
                        <View
                          style={[
                            styles.positionRelative,
                            styles.zIndex100,
                            styles.borderRadius,
                            index === Object.keys(prompts).length - 1
                              ? styles.lastPaddingBottom
                              : styles.paddingBottom,
                          ]}
                          key={`promptType-key-${promptType}`}>
                          <Text
                            style={[styles.marginSMVertical, Typography.P2]}>
                            {promptType}
                          </Text>
                          {prompts[promptType].map((prompt: any) => {
                            return (
                              <TouchableOpacity
                                key={`prompt-key-${promptType}-${prompt?.id}`}
                                style={[
                                  styles.row,
                                  styles.alignCenter,
                                  styles.marginSMVertical,
                                ]}
                                onPress={() => {
                                  checkPrompt(prompt?.id);
                                }}>
                                <Checkbox.Android
                                  status={
                                    (event?.prompts || []).findIndex(v => {
                                      return v === prompt?.id;
                                    }) !== -1
                                      ? 'checked'
                                      : 'unchecked'
                                  }
                                  color={Colors.RAVEN_BLACK}
                                  uncheckedColor={Colors.RAVEN_BLACK}
                                />
                                <Text style={[Typography.P2]}>
                                  {prompt?.name}
                                </Text>
                              </TouchableOpacity>
                            );
                          })}
                        </View>
                      );
                    },
                  )}
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
      ) : module === 'intensity' ? (
        <View
          style={[
            styles.border,
            styles.borderColorPrimary300,
            styles.borderRadiusSM,
            styles.padding,
            styles.marginLVertical,
          ]}>
          <View style={[styles.row, styles.justifySpaceBetween]}>
            <Text style={[styles.marginSMVertical, Typography.P2]}>
              {translations('intensity')} - {translations('instance')}
              {` ${instance}`}
            </Text>
            <Text
              style={[
                styles.intensityText,
                editableIntensity === 'mild'
                  ? styles.mildColor
                  : editableIntensity === 'moderate'
                  ? styles.moderateColor
                  : styles.severeColor,
              ]}
            />
          </View>
          <View style={styles.radio}>
            <RadioButton.Group
              onValueChange={changeIntensity}
              value={editableIntensity}>
              <View style={[styles.row, styles.justifySpaceBetween]}>
                {intensityArray.map((button: any, index: number) => {
                  return (
                    <View
                      key={`radio-key-${button?.value}-${index}`}
                      style={styles.radioButton}>
                      <RadioButton.Android
                        value={button.value}
                        color={
                          editableIntensity === 'mild'
                            ? '#4FF5AF'
                            : editableIntensity === 'moderate'
                            ? '#F7AA4F'
                            : '#F12E5D'
                        }
                        uncheckedColor={'#909093'}
                      />
                      <Text
                        style={[
                          Typography.P2,
                          button.title === _.capitalize(editableIntensity)
                            ? button.title === 'Mild'
                              ? styles.mildColor
                              : button.title === 'Moderate'
                              ? styles.moderateColor
                              : styles.severeColor
                            : styles.noResultsColor,
                        ]}>
                        {button.title}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </RadioButton.Group>
          </View>
        </View>
      ) : (
        <View
          style={[
            styles.border,
            styles.borderColorPrimary300,
            styles.borderRadiusSM,
            styles.padding,
            styles.marginLVertical,
          ]}>
          <View style={[styles.row, styles.justifySpaceBetween]}>
            <Text style={[styles.marginSMVertical, Typography.P2]}>
              {translations('duration')} - {translations('instance')} {instance}
            </Text>
            <TimerComponent
              value={durationEdit}
              onChange={value => setDuration(value)}
            />
          </View>
        </View>
      )}
      <View style={[styles.justifySpaceBetween, styles.row]}>
        <RHButton
          secondary
          onPress={() => {
            onCancel();
          }}
          textColor={Colors.RAVEN_BLACK}
          color="black"
          mode="outlined">
          {translations('cancel_button')}
        </RHButton>
        <RHButton mode="contained" onPress={() => onSave()}>
          {translations('save_button')}
        </RHButton>
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({event}: any) => ({
    set: event.set ? event.set : of({}),
  })),
)(IntervalEdit);
