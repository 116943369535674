import React, {useReducer, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import EStyleSheet from 'react-native-extended-stylesheet';
import {memoize} from 'lodash';
import withObservables from '@nozbe/with-observables';
import {Colors, Typography} from 'src/styles';
import Collapsible from 'react-native-collapsible';
import {compose} from 'recompose';
import {SessionProgramPrompt} from 'src/modules/session/components';
import {useDimensions} from '@react-native-community/hooks';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {of} from 'rxjs';
import {Prompt} from 'src/models';

const TaskAnalysisTargetEdit = ({
  target,
  prompts,
  event,
  setEventId,
  currentPrompt,
}: any) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const dimensions = useDimensions();
  const [eventId, setUpdatedEventId] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<number | null>(null);
  const styles = getStyles(dimensions.window.width);

  const error = () => {
    if (prompts?.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
      onValue('error', null);
      setUpdatedEventId(event.id);
      setEventId(eventId);
    }
  };

  const success = () => {
    setSelectedPrompt(null);
    onValue('success', null);
    setUpdatedEventId(event.id);
    setEventId(eventId);
  };

  const promptSelected = (id: number) => {
    setSelectedPrompt(id);
    onValue('error', id);
    setUpdatedEventId(event.id);
    setEventId(eventId);
  };

  const onValue = async (value: string, selectedPrompts: any) => {
    if (selectedPrompts) {
      event.updateEntity({value, prompts: [selectedPrompts]});
    } else {
      event.updateEntity({value, prompts: []});
    }
    forceUpdate();
  };

  return (
    <View style={[styles.column, styles.paddingHorizontal, styles.maxWidth]}>
      <View style={[styles.skillCard]}>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignCenter,
          ]}>
          <Text
            style={[styles.skillCardTitle]}
            numberOfLines={1}
            ellipsizeMode="tail">
            {target?.name || ''}
          </Text>
          <Text style={[styles.pill]}>{target.name}</Text>
        </View>

        <View
          style={[styles.row, styles.justifySpaceBetween, styles.paddingTop]}>
          <TouchableOpacity
            style={[
              styles.borderCircleBut,
              {
                backgroundColor:
                  event.value === 'error' ? Colors.ERROR : Colors.RAVEN_WHITE,
              },
            ]}
            onPress={error}>
            <MaterialCommunityIcon
              size={20}
              name="close"
              color={
                event.value === 'error' ? Colors.RAVEN_WHITE : Colors.ERROR
              }
            />
          </TouchableOpacity>

          <View
            style={[
              styles.flex,
              styles.row,
              styles.justifySpaceAround,
              styles.alignCenter,
              styles.paddingHorizontal,
            ]}>
            <Text style={[styles.measurementTxt]}>Independent</Text>
          </View>

          <TouchableOpacity
            style={[
              styles.borderCircleBut,
              {
                backgroundColor:
                  event.value === 'success'
                    ? Colors.SUCCESS_DARK
                    : Colors.RAVEN_WHITE,
              },
            ]}
            onPress={success}>
            <MaterialCommunityIcon
              size={20}
              name="check"
              color={
                event.value === 'success'
                  ? Colors.RAVEN_WHITE
                  : Colors.SUCCESS_DARK
              }
            />
          </TouchableOpacity>
        </View>
        {event.prompts.length ? (
          <View style={styles.paddingTop}>
            <Text style={styles.promptTxt}>Prompt</Text>
            <View style={styles.promptBoxes}>
              {prompts?.map((prompt: any) => (
                <SessionProgramPrompt
                  key={`session-program-prompt-${prompt?.id ?? prompt}`}
                  selected={selectedPrompt === prompt}
                  prompt={prompt}
                  currentPrompt={currentPrompt}
                  onClk={() => {
                    promptSelected(prompt.id);
                  }}
                />
              ))}
            </View>
          </View>
        ) : (
          <Collapsible collapsed={!open}>
            <View style={styles.paddingTop}>
              <Text style={styles.promptTxt}>Prompt</Text>
              <View style={styles.promptBoxes}>
                {prompts?.map((prompt: any) => (
                  <SessionProgramPrompt
                    key={`session-program-prompt-key-${prompt?.id ?? prompt}`}
                    selected={selectedPrompt === prompt}
                    prompt={prompt}
                    onClk={() => {
                      promptSelected(prompt.id);
                    }}
                  />
                ))}
              </View>
            </View>
          </Collapsible>
        )}
      </View>
    </View>
  );
};

const getStyles = memoize((width: number) =>
  EStyleSheet.create({
    maxWidth: {
      width: width > 400 ? 360 : width - 80,
    },
    skillCard: {
      borderWidth: 1,
      borderColor: Colors.PRIMARY_300,
      borderRadius: 4,
      padding: 20,
      marginVertical: 10,
    },
    paddingTop: {
      paddingTop: 40,
    },
    paddingHorizontal: {
      paddingHorizontal: 12,
    },
    pill: {
      ...Typography.P3,
      paddingVertical: 4,
      paddingHorizontal: 12,
      borderRadius: 12,
      backgroundColor: Colors.PRIMARY_50,
      flexShrink: 0,
    },
    flex: {
      flex: 1,
    },
    skillCardTitle: {
      ...Typography.P2_BOLD,
    },
    row: {
      flexDirection: 'row',
    },
    column: {
      flexDirection: 'column',
    },
    alignCenter: {
      alignItems: 'center',
      alignContent: 'center',
    },
    justifySpaceBetween: {
      justifyContent: 'space-between',
    },
    justifySpaceAround: {
      justifyContent: 'space-around',
    },
    borderCircleBut: {
      width: 44,
      height: 44,
      borderRadius: 22,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    border: {
      height: 40,
      width: 40,
      backgroundColor: Colors.ARCTIC_500,
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    disabledTxt: {
      color: Colors.TEXT_DISABLED,
    },
    measurementTxt: {
      ...Typography.P2_MEDIUM,
    },
    promptTxt: {
      ...Typography.CAPTION,
      color: Colors.TEXT_SECONDARY,
      marginBottom: 10,
    },
    promptBoxes: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  }),
);

export default compose(
  withDatabase,
  withObservables([], ({program}: any) => ({
    prompts: program.prompts,
  })),
  withObservables(['eventId'], ({database, eventId}: any) => {
    return {
      event: database.get('events').findAndObserve(eventId),
    };
  }),
  withObservables([], ({database, event}: any) => ({
    target: database.get('targets').findAndObserve(event.targetId),
    currentPrompt: event?.prompts.length
      ? database.get(Prompt.table).findAndObserve(event.prompts[0])
      : of({}),
  })),
)(TaskAnalysisTargetEdit);
