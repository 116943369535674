import React from 'react';
import {Text} from 'react-native';
import {AnimatedCircularProgress} from 'react-native-circular-progress';
import {Colors, Typography} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';

const SetComplete = ({fill, addSet}: any) => {
  const styles = useStyle();

  return (
    <>
      <AnimatedCircularProgress
        size={110}
        width={22}
        fill={fill}
        rotation={0}
        tintColor="#057295"
        backgroundColor="#84DFFC">
        {percentage => (
          <Text style={[Typography.P1_MEDIUM]}>{percentage}%</Text>
        )}
      </AnimatedCircularProgress>
      <RHButton
        mode="outlined"
        onPress={addSet}
        color={Colors.RAVEN_BLACK}
        textColor={Colors.RAVEN_BLACK}
        style={[styles.flex, styles.width, styles.marginTop]}>
        Add a Set
      </RHButton>
    </>
  );
};

export default SetComplete;
