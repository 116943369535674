import React from 'react';
import {Credential} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';

interface Props {
  override?: string;
  name: string;
  label: string;
  items: any[];
  selectAll?: boolean;
  multiple?: boolean;
  showHelper?: boolean;
}

const UserCredentialSelect = ({
  override,
  name,
  label = 'Select Provider',
  items,
  selectAll = false,
  multiple = false,
  showHelper = true,
}: Props) => {
  return (
    <HookFormSelectInput
      name={override ?? (name ? name + '.' : '') + 'credentialId'}
      label={label}
      required={false}
      multiple={multiple}
      selectAll={selectAll}
      items={items}
      showHelper={showHelper}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    credentials: database
      .get(Credential.table)
      .query(
        Q.where('deleted_at', null),
        Q.or(Q.where('user_id', Q.eq(null)), Q.where('user_id', Q.eq(''))),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('first_name', Q.asc),
        Q.sortBy('last_name', Q.asc),
      ),
  })),
  withObservables([], ({credentials}: any) => {
    let items = credentials.map((credential: Credential) => {
      return {
        label: `${credential.firstName} ${credential.lastName}`,
        value: credential.id,
      };
    });

    items.push({
      value: 'other',
      label: 'Other',
    });

    return {
      items: of(items),
    };
  }),
)(UserCredentialSelect);
