import React, {useState} from 'react';
import * as Yup from 'yup';
import ListItemSeparator from 'src/common-components/separator';
import {Form, Filters} from 'src/design-system';
import CheckboxArrayInput from 'src/design-system/checkbox-array-input';
import {useTranslations} from 'src/providers/translation';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';

export const patientStateArray = [{value: 'archived', title: 'Archived'}];

const ClientFilterMenu = ({filter, applyFilters, tags}: any) => {
  const translations = useTranslations();
  const treatmentTags = tags.length
    ? tags.map((tag: any) => ({value: tag.id, title: tag.name}))
    : [];
  const [visible, setVisible] = useState<boolean>(false);
  const validationSchema = Yup.object({});
  const initialValues = {patientState: [], relatedServices: []};

  const notificationNumberCount = () => {
    let result = 0;
    if (filter === undefined) {
      return 0;
    }
    for (const key in filter) {
      result += filter[key].length;
    }
    return result;
  };

  const filterSubmit = (newFilters: any) => {
    applyFilters(newFilters);
    setVisible(false);
  };

  const filterCancelled = () => {
    applyFilters(initialValues);
    setVisible(false);
  };

  const closeMenu = () => {
    setVisible(false);
  };

  return (
    <Filters
      open={visible}
      setOpen={setVisible}
      notificationNumberCount={notificationNumberCount}
      onDismiss={closeMenu}>
      <Form
        handleSubmit={filterSubmit}
        cancelled={filterCancelled}
        initialValues={initialValues}
        validationSchema={validationSchema}
        cancelText={translations('reset')}
        saveText={translations('apply')}>
        <CheckboxArrayInput
          valueKey={'patientState'}
          title={translations('state')}
          items={patientStateArray}
        />
        <ListItemSeparator />
        <CheckboxArrayInput
          valueKey={'relatedServices'}
          title={translations('related_services')}
          items={treatmentTags}
        />
        <ListItemSeparator />
        <RHSeparator height={16} />
      </Form>
    </Filters>
  );
};

export default ClientFilterMenu;
