import {Colors} from 'src/styles/index';
import {DefaultTheme as DefaultPaperTheme} from 'react-native-paper';
import {DefaultTheme as DefaultNavTheme} from '@react-navigation/native';
export const Theme = {
  ...DefaultPaperTheme,
  ...DefaultNavTheme,
  isV3: true,
  colors: {
    ...DefaultPaperTheme.colors,
    ...DefaultNavTheme.colors,
    primary: Colors.RAVEN_BLACK,
    background: '#F7F7FA',
    surface: Colors.RAVEN_WHITE,
    surfaceVariant: Colors.RAVEN_WHITE,
    onSurface: Colors.RAVEN_BLACK,
    onSurfaceVariant: Colors.RAVEN_BLACK,
    primaryContainer: Colors.RAVEN_WHITE,
    onPrimary: Colors.RAVEN_WHITE,
    // backdrop: 'transparent'
  },
};

export const dropdownTheme = {
  ...DefaultPaperTheme,
  colors: {
    background: 'white',
    primary: Colors.BLUE_RIBBON,
    text: 'black',
  },
};
