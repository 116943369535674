import React, {useEffect, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import ServiceLineForm from 'src/modules/billing/components/service-line-form';
import Staff from 'src/hook-form-inputs/staff';
import Location from 'src/hook-form-inputs/location';
import {default as HookDateInput} from 'src/hook-form-wrapper/date-input';
import {IconButton} from 'react-native-paper';
import Collapsible from 'react-native-collapsible';
import {useStyle} from 'src/providers/style';
import {useFormContext, useWatch} from 'react-hook-form';
import CredentialForm from 'src/modules/patients/components/credential-form';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Patient, User} from 'src/models';
import Address from 'src/hook-form-inputs/address';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
import _ from 'lodash';

const SessionForm = ({index, deleteCallback}: any) => {
  const database = useDatabase();
  const styles = useStyle();

  const {control, setValue} = useFormContext();
  const [patientModel, setPatientModel] = useState<any>(null);
  const [caregiverModel, setCaregiverModel] = useState<any>(null);

  const [collapsed, setCollapsed] = useState(true);
  const [renderingProvider, setRenderingProvider] = useState<any>({
    id: null,
    tin: '',
    npi: '',
    ssn: '',
    taxonomy: [],
    firstName: '',
    middleName: '',
    lastName: '',
  });
  const [supervisingProvider, setSupervisingProvider] = useState<any>({
    id: null,
    tin: '',
    npi: '',
    ssn: '',
    taxonomy: [],
    firstName: '',
    middleName: '',
    lastName: '',
  });

  const renderingProviderId = useWatch({
    control,
    name: `sessions.${index}.renderingId`,
  });

  const address = useWatch({
    control,
    name: `sessions.${index}.address`,
  });

  const session = useWatch({
    control,
    name: `sessions.${index}`,
  });

  const location = useWatch({
    control,
    name: `sessions.${index}.location`,
  });

  const patient = useWatch({
    control,
    name: `sessions.${index}.patient`,
  });

  const fetchPatient = async () => {
    if (patient) {
      const fetchedPatient = await database.get(Patient.table).find(patient);
      const caregivers = await fetchedPatient?.activeCaregivers;

      if (fetchedPatient) {
        setPatientModel(fetchedPatient);
        setCaregiverModel(caregivers);
      }
    }
  };
  const setAddress = () => {
    if (session.address || session.customAddress) {
      return;
    } else {
      if (patientModel && location === '03' && patientModel?.schoolId) {
        setValue(`sessions.${index}.address`, patientModel.schoolId);
      } else if (patientModel && location === '12' && caregiverModel) {
        setValue(`sessions.${index}.address`, caregiverModel[0]?.id);
      } else {
        setValue('address', '');
      }
    }
  };
  useEffect(() => {
    if (patient && !patientModel) {
      fetchPatient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  useEffect(() => {
    if (
      (patientModel && location === '03' && patientModel?.schoolId) ||
      (location === '12' && caregiverModel)
    ) {
      setAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientModel, caregiverModel, location]);

  const queryRenderingProvider = async (id: string) => {
    const user = await database.get(User.table).find(id);
    const credential = (await user.userCredential)[0];
    const referringProviderCredentials =
      await credential.activePayerCredentials.fetch();
    const mappedCredentials = _.keyBy(referringProviderCredentials, 'payerId');

    if (!credential?.npi || !user?.firstName || !user?.lastName) {
      setCollapsed(false);
    }

    setValue(`sessions.${index}.renderingProvider`, {
      id: credential.id,
      tin: credential.tin,
      npi: credential.npi,
      ssn: credential.ssn,
      taxonomy: credential.taxonomy,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      payers: watchedInsurances.map((insurance: any) => {
        return {
          _id: mappedCredentials[insurance.payerId]?.id,
          id: insurance.payerId,
          legacyId: mappedCredentials[insurance.payerId]?.legacyId || '',
          taxonomy: mappedCredentials[insurance.payerId]?.taxonomy || '',
        };
      }),
    });

    setRenderingProvider({
      id: credential.id,
      tin: credential.tin,
      npi: credential.npi,
      ssn: credential.ssn,
      taxonomy: credential.taxonomy,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      payers: watchedInsurances.map((insurance: any) => {
        return {
          _id: mappedCredentials[insurance.payerId]?.id,
          id: insurance.payerId,
          legacyId: mappedCredentials[insurance.payerId]?.legacyId || '',
          taxonomy: mappedCredentials[insurance.payerId]?.taxonomy || '',
        };
      }),
    });
  };

  useEffect(() => {
    queryRenderingProvider(renderingProviderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderingProviderId]);

  const supervisingProviderId = useWatch({
    control,
    name: `sessions.${index}.supervisingId`,
  });

  const querySupervisingProvider = async (id: string) => {
    if (id) {
      const user = await database.get(User.table).find(id);
      const credential = (await user.userCredential)[0];
      const referringProviderCredentials =
        await credential.activePayerCredentials.fetch();
      const mappedCredentials = _.keyBy(
        referringProviderCredentials,
        'payerId',
      );

      setValue(`sessions.${index}.supervisingProvider`, {
        id: credential.id,
        tin: credential.tin,
        npi: credential.npi,
        ssn: credential.ssn,
        taxonomy: credential.taxonomy,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        payers: watchedInsurances.map((insurance: any) => {
          return {
            _id: mappedCredentials[insurance.payerId]?.id,
            id: insurance.payerId,
            legacyId: mappedCredentials[insurance.payerId]?.legacyId || '',
            taxonomy: mappedCredentials[insurance.payerId]?.taxonomy || '',
          };
        }),
      });

      setSupervisingProvider({
        id: credential.id,
        tin: credential.tin,
        npi: credential.npi,
        ssn: credential.ssn,
        taxonomy: credential.taxonomy,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        payers: watchedInsurances.map((insurance: any) => {
          return {
            _id: mappedCredentials[insurance.payerId]?.id,
            id: insurance.payerId,
            legacyId: mappedCredentials[insurance.payerId]?.legacyId || '',
            taxonomy: mappedCredentials[insurance.payerId]?.taxonomy || '',
          };
        }),
      });
    }
  };

  useEffect(() => {
    querySupervisingProvider(supervisingProviderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supervisingProviderId]);

  const watchedInsurances = useWatch({
    control,
    name: 'insurances',
  });

  return (
    <View
      style={[
        styles.column,
        styles.border,
        styles.borderRadius,
        styles.borderColorGray400,
        styles.padding,
        styles.marginLVertical,
      ]}>
      <TouchableOpacity onPress={() => setCollapsed(!collapsed)}>
        <View
          style={[styles.row, styles.alignCenter, styles.justifySpaceBetween]}>
          <Text style={[Typography.P3_BOLD]}>Session {index + 1}</Text>
          <View
            style={[
              styles.row,
              styles.marginXXLTop,
              styles.justifySpaceBetween,
            ]}>
            <HookDateInput
              name={`sessions.${index}.startDate`}
              label={'Service Start Date (Box 24A)'}
              placeholder={'Service Start Date (Box 24A)'}
              required={false}
            />
            <HookDateInput
              name={`sessions.${index}.endDate`}
              label={'Service End Date (Box 24A)'}
              placeholder={'Service End Date (Box 24A)'}
              required={false}
            />
          </View>
          <Location
            name={`sessions.${index}`}
            showHelper={false}
            label={'Place of Service (Box 24B)'}
          />
          <View>
            <Address
              name={`sessions.${index}`}
              patientId={location === '12' ? patient : undefined}
              showHelper={false}
            />
            {address === 'other' ? (
              <AddressAutocompleteInput
                name={`sessions.${index}.customAddress`}
                label={'Custom Address'}
              />
            ) : (
              <></>
            )}
          </View>
          <Staff
            override={`sessions.${index}.renderingId`}
            label={'Rendering Provider (Box 24J)'}
            showHelper={false}
          />
          <View style={[styles.row]}>
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon="trash-can"
              onPress={() => deleteCallback()}
            />
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={!collapsed ? 'chevron-up' : 'chevron-down'}
              onPress={() => setCollapsed(!collapsed)}
            />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible
        collapsed={collapsed}
        style={[]}
        renderChildrenCollapsed={false}>
        <View style={[styles.paddingTop]}>
          <View style={[styles.width, styles.marginLVertical]}>
            <Text style={[Typography.P3]}>Rendering Provider</Text>
          </View>
          <CredentialForm
            name={`sessions.${index}.renderingProvider`}
            box={'(Box 24J)'}
            entity={renderingProvider}
            extraFields={false}
            payers={watchedInsurances.map(
              (insurance: any) => insurance.payerId,
            )}
            showTaxonomy={true}
            shouldRerender={true}
          />
          <View style={[styles.width, styles.marginLVertical]}>
            <Text style={[Typography.P3]}>Supervising Provider</Text>
          </View>
          <Staff
            override={`sessions.${index}.supervisingId`}
            label={'Supervising Provider'}
          />
          {supervisingProviderId ? (
            <CredentialForm
              name={`sessions.${index}.supervisingProvider`}
              entity={supervisingProvider}
              extraFields={false}
              payers={watchedInsurances.map(
                (insurance: any) => insurance.payerId,
              )}
              showTaxonomy={true}
              shouldRerender={true}
            />
          ) : null}
        </View>
      </Collapsible>
      <ServiceLineForm
        name={`sessions.${index}`}
        planId={watchedInsurances?.[0].planId}
      />
    </View>
  );
};

export default SessionForm;
