import {Q} from '@nozbe/watermelondb';
import {
  text,
  field,
  children,
  lazy,
  json,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Payer extends Base {
  static table = 'payers';

  static associations = {
    payer_plans: {type: 'has_many', foreignKey: 'payer_id'},
    payer_credential: {type: 'has_many', foreignKey: 'payer_id'},
    insurances: {type: 'has_many', foreignKey: 'payer_id'},
  };

  @children('payer_plans') plans;
  @children('payer_credential') credentials;

  @lazy activeUserCredentials = this.credentials.extend(
    Q.where('user_id', Q.notEq(null)),
    Q.where('deleted_at', null),
  );

  @lazy activeDoctorCredentials = this.credentials.extend(
    Q.where('user_id', Q.eq(null)),
    Q.where('deleted_at', null),
  );

  @lazy activeCredentials = this.credentials.extend(
    Q.where('deleted_at', null),
  );

  @lazy activePlans = this.plans.extend(Q.where('deleted_at', null));

  @text('_partition') partition;
  @text('name') name;
  @field('payer_id') payerId;
  @field('inovalon_id') inovalonId;
  @field('legacy_id') legacyId;
  @field('staff_ids') staffIds;
  @field('enrollment') enrollment;
  @text('mobile_phone') mobilePhone;
  @text('taxonomy') taxonomy;
  @json('address', value => value) address;
}
