import {lazy, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';
import {Q} from '@nozbe/watermelondb';

export class PayerCredential extends Base {
  static table = 'payer_credential';
  static associations = {
    credentials: {type: 'belongs_to', key: 'credential_id'},
    payers: {type: 'belongs_to', key: 'payer_id'},
  };

  @relation('credentials', 'credential_id') credential;
  @relation('payers', 'payer_id') payers;

  @lazy
  activePayers = this.payers.extend(Q.where('deleted_at', Q.eq(null)));

  @text('legacy_id') legacyId;
  @text('credential_id') credentialId;
  @text('payer_id') payerId;
  @text('taxonomy') taxonomy;
}
