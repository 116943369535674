import React from 'react';
import {Text, ScrollView} from 'react-native';
import {Typography} from 'src/styles';
import {
  ProgramName,
  StimulusDescription,
  ProgramObjective,
  ProgramInstructions,
  Tags,
} from 'src/hook-form-inputs';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

interface Props {
  control: any;
  errors: any;
  edit: boolean;
  values: any;
}

const ProgramSummary = ({edit, values}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <ScrollView
      style={[styles.flex, styles.paddingHorizontal]}
      contentContainerStyle={[styles.paddingVertical]}>
      <Text style={Typography.H4}>{translations('summary')}</Text>
      <Text style={[Typography.H5, styles.marginLBottom, styles.marginTop]}>
        {translations('overview')}
      </Text>

      {edit ? (
        <>
          <ProgramName.Input />
          <StimulusDescription.Input />
          <ProgramObjective.Input />
          <ProgramInstructions.Input />
          <Tags.Input />
          <Tags.Display value={values.tags} />
        </>
      ) : (
        <>
          <ProgramName.Display value={values.name} />
          <StimulusDescription.Display value={values.stimulusDescription} />
          <ProgramObjective.Display value={values.objective} />
          <ProgramInstructions.Display value={values.teachingInstructions} />
          <Tags.Display value={values.tags} />
        </>
      )}
    </ScrollView>
  );
};

export default ProgramSummary;
