import {relation} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class ProgramTag extends Base {
  static table = 'program_tag';
  static associations = {
    programs: {type: 'belongs_to', key: 'program_id'},
    tags: {type: 'belongs_to', key: 'tag_id'},
  };

  @relation('programs', 'program_id') program;
  @relation('tags', 'tag_id') tag;
}
