import React, {forwardRef} from 'react';
import {HelperText, RadioButton} from 'react-native-paper';
import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

interface Props {
  label?: string;
  required?: boolean;
  showHelper?: boolean;
  items: {value: any; label: string}[];
  onChange: (value: string) => void;
  value: string;
  error?: any;
  style?: any;
}

const RadioInput = forwardRef(
  (
    {
      label = '',
      required = false,
      showHelper = true,
      items = [],
      onChange,
      value,
      error,
      style = {},
    }: Props,
    ref,
  ) => {
    const styles = useStyle();

    return (
      <>
        <Text
          style={[
            Typography.LABEL,
            styles.textColorSecondary,
            styles.marginSMBottom,
          ]}>
          {label + (required ? '*' : '')}
        </Text>
        <RadioButton.Group ref={ref} onValueChange={onChange} value={value}>
          <View style={[styles.flex, styles.row, styles.flexWrap]}>
            {items.map((item: any) => {
              return (
                <View
                  key={`radio-input-key-${item?.value}`}
                  style={[
                    styles.row,
                    styles.alignItemCenter,
                    styles.justifyCenter,
                    styles.alignCenter,
                    styles.marginRight,
                  ]}>
                  <RadioButton.Android
                    value={item.value}
                    color={error ? Colors.ERROR : Colors.TEXT_PRIMARY}
                    uncheckedColor={error ? Colors.ERROR : Colors.TEXT_PRIMARY}
                  />
                  <Text
                    style={[
                      {
                        ...Typography.P2,
                        color: error ? Colors.ERROR : Colors.TEXT_PRIMARY,
                      },
                      style,
                    ]}
                    onPress={() => onChange(item.value)}>
                    {item.label}
                  </Text>
                </View>
              );
            })}
          </View>
        </RadioButton.Group>
        {showHelper ? (
          <HelperText type="error" visible={true} style={styles.helper}>
            {error && 'ⓧ ' + error.message}
          </HelperText>
        ) : null}
      </>
    );
  },
);

export default RadioInput;
