import React, {useReducer, useState} from 'react';
import {BaseIndexScreen} from 'src/design-system';
import {Calendar} from 'src/modules/scheduling/components';
import {View} from 'react-native';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {compose} from 'recompose';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';
import DeleteSessionModal from '../../components/delete-session-modal';
import {useStyle} from 'src/providers/style';
import SchedulingDrawer from 'src/modules/scheduling/components/scheduling-drawer';
import SchedulingImport from 'src/modules/scheduling/components/scheduling-import';
import {useTranslations} from 'src/providers/translation';
import {useNavigation} from '@react-navigation/native';
import {useSelector} from 'react-redux';

function CalendarScreen({profile, role}: any) {
  const styles = useStyle();
  const translations = useTranslations();
  const navigation = useNavigation();

  const [update, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const [showDelete, setShowDelete] = useState<any | boolean>(false);

  const {selectedFilters} = useSelector(state => state.schedulingFilters);

  const addCallback = (event: any) => {
    navigation.navigate('ScheduleAppointment', {
      date: event.date,
      start: event.date,
    });
  };

  const editCallback = (event: any, date: Date) => {
    navigation.navigate('ScheduleAppointment', {
      id: event.id,
      date,
    });
  };

  const deleteCallback = (appointment: any, date: any) => {
    setShowDelete({
      appointment,
      date,
    });
  };

  const deleteCancelled = () => {
    setShowDelete(false);
    forceUpdate();
  };

  return (
    <BaseIndexScreen header={false} title={translations('schedule')}>
      <SchedulingImport />
      <DeleteSessionModal
        show={[showDelete, setShowDelete]}
        deleteCancelled={() => deleteCancelled()}
      />

      <View style={[styles.height, styles.flex, styles.row]}>
        <SchedulingDrawer role={role} selectedFilters={selectedFilters} />
        <View style={[styles.flex, styles.backgroundColorWhite]}>
          <Calendar
            update={update}
            filters={selectedFilters}
            profile={profile}
            assignedOnly={role?.appointmentAssignedOnly}
            canAdd={role?.appointmentCreate}
            addCallback={addCallback}
            canEdit={role?.appointmentEdit}
            editCallback={editCallback}
            canDelete={role?.appointmentDelete}
            deleteCallback={deleteCallback}
          />
        </View>
      </View>
    </BaseIndexScreen>
  );
}

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
)(CalendarScreen);
