import React from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import _ from 'lodash';

import {Colors, Typography} from 'src/styles';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import {intervalTypes} from 'src/hook-form-inputs/interval-type';

export const collectionMethods = [
  {
    value: 'trial_by_trial',
    title: 'Trial by Trial',
  },
  {
    value: 'task_analysis',
    title: 'Task Analysis',
  },
  {
    value: 'frequency',
    title: 'Frequency',
  },
  {
    value: 'rate',
    title: 'Rate',
  },
  {
    value: 'duration',
    title: 'Duration',
  },
  {
    value: 'interval',
    title: 'Interval',
  },
];

const ProgramCardCollection = ({program, targets, prompts}: any) => {
  const method = collectionMethods.find(
    collectionMethod => collectionMethod.value === program?.method,
  );

  const d = Number(program?.intervalOccurs || 0);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const promptTypes = _.groupBy(prompts, 'promptType');
  return (
    <View style={styles.container}>
      <Text style={styles.headerTxt}>Collection</Text>
      <Text style={styles.titleTxt}>Tracking Methods</Text>
      <Text style={styles.descriptionTxt}>
        Data collection method used for the program.
      </Text>
      <Text style={styles.labelTxt}>{method?.title}</Text>

      {program?.type === 'skill' && (
        <>
          <Text style={styles.titleTxt}>Targets</Text>
          <Text style={styles.descriptionTxt}>
            Individual data points to be collected on
          </Text>
          {targets.map((target: any) => (
            <Text
              key={`target-key-${target?.id}`}
              style={[styles.listTxt, styles.spacel]}>
              {target?.name}
            </Text>
          ))}
        </>
      )}

      {program?.type === 'behavior' && program?.method === 'interval' && (
        <>
          <Text style={styles.titleTxt}>Interval Type</Text>
          <Text style={styles.labelTxt}>
            {
              intervalTypes.find(
                (item: any) => item.value === program?.interval,
              )?.label
            }
          </Text>

          <Text style={styles.titleTxt}>Automation</Text>
          <Text style={styles.descriptionTxt}>
            Toggle whether the interval loops automatically once started.
          </Text>
          <Text style={styles.labelTxt}>
            {program?.intervalAutomation ? 'Automatic' : 'Manual'}
          </Text>

          <Text style={styles.titleTxt}>Occurs for</Text>
          <Text style={styles.labelTxt}>
            {h > 0 ? h + ' hours ' : ''}
            {m > 0 ? m + ' minutes ' : ''}
            {s > 0 ? s + ' seconds' : ''}
          </Text>

          <Text style={styles.titleTxt}>Fixed Occurrences</Text>
          <Text style={styles.descriptionTxt}>
            Toggle for a fixed number of interval occurrences in a session.
          </Text>
          <Text style={styles.labelTxt}>
            {program?.intervalRecurrences !== 0 ? 'Fixed' : 'Unlimited'}
          </Text>

          {program?.intervalRecurrences !== 0 ? (
            <>
              <Text style={styles.titleTxt}>Total Number of Occurrences</Text>
              <Text style={styles.descriptionTxt}>
                {program.intervalRecurrences}
              </Text>
            </>
          ) : (
            <></>
          )}
        </>
      )}

      {program?.type === 'behavior' && (
        <>
          <Text style={styles.titleTxt}>Intensity Tracker</Text>
          <Text style={styles.descriptionTxt}>
            Turning Intensity Tracking on shows mild, moderate or severe
            behavior data options for each frequency
          </Text>
          <Text style={styles.labelTxt}>
            {program?.intensity ? 'On' : 'Off'}
          </Text>
        </>
      )}

      <Text style={styles.titleTxt}>
        {program?.type === 'skill' ? 'Prompt List' : 'ABC Data'}
      </Text>
      <Text style={styles.descriptionTxt}>
        Additional response options if not independent
      </Text>
      {Object.keys(promptTypes)?.map(promptType => (
        <View key={`prompt-type-key-${promptType}`}>
          <RHSeparator height={5} />
          {Object.keys(promptTypes)?.length > 1 ? (
            <Text style={[styles.listTxt, styles.fontMedium]}>
              {promptType}
            </Text>
          ) : (
            <></>
          )}
          {promptTypes[promptType].map(prompt => (
            <Text
              key={`prompt-key-${promptType}-${prompt?.id ?? prompt}`}
              style={[styles.listTxt, styles.spacel]}>
              {prompt?.name}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 15,
    paddingBottom: 30,
  },
  headerTxt: {
    ...Typography.P2_MEDIUM,
    color: Colors.TEXT_DISABLED,
  },
  titleTxt: {
    ...Typography.P3_BOLD,
    color: Colors.TEXT_DISABLED,
    marginTop: 20,
  },
  descriptionTxt: {
    ...Typography.CAPTION,
    color: Colors.TEXT_DISABLED,
    marginVertical: 5,
  },
  labelTxt: {
    ...Typography.TAB,
    color: Colors.TEXT_PRIMARY_GRAY,
  },
  listTxt: {
    ...Typography.TAB,
    color: Colors.TEXT_PRIMARY_GRAY,
    marginLeft: 3,
  },
  spacer: {
    marginTop: 15,
  },
  fontMedium: {
    fontWeight: '500',
  },
  spacel: {
    marginLeft: 3,
  },
});

export default compose(
  withDatabase,
  withObservables(['program'], ({program}: any) => ({
    program,
    targets: program.targets,
    prompts: program.prompts,
  })),
)(ProgramCardCollection);
