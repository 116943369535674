import React from 'react';
import {Text} from 'react-native-paper';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

interface RegexValidationProps {
  regex: RegExp;
  value: string;
  message: string;
}

const RegexValidation = ({regex, value, message}: RegexValidationProps) => {
  const styles = useStyle();

  if (value.length < 1) {
    return (
      <Text
        style={[Typography.CAPTION, styles.textColorPrimary, styles.paddingSM]}>
        • {message}
      </Text>
    );
  } else if (regex.test(value)) {
    return (
      <Text
        style={[Typography.CAPTION, styles.textColorPrimary, styles.paddingSM]}>
        ✓ {message}
      </Text>
    );
  } else {
    return (
      <Text
        style={[[Typography.CAPTION, styles.textColorError, styles.paddingSM]]}>
        ✕ {message}
      </Text>
    );
  }
};

export default RegexValidation;
