import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import DisplayUserDataSnippet from 'src/common-components/displayUserDataSnippet';
import _ from 'lodash';
import {getAge} from 'src/common-utils/age';
import {useStyle} from 'src/providers/style';

interface Props {
  entities: any[];
}

const Patient = ({patient}: any) => {
  const firstName = patient?.firstName || '';
  const lastName = patient?.lastName || '';
  const resourceType = 'Patient';
  const age = getAge(patient?.birthDate);
  const gender = _.capitalize(patient?.gender);

  return (
    <DisplayUserDataSnippet
      firstName={firstName}
      lastName={lastName}
      resourceType={resourceType}
      gender={gender}
      age={age}
    />
  );
};

const Patients = ({entities}: Props) => {
  const styles = useStyle();

  return (
    <View style={[styles.column, styles.flex]}>
      <Text style={[Typography.P1, styles.textColorSecondary]}>Clients</Text>
      <View style={[styles.paddingLVertical]}>
        {entities.map((entity: any, index: number) => (
          <Patient patient={entity} key={`patient-key-${entity.id}-${index}`} />
        ))}
      </View>
    </View>
  );
};

export default Patients;
