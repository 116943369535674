import {View} from 'react-native';
import React from 'react';
import {useStyle} from 'src/providers/style';

const NotificationSeparator = () => {
  const styles = useStyle();

  return (
    <View style={[styles.flex, styles.paddingLVertical]}>
      <View style={[styles.separator]} />
    </View>
  );
};

export default NotificationSeparator;
