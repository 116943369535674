import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {Typography} from 'src/styles';
import {useNavigation} from '@react-navigation/core';
import SVG from 'src/styles/svg';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

interface HomeNotesProps {
  notesDue: any;
}
const HomeNotes = ({notesDue}: HomeNotesProps) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <LinearGradient
      start={{x: 0, y: 0}}
      end={{x: 1, y: 0}}
      colors={['#DA0E44', '#D85D10']}
      style={[styles.paddingXS, styles.borderRadius]}>
      <View
        style={[
          styles.width,
          styles.backgroundColorWhite,
          styles.paddingHorizontal,
          styles.paddingLVertical,
          styles.borderRadius,
          styles.elevation,
        ]}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('SessionNotesDue');
          }}>
          <View
            style={[
              styles.backgroundColorWhite,
              styles.row,
              styles.alignCenter,
              styles.width,
            ]}>
            <SVG height={36} name={'note'} />
            <View style={[styles.marginLLeft, styles.marginSMTop]}>
              <Text style={[Typography.H4]}>{notesDue}</Text>
              <Text
                style={[
                  Typography.P3,
                  styles.textColorPrimary,
                  styles.positionRelative,
                ]}>
                {translations('notes_due')}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
};

export default HomeNotes;
