import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import FormInput from 'src/hook-form-wrapper/form-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  required?: boolean;
}

export const Import = (
  key: string = 'email',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Email',
  key,
  validators: [
    {
      validate: 'required',
    },
    {
      validate: 'regex_match',
      regex:
        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
      errorMessage: 'Must be a valid email.',
    },
  ],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Email</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, required = false}: Props) => {
  return (
    <FormInput
      name={(name ? name + '.' : '') + 'email'}
      label={'Email'}
      placeholder={'Email'}
      helper={''}
      required={required}
    />
  );
};

export const validation = () =>
  Yup.string()
    .required('Please enter a valid email')
    .email('Must be a valid email');

export default Input;
