import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import moment from 'moment';
import {IDeveloperField} from 'dromo-uploader-react';
import DateInput from 'src/hook-form-wrapper/date-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
  label?: string;
}

interface Props {
  name?: string;
  label?: string;
  disabled?: boolean;
}

export const Import = (
  key: string = 'birthDate',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Birth Date (MM/DD/YYYY)',
  key,
  validators: [
    {
      validate: 'regex_match',
      regex:
        '(0[1-9]|1[012])[- \\/.](0[1-9]|[12][0-9]|3[01])[- \\/.](19|20)\\d\\d$',
      errorMessage: 'Must be a valid date.',
    },
  ],
});

const resolveDisplayValue = (value: string) => {
  return value ? moment(value).format('ll') : '';
};

const BirthDateDisplay = ({value, label}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>
        {label ? label : translations('birth_date')}
      </Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const BirthDateInput = ({name, disabled = false, label}: Props) => {
  const translations = useTranslations();

  return (
    <DateInput
      name={(name ? name + '.' : '') + 'birthDate'}
      label={label ? label : translations('birth_date')}
      placeholder={label ? label : translations('birth_date')}
      helper={''}
      required={false}
      maximumDate={new Date()}
      disabled={disabled}
    />
  );
};

export const validation = () => Yup.string().required('Birth date is required');

export const Display = BirthDateDisplay;
export const Input = BirthDateInput;
export const Value = resolveDisplayValue;

export default BirthDateInput;
