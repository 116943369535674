import React from 'react';
import _ from 'lodash';
import SelectInput from 'src/hook-form-wrapper/select-input';

const RepeatIntervalInput = () => {
  return (
    <SelectInput
      name={'repeatInterval'}
      label={'Repeats every'}
      required={true}
      items={_.range(1, 100).map(idx => {
        return {label: idx.toString(), value: idx.toString()};
      })}
    />
  );
};

export const Input = RepeatIntervalInput;

export default RepeatIntervalInput;
