import React from 'react';
import CheckboxInput from 'src/hook-form-wrapper/checkbox-input';
import {Colors} from 'src/styles';

export const statusArray = [
  {
    value: 'not-started',
    label: 'Not Started',
    color: Colors.TEAL_500,
    textColor: Colors.RAVEN_BLACK,
  },
  {
    value: 'in-progress',
    label: 'In Progress',
    color: Colors.LIME_500,
    textColor: Colors.RAVEN_BLACK,
  },
  {
    value: 'missing-values',
    label: 'Missing Values',
    color: Colors.RUBY_500,
    textColor: Colors.RAVEN_BLACK,
  },
  {
    value: 'completed',
    label: 'Completed',
    color: Colors.ARCTIC,
    textColor: Colors.RAVEN_BLACK,
  },
];

interface Props {
  name?: string;
  allowSelectAll?: boolean;
  search?: boolean;
}

const StatusCheckbox = ({name, allowSelectAll, search = false}: Props) => {
  return (
    <CheckboxInput
      name={(name ? name + '.' : '') + 'status'}
      items={statusArray}
      allowSelectAll={allowSelectAll}
      search={search}
    />
  );
};

export default StatusCheckbox;
