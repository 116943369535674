import React from 'react';
import {Text, View} from 'react-native';
import {DataTable} from 'react-native-paper';
import EStyleSheet from 'react-native-extended-stylesheet';
import {useDimensions} from '@react-native-community/hooks';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import moment from 'moment';
import _ from 'lodash';
import {Colors, Typography} from 'src/styles';
import PromptsDisplay from '../../prompts-display';
import DurationTableTooltip from 'src/modules/session/components/duration-table-tooltip';
import {useTranslations} from 'src/providers/translation';
import DurationTooltipEdit from '../../duration-tooltip-edit';

const RateTable = ({
  type = undefined,
  setEvents,
  setInstance,
  sets,
  program,
  events,
  setRow,
}: any) => {
  const dimensions = useDimensions();
  const width = dimensions.window.width;
  const styles = getStyles();
  const countInRow = width > 1024 ? 10 : width > 768 ? 5 : width > 480 ? 3 : 2;
  const tableCount = Math.floor(sets?.length / countInRow);
  const restCount = countInRow - (sets?.length % countInRow);
  const eventsBySetId = _.groupBy(events, 'set.id');
  const sortedSets = sets.sort((a: any, b: any) => a?.createdAt - b?.createdAt);
  const translations = useTranslations();

  if (type) {
    return (
      <DataTable style={styles.table}>
        {_.times(tableCount + 1, i => {
          if (i < tableCount || (i === tableCount && restCount < countInRow)) {
            return (
              <View key={`rate-table-${i}`}>
                <DataTable.Header
                  style={[
                    styles.head,
                    styles.ph_0,
                    i === 0 && styles.firstRow,
                  ]}>
                  <DataTable.Title
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.titleTxt}>
                    {translations('instance')}
                  </DataTable.Title>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, index: number) => (
                      <DataTable.Title
                        style={[
                          styles.justifyCenter,
                          index !== countInRow - 1 &&
                          index !== sortedSets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.titleTxt}
                        key={`rate-table-set-${set?.id}-${index}`}>
                        {i * countInRow + index + 1}
                      </DataTable.Title>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Header>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('date')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <View
                        style={[
                          styles.justifyCenter,
                          styles.alignCenter,
                          styles.flex,
                          idx !== countInRow - 1 &&
                          idx !== sortedSets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`rate-date-${set?.id}-${idx}`}>
                        <DurationTableTooltip
                          method={program?.method}
                          unit={program?.unit}
                          set={set}
                          instance={i * countInRow + idx + 1}
                          intensity={
                            program?.intensity
                              ? _.capitalize(set?.intensity)
                              : ''
                          }
                        />
                        <View style={styles.cornerTab} />
                      </View>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('start_time')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 &&
                          idx !== sortedSets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.cellBoldTxt}
                        key={`rate-starttime-${set?.id}-${idx}`}>
                        {moment(set?.startTimestamp).format('hh:mm:ss A')}
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('end_time')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 &&
                          idx !== sortedSets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`rate-endtime-${set?.id}-${idx}`}>
                        <Text style={styles.cellBoldTxt}>
                          {moment(set?.endTimestamp).format('hh:mm:ss A')}
                        </Text>
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('duration_time')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      return (
                        <View
                          style={[
                            styles.justifyCenter,
                            styles.alignCenter,
                            styles.flex,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`rate-durationtime-${set?.id}-${idx}`}>
                          <DurationTooltipEdit
                            setEvents={setEvents}
                            setRow={setRow}
                            method={program?.method}
                            set={set}
                            setInstance={setInstance}
                            row={'duration'}
                            instance={i * countInRow + idx + 1}
                          />
                          <View style={styles.cornerTab} />
                        </View>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('count')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      const count = eventsBySetId[set?.id]?.length || 0;
                      return (
                        <DataTable.Cell
                          style={[
                            styles.justifyCenter,
                            idx !== countInRow - 1 &&
                            idx !== sortedSets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`rate-count-${set?.id}-${idx}`}>
                          <Text style={styles.cellBoldTxt}>{count}</Text>
                        </DataTable.Cell>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                {program?.intensity && (
                  <DataTable.Row style={styles.ph_0}>
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.cellBoldTxt}>
                      {translations('intensity')}
                    </DataTable.Cell>
                    {sortedSets
                      .slice(i * countInRow, (i + 1) * countInRow)
                      .map((set: any, idx: number) => {
                        return (
                          <View
                            style={[
                              styles.justifyCenter,
                              styles.alignCenter,
                              styles.flex,
                              idx !== countInRow - 1 &&
                              idx !== sortedSets?.length - 1
                                ? styles.borderRight
                                : {},
                            ]}
                            key={`rate-intensity-${set?.id}-${idx}`}>
                            <DurationTooltipEdit
                              setEvents={setEvents}
                              row={'intensity'}
                              setRow={setRow}
                              method={program?.method}
                              set={set}
                              setInstance={setInstance}
                              instance={i * countInRow + idx + 1}
                              intensity={
                                program?.intensity
                                  ? _.capitalize(set?.intensity)
                                  : ''
                              }
                            />
                            <View style={styles.cornerTab} />
                          </View>
                        );
                      })}

                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Cell />)}
                  </DataTable.Row>
                )}
                <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('rate')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      const duration =
                        moment
                          .duration(
                            moment(set?.endTimestamp).diff(
                              moment(set?.startTimestamp),
                            ),
                          )
                          .asMinutes() || 0.01;
                      const count = eventsBySetId[set?.id]?.length || 0;
                      const rate =
                        duration !== 0 ? (count / duration).toFixed(2) : 0;
                      return (
                        <View
                          style={[
                            styles.justifyCenter,
                            styles.alignCenter,
                            styles.flex,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`rate-rate-${set?.id}-${idx}`}>
                          <DurationTooltipEdit
                            setEvents={setEvents}
                            setRow={setRow}
                            method={program?.method}
                            set={set}
                            setInstance={setInstance}
                            row={'rate'}
                            rate={rate}
                            instance={i * countInRow + idx + 1}
                          />
                          <View style={styles.cornerTab} />
                        </View>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
              </View>
            );
          }
        })}
      </DataTable>
    );
  } else {
    return (
      <DataTable style={styles.table}>
        {_.times(tableCount + 1, i => {
          if (i < tableCount || (i === tableCount && restCount < countInRow)) {
            return (
              <View key={`rate-table-${i}`}>
                <DataTable.Header
                  style={[
                    styles.head,
                    styles.ph_0,
                    i === 0 && styles.firstRow,
                  ]}>
                  <DataTable.Title
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.titleTxt}>
                    {translations('instance')}
                  </DataTable.Title>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, index: number) => (
                      <DataTable.Title
                        style={[
                          styles.justifyCenter,
                          index !== countInRow - 1 &&
                          index !== sortedSets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.titleTxt}
                        key={`rate-table-set-${set?.id}-${index}`}>
                        {i * countInRow + index + 1}
                      </DataTable.Title>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Header>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('date')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <View
                        style={[
                          styles.justifyCenter,
                          styles.alignCenter,
                          styles.flex,
                          idx !== countInRow - 1 &&
                          idx !== sortedSets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`rate-date-${set?.id}-${idx}`}>
                        <DurationTableTooltip
                          method={program?.method}
                          unit={program?.unit}
                          set={set}
                          instance={i * countInRow + idx + 1}
                          intensity={
                            program?.intensity
                              ? _.capitalize(set?.intensity)
                              : ''
                          }
                        />
                        <View style={styles.cornerTab} />
                      </View>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('start_time')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 &&
                          idx !== sortedSets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.cellBoldTxt}
                        key={`rate-starttime-${set?.id}-${idx}`}>
                        {moment(set?.startTimestamp).format('hh:mm:ss A')}
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('end_time')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 &&
                          idx !== sortedSets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`rate-endtime-${set?.id}-${idx}`}>
                        <Text style={styles.cellBoldTxt}>
                          {set?.endTimestamp
                            ? moment(set?.endTimestamp).format('hh:mm:ss A')
                            : 'In Progress'}
                        </Text>
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                {program?.unit !== 'per_session' ? (
                  <DataTable.Row style={styles.ph_0}>
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.cellBoldTxt}>
                      {translations('duration_time')}
                    </DataTable.Cell>
                    {sortedSets
                      .slice(i * countInRow, (i + 1) * countInRow)
                      .map((set: any, idx: number) => {
                        const duration = moment.duration(
                          moment(set?.endTimestamp).diff(
                            moment(set?.startTimestamp),
                          ),
                        );
                        const hour = duration.hours();
                        const min = duration.minutes();
                        const sec = duration.seconds();
                        return (
                          <DataTable.Cell
                            style={[
                              styles.justifyCenter,
                              idx !== countInRow - 1 &&
                              idx !== sortedSets?.length - 1
                                ? styles.borderRight
                                : {},
                            ]}
                            textStyle={styles.cellBoldTxt}
                            key={`rate-durationtime-${set?.id}-${idx}`}>
                            {hour > 0 && hour.toString() + ' hr '}
                            {min > 0 && min.toString() + ' min '}
                            {sec > 0 && sec.toString() + ' sec'}
                          </DataTable.Cell>
                        );
                      })}
                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Cell />)}
                  </DataTable.Row>
                ) : (
                  <></>
                )}
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('count')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      const count = eventsBySetId[set?.id]?.length || 0;
                      return (
                        <DataTable.Cell
                          style={[
                            styles.justifyCenter,
                            idx !== countInRow - 1 &&
                            idx !== sortedSets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`rate-count-${set?.id}-${idx}`}>
                          <Text style={styles.cellBoldTxt}>{count}</Text>
                        </DataTable.Cell>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                {program?.intensity && (
                  <DataTable.Row style={styles.ph_0}>
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.cellBoldTxt}>
                      {translations('intensity')}
                    </DataTable.Cell>
                    {sortedSets
                      .slice(i * countInRow, (i + 1) * countInRow)
                      .map((set: any, idx: number) => {
                        return (
                          <DataTable.Cell
                            style={[
                              styles.justifyCenter,
                              idx !== countInRow - 1 &&
                              idx !== sortedSets?.length - 1
                                ? styles.borderRight
                                : {},
                            ]}
                            key={`rate-intensity-${set?.id}-${idx}`}>
                            <Text style={styles.cellBoldTxt}>
                              {_.capitalize(set?.intensity)}
                            </Text>
                          </DataTable.Cell>
                        );
                      })}

                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Cell />)}
                  </DataTable.Row>
                )}
                <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('rate')}
                  </DataTable.Cell>
                  {sortedSets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      const duration =
                        moment
                          .duration(
                            moment(set?.endTimestamp).diff(
                              moment(set?.startTimestamp),
                            ),
                          )
                          .asMinutes() || 0.01;

                      let denominator = duration;

                      switch (program?.unit) {
                        case 'per_min':
                          denominator = duration;
                          break;
                        case 'per_hour':
                          denominator = duration / 60;
                          break;
                        case 'per_session':
                          denominator = 1;
                          break;
                      }
                      const count = eventsBySetId[set?.id]?.length || 0;
                      const rate = set?.endTimestamp
                        ? duration !== 0
                          ? (count / denominator).toFixed(2)
                          : 0.01
                        : 'To Be Determined';
                      return (
                        <DataTable.Cell
                          style={[
                            styles.justifyCenter,
                            idx !== countInRow - 1 &&
                            idx !== sortedSets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`rate-rate-${set?.id}-${idx}`}>
                          <View
                            style={[
                              styles.column,
                              styles.alignCenter,
                              styles.justifyCenter,
                            ]}>
                            <View style={styles.valuePadding}>
                              <Text style={styles.cellBoldTxt}>{rate}</Text>
                            </View>
                            {set?.prompts?.length ? (
                              <PromptsDisplay prompts={set?.prompts} />
                            ) : (
                              <></>
                            )}
                          </View>
                        </DataTable.Cell>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
              </View>
            );
          }
        })}
      </DataTable>
    );
  }
};

const getStyles = () =>
  EStyleSheet.create({
    table: {
      borderWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
      borderRadius: 10,
    },
    firstRow: {
      borderTopStartRadius: 10,
      borderTopEndRadius: 10,
    },
    lastRow: {
      borderBottomStartRadius: 10,
      borderBottomEndRadius: 10,
    },
    head: {
      height: 50,
      backgroundColor: Colors.PRIMARY_50,
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    titleTxt: {
      ...Typography.LABEL_MEDIUM,
      color: Colors.PRIMARY_900,
      alignSelf: 'center',
      textAlign: 'center',
      width: 100,
      flexWrap: 'wrap',
    },
    cellBoldTxt: {
      ...Typography.CAPTION_MEDIUM,
      color: Colors.PRIMARY_900,
    },
    cellTxt: {
      ...Typography.CAPTION,
      color: Colors.PRIMARY_900,
    },
    spaceTop: {
      marginTop: 5,
    },
    column: {
      flexDirection: 'column',
    },
    alignCenter: {
      alignItems: 'center',
    },
    valuePadding: {
      paddingTop: 20,
      paddingBottom: 10,
    },
    borderRight: {
      borderRightWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
    },
    flex: {
      flex: 1,
    },
    cornerTab: {
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderRightWidth: 8,
      borderTopWidth: 8,
      borderRightColor: 'transparent',
      borderTopColor: '#B5B5B8',
      transform: [{rotate: '90deg'}],
      position: 'absolute',
      top: 0,
      right: 0,
    },
    ph_0: {
      paddingHorizontal: 0,
    },
  });

export default compose(
  withDatabase,
  withObservables(['sets'], ({sets, database}: any) => {
    const setIds = _.map(sets, 'id');
    return {
      events: database
        .get('events')
        .query(
          Q.where('set_id', Q.oneOf(setIds)),
          Q.where('deleted_at', null),
          Q.sortBy('created_at', Q.desc),
        ),
    };
  }),
)(RateTable);
