import React, {useState} from 'react';
import {Searchbar} from 'react-native-paper';
import {useNavigation} from '@react-navigation/native';
import {BaseIndexScreen, DeleteModal, IconButton} from 'src/design-system';
import {ProgramProfile} from 'src/modules';
import ProgramList from '../../components/program-list';
import ProgramFilterMenu from '../../components/filter-menu';
import ProgramFilterChipIndicator from '../../components/filter-chip-indicator';
import SortByMenu from '../../components/sort-by-menu';
import {Program, Target} from 'src/models';
import DromoImportModal from 'src/common-components/bulk-import';
import PaperMenu from 'src/design-system/paper-menu';
import {Q} from '@nozbe/watermelondb';
import {IDeveloperField, IRowHook} from 'dromo-uploader-react';
import {
  ProgramName,
  StimulusDescription,
  ProgramObjective,
  // Tags,
  ProgramInstructions,
  ProgramTargets,
  NumberOfTrials,
  SkillCollectionMethods,
  BehaviorCollectionMethods,
  IntensityTracker,
  IntervalType,
  IntervalAutomation,
  IntervalRecurrences,
  IntervalOccurs,
  // ProgramPrompts,
} from 'src/hook-form-inputs';
import {ProgramPrompt, ProgramTag} from 'src/models';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {compose} from 'recompose';
import {useSelector} from 'react-redux';
import CsvExport from 'src/common-components/csv-export/components';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';
import SVG from 'src/styles/svg';

export const SkillImport: (...args: any) => IDeveloperField[] = () =>
  // tags: [],
  // prompts: [],
  [
    SkillCollectionMethods.Import(),
    ProgramName.Import(),
    StimulusDescription.Import(),
    ProgramObjective.Import(),
    // Tags.Import(tags), removing until we can do multi-select
    ProgramInstructions.Import(),
    ProgramTargets.Import(),
    NumberOfTrials.Import(),
    // ProgramPrompts.Import(prompts), removing until we can do multi-select
  ];

export const BehaviorImport: (...args: any) => IDeveloperField[] = () =>
  // tags: [],
  // prompts: [],
  [
    BehaviorCollectionMethods.Import(),
    ProgramName.Import(),
    StimulusDescription.Import(),
    ProgramObjective.Import(),
    // Tags.Import(tags), removing until we can do multi-select
    ProgramInstructions.Import(),
    IntensityTracker.Import(),
    IntervalType.Import(),
    IntervalAutomation.Import(),
    IntervalOccurs.Import(),
    IntervalRecurrences.Import(),
    // ProgramPrompts.Import(prompts), removing until we can do multi-select
  ];

export const RowHooks: IRowHook[] = [
  record => {
    const newRecord = record;

    console.log(newRecord.row);
    // newRecord.row.birthDate.value = MaskService.toMask(
    //   'datetime',
    //   newRecord.row.birthDate.value,
    //   {
    //     format: 'MM/DD/YYYY',
    //   },
    // );
    //
    // newRecord.row.mobilePhone.value = MaskService.toMask(
    //   'custom',
    //   newRecord.row.mobilePhone.value,
    //   {
    //     mask: '(999) 999-9999',
    //   },
    // );

    return newRecord;
  },
];

const ProgramListScreen = ({route, role, tags, prompts}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const {selectedGroup} = useSelector(state => state.authentication);

  const {patientId}: any = route?.params || {};
  const patientMode = !!patientId;

  const [searchStr, setSearchStr] = useState('');
  const [sortByStr, setSortByStr] = useState('');
  const [filters, setFilters] = useState<any>({
    programType: [],
    programStatus: [],
    programState: [],
    assessments: [],
    skills: [],
    behaviors: [],
    relatedServices: [],
  });
  const [showDelete, setShowDelete] = useState<any | boolean>(false);
  const navigation = useNavigation();
  const navigateToUpsert = () => {
    navigation.navigate('ProgramBuilder', {
      patientId,
    });
  };
  const viewCallback = (program: any) => {
    navigation.navigate(ProgramProfile.screenName, {
      programId: program.id,
    });
  };
  const editCallback = (program: any) => {
    navigation.navigate(ProgramProfile.screenName, {
      programId: program.id,
      edit: true,
    });
  };
  const deleteCallback = async (program: any) => {
    setShowDelete(program);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (program: any) => {
    program.delete();
    setShowDelete(false);
  };
  const archiveCallback = (program: Program) => {
    if (program?.state !== 'archived') {
      program.updateEntity({state: 'archived'});
    } else {
      program.updateEntity({state: 'active'});
    }
  };
  const exportPrograms = async () => {
    let programs = [
      [
        'Name',
        'Type',
        'Description (SD)',
        'Objective',
        'Tags',
        'Instructions',
        'Targets',
        'Collection Method',
        'Number of Trials',
        'Prompts',
      ],
    ];

    const fetchedPrograms = await database
      .get('programs')
      .query(Q.where('_partition', selectedGroup), Q.where('deleted_at', null))
      .fetch();

    for (const program of fetchedPrograms) {
      const programPrompts = await database
        .get('prompts')
        .query(Q.on('program_prompt', Q.where('program_id', program.id)))
        .fetch();
      const promptNames = programPrompts.map(prompt => prompt.name).join(',');

      const programTags = await database
        .get('tags')
        .query(Q.on('program_tag', Q.where('program_id', program.id)))
        .fetch();
      const tagNames = programTags.map(tag => tag.name).join(',');

      const targets = await database
        .get('targets')
        .query(Q.where('program_id', program.id))
        .fetch();
      const targetNames = targets.map(target => target.name).join(',');

      programs.push([
        program?.name || '',
        program?.type || '',
        program?.stimulusDescription || '',
        program?.objective || '',
        tagNames || '',
        program?.teachingInstructions || '',
        targetNames || '',
        program?.method || '',
        program?.numberOfTrials || '',
        promptNames || '',
      ]);
    }

    return programs;
  };

  const upload = async (importType: string, data: any[]) => {
    await database.write(async () => {
      for (const program of data) {
        const newProgram = await database?.get(Program.table).create(entity => {
          entity.partition = selectedGroup;
          if (importType === 'SkillImport') {
            entity.type = 'skill';
          } else if (importType === 'BehaviorImport') {
            entity.type = 'behavior';
          }
          entity.name = program.name;
          entity.stimulusDescription = program.stimulusDescription;
          entity.objective = program.objective;
          entity.teachingInstructions = program.teachingInstructions;
          entity.numberOfTrials = parseInt(program.numberOfTrials, 10);
          entity.method = program.method;
          entity.interval = parseInt(program.interval, 10);
          entity.intensity = program.intensity;

          entity.intervalAutomation = program.intervalAutomation;
          entity.intervalOccurs = parseInt(program.intervalOccurs, 10);
          entity.intervalRecurrences = parseInt(
            program.intervalRecurrences,
            10,
          );
          entity.state = 'active';
        });
        await database.get(ProgramTag.table).create(entity => {
          entity.partition = selectedGroup;
          entity.program.id = newProgram.id;
          entity.tag.id = program.tags;
        });
        await database.get(ProgramPrompt.table).create(entity => {
          entity.partition = selectedGroup;
          entity.program.id = newProgram.id;
          entity.prompt.id = program.prompts;
        });
        const splitTargets = program.programTargets.split(',');
        for (const target of splitTargets) {
          await database.get(Target.table).create(entity => {
            entity.partition = selectedGroup;
            entity.name = target;
            entity.program.id = newProgram.id;
            entity.enabled = true;
          });
        }
      }
    });
  };

  const [visible, setVisible] = useState(false);

  const closeMenu = () => setVisible(false);

  return (
    <BaseIndexScreen
      title={patientMode ? 'Add a Program' : ProgramListScreen.screenName}
      canAdd={role?.programCreate}
      add={navigateToUpsert}
      search={
        <Searchbar
          value={searchStr}
          onChangeText={setSearchStr}
          placeholder={'Search'}
        />
      }
      exportBut={<CsvExport data={exportPrograms} name={'program_export'} />}
      importBut={
        <PaperMenu
          visible={visible}
          onDismiss={closeMenu}
          anchor={
            <IconButton
              icon={'upload'}
              type={'outlined'}
              onPress={() => setVisible(!visible)}
            />
          }>
          <DromoImportModal
            fields={SkillImport(tags, prompts)}
            rowHooks={RowHooks}
            onSubmit={data => upload('SkillImport', data)}
            onCancel={closeMenu}
            identifier={'Programs'}
            templateDownloadFilename={'skill_import'}
            helpText={
              'Skill Acquisition Important Upload Note:<br/>• If your program requires Program Tags and Prompts, the tags and prompt selection can be completed within the program card after the initial upload.'
            }
            anchor={
              <View
                style={[
                  styles.row,
                  styles.alignCenter,
                  styles.justifyStart,
                  styles.paddingL,
                ]}>
                <SVG width={24} height={24} name={'skill'} fill={''} />
                <Text style={[styles.paddingLLeft, Typography.P3]}>
                  {'Skill Acquisition Import'}
                </Text>
              </View>
            }
            style={'item'}
          />
          <DromoImportModal
            fields={BehaviorImport(tags, prompts)}
            rowHooks={RowHooks}
            onSubmit={data => upload('BehaviorImport', data)}
            onCancel={closeMenu}
            identifier={'Programs'}
            templateDownloadFilename={'behavior_import'}
            helpText={
              'Behavior Tracking Important Upload Note:<br/>• If your program requires Program Tags and ABC data, the tags and ABC data selection can be completed within the program card after the initial upload.'
            }
            anchor={
              <View
                style={[
                  styles.row,
                  styles.alignCenter,
                  styles.justifyStart,
                  styles.paddingL,
                ]}>
                <SVG width={24} height={24} name={'behavior'} fill={''} />
                <Text style={[styles.paddingLLeft, Typography.P3]}>
                  {'Behavior Import'}
                </Text>
              </View>
            }
            style={'item'}
          />
        </PaperMenu>
      }
      sortByMenu={<SortByMenu setSortBy={setSortByStr} sortByStr={sortByStr} />}
      filters={
        <ProgramFilterMenu applyFilters={setFilters} filter={filters} />
      }>
      <ProgramFilterChipIndicator
        filters={filters}
        setFilters={setFilters}
        tags={tags}
      />
      <DeleteModal
        model={'Program'}
        text={showDelete ? `${showDelete?.name}` : null}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <ProgramList
        search={searchStr}
        filter={filters}
        tags={tags}
        sortBy={sortByStr}
        viewCallback={viewCallback}
        canEdit={role?.programEdit}
        editCallback={editCallback}
        canDelete={role?.programDelete}
        deleteCallback={deleteCallback}
        canArchive={role?.programEdit}
        archiveCallback={archiveCallback}
      />
    </BaseIndexScreen>
  );
};

ProgramListScreen.screenName = 'Programs';

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({database, authentication}: any) => ({
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('rank', Q.asc),
      ),
  })),
  withObservables([], ({database, authentication}: any) => ({
    prompts: database
      ?.get('prompts')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('rank', Q.asc),
      ),
  })),
)(ProgramListScreen);
