import {captureRef} from 'react-native-view-shot';
import {downloadImage} from 'src/common-utils/download-image';
import {PDFGenerator} from 'src/common-components/pdf-export/src';

export const exportGraph = async (
  exportRef: any,
  name: string,
  type: string,
) => {
  const img = await captureRef(exportRef, {
    format: type === 'pdf' ? 'png' : type,
    quality: 1,
  });
  if (type === 'pdf') {
    const pdfGenerator = new PDFGenerator();
    await pdfGenerator.generatePdf(img, name);
  } else {
    await downloadImage(img, name + '.' + type);
  }
};
