import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {Q} from '@nozbe/watermelondb';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {Role} from 'src/models';
import {catchError} from 'rxjs/operators';

const permissionsList = [
  {label: 'Admin', value: 'Admin'},
  {label: 'Member', value: 'Member'},
];

interface DisplayProps {
  value: string;
  role: any;
}

interface Props {
  name: string;
  roles: any[];
}

export const Import = (
  key: string = 'permission',
  roles: any[],
): IDeveloperField => ({
  label: `Permission (${roles.map(value => value.name).join(', ')})`,
  key,
  type: 'select',
  selectOptions: roles.map(role => ({
    label: role.name,
    value: role.id,
  })),
  validators: [
    {
      validate: 'required',
    },
  ],
});

const resolveDisplayValue = (value: string) => {
  return permissionsList.find(item => item.value === value)?.label;
};

const PermissionDisplay = compose(
  withDatabase,
  withObservables([], ({database, value}: any) => {
    return {
      role: database
        .get(Role.table)
        .findAndObserve(value)
        .pipe(catchError(() => of({}))),
    };
  }),
)(({role}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Permission Level</Text>
      <Text style={[Typography.P3]}>{role?.name}</Text>
    </DataItem>
  );
});

const PermissionInput = compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({database, authentication}: any) => ({
    roles: database
      .get('roles')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('name', Q.asc),
      ),
  })),
)(({name, roles}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'roleId'}
      label={'Permission'}
      required={true}
      multiple={false}
      selectAll={false}
      items={roles.map(role => {
        return {
          value: role.id,
          label: role.name,
        };
      })}
    />
  );
});

export const validation = () => Yup.string().required('Permission is required');

export const Display = PermissionDisplay;
export const Input = PermissionInput;
export const Value = resolveDisplayValue;

export default PermissionInput;
