import {
  children,
  json,
  lazy,
  relation,
  text,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';
import {sanitizeStrings} from 'src/common-utils/sanitize-strings';
import {Q} from '@nozbe/watermelondb';

export class Credential extends Base {
  static table = 'credentials';
  static associations = {
    users: {type: 'belongs_to', key: 'user_id'},
    payer_credential: {type: 'has_many', foreignKey: 'credential_id'},
  };

  @relation('users', 'user_id') user;

  @children('payer_credential') payerCredentials;

  @lazy
  activePayerCredentials = this.payerCredentials.extend(
    Q.where('_partition', Q.eq(this.partition)),
    Q.where('deleted_at', Q.eq(null)),
  );

  @text('user_id') userId;
  @text('tin') tin;
  @text('npi') npi;
  @text('ssn') ssn;
  @json('taxonomy', sanitizeStrings) taxonomy;
  @text('first_name') firstName;
  @text('middle_name') middleName;
  @text('last_name') lastName;
  @text('phone_number') mobilePhone;
  @text('email') email;
  @json('address', value => value) address;
}
