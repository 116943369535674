import React, {useState} from 'react';
import {View} from 'react-native';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useFieldArray, useFormContext} from 'react-hook-form';
import AddSessionsModal from 'src/modules/billing/components/add-sessions-modal';
import {Session} from 'src/models';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import Spinner from 'react-native-loading-spinner-overlay';
import SessionForm from 'src/modules/billing/components/session-form';

const SessionLineForm = ({claim}: any) => {
  const database = useDatabase();
  const styles = useStyle();

  const {control} = useFormContext();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const {
    fields: sessionFields,
    append: sessionsAppend,
    remove: sessionsRemove,
  } = useFieldArray({
    name: 'sessions',
    control,
  });

  const addSessions = async (values: string[]) => {
    setLoading(true);
    let sessions = [];

    for (const id of values) {
      const session = await database.get(Session.table).find(id);

      await session.updateEntity({
        claimId: claim.id,
      });

      const appointment = await session.appointment;

      const participant = await appointment.currentStaffParticipant(
        session.userId,
      );

      const services = [];
      for (const cpt of participant?.cpt || []) {
        services.push({
          cpt,
          modifier1: '',
          modifier2: '',
          modifier3: '',
          modifier4: '',
          diagnosisPointer: '1',
          units: '1',
          amount: '50.00',
        });
      }
      sessions.push({
        _id: session.id,
        staff: session.userId,
        renderingId: session.userId,
        supervisingId: session.supervisingId,
        date: session.date,
        startDate: session.editedStartTimestamp,
        endDate: session.editedEndTimestamp,
        location: session.location,
        address: session.address,
        customAddress: session.customAddress,
        patient: session.patientId,
        services,
      });
    }

    sessionsAppend(sessions);
    setShow(false);
    setLoading(false);
  };

  const removeSession = async (id: string, index: number) => {
    const session = await database.get(Session.table).find(id);
    await session.updateEntity({
      claimId: '',
    });
    sessionsRemove(index);
  };

  return (
    <>
      <Spinner visible={loading} />
      {show ? (
        <AddSessionsModal
          claim={claim}
          show={[show, setShow]}
          callback={addSessions}
        />
      ) : null}
      {sessionFields.map((session: any, index: number) => {
        return (
          <SessionForm
            key={`session-${session.id}-${index}`}
            index={index}
            deleteCallback={() => removeSession(session._id, index)}
          />
        );
      })}
      <View style={[styles.row]}>
        <RHButton
          icon="plus-circle-outline"
          mode="outlined"
          color={Colors.RAVEN_WHITE}
          textColor={Colors.RAVEN_BLACK}
          onPress={() => {
            setShow(true);
          }}>
          ADD MORE
        </RHButton>
      </View>
    </>
  );
};

export default SessionLineForm;
