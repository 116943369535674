import {View} from 'react-native';
import React, {ReactNode} from 'react';
import {memoize} from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';
import {useDimensions} from '@react-native-community/hooks';

interface Props {
  left: ReactNode;
  right: ReactNode;
}

const DisplayGroup = ({left = <></>, right = <></>}: Props) => {
  const dimensions = useDimensions();

  const styles = getStyles(dimensions.window.width);

  return (
    <View style={[styles.container]}>
      <View style={[styles.marginRightAuto]}>{left}</View>
      <View style={[styles.marginRightAuto]}>{right}</View>
    </View>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStyles = memoize((width: number) =>
  EStyleSheet.create({
    marginRightAuto: {marginRight: 'auto'},
    '@media (min-width: 768)': {
      container: {
        flexDirection: 'row',
      },
    },
  }),
);

export default DisplayGroup;
