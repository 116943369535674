import React, {useState} from 'react';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import PlanName, {
  Display as PlanNameDisplay,
} from 'src/hook-form-inputs/plan-name';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {IconButton} from 'react-native-paper';
import Collapsible from 'react-native-collapsible';
import {useTranslations} from 'src/providers/translation';
import PayerPlanFeeScheduleItem from 'src/modules/billing/components/payer-plan-fee-schedule-item';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {DataItem} from 'src/design-system';

export const defaultFeeLine = {
  cpt: '',
  description: '',
  modifier1: '',
  modifier2: '',
  modifier3: '',
  modifier4: '',
  rate: '',
};

const feeScheduleHeaders = [
  {
    text: 'Billing Code',
    style: 'flex03',
  },
  {
    text: 'Service Desc.',
    style: 'flex13',
  },
  {
    text: 'Mod #1',
    style: 'flex05',
  },
  {
    text: 'Mod #2',
    style: 'flex05',
  },
  {
    text: 'Mod #3',
    style: 'flex05',
  },
  {
    text: 'Mod #4',
    style: 'flex05',
  },
  {
    text: 'Contracted Rate',
    style: 'flex',
  },
  {
    text: 'Billing Rate',
    style: 'flex',
  },
];

export const PlanDisplay = ({value}: any) => {
  const styles = useStyle();

  return (
    <>
      <PlanNameDisplay value={value.name} />
      {value.notes ? (
        <DataItem>
          <Text style={[Typography.H6]}>Plan Notes</Text>
          <Text style={[Typography.P3]}>{value.notes}</Text>
        </DataItem>
      ) : null}
      <View style={[styles.flex]}>
        <View style={[styles.row, styles.borderBottom]}>
          {feeScheduleHeaders.map((header: any, index: number) => (
            <View
              key={`header-${header.text}-${index}`}
              style={[
                styles.paddingL,
                styles.borderRight,
                styles.alignCenter,
                styles.justifyCenter,
                styles[header.style],
              ]}>
              <Text style={[Typography.P3]}>{header.text}</Text>
            </View>
          ))}
        </View>
        {value.feeSchedule.map((plan: any, index: number) => (
          <View
            key={`plan-display-fee-${index}`}
            style={[
              styles.row,
              styles.borderBottom,
              styles.alignCenter,
              styles.justifyCenter,
            ]}>
            <View
              style={[
                styles.paddingL,
                styles.borderRight,
                styles.flex03,
                styles.alignCenter,
                styles.justifyCenter,
              ]}>
              <View style={[styles.width, styles.alignCenter]}>
                <Text style={[Typography.P3, styles.textColorPrimary]}>
                  {plan.cpt}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.paddingL,
                styles.borderRight,
                styles.flex13,
                styles.alignCenter,
                styles.justifyCenter,
                styles.height,
              ]}>
              <View style={[styles.width, styles.alignCenter]}>
                <Text style={[Typography.P3, styles.textColorPrimary]}>
                  {plan.description}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.paddingL,
                styles.borderRight,
                styles.flex05,
                styles.alignCenter,
              ]}>
              <View style={[styles.width, styles.alignCenter]}>
                <Text style={[Typography.P3, styles.textColorPrimary]}>
                  {plan.modifier1}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.paddingL,
                styles.borderRight,
                styles.flex05,
                styles.alignCenter,
              ]}>
              <View style={[styles.width, styles.alignCenter]}>
                <Text style={[Typography.P3, styles.textColorPrimary]}>
                  {plan.modifier2}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.paddingL,
                styles.borderRight,
                styles.flex05,
                styles.alignCenter,
              ]}>
              <View style={[styles.width, styles.alignCenter]}>
                <Text style={[Typography.P3, styles.textColorPrimary]}>
                  {plan.modifier3}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.paddingL,
                styles.borderRight,
                styles.flex05,
                styles.alignCenter,
              ]}>
              <View style={[styles.width, styles.alignCenter]}>
                <Text style={[Typography.P3, styles.textColorPrimary]}>
                  {plan.modifier4}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.paddingL,
                styles.borderRight,
                styles.flex,
                styles.alignCenter,
              ]}>
              <View style={[styles.width, styles.alignCenter]}>
                <Text style={[Typography.P3, styles.textColorPrimary]}>
                  ${plan.rate} / 1 unit
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.paddingL,
                styles.borderRight,
                styles.flex,
                styles.alignCenter,
              ]}>
              <View style={[styles.width, styles.alignCenter]}>
                <Text style={[Typography.P3, styles.textColorPrimary]}>
                  ${plan.billingRate} / 1 unit
                </Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};

export const PlanInput = ({name, deleteCallback}: any) => {
  const styles = useStyle();
  const {control} = useFormContext();
  const [collapsed, setCollapsed] = useState(false);
  const planName = useWatch({control, name: `${name}.name`});
  const {fields, append, remove} = useFieldArray({
    control,
    name: `${name}.feeSchedule`,
  });

  return (
    <View style={[styles.card, styles.paddingHorizontal, styles.justifyCenter]}>
      <TouchableOpacity
        style={[styles.justifyCenter]}
        onPress={() => setCollapsed(!collapsed)}>
        <View style={[styles.row, styles.flex]}>
          <View style={[styles.paddingVertical, styles.flex]}>
            <Text style={Typography.P2_MEDIUM}>{planName}</Text>
          </View>
          <View
            style={[
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
            ]}>
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon="trash-can"
              onPress={deleteCallback}
            />
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={!collapsed ? 'chevron-up' : 'chevron-down'}
              onPress={() => setCollapsed(!collapsed)}
            />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible collapsed={collapsed} style={[styles.paddingVertical]}>
        <PlanName name={name} />
        <HookFormInput name={`${name}.notes`} label={'Plan Notes'} />
        <View style={[styles.flex]}>
          <View style={[styles.row, styles.borderBottom]}>
            {feeScheduleHeaders.map((header: any, index: number) => (
              <View
                key={`header-${header.text}-${index}`}
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.alignCenter,
                  styles.justifyCenter,
                  styles[header.style],
                ]}>
                <Text style={[Typography.P3]}>{header.text}</Text>
              </View>
            ))}
          </View>
          {fields.map((field: any, index: number) => (
            <PayerPlanFeeScheduleItem
              key={`${field.id}-${index}`}
              item={field}
              index={index}
              remove={remove}
              name={name}
            />
          ))}
          <View
            style={[styles.row, styles.paddingHorizontal, styles.marginBottom]}>
            <RHButton
              icon="plus-circle-outline"
              mode="outlined"
              color={Colors.RAVEN_WHITE}
              textColor={Colors.RAVEN_BLACK}
              onPress={() => {
                append(defaultFeeLine);
              }}>
              Add New
            </RHButton>
          </View>
        </View>
      </Collapsible>
    </View>
  );
};

export const defaultPlan = {
  name: '',
  feeSchedule: [defaultFeeLine],
};

export const PayerPlansDisplay = ({values}: any) => {
  const styles = useStyle();
  return (
    <>
      {values.plans.map((plan: any, index: number) => {
        return (
          <View key={`plan-display-${index}`} style={[styles.paddingBottom]}>
            <PlanDisplay value={plan} />
          </View>
        );
      })}
    </>
  );
};

const PayerPlansForm = ({}) => {
  const styles = useStyle();
  const translations = useTranslations();
  const {control} = useFormContext();

  const {fields, append, remove, replace} = useFieldArray({
    control,
    name: 'plans',
  });

  return (
    <ScrollView contentContainerStyle={[]}>
      <View style={[styles.padding, styles.paddingLBottom]}>
        <Text style={[Typography.H5, styles.textColorPrimary]}>
          {translations('add_payer_plans')}
        </Text>
      </View>
      {fields.map((plan: any, index: number) => {
        if (plan?.deletedAt) {
          return null;
        }
        return (
          <PlanInput
            key={plan.id}
            name={`plans.${index}`}
            deleteCallback={() => {
              if (plan?._id) {
                const planFields = [...fields];
                planFields[index] = {
                  ...plan,
                  deletedAt: new Date(),
                };
                replace(planFields);
              } else {
                remove(index);
              }
            }}
          />
        );
      })}
      <View style={[styles.row, styles.paddingHorizontal, styles.marginBottom]}>
        <RHButton
          icon="plus-circle-outline"
          mode="outlined"
          color={Colors.RAVEN_WHITE}
          textColor={Colors.RAVEN_BLACK}
          onPress={() => {
            append(defaultPlan);
          }}>
          ADD MORE
        </RHButton>
      </View>
    </ScrollView>
  );
};

export default PayerPlansForm;
