import {InputGroup} from 'src/design-system';
import React from 'react';
import * as Yup from 'yup';
import {
  AddressAutoComplete,
  AddressLine,
  AddressUse,
  City,
  Country,
  PostalCode,
  State,
} from 'src/form-inputs';

interface DisplayProps {
  value: {
    use: string;
    line: string[];
    city: string;
    state: string;
    country: string;
    postalCode: string;
  };
}

interface Props {
  valueKey: string;
}

const resolveDisplayValue = (value: string) => {
  return value;
};

const AddressDisplay = ({value}: DisplayProps) => {
  return (
    <>
      <AddressUse.Display value={value?.use} />
      <AddressLine.Display value={value?.line} />
      <City.Display value={value?.city} />
      <State.Display value={value?.state} />
      <Country.Display value={value.country} />
      <PostalCode.Display value={value?.postalCode} />
    </>
  );
};

const AddressInput = ({valueKey}: Props) => {
  return (
    <>
      <AddressUse.Input
        valueKey={(valueKey ? valueKey + '.' : '') + 'address'}
      />

      <Country.Input valueKey={(valueKey ? valueKey + '.' : '') + 'address'} />

      <AddressLine.Input
        valueKey={(valueKey ? valueKey + '.' : '') + 'address'}
      />
      <City.Input valueKey={(valueKey ? valueKey + '.' : '') + 'address'} />

      <InputGroup
        left={
          <State.Input
            valueKey={(valueKey ? valueKey + '.' : '') + 'address'}
          />
        }
        right={
          <PostalCode.Input
            valueKey={(valueKey ? valueKey + '.' : '') + 'address'}
          />
        }
      />
    </>
  );
};

export const validation = () =>
  Yup.object().shape({
    use: AddressUse.validation(),
    line: AddressAutoComplete.validation(),
    city: City.validation(),
    state: Yup.string().when('country', {
      is: (country: string) => country === 'US',
      then: () => State.validation(),
    }),
    country: Country.validation(),
    postalCode: PostalCode.validation(),
  });

export const Display = AddressDisplay;
export const Input = AddressInput;
export const Value = resolveDisplayValue;

export default AddressInput;
