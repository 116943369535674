import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {FastField} from 'formik';
import {HelperText} from 'react-native-paper';
import {DatePickerInput} from 'react-native-paper-dates';
import {BLUE_RIBBON} from 'src/styles/colors';
import {SafeAreaProvider} from 'react-native-safe-area-context';

interface Props {
  valueKey: string;
  name: string;
  required: boolean;
  maximumDate?: Date | undefined;
  minDate?: Date | undefined;
  style?: any;
}

const DateLayout = ({
  valueKey,
  name,
  required = false,
  maximumDate = undefined,
  minDate = undefined,
  style = {},
}: Props) => {
  return (
    <View style={styles.container}>
      <FastField name={valueKey}>
        {({field, form: {setFieldValue}, meta: {error, touched}}: any) => {
          return (
            <SafeAreaProvider>
              <DatePickerInput
                locale="en"
                label={name + (required ? '*' : '')}
                value={field.value}
                onChange={date => setFieldValue(field.name, date)}
                validRange={{
                  startDate: minDate,
                  endDate: maximumDate,
                }}
                inputMode="start"
                mode="outlined"
                theme={{colors: {primary: BLUE_RIBBON}}}
                style={[styles.input, style]}
              />
              <HelperText type="error" visible={true} style={styles.helper}>
                {error && touched && 'ⓧ ' + error}
              </HelperText>
            </SafeAreaProvider>
          );
        }}
      </FastField>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  input: {
    backgroundColor: 'white',
  },
  label: {
    ...Typography.LABEL,
    color: Colors.TEXT_SECONDARY,
    paddingBottom: 8,
  },
  datepicker: {
    borderWidth: 1,
    borderRadius: 10,
    padding: 2,
    borderColor: Colors.GRAY_400,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
  },
  helper: {},
});

export default DateLayout;
