import React from 'react';
import {DataItem, SelectInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';

export const positionsList = [
  {label: 'Clinician', value: '224583007'},
  {label: 'Technician', value: '224584001'},
  {label: 'Admin Assistant', value: '224608005'},
];

interface DisplayProps {
  value: string;
}

interface InputProps {
  valueKey: string;
}

export const Import = (
  key: string = 'position',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Position (${positionsList.map(value => value.label).join(', ')})`,
  key,
  type: 'select',
  selectOptions: positionsList,
});

const resolveDisplayValue = (value: string) => {
  return positionsList.find(item => item.value === value)?.label;
};

const PositionDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Position</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const PositionInput = ({valueKey}: InputProps) => {
  return (
    <SelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'position'}
      name={'Position'}
      required={false}
      items={positionsList}
    />
  );
};

export const validation = () => Yup.string().required('Position is required');

export const Display = PositionDisplay;
export const Input = PositionInput;
export const Value = resolveDisplayValue;

export default PositionInput;
