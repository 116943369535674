import React from 'react';
import {View, Text} from 'react-native';
import PaperMenu from 'src/design-system/paper-menu';
import {IconButton as FilterButton} from 'src/design-system';
import {useStyle} from 'src/providers/style';

const Filters = ({
  children,
  notificationNumberCount,
  onDismiss,
  open,
  setOpen,
}: any) => {
  const styles = useStyle();

  return (
    <PaperMenu
      visible={open}
      onDismiss={() => {
        onDismiss();
        setOpen(false);
      }}
      anchor={
        <View style={[styles.positionRelative]}>
          {notificationNumberCount() !== 0 ? (
            <View style={[styles.circle]}>
              <Text
                style={[
                  styles.alignCenter,
                  styles.alignSelfCenter,
                  styles.textColorWhite,
                ]}>
                {notificationNumberCount()}
              </Text>
            </View>
          ) : (
            <></>
          )}
          <FilterButton
            icon={'filter-variant'}
            type={'outlined'}
            onPress={() => setOpen(!open)}
          />
        </View>
      }>
      <View style={[styles.padding]}>{children}</View>
    </PaperMenu>
  );
};

export default Filters;
