import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {buildInitials} from 'src/common-utils/name-builders';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

interface Props {
  notification: any;
  navigation: any;
}

const Notification = ({
  notification: currentNotification,
  navigation,
}: Props) => {
  const styles = useStyle();

  const title = currentNotification.title || 'Test Notification';
  const body = currentNotification.body || 'This is a Dummy Notification';

  const handlePress = () => {
    if (currentNotification.subjectId === 'session_sigs') {
      currentNotification.read();
    } else {
      navigation.navigate('Schedule');
      currentNotification.read();
    }
  };

  return (
    <TouchableOpacity
      onPress={() => handlePress()}
      style={[styles.row, styles.alignCenter]}>
      <RHAvatar
        label={buildInitials(title)}
        color={Colors.RAVEN_BLACK}
        textColor={Colors.RAVEN_WHITE}
        size={28}
      />
      <View style={[styles.flex, styles.marginLLeft]}>
        <Text style={[Typography.SECONDARY_BUTTON]}>{title}</Text>
        <Text style={[Typography.P3]}>{body}</Text>
      </View>
      <Icon name="chevron-right" size={15} />
    </TouchableOpacity>
  );
};

export default Notification;
