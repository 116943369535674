import React from 'react';
import Form from 'src/hook-form/form';
import {Controller} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import FormInput from 'src/hook-form/form-input';
import FileInput from 'src/hook-form/file-input';
import _ from 'lodash';

interface Props {
  handleSubmit: (values: any, formik: any) => void;
  handleCancel?: () => void;
  defaultValues: object;
}

const DocumentForm = ({handleSubmit, handleCancel, defaultValues}: Props) => {
  const {
    control,
    handleSubmit: onSubmit,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
  });

  return (
    <Form
      // errors={errors}
      handleSubmit={onSubmit(handleSubmit)}
      cancelled={handleCancel}
      saveText={'Save'}
      cancelText={'Cancel'}>
      <Controller
        control={control}
        rules={{
          required: 'File Name is required.',
        }}
        render={({
          field: {onChange, onBlur, value, ref},
          fieldState: {error},
        }) => (
          <FormInput
            formRef={ref}
            onChange={onChange}
            onBlur={onBlur}
            onSubmit={() => console.log('submit file name')}
            value={value}
            placeholder="File Name"
            label="File Name"
            helper=""
            error={error}
          />
        )}
        name="fileName"
        defaultValue=""
      />

      {defaultValues.id === undefined ? (
        <>
          <Controller
            control={control}
            rules={{
              required: 'A File is required.',
            }}
            render={({field: {onChange, value, ref}, fieldState: {error}}) => (
              <FileInput
                formRef={ref}
                onChange={(file: any) => {
                  onChange(file);
                  if (_.isEmpty(getValues().fileName)) {
                    setValue('fileName', file.name);
                  }
                }}
                value={value}
                error={error}
              />
            )}
            name="file"
            defaultValue=""
          />
        </>
      ) : (
        <></>
      )}
    </Form>
  );
};

export default DocumentForm;
