import moment from 'moment/moment';

export const calculateDuration = (
  startTimestamp: number,
  endTimestamp: number,
) => {
  const durationInSeconds = moment(endTimestamp).diff(
    startTimestamp,
    'seconds',
  );
  const remainingSeconds = durationInSeconds % 60;
  const durationInMins = moment(endTimestamp).diff(startTimestamp, 'minutes');
  const diffInHours = Math.floor(durationInMins / 60);
  const remainingMinutes = durationInMins % 60;
  const diffInMinutes = Math.floor(durationInSeconds / 60);

  if (durationInSeconds < 60) {
    return durationInSeconds + ' second(s)';
  } else if (durationInSeconds < 3600) {
    return diffInMinutes + ' minute(s) and ' + remainingSeconds + ' second(s)';
  } else {
    return (
      diffInHours +
      ' hour(s) and ' +
      remainingMinutes +
      ' minute(s) and ' +
      remainingSeconds +
      ' second(s)'
    );
  }
};
