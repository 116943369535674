import FilterChip from 'src/design-system/filter-chip';
import React from 'react';
import {Colors} from 'src/styles';
import {appointmentLocations} from 'src/form-inputs/appointment-location-input';

const Location = ({value}: any) => {
  const location = appointmentLocations.find(item => item.value === value);

  return (
    <FilterChip
      textColor={location?.textColor ?? Colors.RAVEN_WHITE}
      backgroundColor={location?.chipColor ?? Colors.RAVEN_BLACK}
      icon={location?.icon ?? 'office-building'}
      label={location?.label}
    />
  );
};

export default Location;
