import React from 'react';
import {DataItem} from 'src/design-system';
import {sortedDiagnosisList} from 'src/common-utils/diagnosisCodes';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  multiple?: boolean;
}

export const Import = (
  key: string = 'diagnosis',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Diagnosis Code',
  key,
  type: 'select',
  selectOptions: sortedDiagnosisList(),
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return sortedDiagnosisList().find(item => item.value === value)?.label;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Diagnosis Code</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, multiple = false}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'diagnosis'}
      label={'Diagnosis Code'}
      required={false}
      items={sortedDiagnosisList()}
      multiple={multiple}
    />
  );
};

export const validation = () => Yup.string().required('Diagnosis is required');

export default Input;
