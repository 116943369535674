import React, {useState} from 'react';
import BaseAuthScreen from 'src/modules/authentication/screens/base-auth-screen';
import {ForgotPasswordForm} from 'src/modules/authentication/components';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {BASE_AUTH_URL} from 'react-native-dotenv';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

const ForgotPasswordScreen = ({navigation}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [progress, setProgress] = useState(false);
  const [toast, setToast] = useState<null | any>(null);

  const handleSubmit = async (payload: any, formik: any) => {
    setProgress(true);
    try {
      await fetch(`${BASE_AUTH_URL}/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Transfer-Encoding': 'chunked',
        },
        body: JSON.stringify({email: payload.email}),
      });
      formik.resetForm();
      setToast(null);
      navigation.navigate('Login', {
        success: true,
        title: translations('reset_password_title'),
        message: `${translations('reset_password_message_start')} ${
          payload.email
        } ${translations('reset_password_message_end')}`,
      });
    } catch (err) {
      setToast({
        success: false,
        title: translations('error_occurred'),
        message: translations('please_try_again'),
      });
    } finally {
      setProgress(false);
    }
  };

  const login = () => {
    navigation.navigate('Login');
  };

  return (
    <BaseAuthScreen loading={progress} toast={toast}>
      <Text
        style={[
          Typography.P3,
          styles.textAlignCenter,
          styles.marginBottom,
          styles.textColorPrimary,
        ]}>
        {translations('auth_screen_password_reset')}
      </Text>
      <ForgotPasswordForm handleSubmit={handleSubmit} login={login} />
    </BaseAuthScreen>
  );
};

export default ForgotPasswordScreen;
