import React from 'react';
import {View, StyleSheet} from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors} from 'src/styles';
import SVG from 'src/styles/svg';

export const ProgramMethodAvatar = ({type}: {type: string}) => {
  switch (type) {
    case 'trial_by_trial':
      return (
        <View style={[styles.circleBack, styles.blueBack]}>
          <SVG width={18} height={18} name={'trial_by_trial'} fill={''} />
        </View>
      );
    case 'task_analysis':
      return (
        <View style={[styles.circleBack, styles.blueBack]}>
          <SVG width={18} height={18} name={'task_analysis'} fill={''} />
        </View>
      );
    case 'frequency':
      return (
        <View style={[styles.circleBack, styles.redBack]}>
          <AntDesign name="plus" size={18} color={Colors.RAVEN_WHITE} />
        </View>
      );
    case 'duration':
      return (
        <View style={[styles.circleBack, styles.redBack]}>
          <MaterialCommunityIcon
            name="timer-outline"
            size={17}
            color={Colors.RAVEN_WHITE}
          />
        </View>
      );
    case 'rate': {
      return (
        <View style={[styles.circleBack, styles.redBack]}>
          <MaterialIcon name="bar-chart" size={17} color={Colors.RAVEN_WHITE} />
        </View>
      );
    }
    case 'interval': {
      return (
        <View style={[styles.circleBack, styles.redBack]}>
          <MaterialCommunityIcon
            name="timer-outline"
            size={17}
            color={Colors.RAVEN_WHITE}
          />
        </View>
      );
    }
    default:
      return <View style={[styles.circleBack, styles.redBack]} />;
  }
};

const styles = StyleSheet.create({
  circleBack: {
    width: 33,
    height: 33,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  blueBack: {
    backgroundColor: Colors.TEAL_500,
  },
  redBack: {
    backgroundColor: Colors.ARCTIC_500,
  },
});

export default ProgramMethodAvatar;
