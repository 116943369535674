import {field, json, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';
import {sanitizeStrings} from 'src/common-utils/sanitize-strings';

export class Instance extends Base {
  static table = 'instances';

  @text('name') name;
  @text('dba') dba;
  @text('logo') logo;
  @json('address', value => value) address;
  @json('billing_address', value => value) billingAddress;
  @field('reporting') reporting;
  @text('tin') tin;
  @text('npi') npi;
  @text('note_template') noteTemplate;
  @text('account_number') accountNumber;
  @field('billing') billing;
  @text('diagnoses') diagnoses;
  @field('single_address') singleAddress;
  @field('actual_time') actualTime;
  @field('use_client_identifier') useClientIdentifier;
  @json('taxonomy', sanitizeStrings) taxonomy;
}
