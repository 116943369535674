import React from 'react';
import _ from 'lodash';
import {Svg, Path, G, Defs, LinearGradient, Stop, Rect} from 'react-native-svg';

const SVG = ({name, fill, width, height, ...otherProps}) => {
  const graphics = {
    logo: {
      width: 380,
      height: 105,
      content: (
        <Svg
          width="380"
          height="105"
          fill="#000000"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 379.59 104.16">
          <Path d="M165.68,66.69a20.42,20.42,0,0,1,15.2-6.1v9.63c-6.63.27-11.58,2.57-15.2,7v26H155.42V61.38h10.26Z" />
          <Path d="M264.94,103.19h-9L236.66,61.38h10.87L260.44,90.2l13-28.82h10.78Z" />
          <Path d="M328.76,86.31H296.68c1.23,5.21,5.12,9.19,12.19,9.19,4.07,0,7-1.32,8.49-3.89h10.87c-2.38,7.69-10.07,12.55-19.62,12.55-13.35,0-22.19-9.45-22.19-21.83s8.84-21.92,22.19-21.92c11.58,0,20.33,8.66,20.42,21.21A27.81,27.81,0,0,1,328.76,86.31Zm-32.17-7.6h22.1c-.8-5.92-4.51-9.64-10.61-9.64C301.54,69.07,297.74,73.32,296.59,78.71Z" />
          <Path d="M379.59,80.12v23.07H369.42V80.74c0-7.34-3.89-11.4-10.25-11.4-3.62,0-7.6,2.12-10.78,6v27.84H338.13V61.38h10.26v3.45a19.63,19.63,0,0,1,12.9-4.77C372.25,60.06,379.59,68,379.59,80.12Z" />
          <Path d="M219.6,61.38V64.3a21.6,21.6,0,0,0-12.82-3.89c-11.67,0-21,9.72-21,21.92s9.37,21.83,21,21.83a21.66,21.66,0,0,0,12.82-3.88v2.91h10.16V61.38Zm-11,34.16a13.29,13.29,0,1,1,13.28-13.28A13.29,13.29,0,0,1,208.64,95.54Z" />
          <Path d="M128.29,6.94C128.21,6.83,123.61,0,111.35,0S93.83,8.14,93.83,8.14c-12.52,2.44-16.76,9.53-16.76,9.53l23.38.31c2.35.84,4.66,2.87,6.81,5.44L29.94,76.62H43.67L0,102.2l82.07-9.47,22.8,11.43S129,64.69,131.81,49.79C133.83,39.23,128.29,6.94,128.29,6.94Z" />
        </Svg>
      ),
    },
    note: {
      width: 42,
      height: 45,
      content: (
        <Svg
          width="42"
          height="45"
          viewBox="0 0 42 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <G opacity="0.5">
            <Path
              d="M3.70433 12.5687H28.3793C28.7429 12.5687 29.0916 12.7132 29.3487 12.9702C29.6057 13.2273 29.7502 13.576 29.7502 13.9396V39.9854C29.7502 41.0761 29.3169 42.1221 28.5456 42.8934C27.7744 43.6646 26.7284 44.0979 25.6377 44.0979H6.446C5.35529 44.0979 4.30926 43.6646 3.53802 42.8934C2.76678 42.1221 2.3335 41.0761 2.3335 39.9854V13.9396C2.3335 13.576 2.47792 13.2273 2.735 12.9702C2.99209 12.7132 3.34076 12.5687 3.70433 12.5687Z"
              fill="url(#paint0_linear)"
            />
          </G>
          <Path
            d="M15.75 21.9031H26.25"
            stroke="black"
            strokeWidth="2.76923"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M15.75 27.153H26.25"
            stroke="black"
            strokeWidth="2.76923"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M9.1875 7.46545H32.8125C33.1606 7.46545 33.4944 7.60374 33.7406 7.84988C33.9867 8.09602 34.125 8.42986 34.125 8.77795V33.7155C34.125 34.7597 33.7102 35.7613 32.9717 36.4997C32.2333 37.2381 31.2318 37.653 30.1875 37.653H11.8125C10.7682 37.653 9.76669 37.2381 9.02827 36.4997C8.28984 35.7613 7.875 34.7597 7.875 33.7155V8.77795C7.875 8.42986 8.01328 8.09602 8.25942 7.84988C8.50556 7.60374 8.8394 7.46545 9.1875 7.46545Z"
            stroke="black"
            strokeWidth="2.76923"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M13.125 4.84058V10.0906"
            stroke="black"
            strokeWidth="2.76923"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M21 4.84058V10.0906"
            stroke="black"
            strokeWidth="2.76923"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M28.875 4.84058V10.0906"
            stroke="black"
            strokeWidth="2.76923"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Defs>
            <LinearGradient
              id="paint0_linear"
              x1="7.62681"
              y1="18.5743"
              x2="29.4087"
              y2="19.0053"
              gradientUnits="userSpaceOnUse">
              <Stop offset="0.335858" stopColor="#DA0E44" />
              <Stop offset="1" stopColor="#D85D10" />
            </LinearGradient>
          </Defs>
        </Svg>
      ),
    },
    ios: {
      width: 120,
      height: 40,
      content: (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.66 40">
          <G>
            <G>
              <G>
                <Path
                  fill="#b7b7b7"
                  d="M110.13,0H7.52a13.51,13.51,0,0,0-2,.18,6.74,6.74,0,0,0-1.9.63A6.07,6.07,0,0,0,2,2,6.07,6.07,0,0,0,.82,3.62a6.74,6.74,0,0,0-.63,1.9,12.18,12.18,0,0,0-.17,2c0,.31,0,.62,0,.92V31.56c0,.31,0,.61,0,.92a12.18,12.18,0,0,0,.17,2,6.8,6.8,0,0,0,.63,1.91A6.19,6.19,0,0,0,2,38a6.07,6.07,0,0,0,1.62,1.18,6.74,6.74,0,0,0,1.9.63,13.51,13.51,0,0,0,2,.18H112.14a13.39,13.39,0,0,0,2-.18,6.91,6.91,0,0,0,1.91-.63A6.24,6.24,0,0,0,117.67,38a6.68,6.68,0,0,0,1.18-1.61,7.12,7.12,0,0,0,.62-1.91,13.39,13.39,0,0,0,.18-2c0-.31,0-.61,0-.92V8.44c0-.3,0-.61,0-.92a13.39,13.39,0,0,0-.18-2,7.05,7.05,0,0,0-.62-1.9A6.54,6.54,0,0,0,117.67,2,6.24,6.24,0,0,0,116.05.82a6.91,6.91,0,0,0-1.91-.63,13.39,13.39,0,0,0-2-.18h-2Z"
                />
                <Path d="M8.44,39.12h-.9A11.52,11.52,0,0,1,5.67,39,5.89,5.89,0,0,1,4,38.4a5.16,5.16,0,0,1-1.39-1,5.19,5.19,0,0,1-1-1.4,5.79,5.79,0,0,1-.55-1.66,12.1,12.1,0,0,1-.16-1.87c0-.21,0-.92,0-.92V8.44s0-.69,0-.89a12,12,0,0,1,.16-1.87A5.69,5.69,0,0,1,1.6,4a5.06,5.06,0,0,1,1-1.4A5.52,5.52,0,0,1,4,1.6a5.79,5.79,0,0,1,1.66-.55A12.1,12.1,0,0,1,7.54.89H112.13a12.08,12.08,0,0,1,1.86.16,6,6,0,0,1,1.67.55A5.49,5.49,0,0,1,118.07,4a5.92,5.92,0,0,1,.54,1.65,13.68,13.68,0,0,1,.17,1.88v.89c0,.38,0,.74,0,1.1V30.46c0,.37,0,.72,0,1.08v.93a13.12,13.12,0,0,1-.17,1.85,5.85,5.85,0,0,1-.54,1.67,5.42,5.42,0,0,1-1,1.39,5.19,5.19,0,0,1-1.4,1A5.59,5.59,0,0,1,114,39a11.52,11.52,0,0,1-1.87.16H8.44Z" />
              </G>
              <G>
                <G>
                  <Path
                    fill="#fff"
                    d="M24.77,20.3a4.92,4.92,0,0,1,2.36-4.15,5.08,5.08,0,0,0-4-2.16c-1.67-.18-3.3,1-4.16,1s-2.19-1-3.61-1a5.31,5.31,0,0,0-4.47,2.73C9,20.11,10.4,25,12.25,27.74c.93,1.33,2,2.81,3.43,2.75s1.9-.88,3.58-.88,2.14.88,3.59.85,2.43-1.33,3.32-2.67a11.09,11.09,0,0,0,1.52-3.09A4.77,4.77,0,0,1,24.77,20.3Z"
                  />
                  <Path
                    fill="#fff"
                    d="M22,12.21a4.89,4.89,0,0,0,1.11-3.49,5,5,0,0,0-3.21,1.66,4.65,4.65,0,0,0-1.14,3.36A4.11,4.11,0,0,0,22,12.21Z"
                  />
                </G>
                <G>
                  <Path
                    fill="#fff"
                    d="M42.3,27.14H37.57L36.43,30.5h-2l4.48-12.42H41L45.48,30.5h-2Zm-4.24-1.55h3.75L40,20.14h-.05Z"
                  />
                  <Path
                    fill="#fff"
                    d="M55.16,26c0,2.81-1.51,4.62-3.78,4.62A3.09,3.09,0,0,1,48.53,29h0v4.48H46.63v-12h1.8V23h0a3.22,3.22,0,0,1,2.89-1.6C53.65,21.35,55.16,23.16,55.16,26Zm-1.91,0c0-1.83-1-3-2.39-3s-2.38,1.23-2.38,3,1,3.05,2.38,3.05S53.25,27.82,53.25,26Z"
                  />
                  <Path
                    fill="#fff"
                    d="M65.12,26c0,2.81-1.5,4.62-3.77,4.62A3.08,3.08,0,0,1,58.5,29h0v4.48H56.6v-12h1.8V23h0a3.22,3.22,0,0,1,2.88-1.6C63.61,21.35,65.12,23.16,65.12,26Zm-1.91,0c0-1.83-.94-3-2.39-3s-2.37,1.23-2.37,3S59.4,29,60.82,29,63.21,27.82,63.21,26Z"
                  />
                  <Path
                    fill="#fff"
                    d="M71.71,27c.14,1.23,1.33,2,3,2s2.69-.81,2.69-1.92-.68-1.54-2.29-1.94l-1.61-.39c-2.28-.55-3.33-1.61-3.33-3.34,0-2.15,1.86-3.62,4.51-3.62s4.43,1.47,4.49,3.62H77.26c-.11-1.24-1.14-2-2.63-2s-2.52.75-2.52,1.86c0,.87.65,1.39,2.25,1.79l1.37.33c2.55.6,3.61,1.63,3.61,3.44,0,2.33-1.86,3.78-4.8,3.78-2.75,0-4.61-1.42-4.73-3.66Z"
                  />
                  <Path
                    fill="#fff"
                    d="M83.35,19.3v2.14h1.72v1.47H83.35v5c0,.77.34,1.13,1.1,1.13a5,5,0,0,0,.61,0v1.46a5.21,5.21,0,0,1-1,.09c-1.84,0-2.55-.69-2.55-2.45V22.91H80.16V21.44h1.32V19.3Z"
                  />
                  <Path
                    fill="#fff"
                    d="M86.06,26c0-2.85,1.68-4.64,4.3-4.64s4.29,1.79,4.29,4.64S93,30.61,90.36,30.61,86.06,28.83,86.06,26Zm6.7,0c0-1.95-.9-3.11-2.4-3.11S88,24,88,26s.89,3.11,2.4,3.11S92.76,27.93,92.76,26Z"
                  />
                  <Path
                    fill="#fff"
                    d="M96.19,21.44H98V23h0a2.16,2.16,0,0,1,2.18-1.63,2.58,2.58,0,0,1,.64.07v1.74A2.37,2.37,0,0,0,100,23,1.88,1.88,0,0,0,98,25.13V30.5H96.19Z"
                  />
                  <Path
                    fill="#fff"
                    d="M109.38,27.84c-.25,1.64-1.85,2.77-3.89,2.77-2.64,0-4.27-1.77-4.27-4.6s1.64-4.68,4.19-4.68,4.08,1.72,4.08,4.47v.63h-6.4v.12a2.36,2.36,0,0,0,2.44,2.56,2.05,2.05,0,0,0,2.09-1.27Zm-6.28-2.71h4.53a2.17,2.17,0,0,0-2.22-2.29A2.3,2.3,0,0,0,103.1,25.13Z"
                  />
                </G>
              </G>
            </G>
            <G>
              <Path
                fill="#fff"
                d="M37.83,8.73a2.64,2.64,0,0,1,2.8,3c0,1.9-1,3-2.8,3H35.67v-6ZM36.6,13.85h1.12a1.87,1.87,0,0,0,2-2.14,1.88,1.88,0,0,0-2-2.14H36.6Z"
              />
              <Path
                fill="#fff"
                d="M41.68,12.44a2.13,2.13,0,1,1,4.25,0,2.13,2.13,0,1,1-4.25,0Zm3.33,0c0-1-.43-1.54-1.2-1.54s-1.21.57-1.21,1.54S43,14,43.81,14,45,13.42,45,12.44Z"
              />
              <Path
                fill="#fff"
                d="M51.57,14.7h-.92l-.93-3.32h-.07l-.93,3.32h-.91l-1.24-4.51h.9l.81,3.44h.06l.93-3.44h.85l.93,3.44h.07l.8-3.44h.89Z"
              />
              <Path
                fill="#fff"
                d="M53.85,10.19h.86v.72h.07a1.33,1.33,0,0,1,1.34-.8,1.46,1.46,0,0,1,1.56,1.67V14.7h-.89V12c0-.73-.32-1.09-1-1.09a1,1,0,0,0-1.08,1.14V14.7h-.89Z"
              />
              <Path fill="#fff" d="M59.09,8.44H60V14.7h-.89Z" />
              <Path
                fill="#fff"
                d="M61.22,12.44a2.13,2.13,0,1,1,4.25,0,2.13,2.13,0,1,1-4.25,0Zm3.33,0c0-1-.44-1.54-1.21-1.54s-1.2.57-1.2,1.54S62.57,14,63.34,14,64.55,13.42,64.55,12.44Z"
              />
              <Path
                fill="#fff"
                d="M66.4,13.42c0-.81.6-1.27,1.68-1.34L69.3,12v-.39c0-.47-.32-.74-.93-.74s-.84.18-.93.5h-.87c.1-.78.82-1.27,1.84-1.27s1.77.56,1.77,1.51V14.7h-.86v-.64h-.07a1.49,1.49,0,0,1-1.35.71A1.36,1.36,0,0,1,66.4,13.42ZM69.3,13v-.38l-1.1.07c-.62,0-.91.26-.91.65s.36.64.84.64A1.06,1.06,0,0,0,69.3,13Z"
              />
              <Path
                fill="#fff"
                d="M71.35,12.44c0-1.42.73-2.32,1.87-2.32a1.49,1.49,0,0,1,1.38.79h.06V8.44h.89V14.7H74.7V14h-.07a1.56,1.56,0,0,1-1.41.78C72.07,14.77,71.35,13.87,71.35,12.44Zm.92,0c0,1,.45,1.53,1.2,1.53s1.21-.58,1.21-1.52-.47-1.53-1.21-1.53S72.27,11.5,72.27,12.44Z"
              />
              <Path
                fill="#fff"
                d="M79.23,12.44a2.13,2.13,0,1,1,4.25,0,2.13,2.13,0,1,1-4.25,0Zm3.33,0c0-1-.44-1.54-1.2-1.54s-1.21.57-1.21,1.54S80.58,14,81.36,14,82.56,13.42,82.56,12.44Z"
              />
              <Path
                fill="#fff"
                d="M84.67,10.19h.85v.72h.07a1.36,1.36,0,0,1,1.35-.8,1.46,1.46,0,0,1,1.55,1.67V14.7h-.88V12c0-.73-.32-1.09-1-1.09a1,1,0,0,0-1.07,1.14V14.7h-.89Z"
              />
              <Path
                fill="#fff"
                d="M93.52,9.07v1.15h1V11h-1v2.32c0,.47.19.68.63.68l.34,0v.74a3.45,3.45,0,0,1-.48,0c-1,0-1.38-.34-1.38-1.21V11h-.72v-.74h.72V9.07Z"
              />
              <Path
                fill="#fff"
                d="M95.7,8.44h.89v2.48h.07A1.37,1.37,0,0,1,98,10.11a1.48,1.48,0,0,1,1.55,1.68V14.7h-.89V12c0-.72-.33-1.08-1-1.08a1.06,1.06,0,0,0-1.14,1.14V14.7H95.7Z"
              />
              <Path
                fill="#fff"
                d="M104.76,13.48a1.82,1.82,0,0,1-2,1.3,2,2,0,0,1-2.08-2.32,2.08,2.08,0,0,1,2.08-2.35c1.25,0,2,.85,2,2.27v.31h-3.17v.05A1.18,1.18,0,0,0,102.83,14a1.1,1.1,0,0,0,1.08-.55ZM101.64,12h2.27a1.09,1.09,0,0,0-1.11-1.17A1.15,1.15,0,0,0,101.64,12Z"
              />
            </G>
          </G>
        </Svg>
      ),
    },
    behavior: {
      width: 33,
      height: 33,
      content: (
        <Svg
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M27.9887 5.01125L25.6975 7.3025C28.0375 9.65875 29.5 12.9087 29.5 16.5C29.5 23.6825 23.6825 29.5 16.5 29.5C9.3175 29.5 3.5 23.6825 3.5 16.5C3.5 9.87 8.45625 4.41 14.875 3.61375V6.89625C10.26 7.67625 6.75 11.6737 6.75 16.5C6.75 21.8787 11.1213 26.25 16.5 26.25C21.8787 26.25 26.25 21.8787 26.25 16.5C26.25 13.8025 25.1612 11.365 23.39 9.61L21.0987 11.9013C22.2687 13.0875 23 14.7125 23 16.5C23 20.0913 20.0913 23 16.5 23C12.9087 23 10 20.0913 10 16.5C10 13.4775 12.08 10.9588 14.875 10.2275V13.705C13.9 14.2737 13.25 15.2975 13.25 16.5C13.25 18.2875 14.7125 19.75 16.5 19.75C18.2875 19.75 19.75 18.2875 19.75 16.5C19.75 15.2975 19.1 14.2575 18.125 13.705V0.25H16.5C7.53 0.25 0.25 7.53 0.25 16.5C0.25 25.47 7.53 32.75 16.5 32.75C25.47 32.75 32.75 25.47 32.75 16.5C32.75 12.015 30.93 7.9525 27.9887 5.01125Z"
            fill="url(#paint0_linear_35805_1433)"
          />
          <Defs>
            <LinearGradient
              id="paint0_linear_35805_1433"
              x1="16.5"
              y1="0.25"
              x2="16.5"
              y2="32.75"
              gradientUnits="userSpaceOnUse">
              <Stop stopColor="#4DB6C3" />
              <Stop offset="1" stopColor="#037481" />
            </LinearGradient>
          </Defs>
        </Svg>
      ),
    },
    skill: {
      width: 35,
      height: 35,
      content: (
        <Svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M30.3125 16.875H27.875V10.375C27.875 8.5875 26.4125 7.125 24.625 7.125H18.125V4.6875C18.125 2.445 16.305 0.625 14.0625 0.625C11.82 0.625 10 2.445 10 4.6875V7.125H3.5C1.7125 7.125 0.26625 8.5875 0.26625 10.375V16.55H2.6875C5.10875 16.55 7.075 18.5162 7.075 20.9375C7.075 23.3588 5.10875 25.325 2.6875 25.325H0.25V31.5C0.25 33.2875 1.7125 34.75 3.5 34.75H9.675V32.3125C9.675 29.8912 11.6412 27.925 14.0625 27.925C16.4837 27.925 18.45 29.8912 18.45 32.3125V34.75H24.625C26.4125 34.75 27.875 33.2875 27.875 31.5V25H30.3125C32.555 25 34.375 23.18 34.375 20.9375C34.375 18.695 32.555 16.875 30.3125 16.875Z"
            fill="url(#paint0_linear_35805_47)"
          />
          <Defs>
            <LinearGradient
              id="paint0_linear_35805_47"
              x1="7.33094"
              y1="0.625"
              x2="20.663"
              y2="27.6615"
              gradientUnits="userSpaceOnUse">
              <Stop stopColor="#8D96E8" />
              <Stop offset="1" stopColor="#353F95" />
            </LinearGradient>
          </Defs>
        </Svg>
      ),
    },
    skill_white: {
      width: 21,
      height: 21,
      content: (
        <Svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M18.2364 10.0307H16.7829V6.15477C16.7829 5.08888 15.9108 4.21679 14.8449 4.21679H10.969V2.7633C10.969 1.42609 9.8837 0.34082 8.54649 0.34082C7.20928 0.34082 6.12401 1.42609 6.12401 2.7633V4.21679H2.24804C1.18215 4.21679 0.319749 5.08888 0.319749 6.15477V9.83694H1.76355C3.20735 9.83694 4.37983 11.0094 4.37983 12.4532C4.37983 13.897 3.20735 15.0695 1.76355 15.0695H0.310059V18.7517C0.310059 19.8176 1.18215 20.6897 2.24804 20.6897H5.93021V19.2362C5.93021 17.7924 7.10269 16.6199 8.54649 16.6199C9.99029 16.6199 11.1628 17.7924 11.1628 19.2362V20.6897H14.8449C15.9108 20.6897 16.7829 19.8176 16.7829 18.7517V14.8757H18.2364C19.5736 14.8757 20.6589 13.7904 20.6589 12.4532C20.6589 11.116 19.5736 10.0307 18.2364 10.0307Z"
            fill="white"
          />
        </Svg>
      ),
    },
    trial_by_trial: {
      width: 27,
      height: 23,
      content: (
        <Svg
          width="27"
          height="23"
          viewBox="0 0 27 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <Rect
            x="2.03747"
            y="22.0234"
            width="16.4038"
            height="12.3875"
            rx="1.5"
            transform="rotate(-95.2255 2.03747 22.0234)"
            fill="white"
            stroke="#09BEF8"
          />
          <Rect
            x="5.84012"
            y="18.6885"
            width="16.4038"
            height="12.3875"
            rx="1.5"
            transform="rotate(-82.4967 5.84012 18.6885)"
            fill="white"
            stroke="#09BEF8"
          />
          <Rect
            x="10.6407"
            y="16.583"
            width="16.4038"
            height="12.3875"
            rx="1.5"
            transform="rotate(-76.9997 10.6407 16.583)"
            fill="white"
            stroke="#09BEF8"
          />
        </Svg>
      ),
    },
    task_analysis: {
      width: 22,
      height: 18,
      content: (
        <Svg
          width="22"
          height="18"
          viewBox="0 0 22 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <Rect
            x="14.6667"
            y="6.28571"
            width="7.33333"
            height="5.2381"
            rx="2"
            fill="white"
          />
          <Rect
            x="14.6667"
            y="6.28571"
            width="7.33333"
            height="5.2381"
            rx="2"
            fill="white"
          />
          <Rect
            x="14.6667"
            y="12.5714"
            width="7.33333"
            height="5.2381"
            rx="2"
            fill="white"
          />
          <Rect
            y="6.28571"
            width="12.5714"
            height="5.2381"
            rx="2"
            fill="white"
          />
          <Rect
            y="12.5714"
            width="12.5714"
            height="5.2381"
            rx="2"
            fill="white"
          />
          <Rect width="22" height="5.07692" rx="2" fill="white" />
          <Rect
            width="13.619"
            height="1.04762"
            rx="0.52381"
            transform="matrix(0 -1 -1 0 18.8571 15.7143)"
            fill="white"
          />
        </Svg>
      ),
    },
    pen: {
      width: 34,
      height: 32,
      content: (
        <Svg
          width="34"
          height="32"
          viewBox="0 0 34 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M14.5 20C15.8807 20 17 18.8807 17 17.5C17 16.1193 15.8807 15 14.5 15C13.1193 15 12 16.1193 12 17.5C12 18.8807 13.1193 20 14.5 20Z"
            stroke="#25262F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M4.00073 28.0005L12.7322 19.2678"
            stroke="#25262F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M4.00073 28.0005L21.4314 25.0954C21.6045 25.0665 21.767 24.9926 21.9025 24.8811C22.038 24.7696 22.1418 24.6244 22.2034 24.4601L25.0007 17.0005L15.0007 7.00049L7.54112 9.79785C7.37682 9.85946 7.23161 9.96323 7.1201 10.0987C7.00859 10.2342 6.9347 10.3967 6.90585 10.5698L4.00073 28.0005Z"
            stroke="#25262F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M25.0007 17.0003L28.2936 13.7074C28.3865 13.6146 28.4601 13.5043 28.5104 13.383C28.5607 13.2617 28.5865 13.1317 28.5865 13.0003C28.5865 12.869 28.5607 12.739 28.5104 12.6177C28.4601 12.4963 28.3865 12.3861 28.2936 12.2932L19.7078 3.70744C19.5203 3.51991 19.2659 3.41455 19.0007 3.41455C18.7355 3.41455 18.4812 3.51991 18.2936 3.70744L15.0007 7.00034"
            stroke="#25262F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M26.8571 1C23.072 1 20 4.072 20 7.85714C20 11.6423 23.072 14.7143 26.8571 14.7143C30.6423 14.7143 33.7143 11.6423 33.7143 7.85714C33.7143 4.072 30.6423 1 26.8571 1ZM26.8571 8.54286C26.48 8.54286 26.1714 8.23429 26.1714 7.85714V5.11429C26.1714 4.73714 26.48 4.42857 26.8571 4.42857C27.2343 4.42857 27.5429 4.73714 27.5429 5.11429V7.85714C27.5429 8.23429 27.2343 8.54286 26.8571 8.54286ZM27.5429 11.2857H26.1714V9.91429H27.5429V11.2857Z"
            fill="#F12E5D"
          />
        </Svg>
      ),
    },
    google: {
      width: 705,
      height: 720,
      content: (
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width="705.6"
          height="720"
          viewBox="0 0 186.69 190.5">
          <G transform="translate(1184.583 765.171)">
            <Path
              d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
              fill="#4285f4"
            />
            <Path
              d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
              fill="#34a853"
            />
            <Path
              d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
              fill="#fbbc05"
            />
            <Path
              d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
              fill="#ea4335"
            />
          </G>
        </Svg>
      ),
    },
    microsoft: {
      width: 2500,
      height: 2500,
      content: (
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width="2500"
          height="2500"
          viewBox="0 0 2499.6 2500">
          <Path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b" />
          <Path
            d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z"
            fill="#80cc28"
          />
          <Path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef" />
          <Path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09" />
        </Svg>
      ),
    },
    apple: {
      width: 2500,
      height: 2500,
      content: (
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width="2500"
          height="2500"
          fill="black"
          className="bi bi-apple"
          viewBox="0 0 16 16">
          <Path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z" />
          <Path />
        </Svg>
      ),
    },
    export_pdf: {
      width: 24,
      height: 24,
      content: (
        <Svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M4.5 2.68786C4.5 2.57478 4.52265 2.51998 4.53372 2.5H14.8108L18.8547 6.54397C18.8561 6.56634 18.8571 6.59604 18.8571 6.635V21.4953C18.8475 21.4987 18.8395 21.5 18.8346 21.5H4.5225C4.51763 21.5 4.50967 21.4987 4.5 21.4953V2.68786Z"
            stroke="#25262F"
          />
          <Path
            d="M15.0718 2.05371V6.2855H19.3036L15.0718 2.05371Z"
            fill="#25262F"
          />
          <Path
            d="M8.6479 13.9011C8.52362 13.9011 8.40433 13.8607 8.30255 13.7847C7.93076 13.5057 7.88076 13.1954 7.90433 12.9839C7.96933 12.4025 8.68826 11.7939 10.0418 11.1739C10.579 9.9968 11.09 8.54644 11.3947 7.33465C11.0383 6.55894 10.6918 5.55251 10.9443 4.96215C11.0329 4.75537 11.1433 4.5968 11.3493 4.52823C11.4308 4.50108 11.6365 4.4668 11.7122 4.4668C11.8922 4.4668 12.0504 4.69858 12.1625 4.84144C12.2679 4.97573 12.5068 5.26037 12.0293 7.27073C12.5108 8.26501 13.1929 9.27787 13.8465 9.97144C14.3147 9.8868 14.7175 9.84358 15.0458 9.84358C15.605 9.84358 15.944 9.97394 16.0822 10.2425C16.1965 10.4647 16.1497 10.7243 15.9429 11.0139C15.744 11.2922 15.4697 11.4393 15.15 11.4393C14.7158 11.4393 14.21 11.165 13.6461 10.6232C12.6329 10.835 11.4497 11.2129 10.4933 11.6311C10.1947 12.2647 9.90862 12.775 9.64219 13.1493C9.27612 13.6618 8.9604 13.9011 8.6479 13.9011ZM9.59862 12.0704C8.8354 12.4993 8.52433 12.8518 8.50183 13.0504C8.49826 13.0832 8.48862 13.1697 8.65576 13.2975C8.70897 13.2807 9.01969 13.1389 9.59862 12.0704ZM14.469 10.4839C14.76 10.7079 14.8311 10.8211 15.0215 10.8211C15.105 10.8211 15.3433 10.8175 15.4536 10.6636C15.5068 10.5889 15.5275 10.5411 15.5358 10.5154C15.4918 10.4922 15.4336 10.445 15.1161 10.445C14.9358 10.4454 14.709 10.4532 14.469 10.4839ZM11.8011 8.13323C11.5458 9.0168 11.2086 9.97073 10.8461 10.8347C11.5925 10.545 12.404 10.2922 13.1661 10.1132C12.684 9.55323 12.2022 8.85394 11.8011 8.13323ZM11.5843 5.11108C11.5493 5.12287 11.1093 5.73858 11.6186 6.25965C11.9575 5.5043 11.5997 5.10608 11.5843 5.11108Z"
            fill="#25262F"
          />
          <Path
            d="M18.8346 22.0001H4.5225C4.23393 22.0001 4 21.7662 4 21.4776V15.9287H19.3571V21.4776C19.3571 21.7662 19.1232 22.0001 18.8346 22.0001Z"
            fill="#25262F"
          />
          <Path
            d="M7.88783 20.9287H7.30176V17.3301H8.33676C8.48961 17.3301 8.64104 17.3544 8.79069 17.4033C8.94033 17.4522 9.07461 17.5254 9.19354 17.6229C9.31247 17.7204 9.40854 17.8386 9.48176 17.9769C9.55497 18.1151 9.59176 18.2704 9.59176 18.4433C9.59176 18.6258 9.56069 18.7908 9.4989 18.939C9.43711 19.0872 9.35069 19.2119 9.23997 19.3126C9.12926 19.4133 8.99569 19.4915 8.83961 19.5469C8.68354 19.6022 8.51069 19.6297 8.32211 19.6297H7.88747L7.88783 20.9287ZM7.88783 17.7744V19.2001H8.42497C8.4964 19.2001 8.56711 19.1879 8.63747 19.1633C8.70747 19.139 8.77176 19.099 8.83033 19.0436C8.8889 18.9883 8.93604 18.9112 8.97176 18.8119C9.00747 18.7126 9.02533 18.5897 9.02533 18.4433C9.02533 18.3847 9.01711 18.3169 9.00104 18.2408C8.98461 18.1644 8.9514 18.0912 8.90104 18.0212C8.85033 17.9512 8.77961 17.8926 8.68854 17.8454C8.59747 17.7983 8.47676 17.7747 8.32711 17.7747L7.88783 17.7744Z"
            fill="white"
          />
          <Path
            d="M13.1856 19.0294C13.1856 19.3254 13.1538 19.5786 13.0903 19.7886C13.0267 19.9986 12.9463 20.1744 12.8485 20.3161C12.7506 20.4579 12.641 20.5694 12.5188 20.6508C12.3967 20.7322 12.2788 20.7929 12.1649 20.834C12.051 20.8747 11.9467 20.9008 11.8524 20.9122C11.7581 20.9229 11.6881 20.9286 11.6424 20.9286H10.2803V17.3301H11.3642C11.6671 17.3301 11.9331 17.3783 12.1624 17.474C12.3917 17.5697 12.5824 17.6979 12.7338 17.8572C12.8853 18.0165 12.9981 18.1983 13.0731 18.4015C13.1481 18.6051 13.1856 18.8144 13.1856 19.0294ZM11.4474 20.499C11.8446 20.499 12.131 20.3722 12.3067 20.1183C12.4824 19.8644 12.5703 19.4965 12.5703 19.0147C12.5703 18.8651 12.5524 18.7169 12.5167 18.5704C12.4806 18.424 12.4117 18.2915 12.3092 18.1726C12.2067 18.0536 12.0674 17.9576 11.8917 17.8844C11.716 17.8112 11.4881 17.7744 11.2081 17.7744H10.8663V20.499H11.4474Z"
            fill="white"
          />
          <Path
            d="M14.6314 17.7744V18.9072H16.1353V19.3076H14.6314V20.9286H14.0356V17.3301H16.2864V17.7744H14.6314Z"
            fill="white"
          />
        </Svg>
      ),
    },
    export_jpg: {
      width: 24,
      height: 24,
      content: (
        <Svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M4.54376 2.5H15.265L19.48 6.55017C19.4812 6.57143 19.4821 6.59917 19.4821 6.635V21.4905C19.4659 21.4969 19.4505 21.5 19.4384 21.5H4.54376C4.53166 21.5 4.51625 21.4969 4.5 21.4905V2.68786C4.5 2.56011 4.53076 2.51025 4.53806 2.50024C4.53951 2.5001 4.54139 2.5 4.54376 2.5Z"
            stroke="#25262F"
          />
          <Path
            d="M15.522 2.05371V6.2855H19.926L15.522 2.05371Z"
            fill="#25262F"
          />
          <Path
            d="M8.62056 8.78603C9.55844 8.78603 10.3188 8.05546 10.3188 7.15425C10.3188 6.25304 9.55844 5.52246 8.62056 5.52246C7.68267 5.52246 6.92236 6.25304 6.92236 7.15425C6.92236 8.05546 7.68267 8.78603 8.62056 8.78603Z"
            fill="#25262F"
          />
          <Path
            d="M4 15.9289H8.08845H19.9821V12.0003L16.2654 8.60742L12.3627 12.7146L10.3248 10.7564L4 15.9289Z"
            fill="#25262F"
          />
          <Path
            d="M19.4384 22.0001H4.54376C4.24345 22.0001 4 21.7662 4 21.4776V15.9287H19.9821V21.4776C19.9821 21.7662 19.7387 22.0001 19.4384 22.0001Z"
            fill="#25262F"
          />
          <Path
            d="M9.54811 17.2324V20.0353C9.54811 20.2046 9.51578 20.3471 9.45148 20.4624C9.38717 20.5778 9.30057 20.6706 9.19242 20.7406C9.08389 20.8106 8.95937 20.8596 8.81888 20.8871C8.67801 20.9149 8.53343 20.9288 8.38439 20.9288C8.30968 20.9288 8.22234 20.9213 8.12273 20.9067C8.02275 20.8921 7.91942 20.8696 7.81275 20.8385C7.70608 20.8074 7.60349 20.7724 7.50537 20.7335C7.40688 20.6946 7.32399 20.6506 7.25635 20.6017L7.51541 20.2064C7.54923 20.2289 7.59755 20.2528 7.66036 20.2771C7.7228 20.3013 7.79156 20.3242 7.86627 20.3453C7.94061 20.3667 8.01866 20.3849 8.10006 20.4013C8.18145 20.4178 8.25765 20.4256 8.32864 20.4256C8.50816 20.4256 8.65125 20.3921 8.75792 20.3256C8.8646 20.2592 8.92109 20.1456 8.92815 19.9863V17.2324H9.54811Z"
            fill="white"
          />
          <Path
            d="M11.0674 20.9046H10.4575V17.3057H11.5346C11.6937 17.3057 11.8513 17.33 12.007 17.3789C12.1628 17.4278 12.3025 17.501 12.4263 17.5985C12.5501 17.696 12.65 17.8142 12.7262 17.9525C12.8024 18.0907 12.8407 18.246 12.8407 18.4189C12.8407 18.6014 12.8084 18.7664 12.7441 18.9146C12.6798 19.0628 12.5898 19.1874 12.4746 19.2882C12.3594 19.3889 12.2204 19.4671 12.058 19.5225C11.8955 19.5778 11.7156 19.6053 11.5194 19.6053H11.0671L11.0674 20.9046ZM11.0674 17.7503V19.176H11.6264C11.7008 19.176 11.7744 19.1639 11.8476 19.1392C11.9204 19.1149 11.9873 19.0749 12.0483 19.0196C12.1093 18.9642 12.1583 18.8871 12.1955 18.7878C12.2327 18.6885 12.2512 18.5657 12.2512 18.4192C12.2512 18.3607 12.2427 18.2928 12.226 18.2167C12.2089 18.1403 12.1743 18.0671 12.1219 17.9971C12.0691 17.9271 11.9955 17.8685 11.9007 17.8214C11.8056 17.7739 11.6803 17.7503 11.5246 17.7503H11.0674Z"
            fill="white"
          />
          <Path
            d="M16.2658 19.0485V20.4399C16.1878 20.5346 16.1008 20.6114 16.0042 20.6717C15.9075 20.7321 15.8061 20.7817 15.6994 20.8206C15.5927 20.8596 15.4827 20.8874 15.369 20.9035C15.2552 20.9206 15.143 20.9285 15.0311 20.9285C14.8074 20.9285 14.6018 20.8896 14.4138 20.8114C14.2257 20.7331 14.0614 20.6178 13.9209 20.4646C13.7804 20.3114 13.67 20.1196 13.5905 19.8885C13.511 19.6574 13.4712 19.3903 13.4712 19.0878C13.4712 18.7853 13.511 18.5189 13.5905 18.2896C13.67 18.0599 13.7801 17.8689 13.9209 17.716C14.0614 17.5628 14.2264 17.4467 14.4164 17.3667C14.6059 17.2867 14.8111 17.2471 15.0311 17.2471C15.2344 17.2471 15.424 17.2796 15.6002 17.3446C15.7763 17.4099 15.9336 17.5074 16.0726 17.6374L15.6507 17.9989C15.5693 17.9042 15.476 17.836 15.3712 17.7939C15.266 17.7517 15.1579 17.7303 15.046 17.7303C14.9207 17.7303 14.8011 17.7528 14.6877 17.7985C14.574 17.8442 14.4725 17.9214 14.3829 18.0303C14.293 18.1396 14.2227 18.2799 14.1722 18.4528C14.1216 18.6256 14.0941 18.8367 14.0908 19.0874C14.0941 19.3317 14.1205 19.5431 14.1696 19.7221C14.2186 19.901 14.2863 20.0474 14.3729 20.1614C14.4595 20.2753 14.5576 20.3599 14.6676 20.4153C14.7776 20.4706 14.8936 20.4981 15.0159 20.4981C15.0531 20.4981 15.1029 20.4956 15.1657 20.4906C15.2281 20.4856 15.2909 20.4778 15.3537 20.4664C15.4162 20.4549 15.4764 20.4396 15.534 20.4199C15.5916 20.4003 15.634 20.3728 15.6611 20.3371V19.4485H15.0259V19.0481L16.2658 19.0485Z"
            fill="white"
          />
        </Svg>
      ),
    },
    export_png: {
      width: 24,
      height: 24,
      content: (
        <Svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M4.5 2.68786C4.5 2.57478 4.52265 2.51998 4.53372 2.5H14.8107L18.8547 6.54397C18.8561 6.56634 18.8571 6.59603 18.8571 6.635V21.4953C18.8475 21.4987 18.8395 21.5 18.8346 21.5H4.5225C4.51762 21.5 4.50967 21.4987 4.5 21.4953V2.68786Z"
            stroke="#25262F"
          />
          <Path
            d="M18.8346 22.0001H4.5225C4.23393 22.0001 4 21.7662 4 21.4776V15.9287H19.3571V21.4776C19.3571 21.7662 19.1232 22.0001 18.8346 22.0001Z"
            fill="#25262F"
          />
          <Path
            d="M15.0713 2.05371V6.2855H19.3031L15.0713 2.05371Z"
            fill="#25262F"
          />
          <Path
            d="M7.88734 20.9286H7.30127V17.3301H8.33627C8.48913 17.3301 8.64055 17.3544 8.7902 17.4033C8.93984 17.4522 9.07413 17.5254 9.19305 17.6229C9.31198 17.7204 9.40805 17.8386 9.48127 17.9769C9.55448 18.1151 9.59127 18.2704 9.59127 18.4433C9.59127 18.6258 9.5602 18.7908 9.49841 18.939C9.43663 19.0872 9.3502 19.2119 9.23948 19.3126C9.12877 19.4133 8.9952 19.4915 8.83913 19.5469C8.68305 19.6022 8.5102 19.6297 8.32163 19.6297H7.88698L7.88734 20.9286ZM7.88734 17.7744V19.2001H8.42448C8.49591 19.2001 8.56663 19.1879 8.63698 19.1633C8.70698 19.139 8.77127 19.099 8.82984 19.0436C8.88841 18.9883 8.93555 18.9111 8.97127 18.8119C9.00698 18.7126 9.02484 18.5897 9.02484 18.4433C9.02484 18.3847 9.01663 18.3169 9.00056 18.2408C8.98413 18.1644 8.95091 18.0911 8.90055 18.0211C8.84984 17.9511 8.77913 17.8926 8.68806 17.8454C8.59698 17.7983 8.47627 17.7747 8.32663 17.7747L7.88734 17.7744Z"
            fill="white"
          />
          <Path
            d="M12.8628 17.3301V20.9286H12.267L10.856 18.4483V20.9286H10.2603V17.3301H10.856L12.267 19.8104V17.3301H12.8628Z"
            fill="white"
          />
          <Path
            d="M16.3782 19.0733V20.4647C16.3032 20.5593 16.2197 20.6361 16.1268 20.6965C16.0339 20.7568 15.9364 20.8065 15.8339 20.8454C15.7314 20.8843 15.6254 20.9122 15.5164 20.9286C15.4072 20.9451 15.2993 20.9529 15.1918 20.9529C14.9768 20.9529 14.7793 20.914 14.5986 20.8358C14.4179 20.7576 14.26 20.6422 14.125 20.489C13.99 20.3358 13.8839 20.144 13.8075 19.9129C13.7311 19.6818 13.6929 19.4147 13.6929 19.1122C13.6929 18.8097 13.7311 18.5433 13.8075 18.314C13.8839 18.0843 13.9897 17.8933 14.125 17.7404C14.26 17.5872 14.4186 17.4711 14.6011 17.3911C14.7832 17.3111 14.9804 17.2715 15.1918 17.2715C15.3872 17.2715 15.5693 17.304 15.7386 17.369C15.9079 17.4343 16.0589 17.5318 16.1925 17.6618L15.7872 18.0233C15.7089 17.9286 15.6193 17.8604 15.5186 17.8183C15.4175 17.7761 15.3136 17.7547 15.2061 17.7547C15.0857 17.7547 14.9707 17.7772 14.8618 17.8229C14.7525 17.8686 14.655 17.9458 14.5689 18.0547C14.4825 18.164 14.415 18.3043 14.3664 18.4772C14.3179 18.6501 14.2914 18.8611 14.2882 19.1118C14.2914 19.3561 14.3168 19.5676 14.3639 19.7465C14.4111 19.9254 14.4761 20.0718 14.5593 20.1858C14.6425 20.2997 14.7368 20.3843 14.8425 20.4397C14.9482 20.4951 15.0597 20.5226 15.1772 20.5226C15.2129 20.5226 15.2607 20.5201 15.3211 20.5151C15.3811 20.5101 15.4414 20.5022 15.5018 20.4908C15.5618 20.4793 15.6197 20.464 15.675 20.4443C15.7304 20.4247 15.7711 20.3972 15.7972 20.3615V19.4729H15.1868V19.0726H16.3782V19.0733Z"
            fill="white"
          />
          <Path
            d="M8.43989 8.78603C9.3411 8.78603 10.0717 8.05546 10.0717 7.15425C10.0717 6.25304 9.3411 5.52246 8.43989 5.52246C7.53868 5.52246 6.80811 6.25304 6.80811 7.15425C6.80811 8.05546 7.53868 8.78603 8.43989 8.78603Z"
            fill="#25262F"
          />
          <Path
            d="M4 15.9288H7.92857H19.3571V12.0003L15.7857 8.60742L12.0357 12.7146L10.0775 10.7564L4 15.9288Z"
            fill="#25262F"
          />
        </Svg>
      ),
    },
  };
  const viewBoxWidth = graphics[name].width;
  const viewBoxHeight = graphics[name].height;
  const viewBoxRatio = viewBoxWidth / viewBoxHeight;

  return (
    <Svg
      width={width || (height && _.parseInt(height * viewBoxRatio)) || 100}
      height={height || (width && _.parseInt(width / viewBoxRatio)) || 100}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      {...otherProps}>
      {graphics[name].content}
    </Svg>
  );
};

export default SVG;
