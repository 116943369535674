import {children, json, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class RemitClaim extends Base {
  static table = 'remit_claim';
  static associations = {
    claims: {type: 'belongs_to', foreignKey: 'claim_id'},
    remits: {type: 'belongs_to', foreignKey: 'remit_id'},
    transactions: {type: 'has_many', foreignKey: 'remit_claim_id'},
  };

  @children('transactions') transactions;

  @text('claim_id') claimId;
  @text('remit_id') remitId;
  @text('inovalon_id') inovalonId;
  @json('response', value => value) response;
}
