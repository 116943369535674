import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormPercentageSlider from 'src/hook-form-wrapper/percentage-slider';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  required?: boolean;
  type?: string;
}

export const Import = (
  key: string = 'masteryValue',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Percentage',
  key,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.P3]}>{Value(value)}%</Text>
    </DataItem>
  );
};

export const Input = ({name, type}: Props) => {
  return (
    <HookFormPercentageSlider
      name={(name ? name + '.' : '') + type}
      label={'Correct Percentage'}
      placeholder={'Correct Percentage'}
      helper={''}
    />
  );
};

export const validation = () => Yup.string().required('Percentage is required');
