import _ from 'lodash';
import moment from 'moment/moment';
import {Q} from '@nozbe/watermelondb';

export const exportTrialByTrial = async (
  database: any,
  sets: any[],
  targetId: string,
  trialCount: number,
) => {
  const target = await database.get('targets').find(targetId);
  let _exportData: (any[] | undefined)[] = [];
  const groupSetsByTargetId = _.groupBy(sets, 'target.id');
  let setsByTargetId =
    groupSetsByTargetId[target.id]?.sort(
      (a: any, b: any) =>
        new Date(a.startTimestamp) - new Date(b.startTimestamp),
    ) || [];
  _exportData.push([
    target?.name,
    'Date',
    'Time Stamp',
    'Set',
    ..._.range(0, trialCount || 10).map(
      (t: any, index: number) => `${index + 1}`,
    ),
    'Score',
  ]);
  for (const set of setsByTargetId) {
    const index: number = sets.indexOf(set);
    let column = [
      '',
      moment(set?.startTimestamp).format('MM/DD/YY'),
      moment(set?.startTimestamp).format('HH:mm:ss') +
        ' - ' +
        (set?.endTimestamp
          ? moment(set?.endTimestamp).format('HH:mm:ss')
          : 'Incomplete'),
      `Set ${index + 1}`,
    ];
    const events = await database
      .get('events')
      .query(Q.where('set_id', set?.id))
      .fetch();
    for (const i of _.range(0, trialCount || 10)) {
      const event = events?.[i];
      let icon;
      if (event?.value === 'success') {
        icon = 'O';
      } else if (event?.value === 'error') {
        icon = 'X';
        for (const promptId of event?.prompts) {
          const prompt = await database.get('prompts').find(promptId);
          icon += '\n' + prompt?.acronym;
        }
      } else {
        icon = '-';
      }
      column.push(icon);
    }
    column.push(
      Math.ceil(
        (events.filter((event: any) => event.value === 'success').length /
          events.length) *
          100,
      ) + '%',
    );
    _exportData.push(column);
  }
  return _exportData;
};
