import {date, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class ServiceLine extends Base {
  static table = 'service_lines';

  static associations = {
    sessions: {type: 'belongs_to', foreignKey: 'session_id'},
    claims: {type: 'belongs_to', key: 'claim_id'},
    users: {type: 'belongs_to', key: 'user_id'},
  };

  @relation('claims', 'claim_id') claim;
  @relation('sessions', 'session_id') session;
  @relation('users', 'user_id') user;

  @text('claim_id') claimId;
  @text('session_id') sessionId;
  @text('user_id') userId;
  @date('start_date') startDate;
  @date('end_date') endDate;
  @text('cpt') cpt;
  @text('description') description;
  @text('modifier_1') modifier1;
  @text('modifier_2') modifier2;
  @text('modifier_3') modifier3;
  @text('modifier_4') modifier4;
  @text('units') units;
  @text('amount') amount;
  @text('diagnosis_pointer') diagnosisPointer;
}
