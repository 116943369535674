import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import withState from 'src/redux/wrapper';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  prompts?: any;
}
export const Import = (
  prompts: any[],
  key: string = 'prompts',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Program Prompts',
  key,
  type: 'select',
  selectOptions: prompts,
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Program Prompts</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, prompts}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'prompts'}
      label={'Program Prompts'}
      required={false}
      items={prompts}
      multiple={true}
    />
  );
};

export const validation = () =>
  Yup.string().required('Program Prompts are required');

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    promptItems: database
      ?.get('prompts')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('rank', Q.asc),
        Q.sortBy('name', Q.asc),
      ),
  })),
  withObservables([], ({promptItems}: any) => ({
    prompts: of(
      promptItems?.map((prompt: any) => ({
        label: prompt?.name,
        value: prompt?.id,
      })),
    ),
  })),
)(Input);
