import React from 'react';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {View} from 'react-native';
import {useForm, FormProvider} from 'react-hook-form';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
interface Props {
  onAssign: any;
  location: any;
}

const LocationForm = ({onAssign, location}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  const methods = useForm({
    defaultValues: {
      name: location?.name || '',
      address: location?.address,
    },
  });
  return (
    <FormProvider {...methods}>
      <HookFormInput name={'name'} label={'Name'} />
      <AddressAutocompleteInput
        name={'address'}
        label={'Address'}
        fullAddressPreview={false}
      />
      <View style={[styles.row, styles.alignCenter, styles.justifyEnd]}>
        <RHButton
          secondary
          mode="contained"
          onPress={methods.handleSubmit(onAssign)}>
          {translations('save_button')}
        </RHButton>
      </View>
    </FormProvider>
  );
};

export default LocationForm;
