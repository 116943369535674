import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Payer, PayerCredential} from 'src/models';
import ListItemSeparator from 'src/common-components/separator';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {Separator} from 'src/common-components/atoms';

export const CredentialPayerItemDisplay = compose(
  withDatabase,
  withObservables(['value'], ({value, database}: any) => ({
    payer: value?.id
      ? database.get(Payer.table).findAndObserve(value.id)
      : of({}),
  })),
)(({value, payer, showTaxonomy = false}: any) => {
  const styles = useStyle();

  return (
    <View
      style={[
        styles.row,
        styles.borderBottom,
        styles.alignCenter,
        styles.justifyCenter,
        styles.flex,
      ]}>
      <View
        style={[
          styles.paddingL,
          styles.borderRight,
          styles.flex,
          styles.alignCenter,
          styles.justifyCenter,
        ]}>
        <View style={[styles.width]}>
          <Text style={[Typography.P3, styles.textColorPrimary]}>
            {payer?.name || ''}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.paddingL,
          styles.borderRight,
          styles.flex,
          styles.alignCenter,
          styles.justifyCenter,
          styles.container,
        ]}>
        <View style={[styles.width]}>
          <Text style={[Typography.P3, styles.textColorPrimary]}>
            {value.legacyId}
          </Text>
        </View>
        {showTaxonomy ? (
          <View style={[styles.width]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>
              {value.taxonomy}
            </Text>
          </View>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
});

const CredentialPayerItem = ({
  payer,
  payerCredential,
  index,
  showTaxonomy = false,
  name = '',
}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.alignCenter]}>
      <View
        style={[
          styles.row,
          styles.width,
          styles.justifySpaceBetween,
          styles.alignCenter,
          styles.flex,
          styles.paddingL,
        ]}>
        <View style={[styles.flex]}>
          <Text style={[Typography.P2_MEDIUM, styles.textColorPrimary]}>
            {payer?.name || ''}
          </Text>
        </View>
        <View style={[styles.flex, styles.container]}>
          <View style={[styles.flex]}>
            <HookFormInput
              name={(name ? name + '.' : '') + `payers.${index}.legacyId`}
              label={'ID #'}
              helper=""
              showHelper={false}
              defaultValue={payerCredential?.legacyId}
            />
          </View>
          {showTaxonomy ? (
            <>
              <Separator width={10} />
              <View style={[styles.flex]}>
                <HookFormInput
                  name={(name ? name + '.' : '') + `payers.${index}.taxonomy`}
                  label={'Taxonomy'}
                  helper=""
                  showHelper={false}
                  defaultValue={payerCredential?.taxonomy}
                />
              </View>
            </>
          ) : (
            <></>
          )}
        </View>
      </View>
      <ListItemSeparator />
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables(
    ['credentialId'],
    ({credentialId, payerId, database}: any) => ({
      payer: database.get(Payer.table).findAndObserve(payerId),
      payerCredentials: credentialId
        ? database
            .get(PayerCredential.table)
            .query(
              Q.where('payer_id', payerId),
              Q.where('credential_id', credentialId),
              Q.where('deleted_at', Q.eq(null)),
            )
        : of([]),
    }),
  ),
  withObservables(['payerCredentials'], ({payerCredentials}: any) => ({
    payerCredential:
      payerCredentials.length > 0 ? payerCredentials[0] : of({legacyId: ''}),
  })),
)(CredentialPayerItem);
