import React from 'react';
import _ from 'lodash';

import {SelectInput} from 'src/design-system';

const RepeatIntervalInput = () => {
  return (
    <SelectInput
      valueKey={'repeatInterval'}
      name={'Repeats every'}
      required={true}
      items={_.range(1, 100).map(idx => {
        return {label: idx.toString(), value: idx.toString()};
      })}
    />
  );
};

export const Input = RepeatIntervalInput;

export default RepeatIntervalInput;
