import React, {useEffect, useState} from 'react';
import {View, Text, FlatList} from 'react-native';
import {TextInput} from 'react-native-paper';
import {Separator} from 'src/common-components/atoms';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {useNavigation} from '@react-navigation/core';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import {Modal, Tooltip} from 'src/design-system';
import {Checkbox, HelperText} from 'react-native-paper';
import SessionSummaryDataTable from 'src/modules/session/components/summary-data-table';
import {replace} from 'src/navigation/utils/replace';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {mergeMap, of} from 'rxjs';
import {Q} from '@nozbe/watermelondb';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import OrganizationDisplay from 'src/modules/organization/components/organization-display';
import ClientInfoDisplay from 'src/modules/organization/components/client-info-display';
import {useStyle} from 'src/providers/style';
import NoteTemplateVersionInput from 'src/hook-form-inputs/note-template-version';
import SessionInfoDisplay from 'src/modules/organization/components/session-info-display';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {
  Instance,
  InstanceDiagnosis,
  NoteTemplateVersion,
  Session,
  Signature,
  Note,
} from 'src/models';
import {useTranslations} from 'src/providers/translation';
import {useDimensions} from '@react-native-community/hooks';
import RenderFormInputs from 'src/modules/form-builder/components/render-form-inputs';
import SignatureInput from 'src/modules/session/components/signature-item';
import {useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import SessionNoteListItem from '../session-note-list-item';
import {sessionNoteReplacement} from 'src/common-utils/session-note-replacement';
import {map} from '@nozbe/watermelondb/utils/rx';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import Icon from 'react-native-vector-icons/MaterialIcons';

const defaultSignature = {
  entity: '',
  entity_id: '',
  signature: '',
  type: '',
  signatory: '',
};

const SessionMessage = ({
  session,
  supervisedSession,
  appointment,
  patient,
  staff,
  diagnoses,
  organization,
  noteTemplate,
  careTeam,
  signatures,
  notes,
  cptCodes,
  role,
  showSubmitButton = true,
  fromRecap = false,
}: any) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const database = useDatabase();
  const dimensions = useDimensions();
  const translations = useTranslations();
  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [submitting, setSubmitting] = useState(false);
  const [filter, setFilter] = useState<any>({
    types: [
      noteTemplate?.skill ? 'skill' : '',
      noteTemplate?.behavior ? 'behavior' : '',
    ],
    programs: [],
  });

  const requiredFieldText = translations('field_is_required');
  const ruleMap = {
    RADIO: Yup.string().required(requiredFieldText),
    INPUT: Yup.string().required(requiredFieldText),
    DATE: Yup.date().required(requiredFieldText),
  };

  const validateAdditionalFields = () => {
    const result = {};
    for (const key in noteTemplate?.formBuilderValues) {
      noteTemplate?.formBuilderValues[key].items.forEach(async item => {
        if (item.required) {
          if (ruleMap[item.componentType]) {
            result[item.id] = ruleMap[item.componentType];
          } else {
            result[item.id] = Yup.mixed().test(
              item.id,
              requiredFieldText,
              value => {
                if (Array.isArray(value)) {
                  return value.length;
                }
              },
            );
          }
        }
      });
    }
    return Yup.object(result);
  };

  const validationSchema = Yup.object({
    signatures: Yup.array().test(
      'signatures',
      'At least one staff member signature is required.',
      values => {
        return values?.some(
          (value: any) =>
            value.type === 'staff' && value.signature && value.signatory,
        );
      },
    ),
    additionalFields: validateAdditionalFields(),
  });

  const methods = useForm({
    defaultValues: {
      noteTemplateVersionId: session?.noteTemplateVersionId,
      attestationTimestamp: session?.attestationTimestamp ?? null,
      editedStartTimestamp: session?.editedStartTimestamp
        ? session?.editedStartTimestamp
        : session?.startTimestamp,
      editedEndTimestamp: session?.editedEndTimestamp
        ? session?.editedEndTimestamp
        : session?.endTimestamp,
      note: session.note,
      newNote: '',
      signatures: signatures.length
        ? signatures.map(signature => {
            return {
              _id: signature?.id,
              entity: signature.entity,
              entity_id: signature.entity_id,
              signature: signature.signature,
              type: signature.type,
              signatory: signature.signatory,
              updatedAt: signature.updatedAt,
            };
          })
        : [
            {
              entity: '',
              entity_id: '',
              signature: '',
              type: 'staff',
              signatory: '',
              updatedAt: new Date(),
            },
          ],
      staff: session?.userId ?? '',
      parentSignature1: session?.parentSignature1 ?? '',
      parentSignature2: session?.parentSignature2 ?? '',
      therapistSignature: session?.therapistSignature ?? '',
      therapistSignature2: session?.therapistSignature2 ?? '',
      additionalFields: session?.additionalFields ?? {},
      startTimestamp: session?.startTimestamp,
      endTimestamp: session?.endTimestamp,
      location: session?.location,
      cpt: session?.cpt || [],
      showAuthorsAndTimestamps: noteTemplate?.showAuthorsAndTimestamps,
      address: session?.address ?? '',
      customAddress: session?.customAddress ?? '',
    },
    resolver: yupResolver(validationSchema),
  });

  methods.formState.isDirty;

  const watchedNewNote = useWatch({
    control: methods.control,
    name: 'newNote',
  });

  const watchedLocation = useWatch({
    control: methods.control,
    name: 'location',
  });

  const watchedAddress = useWatch({
    control: methods.control,
    name: 'address',
  });

  const showAuthorsAndTimestamps = useWatch({
    control: methods.control,
    name: 'showAuthorsAndTimestamps',
  });
  const addNote = async () => {
    if (watchedNewNote) {
      await database.write(async () => {
        await database.get(Note.table).create(entity => {
          entity.partition = selectedGroup;
          entity.description = watchedNewNote;
          entity.entity = 'session';
          entity.entityId = session.id;
          entity.createdBy = userId;
        });
      });
      methods.setValue('newNote', '');
    }
  };

  useEffect(() => {
    const subscription = methods.watch((value, {name}) => {
      if (
        name !== 'noteTemplateVersionId' &&
        name.split('.')[0] !== 'signatures'
      ) {
        session.updateEntity({[name.split('.')[0]]: value[name.split('.')[0]]});
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch]);

  const updateNote = async () => {
    await session.updateEntity({
      submissionTimestamp: new Date(),
      note: watchedTemplateText,
      showAuthorsAndTimestamps,
    });
    addNote();
    setSubmitting(false);
    navigation.dispatch(
      replace('SessionRecap', {
        id: session.id,
      }),
    );
  };

  const submitForm = () => {
    setSubmitting(true);
  };

  const noteTemplateVersionId = methods.watch('noteTemplateVersionId');
  const watchedTemplateText = methods.watch('note');
  const updateNoteTemplate = async () => {
    if (noteTemplateVersionId !== session.noteTemplateVersionId) {
      const fetchedTemplate = await database
        .get(NoteTemplateVersion.table)
        .find(noteTemplateVersionId);
      await session.updateEntity({
        noteTemplateId: fetchedTemplate.noteTemplateId,
        noteTemplateVersionId: noteTemplateVersionId,
      });
    }
  };

  const updateNoteVersion = async () => {
    const fetchedTemplate = await database
      .get(NoteTemplateVersion.table)
      .find(noteTemplateVersionId);
    const newNote = await sessionNoteReplacement(
      database,
      fetchedTemplate.template,
      session.id,
      appointment.id,
      appointment?.type,
      appointment?.location,
      organization?.actualTime ? session.startTimestamp : appointment?.start,
      organization?.actualTime ? new Date() : appointment?.end,
    );

    await session.updateEntity({
      note: newNote,
      noteTemplateId: fetchedTemplate.noteTemplateId,
      noteTemplateVersionId: noteTemplateVersionId,
    });
    methods.setValue('note', newNote);
  };

  useEffect(() => {
    if (!fromRecap) {
      updateNoteTemplate();
      if (noteTemplateVersionId !== session.noteTemplateVersionId) {
        updateNoteVersion();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteTemplateVersionId]);

  const compareNoteTemplates = async () => {
    const latestNoteTemplateVersions = await database
      .get(NoteTemplateVersion.table)
      .query(
        Q.where('note_template_id', session.noteTemplateId),
        Q.sortBy('published_at', 'desc'),
        Q.take(1),
      );
    const latestNoteTemplateVersion = latestNoteTemplateVersions?.[0];
    const sessionNoteTemplateVersion = await database
      .get(NoteTemplateVersion.table)
      .find(session.noteTemplateVersionId);
    if (
      sessionNoteTemplateVersion &&
      latestNoteTemplateVersion &&
      sessionNoteTemplateVersion?.id !== latestNoteTemplateVersion?.id
    ) {
      methods.setValue('noteTemplateVersionId', latestNoteTemplateVersion?.id);
      await session.updateEntity({
        note: session.note.replace(
          sessionNoteTemplateVersion.template,
          latestNoteTemplateVersion.template,
        ),
        noteTemplateVersionId: latestNoteTemplateVersion?.id,
      });
    }
  };

  useEffect(() => {
    if (!fromRecap) {
      compareNoteTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilter({
      types: [
        noteTemplate?.skill ? 'skill' : '',
        noteTemplate?.behavior ? 'behavior' : '',
      ],
      programs: [],
    });
  }, [noteTemplate]);

  const attestationTimestamp = useWatch({
    control: methods.control,
    name: 'attestationTimestamp',
  });
  const {remove: signaturesRemove, replace: signaturesReplace} = useFieldArray({
    name: 'signatures',
    control: methods.control,
  });
  const signaturesWatch = useWatch({
    name: 'signatures',
    control: methods.control,
  });

  const isSmallSize = dimensions.window.width < 768;
  return (
    <>
      <Modal
        show={[submitting, setSubmitting]}
        title={'Submit Session Note?'}
        footer={
          <>
            <RHButton
              secondary
              mode
              onPress={() => setSubmitting(false)}
              textColor={Colors.RAVEN_BLACK}>
              {translations('cancel_button')}
            </RHButton>
            <Separator width={16} />
            <RHButton
              mode="contained"
              secondary
              onPress={updateNote}
              disabled={!attestationTimestamp}>
              {translations('yes_submit_note')}
            </RHButton>
          </>
        }>
        <View
          style={[
            styles.column,
            styles.justifyCenter,
            isSmallSize ? styles.paddingBottom : {},
          ]}>
          <Controller
            control={methods.control}
            rules={{
              required: translations('user_attestation'),
            }}
            render={({field: {onChange, value}}) => (
              <View
                style={[styles.row, styles.alignCenter, styles.justifyCenter]}>
                <Checkbox.Android
                  status={value ? 'checked' : 'unchecked'}
                  onPress={() => {
                    onChange(new Date());

                    session.updateEntity({
                      attestationTimestamp: new Date(),
                    });
                  }}
                  color={Colors.BLUE_700}
                  uncheckedColor={
                    methods.formState.errors.attestationTimestamp
                      ? Colors.ERROR
                      : Colors.PRIMARY_200
                  }
                />
                <Text
                  style={[
                    Typography.P3_BOLD,
                    methods.formState.errors.attestationTimestamp
                      ? styles.textColorError
                      : styles.textColorPrimary,
                  ]}>
                  {translations('attestation')}
                </Text>
              </View>
            )}
            name="attestationTimestamp"
            defaultValue=""
          />
        </View>
      </Modal>
      <FormProvider {...methods}>
        <View
          style={[
            styles.width,
            styles.container,
            styles.justifySpaceBetween,
            styles.alignCenter,
          ]}>
          <Text style={[Typography.H4, styles.textColorPrimary]}>
            {translations('summary')}
          </Text>
          {!fromRecap ? (
            <View
              style={[
                styles.container,
                styles.justifyCenter,
                styles.paddingTop,
                styles.alignCenter,
              ]}>
              <View style={[styles.paddingSMRight]}>
                <Tooltip
                  anchor={
                    <Icon name={'warning'} size={32} color={Colors.ERROR} />
                  }>
                  <Text style={[Typography.P3]}>
                    {translations('template_change_warning')}
                  </Text>
                </Tooltip>
              </View>
              <View
                style={[
                  styles.menuMinWidth,
                  isSmallSize ? styles.height76 : {},
                ]}>
                <NoteTemplateVersionInput />
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
        {noteTemplate?.organizationInformation ? (
          <OrganizationDisplay
            currentNoteTemplate={noteTemplate}
            organization={organization}
          />
        ) : null}
        {noteTemplate?.clientInformation ? (
          <ClientInfoDisplay
            currentNoteTemplate={noteTemplate}
            patient={patient}
            staff={staff}
            diagnoses={diagnoses}
            careTeam={careTeam}
          />
        ) : null}
        {noteTemplate?.sessionInformation ? (
          <SessionInfoDisplay
            cptCodes={cptCodes}
            currentNoteTemplate={noteTemplate}
            appointment={appointment}
            session={session}
            staff={staff}
            edit={role?.appointmentEdit}
            patient={patient}
            watchedLocation={watchedLocation}
            watchedAddress={watchedAddress}
          />
        ) : null}
        {noteTemplate?.clinicalNote ? (
          <>
            <Text style={[Typography.H5]}>{translations('note')}</Text>
            <View
              style={[
                styles.marginLVertical,
                styles.padding,
                styles.borderRadius,
                styles.backgroundColorWhite,
              ]}>
              <HookFormSwitchInput
                name={'showAuthorsAndTimestamps'}
                label={translations('show_authors_and_timestamps')}
                showHelper={false}
              />
              <View style={[styles.paddingTop]}>
                <HookFormInput
                  numberOfLines={3}
                  label={'Automated Template'}
                  name={'note'}
                />
              </View>
              <FlatList
                keyExtractor={item => item.id}
                data={notes}
                renderItem={({item}) => (
                  <View style={[styles.paddingSMVertical]}>
                    <SessionNoteListItem
                      item={item}
                      showAuthorsAndTimestamps={showAuthorsAndTimestamps}
                    />
                  </View>
                )}
              />
              <View style={[styles.paddingTop]}>
                <HookFormInput
                  name={'newNote'}
                  label={'Add Note'}
                  numberOfLines={2}
                  onSubmit={() => addNote()}
                  right={
                    <TextInput.Icon icon="send" onPress={() => addNote()} />
                  }
                />
              </View>
            </View>
          </>
        ) : null}

        {noteTemplate?.sessionData ? (
          <>
            <Text style={[Typography.H5]}>{translations('data')}</Text>
            <View style={[styles.marginLVertical]}>
              <SessionSummaryDataTable
                session={
                  session.type === 'supervision' ? supervisedSession : session
                }
                filters={filter}
              />
            </View>
          </>
        ) : null}

        {noteTemplate?.additionalFields && noteTemplate?.formBuilderValues ? (
          <View style={[styles.paddingVertical]}>
            <Text style={[Typography.H5]}>
              {translations('additional_fields')}
            </Text>
            <View
              style={[
                styles.marginLVertical,
                styles.cardFlat,
                styles.padding,
                styles.borderRadius,
              ]}>
              <RenderFormInputs
                values={noteTemplate?.formBuilderValues}
                name={'additionalFields'}
              />
            </View>
          </View>
        ) : (
          <></>
        )}

        <Text style={[Typography.H5]}>{translations('sigs')}</Text>
        <View
          style={[
            styles.marginLVertical,
            styles.padding,
            styles.borderRadius,
            styles.backgroundColorWhite,
          ]}>
          <View style={[styles.column]}>
            <View style={[styles.flex, styles.marginRightAuto]}>
              {signaturesWatch.map((signature: any, index: number) => {
                return (
                  <View key={`signature-watch-${index}`} style={[styles.row]}>
                    <SignatureInput
                      index={index}
                      signature={signature}
                      patient={patient}
                      update={async (values: any) => {
                        const signatureFields = [...signaturesWatch];
                        if (values._id) {
                          const entity = await database
                            .get(Signature.table)
                            .find(values._id);

                          await entity.updateEntity({
                            entity: 'session',
                            entityId: session.id,
                            signature: values.signature,
                            type: values.type,
                            signatory: values.signatory,
                            deletedAt: values?.deletedAt ?? null,
                          });
                          signatureFields[index] = {
                            ...values,
                            updatedAt: new Date(),
                          };
                        } else {
                          const createdSignature = await database?.write(
                            async () => {
                              return database
                                ?.get(Signature.table)
                                .create(entity => {
                                  entity.partition = selectedGroup;
                                  entity.entity = 'session';
                                  entity.entityId = session.id;
                                  entity.signature = values.signature;
                                  entity.type = values.type;
                                  entity.signatory = values.signatory;

                                  entity.createdBy = userId;
                                  entity.updatedBy = userId;
                                });
                            },
                          );
                          signatureFields[index] = {
                            _id: createdSignature.id,
                            ...values,
                            updatedAt: new Date(),
                          };
                        }
                        signaturesReplace(signatureFields);
                      }}
                      remove={
                        index !== 0
                          ? async () => {
                              if (signature?._id) {
                                const entity = await database
                                  .get(Signature.table)
                                  .find(signature._id);

                                entity.delete();
                                const signatureFields = [...signaturesWatch];
                                signatureFields[index] = {
                                  ...signature,
                                  deletedAt: new Date(),
                                };
                                signaturesReplace(signatureFields);
                              } else {
                                signaturesRemove(index);
                              }
                            }
                          : undefined
                      }
                    />
                  </View>
                );
              })}
            </View>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={[styles.alignSelfStart, styles.paddingLVertical]}>
                <RHButton
                  icon="plus-circle-outline"
                  mode="outlined"
                  color={Colors.RAVEN_WHITE}
                  textColor={Colors.RAVEN_BLACK}
                  onPress={() => {
                    const signatureFields = [
                      ...signaturesWatch,
                      {...defaultSignature, show: true},
                    ];
                    signaturesReplace(signatureFields);
                  }}>
                  {translations('add_new')}
                </RHButton>
              </View>
            </View>
            {methods.formState.errors?.signatures ? (
              <HelperText type="error" visible={true} style={[styles.helper]}>
                {'ⓧ ' + methods.formState.errors?.signatures?.message}
              </HelperText>
            ) : null}
          </View>
        </View>
        {showSubmitButton ? (
          <View
            style={[
              styles.flex,
              styles.padding,
              styles.affix,
              styles.container,
              styles.justifyEnd,
              styles.alignContentCenter,
            ]}>
            <View style={[styles.column, styles.justifyCenter]}>
              <RHButton
                secondary
                onPress={methods.handleSubmit(submitForm)}
                mode="contained">
                {translations('submit_session_note')}
              </RHButton>
            </View>
          </View>
        ) : (
          <></>
        )}
      </FormProvider>
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['session'], ({database, session}: any) => ({
    appointment: session.appointment,
    patient: session.patient,
    noteTemplate: session.noteTemplateVersion,
    signatures: session.sessionSignatures,
    notes: session.activeNotes,
    supervisedSessions:
      session.type === 'supervision'
        ? database
            .get(Session.table)
            .query(
              Q.where('type', 'session'),
              Q.where('appointment_id', session.appointmentId),
              Q.where('session_date', session.sessionDate),
              Q.where('deleted_at', null),
            )
        : of([]),
  })),
  withObservables([], ({appointment, supervisedSessions}: any) => ({
    staff: appointment.allStaff,
    supervisedSession: supervisedSessions.length
      ? supervisedSessions[0]
      : of({}),
  })),
  withObservables(['appointment', 'session'], ({appointment, session}) => {
    if (!appointment) {
      return {cptCodes: of([])};
    }
    return {
      cptCodes: session?.cpt.length
        ? of(session.cpt)
        : appointment.staffParticipants.observe().pipe(
            map(participants => {
              return participants.reduce((allCodes, participant) => {
                return [...allCodes, ...(participant.cpt || [])];
              }, []);
            }),
          ),
    };
  }),
  withObservables([], ({patient}: any) => {
    if (patient.id) {
      return {
        patientDiagnoses: patient.activeDiagnoses,
      };
    }
    return {
      patientDiagnoses: of([]),
    };
  }),
  withObservables([], ({database, patientDiagnoses}: any) => {
    return {
      diagnoses: database
        .get(InstanceDiagnosis.table)
        .query(
          Q.where(
            'id',
            Q.oneOf(
              patientDiagnoses.map(diagnosis => diagnosis.instanceDiagnosisId),
            ),
          ),
        ),
    };
  }),
  withObservables(['authentication'], ({authentication, database}: any) => ({
    organization: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
    profile: authentication?.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({patient, profile}: any) => ({
    careTeam: patient.careTeam,
    role: profile.role,
  })),
)(SessionMessage);
