import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {buildInitials} from 'src/common-utils/name-builders';
import {Colors, Typography} from 'src/styles';
import {useNavigation} from '@react-navigation/core';
import {getAge} from 'src/common-utils/age';
import {useStyle} from 'src/providers/style';

interface Props {
  patient: any;
}

const PatientComponent = ({patient: currentPatient}: Props) => {
  const navigation = useNavigation();
  const styles = useStyle();

  const firstName = currentPatient.firstName || '';
  const lastName = currentPatient.lastName || '';
  const gender = currentPatient?.gender || 'male';
  const age = getAge(currentPatient.birthDate);
  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate('PatientProfile', {
          patientId: currentPatient.id,
        })
      }
      style={[
        styles.row,
        styles.alignCenter,
        styles.justifySpaceBetween,
        styles.paddingVertical,
      ]}>
      <View style={[styles.row, styles.alignCenter]}>
        <RHAvatar
          label={buildInitials(firstName, lastName)}
          color={Colors.RAVEN_BLACK}
          textColor={Colors.RAVEN_WHITE}
          size={28}
        />
        <View style={[styles.marginLLeft]}>
          <Text style={[Typography.SECONDARY_BUTTON]}>
            {firstName} {lastName}
          </Text>
          <Text style={[Typography.P3]}>
            {`${gender[0].toUpperCase()}${gender.slice(1)}`} | Age: {age}
          </Text>
        </View>
      </View>
      <Icon name="chevron-right" size={15} />
    </TouchableOpacity>
  );
};

export default PatientComponent;
