import React from 'react';
import {Display as ClientFormDisplay} from '../client-form';
import {Display as CaregiverFormDisplay} from '../caregiver-form';
import {Display as MedicalFormDisplay} from '../medical-form';
import {Display as ClinicalFormDisplay} from '../clinical-form';
import {Display as InsuranceFormDisplay} from 'src/modules/billing/components/insurance-form';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';

const ReviewPage = ({values, ...extras}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.column, styles.paddingHorizontal]}>
      <View style={[styles.container]}>
        <ClientFormDisplay {...values} />
        <CaregiverFormDisplay {...values} />
      </View>
      <View style={[styles.separator, styles.marginVertical]} />
      <MedicalFormDisplay {...values} />
      <View style={[styles.separator, styles.marginVertical]} />
      <ClinicalFormDisplay staffId={values.staff} {...values} />
      <View style={[styles.separator, styles.marginVertical]} />
      {extras?.billing ? <InsuranceFormDisplay {...values} /> : <></>}
    </View>
  );
};

export default ReviewPage;
