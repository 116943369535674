import React from 'react';
import {DataItem} from 'src/design-system';
import {relationshipList} from 'src/common-utils/commonPatientData';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
  label?: string;
}

interface Props {
  name?: string;
  label?: string;
  disabled?: boolean;
  subscriberRelationShipList?: boolean;
}

const filteredRelationshipList = relationshipList.filter(item =>
  ['19', '18', '01', '20', '21', '39', '40', '53', 'G8'].includes(item.value),
);

export const Import = (
  key: string = 'relationship',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Relationship',
  key,
  type: 'select',
  selectOptions: relationshipList,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return relationshipList.find(item => item.value === value)?.label;
};

export const Display = ({value, label}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>
        {label ? label : translations('relationship')}
      </Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({
  name,
  disabled = false,
  label,
  subscriberRelationShipList = false,
}: Props) => {
  const translations = useTranslations();

  const items = subscriberRelationShipList
    ? filteredRelationshipList
    : relationshipList;

  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'relationship'}
      label={label ? label : translations('relationship')}
      required={false}
      items={items}
      disabled={disabled}
    />
  );
};

export const validation = () =>
  Yup.string().required('Relationship is required');

export default Input;
