import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {DataItem} from 'src/design-system';
import moment from 'moment';
import AppointmentLocation, {
  Display as AppointmentLocationDisplay,
} from 'src/hook-form-inputs/location';
import EditTime from 'src/modules/session/components/edit-time';
import {Display as AddressDisplay} from 'src/hook-form-inputs/address';
import NpiDisplay from 'src/modules/organization/components/npi-display';
import Cpt, {Display as CptDisplay} from 'src/hook-form-inputs/cpt';
import addressFormat from 'src/common-utils/address-format';
import AddressAutocomplete from 'src/hook-form/address-input';
import Address from 'src/hook-form-inputs/address';

const SessionInfoDisplay = ({
  currentNoteTemplate,
  session,
  appointment,
  staff,
  startDate = 'editedStartTimestamp',
  endDate = 'editedEndTimestamp',
  edit = false,
  startTimestamp = 'startTimestamp',
  endTimestamp = 'endTimestamp',
  cptCodes = appointment?.staffParticipant?.cpt,
  patient = undefined,
  watchedLocation = session?.location,
  watchedAddress = session?.address,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const calculateDuration = (startTime: string, endTime: string) => {
    const start = new Date(session[startTime]);
    const end = new Date(session[endTime]);
    const newEndTime = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      end.getHours(),
      end.getMinutes(),
      end.getSeconds(),
    );
    const durationInSeconds = moment(newEndTime).diff(
      session[startTime],
      'seconds',
    );
    const remainingSeconds = durationInSeconds % 60;
    const durationInMins = moment(newEndTime).diff(
      session[startTime],
      'minutes',
    );
    const diffInHours = Math.floor(durationInMins / 60);
    const remainingMinutes = durationInMins % 60;
    if (durationInSeconds < 60) {
      return `${durationInSeconds} second(s)`;
    } else if (durationInSeconds < 3600) {
      return `${Math.floor(
        durationInSeconds / 60,
      )} minute(s) and ${remainingSeconds} second(s)`;
    } else {
      return `${diffInHours} hour(s), ${remainingMinutes} minute(s), and ${remainingSeconds} second(s)`;
    }
  };

  const duration = calculateDuration(startDate, endDate);
  const durationInMins = moment(session[endDate]).diff(
    session[startDate],
    'minutes',
  );
  const units =
    cptCodes.length && cptCodes.length === 1 && cptCodes[0] === 'H2012'
      ? Math.floor(durationInMins / 60)
      : Math.round(durationInMins / 15);

  return (
    <>
      <Text style={[Typography.H5]}>{translations('session_information')}</Text>
      <View
        style={[
          styles.marginLVertical,
          styles.cardFlat,
          styles.padding,
          styles.borderRadius,
        ]}>
        <View style={[styles.width, styles.row]}>
          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            {currentNoteTemplate.sessionStaff ? (
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('session_staff')}
                </Text>
                <View
                  style={[styles.flex, styles.column, styles.marginRightAuto]}>
                  {staff?.map((participant: any) => {
                    return (
                      <View key={`participant-${participant?.id}`}>
                        <Text
                          style={[Typography.P3]}
                          key={`participant-${participant?.id}`}>
                          {participant.firstName}{' '}
                          {participant.middleName
                            ? `${participant.middleName} `
                            : null}
                          {participant.lastName}
                          {participant.credentials
                            ? ', ' + participant.credentials.join(', ')
                            : ''}
                        </Text>
                        {currentNoteTemplate.staffLicenseNumber ? (
                          <Text style={[Typography.P3]}>
                            License #:
                            {participant.licenseNumber
                              ? participant.licenseNumber
                              : ''}
                          </Text>
                        ) : (
                          <></>
                        )}
                        {currentNoteTemplate.staffNpi ? (
                          <NpiDisplay user={participant} />
                        ) : (
                          <></>
                        )}
                      </View>
                    );
                  })}
                </View>
              </DataItem>
            ) : null}
            {currentNoteTemplate.sessionDateOfService ? (
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('scheduled_DOS')}
                </Text>
                <Text style={[Typography.P3]}>
                  {moment(session?.sessionDate).format('MM/DD/YYYY')}
                </Text>
              </DataItem>
            ) : null}
            {currentNoteTemplate.sessionUnitsOfService ? (
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('units_of_service')}
                </Text>
                <Text style={[Typography.P3]}>{units}</Text>
              </DataItem>
            ) : (
              <></>
            )}
          </View>

          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            {currentNoteTemplate.sessionStartTime ? (
              <DataItem>
                <View style={styles.paddingLeft}>
                  <Text style={[Typography.H6]}>
                    {translations('start_time')}
                  </Text>
                  {edit ? (
                    <EditTime
                      label={translations('start_time')}
                      name={startDate}
                    />
                  ) : (
                    <Text style={[Typography.P3]}>
                      {currentNoteTemplate?.sessionHourFormat &&
                      session[startDate]
                        ? moment(session[startDate]).format('HH:mm:ss A')
                        : session[startDate]
                        ? moment(session[startDate]).format('hh:mm:ss A')
                        : 'Not Started'}
                    </Text>
                  )}
                </View>
              </DataItem>
            ) : null}

            {currentNoteTemplate.sessionEndTime ? (
              <DataItem>
                <View style={styles.paddingLeft}>
                  <Text style={[Typography.H6]}>
                    {translations('end_time')}
                  </Text>
                  {edit ? (
                    <EditTime label={translations('end_time')} name={endDate} />
                  ) : (
                    <Text style={[Typography.P3]}>
                      {currentNoteTemplate?.sessionHourFormat &&
                      session[endTimestamp]
                        ? moment(session[endDate]).format('HH:mm:ss A')
                        : session[endDate]
                        ? moment(session[endDate]).format('hh:mm:ss A')
                        : session[startTimestamp] && !session[endDate]
                        ? 'In-Progress'
                        : 'Not Started'}
                    </Text>
                  )}
                </View>
              </DataItem>
            ) : null}
            {currentNoteTemplate.sessionDuration ? (
              <DataItem>
                <View style={styles.paddingLeft}>
                  <Text style={[Typography.H6]}>
                    {translations('duration')}
                  </Text>
                  <Text style={[Typography.P3]}>{duration}</Text>
                </View>
              </DataItem>
            ) : null}
          </View>
          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            {currentNoteTemplate.sessionType ? (
              <View style={[styles.paddingLeft]}>
                {edit ? (
                  <>
                    <Text style={[Typography.H6]}>
                      {translations('billing_codes')}
                    </Text>
                    <Cpt multiple={true} />
                  </>
                ) : (
                  <CptDisplay value={cptCodes} />
                )}
              </View>
            ) : null}
            {currentNoteTemplate.sessionLocation ? (
              <View style={[styles.paddingLeft]}>
                {edit ? (
                  <>
                    <Text style={[Typography.H6]}>
                      {translations('place_of_service')}
                    </Text>
                    <AppointmentLocation />
                  </>
                ) : (
                  <AppointmentLocationDisplay value={session?.location} />
                )}
              </View>
            ) : null}

            {edit ? (
              <View style={[styles.paddingLeft]}>
                <Text style={[Typography.H6]}>{translations('location')}</Text>
                <Address
                  patientId={
                    watchedLocation === '12' || watchedLocation === '10'
                      ? patient?.id
                      : undefined
                  }
                  combineLocations={watchedLocation === '10' && patient?.id}
                />
                {watchedAddress === 'other' && (
                  <>
                    <Text style={[Typography.H6]}>
                      {translations('address')}
                    </Text>

                    <AddressAutocomplete
                      name={'customAddress'}
                      label={'Address Line 1'}
                    />
                  </>
                )}
              </View>
            ) : currentNoteTemplate.sessionAddress ? (
              <View style={[styles.paddingLeft]}>
                {session?.address === 'other' ? (
                  <View>
                    <DataItem>
                      <Text style={[Typography.H6]}>
                        {translations('address')}
                      </Text>
                      <Text style={[Typography.P3]}>
                        {addressFormat(session.customAddress)}
                      </Text>
                    </DataItem>
                  </View>
                ) : (
                  <AddressDisplay value={session?.address} />
                )}
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </>
  );
};

export default SessionInfoDisplay;
