import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Image} from 'react-native';
import {memoize} from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';
import {useDimensions} from '@react-native-community/hooks';
import AsyncStorage from '@react-native-async-storage/async-storage';

const FileImage = ({src}: any) => {
  const dimensions = useDimensions();

  const [image, setImage] = useState<undefined | any>(undefined);

  useEffect(() => {
    const toDataURL = async (url: string) => {
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${await AsyncStorage.getItem(
              'accessToken',
            )}`,
          },
        });
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onloadend = () => setImage(reader.result);
        reader.onerror = () => console.log('error');
        reader.readAsDataURL(blob);
      } catch (e) {
        console.log(e);
      }
    };

    toDataURL(src);
  }, [src]);

  const styles = getStyles(dimensions.window.width);

  return (
    <>
      {image !== undefined ? (
        <Image source={image} style={styles.blobPreview} />
      ) : (
        <ActivityIndicator />
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStyles = memoize((width: number) =>
  EStyleSheet.create({
    blobPreview: {
      width: '100%',
      height: '100%',
      resizeMode: 'cover',
      backgroundColor: 'transparent',
    },
  }),
);

export default FileImage;
