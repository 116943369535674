export const exportCsv = async (name: string, content: string[][]) => {
  let csv = '';

  content.forEach(function (row) {
    csv += row
      .map(String)
      .map(v => v.replaceAll('"', '""'))
      .map(v => `"${v}"`)
      .join(',');
    csv += '\n';
  });

  const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name + '.csv');
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};
