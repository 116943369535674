import React, {Dispatch, SetStateAction} from 'react';
import {Form, IconButton, Modal} from 'src/design-system';
import {StyleSheet} from 'react-native';

interface Props {
  show: [boolean, Dispatch<SetStateAction<boolean>>];
  handleSubmit?: (payload: any, formik: any) => void;
  cancelled?: (formik: any) => void;
  initialValues: any;
  validationSchema: any;
  children?: JSX.Element[] | JSX.Element;
}

const FilterModal = ({
  show,
  initialValues,
  validationSchema,
  handleSubmit,
  cancelled,
  children,
}: Props) => {
  return (
    <Modal
      show={show}
      anchor={<IconButton icon={'filter-variant'} type={'outlined'} />}
      title={'Filters'}>
      <Form
        handleSubmit={handleSubmit}
        cancelled={cancelled}
        initialValues={initialValues}
        validationSchema={validationSchema}
        cancelText={'Reset'}
        saveText={'Apply'}
        style={styles.paddingBottom}>
        {children}
      </Form>
    </Modal>
  );
};

const styles = StyleSheet.create({
  paddingBottom: {paddingBottom: 20},
});

export default FilterModal;
