import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import moment from 'moment';
import {IDeveloperField} from 'dromo-uploader-react';
import {default as HookDateInput} from 'src/hook-form-wrapper/date-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'date',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Date (MM/DD/YYYY)',
  key,
  validators: [
    {
      validate: 'regex_match',
      regex:
        '(0[1-9]|1[012])[- \\/.](0[1-9]|[12][0-9]|3[01])[- \\/.](19|20)\\d\\d$',
      errorMessage: 'Must be a valid date.',
    },
  ],
});

const resolveDisplayValue = (value: string) => {
  return moment(value).format('ll');
};

const DateDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Birth Date</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const DateInput = ({name}: Props) => {
  return (
    <HookDateInput
      name={(name ? name + '.' : '') + 'date'}
      label={'Date'}
      placeholder={'Date'}
      helper={''}
      required={false}
    />
  );
};

export const validation = () => Yup.string().required('Birth date is required');

export const Display = DateDisplay;
export const Input = DateInput;
export const Value = resolveDisplayValue;

export default DateInput;
