import React from 'react';
import {DataItem, DateInput} from 'src/design-system';
import * as Yup from 'yup';
import moment from 'moment';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const Import = (
  key: string = 'startDate',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Intake Date (MM/DD/YYYY)',
  key,
  validators: [
    {
      validate: 'regex_match',
      regex:
        '(0[1-9]|1[012])[- \\/.](0[1-9]|[12][0-9]|3[01])[- \\/.](19|20)\\d\\d$',
      errorMessage: 'Must be a valid date.',
    },
  ],
});

const resolveDisplayValue = (value: string) => {
  return moment(value).format('ll');
};

const IntakeDateDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Intake Date</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const IntakeDateInput = ({valueKey}: Props) => {
  return (
    <DateInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'startDate'}
      name={'Intake Date'}
      required={true}
      maximumDate={new Date()}
    />
  );
};

export const validation = () =>
  Yup.string().required('Intake date is required');

export const Display = IntakeDateDisplay;
export const Input = IntakeDateInput;
export const Value = resolveDisplayValue;

export default IntakeDateInput;
