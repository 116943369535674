import React from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {intervalTypes} from 'src/hook-form-inputs/interval-type';
import {ProgramMethodAvatar} from 'src/modules/programs/components';
import {collectionMethods} from 'src/modules/programs/components/program-card/collection';
import {Typography} from 'src/styles';
import {Controller} from 'react-hook-form';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import TextInput from 'src/hook-form/text-input';
import CheckboxInput from 'src/hook-form/checkbox-input';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {of} from 'rxjs';
import SelectInput from 'src/hook-form/select-input';

export const reasonList = [
  {label: 'IP - Implementation Problem', value: 'IP'},
  {label: 'MM - Materials Missing', value: 'MM'},
  {label: 'AAA - Alternative Appropriate Activity', value: 'AAA'},
  {label: 'UE - Unexpected Event', value: 'UE'},
  {label: 'BP - Behavioral Problem', value: 'BP'},
  {label: 'SKP - Skipped', value: 'S'},
  {label: 'O - Other', value: 'O'},
];

interface Props {
  index: number;
  target: any;
  control: any;
  program: any;
  events: any[];
}

const NotAddressedProgramItem = ({
  index,
  target,
  program,
  control,
  events,
}: Props) => {
  const method = collectionMethods.find(
    collectionMethod => collectionMethod.value === program?.method,
  );
  const total = program?.numberOfTrials;
  const styles = useStyle();

  return (
    <View
      style={[
        styles.container,
        styles.marginHorizontal,
        styles.border,
        styles.borderColorPrimary300,
        styles.borderRadiusM,
        styles.padding,
        styles.marginBottom,
      ]}>
      <View style={[styles.column, styles.width]}>
        <View style={[styles.container, styles.justifySpaceBetween]}>
          <View style={[styles.row]}>
            <ProgramMethodAvatar type={program?.method} />
            <View>
              <Text style={[styles.textColorPrimary, Typography.P3_MEDIUM]}>
                {program?.method === 'trial_by_trial'
                  ? `${program?.name} - ${target?.name}`
                  : program?.name}
              </Text>
              <Text style={[styles.textColorSecondary, Typography.CAPTION]}>
                {program?.type === 'behavior'
                  ? 'Behavior Tracker'
                  : 'Skill Acquisition'}{' '}
                |{' '}
                {program?.method === 'interval'
                  ? intervalTypes.find(item => item.value === program?.interval)
                      ?.label
                  : method?.title}
              </Text>
            </View>
          </View>
          <Text
            style={[
              Typography.P3_MEDIUM,
              styles.severeColor,
              styles.paddingSM,
              styles.textAlignCenter,
            ]}>
            Task {events.length}/{total}
          </Text>
        </View>
        <RHSeparator height={10} />
        <Controller
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <SelectInput
              label={'Select'}
              items={reasonList}
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
          name={`unaddressedSets[${index}].reason`}
        />
        <RHSeparator height={10} />
        <Controller
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextInput
              onChange={onChange}
              value={value}
              label={'Description'}
              name={'Please indicate the reason for not running this Set.'}
              error={error}
            />
          )}
          name={`unaddressedSets[${index}].description`}
        />
        <RHSeparator height={10} />
        <Controller
          control={control}
          render={({field: {onChange, value}}) => (
            <CheckboxInput
              name={''}
              items={[{label: 'Include in Clinical Note', value: 'include'}]}
              value={value}
              onChange={onChange}
            />
          )}
          name={`unaddressedSets[${index}].includeNotAddressed`}
        />
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({set}: any) => ({
    program: set.program,
    target: set?.target || of({}),
    events: set.events || of([]),
  })),
)(NotAddressedProgramItem);
