import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {DataItem, FileImage} from 'src/design-system';
import addressFormat from 'src/common-utils/address-format';

const OrganizationDisplay = ({currentNoteTemplate, organization}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  return (
    <>
      <Text style={[Typography.H5]}>{translations('organization')}</Text>
      <View
        style={[
          styles.marginLVertical,
          styles.cardFlat,
          styles.padding,
          styles.borderRadius,
        ]}>
        <View style={[styles.row, styles.alignCenter]}>
          {currentNoteTemplate?.organizationLogo ? (
            <View
              style={[
                styles.alignCenter,
                styles.justifyCenter,
                styles.marginMRight,
                styles.overflowHidden,
                styles.borderRadius,
                styles.width50,
                styles.height50,
              ]}>
              <FileImage src={organization?.logo} />
            </View>
          ) : null}
          {currentNoteTemplate?.organizationName ? (
            <View style={[styles.paddingLLeft, styles.justifyCenter]}>
              <Text style={[Typography.P3, styles.textColorPrimary]}>
                {translations('organization_name')}
              </Text>
              <Text style={[Typography.P2]}>{organization?.name}</Text>
            </View>
          ) : null}
        </View>

        <View style={[styles.row]}>
          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            {currentNoteTemplate?.organizationAddress ? (
              <DataItem>
                <Text style={[Typography.H6]}>{translations('address')}</Text>
                <Text style={[Typography.P3]}>
                  {addressFormat(organization?.address)}
                </Text>
              </DataItem>
            ) : (
              <></>
            )}
            {currentNoteTemplate?.organizationBillingAddress ? (
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('billing_address')}
                </Text>
                <Text style={[Typography.P3]}>
                  {addressFormat(organization?.billingAddress)}
                </Text>
              </DataItem>
            ) : (
              <></>
            )}
          </View>

          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            {currentNoteTemplate?.organizationTin ? (
              <DataItem>
                <Text style={[Typography.H6]}>TIN</Text>
                <Text style={[Typography.P3]}>{organization.tin}</Text>
              </DataItem>
            ) : (
              <></>
            )}
            {currentNoteTemplate?.organizationNpi ? (
              <DataItem>
                <Text style={[Typography.H6]}>NPI</Text>
                <Text style={[Typography.P3]}>{organization.npi}</Text>
              </DataItem>
            ) : (
              <></>
            )}
          </View>
        </View>
      </View>
    </>
  );
};

export default OrganizationDisplay;
