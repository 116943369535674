import React, {forwardRef, useRef} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Separator} from 'src/common-components/atoms';
import SignatureCanvas from 'react-signature-canvas';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

const Signature = forwardRef(({onChange}: any) => {
  const styles = useStyle();

  const signatureComponent = useRef('sign');
  const deleteActualSignature = () => {
    signatureComponent.current.clear();
    onChange('');
  };
  const saveActualSignature = (signature: string) => {
    onChange(signature);
  };

  return (
    <>
      <View
        style={[
          styles.width,
          styles.border,
          styles.borderColorPrimary300,
          styles.justifyCenter,
          styles.alignCenter,
        ]}>
        <SignatureCanvas
          ref={signatureComponent}
          canvasProps={{
            width: '680',
            height: '200',
          }}
          backgroundColor={'transparent'}
          onEnd={() => {
            saveActualSignature(
              signatureComponent.current
                .getTrimmedCanvas()
                .toDataURL('image/png'),
            );
          }}
        />
      </View>
      <Separator height={15} />
      <TouchableOpacity
        style={[styles.row, styles.justifyStart, styles.alignCenter]}
        onPress={deleteActualSignature}>
        <Icon name={'delete'} size={15} />
        <Text style={[Typography.SECONDARY_BUTTON, styles.marginLLeft]}>
          DELETE SIGNATURE
        </Text>
      </TouchableOpacity>
    </>
  );
});

export default Signature;
