import React from 'react';
import FormProvider from '../../context/form-context';
import Canvas from 'src/modules/form-builder/components/canvas/canvas';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';

const FormBuilder = ({name = ''}: any) => {
  const styles = useStyle();
  return (
    <View style={[styles.flex, styles.backgroundColorWhite]}>
      <FormProvider name={name}>
        <View style={[styles.row]}>
          <View style={[styles.width]}>
            <Canvas />
          </View>
        </View>
      </FormProvider>
    </View>
  );
};

export default FormBuilder;
