import React from 'react';
import {View} from 'react-native';
import DisplayUserDataSnippet from 'src/common-components/displayUserDataSnippet';
import {useNavigation} from '@react-navigation/native';
import {IconButton, Menu} from 'src/design-system';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {Colors} from 'src/styles';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';

const StaffListItem = ({
  item: staffMember,
  canEdit,
  patient,
  credential,
  togglePrimary,
  onAssign,
  canDelete,
}: any) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const translations = useTranslations();

  const firstName = staffMember?.firstName || '';
  const lastName = staffMember?.lastName || '';
  const resourceType = 'Staff';
  const role = staffMember?.role || '';

  const options = [];
  if (canEdit || canDelete) {
    options.push({
      onPress: () => togglePrimary(credential),
      title: translations('make_primary'),
      icon: 'star',
    });
    options.push({
      onPress: () => onAssign(staffMember),
      title: translations('unassign_staff'),
      icon: 'account-remove',
    });
  }
  options.push({
    onPress: () => {
      navigation.navigate('UserProfile', {
        practitionerId: staffMember?.id,
      });
    },
    title: translations('view_staff_profile'),
    icon: 'eye',
  });

  return (
    <View
      style={[
        styles.flex,
        styles.width,
        styles.row,
        styles.justifySpaceBetween,
        styles.paddingLHorizontal,
      ]}>
      <DisplayUserDataSnippet
        firstName={firstName}
        lastName={lastName}
        resourceType={resourceType}
        role={role}
      />
      <View style={[styles.row, styles.alignCenter, styles.justifyCenter]}>
        {patient.renderingId === credential?.id ? (
          <MaterialCommunityIcon
            name={'star'}
            color={Colors.RAVEN_BLACK}
            size={22}
          />
        ) : (
          <MaterialCommunityIcon
            name={'star-outline'}
            color={Colors.RAVEN_BLACK}
            size={22}
          />
        )}
        <View style={[]}>
          {canEdit || canDelete ? (
            <Menu
              anchor={<IconButton type={'icon'} icon={'dots-vertical'} />}
              options={options}
            />
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['item'], ({item}: any) => ({
    credential: item.credential,
  })),
)(StaffListItem);
