import React, {useEffect, useState} from 'react';
import BaseAuthScreen from 'src/modules/authentication/screens/base-auth-screen';
import {LoginForm} from 'src/modules/authentication/components';
import {useNavigation} from '@react-navigation/native';
import _ from 'lodash';
import {useTokenMutation} from 'src/services/auth';
import sync from 'src/providers/watermelondb/sync';
import {useDatabase} from '@nozbe/watermelondb/hooks';

const LoginScreen = ({route}: any) => {
  const database = useDatabase();
  const navigation = useNavigation();

  const [toast, setToast] = useState<null | any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [token] = useTokenMutation();

  useEffect(() => {
    if (!_.isEmpty(route.params)) {
      setToast(route.params);
    }
  }, [route.params]);

  const handleSubmit = async ({
    type,
    code,
    clientId,
    username,
    password,
  }: any) => {
    const formData = new FormData();
    switch (type) {
      case 'code':
        formData.append('code', code);
        formData.append('client_id', clientId);
        formData.append('grant_type', 'authorization_code');
        break;
      case 'password':
        formData.append('username', username);
        formData.append('password', password);
        formData.append('grant_type', 'password');
        break;
    }
    setLoading(true);
    token(formData).then(async (res: any) => {
      if (res.error) {
        if (
          res.error.status === 400 ||
          res.error.status === 401 ||
          res.error.status === 404
        ) {
          switch (type) {
            case 'code':
              if (res.error.status === 404) {
                setToast({
                  success: false,
                  title: 'User Not Found.',
                  message: '',
                });
              } else {
                setToast({
                  success: false,
                  title: 'Invalid Authorization Code.',
                  message: '',
                });
              }
              break;
            case 'password':
              setToast({
                success: false,
                title: 'Invalid Username or Password.',
                message: '',
              });
              break;
          }
        } else {
          setToast({
            success: false,
            title: 'Authentication server is down.',
            message:
              'Sorry for the inconvenience. The product team has been notified.',
          });
        }
        setLoading(false);
      } else {
        try {
          await sync(database, false, true);
          setLoading(false);
          setToast(null);
          navigation.navigate('OrganizationSelection');
        } catch (e) {
          console.log(e);
        }
      }
    });
  };

  const forgotPassword = () => {
    navigation.navigate('ForgotPassword');
  };

  const signup = () => {
    navigation.navigate('Signup');
  };

  return (
    <BaseAuthScreen loading={loading} toast={toast}>
      <LoginForm
        handleSubmit={handleSubmit}
        forgotPassword={forgotPassword}
        signup={signup}
      />
    </BaseAuthScreen>
  );
};

export default LoginScreen;
