import React, {useEffect, useReducer, useState} from 'react';
import {Colors, Typography} from 'src/styles';
import {Text, TouchableOpacity, View, Pressable} from 'react-native';
import _ from 'lodash';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {useStopwatch} from 'react-timer-hook';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {Event, Set} from 'src/models';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import ProgramMenu from 'src/design-system/program-menu';
import ProgramInstructionModal from '../program-instruction-modal';
import {Modal} from 'src/design-system';
import ProgramProgress from 'src/modules/programs/components/program-progress-legacy';
import {RateTable} from 'src/modules/session/components';
import DurationTotalEdit from 'src/modules/session/components/duration/total-edit';
import {useStyle} from 'src/providers/style';
import BehaviorABC from 'src/modules/session/components/behavior-abc';
import BehaviorProgramTooltip from '../behavior-program-tooltip';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';
import {useTranslations} from 'src/providers/translation';

const intensityArray = [
  {title: 'Mild', value: 'mild'},
  {title: 'Moderate', value: 'moderate'},
  {title: 'Severe', value: 'severe'},
];

const Rate = ({program, session, sets, events, programPrompts}: any) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();
  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const currentSet = sets?.[0] || {
    startTimestamp: null,
    endTimestamp: null,
  };

  const [expandABCView, setExpandABCView] = useState(false);
  const [intensity, setIntensity] = useState<string>('mild');
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [editEvents, setEvents] = useState('');
  const [instanceNumber, setInstance] = useState('');
  const [editRow, setRow] = useState('');

  const {seconds, minutes, hours, isRunning, start, pause, reset} =
    useStopwatch({
      autoStart: false,
    });

  const prompts = _.groupBy(programPrompts, 'promptType') || [];

  useEffect(() => {
    if (sets && sets?.length > 0) {
      let discrepancy = 0;
      const stopwatchOffset = new Date();
      if (
        currentSet.startTimestamp !== null &&
        currentSet.endTimestamp !== null
      ) {
        discrepancy += currentSet.endTimestamp - currentSet.startTimestamp;
        stopwatchOffset.setSeconds(
          stopwatchOffset.getSeconds() + discrepancy / 1000,
        );
        reset(stopwatchOffset);
        pause();
      } else if (
        currentSet.startTimestamp !== null &&
        currentSet.endTimestamp === null
      ) {
        discrepancy += new Date() - currentSet.startTimestamp;
        stopwatchOffset.setSeconds(
          stopwatchOffset.getSeconds() + discrepancy / 1000,
        );
        reset(stopwatchOffset);
        start();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSet]);

  const onValue = async () => {
    if (
      currentSet === undefined ||
      currentSet?.startTimestamp === null ||
      currentSet?.endTimestamp !== null
    ) {
      start();
      await database?.write(async () => {
        return await database?.get(Set.table).create(entity => {
          entity.partition = selectedGroup;
          entity.session.id = session.id;
          entity.program.id = program.id;
          entity.startTimestamp = new Date();
          entity.endTimestamp = null;
          entity.intensity = program?.intensity ? 'mild' : '';
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    } else {
      pause();
      currentSet.endSet();
      setExpandABCView(true);
    }
    setIntensity('mild');
  };

  const onTarget = async (value: string) => {
    await database?.write(async () => {
      await database.get(Event.table).create(entity => {
        entity.partition = selectedGroup;
        entity.set.id = currentSet.id;
        entity.value = value;
        entity.prompts = [];
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
    });
  };

  const checkPrompt = (id: any) => {
    const index = [...currentSet?.prompts].findIndex((v: any) => {
      return v === id;
    });

    if (index === -1) {
      const values = [...currentSet?.prompts];
      values.push(id);
      currentSet.updateEntity({
        prompts: values,
      });
    } else {
      const values = [...currentSet?.prompts];
      values.splice(index, 1);
      currentSet.updateEntity({
        prompts: values,
      });
    }
    forceUpdate();
  };

  const undo = async () => {
    if (currentSet.endTimestamp !== null) {
      currentSet.updateEntity({
        endTimestamp: null,
      });
      let discrepancy = 0;
      const stopwatchOffset = new Date();
      discrepancy += new Date() - currentSet.startTimestamp;
      stopwatchOffset.setSeconds(
        stopwatchOffset.getSeconds() + discrepancy / 1000,
      );
      reset(stopwatchOffset);
      start();
      setExpandABCView(false);
    } else {
      if (currentSet.startTimestamp !== null) {
        if (sets?.length > 1) {
          const previousSetIntensity = sets[1]?.intensity;
          setIntensity(previousSetIntensity);
        } else {
          setIntensity('mild');
        }

        const orderedEvents = await currentSet.orderedEvents.fetch();
        if (orderedEvents.length > 0) {
          const event = orderedEvents[0];
          event.delete();
        } else {
          currentSet.delete();
          reset();
        }
      }
    }
  };

  const showPrompts = () => {
    if (sets.length > 0) {
      setExpandABCView(!expandABCView);
    }
  };

  const changeIntensity = (val: string) => {
    setIntensity(val);
    currentSet.updateEntity({
      intensity: val,
    });
  };

  const leadingZero = (value: number) => {
    return /^\d$/.test(value) ? '0' + value : value;
  };

  const viewInstructions = () => {
    setInstructionsModal(!instructionsModal);
  };
  const viewHistory = () => {
    setHistoryModal(!historyModal);
  };
  const viewData = () => {
    setDataModal(!dataModal);
  };

  return (
    <View style={[styles.flex]}>
      <View
        style={[
          styles.borderRadius,
          styles.border,
          styles.borderColorGray,
          styles.marginLBottom,
          styles.backgroundColorGray,
          styles.width300,
        ]}>
        <Pressable onPress={showPrompts}>
          <View style={[styles.paddingVertical, styles.paddingHorizontal]}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={[styles.flex]}>
                <View
                  style={[
                    styles.row,
                    styles.alignCenter,
                    styles.marginSMBottom,
                  ]}>
                  <BehaviorProgramTooltip name={program?.name} />
                  {sets.length > 0 ? (
                    <MaterialCommunityIcons
                      name={'undo'}
                      size={22}
                      color={Colors.RAVEN_WHITE}
                      onPress={undo}
                      style={[styles.marginLLeft]}
                    />
                  ) : (
                    <></>
                  )}
                </View>
                <Text style={[Typography.CAPTION, styles.textColorSecondary]}>
                  Rate
                </Text>
              </View>
              <ProgramMenu
                viewInstructions={viewInstructions}
                viewHistory={viewHistory}
                viewData={viewData}
                color={Colors.RAVEN_WHITE}
              />
            </View>
            <View style={[styles.column, styles.marginTop, styles.zIndex]}>
              <View
                style={[
                  styles.row,
                  styles.justifySpaceBetween,
                  styles.alignCenter,
                ]}>
                <View
                  style={[
                    styles.row,
                    styles.justifySpaceBetween,
                    styles.alignCenter,
                  ]}>
                  <TouchableOpacity
                    style={[styles.alignCenter]}
                    onPress={onValue}>
                    <View
                      style={[
                        styles.width40,
                        styles.height40,
                        styles.borderRadius20,
                        styles.alignCenter,
                        styles.justifyCenter,
                        styles.backgroundColorBlack,
                        styles.border,
                        !(sets?.length === 0)
                          ? styles.borderColorBlue
                          : styles.borderColorWhite,
                      ]}>
                      {sets?.length === 0 ? (
                        <MaterialIcon
                          name="bar-chart"
                          size={22}
                          color={Colors.RAVEN_WHITE}
                        />
                      ) : (
                        <Ionicons
                          name={isRunning ? 'stop' : 'play'}
                          size={22}
                          color={Colors.RAVEN_WHITE}
                        />
                      )}
                    </View>
                  </TouchableOpacity>
                  {!(sets?.length === 0) ? (
                    <Text
                      style={[
                        Typography.P2_MEDIUM,
                        styles.paddingLHorizontal,
                        styles.textColorWhite,
                      ]}>
                      {leadingZero(hours)}:{leadingZero(minutes)}:
                      {leadingZero(seconds)}
                    </Text>
                  ) : (
                    <></>
                  )}
                </View>
                <View
                  style={[
                    styles.row,
                    styles.alignCenter,
                    styles.justifySpaceBetween,
                  ]}>
                  {!(sets?.length === 0) ? (
                    <Text
                      style={[
                        Typography.P2_MEDIUM,
                        styles.paddingLHorizontal,
                        styles.textColorWhite,
                      ]}>
                      {events.length}
                    </Text>
                  ) : (
                    <></>
                  )}
                  {isRunning ? (
                    <TouchableOpacity
                      style={[styles.alignCenter]}
                      onPress={() => onTarget('1')}>
                      <View
                        style={[
                          styles.width40,
                          styles.height40,
                          styles.borderRadius20,
                          styles.alignCenter,
                          styles.justifyCenter,
                          styles.backgroundColorBlack,
                          styles.border,
                          styles.borderColorWhite,
                        ]}>
                        <AntDesign
                          name="plus"
                          size={22}
                          color={Colors.RAVEN_WHITE}
                        />
                      </View>
                    </TouchableOpacity>
                  ) : (
                    <></>
                  )}
                </View>
              </View>
            </View>
          </View>
        </Pressable>
        <BehaviorABC
          program={program}
          expandABCView={expandABCView}
          setExpandABCView={setExpandABCView}
          intensity={intensity}
          changeIntensity={changeIntensity}
          prompts={prompts}
          checkPrompt={checkPrompt}
          currentSet={currentSet}
        />
      </View>
      {instructionsModal ? (
        <ProgramInstructionModal
          show={[instructionsModal, setInstructionsModal]}
          program={program}
        />
      ) : null}
      {historyModal ? (
        <Modal
          show={[historyModal, setHistoryModal]}
          title={'Progress'}
          footer={
            <InformationTooltip
              value={translations('program_progress_month')}
            />
          }>
          <ProgramProgress program={program} />
        </Modal>
      ) : null}
      {dataModal ? (
        <Modal show={[dataModal, setDataModal]} title={`${program.name} Data`}>
          <RateTable
            type={'edit-modal'}
            setEvents={chosenSet => {
              setEvents(chosenSet);
            }}
            setInstance={idx => {
              setInstance(idx);
            }}
            setRow={r => {
              setRow(r);
            }}
            program={program}
            sets={sets}
          />
          {editEvents ? (
            <DurationTotalEdit
              setEvents={show => {
                setEvents(show);
              }}
              row={editRow}
              intensityArray={intensityArray}
              instance={instanceNumber}
              set={editEvents}
              prompts={prompts}
              session={session}
              program={program}
            />
          ) : (
            <></>
          )}
        </Modal>
      ) : null}
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({session, program, database}: any) => ({
    sets: database
      .get('sets')
      .query(
        Q.and(
          Q.where('program_id', program.id),
          Q.where('session_id', session.id),
          Q.where('deleted_at', null),
        ),
        Q.sortBy('created_at', Q.desc),
      ),
    programPrompts: program?.prompts,
  })),
  withObservables(['sets'], ({sets}: any) => {
    if (sets.length !== 0) {
      return {
        events: sets?.[0]?.orderedEvents,
      };
    }

    return {
      events: of([]),
    };
  }),
)(Rate);
