import React from 'react';
import {Text} from 'react-native';
import {useFormikContext} from 'formik';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

const EnvDescriptionLabel = ({}) => {
  const styles = useStyle();

  const {values} = useFormikContext();

  return (
    <Text style={[Typography.P3, styles.textColorSecondary]}>
      {values?.type === 'factor'
        ? 'Definition: A one-time data point collected via interview of caregiver or client and/or observed by clinical team that may have an impact on therapy session.\n\nExamples: Poor sleep/tired, low appetite, tantrum on way to session, crying on entry, etc.'
        : values?.type === 'change'
        ? 'Definition: A life event that occurs at one point in time that can have ongoing influence on behavior and programming data.\n\nExamples:  New medication or change in dosage, change in family status, change in school setting, co-morbid diagnosis, etc.'
        : ''}
    </Text>
  );
};

export default EnvDescriptionLabel;
