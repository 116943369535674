import React, {useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {TouchableOpacity, Text, View} from 'react-native';
import {RegexValidation} from '../index';
import {TextInput} from 'react-native-paper';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {FormInput} from 'src/design-system';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

interface Props {
  handleSubmit: (payload: any, formik: any) => void;
  login: () => void;
}

const RestPasswordForm = ({handleSubmit, login}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [hidePassword, setHidePassword] = useState(true);

  const minLengthRegex = /^.{8,}$/;
  const lowercaseRegex = /^(.*[a-z].*)$/;
  const uppercaseRegex = /^(.*[A-Z].*)$/;
  const symbolRegex = /^(.*[-+_!@#$%^&*.,?/|:;'"><()=\\{}[\]].*)$/;

  return (
    <Formik
      initialValues={{
        password: '',
        password_confirmation: '',
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .required(translations('password_valid'))
          .matches(minLengthRegex, translations('auth_form_min_length_message'))
          .matches(lowercaseRegex, translations('auth_form_lowercase_message'))
          .matches(uppercaseRegex, translations('auth_form_uppercase_message'))
          .matches(symbolRegex, translations('password_symbol')),
        password_confirmation: Yup.string()
          .required(translations('password_confirmation_required'))
          .test({
            name: 'password-confirmation',
            exclusive: false,
            params: {},
            message: translations('password_confirmation'),
            test: function (value) {
              return value === this.parent.password;
            },
          }),
      })}
      onSubmit={handleSubmit}>
      {({
        errors,
        // setErrors,
        values,
        setValues,
        touched,
        setTouched,
        // resetForm,
        submitForm,
      }) => (
        <>
          <FormInput
            secureTextEntry={hidePassword}
            valueKey={'password'}
            text={translations('auth_form_new_password')}
            required={true}
            errors={errors}
            values={values}
            setValues={setValues}
            touched={touched}
            setTouched={setTouched}
            right={
              <TextInput.Icon
                name={hidePassword ? 'eye-off' : 'eye'}
                onPress={() => setHidePassword(!hidePassword)}
                touchSoundDisabled={true}
              />
            }
          />
          <View>
            <RegexValidation
              regex={minLengthRegex}
              value={values.password}
              message={translations('auth_form_min_length_message')}
            />
            <RegexValidation
              regex={lowercaseRegex}
              value={values.password}
              message={translations('auth_form_lowercase_message')}
            />
            <RegexValidation
              regex={uppercaseRegex}
              value={values.password}
              message={translations('auth_form_uppercase_message')}
            />
            <RegexValidation
              regex={symbolRegex}
              value={values.password}
              message={translations('password_symbol')}
            />
          </View>
          <FormInput
            secureTextEntry={hidePassword}
            valueKey={'password_confirmation'}
            text={translations('auth_form_confirm_password')}
            required={true}
            errors={errors}
            values={values}
            setValues={setValues}
            touched={touched}
            setTouched={setTouched}
            right={
              <TextInput.Icon
                name={hidePassword ? 'eye-off' : 'eye'}
                onPress={() => setHidePassword(!hidePassword)}
                touchSoundDisabled={true}
              />
            }
          />
          <View style={[styles.paddingLVertical]}>
            <RHButton onPress={submitForm} mode={'contained'} secondary>
              <Text>{translations('reset')}</Text>
            </RHButton>
            <View style={[styles.row, styles.justifyCenter, styles.paddingTop]}>
              <TouchableOpacity onPress={login}>
                <Text
                  style={[
                    Typography.TERTIARY_BUTTON,
                    styles.textColorSecondary,
                    styles.textTransformUppercase,
                  ]}>
                  {translations('auth_form_back_to_login')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </>
      )}
    </Formik>
  );
};

export default RestPasswordForm;
