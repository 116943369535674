import React from 'react';
import {Text, View} from 'react-native';
import FirstName, {
  Display as FirstNameDisplay,
} from 'src/hook-form-inputs/first-name';
import LastName, {
  Display as LastNameDisplay,
} from 'src/hook-form-inputs/last-name';
import FormSectionHeader from 'src/design-system/form-section-header';
import Relationship from 'src/hook-form-inputs/relationship';
import PhoneNumber from 'src/hook-form-inputs/phone-number';
import Email from 'src/hook-form-inputs/email';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {Display as RelationshipDisplay} from 'src/hook-form-inputs/relationship';
import {Display as PhoneNumberDisplay} from 'src/hook-form-inputs/phone-number';
import {Display as EmailDisplay} from 'src/hook-form-inputs/email';
import {Display as AddressDisplay} from 'src/hook-form-inputs/address';
import {useStyle} from 'src/providers/style';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
import {Tooltip} from 'src/design-system';
import Icon from 'react-native-vector-icons/MaterialIcons';

export const defaultCaregiver = {
  firstName: '',
  lastName: '',
  mobilePhone: '',
  email: '',
  relationship: '',
  'address.line': '',
  'address.city': '',
  'address.state': '',
  'address.postalCode': '',
  'address.country': '',

  'workAddress.line': '',
  'workAddress.city': '',
  'workAddress.state': '',
  'workAddress.postalCode': '',
  'workAddress.country': '',
};

const CaregiverFormDisplay = ({caregivers}: any) => {
  const styles = useStyle();
  return (
    <View style={[styles.flex, styles.column]}>
      <FormSectionHeader title={'Caregiver Information'} />
      {caregivers?.map((caregiver, index) => (
        <React.Fragment key={index}>
          <FormSectionHeader title={`Caregiver ${index + 1}`} />
          <FirstNameDisplay value={caregiver?.firstName} />
          <LastNameDisplay value={caregiver?.lastName} />
          <RelationshipDisplay value={caregiver?.relationship} />
          <PhoneNumberDisplay value={caregiver?.mobilePhone} />
          <EmailDisplay value={caregiver?.email} />
          <AddressDisplay value={caregiver?.address} />
          <AddressDisplay value={caregiver?.workAddress} type={'Work'} />
        </React.Fragment>
      ))}
    </View>
  );
};

const CaregiverForm = () => {
  const styles = useStyle();
  const {control} = useFormContext();

  const {fields, append, remove} = useFieldArray({
    name: 'caregivers',
    control,
  });

  return (
    <>
      {fields.map((field, index) => (
        <View key={field.id}>
          <View style={[styles.row, styles.paddingHorizontal]}>
            <FormSectionHeader title={`Caregiver ${index + 1}`} />
            {index !== 0 && (
              <View style={[styles.row, styles.marginLeft]}>
                <RHButton
                  icon="minus-circle-outline"
                  mode="outlined"
                  color={Colors.RAVEN_WHITE}
                  textColor={Colors.PRIMARY_300}
                  onPress={() => {
                    remove(index);
                  }}>
                  REMOVE
                </RHButton>
              </View>
            )}
          </View>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex, styles.paddingHorizontal]}>
              <FirstName name={`caregivers.${index}`} />
            </View>
            <View style={[styles.flex, styles.paddingHorizontal]}>
              <LastName name={`caregivers.${index}`} />
            </View>
            <View style={[styles.flex, styles.paddingHorizontal]}>
              <View style={[styles.row, styles.flex]}>
                <View style={[styles.flex]}>
                  <Relationship name={`caregivers.${index}`} />
                </View>
                <Tooltip
                  anchor={
                    <Icon
                      name={'info'}
                      style={[styles.marginBottom, styles.paddingBottom]}
                    />
                  }>
                  <Text style={[Typography.P3]}>
                    How the caregiver relates to the client
                  </Text>
                </Tooltip>
              </View>
            </View>
          </View>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex, styles.paddingHorizontal]}>
              <PhoneNumber name={`caregivers.${index}`} />
            </View>
            <View style={[styles.flex, styles.paddingHorizontal]}>
              <Email name={`caregivers.${index}`} />
            </View>
          </View>
          <View style={[styles.paddingHorizontal]}>
            <AddressAutocompleteInput
              label={'Address'}
              name={`caregivers.${index}.address`}
            />
          </View>
          <View style={[styles.paddingHorizontal]}>
            <AddressAutocompleteInput
              label={'Work Address'}
              name={`caregivers.${index}.workAddress`}
            />
          </View>
        </View>
      ))}
      <View style={[styles.row, styles.paddingHorizontal, styles.marginBottom]}>
        <RHButton
          icon="plus-circle-outline"
          mode="outlined"
          color={Colors.RAVEN_WHITE}
          textColor={Colors.RAVEN_BLACK}
          onPress={() => {
            append(defaultCaregiver);
          }}>
          ADD MORE
        </RHButton>
      </View>
    </>
  );
};

export const Display = CaregiverFormDisplay;

export default CaregiverForm;
