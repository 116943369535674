import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {IconButton} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import Icon from 'react-native-vector-icons/Ionicons';
import ArrowIcon from 'react-native-vector-icons/MaterialIcons';
import {Separator} from 'src/common-components/atoms';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import moment from 'moment';
import SessionNote from 'src/modules/session/components/session-note';
import SessionPrograms from 'src/modules/session/components/session-program-list';
import {useStyle} from 'src/providers/style';
import {useNavigation} from '@react-navigation/core';
import {Event, Note, Set} from 'src/models';
import {Q} from '@nozbe/watermelondb';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import replace from 'src/navigation/utils/replace';
import EndSessionModal from 'src/modules/session/components/end-session-modal';
import {sessionNoteReplacement} from 'src/common-utils/session-note-replacement';
import {useSelector} from 'react-redux';
import {useTranslations} from 'src/providers/translation';
import withState from 'src/redux/wrapper';

interface Props {
  session: any;
  appointment: any;
  patient: any;
  participant: any;

  organization: any;
}

const SessionHeader = ({
  appointment,
  session,
  patient,
  participant,
  organization,
}: Props) => {
  const database = useDatabase();
  const styles = useStyle();
  const navigation = useNavigation();
  const translations = useTranslations();
  const {inSession} = useSelector(state => state);
  const {userId, selectedGroup} = useSelector(state => state.authentication);
  const statefulNote = inSession?.notes?.[session.id] || '';
  const [openNote, setOpenNote] = useState(false);
  const [openReorderList, setOpenReorderList] = useState(false);

  const startClk = async () => {
    await session.updateEntity({
      startTimestamp: new Date(),
    });
  };

  const endCallback = async () => {
    if (session.type !== 'supervision') {
      const behaviorSets = await database
        .get(Set.table)
        .query(
          Q.on(
            'programs',
            Q.or(Q.where('method', 'duration'), Q.where('method', 'rate')),
          ),
          Q.where('end_timestamp', Q.eq(null)),
          Q.where('session_id', session.id),
        );
      for (const behaviorSet of behaviorSets) {
        await behaviorSet.updateEntity({
          endTimestamp: new Date(),
        });
      }

      const sets = await database
        .get(Set.table)
        .query(
          Q.where('end_timestamp', Q.eq(null)),
          Q.where('session_id', session.id),
        );

      for (const set of sets) {
        const numberOfTrials = await database
          .get(Event.table)
          .query(Q.where('set_id', set.id), Q.where('deleted_at', Q.eq(null)))
          .fetchCount();

        if (numberOfTrials > 0) {
          await set.updateEntity({
            endTimestamp: new Date(),
            numberOfTrials,
          });
        } else {
          await set.delete();
        }
      }
    }

    const participants = await appointment.currentStaffParticipant(userId);

    let appointmentStart = appointment.start;
    let appointmentEnd = appointment.end;
    if (participants.length > 0) {
      if (participants[0]?.startTime) {
        appointmentStart = participants[0].startTime;
      }
      if (participants[0]?.endTime) {
        appointmentEnd = participants[0].endTime;
      }
    }

    const startTime = moment(appointmentStart);
    const startDateMod = moment(session.sessionDate);
    startDateMod.set({
      hour: startTime.get('hour'),
      minute: startTime.get('minute'),
      second: 0,
      millisecond: 0,
    });
    const startDate = startDateMod.toDate();

    const endTime = moment(appointmentEnd);
    const endDateMod = moment(session.sessionDate);
    endDateMod.set({
      hour: endTime.get('hour'),
      minute: endTime.get('minute'),
      second: 0,
      millisecond: 0,
    });
    const endDate = endDateMod.toDate();
    await session.updateEntity({
      note: await sessionNoteReplacement(
        database,
        session?.note,
        session.id,
        appointment.id,
        appointment?.type,
        appointment?.location,
        organization?.actualTime ? session.startTimestamp : startDate,
        organization?.actualTime ? new Date() : endDate,
      ),
      endTimestamp: new Date(),
      userId,
      renderingId: userId,
      cpt: participant?.cpt || [],
      location: appointment?.location,
      address: appointment?.address,
      customAddress: appointment?.customAddress,
      editedStartTimestamp: organization?.actualTime
        ? session.startTimestamp
        : startDate,
      editedEndTimestamp: organization?.actualTime ? new Date() : endDate,
    });

    if (statefulNote) {
      await database.write(async () => {
        await database.get(Note.table).create(entity => {
          entity.partition = selectedGroup;
          entity.description = statefulNote;
          entity.entity = 'session';
          entity.entityId = session.id;
          entity.createdBy = userId;
        });
      });
    }
    navigation.dispatch(
      replace('SessionMessage', {
        id: session?.id,
      }),
    );
  };
  return (
    <>
      <SessionPrograms
        openReorderList={[openReorderList, setOpenReorderList]}
        session={session}
        patient={patient}
      />
      <View style={[styles.backgroundColorBlack]}>
        <SessionNote session={session} openNote={[openNote, setOpenNote]} />
        <View
          style={[
            styles.width,
            styles.row,
            styles.alignCenter,
            styles.justifySpaceBetween,
            styles.paddingSMVertical,
          ]}>
          <View style={[styles.flex]}>
            <IconButton
              icon="arrow-left"
              color={Colors.RAVEN_WHITE}
              onPress={() => {
                if (navigation.canGoBack()) {
                  navigation.goBack();
                  return;
                } else {
                  navigation.navigate('Dashboard');
                }
              }}
            />
          </View>
          <View style={[styles.alignCenter]}>
            <Text style={[Typography.H6, styles.textColorWhite]}>
              {organization?.useClientIdentifier
                ? `${patient?.identifier}` ||
                  `${patient?.firstName[0].toUpperCase()} ${patient?.lastName[0].toUpperCase()}`
                : `${patient?.firstName} ${patient?.lastName}`}
            </Text>
            <Text style={[Typography.P4, styles.textColorWhite]}>
              {appointment
                ? `${moment(session.sessionDate).format(
                    'MMMM Do YYYY',
                  )}, ${moment(appointment.start).format('hh:mm A')} - ${moment(
                    appointment.end,
                  ).format('hh:mm A')}`
                : ''}
            </Text>
          </View>
          <View
            style={[
              styles.flex,
              styles.row,
              styles.alignCenter,
              styles.justifyEnd,
              styles.marginLRight,
            ]}>
            {(session?.startTimestamp && session?.type === 'supervision') ||
            session?.type === 'session' ? (
              <Icon
                onPress={() => setOpenNote(!openNote)}
                name="document-outline"
                size={30}
                color="white"
              />
            ) : null}
            <Separator width={20} />
            <ArrowIcon
              onPress={() => setOpenReorderList(!openReorderList)}
              name="compare-arrows"
              size={30}
              color="white"
            />
            <Separator width={20} />
            {session?.type === 'session' && !session?.startTimestamp ? (
              <TouchableOpacity
                style={[
                  styles.border,
                  styles.borderColorWhite,
                  styles.borderRadiusSM,
                  styles.paddingMHorizontal,
                  styles.paddingXS,
                ]}
                onPress={startClk}>
                <Text
                  style={[
                    Typography.P3,
                    styles.textColorWhite,
                    styles.textTransformUppercase,
                  ]}>
                  {translations('start')}
                </Text>
              </TouchableOpacity>
            ) : null}
            {session?.startTimestamp ? (
              <EndSessionModal endCallback={endCallback} session={session} />
            ) : null}
          </View>
        </View>
      </View>
    </>
  );
};

export default compose(
  withState,
  withObservables([], ({session}: any) => ({
    session,
    appointment: session.appointment,
  })),
  withObservables([], ({authentication, appointment}: any) => ({
    currentParticipants: appointment.currentStaffParticipant(
      authentication.userId,
    ),
    participants: appointment.staffParticipants,
  })),
  withObservables([], ({participants, currentParticipants}: any) => ({
    participant: currentParticipants.length
      ? currentParticipants[0]
      : participants[0],
  })),
)(SessionHeader);
