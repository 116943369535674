import React from 'react';
import {ScrollView, View} from 'react-native';
import Medication, {
  Display as MedicationDisplay,
} from 'src/hook-form-inputs/medication';
import Dosage, {Display as DosageDisplay} from 'src/hook-form-inputs/dosage';
import Frequency, {
  Display as FrequencyDisplay,
} from 'src/hook-form-inputs/frequency';
import {Display as SeverityDisplay} from 'src/hook-form-inputs/severity';
import {Display as DiagnosisDateDisplay} from 'src/hook-form-inputs/diagnosis-date';
import {useStyle} from 'src/providers/style';
import FormSectionHeader from 'src/design-system/form-section-header';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors} from 'src/styles';
import {Display as EnabledDiagnosisCodesDisplay} from 'src/hook-form-inputs/enabled-diagnosis-codes';
import DiagnosesForm from 'src/modules/patients/components/diagnoses-form';

export const defaultMedication = {
  medication: '',
  dosage: '',
  frequency: '',
};

const MedicalFormDisplay = ({medications, diagnoses}: any) => {
  const styles = useStyle();
  return (
    <>
      <FormSectionHeader title={'Medical Information'} />
      <View style={[styles.container]}>
        <View style={[styles.flex, styles.column]}>
          {medications?.map((medication, index) => (
            <View key={index} style={[styles.column, styles.paddingBottom]}>
              <FormSectionHeader title={`Medication ${index + 1}`} />
              <MedicationDisplay value={medication?.medication} />
              <DosageDisplay value={medication?.dosage} />
              <FrequencyDisplay value={medication?.frequency} />
            </View>
          ))}
        </View>
        <View style={[styles.flex, styles.column]}>
          {diagnoses?.map((diagnosis, index) => {
            if (diagnosis.deletedAt) {
              return null;
            } else {
              return (
                <View key={index} style={[styles.column, styles.paddingBottom]}>
                  <FormSectionHeader title={`Diagnosis ${index + 1}`} />
                  <EnabledDiagnosisCodesDisplay
                    value={diagnosis?.instanceDiagnosisId}
                  />
                  <SeverityDisplay value={diagnosis?.severity} />
                  <DiagnosisDateDisplay value={diagnosis?.diagnosisDate} />
                </View>
              );
            }
          })}
        </View>
      </View>
    </>
  );
};

const MedicalForm = () => {
  const styles = useStyle();
  const {control} = useFormContext();

  const {
    fields: medicationFields,
    append: medicationAppend,
    remove: medicationRemove,
    replace: medicationReplace,
  } = useFieldArray({
    name: 'medications',
    control,
  });

  return (
    <ScrollView style={[styles.affixPadding]}>
      <View style={[styles.row, styles.paddingHorizontal]}>
        <FormSectionHeader title={'Current Medication'} />
      </View>
      {medicationFields.map((field, index) => {
        if (field?.deletedAt) {
          return null;
        }
        return (
          <View key={field.id}>
            <View style={[styles.row, styles.paddingHorizontal]}>
              <FormSectionHeader title={`Medication ${index + 1}`} />
              {index !== 0 && (
                <View style={[styles.row, styles.marginLeft]}>
                  <RHButton
                    icon="minus-circle-outline"
                    mode="outlined"
                    color={Colors.RAVEN_WHITE}
                    textColor={Colors.PRIMARY_300}
                    onPress={() => {
                      if (field?._id) {
                        const medFields = [...medicationFields];
                        medFields[index] = {
                          ...field,
                          deletedAt: new Date(),
                        };
                        medicationReplace(medFields);
                      } else {
                        medicationRemove(index);
                      }
                    }}>
                    REMOVE
                  </RHButton>
                </View>
              )}
            </View>
            <View style={[styles.width, styles.container]}>
              <View style={[styles.flex, styles.paddingHorizontal]}>
                <Medication name={`medications.${index}`} />
              </View>
              <View style={[styles.flex, styles.paddingHorizontal]}>
                <Dosage name={`medications.${index}`} />
              </View>
              <View style={[styles.flex, styles.paddingHorizontal]}>
                <Frequency name={`medications.${index}`} />
              </View>
            </View>
          </View>
        );
      })}
      <View style={[styles.row, styles.paddingHorizontal]}>
        <RHButton
          icon="plus-circle-outline"
          mode="outlined"
          color={Colors.RAVEN_WHITE}
          textColor={Colors.RAVEN_BLACK}
          onPress={() => {
            medicationAppend(defaultMedication);
          }}>
          ADD MORE
        </RHButton>
      </View>
      <View style={[styles.separator, styles.marginVertical]} />
      <View style={[styles.paddingHorizontal]}>
        <DiagnosesForm />
      </View>
    </ScrollView>
  );
};

export const Display = MedicalFormDisplay;

export default MedicalForm;
