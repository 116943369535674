import {date, json, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class ClaimEvent extends Base {
  static table = 'claim_events';

  static associations = {
    claims: {type: 'belongs_to', key: 'claim_id'},
  };

  @relation('claims', 'claim_id') claim;

  @text('claim_id') claimId;
  @text('inovalon_id') inovalonId;
  @date('timestamp') timestamp;
  @text('type') type;
  @text('description') description;
  @text('user') user;
  @json('details', value => value) details;
}
