import React from 'react';
import {Platform, Text, TouchableOpacity} from 'react-native';
import SVG from 'src/styles/svg';
import {useStyle} from 'src/providers/style';

const MicrosoftButton = ({login}: any) => {
  const styles = useStyle();

  return (
    <TouchableOpacity
      style={[
        styles.row,
        styles.justifyCenter,
        styles.alignCenter,
        styles.paddingLVertical,
        styles.borderRadiusM,
        styles.elevationSM,
        styles.backgroundColorWhite,
      ]}
      onPress={() => login()}>
      <SVG height={23} name={'microsoft'} />
      <Text
        style={[
          styles.paddingLLeft,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            color: '#757575',
            fontSize: 14,
            fontWeight: '600',
            textAlign: 'center',
            fontFamily:
              Platform.OS === 'web' ? 'Segoe UI Regular' : 'Helvetica Neue',
          },
        ]}>
        Login with Microsoft
      </Text>
    </TouchableOpacity>
  );
};

export default MicrosoftButton;
