export const RAVEN_WHITE = '#FFFFFF';
export const RAVEN_BLACK = '#000000';
export const RAVEN_GRAY = '#E6E7E8';

// GRAYSCALE
export const GRAY_50 = '#F5F5F5';
export const GRAY_100 = '#E9E9E9';
export const GRAY_200 = '#D9D9D9';
export const GRAY_300 = '#C4C4C4';
export const GRAY_400 = '#9D9D9D';
export const GRAY_500 = '#7B7B7B';
export const GRAY_600 = '#555555';
export const GRAY_700 = '#434343';
export const GRAY_800 = '#262626';
export const GRAY_900 = '#0A272B';

// PRIMARY
export const PRIMARY_50 = '#F4F5F5';
export const PRIMARY_100 = '#ECEEED';
export const PRIMARY_200 = '#E3E3E3';
export const PRIMARY_300 = '#C5C8C7';
export const PRIMARY_300_SESSION_PLAN = '#D0D0D0';
export const PRIMARY_400 = '#9D9D9D';
export const PRIMARY_500 = '#5E5F65';
export const PRIMARY_600 = '#555555';
export const PRIMARY_700 = '#25262F';
export const PRIMARY_800 = '#232323';
export const PRIMARY_900 = '#101010';

// SECONDARY
export const SECONDARY_200 = '#E9ECFC';
export const SECONDARY_300 = '#DBE0FF';
export const SECONDARY_400 = '#C3CCFF';
export const SECONDARY_600 = '#7D90FD';
export const SECONDARY_700 = '#0569F7';
export const SECONDARY_800 = '#3144F3';

// REDSCALE
export const TORCH_RED_LIGHT = '#FD8DA4';
export const TORCH_RED_REGULAR = '#F20F4B';
export const TORCH_RED_DARK = '#CD0048';
export const TORCH_RED_800 = '#B50A46';
export const TORCH_RED_300 = '#FFE4E9';

// BLUESCALE
export const BLUE_50 = '#E3F3FE';
export const BLUE_100 = '#BBE1FE';
export const BLUE_200 = '#8FCEFF';
export const BLUE_300 = '#61BBFE';
export const BLUE_400 = '#3AACFF';
export const BLUE_500 = '#009EFF';
export const BLUE_600 = '#008FF1';
export const BLUE_700 = '#0569F7';
export const BLUE_800 = '#006BCC';
export const BLUE_900 = '#004DAD';

// TEAL
export const TEAL_300 = '#D4F2F5';
export const TEAL_500 = '#56DBDB';
export const TEAL_600 = '#096F64';
export const TEAL_700 = '#006C73';

// LIME
export const LIME_300 = '#EAFDC1';
export const LIME_500 = '#B7F516';
export const LIME_700 = '#437700';

// TEXT - includes RAVEN_WHITE and RAVEN_BLACK
export const TEXT_PRIMARY = '#404040';
export const TEXT_PRIMARY_GRAY = '#232323';
export const TEXT_SECONDARY = '#616161';
export const TEXT_DISABLED = '#939393';
export const TEXT_TERTIARY = '#9E9E9E';
export const TEXT_BLACK = '#1A2121';

// STATUS
export const ERROR = '#F12E5D';
export const ERROR_LIGHT = '#FFB8B8';
export const ERROR_DARK = '#730000';
export const SUCCESS = '#4FF5AF';
export const SUCCESS_LIGHT = '#B8FFDF';
export const SUCCESS_DARK = '#0F5C4A';
export const INFO = '#10A9E1';
export const INFO_LIGHT = '#B8ECFF';
export const INFO_DARK = '#005473';
export const ALERT = '#F8D02F';
export const ALERT_LIGHT = '#FFF1B8';
export const ALERT_DARK = '#735C00';
export const WARNING_300 = '#FFDFBA';
export const WARNING_700 = '#985200';

// OPACITY STYLE
export const OPACITY_54 = 'rgba(0, 0, 0, 0.54)';
export const OPACITY_38 = 'rgba(0, 0, 0, 0.38)';
export const OPACITY_26 = 'rgba(0, 0, 0, 0.26)';
export const OPACITY_12 = 'rgba(0, 0, 0, 0.12)';
export const OPACITY_6 = 'rgba(0, 0, 0, 0.06)';

// WHITE OPACITY STYLE
export const WHITE_OPACITY_60 = 'rgba(255,255,255, 0.60)';

// PRIMARY COLORS
export const TORCH_RED = '#F20F4B';
export const BLUE_RIBBON = '#0569F7';

// SECONDARY_COLORS
export const ORANGE = '#F7AA4F';
export const ORANGE_300 = '#E07941';
export const BLAZE_ORANGE = '#FC7826';
export const HONEY_YELLOW = '#0569F7';
export const ELECTRIC_VIOLET = '#B400E0';
export const ELECTRIC_SALMON = '#FF2559';
export const CAPRI = '#0569F7';
export const FLUORESCENT_BLUE = '#06F0F0';
export const SPRING_GREEN = '#0569F7';
export const SESSION_PURPLE = '#8E0CAE';

// GRADIENTS
export const USER_GRADIENT = ['#0454C6', '#0454C6'];
export const ADMIN_GRADIENT = ['#0454C6', '#A200CA', '#DA0E44'];
export const DEFAULT_GRADIENT = ['#DA0E44', '#D85D10'];
export const TRIAL_GRADIENT = ['#0454C6', '#00A8E6'];

export const BASELINE = '#00BBFF';
export const BASELINE_LIGHT = '#B8FFFF';
export const BASELINE_DARK = '#007373';

export const IN_TREATMENT = '#004DAD';
export const IN_TREATMENT_LIGHT = '#D9D1FF';
export const IN_TREATMENT_DARK = '#130073';

export const IN_MAINTENANCE = '#B400E0';
export const IN_MAINTENANCE_LIGHT = '#F1B8FF';
export const IN_MAINTENANCE_DARK = '#5C0073';

export const COMPLETED = '#D400A6';
export const COMPLETED_LIGHT = '#B400E0';
export const COMPLETED_DARK = '#73005A';

export const TERTIARY_TEAL = '#037481';
export const TERTIARY_LAVENDER = '#353F95';
export const TERTIARY_SPRING_GREEN = '#029950';

// RUBY
export const RUBY_300 = '#F7B8C7';
export const RUBY_500 = '#F12E5D';
export const RUBY_700 = '#A31034';

// RUBY
export const SEAFOAM_300 = '#B4FFD6';
export const SEAFOAM_500 = '#4FF5AF';
export const SEAFOAM_700 = '#0F5C4A';

// ARCTIC
export const ARCTIC = '#4C9EFF';
export const ARCTIC_300 = '#B8DDFF';
export const ARCTIC_500 = '#09BEF8';
export const ARCTIC_700 = '#004096';

// VIOLET
export const VIOLET_300 = '#DFCDFC';
export const VIOLET_500 = '#AF70FF';
export const VIOLET_700 = '#530EC3';

export const LINK = '#3144F3';
