import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import FormInput from 'src/hook-form-wrapper/form-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
  label?: string;
}

interface Props {
  name?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

export const Import = (
  key: string = 'firstName',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'First Name',
  key,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value, label}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>
        {label ? label : translations('first_name')}
      </Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({
  name,
  required = false,
  disabled = false,
  label,
}: Props) => {
  const translations = useTranslations();

  return (
    <FormInput
      name={(name ? name + '.' : '') + 'firstName'}
      label={label ? label : translations('first_name')}
      placeholder={label ? label : translations('first_name')}
      helper={''}
      required={required}
      disabled={disabled}
    />
  );
};

export const validation = () => Yup.string().required('First name is required');

export default Input;
