import React, {Dispatch, SetStateAction} from 'react';
import {Modal} from 'src/design-system';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {Colors} from 'src/styles';
import {Controller, useForm} from 'react-hook-form';
import RadioInput from 'src/hook-form/radio-input';
import {useTranslations} from 'src/providers/translation';

interface Props {
  show: [any, Dispatch<SetStateAction<boolean>>];
  cancelled: () => void;
  onSubmit: (values: any) => void;
}

const EditRecurringAppointment = ({
  show: [show, setShow],
  cancelled,
  onSubmit,
}: Props) => {
  const translations = useTranslations();

  const {control, handleSubmit, reset} = useForm({});

  return (
    <Modal
      show={[show, setShow]}
      title={translations('edit_appointment')}
      footer={
        <>
          <RHButton
            secondary
            mode="outlined"
            textColor={Colors.RAVEN_BLACK}
            onPress={() => {
              reset();
              cancelled();
            }}>
            {translations('cancel_button')}
          </RHButton>
          <RHSeparator width={16} />
          <RHButton
            secondary
            mode="contained"
            onPress={handleSubmit(values => {
              onSubmit(values);
              reset();
            })}>
            {translations('yes_edit')}
            {translations('appointment')}
          </RHButton>
        </>
      }
      closeCallback={() => {
        reset();
        cancelled();
      }}>
      <Controller
        control={control}
        render={({field: {onChange, value}}) => (
          <RadioInput
            name={''}
            items={[
              {value: 'event', label: translations('this_event')},
              {
                value: 'following',
                label: translations('this_and_following_events'),
              },
              {value: 'all', label: translations('all_events')},
            ]}
            onChange={onChange}
            value={value}
          />
        )}
        name="action"
        defaultValue={'event'}
      />
    </Modal>
  );
};

export default EditRecurringAppointment;
