import React, {Dispatch, Fragment, SetStateAction} from 'react';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors} from 'src/styles';

interface Props {
  steps: {step: string; form: any; validated: boolean; touched: boolean}[];
  currentStep: [number, Dispatch<SetStateAction<number>>];
  onClickCallback?: (arg0: number) => void;
  watchedFirstName?: any;
  watchedLastName?: any;
}

const Stepper = ({
  steps,
  currentStep: [currentStep],
  onClickCallback = () => {},
}: Props) => {
  const styles = useStyle();
  return (
    <View style={[styles.width]}>
      <ScrollView
        horizontal={true}
        contentContainerStyle={[
          styles.row,
          styles.justifySpaceBetween,
          styles.alignCenter,
          styles.padding,
          styles.width,
        ]}>
        {steps.map((step: any, index: number) => {
          return (
            <Fragment key={`step-item-${index}`}>
              <TouchableOpacity
                style={[
                  styles.column,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}
                onPress={() => onClickCallback(index)}>
                <View
                  style={[
                    styles.height40,
                    styles.width40,
                    styles.column,
                    styles.alignCenter,
                    styles.justifyCenter,
                    styles.borderRadius20,
                    styles.border,
                    currentStep > index && step.validated
                      ? styles.borderColorSuccess
                      : currentStep === index
                      ? styles.borderColorSecondary800
                      : step.touched && !step.validated
                      ? styles.borderColorError
                      : styles.borderColorPrimary300,
                  ]}>
                  <Text
                    style={[
                      Typography.P3,
                      styles.paddingLHorizontal,
                      styles.paddingMVertical,
                      currentStep > index && step.validated
                        ? styles.textColorSuccess
                        : currentStep === index
                        ? styles.textColorSecondaryColor
                        : step.touched && !step.validated
                        ? styles.textColorError
                        : styles.textColorPrimary300,
                    ]}>
                    {step.touched && !step.validated ? (
                      <Icon
                        name={'exclamation-thick'}
                        size={20}
                        color={Colors.ERROR}
                      />
                    ) : (
                      index + 1
                    )}
                  </Text>
                </View>
                <View
                  style={[
                    styles.paddingSMVertical,
                    styles.paddingLHorizontal,
                    styles.borderRadius,
                    styles.marginMTop,
                    styles.marginMBottom,
                    currentStep > index && step.validated
                      ? styles.backgroundColorSuccess
                      : currentStep === index
                      ? styles.backgroundColorSecondary800
                      : step.touched && !step.validated
                      ? styles.backgroundColorError
                      : styles.backgroundColorGray300,
                  ]}>
                  <Text
                    style={[
                      Typography.LABEL,
                      currentStep > index && step.validated
                        ? styles.textColorPrimary
                        : currentStep === index
                        ? styles.textColorWhite
                        : styles.textColorWhite,
                    ]}>
                    {/* {currentStep > index
                  ? 'Complete'
                  : currentStep === index
                  ? 'In Progress'
                  : 'Pending'} */}
                    {step.step}
                  </Text>
                </View>
              </TouchableOpacity>
              {index + 1 !== steps.length ? (
                <View
                  style={[
                    styles.flex,
                    styles.borderRadius20,
                    styles.marginLHorizontal,
                    styles.height6,
                    currentStep > index
                      ? styles.backgroundColorSuccess
                      : currentStep === index
                      ? styles.backgroundColorPrimary300
                      : styles.backgroundColorPrimary300,
                  ]}
                />
              ) : null}
            </Fragment>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default Stepper;
