import React from 'react';
import {
  TouchableOpacity,
  Text,
  View,
  StyleSheet,
  Pressable,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import moment from 'moment';
import {useNavigation} from '@react-navigation/native';
import {MoreMenu} from 'src/design-system';
import {Colors, Typography} from 'src/styles';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {mergeMap, of} from 'rxjs';
import StaffParticipant from '../staff-participant';
import AppointmentCardButton from 'src/modules/scheduling/components/appointment-card-button';
import {Instance, Session} from 'src/models';
import _ from 'lodash';
import Location from 'src/modules/scheduling/components/location';

export enum APPOINTMENTCARDTYPE {
  DASHBOARD = 'DASHBOARD',
  CALENDAR = 'CALENDAR',
  SESSIONNOTEDUE = 'SESSIONNOTEDUE',
  PATIENT_CARD = 'PATIENT_CARD',
  USER_CARD = 'USER_CARD',
}
interface SessionCardProps {
  organization: any;
  appointment: any;
  session: any;
  sessions: any[];
  date: any;
  userId: any;
  patients: any[];
  users: any[];
  type?: any;
  sessionProps?: any;
  canEdit: boolean;
  canDelete: boolean;
  deleteCallback: (appointment: any, selectedDeleteDate: any) => void;
  currentStaffParticipant: any;
  previousCompletedSession: Session[];
}

export const RenderAppointment = compose(
  withDatabase,
  withState,
  withObservables([], ({appointment, authentication}: any) => ({
    appointment,
    patients: appointment.patients,
    users: appointment.staffParticipants,
    currentStaffParticipants: appointment
      .currentStaffParticipant(authentication.userId)
      .observe(),
  })),
  withObservables(
    [],
    ({database, authentication, patients, currentStaffParticipants}: any) => {
      const clientIds = _.map(patients, 'id');
      return {
        organization: database
          ?.get(Instance.table)
          .query(Q.where('_partition', authentication.selectedGroup))
          .observe()
          .pipe(mergeMap(x => x)),
        previousCompletedSession: database
          .get(Session.table)
          .query(
            Q.where('type', 'session'),
            Q.where('start_timestamp', Q.notEq(null)),
            Q.where('end_timestamp', Q.notEq(null)),
            Q.where('submission_timestamp', Q.notEq(null)),
            Q.where('deleted_at', null),
            Q.where('user_id', authentication?.userId),
            Q.where('patient_id', Q.oneOf(clientIds)),
            Q.sortBy('submission_timestamp', Q.desc),
            Q.take(1),
          ),
        currentStaffParticipant: currentStaffParticipants.length
          ? currentStaffParticipants[0]
          : of({}),
      };
    },
  ),
  withObservables(
    ['date'],
    ({database, date, appointment, currentStaffParticipant}: any) => ({
      sessions: database
        .get(Session.table)
        .query(
          Q.where(
            'type',
            currentStaffParticipant.supervision ? 'supervision' : 'session',
          ),
          Q.where('appointment_id', appointment.id),
          Q.where('session_date', moment(date).format('YYYY-MM-DD')),
          Q.where('deleted_at', null),
          Q.take(1),
        ),
    }),
  ),
)(
  ({
    type,
    organization,
    appointment,
    date,
    userId,
    sessions,
    session,
    patients,
    users,
    canEdit,
    canDelete,
    deleteCallback,
    currentStaffParticipant,
    previousCompletedSession,
  }: SessionCardProps) => {
    const navigation = useNavigation();
    if (!session) {
      session = sessions[0];
    }
    const isSupervision = currentStaffParticipant?.supervision;
    const formattedDate = moment(
      date ? date : session?.date ? session?.date : appointment?.date,
    ).format('ll');
    const formattedStartDate = moment(
      userId ? currentStaffParticipant.startTime : appointment?.start,
    ).format('h:mmA');
    const formattedEndDate = moment(
      userId ? currentStaffParticipant.endTime : appointment?.end,
    ).format('h:mmA');

    const editCallback = (app: any, d: Date) => {
      navigation.navigate('ScheduleAppointment', {
        id: app.id,
        date: d,
      });
    };

    let noteCompleted =
      session?.note !== undefined &&
      session?.note !== null &&
      session?.note !== '';

    const noteClk = () => {
      if (previousCompletedSession?.length > 0) {
        navigation.navigate('SessionRecap', {
          id: previousCompletedSession[0]?.id,
        });
      }
    };

    if (type === APPOINTMENTCARDTYPE.SESSIONNOTEDUE) {
      return (
        <Pressable
          onPress={() => {
            navigation.navigate('SessionMessage', {
              id: session.id,
            });
          }}>
          <View style={[styles.patientContainer]}>
            <View
              style={[
                styles.flex,
                styles.row,
                styles.justifySpaceBetween,
                styles.alignCenter,
              ]}>
              <Text style={[styles.flex, styles.schedule]}>
                {formattedDate} | {formattedStartDate} - {formattedEndDate}
              </Text>
              <View style={[styles.row]}>
                <AppointmentCardButton
                  appointment={appointment}
                  organization={organization}
                  patients={patients}
                  session={session}
                  date={date}
                  navigation={navigation}
                  isSupervision={isSupervision}
                />
              </View>
            </View>
            <Text style={styles.clinician}>Assigned Staff</Text>
            {users.map((participant: any, index: any) => (
              <StaffParticipant
                participant={participant}
                key={index}
                navigation={navigation}
              />
            ))}
            <Text style={styles.clinician}>Assigned Clients</Text>
            {patients.map((participant: any, index: any) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('PatientProfile', {
                      patientId: participant?.id,
                    });
                  }}
                  key={index}
                  style={[styles.row, styles.alignCenter]}>
                  <View style={[styles.icon]}>
                    <Icon name={'account-circle-outline'} size={22} />
                  </View>
                  <Text style={styles.clinicianName}>
                    {participant?.firstName} {participant?.lastName}
                  </Text>
                </TouchableOpacity>
              );
            })}

            <View style={styles.appointmentContainer}>
              <View style={styles.sessionNote}>
                {previousCompletedSession?.length > 0 && (
                  <TouchableOpacity onPress={noteClk}>
                    <SessionNotesIcon type={APPOINTMENTCARDTYPE.DASHBOARD} />
                  </TouchableOpacity>
                )}
              </View>
              <Location value={session?.location || appointment?.location} />
            </View>
            <View style={styles.row}>
              {!noteCompleted ? (
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('SessionMessage', {
                      id: session.id,
                    })
                  }
                  style={styles.mr_15}
                />
              ) : null}
              {!noteCompleted ? (
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('SessionMessage', {
                      id: session.id,
                    })
                  }>
                  <SessionNotesIcon type={type} />
                </TouchableOpacity>
              ) : null}
              {canDelete && (
                <TouchableOpacity
                  onPress={() => deleteCallback(appointment, date)}>
                  {/* <Icon
                    name={'trash-can'}
                    size={30}
                    color={Colors.PRIMARY_900}
                  /> */}
                </TouchableOpacity>
              )}
            </View>
          </View>
        </Pressable>
      );
    } else {
      return (
        <View style={styles.patientContainer}>
          <View
            style={[
              styles.flex,
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
            ]}>
            <Text style={[styles.flex, styles.schedule]}>
              {formattedDate} | {formattedStartDate} - {formattedEndDate}
            </Text>
            <View style={[styles.row]}>
              <AppointmentCardButton
                appointment={appointment}
                organization={organization}
                patients={patients}
                session={session}
                date={date}
                navigation={navigation}
                isSupervision={isSupervision}
              />
              <MoreMenu
                canEdit={canEdit}
                editCallback={() => editCallback(appointment, date)}
                canDelete={canDelete}
                deleteCallback={() => deleteCallback(appointment, date)}
              />
            </View>
          </View>
          <Text style={styles.clinician}>Assigned Staff</Text>
          {users.map((participant: any, index: any) => (
            <StaffParticipant
              participant={participant}
              key={index}
              navigation={navigation}
            />
          ))}
          <Text style={styles.clinician}>Assigned Clients</Text>
          {patients.map((participant: any, index: any) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('PatientProfile', {
                    patientId: participant?.id,
                  });
                }}
                key={index}
                style={[styles.row, styles.alignCenter]}>
                <View style={[styles.icon]}>
                  <Icon name={'account-circle-outline'} size={22} />
                </View>
                <Text style={styles.clinicianName}>
                  {participant?.firstName} {participant?.lastName}
                </Text>
              </TouchableOpacity>
            );
          })}
          <View style={styles.appointmentContainer}>
            <View style={styles.sessionNote}>
              {previousCompletedSession?.length > 0 && (
                <TouchableOpacity onPress={noteClk}>
                  <SessionNotesIcon type={APPOINTMENTCARDTYPE.DASHBOARD} />
                </TouchableOpacity>
              )}
            </View>
            <Location value={session?.location || appointment?.location} />
          </View>
        </View>
      );
    }
  },
);

interface SessionNotesIconProps {
  type: APPOINTMENTCARDTYPE;
}

export const SessionNotesIcon = ({type}: SessionNotesIconProps) => {
  switch (type) {
    case APPOINTMENTCARDTYPE.DASHBOARD:
      return <Icon name="clipboard-text-clock-outline" size={27} />;
    case APPOINTMENTCARDTYPE.CALENDAR:
      return (
        <>
          {/* <separator width={20} />
          <Icon name="clipboard-text-outline" size={25} />
          <Icon
            style={styles.alertIcon}
            name="alert-circle"
            size={18}
            color={Colors.TORCH_RED_DARK}
          /> */}
        </>
      );
    case APPOINTMENTCARDTYPE.SESSIONNOTEDUE:
      return (
        <View style={[styles.row, styles.alignCenter]}>
          {/* <Icon name="clipboard-text-outline" size={30} />
          <Icon
            style={styles.alertIcon}
            name="alert-circle"
            size={16}
            color={Colors.TORCH_RED_DARK}
          /> */}
        </View>
      );
  }
};

export const styles = StyleSheet.create({
  flex: {flex: 1},
  row: {flexDirection: 'row'},
  alignCenter: {alignItems: 'center'},
  justifySpaceBetween: {justifyContent: 'space-between'},
  alertIcon: {
    position: 'relative',
    top: -9,
    right: 11,
  },
  patientContainer: {
    flex: 1,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: '#E5E5E5',
  },
  icon: {
    marginRight: 6,
  },
  patientName: {
    ...Typography.P3_BOLD,
    marginBottom: 4,
  },
  schedule: {
    ...Typography.SECONDARY_BUTTON,
    flexWrap: 'wrap',
    paddingRight: 12,
  },
  sessionNote: {
    width: 27,
    height: 27,
    marginRight: 7,
  },
  clinician: {
    ...Typography.H6,
    marginTop: 4,
  },
  clinicianName: {
    ...Typography.P3,
  },
  appointmentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
  },
  virtualButton: {
    flexDirection: 'row',
    backgroundColor: Colors.RUBY_300,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 26,
  },
  virtualText: {
    ...Typography.P3_MEDIUM,
    fontSize: 14,
    fontWeight: '500',
    color: Colors.RUBY_700,
    marginLeft: 4,
  },
  officeButton: {
    flexDirection: 'row',
    backgroundColor: Colors.LIME_300,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 26,
  },
  officeText: {
    ...Typography.P3_MEDIUM,
    fontSize: 14,
    fontWeight: '500',
    color: Colors.LIME_700,
    marginLeft: 4,
  },
  homeButton: {
    flexDirection: 'row',
    backgroundColor: Colors.TEAL_300,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 26,
  },
  homeText: {
    ...Typography.P3_MEDIUM,
    fontSize: 14,
    fontWeight: '500',
    color: Colors.TEAL_700,
    marginLeft: 4,
  },
  schoolButton: {
    flexDirection: 'row',
    backgroundColor: Colors.ARCTIC_300,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 26,
  },
  schoolText: {
    ...Typography.P3_MEDIUM,
    fontSize: 14,
    fontWeight: '500',
    color: Colors.ARCTIC_700,
    marginLeft: 4,
  },
  communityButton: {
    flexDirection: 'row',
    backgroundColor: Colors.VIOLET_300,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 26,
  },
  communityText: {
    ...Typography.P3_MEDIUM,
    fontSize: 14,
    fontWeight: '500',
    color: Colors.VIOLET_700,
    marginLeft: 4,
  },
  alertIconDashboard: {
    position: 'absolute',
    top: -4,
    right: -3,
  },
  supervisionContainer: {
    backgroundColor: '#FCF1FF',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
    paddingHorizontal: 6,
    borderRadius: 15,
    marginTop: 6,
  },
  supervision: {
    ...Typography.H8,
    fontWeight: '500',
    color: '#B400E0',
  },
  mr_15: {
    marginRight: 15,
  },
  launchButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
