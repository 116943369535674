import React, {useState} from 'react';
import {
  Duration,
  Frequency,
  Interval,
  Rate,
} from 'src/modules/session/components';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {TrackerType} from 'src/modules/session/types';

const Behavior = ({program, session}: any) => {
  const [currentSetId, setCurrentSetId] = useState<string>('');
  return (
    <>
      {program?.method === TrackerType.FREQUENCY ? (
        <Frequency program={program} session={session} />
      ) : program?.method === TrackerType.RATE ? (
        <Rate program={program} session={session} />
      ) : program?.method === TrackerType.DURATION ? (
        <Duration program={program} session={session} />
      ) : program?.method === TrackerType.INTERVAL ? (
        <Interval
          program={program}
          session={session}
          currentSetId={currentSetId}
          setCurrentSetId={setCurrentSetId}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({sessionProgram}: any) => {
    return {
      program: sessionProgram.program,
    };
  }),
)(Behavior);
