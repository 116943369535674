import {relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class InstanceDiagnosis extends Base {
  static table = 'instance_diagnoses';
  static associations = {
    instance: {type: 'belongs_to', key: 'instance_id'},
  };

  @relation('instances', 'instance_id') instance;

  @text('instance_id') instanceId;
  @text('diagnosis') diagnosis;
  @text('name') name;
}
