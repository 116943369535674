import React from 'react';
import DromoReactUploader, {
  IColumnHook,
  IDeveloperField,
  IRowHook,
} from 'dromo-uploader-react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors} from 'src/styles';
import {IResultMetadata} from 'dromo-uploader-react/dist/interfaces';
import {useSelector} from 'react-redux';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';

const importButton: React.CSSProperties = {
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colors.RAVEN_WHITE,
  borderRadius: 22,
  width: 44,
  height: 44,
  padding: 0,
  margin: 0,
  borderWidth: 1,
  borderColor: Colors.RAVEN_BLACK,
};

const importItem: React.CSSProperties = {
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colors.RAVEN_WHITE,
  padding: 0,
  margin: 0,
  borderWidth: 0,
  borderColor: Colors.RAVEN_BLACK,
};

interface Props {
  fields: IDeveloperField[];
  rowHooks?: IRowHook[];
  columnHooks?: IColumnHook[];
  identifier: string;
  onSubmit?: (data: any, metadata: IResultMetadata) => void;
  onCancel?: () => void;
  templateDownloadFilename: string;
  profile: any;
  anchor?: React.ReactNode;
  style?: string;
  helpText?: string;
}

const DromoImportModal = ({
  fields,
  columnHooks,
  rowHooks,
  identifier,
  onSubmit = (data: any) => console.log('Response:', data),
  templateDownloadFilename,
  profile,
  onCancel,
  anchor = <Icon name="upload" size={22} color={Colors.RAVEN_BLACK} />,
  style = 'button',
  helpText = '',
}: Props) => {
  const {selectedGroup} = useSelector(state => state.authentication);

  return (
    <>
      <DromoReactUploader
        licenseKey="59a3ab3f-4fb4-41dc-b4fc-ffcecdb70fdc"
        fields={fields}
        settings={{
          importIdentifier: identifier,
          developmentMode: __DEV__,
          manualInputDisabled: false,
          styleOverrides: {
            global: {
              textColor: Colors.TEXT_PRIMARY,
              primaryTextColor: Colors.TEXT_PRIMARY,
              secondaryTextColor: Colors.TEXT_SECONDARY,
              successColor: Colors.SUCCESS,
              warningColor: Colors.ERROR,
              customFontFamily: 'ReneBieder-CamptonLight',
            },
            primaryButton: {
              borderRadius: '40px',
              backgroundColor: Colors.RAVEN_BLACK,
              textColor: Colors.RAVEN_WHITE,
              border: `1px solid ${Colors.RAVEN_BLACK}`,
            },
            secondaryButton: {
              borderRadius: '40px',
              backgroundColor: Colors.RAVEN_WHITE,
              textColor: Colors.RAVEN_BLACK,
              border: `1px solid ${Colors.RAVEN_BLACK}`,
            },
            tertiaryButton: {
              borderRadius: '40px',
              backgroundColor: Colors.RAVEN_WHITE,
              textColor: Colors.RAVEN_BLACK,
              border: `1px solid ${Colors.RAVEN_BLACK}`,
            },
            dropzone: {
              backgroundColor: Colors.RAVEN_WHITE,
              borderColor: Colors.PRIMARY_200,
            },
            helpText: {
              textColor: Colors.TEXT_PRIMARY,
              backgroundColor: Colors.PRIMARY_200,
            },
            stepperBar: {
              completeColor: Colors.SUCCESS,
            },
          },
          backendSync: false,
          autoMapHeaders: true,
          templateDownloadFilename: templateDownloadFilename,
          allowCustomFields: false,
          allowInvalidSubmit: false,
          uploadStep: {
            helpText: helpText,
          },
        }}
        columnHooks={columnHooks}
        rowHooks={rowHooks}
        user={{
          id: profile?.id,
          name: `${profile?.firstName} ${profile?.lastName}`,
          email: `${profile?.email}`,
          companyId: selectedGroup,
        }}
        onResults={onSubmit}
        onCancel={onCancel ? () => onCancel() : undefined}
        style={style === 'button' ? importButton : importItem}>
        {anchor}
      </DromoReactUploader>
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
)(DromoImportModal);
