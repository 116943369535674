import React, {useRef} from 'react';
import {Model, Q} from '@nozbe/watermelondb';
import {Appointment, Participant, Session} from 'src/models';
import moment from 'moment/moment';
import {IDeveloperField, IRowHook} from 'dromo-uploader-react';
import {MaskService} from 'react-native-masked-text';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import {View} from 'react-native';
import DromoImportModal from 'src/common-components/bulk-import';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {
  Patient,
  Staff,
  AppointmentLocation,
  AppointmentType,
  Date as DateInput,
  StartTime,
} from 'src/form-inputs';

const SchedulingImport = ({patients, users}: any) => {
  const database = useDatabase();
  const styles = useStyle();

  const importRef = useRef();
  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const upload = async (data: any[]) => {
    const operations: Model[] = [];
    for (const d of data) {
      const createAppointment = database
        ?.get(Appointment.table)
        .prepareCreate(entity => {
          entity.partition = selectedGroup;
          entity.location = d.appointmentLocation;
          entity.type = d.appointmentType;
          entity.date = d.date;
          entity.start = moment(d.date + ' ' + d.startTime).toDate();
          entity.end = moment(d.date + ' ' + d.endTime).toDate();
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      operations.push(createAppointment);
      operations.push(
        database?.get(Participant.table).prepareCreate(entity => {
          entity.partition = selectedGroup;
          entity.billable = true;
          entity.appointment.id = createAppointment.id;
          entity.user.id = d.staff;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        }),
      );
      operations.push(
        database?.get(Participant.table).prepareCreate(entity => {
          entity.partition = selectedGroup;
          entity.appointment.id = createAppointment.id;
          entity.patient.id = d.patient;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        }),
      );
      await database?.get(Session.table).create(entity => {
        entity.partition = selectedGroup;
        entity.type = 'session';
        entity.date = d.date;
        entity.sessionDate = moment(d.date).format('YYYY-MM-DD');
        entity.startTimestamp = null;
        entity.endTimestamp = null;
        entity.editedStartTimestamp = null;
        entity.editedEndTimestamp = null;
        entity.note = '';
        entity.therapistSignature = null;
        entity.therapistSignature2 = null;
        entity.parentSignature1 = null;
        entity.parentSignature2 = null;
        entity.attestationTimestamp = null;
        entity.submissionTimestamp = null;
        entity.appointment.id = createAppointment.id;
        entity.patient.id = d.patient;
        entity.user.id = null;
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
    }
    await database?.write(async () => {
      await database?.batch(...operations);
    });
    // if (result) {
    //   setCreatedAppointments(data);
    // }
  };

  const Import: (...args: any) => IDeveloperField[] = (
    patientList: any[],
    practitionerList: any[],
  ): IDeveloperField[] => {
    return [
      Patient.Import('patient', patientList),
      Staff.Import('staff', practitionerList),
      AppointmentLocation.Import('appointmentLocation'),
      AppointmentType.Import('appointmentType'),
      DateInput.Import('date'),
      StartTime.Import('startTime', 'Start'),
      StartTime.Import('endTime', 'End'),
    ];
  };

  const RowHooks: IRowHook[] = [
    record => {
      const newRecord = record;

      newRecord.row.date.value = MaskService.toMask(
        'datetime',
        newRecord.row.date.value,
        {
          format: 'MM/DD/YYYY',
        },
      );

      newRecord.row.startTime.value = MaskService.toMask(
        'datetime',
        newRecord.row.startTime.value,
        {
          format: 'HH:mm',
        },
      );

      newRecord.row.endTime.value = MaskService.toMask(
        'datetime',
        newRecord.row.endTime.value,
        {
          format: 'HH:mm',
        },
      );

      return newRecord;
    },
  ];

  return (
    <View style={[styles.hide]}>
      <DromoImportModal
        fields={Import(patients, users)}
        rowHooks={RowHooks}
        onSubmit={upload}
        identifier={'Appointment'}
        anchor={<View ref={ref => (importRef.current = ref)} />}
        templateDownloadFilename={'appointment_import'}
      />
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({profile, database, authentication, role}: any) => ({
    patients: !role?.patientAssignedOnly
      ? database
          .get('patients')
          .query(
            Q.where('deleted_at', null),
            Q.where('state', 'active'),
            Q.where('_partition', authentication.selectedGroup),
          )
      : database
          .get('patients')
          .query(
            Q.on(
              'care_team_participants',
              Q.and(
                Q.where('user_id', Q.eq(profile.id)),
                Q.where('deleted_at', null),
              ),
            ),
            Q.where('deleted_at', null),
            Q.where('_partition', authentication.selectedGroup),
          ),
    users: database
      .get('users')
      .query(
        Q.where('deleted_at', null),
        Q.where('state', 'active'),
        Q.where('_partition', authentication.selectedGroup),
      ),
  })),
)(SchedulingImport);
