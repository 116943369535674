import {Text, TouchableOpacity, View} from 'react-native';
import React, {useState} from 'react';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {ServiceLine, Session} from 'src/models';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {Colors, Typography} from 'src/styles';
import moment from 'moment';
import ListItemSeparator from 'src/common-components/separator';
import {Separator} from 'src/common-components/atoms';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Modal} from 'src/design-system';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';

const ClaimListItem = ({
  item,
  sessions,
  patient,
  insurance,
  onPress,
  services,
  events,
  confirmDelete,
}: any) => {
  const styles = useStyle();
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const calculateTotals = (sessionsArray: any[], servicesArray: any[]) => {
    let totalUnits = 0;
    let totalAmount = 0;

    for (const session of sessionsArray) {
      const durationInMins = moment(session.editedEndTimestamp).diff(
        session.editedStartTimestamp,
        'minutes',
      );
      totalUnits += Math.ceil(durationInMins / 15);
    }

    for (const service of servicesArray) {
      totalAmount += parseFloat(service.amount || '0');
    }

    const formattedNumber = totalAmount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return {totalUnits, totalAmount: formattedNumber};
  };

  const {totalAmount} = calculateTotals(sessions, services);

  return (
    <>
      <Modal
        show={[showDelete, setShowDelete]}
        title={'DELETE CLAIM'}
        footer={
          <>
            <RHButton
              secondary
              mode="outlined"
              textColor={Colors.RAVEN_BLACK}
              onPress={() => setShowDelete(false)}>
              CANCEL
            </RHButton>
            <RHSeparator width={16} />
            <RHButton
              secondary
              mode="contained"
              onPress={async () => confirmDelete(item, sessions, services)}>
              YES, DELETE
            </RHButton>
          </>
        }>
        <Text style={[Typography.P1]}>
          Are you sure you want to delete this Claim?
        </Text>
      </Modal>
      <TouchableOpacity onPress={onPress}>
        <Separator height={10} />
        <View style={[styles.flex, styles.row]}>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>
              {item.kafka}
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>
              {item.status === 'unsubmitted'
                ? moment(item.createdAt).format('MM/DD/YYYY - hh:mm A')
                : moment(item.updatedAt).format('MM/DD/YYYY - hh:mm A')}
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>
              {patient.firstName} {patient.lastName}
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>
              {insurance?.name}
            </Text>
          </View>
          <View style={[styles.flex, styles.column]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>
              {services.length}
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>
              {totalAmount}
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>$0.00</Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>
              {moment(item.createdAt).format('MM/DD/YYYY - hh:mm A')}
            </Text>
          </View>
          <View style={[styles.flex]}>
            <Text style={[Typography.P3, styles.textColorPrimary]}>
              {events.length ? events[0]?.type : 'Not Submitted'}
            </Text>
          </View>
          {item.status === 'unsubmitted' ? (
            <View style={[styles.flex05]}>
              <TouchableOpacity style={[]} onPress={() => setShowDelete(true)}>
                <Icon name={'trash-can'} size={20} color={Colors.PRIMARY_900} />
              </TouchableOpacity>
            </View>
          ) : (
            <View style={[styles.flex05]} />
          )}
        </View>
        <Separator height={10} />
        <ListItemSeparator />
      </TouchableOpacity>
    </>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({database, item}: any) => ({
    sessions: database
      .get(Session.table)
      .query(Q.where('claim_id', Q.eq(item.id))),
    patient: item.patient,
    services: database
      .get(ServiceLine.table)
      .query(Q.where('claim_id', Q.eq(item.id))),
    events: item.claimEvents,
  })),
  withObservables([], ({patient}: any) => ({
    insurances: patient.activeInsurances,
  })),
  withObservables([], ({insurances}: any) => ({
    insurance: insurances.length > 0 ? insurances?.[0].payer : of({}),
  })),
)(ClaimListItem);
