import React, {useState} from 'react';
import * as Yup from 'yup';
import {appointmentLocations} from 'src/form-inputs/appointment-location-input';
import CheckboxArrayInput from 'src/design-system/checkbox-array-input';
import ListItemSeparator from 'src/common-components/separator';
import {Filters, Form} from 'src/design-system';
import {Patient} from 'src/models';
import {useTranslations} from 'src/providers/translation';

const NotesDueFilterMenu = ({setFilters, filters, clients, staff}: any) => {
  const translations = useTranslations();

  const validationSchema = Yup.object({});
  const initialValues = filters;

  const [visible, setVisible] = useState<boolean>(false);

  const clientArr = clients.map((patient: Patient) => ({
    value: patient?.id,
    title: `${patient?.firstName} ${patient?.lastName}`,
  }));
  const staffArr = staff.map((_staff: any) => ({
    value: _staff?.id,
    title: `${_staff?.firstName} ${_staff?.lastName}`,
  }));
  const locationArr = appointmentLocations.map((_location: any) => ({
    value: _location?.value,
    title: _location?.label,
  }));

  const notificationNumberCount = () => {
    let result = 0;
    if (filters === undefined) {
      return 0;
    }
    for (const key in filters) {
      result += filters[key].length;
    }
    return result;
  };

  const filterSubmit = (payload: any) => {
    setFilters(payload);
    setVisible(false);
  };

  const filterCancelled = () => {
    setFilters({
      clients: [],
      staff: [],
      locations: [],
    });
    setVisible(false);
  };

  return (
    <Filters
      open={visible}
      setOpen={setVisible}
      notificationNumberCount={notificationNumberCount}
      onDismiss={filterCancelled}>
      <Form
        handleSubmit={filterSubmit}
        cancelled={filterCancelled}
        initialValues={initialValues}
        validationSchema={validationSchema}
        saveText={translations('apply')}
        cancelText={translations('reset')}>
        <CheckboxArrayInput
          valueKey={'clients'}
          title={translations('client')}
          items={clientArr}
        />
        <ListItemSeparator />
        <CheckboxArrayInput
          valueKey={'staff'}
          title={translations('staff')}
          items={staffArr}
        />
        <ListItemSeparator />
        <CheckboxArrayInput
          valueKey={'locations'}
          title={translations('place_of_service')}
          items={locationArr}
        />
      </Form>
    </Filters>
  );
};

export default NotesDueFilterMenu;
