import React from 'react';
import {DataItem, SelectInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {countries} from 'src/common-utils/countries';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const Import = (
  key: string = 'country',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Country',
  key,
  type: 'select',
  selectOptions: countries(),
});

const resolveDisplayValue = (value: string) => {
  return countries().find(item => item.value === value)?.label;
};

const CountryDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Country</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const CountryInput = ({valueKey}: Props) => {
  return (
    <SelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'country'}
      name={'Country'}
      required={false}
      items={countries()}
    />
  );
};

export const validation = () => Yup.string().required('Country is required');

export const Display = CountryDisplay;
export const Input = CountryInput;
export const Value = resolveDisplayValue;

export default CountryInput;
