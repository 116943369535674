import React, {useState} from 'react';
import _ from 'lodash';
import {Text, TouchableOpacity, View} from 'react-native';
import {Divider} from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  ContactInformation,
  Sessions,
  Technicians,
} from 'src/modules/patients/components';
import {Colors, Typography} from 'src/styles';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {MoreMenu} from 'src/design-system';
import Collapsible from 'react-native-collapsible';
import {getAge} from 'src/common-utils/age';
import {Display as BirthDateDisplay} from 'src/hook-form-inputs/birth-date';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useStyle} from 'src/providers/style';
import {Tags} from 'src/hook-form-inputs';

interface Props {
  patient: any;
  staff: any;
  careTeam: any[];
  tags: any[];
  viewCallback: () => void;
  canEdit: boolean;
  editCallback: () => void;
  canDelete: boolean;
  deleteCallback: () => void;
  canArchive: boolean;
  archiveCallback?: () => void;
}

const PatientCard = ({
  patient,
  careTeam,
  tags,
  viewCallback,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
  canArchive,
  archiveCallback,
}: Props) => {
  const styles = useStyle();
  const [expansionContent, setExpansionContent] = useState<string | null>(null);
  const [collapsed, setCollapsed] = useState(true);

  const toggle = (identifier: string) => {
    if (expansionContent === identifier) {
      setExpansionContent(null);
      setCollapsed(true);
    } else {
      setExpansionContent(identifier);
      setCollapsed(false);
    }
  };
  const firstName = patient?.firstName;
  const lastName = patient?.lastName;
  const age = getAge(patient?.birthDate);
  const gender = patient?.gender;
  const firstInitial = firstName?.length > 0 ? firstName[0].toUpperCase() : 'N';
  const lastInitial = lastName?.length > 0 ? lastName[0].toUpperCase() : 'N';
  const initials = firstInitial + lastInitial;
  const secondLineDisplay = `Age ${age}, ${_.capitalize(gender)}`;

  return (
    <View style={[styles.cardColumn]}>
      <View style={[styles.card]}>
        <View style={[styles.overflowHidden]}>
          <TouchableOpacity onPress={viewCallback} style={[styles.padding]}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={[styles.row, styles.alignCenter]}>
                <RHAvatar
                  label={initials}
                  color={Colors.RAVEN_BLACK}
                  textColor={Colors.RAVEN_WHITE}
                  size={32}
                />
                <View style={[styles.paddingLLeft, styles.justifyCenter]}>
                  <Text
                    style={[
                      Typography.P3,
                      styles.textColorPrimary,
                    ]}>{`${firstName} ${lastName}`}</Text>
                  <Text style={[Typography.P3, styles.textColorSecondary]}>
                    {secondLineDisplay}
                  </Text>
                </View>
              </View>

              <MoreMenu
                canEdit={canEdit}
                editCallback={editCallback}
                canDelete={canDelete}
                deleteCallback={deleteCallback}
                canArchive={canArchive}
                archiveTxt={
                  patient?.state !== 'archived' ? 'Archive' : 'Unarchive'
                }
                archiveCallback={archiveCallback}
              />
            </View>
            <View style={[styles.paddingTop]}>
              <BirthDateDisplay value={patient?.birthDate} />
              <Tags.CardDisplay value={tags.map((tag: any) => tag?.id)} />
            </View>
          </TouchableOpacity>
          <Divider />
          <View style={[styles.row, styles.justifySpaceBetween]}>
            <TouchableOpacity
              onPress={() => toggle('technicians')}
              style={[styles.padding]}>
              <View style={[styles.row, styles.alignCenter]}>
                <Icon
                  size={24}
                  name="account"
                  color={
                    expansionContent === 'technicians'
                      ? Colors.GRAY_500
                      : Colors.TEXT_PRIMARY
                  }
                />
                <Text style={[styles.textColorSecondary]}>
                  {careTeam?.length || 0}
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => toggle('contactInformation')}
              style={[styles.padding]}>
              <Icon
                size={24}
                name="card-account-phone"
                color={
                  expansionContent === 'contactInformation'
                    ? Colors.GRAY_500
                    : Colors.TEXT_PRIMARY
                }
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => toggle('schedule')}
              style={[styles.padding]}>
              <Icon
                size={24}
                name="calendar-range"
                color={
                  expansionContent === 'schedule'
                    ? Colors.GRAY_500
                    : Colors.TEXT_PRIMARY
                }
              />
            </TouchableOpacity>
          </View>
          <Collapsible collapsed={collapsed} style={[styles.paddingHorizontal]}>
            {expansionContent === 'technicians' ? (
              <Technicians technicians={careTeam} />
            ) : null}
            {expansionContent === 'contactInformation' ? (
              <ContactInformation patient={patient} />
            ) : null}
            {expansionContent === 'schedule' ? (
              <Sessions patient={patient} />
            ) : null}
          </Collapsible>
        </View>
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({patient}: any) => ({
    patient,
    staff: patient.staff,
    careTeam: patient.careTeam,
    tags: patient.activeTags,
  })),
)(PatientCard);
