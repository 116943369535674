export const fuzzy = (input, search) => {
  var hay = input.toLowerCase(),
    i = 0,
    n = -1,
    l;
  search = search.toLowerCase();
  for (; (l = search[i++]); ) {
    // eslint-disable-next-line no-bitwise
    if (!~(n = hay.indexOf(l, n + 1))) {
      return false;
    }
  }
  return true;
};
