import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {countries} from 'src/common-utils/countries';
import SelectInput from 'src/hook-form-wrapper/select-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name: string;
}

export const Import = (
  key: string = 'country',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Country',
  key,
  type: 'select',
  selectOptions: countries(),
});

export const Value = (value: string) => {
  return countries().find(item => item.value === value)?.label;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Country</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  return (
    <SelectInput
      name={(name ? name + '.' : '') + 'country'}
      label={'Country'}
      required={false}
      items={countries()}
    />
  );
};

export const validation = () => Yup.string().required('Country is required');

export default Input;
