import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormRadioInput from 'src/hook-form-wrapper/radio-input';

interface DisplayProps {
  value: any;
}

interface Props {
  name?: string;
  method?: string;
  type?: string;
  defaultValue?: any;
}

const adjustments = [
  {value: 'increase', label: 'Increase'},
  {value: 'decrease', label: 'Decrease'},
];

export const Import = (
  key: string = 'unit',
  prefix?: string,
  items?: {value: any; label: string}[],
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Unit',
  key,
  type: 'select',
  selectOptions: items.map(item => ({
    ...item,
    value: item.value.toString(),
  })),
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: any) => {
  return adjustments.find(item => item.value === value)?.label;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.P3_BOLD]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, type}: Props) => {
  return (
    <HookFormRadioInput
      name={(name ? name + '.' : '') + type}
      label={''}
      required={false}
      items={adjustments}
    />
  );
};

export const validation = () => Yup.string().required('Adjustemnt is required');

export default Input;
