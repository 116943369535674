import React from 'react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {InstanceDiagnosis} from 'src/models';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {useTranslations} from 'src/providers/translation';

const DiagnosisPointer = ({name, items, label}: any) => {
  const translations = useTranslations();

  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + '.diagnosisPointer'}
      label={label ? label : translations('diagnosis_pointer')}
      items={items}
      searchable={false}
      multiple={false}
      showHelper={false}
    />
  );
};

export default compose(
  withDatabase,
  withObservables(['diagnoses'], ({database, diagnoses}: any) => {
    const ids = diagnoses.map(diagnosis => diagnosis.instanceDiagnosisId);
    return {
      instanceDiagnoses: database
        .get(InstanceDiagnosis.table)
        .query(Q.where('id', Q.oneOf(ids))),
    };
  }),
  withObservables(['instanceDiagnoses'], ({instanceDiagnoses}: any) => {
    return {
      items: of(
        instanceDiagnoses.map((diagnosis: any, i: number) => ({
          value: `${i + 1}`,
          label: `${i + 1} - ${diagnosis.diagnosis}`,
        })),
      ),
    };
  }),
)(DiagnosisPointer);
