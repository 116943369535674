import React, {Dispatch, SetStateAction} from 'react';
import {Modal} from 'src/design-system';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';

interface Props {
  show: [boolean, Dispatch<SetStateAction<boolean>>];
}

const NotDraggableModal = ({show: [show, setShow]}: Props) => {
  return (
    <Modal
      show={[show, setShow]}
      title={'Unable to Move Appointment'}
      footer={
        <RHButton secondary mode="contained" onPress={() => setShow(false)}>
          {'OKAY'}
        </RHButton>
      }>
      <Text style={Typography.P1}>
        This appointment cannot be changed because it is currently in progress
        or has been completed.
      </Text>
    </Modal>
  );
};

export default NotDraggableModal;
