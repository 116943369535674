import {CommonActions} from '@react-navigation/native';

export const replace = (routeName: string, params: any) => (state: any) => {
  const history = state.history;

  history.splice(-1);

  CommonActions.reset({
    ...state,
    history,
  });

  return CommonActions.navigate(routeName, params);
};

export default replace;
