import React, {useState} from 'react';
import {View, Text, TouchableOpacity, Platform} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, Typography} from 'src/styles';
import {ProgramMethodAvatar} from 'src/modules/programs/components';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import _ from 'lodash';
import Collapsible from 'react-native-collapsible';
import {RenderItemParams} from 'react-native-draggable-flatlist';
import SessionTargetListItem from '../session-target-list-item';
import {Separator} from 'src/common-components/atoms';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {
  NestableScrollContainer,
  NestableDraggableFlatList,
} from 'react-native-draggable-flatlist';
import {useStyle} from 'src/providers/style';

interface ProgramProps {
  drag: () => void;
  navigate: () => void;
  editing: boolean;
  sessionProgram: any;
  program: any;
  sessionTargets: any[];
  isActive: boolean;
}

const SessionSkillRow = ({
  sessionProgram,
  drag,
  // navigate,
  program,
  sessionTargets,
}: ProgramProps) => {
  const styles = useStyle();

  const [open, setOpen] = useState<boolean>(false);

  const toggleEnabled = () => {
    sessionProgram.updateEntity({
      enabled: !sessionProgram.enabled,
    });
  };
  const isWeb = Platform.OS === 'web';
  return (
    <View style={[styles.card]}>
      <View
        style={[
          styles.row,
          styles.flex,
          styles.alignCenter,
          styles.backgroundColorWhite,
        ]}>
        <TouchableOpacity
          onPressIn={sessionProgram.enabled && isWeb ? drag : () => {}}
          onLongPress={sessionProgram.enabled ? drag : () => {}}
          delayLongPress={50}>
          <Icon
            style={[styles.paddingL, styles.paddingLeft]}
            name="reorder-horizontal"
            size={20}
            color={
              sessionProgram.enabled ? Colors.GRAY_300 : Colors.RAVEN_WHITE
            }
          />
        </TouchableOpacity>
        <TouchableOpacity style={[styles.paddingL]} onPress={toggleEnabled}>
          <Icon
            name={
              sessionProgram.enabled
                ? 'checkbox-marked'
                : 'checkbox-blank-outline'
            }
            size={20}
            color={
              sessionProgram.enabled ? Colors.PRIMARY_900 : Colors.TEXT_DISABLED
            }
          />
        </TouchableOpacity>
        <View
          style={[
            styles.alignCenter,
            styles.justifyCenter,
            styles.marginLRight,
            styles.paddingL,
          ]}>
          <ProgramMethodAvatar type={program?.method} />
        </View>
        <View style={[styles.row, styles.flex]}>
          <View style={[styles.column]}>
            <Text
              style={[
                Typography.P3_MEDIUM,
                sessionProgram.enabled
                  ? styles.textColorPrimary
                  : styles.textColorDisabled,
              ]}>
              {program.name}
            </Text>
            <Text
              style={[
                Typography.CAPTION,
                sessionProgram.enabled
                  ? styles.textColorSecondary
                  : styles.textColorDisabled,
              ]}>
              {_.capitalize(program?.type) || ''} |{' '}
              {_.split(program?.method, '_')
                .map(value => _.capitalize(value))
                .join(' ')}
            </Text>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => setOpen(!open)}
          style={[styles.padding]}>
          <Icon
            name={open ? 'chevron-down' : 'chevron-right'}
            size={20}
            color={Colors.RAVEN_BLACK}
          />
        </TouchableOpacity>
      </View>

      <Collapsible collapsed={!open} renderChildrenCollapsed={false}>
        <NestableScrollContainer persistentScrollbar={true}>
          <NestableDraggableFlatList
            nestedScrollEnabled={true}
            scrollEnabled={true}
            data={sessionTargets}
            renderItem={({item}: RenderItemParams<any>) => (
              <SessionTargetListItem key={item?.id} sessionTargetId={item.id} />
            )}
            keyExtractor={(item, index) =>
              `draggable-session-target-${item?.id}-${index}`
            }
          />
          <Separator height={12} />
        </NestableScrollContainer>
      </Collapsible>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({sessionProgramId, database}: any) => ({
    sessionProgram: database
      .get('session_programs')
      .findAndObserve(sessionProgramId),
  })),
  withObservables([], ({sessionProgram}: any) => ({
    program: sessionProgram.program,
    sessionTargets: sessionProgram.sessionTargets,
  })),
)(SessionSkillRow);
