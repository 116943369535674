import React, {useState} from 'react';
import {Text, View} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import * as Yup from 'yup';
import {memoize} from 'lodash';
import {DateInput, FilterModal} from 'src/design-system';
import {Separator} from 'src/common-components/atoms';
import {Colors, Typography} from 'src/styles';

const ExportFilterModal = ({applyFilters, resetFilters, filters}: any) => {
  const styles = getStyles();

  const validationSchema = Yup.object({});
  const initialValues = filters;

  const [showFilter, setShowFilter] = useState(false);

  const filterSubmit = (payload: any) => {
    applyFilters(payload);
    setShowFilter(false);
  };

  const filterCancelled = (formik: any) => {
    resetFilters();
    formik.resetForm();
    setShowFilter(false);
  };

  return (
    <FilterModal
      show={[showFilter, setShowFilter]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={filterSubmit}
      cancelled={filterCancelled}>
      <>
        <Text style={styles.labelTxt}>Date Range</Text>
        <View style={styles.dateContainer}>
          <View style={styles.flex}>
            <DateInput
              valueKey={'startDate'}
              name={'Start Date'}
              required={false}
              style={styles.flex}
            />
          </View>
          <Separator width={20} />
          <View style={styles.flex}>
            <DateInput
              valueKey={'endDate'}
              name={'End Date'}
              required={false}
              style={styles.flex}
            />
          </View>
        </View>
      </>
    </FilterModal>
  );
};

const getStyles = memoize(() =>
  EStyleSheet.create({
    dateContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    labelTxt: {
      ...Typography.P3_MEDIUM,
      color: Colors.TEXT_PRIMARY,
      marginBottom: 5,
    },
    flex: {
      flex: 1,
    },
    '@media (min-width: 768)': {
      dateContainer: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
  }),
);

export default ExportFilterModal;
