import React from 'react';
import {BLUE_RIBBON} from 'src/styles/colors';
import {HelperText, TextInput as PaperInput} from 'react-native-paper';
import {RenderProps} from 'react-native-paper/lib/typescript/components/TextInput/types';
import {useStyle} from 'src/providers/style';

interface Props {
  name: string;
  keyboardType?: KeyboardTypeOptions;
  required?: boolean;
  secureTextEntry?: boolean;
  right?: () => void;
  render?: (props: RenderProps) => React.ReactNode;
  style?: any;
  numberOfLines?: number;
  label?: string;
  error?: any;
  value: any;
  onChange(selected: string): void;
}

const TextInput = ({
  name,
  keyboardType = 'default',
  required = false,
  secureTextEntry = false,
  right = () => {},
  render = undefined,
  style = {},
  numberOfLines = 1,
  label,
  error,
  value,
  onChange,
}: Props) => {
  const styles = useStyle();

  return (
    <>
      <PaperInput
        onChangeText={onChange}
        keyboardType={keyboardType}
        value={value}
        placeholder={name}
        label={label + (required ? '*' : '')}
        error={!!error}
        secureTextEntry={secureTextEntry}
        theme={{colors: {primary: BLUE_RIBBON}}}
        right={right}
        mode={'outlined'}
        style={[styles.backgroundColorWhite, style]}
        render={render}
        multiline={numberOfLines > 1}
        numberOfLines={numberOfLines}
      />
      {error && (
        <HelperText type="error" visible={true}>
          {'ⓧ ' + error?.message}
        </HelperText>
      )}
    </>
  );
};

export default TextInput;
