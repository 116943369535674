import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {PreviewProps} from 'src/modules/form-builder/utils/types';
import {useStyle} from 'src/providers/style';

export const Details = ({label}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.paddingMTop]}>
      <Text style={[Typography.P3]}>{label}</Text>
    </View>
  );
};

export const Definition = () => ({
  name: 'Text Display',
  label: '',
  placeholder: '',
  componentType: 'TEXT',
  icon: 'format-size',
  options: [],
  helper: '',
  required: false,
});

export const Preview = ({item}: PreviewProps) => {
  return (
    <View>
      <Text style={[Typography.P3]}>{item?.label}</Text>
    </View>
  );
};
