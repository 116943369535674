import {Platform} from 'react-native';
// FONT FAMILY
export const FONT_FAMILY_REGULAR = 'OpenSans-Regular';

export const CAMPTON_BOOK =
  Platform.OS !== 'android' ? 'Campton-Book' : 'ReneBieder-CamptonBook';
export const CAMPTON_BLACK =
  Platform.OS !== 'android' ? 'Campton-Black' : 'ReneBieder-CamptonBlack';
export const CAMPTON_BOLD =
  Platform.OS !== 'android' ? 'Campton-Bold' : 'ReneBieder-CamptonBold';
export const CAMPTON_MEDIUM =
  Platform.OS !== 'android' ? 'Campton-Medium' : 'ReneBieder-CamptonMedium';
export const CAMPTON_LIGHT =
  Platform.OS !== 'android' ? 'Campton-Light' : 'ReneBieder-CamptonLight';

// FONT WEIGHT
export const FONT_WEIGHT_REGULAR = '400';
export const FONT_WEIGHT_MEDIUM = '500';
export const FONT_WEIGHT_BOLD = '700';
export const FONT_WEIGHT_BLACK = '800';

// LINE HEIGHT
export const LINE_HEIGHT_42 = 42;
export const LINE_HEIGHT_34 = 34;
export const LINE_HEIGHT_28 = 28;
export const LINE_HEIGHT_24 = 24;
export const LINE_HEIGHT_22 = 22;
export const LINE_HEIGHT_20 = 20;
export const LINE_HEIGHT_16 = 16;
export const LINE_HEIGHT_14 = 14;
export const LINE_HEIGHT_12 = 12;

// FONT SIZE
export const FONT_SIZE_35 = 35;
export const FONT_SIZE_28 = 28;
export const FONT_SIZE_24 = 24;
export const FONT_SIZE_23 = 23;
export const FONT_SIZE_18 = 18;
export const FONT_SIZE_16 = 16;
export const FONT_SIZE_14 = 14;
export const FONT_SIZE_12 = 12;
export const FONT_SIZE_10 = 10;

// LETTER SPACING
export const LETTER_SPACING_LARGE = 1.25;
export const LETTER_SPACING_MEDIUM = 0.5;
export const LETTER_SPACING_SMALL = 0.4;

// FONT STYLE
export const H1 = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_35,
  lineHeight: LINE_HEIGHT_42,
  letterSpacing: 0,
};

export const H2 = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_28,
  lineHeight: LINE_HEIGHT_34,
  letterSpacing: 0,
};

export const H3 = {
  fontFamily: CAMPTON_LIGHT,
  fontSize: FONT_SIZE_23,
  lineHeight: LINE_HEIGHT_28,
  letterSpacing: 0,
};

export const H4 = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_23,
  lineHeight: LINE_HEIGHT_28,
  letterSpacing: 0,
};
export const H5 = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_18,
  lineHeight: LINE_HEIGHT_24,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const H6 = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_20,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const H7 = {
  fontFamily: CAMPTON_BLACK,
  fontSize: FONT_SIZE_12,
  lineHeight: LINE_HEIGHT_16,
  letterSpacing: LETTER_SPACING_MEDIUM,
};
export const H8 = {
  fontFamily: CAMPTON_BLACK,
  fontSize: FONT_SIZE_10,
  lineHeight: LINE_HEIGHT_12,
  letterSpacing: LETTER_SPACING_MEDIUM,
};
export const P1 = {
  fontFamily: CAMPTON_BOOK,
  fontSize: FONT_SIZE_18,
  lineHeight: LINE_HEIGHT_24,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const P1_MEDIUM = {
  fontFamily: CAMPTON_MEDIUM,
  fontSize: FONT_SIZE_18,
  lineHeight: LINE_HEIGHT_24,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const P1_BOLD = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_18,
  lineHeight: LINE_HEIGHT_24,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const P2 = {
  fontFamily: CAMPTON_BOOK,
  fontSize: FONT_SIZE_16,
  // fontWeight: FONT_WEIGHT_REGULAR,
  lineHeight: LINE_HEIGHT_22,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const P2_MEDIUM = {
  fontFamily: CAMPTON_MEDIUM,
  fontSize: FONT_SIZE_16,
  lineHeight: LINE_HEIGHT_22,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const P2_BOLD = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_16,
  lineHeight: LINE_HEIGHT_22,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const P3 = {
  fontFamily: CAMPTON_BOOK,
  // fontWeight: FONT_WEIGHT_REGULAR,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_20,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const P3_MEDIUM = {
  fontFamily: CAMPTON_MEDIUM,
  fontWeight: FONT_WEIGHT_MEDIUM,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_20,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const P3_BOLD = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_20,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const P4 = {
  fontFamily: CAMPTON_BOOK,
  fontWeight: FONT_WEIGHT_REGULAR,
  fontSize: FONT_SIZE_12,
  lineHeight: LINE_HEIGHT_16,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const INPUT = {
  fontFamily: CAMPTON_BOOK,
  fontSize: FONT_SIZE_16,
  lineHeight: LINE_HEIGHT_24,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const TAB = {
  fontFamily: CAMPTON_BOOK,
  fontSize: FONT_SIZE_16,
  lineHeight: LINE_HEIGHT_24,
  letterSpacing: LETTER_SPACING_MEDIUM,
};

export const PRIMARY_BUTTON = {
  fontFamily: CAMPTON_BOLD,
  fontWeight: FONT_WEIGHT_BOLD,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_24,
  letterSpacing: LETTER_SPACING_LARGE,
};

export const SECONDARY_BUTTON = {
  fontFamily: CAMPTON_MEDIUM,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_24,
  letterSpacing: LETTER_SPACING_LARGE,
};

export const TERTIARY_BUTTON = {
  fontFamily: CAMPTON_BOOK,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_24,
  letterSpacing: LETTER_SPACING_SMALL,
};

export const CAPTION = {
  fontFamily: CAMPTON_BOOK,
  fontSize: FONT_SIZE_12,
  lineHeight: LINE_HEIGHT_14,
  letterSpacing: LETTER_SPACING_SMALL,
};

export const CAPTION_MEDIUM = {
  fontFamily: CAMPTON_MEDIUM,
  fontSize: FONT_SIZE_12,
  lineHeight: LINE_HEIGHT_14,
  letterSpacing: LETTER_SPACING_SMALL,
};

export const CAPTION_BOLD = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_12,
  lineHeight: LINE_HEIGHT_14,
  letterSpacing: LETTER_SPACING_SMALL,
};

export const LABEL = {
  fontFamily: CAMPTON_BOOK,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_16,
  letterSpacing: LETTER_SPACING_SMALL,
};

export const LABEL_MEDIUM = {
  fontFamily: CAMPTON_MEDIUM,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_16,
  letterSpacing: LETTER_SPACING_SMALL,
};

export const LABEL_BOLD = {
  fontFamily: CAMPTON_BOLD,
  fontSize: FONT_SIZE_14,
  lineHeight: LINE_HEIGHT_16,
  letterSpacing: LETTER_SPACING_SMALL,
};
