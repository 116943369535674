import React from 'react';
import {View} from 'react-native';
import {DataTable} from 'react-native-paper';
import {useDimensions} from '@react-native-community/hooks';
import IconMaterial from 'react-native-vector-icons/MaterialCommunityIcons';
import EStyleSheet from 'react-native-extended-stylesheet';
import _, {memoize} from 'lodash';
import moment from 'moment';
import {useTranslations} from 'src/providers/translation';
import IntervalTableTooltip from 'src/modules/session/components/interval-table-tooltip';
import {PromptsDisplay} from 'src/modules/session/components';
import {Colors, Typography} from 'src/styles';

const IntervalTable = ({
  setEditEvents,
  setInstance,
  setEditModule,
  type = undefined,
  program,
  sets,
  events,
}: any) => {
  const dimensions = useDimensions();
  const translations = useTranslations();
  const width = dimensions.window.width;
  const styles = getStyles();
  const countInRow = width > 1024 ? 10 : width > 768 ? 5 : width > 480 ? 3 : 2;
  const tableCount = Math.floor(sets?.length / countInRow);
  const restCount = countInRow - (sets?.length % countInRow);
  const eventsBySetId = _.keyBy(events, 'set.id');

  if (type) {
    return (
      <DataTable style={styles.table}>
        {_.times(tableCount + 1, i => {
          if (i < tableCount || (i === tableCount && restCount < countInRow)) {
            return (
              <View key={`interval-table-${i}`}>
                <DataTable.Header
                  style={[
                    styles.head,
                    styles.ph_0,
                    i === 0 && styles.firstRow,
                  ]}>
                  <DataTable.Title
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.titleTxt}>
                    {translations('instance')}
                  </DataTable.Title>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, index: number) => (
                      <DataTable.Title
                        style={[
                          styles.justifyCenter,
                          index !== countInRow - 1 && index !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.titleTxt}
                        key={`interval-instance-${set?.id}-${index}`}>
                        {i * countInRow + index + 1}
                      </DataTable.Title>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Header>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('date')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <View
                        style={[
                          styles.justifyCenter,
                          styles.alignCenter,
                          styles.flex,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`interval-starttime-${set?.id}-${idx}`}>
                        <IntervalTableTooltip
                          event={eventsBySetId[set?.id]}
                          set={set}
                          instance={i * countInRow + idx + 1}
                          intensity={
                            program?.intensity
                              ? _.capitalize(eventsBySetId[set?.id]?.intensity)
                              : ''
                          }
                        />
                        <View style={styles.cornerTab} />
                      </View>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('start_time')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.cellTxt}
                        key={`interval-starttime-${set?.id}-${idx}`}>
                        {moment(set?.startTimestamp).format('hh:mm:ss A')}
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('end_time')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.cellTxt}
                        key={`interval-endtime-${set?.id}-${idx}`}>
                        {moment(set?.endTimestamp).format('hh:mm:ss A')}
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('duration_time')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      return (
                        <View
                          style={[
                            styles.justifyCenter,
                            styles.alignCenter,
                            styles.flex,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`interval-durationtime-${set?.id}-${idx}`}>
                          <IntervalTableTooltip
                            setEditEvents={setEditEvents}
                            setInstance={setInstance}
                            setEditModule={setEditModule}
                            edit={'duration'}
                            event={eventsBySetId[set?.id]}
                            set={set}
                            instance={i * countInRow + idx + 1}
                            intensity={
                              program?.intensity
                                ? _.capitalize(
                                    eventsBySetId[set?.id]?.intensity,
                                  )
                                : ''
                            }
                          />
                          <View style={styles.cornerTab} />
                        </View>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                {program?.intensity && (
                  <DataTable.Row style={styles.ph_0}>
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.cellBoldTxt}>
                      {translations('intensity')}
                    </DataTable.Cell>
                    {sets
                      .slice(i * countInRow, (i + 1) * countInRow)
                      .map((set: any, idx: number) => (
                        <View
                          style={[
                            styles.justifyCenter,
                            styles.alignCenter,
                            styles.flex,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`interval-intensity-${set?.id}-${idx}`}>
                          <IntervalTableTooltip
                            setEditEvents={setEditEvents}
                            setInstance={setInstance}
                            setEditModule={setEditModule}
                            edit={'intensity'}
                            event={eventsBySetId[set?.id]}
                            set={set}
                            instance={i * countInRow + idx + 1}
                            intensity={
                              program?.intensity
                                ? _.capitalize(
                                    eventsBySetId[set?.id]?.intensity,
                                  )
                                : ''
                            }
                          />
                          <View style={styles.cornerTab} />
                        </View>
                      ))}
                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Title />)}
                  </DataTable.Row>
                )}
                <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={[styles.cellBoldTxt]}>
                    {translations('occurrence')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      const event = eventsBySetId[set?.id];
                      return (
                        <View
                          style={[
                            styles.justifyCenter,
                            styles.alignCenter,
                            styles.flex,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`interval-occurance-key-${set?.id}-${idx}`}>
                          <IntervalTableTooltip
                            setEditEvents={setEditEvents}
                            setInstance={setInstance}
                            setEditModule={setEditModule}
                            edit={'occurence'}
                            event={event}
                            set={set}
                            instance={i * countInRow + idx + 1}
                            intensity={
                              program?.intensity
                                ? _.capitalize(event?.intensity)
                                : ''
                            }
                          />
                          <View style={styles.cornerTab} />
                        </View>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
              </View>
            );
          }
        })}
      </DataTable>
    );
  } else {
    return (
      <DataTable style={styles.table}>
        {_.times(tableCount + 1, i => {
          if (i < tableCount || (i === tableCount && restCount < countInRow)) {
            return (
              <View key={`interval-table-${i}`}>
                <DataTable.Header
                  style={[
                    styles.head,
                    styles.ph_0,
                    i === 0 && styles.firstRow,
                  ]}>
                  <DataTable.Title
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.titleTxt}>
                    {translations('instance')}
                  </DataTable.Title>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, index: number) => (
                      <DataTable.Title
                        style={[
                          styles.justifyCenter,
                          index !== countInRow - 1 && index !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.titleTxt}
                        key={`interval-instance-${set?.id}-${index}`}>
                        {i * countInRow + index + 1}
                      </DataTable.Title>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Header>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('date')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <View
                        style={[
                          styles.justifyCenter,
                          styles.alignCenter,
                          styles.flex,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`interval-starttime-${set?.id}-${idx}`}>
                        <IntervalTableTooltip
                          event={eventsBySetId[set?.id]}
                          set={set}
                          instance={i * countInRow + idx + 1}
                          intensity={
                            program?.intensity
                              ? _.capitalize(eventsBySetId[set?.id]?.intensity)
                              : ''
                          }
                        />
                        <View style={styles.cornerTab} />
                      </View>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('start_time')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.cellTxt}
                        key={`interval-starttime-${set?.id}-${idx}`}>
                        {moment(set?.startTimestamp).format('hh:mm:ss A')}
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                <DataTable.Row style={styles.ph_0}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    {translations('end_time')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => (
                      <DataTable.Cell
                        style={[
                          styles.justifyCenter,
                          idx !== countInRow - 1 && idx !== sets?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        textStyle={styles.cellTxt}
                        key={`interval-endtime-${set?.id}-${idx}`}>
                        {moment(set?.endTimestamp).format('hh:mm:ss A')}
                      </DataTable.Cell>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
                {program?.intensity && (
                  <DataTable.Row style={styles.ph_0}>
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.cellBoldTxt}>
                      {translations('intensity')}
                    </DataTable.Cell>
                    {sets
                      .slice(i * countInRow, (i + 1) * countInRow)
                      .map((set: any, idx: number) => (
                        <DataTable.Cell
                          style={[
                            styles.justifyCenter,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          textStyle={styles.cellTxt}
                          key={`interval-intensity-${set?.id}-${idx}`}>
                          {_.capitalize(eventsBySetId[set?.id]?.intensity)}
                        </DataTable.Cell>
                      ))}
                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Title />)}
                  </DataTable.Row>
                )}
                <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={[styles.cellBoldTxt]}>
                    {translations('occurrence')}
                  </DataTable.Cell>
                  {sets
                    .slice(i * countInRow, (i + 1) * countInRow)
                    .map((set: any, idx: number) => {
                      const event = eventsBySetId[set?.id];

                      return (
                        <DataTable.Cell
                          style={[
                            styles.justifyCenter,
                            idx !== countInRow - 1 && idx !== sets?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`interval-occurance-key-${set?.id}-${idx}`}>
                          <View
                            style={[
                              styles.column,
                              styles.alignCenter,
                              styles.justifyCenter,
                            ]}>
                            <View style={styles.valuePadding}>
                              {event?.value === 'Y' ? (
                                <IconMaterial
                                  name="check"
                                  size={18}
                                  color={Colors.TERTIARY_SPRING_GREEN}
                                />
                              ) : event?.value === 'N' ? (
                                <IconMaterial
                                  name="close"
                                  size={18}
                                  color={Colors.ELECTRIC_SALMON}
                                />
                              ) : event?.value === '-' ? (
                                <IconMaterial
                                  name="minus"
                                  size={12}
                                  color={Colors.TEXT_SECONDARY}
                                />
                              ) : (
                                <></>
                              )}
                            </View>
                            {event?.prompts?.length ? (
                              <PromptsDisplay prompts={event?.prompts} />
                            ) : (
                              <></>
                            )}
                          </View>
                        </DataTable.Cell>
                      );
                    })}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Row>
              </View>
            );
          }
        })}
      </DataTable>
    );
  }
};

const getStyles = memoize(() =>
  EStyleSheet.create({
    table: {
      borderWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
      borderRadius: 10,
    },
    head: {
      height: 50,
      backgroundColor: Colors.PRIMARY_50,
    },
    firstRow: {
      borderTopStartRadius: 10,
      borderTopEndRadius: 10,
    },
    lastRow: {
      borderBottomStartRadius: 10,
      borderBottomEndRadius: 10,
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    alignSelfCenter: {
      alignSelf: 'center',
    },
    titleTxt: {
      ...Typography.P3_MEDIUM,
      color: Colors.PRIMARY_900,
      alignSelf: 'center',
      textAlign: 'center',
      flexWrap: 'wrap',
    },
    cellBoldTxt: {
      ...Typography.CAPTION_MEDIUM,
      color: Colors.PRIMARY_900,
    },
    cellTxt: {
      ...Typography.CAPTION,
      color: Colors.PRIMARY_900,
    },
    pl_20: {
      paddingLeft: 20,
    },
    ph_0: {
      paddingHorizontal: 0,
    },
    column: {
      flexDirection: 'column',
    },
    alignCenter: {
      alignItems: 'center',
    },
    valuePadding: {
      paddingTop: 20,
      paddingHorizontal: 20,
      paddingBottom: 10,
    },
    borderRight: {
      borderRightWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
    },
    flex: {
      flex: 1,
    },
    cornerTab: {
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderRightWidth: 8,
      borderTopWidth: 8,
      borderRightColor: 'transparent',
      borderTopColor: '#B5B5B8',
      transform: [{rotate: '90deg'}],
      position: 'absolute',
      top: 0,
      right: 0,
    },
    '@media (max-width: 768)': {
      pl_20: {
        paddingLeft: 10,
      },
    },
    '@media (max-width: 568)': {
      pl_20: {
        paddingLeft: 0,
      },
    },
  }),
);

export default IntervalTable;
