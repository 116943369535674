import React, {useEffect, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import moment from 'moment';
import {Checkbox, IconButton} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import {useNavigation} from '@react-navigation/native';

const PatientClaimSessionsItem = ({
  item,
  selectedItems,
  setSelectedItems,
  plans,
}: any) => {
  const styles = useStyle();
  const navigation = useNavigation();
  const [amount, setAmount] = useState<string>('');
  const sortedCpt = item.cpt.sort((a, b) => a - b);
  const durationInMins = moment(item.editedEndTimestamp).diff(
    item.editedStartTimestamp,
    'minutes',
  );
  const units =
    sortedCpt.length && sortedCpt.length === 1 && sortedCpt[0] === 'H2012'
      ? Math.floor(durationInMins / 60)
      : Math.round(durationInMins / 15);

  const calculateAmount = () => {
    if (!item.cpt.length || !plans.length) {
      return;
    }

    const feeScheduleMap = plans.reduce((acc, plan) => {
      plan.feeSchedule.forEach(feeSchedule => {
        const {cpt, billingRate} = feeSchedule;
        if (!acc[cpt]) {
          acc[cpt] = [];
        }
        if (billingRate) {
          acc[cpt].push(Number(billingRate));
        }
      });
      return acc;
    }, {});

    for (const cpt of sortedCpt) {
      if (feeScheduleMap[cpt] && feeScheduleMap[cpt].length) {
        const rates = feeScheduleMap[cpt];
        const rate = rates[0];
        setAmount(`$${units * rate}`);
        return;
      }
    }
  };

  useEffect(() => {
    calculateAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let status = 'missing_values';
  if (item.endTimestamp && !item.submissionTimestamp) {
    status = 'missing_values';
  } else if (item.submissionTimestamp) {
    status = 'completed';
  }

  return (
    <View
      style={[styles.row, styles.padding, styles.width, styles.alignCenter]}>
      <TouchableOpacity
        onPress={() => {
          if (selectedItems.includes(item.id)) {
            setSelectedItems(
              selectedItems.filter((selected: string) => selected !== item.id),
            );
          } else {
            setSelectedItems([...selectedItems, item.id]);
          }
        }}
        style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <Checkbox.Android
          status={selectedItems.includes(item.id) ? 'checked' : 'unchecked'}
          color={Colors.RAVEN_BLACK}
          uncheckedColor={Colors.RAVEN_BLACK}
        />
      </TouchableOpacity>
      <View style={[styles.flex]}>
        <Text style={[Typography.P3, styles.textColorPrimary]}>
          {moment(item.sessionDate).format('MM/DD/YYYY')}
        </Text>
      </View>
      <View style={[styles.flex]}>
        <Text style={[Typography.P3, styles.textColorPrimary]}>
          {`${moment(item.editedStartTimestamp).format('hh:mm A')} - ${moment(
            item.editedEndTimestamp,
          ).format('hh:mm A')}`}
        </Text>
      </View>
      <View style={[styles.flex]}>
        <Text style={[Typography.P3, styles.textColorPrimary]}>
          {item.cpt.join(', ')}
        </Text>
      </View>
      <View style={[styles.flex]}>
        <Text style={[Typography.P3, styles.textColorPrimary]}>
          {item.location}
        </Text>
      </View>
      <View style={[styles.flex]}>
        <Text style={[Typography.P3, styles.textColorPrimary]}>{units}</Text>
      </View>
      <View style={[styles.flex]}>
        <Text style={[Typography.P3, styles.textColorPrimary]}>{amount}</Text>
      </View>
      <IconButton
        color={Colors.RAVEN_BLACK}
        icon={'eye'}
        onPress={() => {
          if (status === 'missing_values') {
            navigation.navigate('SessionMessage', {
              id: item.id,
            });
          } else {
            navigation.navigate('SessionRecap', {
              id: item.id,
            });
          }
        }}
      />
    </View>
  );
};

export default PatientClaimSessionsItem;
