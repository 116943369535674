import React, {useEffect, useState} from 'react';
import {FlatList} from 'react-native';
import {SessionProgramDataTable} from 'src/modules/session/components';
import NoResults from 'src/common-components/noResults';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {oneOf} from '@nozbe/watermelondb/QueryDescription';
import {of} from 'rxjs';
import _ from 'lodash';
import {Program, Set, SessionProgram} from 'src/models';

const SessionSummaryDataTable = ({
  session,
  showGraphs = true,
  sortedPrograms,
  showProgramMenu = false,
}: any) => {
  const [reorderedPrograms, setReorderedPrograms] = useState(sortedPrograms);

  useEffect(() => {
    setReorderedPrograms(sortedPrograms);
  }, [sortedPrograms]);

  const reorderPrograms = prevProgram => {
    setReorderedPrograms(prevOrderedPrograms => {
      let newArr = prevOrderedPrograms.filter(
        program => program.id !== prevProgram.id,
      );
      newArr.unshift(prevProgram);
      return _.uniq(newArr, (program: any) => program.id);
    });
  };

  return (
    <>
      {reorderedPrograms.length ? (
        <FlatList
          data={reorderedPrograms}
          keyExtractor={item => `session-program-data-key-${item?.id}`}
          renderItem={({item, index}: any) => (
            <SessionProgramDataTable
              program={item}
              session={session}
              showGraphs={showGraphs}
              reorderPrograms={reorderPrograms}
              index={index}
              showProgramMenu={showProgramMenu}
            />
          )}
        />
      ) : (
        <NoResults
          iconName={'file-outline'}
          secondaryMessage={'No programs have been enabled for this session.'}
        />
      )}
    </>
  );
};

export default React.memo(
  compose(
    withDatabase,
    withObservables(
      ['filters'],
      ({session, filters, database, showAllPrograms = false}: any) => {
        const queries = [];
        if (filters?.types && filters.types.length) {
          queries.push(Q.where('type', oneOf(filters.types)));
        }
        if (filters?.programs && filters.programs.length) {
          queries.push(Q.where('id', oneOf(filters.programs)));
        }

        return {
          sets: session?.id ? session?.sets : of([]),
          programs: showAllPrograms
            ? database
                .get(Program.table)
                .query(
                  Q.on(
                    SessionProgram.table,
                    Q.and(
                      Q.where('session_id', session?.id || null),
                      Q.where('enabled', true),
                    ),
                  ),
                  Q.where('deleted_at', null),
                  ...queries,
                )
            : database
                .get(Program.table)
                .query(
                  ...queries,
                  Q.on(
                    Set.table,
                    Q.and(
                      Q.where('session_id', session?.id || null),
                      Q.where('deleted_at', null),
                    ),
                  ),
                  Q.where('deleted_at', null),
                ),
        };
      },
    ),
    withObservables(['programs'], ({sets, programs}: any) => {
      const sortedPrograms = programs.sort((a, b) => {
        const timestampA =
          sets.find(set => set.programId === a.id)?.updatedAt || 0;
        const timestampB =
          sets.find(set => set.programId === b.id)?.updatedAt || 0;
        return timestampB - timestampA;
      });
      return {
        sortedPrograms: of(sortedPrograms),
      };
    }),
  )(SessionSummaryDataTable),
);
