import {InputGroup} from 'src/design-system';
import React from 'react';
import {FirstName, LastName} from 'src/form-inputs';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

const resolveDisplayValue = (value: string) => {
  return value;
};

const NameDisplay = ({value}: DisplayProps) => {
  return (
    <>
      <Text style={[Typography.H6]}>Name</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </>
  );
};

const NameInput = ({valueKey}: Props) => {
  return (
    <InputGroup
      left={<FirstName.Input valueKey={valueKey} />}
      right={<LastName.Input valueKey={valueKey} />}
    />
  );
};

export const validation = () =>
  Yup.object().shape({
    firstName: FirstName.validation(),
    lastName: LastName.validation(),
  });

export const Display = NameDisplay;
export const Input = NameInput;
export const Value = resolveDisplayValue;

export default NameInput;
