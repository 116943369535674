import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {DataTable, IconButton} from 'react-native-paper';
import Collapsible from 'react-native-collapsible';
import _ from 'lodash';
import {Tooltip} from 'src/design-system';
import Icon from 'react-native-vector-icons/MaterialIcons';
import IconMaterial from 'react-native-vector-icons/MaterialCommunityIcons';
import {useStyle} from 'src/providers/style';

interface Props {
  item: any;
  canEdit: boolean;
  editSelected: (value: any) => void;
  canDelete: boolean;
  deleteSelected: (value: any) => void;
  permissions: any;
}

const RoleItem = ({
  item,
  canEdit,
  editSelected,
  canDelete,
  deleteSelected,
  permissions,
}: Props) => {
  const styles = useStyle();

  const [collapsed, setCollapsed] = useState(true);

  const supportedPermissions = [
    'Create',
    'Edit',
    'Delete',
    'View',
    'Assigned Only',
  ];

  return (
    <View style={[styles.card]}>
      <TouchableOpacity
        style={[styles.column, styles.justifyCenter, styles.alignCenter]}
        onPress={() => setCollapsed(!collapsed)}>
        <View
          style={[
            styles.width,
            styles.row,
            styles.justifySpaceBetween,
            styles.padding,
          ]}>
          <View style={[styles.paddingLVertical, styles.row]}>
            <Text style={Typography.P2_MEDIUM}>{item.name}</Text>
          </View>
          <View style={[styles.row, styles.justifySpaceBetween]}>
            {canEdit ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="pencil"
                onPress={editSelected}
              />
            ) : (
              <></>
            )}
            {canDelete ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="trash-can"
                onPress={deleteSelected}
              />
            ) : (
              <></>
            )}
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={!collapsed ? 'chevron-up' : 'chevron-down'}
              onPress={() => setCollapsed(!collapsed)}
            />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible
        collapsed={collapsed}
        style={[styles.paddingHorizontal]}
        renderChildrenCollapsed={false}>
        <View style={[]}>
          <DataTable.Header
            style={[
              styles.row,
              styles.width,
              styles.paddingMHorizontal,
              styles.alignCenter,
              styles.borderBottomWidth0,
            ]}>
            <DataTable.Cell style={[styles.flex2]}>
              <Text style={[Typography.P3_MEDIUM]}>Feature</Text>
            </DataTable.Cell>
            <DataTable.Cell style={[styles.flex, styles.width]}>
              <View
                style={[
                  styles.flex,
                  styles.width,
                  styles.row,
                  styles.justifyCenter,
                  styles.alignCenter,
                ]}>
                <Text style={[Typography.P3_MEDIUM]}>Create</Text>
              </View>
            </DataTable.Cell>
            <DataTable.Cell>
              <View
                style={[styles.row, styles.justifyCenter, styles.alignCenter]}>
                <Text style={[Typography.P3_MEDIUM]}>Edit</Text>
              </View>
            </DataTable.Cell>
            <DataTable.Cell>
              <View
                style={[styles.row, styles.justifyCenter, styles.alignCenter]}>
                <Text style={[Typography.P3_MEDIUM]}>Delete</Text>
              </View>
            </DataTable.Cell>
            <DataTable.Cell>
              <View
                style={[styles.row, styles.justifyCenter, styles.alignCenter]}>
                <Text style={[Typography.P3_MEDIUM]}>View</Text>
              </View>
            </DataTable.Cell>
            <DataTable.Cell>
              <View
                style={[styles.row, styles.justifyCenter, styles.alignCenter]}>
                <Tooltip
                  anchor={
                    <Text style={[Typography.P3_MEDIUM]}>
                      Assigned <Icon name={'help-outline'} />
                    </Text>
                  }>
                  <Text style={[Typography.H6, styles.paddingBottom]}>
                    Assigned Only Permissions
                  </Text>
                  <Text style={[Typography.P3]}>
                    Users can only view the associated entities that are
                    assigned to them.
                  </Text>
                </Tooltip>
              </View>
            </DataTable.Cell>
          </DataTable.Header>
          {Object.keys(permissions).map((entity: string, idx) => {
            return (
              <DataTable.Row
                key={`role-form-row-${entity}-${idx}`}
                style={[
                  styles.row,
                  styles.width,
                  styles.paddingMHorizontal,
                  styles.alignCenter,
                  styles.borderBottomWidth0,
                  permissions[entity].nested ? {} : styles.borderTop,
                ]}>
                <DataTable.Cell style={[styles.flex2]}>
                  <Text
                    style={[
                      Typography.P3,
                      permissions[entity].nested ? styles.paddingLeft : {},
                    ]}>
                    {_.startCase(permissions[entity].title)}
                  </Text>
                </DataTable.Cell>
                {supportedPermissions.map((permission, index) => {
                  return (
                    <DataTable.Cell key={`role-item-${permission}-${index}`}>
                      <View
                        style={[
                          styles.column,
                          styles.justifyCenter,
                          styles.alignCenter,
                        ]}>
                        {permissions[entity].permissions.indexOf(permission) !==
                        -1 ? (
                          item[
                            permissions[entity].key +
                              permission.replace(' ', '')
                          ] ? (
                            <IconMaterial
                              name="check"
                              size={20}
                              color={Colors.TERTIARY_SPRING_GREEN}
                            />
                          ) : (
                            <IconMaterial
                              name="close"
                              size={20}
                              color={Colors.ELECTRIC_SALMON}
                            />
                          )
                        ) : (
                          <></>
                        )}
                      </View>
                    </DataTable.Cell>
                  );
                })}
              </DataTable.Row>
            );
          })}
        </View>
      </Collapsible>
    </View>
  );
};

export default RoleItem;
