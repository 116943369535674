import React, {Dispatch, SetStateAction} from 'react';
import {Modal} from 'src/design-system';
import {Text} from 'react-native';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';

interface Props {
  model: string;
  text: string;
  customText?: string;
  show: [boolean, Dispatch<SetStateAction<boolean>>];
  handleSubmit?: (id: string) => void;
  cancelled?: (formik: any) => void;
  children?: any;
}

const DeleteModal = ({
  model = '',
  text = '',
  customText,
  show,
  cancelled,
  handleSubmit,
}: Props) => {
  return (
    <Modal
      show={show}
      title={'Delete ' + model}
      footer={
        <>
          <RHButton
            secondary
            mode="outlined"
            textColor={Colors.RAVEN_BLACK}
            onPress={cancelled}>
            CANCEL
          </RHButton>
          <RHSeparator width={16} />
          <RHButton secondary mode="contained" onPress={handleSubmit}>
            YES, DELETE {model}
          </RHButton>
        </>
      }>
      {customText ? (
        <Text style={[Typography.P1]}>{customText}</Text>
      ) : (
        <Text style={[Typography.P1]}>
          Are you sure you want to delete {text}?
        </Text>
      )}
    </Modal>
  );
};

export default DeleteModal;
