import React, {forwardRef} from 'react';
import {Colors, Typography} from 'src/styles';
import {TouchableOpacity, View, Text} from 'react-native';
import {HelperText, Switch} from 'react-native-paper';
import {useStyle} from 'src/providers/style';

interface Props {
  label: string;
  description?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  error: any;
  isDisabled?: boolean;
  showHelper?: boolean;
  color?: string;
}

const SwitchInput = forwardRef(
  (
    {
      label,
      description,
      value,
      onChange,
      error,
      isDisabled = false,
      showHelper = true,
      color = '',
    }: Props,
    ref,
  ) => {
    const styles = useStyle();
    return (
      <View ref={ref} style={[styles.column, styles.paddingLVertical]}>
        <TouchableOpacity
          onPress={() => {
            if (!isDisabled) {
              onChange(!value);
            }
          }}>
          <View style={[styles.flex, styles.row, styles.alignItemsCenter]}>
            <Switch
              disabled={isDisabled}
              color={color ? color : Colors.RAVEN_BLACK}
              value={value}
              onValueChange={val => {
                onChange(val);
              }}
              style={[styles.marginMRight]}
            />
            <View style={[styles.flex, styles.row]}>
              <Text
                style={[
                  Typography.P2_MEDIUM,
                  styles.textColorPrimary,
                  styles.flexWrap,
                ]}>
                {label}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
        {showHelper ? (
          <HelperText
            type={error ? 'error' : 'info'}
            visible={true}
            style={[styles.helper, styles.padding0Horizontal]}>
            {error ? 'ⓧ ' + error.message : description}
          </HelperText>
        ) : null}
      </View>
    );
  },
);

export default SwitchInput;
