import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {Platform, Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import moment from 'moment';
import {default as HookDateInput} from 'src/hook-form-wrapper/date-input';
import {Separator} from 'src/common-components/atoms';

const isWeb = Platform.OS === 'web';

let buttons = {
  '1d': {
    label: '1D',
    definition: moment().subtract(1, 'days').format(),
  },
  '1w': {
    label: '1W',
    definition: moment().subtract(1, 'weeks').format(),
  },
  '1m': {
    label: '1M',
    definition: moment().subtract(1, 'months').format(),
  },
};

if (isWeb) {
  buttons = {
    ...buttons,
    '3m': {
      label: '3M',
      definition: moment().subtract(3, 'months').format(),
    },
    '6m': {
      label: '6M',
      definition: moment().subtract(6, 'months').format(),
    },
    '1y': {
      label: '1Y',
      definition: moment().subtract(1, 'years').format(),
    },
    custom: {
      label: 'Custom',
      definition: null,
    },
  };
}

interface Props {
  name?: string;
  initialState?: string;
  watchedValue?: any;
}

export const Input = ({
  name = 'startDate',
  initialState = '1m',
  watchedValue = '',
}: Props) => {
  const styles = useStyle();
  const [selected, setSelected] = useState<any>(initialState);
  const {setValue} = useFormContext();

  useEffect(() => {
    if (watchedValue) {
      setSelected(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValue]);

  useEffect(() => {
    if (selected && selected !== 'custom') {
      setValue(`${name}`, buttons[selected]?.definition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <View style={[styles.tabletContainer, styles.alignCenter]}>
      <View
        style={[
          styles.row,
          styles.alignCenter,
          styles.border,
          styles.borderColorPrimary200,
          styles.backgroundColorPrimary50,
          styles.borderRadius50,
          styles.paddingM,
          styles.marginTop,
          styles.alignSelfStart,
        ]}>
        {Object.keys(buttons).map(value => {
          return (
            <Text
              key={`toggle-key-${buttons[value]?.label}`}
              style={[
                styles.toggleButton,
                selected === value ? styles.toggleButtonSelected : {},
              ]}
              onPress={() => setSelected(value)}>
              {buttons[value]?.label}
            </Text>
          );
        })}
      </View>
      {selected === 'custom' ? (
        <View style={[styles.container]}>
          <Separator width={20} />
          <HookDateInput
            name={'startDate'}
            label={'Start Date'}
            placeholder={'Start Date'}
            required={false}
            showHelper={false}
          />
          <Separator width={10} />
          <HookDateInput
            name={'endDate'}
            label={'End Date'}
            placeholder={'End Date'}
            required={false}
            showHelper={false}
          />
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

export default Input;
