import React, {useEffect, useState} from 'react';
import BaseAuthScreen from 'src/modules/authentication/screens/base-auth-screen';
import {BASE_AUTH_URL} from 'react-native-dotenv';
import {useTranslations} from 'src/providers/translation';
import ResetUsernameForm from '../../components/reset-username-form';
import {useStyle} from 'src/providers/style';
import {Text} from 'react-native';
import {Typography} from 'src/styles';

const ResetUsernameScreen = ({navigation, route}: any) => {
  const styles = useStyle();
  const [code, setCode] = useState('');
  const [userId, setId] = useState('');
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState<null | any>(null);
  const translations = useTranslations();

  useEffect(() => {
    let unmounted = false;
    if (unmounted) {
      return;
    }
    if (route.params && route.params.code) {
      setCode(route.params.code);
    }
    if (route.params && route.params.id) {
      setId(route.params.id);
    }
    if (route.params && route.params.email) {
      setValue(route.params.email.replace('%40', '@'));
    }
    return () => {
      unmounted = true;
    };
  }, [route.params]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const uri = `${BASE_AUTH_URL}/reset-email`;
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Transfer-Encoding': 'chunked',
        },
        body: JSON.stringify({
          code,
          username: value,
          userId,
        }),
      };

      let response = await (await fetch(uri, config)).json();

      if (response.success) {
        navigation.navigate('Login');
      } else {
        switch (response.status) {
          case 422:
            setToast({
              success: false,
              title: 'An error has occurred.',
              message:
                'The code you are using is more than two days old or has been consumed already. Please request a new code.',
            });
            break;
          case 500:
            setToast({
              success: false,
              title: 'An error has occurred.',
              message: 'Internal server error.',
            });
            break;
        }
      }
    } catch (err) {
      setToast({
        success: false,
        title: 'An error has occurred.',
        message: 'Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const navigateLogin = () => {
    navigation.navigate('Login');
  };

  return (
    <BaseAuthScreen loading={loading} toast={toast}>
      <Text
        style={[
          Typography.P3,
          styles.textAlignCenter,
          styles.marginBottom,
          styles.textColorPrimary,
        ]}>
        {translations('auth_screen_username_reset')}
      </Text>
      <ResetUsernameForm handleSubmit={handleSubmit} login={navigateLogin} />
    </BaseAuthScreen>
  );
};

export default ResetUsernameScreen;
