import React from 'react';
import {StyleSheet, View} from 'react-native';

const DataGroup = ({children}: any) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
  },
});

export default DataGroup;
