import {IconButton, Menu} from 'src/design-system';
import React, {useState, useEffect} from 'react';
import {StyleSheet, View} from 'react-native';

const SortByMenu = ({setSortBy = () => {}, sortByStr = ''}) => {
  const [sortIcon, setSortIcon] = useState(sortByStr);
  useEffect(() => {
    if (sortByStr === 'updatedAt') {
      setSortIcon('update');
    } else if (sortByStr === 'name-asc') {
      setSortIcon('sort-alphabetical-ascending');
    } else if (sortByStr === 'name-desc') {
      setSortIcon('sort-alphabetical-descending');
    } else {
      setSortIcon('sort');
    }

    return () => {
      setSortIcon('sort');
    };
  }, [sortByStr]);

  const options = [
    {
      title: 'Recently Updated',
      icon: 'update',
      param: 'updatedAt',
      onPress: setSortBy,
    },
    {
      title: 'Name: A-Z',
      icon: 'sort-alphabetical-ascending',
      param: 'name-asc',
      onPress: setSortBy,
    },
    {
      title: 'Name: Z-A',
      icon: 'sort-alphabetical-descending',
      param: 'name-desc',
      onPress: setSortBy,
    },
  ];

  return (
    <View style={styles.iconContainer}>
      <Menu
        anchor={<IconButton type={'icon'} icon={sortIcon} />}
        options={options}
      />
    </View>
  );
};

export default SortByMenu;

const styles = StyleSheet.create({
  iconContainer: {
    borderWidth: 1,
    borderRadius: 100,
    borderColor: 'black',
  },
  minWidth: {
    minWidth: 200,
  },
});
