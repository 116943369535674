import React, {useState} from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import {Checkbox, IconButton} from 'react-native-paper';
import Collapsible from 'react-native-collapsible';
import {FastField} from 'formik';
import _ from 'lodash';

import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

interface Props {
  valueKey: string;
  title: string;
  items: {value: string; title: string}[];
}

const CheckboxArrayInput = ({valueKey, title, items}: Props) => {
  const styles = useStyle();

  const [open, setOpen] = useState(true);

  return (
    <View>
      <TouchableOpacity
        onPress={() => setOpen(!open)}
        style={[styles.row, styles.justifySpaceBetween, styles.alignCenter]}>
        <Text style={[Typography.P1_MEDIUM, styles.textColorPrimary]}>
          {title}
        </Text>
        <IconButton
          color={Colors.RAVEN_BLACK}
          icon={!open ? 'chevron-up' : 'chevron-down'}
        />
      </TouchableOpacity>
      <Collapsible collapsed={open}>
        <FastField name={valueKey}>
          {({field, form: {setFieldValue}}: any) => {
            const selectedItems = field.value || [];
            return (
              <>
                <TouchableOpacity
                  key={'check-key-all'}
                  onPress={() => {
                    if (selectedItems?.length === items?.length) {
                      setFieldValue(field.name, []);
                    } else {
                      setFieldValue(field.name, _.map(items, 'value'));
                    }
                  }}
                  style={[styles.row, styles.alignCenter]}>
                  <Checkbox.Android
                    status={
                      selectedItems?.length === items?.length
                        ? 'checked'
                        : 'unchecked'
                    }
                    color={Colors.RAVEN_BLACK}
                    uncheckedColor={Colors.RAVEN_BLACK}
                  />
                  <Text
                    style={[
                      Typography.P2,
                      styles.textColorPrimary,
                      styles.marginSMLeft,
                    ]}>
                    Select All
                  </Text>
                </TouchableOpacity>
                {items.length > 0 &&
                  items.map((item: any) => (
                    <TouchableOpacity
                      key={`check-key-${item.value}`}
                      onPress={() => {
                        if (selectedItems.includes(item.value)) {
                          setFieldValue(
                            field.name,
                            selectedItems.filter(
                              (selected: string) => selected !== item.value,
                            ),
                          );
                        } else {
                          setFieldValue(field.name, [
                            ...selectedItems,
                            item.value,
                          ]);
                        }
                      }}
                      style={[
                        styles.row,
                        styles.alignCenter,
                        styles.marginLeft,
                      ]}>
                      <Checkbox.Android
                        status={
                          selectedItems.includes(item.value)
                            ? 'checked'
                            : 'unchecked'
                        }
                        color={Colors.RAVEN_BLACK}
                        uncheckedColor={Colors.RAVEN_BLACK}
                      />
                      <Text
                        style={[
                          Typography.P2,
                          styles.textColorPrimary,
                          styles.marginSMLeft,
                        ]}>
                        {item.title}
                      </Text>
                    </TouchableOpacity>
                  ))}
              </>
            );
          }}
        </FastField>
      </Collapsible>
    </View>
  );
};

export default CheckboxArrayInput;
