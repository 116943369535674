import {Text} from 'react-native';
import React from 'react';
import {Typography} from 'src/styles';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Prompt} from 'src/models';

const PromptView = ({prompt}: any) => {
  return (
    <>
      <Text style={[Typography.CAPTION]}>{prompt?.acronym}</Text>
    </>
  );
};

export default compose(
  withDatabase,
  withObservables(['promptId'], ({database, promptId}: any) => {
    return {
      prompt: database.get(Prompt.table).findAndObserve(promptId),
    };
  }),
)(PromptView);
