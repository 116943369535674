import moment from 'moment';
import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

const HomeHeader = ({name}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const currentFormattedDate = moment().format('MMMM Do, YYYY');
  return (
    <View
      style={[
        styles.row,
        styles.justifySpaceBetween,
        styles.alignCenter,
        styles.paddingHorizontal,
        styles.paddingTop,
        styles.width,
      ]}>
      <Text style={[Typography.H2, styles.flex]}>{`${translations(
        'hello',
      )} ${name}.`}</Text>
      <Text style={[Typography.P3, styles.flex, styles.textAlignRight]}>
        {currentFormattedDate}
      </Text>
    </View>
  );
};

export default HomeHeader;
