import React from 'react';
import {View, Text} from 'react-native';
import {IconButton} from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';

const FilterChip = ({
  textColor = Colors.RAVEN_WHITE,
  backgroundColor = Colors.RAVEN_BLACK,
  icon = undefined,
  label = '',
  close = undefined,
}: any) => {
  const styles = useStyle();

  return (
    <View
      style={[
        styles.row,
        styles.alignCenter,
        styles.borderRadius50,
        styles.paddingLHorizontal,
        styles.paddingSMVertical,
        {
          backgroundColor,
        },
      ]}>
      {icon ? (
        <Icon
          style={[styles.marginMRight]}
          color={textColor}
          size={12}
          name={icon}
        />
      ) : null}
      <Text
        style={[
          Typography.P3_MEDIUM,
          styles.alignSelfCenter,
          {
            color: textColor,
          },
        ]}>
        {label}
      </Text>
      {close ? (
        <IconButton
          style={[styles.margin0, styles.marginMLeft]}
          size={12}
          icon="close"
          color={textColor}
          onPress={close}
        />
      ) : null}
    </View>
  );
};

export default FilterChip;
