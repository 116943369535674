import React, {Dispatch, SetStateAction} from 'react';
import {
  Platform,
  Text,
  TouchableOpacity,
  View,
  SafeAreaView,
} from 'react-native';
import NoResults from 'src/common-components/noResults';
import {Typography} from 'src/styles';
import {
  SessionBehaviorListItem,
  SessionSkillListItem,
} from 'src/modules/session/components';
import {Separator} from 'src/common-components/atoms';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {updateRank} from 'src/common-utils/rank';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {navigate, updateOffset} from 'src/redux/session';
import {useDispatch} from 'react-redux';
import {
  NestableScrollContainer,
  NestableDraggableFlatList,
  RenderItemParams,
} from 'react-native-draggable-flatlist';
import {Drawer} from 'src/design-system';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

interface Props {
  openReorderList: [boolean, Dispatch<SetStateAction<boolean>>];
  behaviorClick: (idx: number) => void;
  skillClick: (idx: number) => void;
  sessionProgramBehaviors: any[];
  sessionProgramSkills: any[];
  offset: any;
  setOffset: Dispatch<SetStateAction<any>>;
}

const SessionPrograms = ({
  openReorderList: [openReorderList, setOpenReorderList],
  sessionProgramBehaviors,
  sessionProgramSkills,
}: Props) => {
  const dispatch = useDispatch();
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <Drawer
      anchor={'right'}
      open={openReorderList}
      setOpen={setOpenReorderList}
      onClose={() => setOpenReorderList(!openReorderList)}>
      <SafeAreaView style={[styles.flex, styles.backgroundColorWhite]}>
        <TouchableOpacity
          style={[styles.paddingLTop, styles.paddingLBottom, styles.marginLeft]}
          onPress={() => setOpenReorderList(!openReorderList)}>
          <View
            style={[
              styles.row,
              styles.alignCenter,
              styles.justifySpaceBetween,
            ]}>
            <Icon name={'arrow-back'} size={20} />
            <View
              style={[
                styles.column,
                styles.flex,
                styles.justifyCenter,
                styles.paddingLLeft,
              ]}>
              <Text style={[Typography.P1, styles.paddingSMTop]}>
                {translations('return_to_session')}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
        <View
          style={[
            styles.row,
            styles.alignCenter,
            styles.justifySpaceBetween,
            styles.paddingHorizontal,
            styles.paddingLBottom,
          ]}>
          <Text style={[Typography.P3]}>
            {translations('enable_session_programs')}
          </Text>
        </View>
        <GestureHandlerRootView style={[styles.flex]}>
          <NestableScrollContainer persistentScrollbar={true}>
            {sessionProgramBehaviors.length > 0 ? (
              <NestableDraggableFlatList
                nestedScrollEnabled={true}
                scrollEnabled={true}
                data={sessionProgramBehaviors}
                renderItem={({item, getIndex, drag}: RenderItemParams<any>) => (
                  <SessionBehaviorListItem
                    key={item?.id}
                    sessionProgramId={item.id}
                    drag={drag}
                    navigate={() => {
                      dispatch(
                        navigate({
                          type: 'behavior',
                          index: getIndex(),
                        }),
                      );
                      setOpenReorderList(false);
                    }}
                  />
                )}
                keyExtractor={(item, index) =>
                  `draggable-item-${item.id}-${index}`
                }
                onDragEnd={({data}) => {
                  updateRank(database, data);
                }}
                style={[styles.paddingLTop]}
              />
            ) : (
              <NoResults
                style={[styles.backgroundColorWhite, styles.borderRadius]}
                iconName="view-grid-outline"
                message={translations('no_behavior_programs')}
              />
            )}
            <View style={[styles.paddingHorizontal, styles.paddingLBottom]}>
              <View style={[styles.separator]} />
            </View>
            <Separator height={10} />
            {sessionProgramSkills.length > 0 ? (
              <NestableDraggableFlatList
                nestedScrollEnabled={true}
                scrollEnabled={true}
                data={sessionProgramSkills}
                renderItem={({item, getIndex, drag}: RenderItemParams<any>) => (
                  <SessionSkillListItem
                    key={item?.id}
                    sessionProgramId={item.id}
                    drag={drag}
                    navigate={() => {
                      dispatch(
                        navigate({
                          type: 'skill',
                          index: getIndex(),
                        }),
                      );
                      setOpenReorderList(false);
                    }}
                  />
                )}
                keyExtractor={(item, index) =>
                  `draggable-item-${item.id}-${index}`
                }
                onDragEnd={({data, from, to}) => {
                  updateRank(database, data);
                  dispatch(
                    updateOffset({
                      to,
                      from,
                    }),
                  );
                }}
                style={[styles.paddingLTop]}
              />
            ) : (
              <NoResults
                style={[styles.backgroundColorWhite, styles.borderRadius]}
                iconName="view-grid-outline"
                message={translations('no_skill_programs')}
              />
            )}
            <RHSeparator height={Platform.OS === 'android' ? 100 : 50} />
          </NestableScrollContainer>
        </GestureHandlerRootView>
      </SafeAreaView>
    </Drawer>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({patient}: any) => ({
    skills: patient.skills,
    behaviors: patient.behaviors,
  })),
  withObservables([], ({database, behaviors, skills, session}: any) => {
    const behavior_id = behaviors.map((behavior: any) => behavior.id);
    const skill_id = skills.map((skill: any) => skill.id);
    return {
      sessionProgramBehaviors: database
        .get('session_programs')
        .query(
          Q.where('session_id', session.id),
          Q.on(
            'programs',
            Q.and(
              Q.where('id', Q.oneOf(behavior_id)),
              Q.where('state', Q.notEq('archived')),
            ),
          ),
          Q.sortBy('rank', Q.asc),
        )
        .observe(),
      sessionProgramSkills: database
        .get('session_programs')
        .query(
          Q.where('session_id', session.id),
          Q.on(
            'programs',
            Q.and(
              Q.where('id', Q.oneOf(skill_id)),
              Q.where('state', Q.notEq('archived')),
            ),
          ),
          Q.sortBy('rank', Q.asc),
        )
        .observe(),
    };
  }),
)(SessionPrograms);
