import React from 'react';
import {User} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import CheckboxInput from 'src/hook-form-wrapper/checkbox-input';

interface Props {
  name: string;
  items: any[];
  allowSelectAll: boolean;
  search: boolean;
}

const StaffCheckbox = ({
  name,
  items,
  allowSelectAll,
  search = false,
}: Props) => {
  return (
    <CheckboxInput
      name={(name ? name + '.' : '') + 'staff'}
      items={items}
      allowSelectAll={allowSelectAll}
      search={search}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    users: database
      .get(User.table)
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('first_name', Q.asc),
        Q.sortBy('last_name', Q.asc),
      ),
  })),
  withObservables([], ({users}: any) => ({
    items: of(
      users.map((user: User) => {
        return {
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        };
      }),
    ),
  })),
)(StaffCheckbox);
