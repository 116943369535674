import React from 'react';
import * as Yup from 'yup';
import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {Controller, useFormContext} from 'react-hook-form';
import FormInput from 'src/hook-form/form-input';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';
import {IconButton} from 'src/design-system';

interface DisplayProps {
  value: string[];
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'taxonomy',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Taxonomy',
  key,
});

const resolveDisplayValue = (value: string[]) => {
  return value?.join(', ');
};

const TaxonomyDisplay = ({value}: DisplayProps) => {
  const styles = useStyle();

  return (
    <>
      <Text style={[Typography.P3_BOLD]}>Taxonomy</Text>
      <Text
        style={[
          Typography.INPUT,
          styles.textColorPrimary,
          styles.marginSMVertical,
        ]}>
        {resolveDisplayValue(value)}
      </Text>
    </>
  );
};

const TaxonomyInput = ({name}: Props) => {
  const {control} = useFormContext();
  const styles = useStyle();

  return (
    <>
      <Text style={[[Typography.P3_BOLD], styles.marginLVertical]}>
        Taxonomy
      </Text>
      <Controller
        control={control}
        render={({field}) => (
          <View style={[styles.column]}>
            {field.value.map((value: any, index: number) => {
              return (
                <View
                  style={[
                    styles.flex,
                    styles.row,
                    styles.alignCenter,
                    styles.paddingMBottom,
                  ]}>
                  <FormInput
                    onChange={fieldValue => {
                      const newValue = [...field.value];
                      newValue[index] = fieldValue;
                      field.onChange(newValue);
                    }}
                    value={value}
                    label={`Taxonomy ${index + 1}`}
                    showHelper={false}
                  />
                  <IconButton
                    onPress={() => {
                      const newValue = [...field.value];
                      newValue.splice(index, 1);
                      field.onChange(newValue);
                    }}
                    icon={'trash-can'}
                    type={'icon'}
                  />
                </View>
              );
            })}
            <View style={[styles.row, styles.marginLVertical]}>
              <RHButton
                icon="plus-circle-outline"
                mode="outlined"
                color={Colors.RAVEN_WHITE}
                textColor={Colors.RAVEN_BLACK}
                onPress={() => {
                  field.onChange([...field.value, '']);
                }}>
                ADD MORE
              </RHButton>
            </View>
          </View>
        )}
        name={(name ? name + '.' : '') + 'taxonomy'}
      />
    </>
  );
};

export const validation = () => Yup.string().required('Taxonomy is required');

export const Display = TaxonomyDisplay;
export const Input = TaxonomyInput;
export const Value = resolveDisplayValue;

export default TaxonomyInput;
