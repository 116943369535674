import React from 'react';
import {DataItem, RadioInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const statusValues = [
  {value: 'active', title: 'Active', label: 'Active'},
  {value: 'inactive', title: 'Inactive', label: 'Inactive'},
];

export const Import = (
  key: string = 'status',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Status (${statusValues.map(value => value.label).join(', ')})`,
  key,
  type: 'select',
  selectOptions: statusValues,
  validators: [
    {
      validate: 'required',
    },
  ],
});

const resolveDisplayValue = (value: string) => {
  return value === 'active' || !!value ? 'Active' : 'Inactive';
};

const StatusDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Status</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const StatusInput = ({valueKey}: Props) => {
  return (
    <RadioInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'active'}
      name={'Status'}
      required={true}
      buttons={statusValues}
    />
  );
};

export const validation = () => Yup.string().required('Status is required');

export const Display = StatusDisplay;
export const Input = StatusInput;
export const Value = resolveDisplayValue;

export default StatusInput;
