import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {Modal} from 'src/design-system';
import React, {useState} from 'react';
import {Session} from 'src/models';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {FlatList, Text, TouchableOpacity, View} from 'react-native';
import {Checkbox} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import moment from 'moment/moment';
import FilterChip from 'src/design-system/filter-chip';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Separator} from 'src/common-components/atoms';
import {subtractNMonths} from 'src/common-utils/subtractNMonths';
import NoResults from 'src/common-components/noResults';

const AddSessionsModal = ({callback, show: [show, setShow], sessions}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [selectedItems, setSelectedItems] = useState([]);

  return (
    <Modal
      show={[show, setShow]}
      title={translations('add_sessions')}
      footer={
        <>
          <RHButton
            secondary
            mode
            onPress={() => setShow(false)}
            textColor={Colors.RAVEN_BLACK}>
            {translations('cancel_button')}
          </RHButton>
          <Separator width={16} />
          <RHButton
            mode="contained"
            secondary
            onPress={() => callback(selectedItems)}>
            {translations('add_sessions')}
          </RHButton>
        </>
      }>
      <View style={[styles.row]}>
        <View style={[styles.marginMRight]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Select
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Session Date
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Session Time
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Status
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Billing Code
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Place of Service
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Units
          </Text>
        </View>
      </View>
      {!sessions.length ? (
        <NoResults message={'No Sessions Found'} />
      ) : (
        <FlatList
          data={sessions}
          renderItem={({item}: any) => {
            let status = 'missing_values';
            if (item.endTimestamp && !item.submissionTimestamp) {
              status = 'missing_values';
            } else if (item.submissionTimestamp) {
              status = 'completed';
            }
            const durationInMins = moment(item.editedEndTimestamp).diff(
              item.editedStartTimestamp,
              'minutes',
            );
            const units =
              item?.cpt.length &&
              item?.cpt.length === 1 &&
              item?.cpt[0] === 'H2012'
                ? Math.floor(durationInMins / 60)
                : Math.round(durationInMins / 15);

            return (
              <View
                style={[
                  styles.row,
                  styles.alignCenter,
                  styles.paddingSMVertical,
                ]}>
                <TouchableOpacity
                  onPress={() => {
                    if (selectedItems.includes(item.id)) {
                      setSelectedItems(
                        selectedItems.filter(
                          (selected: string) => selected !== item.id,
                        ),
                      );
                    } else {
                      setSelectedItems([...selectedItems, item.id]);
                    }
                  }}
                  style={[styles.row, styles.alignCenter, styles.marginRight]}>
                  <Checkbox.Android
                    status={
                      selectedItems.includes(item.id) ? 'checked' : 'unchecked'
                    }
                    color={Colors.RAVEN_BLACK}
                    uncheckedColor={Colors.RAVEN_BLACK}
                  />
                </TouchableOpacity>
                <View style={[styles.flex]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {moment(item.sessionDate).format('MM/DD/YYYY')}
                  </Text>
                </View>
                <View style={[styles.flex]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {`${moment(item.editedStartTimestamp).format(
                      'hh:mm A',
                    )} - ${moment(item.editedEndTimestamp).format('hh:mm A')}`}
                  </Text>
                </View>
                <View style={[styles.flex, styles.alignCenter]}>
                  <View>
                    <FilterChip
                      textColor={
                        status === 'missing_values'
                          ? Colors.RUBY_700
                          : Colors.SEAFOAM_700
                      }
                      backgroundColor={
                        status === 'missing_values'
                          ? Colors.RUBY_300
                          : Colors.SEAFOAM_300
                      }
                      label={translations(status)}
                    />
                  </View>
                </View>
                <View style={[styles.flex]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {item.cpt.join(', ')}
                  </Text>
                </View>
                <View style={[styles.flex]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {item.location}
                  </Text>
                </View>
                <View style={[styles.flex]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {units || units === 0 ? units : 'No units'}
                  </Text>
                </View>
              </View>
            );
          }}
        />
      )}
    </Modal>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication, claim}: any) => ({
    sessions: database
      .get(Session.table)
      .query(
        Q.where('patient_id', claim.patientId),
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.where('claim_id', Q.eq('')),
        Q.where('submission_timestamp', Q.notEq(null)),
        Q.where('date', Q.gte(subtractNMonths(3).getTime())),
        Q.where('date', Q.lt(new Date().getTime())),
        Q.sortBy('session_date', Q.desc),
      ),
  })),
)(AddSessionsModal);
