import React from 'react';
import {IconButton} from 'react-native-paper';

const BackButton = (props: any) => {
  return (
    <IconButton
      icon="arrow-left"
      onPress={() => {
        if (props.customPress) {
          props.customPress();
          return;
        }
        if (props.navigation.canGoBack()) {
          props.navigation.goBack();
        } else {
          props.navigation.navigate('Dashboard');
        }
      }}
    />
  );
};

export default BackButton;
