import {json, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Location extends Base {
  static table = 'locations';

  static associations = {
    instance: {type: 'belongs_to', key: 'instance_id'},
  };

  @relation('instances', 'instance_id') instance;

  @text('instance_id') instanceId;
  @json('address', value => value) address;
  @text('name') name;
}
