import {createApi} from '@reduxjs/toolkit/query/react';
import {BASE_AUTH_URL} from 'react-native-dotenv';
import {REHYDRATE} from 'redux-persist';
import {baseQueryWithReauth} from 'src/services/base/query';
import {analytics} from 'src/providers/segment';
export const getIndexEndpoint =
  prefix =>
  ({}) =>
    `${BASE_AUTH_URL}/v1/${prefix}`;
export const getEntityEndpoint =
  prefix =>
  ({id}) =>
    `${BASE_AUTH_URL}/v1/${prefix}/${id}`;
export const updateEntityEndpoint =
  prefix =>
  ({data}) => ({
    url: `${BASE_AUTH_URL}/v1/${prefix}/${data.id}`,
    method: 'PUT',
    body: data,
  });
export const identityApi = createApi({
  reducerPath: 'identity',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Me', 'Users', 'Organizations', 'UNAUTHORIZED'],
  endpoints: builder => ({
    getMe: builder.query({
      query: () => `${BASE_AUTH_URL}/v1/me`,
      providesTags: result => {
        return result?.ids ? result.ids.map(id => ({type: 'Me', id})) : ['Me'];
      },
      async onQueryStarted(arg, {queryFulfilled}): Promise<void> | void {
        const {data: payload} = await queryFulfilled;
        const userId = payload.id;
        const name = `${payload.firstName} ${payload.lastName}`;

        analytics.identify(userId, {
          groupId: payload.authorities.map((group: any) => {
            analytics.group(group.id, {
              name: group.name,
              $name: group.name,
              groupId: group.id,
            });
            return group.id;
          }),
          email: payload.email,
          firstName: payload.firstName,
          lastName: payload.lastName,
          name,
          createdAt: Date.parse(payload.createdAt),
          // phone:
        });
      },
    }),
    getIdentity: builder.query({
      query: ({instance, id}) => `${BASE_AUTH_URL}/${instance}/v1/users/${id}`,
      providesTags: result => {
        return [{type: 'Users', id: result?.userId}];
      },
    }),
  }),
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.payload) {
      if (action.type === REHYDRATE) {
        return action.payload[reducerPath];
      }
    }
  },
});

export const {useGetMeQuery, useGetIdentityQuery} = identityApi;
