import moment from 'moment';

export const getAgeInYearsMonths = value => {
  const birthDate = moment(value, 'YYYY-MM-DD');
  const currentDate = moment();

  const yearsDiff = currentDate.diff(birthDate, 'years');
  birthDate.add(yearsDiff, 'years');
  const monthsDiff = currentDate.diff(birthDate, 'months');

  const yearStr = yearsDiff !== 1 ? 'years' : 'year';
  const monthStr = monthsDiff !== 1 ? 'months' : 'month';

  return `${yearsDiff} ${yearStr} and ${monthsDiff} ${monthStr}`;
};
