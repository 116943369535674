import React from 'react';
import {ScrollView} from 'react-native';
import {Portal, Modal} from 'react-native-paper';
import * as colors from 'src/styles/colors';
import {memoize} from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';
import {useDimensions} from '@react-native-community/hooks';

interface props {
  hideModal: () => void;
  visible: boolean;
  children?: JSX.Element[] | JSX.Element;
}

// @ts-ignore
const RHModal = ({hideModal, visible, children = <></>}: props) => {
  const dimensions = useDimensions();

  const styles = getStyles(dimensions.window.width, dimensions.window.height);

  return (
    <>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={[styles.cardBackground]}>
          <ScrollView>{children}</ScrollView>
        </Modal>
      </Portal>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStyles = memoize((width: number, height: number) => {
  return EStyleSheet.create({
    cardBackground: {
      backgroundColor: colors.RAVEN_WHITE,
      borderRadius: 20,
      maxWidth: 720,
      padding: 20,
      alignSelf: 'center',
      width: '100%',
      alignContent: 'center',
    },
    '@media (orientation: landscape)': {
      cardBackground: {
        padding: 20,
      },
    },
  });
});

export default RHModal;
