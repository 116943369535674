import {Form, RadioInput, TextInput} from 'src/design-system';
import React from 'react';

interface Props {
  error?: string;
  enableReinitialize?: boolean;
  handleSubmit?: (payload: any, formik: any) => void;
  validationSchema: any;
  tag: any;
  cancelled: (formik: any) => void;
}

const TagForm = ({
  error,
  enableReinitialize,
  handleSubmit,
  cancelled,
  tag,
  validationSchema,
}: Props) => {
  const initialValues = {
    name: tag ? tag.name : '',
    acronym: tag ? tag.acronym : '',
    tagType: tag ? tag.tagType : '',
    enabled: tag ? tag.enabled : true,
  };

  return (
    <Form
      error={error}
      enableReinitialize={enableReinitialize}
      handleSubmit={handleSubmit}
      cancelled={cancelled}
      initialValues={initialValues}
      validationSchema={validationSchema}>
      <RadioInput
        valueKey={'tagType'}
        name={'Tag Type'}
        buttons={[
          {
            value: 'Assessments',
            title: 'Assessments',
          },
          {
            value: 'Skills',
            title: 'Skills',
          },
          {
            value: 'Behaviors',
            title: 'Behaviors',
          },
          {
            value: 'Related Services',
            title: 'Related Services',
          },
        ]}
      />
      <TextInput valueKey={'name'} name={'Name'} />
      <TextInput valueKey={'acronym'} name={'Acronym'} />
    </Form>
  );
};

export default TagForm;
