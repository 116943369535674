import React from 'react';
import {Image} from 'react-native';
import {DataItem} from 'src/design-system';
import {Text, View} from 'react-native';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {mergeMap} from 'rxjs';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {Typography} from 'src/styles';
import {useFormContext, useWatch} from 'react-hook-form';
import OrganizationDisplay from 'src/modules/organization/components/organization-display';
import ClientInfoDisplay from 'src/modules/organization/components/client-info-display';
import SessionInfoDisplay from 'src/modules/organization/components/session-info-display';
import {Dimensions} from 'react-native';
import RenderFormInputs from 'src/modules/form-builder/components/render-form-inputs';

const ReviewTemplateForm = ({organization}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const {control} = useFormContext();
  const values = useWatch({
    control,
  });
  const screenWidth = Dimensions.get('window').width;

  const imageWidth = screenWidth * 0.6;
  const imageHeight = imageWidth;
  return (
    <View style={[styles.paddingHorizontal]}>
      <DataItem>
        <Text style={[Typography.H6]}>Note Template Title:</Text>
        <Text style={[Typography.P3]}>{values.title}</Text>
      </DataItem>
      {values.organizationInformation ? (
        <OrganizationDisplay
          currentNoteTemplate={values}
          organization={{
            logo: organization?.logo || '',
            name: organization?.name || 'Raven Health',
            address: organization?.address || {
              fullAddress: '135 W 50th St New York, NY 10020 US',
              streetLine: '135 W 50th St',
              city: 'New York',
              state: 'NY',
              country: 'US',
              postalCode: '10020',
            },
            billingAddress: organization?.billingAddress || {
              fullAddress: '135 W 50th St New York, NY 10020 US',
              streetLine: '135 W 50th St',
              city: 'New York',
              state: 'NY',
              country: 'US',
              postalCode: '10020',
            },
            tin: organization?.tin || '12345',
            npi: organization?.npi || '12345',
          }}
        />
      ) : null}

      {values.clientInformation ? (
        <ClientInfoDisplay
          currentNoteTemplate={values}
          patient={{
            firstName: 'Client',
            middleName: 'Cate',
            lastName: 'Cate',
            gender: 'Female',
            birthDate: new Date('2020-02-14'),
            ssn: '111-11-1111',
            staff: [],
          }}
          careTeam={[
            {
              firstName: 'Staff',
              lastName: 'Name',
              credential: 'Staff Credential',
              licenseNumber: '########',
            },
          ]}
          diagnoses={[{diagnosis: 'F80.0'}]}
        />
      ) : null}

      {values.sessionInformation ? (
        <SessionInfoDisplay
          currentNoteTemplate={values}
          session={{
            sessionDate: new Date(),
            address: {
              fullAddress: '135 W 50th St New York, NY 10020 US',
              streetLine: '135 W 50th St',
              city: 'New York',
              state: 'NY',
              country: 'US',
              postalCode: '10020',
            },
          }}
          appointment={{
            location: '12',
            staffParticipant: {
              cpt: ['92507', '92508'],
            },
          }}
          staff={[
            {
              firstName: 'Staff',
              lastName: 'Name',
              credential: 'Staff Credential',
              licenseNumber: '########',
            },
          ]}
        />
      ) : null}

      {values.clinicalNote ? (
        <>
          <Text style={[Typography.H5]}>{translations('clinical_note')}</Text>
          <View
            style={[
              styles.marginLVertical,
              styles.cardFlat,
              styles.padding,
              styles.borderRadius,
            ]}>
            <View
              style={[
                styles.row,
                styles.alignCenter,
                styles.flex,
                styles.flexWrap,
              ]}>
              <Text style={[Typography.P3]}>{values.template}</Text>
            </View>
          </View>
        </>
      ) : null}

      {values.sessionData ? (
        <>
          <Text style={[Typography.H5]}>{translations('session_data')}</Text>
          <View style={styles.flex}>
            <View
              style={[
                styles.row,
                styles.paddingLTop,
                styles.flex,
                styles.alignCenter,
                styles.justifyCenter,
              ]}>
              <View
                style={[styles.row, styles.alignCenter, styles.justifyCenter]}>
                <Image
                  source={require('../../../../../assets/note-template-graph.png')}
                  resizeMode="center"
                  style={{width: imageWidth, height: imageHeight}}
                />
              </View>
            </View>
          </View>
        </>
      ) : null}

      {values.additionalFields ? (
        <View style={[styles.paddingVertical]}>
          <Text style={[Typography.H5]}>
            {translations('additional_fields')}
          </Text>
          <View
            style={[
              styles.marginLVertical,
              styles.cardFlat,
              styles.padding,
              styles.borderRadius,
            ]}>
            <RenderFormInputs values={values.formBuilder} mode={'preview'} />
          </View>
        </View>
      ) : (
        <></>
      )}

      {values.signature ? (
        <>
          <Text style={[Typography.H5]}>{translations('sigs')}</Text>
          <View style={styles.flex}>
            <View
              style={[
                styles.row,
                styles.paddingLTop,
                styles.flex,
                styles.alignCenter,
                styles.justifyCenter,
              ]}>
              <View
                style={[styles.row, styles.alignCenter, styles.justifyCenter]}>
                <Image
                  source={require('../../../../../assets/signatures.png')}
                  resizeMode="stretch"
                  style={{width: imageWidth, height: imageHeight}}
                />
              </View>
            </View>
          </View>
        </>
      ) : null}
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    organization: database
      ?.get('instances')
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
)(ReviewTemplateForm);
