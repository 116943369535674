import React, {useRef} from 'react';
import {
  FlatList,
  ScrollViewProps,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import moment from 'moment';
import _ from 'lodash';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

const MonthView = ({
  open,
  setOpen,
  markedDates,
  selectedDate,
  setSelectedDate,
  initialData,
  setInitialData,
}: any) => {
  const styles = useStyle();
  const ref = useRef();

  const handleScroll: ScrollViewProps['onScroll'] = event => {
    const offset = event.nativeEvent.contentOffset.y;
    const visibleLength = event.nativeEvent.layoutMeasurement.height;
    const contentLength = event.nativeEvent.contentSize.height;

    const isScrollAtStart = offset === 0;
    const isScrollAtEnd = visibleLength + offset >= contentLength;

    if (isScrollAtStart) {
      setInitialData(state => [
        moment(state[0]).subtract(2, 'month').format('YYYY-MM'),
        moment(state[0]).subtract(1, 'month').format('YYYY-MM'),
        ...state,
      ]);
    }

    if (isScrollAtEnd) {
      setInitialData(state => [
        ...state,
        moment(state[state.length - 1])
          .add(1, 'month')
          .format('YYYY-MM'),
        moment(state[state.length - 1])
          .add(2, 'month')
          .format('YYYY-MM'),
      ]);
    }
  };

  return (
    <FlatList
      ref={ref}
      renderItem={({item}) => {
        const date = moment(item);
        return (
          <View key={item} style={[styles.paddingLVertical]}>
            <View style={[styles.row, styles.justifyCenter]}>
              <Text style={[Typography.P3]}>
                {moment(item).format('MMMM YYYY')}
              </Text>
            </View>
            {_.range(0, Math.ceil((date.daysInMonth() + date.day()) / 7)).map(
              week => {
                const days = week * 7;
                return (
                  <View
                    key={`${date.format('YYYY')}-${date.format(
                      'MM',
                    )}-week-${week}`}
                    style={[styles.row]}>
                    {_.range(days, days + 7).map(day => {
                      const adjustedDay = day + 1 - date.day();
                      const dateItems =
                        markedDates[
                          `${date.format('YYYY')}-${date.format('MM')}-${
                            adjustedDay < 10 ? '0' + adjustedDay : adjustedDay
                          }`
                        ]?.length || 0;
                      const range = dateItems >= 5 ? 5 : dateItems;
                      const shouldShowDate =
                        adjustedDay <= 0 || adjustedDay > date.daysInMonth();
                      const momentSelectedDate = moment(selectedDate);
                      return (
                        <TouchableOpacity
                          key={`${date.format('YYYY')}-${date.format('MM')}-${
                            adjustedDay > 10 ? adjustedDay : '0' + adjustedDay
                          }-day`}
                          style={[
                            styles.flex,
                            styles.paddingLVertical,
                            styles.justifyCenter,
                            styles.alignCenter,
                          ]}
                          onPress={() => {
                            const selectableDate = moment(
                              `${date.format('YYYY')}-${date.format('MM')}-${
                                adjustedDay >= 10
                                  ? adjustedDay
                                  : '0' + adjustedDay
                              }`,
                            );
                            if (selectableDate.isValid()) {
                              setSelectedDate(selectableDate.toDate());
                              setOpen(!open);
                            }
                          }}>
                          <View
                            style={[
                              styles.justifyCenter,
                              styles.alignCenter,
                              styles.date,
                              `${date.format('YYYY-MM')}-${
                                adjustedDay < 10
                                  ? '0' + adjustedDay
                                  : adjustedDay
                              }` ===
                              `${momentSelectedDate.format('YYYY-MM-DD')}`
                                ? styles.backgroundColorSecondary700
                                : {},
                            ]}>
                            <Text
                              style={[
                                Typography.P3,
                                styles.textAlignCenter,
                                `${date.format('YYYY-MM')}-${
                                  adjustedDay < 10
                                    ? '0' + adjustedDay
                                    : adjustedDay
                                }` ===
                                `${momentSelectedDate.format('YYYY-MM-DD')}`
                                  ? styles.textColorWhite
                                  : {},
                              ]}>
                              {shouldShowDate ? '' : `${adjustedDay}`}
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.row,
                              styles.justifyCenter,
                              styles.alignCenter,
                              styles.paddingSM,
                            ]}>
                            {!shouldShowDate ? (
                              <>
                                {_.range(0, range).map(() => (
                                  <View
                                    style={[
                                      styles.dot,
                                      styles.marginXS,
                                      {
                                        backgroundColor: Colors.RAVEN_BLACK,
                                      },
                                    ]}
                                  />
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </View>
                        </TouchableOpacity>
                      );
                    })}
                  </View>
                );
              },
            )}
          </View>
        );
      }}
      onEndReached={null}
      initialScrollIndex={2}
      onScroll={handleScroll}
      keyExtractor={item => `${item}`}
      removeClippedSubviews={true}
      data={initialData}
      showsVerticalScrollIndicator={false}
      scrollsToTop={false}
      getItemLayout={(data, index) => {
        const item = moment(data[index]);
        return {
          length: Math.ceil((item.daysInMonth() + item.day()) / 7) * 78.8,
          offset: 394 * 2,
          index,
        };
      }}
      scrollEnabled={true}
      horizontal={false}
      maintainVisibleContentPosition={{
        minIndexForVisible: 0,
        // autoscrollToTopThreshold
      }}
      onScrollToIndexFailed={() => {}}
      style={[styles.backgroundColorWhite]}
    />
  );
};

export default MonthView;
