import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import withState from 'src/redux/wrapper';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  prompts?: any;
}
export const Import = (
  prompts: any[],
  key: string = 'prompts',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Prompts',
  key,
  type: 'select',
  selectOptions: prompts,
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('prompts')}</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    promptItems: database
      ?.get('prompts')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.where('prompt_type', 'Prompts'),
        Q.sortBy('rank', Q.asc),
      ),
  })),
  withObservables([], ({promptItems}: any) => ({
    prompts: of(
      promptItems
        ?.map((prompt: any) => ({
          label: prompt?.name,
          value: prompt?.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ),
  })),
)(({name, prompts}: Props) => {
  const translations = useTranslations();
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'prompts'}
      label={translations('prompts')}
      required={false}
      items={prompts}
      multiple={true}
    />
  );
});

export const validation = () => Yup.string().required('Prompts are required');

export default Input;
