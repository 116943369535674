import React, {useState} from 'react';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {User} from 'src/models';
import {Text, View} from 'react-native';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useStyle} from 'src/providers/style';
import {Separator} from 'src/common-components/atoms';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useTranslations} from 'src/providers/translation';
import moment from 'moment';
import {MoreMenu} from 'src/design-system';
import {Colors, Typography} from 'src/styles';

const SessionNoteListItem = ({
  item,
  createdBy,
  canEdit = true,
  canDelete = true,
  showAuthorsAndTimestamps = false,
  setEditingNote = () => {},
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const [edit, setEdit] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: {
      note: item.description,
    },
  });

  const watchedNote = useWatch({
    name: 'note',
    control: methods.control,
  });

  const handleUpdate = async () => {
    item.updateEntity({
      description: watchedNote,
    });
    setEdit(false);
    setEditingNote(false);
  };

  const handleDelete = async () => {
    item.delete();
  };

  const renderHelper = () => {
    let helperStr = moment(item.updatedAt).format('lll');
    if (item.updatedAt > item.createdAt) {
      helperStr += ' (edited)';
    }
    return helperStr;
  };

  return (
    <FormProvider {...methods}>
      <View style={[styles.row, styles.alignCenter]}>
        <View style={[styles.flex]}>
          {edit ? (
            <HookFormInput
              name={'note'}
              label={`${createdBy?.firstName} ${createdBy?.lastName}`}
              helper={renderHelper()}
              numberOfLines={3}
            />
          ) : (
            <View>
              {showAuthorsAndTimestamps ? (
                <View style={[styles.row, styles.alignCenter]}>
                  <Text
                    style={[
                      Typography.H6,
                    ]}>{`${createdBy?.firstName} ${createdBy?.lastName}`}</Text>
                  <Separator width={10} />
                  <Text
                    style={[
                      Typography.CAPTION,
                      styles.textColorSecondary,
                      styles.alignSelfCenter,
                    ]}>
                    {renderHelper()}
                  </Text>
                </View>
              ) : (
                <></>
              )}
              <Text style={[Typography.P3]}>{watchedNote}</Text>
            </View>
          )}
        </View>
        {canEdit ? (
          <View style={[styles.paddingMHorizontal, styles.alignSelfStart]}>
            <MoreMenu
              canEdit={canEdit}
              editCallback={() => {
                setEdit(true);
                setEditingNote(true);
              }}
              canDelete={canDelete}
              deleteCallback={() => handleDelete()}
            />
          </View>
        ) : (
          <></>
        )}
      </View>
      <View />
      {edit ? (
        <View style={[styles.row, styles.justifyEnd, styles.paddingMBottom]}>
          <View style={[styles.row, styles.justifyCenter, styles.alignCenter]}>
            <RHButton
              secondary
              mode="outlined"
              textColor={Colors.RAVEN_BLACK}
              onPress={() => {
                setEdit(false);
                setEditingNote(false);
              }}>
              {translations('form_cancel')}
            </RHButton>
            <Separator width={10} />
            <RHButton mode="contained" onPress={() => handleUpdate()}>
              {translations('save_button')}
            </RHButton>
          </View>
        </View>
      ) : (
        <></>
      )}
    </FormProvider>
  );
};
export default compose(
  withDatabase,
  withObservables(['item'], ({item, database}: any) => ({
    createdBy: database.get(User.table).findAndObserve(item?.createdBy),
  })),
)(SessionNoteListItem);
