import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {TextInputMask} from 'react-native-masked-text';
import {IDeveloperField} from 'dromo-uploader-react';
import FormInput from 'src/hook-form-wrapper/form-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
  label?: string;
}

interface Props {
  name?: string;
  label?: string;
  disabled?: boolean;
}

export const Import = (
  key: string = 'mobilePhone',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Phone Number (###) ###-####',
  key,
  validators: [],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value, label}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>
        {label ? label : translations('phone_number')}
      </Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, disabled = false, label}: Props) => {
  const translations = useTranslations();

  return (
    <FormInput
      name={(name ? name + '.' : '') + 'mobilePhone'}
      label={label ? label : translations('phone_number')}
      placeholder={label ? label : translations('phone_number')}
      required={false}
      keyboardType={'phone-pad'}
      disabled={disabled}
      render={props => (
        <TextInputMask
          type={'custom'}
          {...props}
          options={{
            mask: '(999) 999-9999',
          }}
        />
      )}
    />
  );
};

export const validation = () =>
  Yup.string().required('Phone number is required');

export default Input;
