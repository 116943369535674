import {date, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Diagnosis extends Base {
  static table = 'diagnoses';

  static associations = {
    patients: {type: 'belongs_to', foreignKey: 'patient_id'},
    instance_diagnoses: {
      type: 'belongs_to',
      foreignKey: 'instance_diagnosis_id',
    },
  };

  @relation('patients', 'patient_id') patient;
  @relation('instance_diagnoses', 'instance_diagnosis_id') instanceDiagnosis;

  @text('diagnosis') diagnosis;
  @text('severity') severity;
  @date('diagnosis_date') diagnosisDate;
  @text('archived') archived;
  @text('instance_diagnosis_id') instanceDiagnosisId;
  @text('rank') rank;
}
