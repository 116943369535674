import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const behaviorCollectionMethods = [
  {label: 'Duration', title: 'Duration', value: 'duration'},
  {label: 'Frequency', title: 'Frequency', value: 'frequency'},
  {label: 'Interval', title: 'Interval', value: 'interval'},
  {label: 'Rate', title: 'Rate', value: 'rate'},
];
export const Import = (
  key: string = 'method',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Collection Method',
  key,
  type: 'select',
  selectOptions: behaviorCollectionMethods,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return behaviorCollectionMethods.find(item => item.value === value)?.label;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Collection Method</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'method'}
      label={'Collection Method'}
      required={false}
      items={behaviorCollectionMethods}
    />
  );
};

export const validation = () =>
  Yup.string().required('Collection Method are required');

export default Input;
