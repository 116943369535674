import React from 'react';
import {FlatList, Text, View} from 'react-native';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import moment from 'moment';
import {Colors, Typography} from 'src/styles';
import EnvironmentalFactorListItem from '../environmental-factor-list-item';
import {useStyle} from 'src/providers/style';

interface Props {
  envFactors: any[];
  canEdit: boolean;
  editEnvironmentalFactor: (item: any) => void;
  canDelete: boolean;
  deleteSelected: (item: any) => void;
}

const EnvironmentalFactorTimeline = ({
  editEnvironmentalFactor,
  canEdit,
  envFactors,
  canDelete,
  deleteSelected,
}: Props) => {
  const styles = useStyle();

  return (
    <FlatList
      data={envFactors}
      keyExtractor={environmentalFactor => environmentalFactor?.id}
      renderItem={({item: environmentalFactor, index}: any) => {
        return (
          <TimelineItem
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}>
            <TimelineOppositeContent>
              <View
                style={[
                  styles.borderRadius,
                  environmentalFactor?.type === 'factor'
                    ? {
                        backgroundColor: Colors.BLAZE_ORANGE,
                      }
                    : {
                        backgroundColor: Colors.RAVEN_BLACK,
                      },
                  // styles.elevation,
                ]}>
                <Text
                  style={[
                    Typography.P3_MEDIUM,
                    styles.textColorWhite,
                    styles.textAlignCenter,
                    styles.paddingMVertical,
                  ]}>
                  {moment(environmentalFactor?.date).format('l')}
                </Text>
              </View>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                sx={
                  environmentalFactor?.type === 'factor'
                    ? {
                        backgroundColor: Colors.BLAZE_ORANGE,
                        mt: '18px',
                        mb: '4px',
                      }
                    : {
                        backgroundColor: Colors.RAVEN_BLACK,
                        mt: '18px',
                        mb: '4px',
                      }
                }
              />
              {index < envFactors?.length - 1 && (
                <TimelineConnector
                  sx={{
                    backgroundColor: Colors.RAVEN_BLACK,
                    width: '.25px',
                  }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <View
                style={[
                  styles.borderRadius,
                  styles.backgroundColorPrimary50,
                  styles.textColorPrimary,
                  styles.paddingLHorizontal,
                  styles.marginBottom,
                  // styles.elevation,
                ]}>
                <EnvironmentalFactorListItem
                  envFactItem={environmentalFactor}
                  canEdit={canEdit}
                  editClk={() => editEnvironmentalFactor(environmentalFactor)}
                  canDelete={canDelete}
                  deleteSelected={() => deleteSelected(environmentalFactor)}
                />
              </View>
            </TimelineContent>
          </TimelineItem>
        );
      }}
    />
  );
};

export default EnvironmentalFactorTimeline;
