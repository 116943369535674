import React, {useEffect, useState} from 'react';
import {ScrollView, Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import PayerStaffItem, {
  PayerStaffItemDisplay,
} from 'src/modules/billing/components/payer-staff-item';
import {useFormContext, useWatch} from 'react-hook-form';
import NoResults from 'src/common-components/noResults';
import _ from 'lodash';
import {Modal} from 'src/design-system';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {useTranslations} from 'src/providers/translation';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import UserCredentialSelect from 'src/hook-form-inputs/user-credential';

export const PayerStaffDisplay = ({values}: any) => {
  const styles = useStyle();

  return (
    <>
      {values.staffIds ? (
        <View style={[styles.marginLVertical]}>
          <Text style={[Typography.P3_BOLD]}>Assigned Staff</Text>
          <View style={[styles.marginLVertical]}>
            <View
              style={[
                styles.row,
                styles.borderBottom,
                styles.alignCenter,
                styles.justifyCenter,
                styles.flex,
              ]}>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width]}>
                  <Text style={[Typography.P3]}>Staff Name</Text>
                </View>
              </View>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width]}>
                  <Text style={[Typography.P3]}>ID #</Text>
                </View>
              </View>
            </View>
            {values.assignedCredentials.map((value: any) => {
              return <PayerStaffItemDisplay value={value} />;
            })}
          </View>
        </View>
      ) : null}
    </>
  );
};

const PayerStaffForm = ({}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [showDelete, setShowDelete] = useState(false);
  const {control, getValues, setValue} = useFormContext();

  const credentials = useWatch({control, name: 'credentials'});
  const assignedCredentials = useWatch({control, name: 'assignedCredentials'});
  const staffIds = useWatch({control, name: 'staffIds'});
  const deleteCallback = (item: any) => {
    setValue(
      'credentials',
      credentials.filter(val => val !== item),
    );
    setShowDelete(false);
  };

  useEffect(() => {
    const updatedCredentials = [...getValues().assignedCredentials];

    const differenceStaff = _.differenceWith(
      updatedCredentials,
      credentials,
      (value1: any, value2: string) => {
        return value1.id === value2;
      },
    );
    const differenceCredentials = _.differenceWith(
      credentials,
      updatedCredentials,
      (value1: any, value2: any) => {
        return value1 === value2.id;
      },
    );

    for (const payerCredentials of differenceStaff) {
      const index = updatedCredentials.findIndex(
        (updatedCredential: any) =>
          updatedCredential.id === payerCredentials.id,
      );

      updatedCredentials.splice(index, 1);
    }
    for (const payerCredentials of differenceCredentials) {
      updatedCredentials.push({
        id: payerCredentials,
        legacyId: '',
      });
    }

    setValue('assignedCredentials', updatedCredentials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  return (
    <ScrollView contentContainerStyle={[styles.padding]}>
      <Modal
        show={[showDelete, setShowDelete]}
        title={'Remove Staff'}
        footer={
          <>
            <RHButton
              secondary
              mode="outlined"
              textColor={Colors.RAVEN_BLACK}
              onPress={() => setShowDelete(false)}>
              CANCEL
            </RHButton>
            <RHSeparator width={16} />
            <RHButton
              secondary
              mode="contained"
              onPress={() => deleteCallback(showDelete)}>
              YES, REMOVE
            </RHButton>
          </>
        }>
        <View style={[styles.paddingVertical]}>
          <Text style={[Typography.P3]}>
            Are you sure you want to remove this staff member from this payer?
          </Text>
        </View>
      </Modal>
      <View style={[styles.paddingLBottom]}>
        <Text style={[Typography.H5, styles.textColorPrimary]}>
          {translations('assigned_staff')}
        </Text>
      </View>
      <View style={[styles.flex]}>
        <HookFormSwitchInput
          name={'staffIds'}
          label={'Unique Payer Staff ID Required'}
        />
      </View>
      {staffIds ? (
        <>
          <UserCredentialSelect multiple={true} />
          {credentials.length ? (
            <View
              style={[
                styles.row,
                styles.flex,
                styles.paddingLHorizontal,
                styles.alignCenter,
              ]}>
              <Text style={[styles.flex, Typography.P2_BOLD]}>Staff Name</Text>
              <Text style={[Typography.P2_BOLD]}>ID #</Text>
              <View style={[styles.flex]} />
            </View>
          ) : (
            <NoResults message={'No Staff Assigned'} />
          )}
          {assignedCredentials.map((item: any, index: any) => (
            <PayerStaffItem
              key={item.id}
              id={item.id}
              item={item}
              index={index}
              onClose={() => setShowDelete(item.id)}
            />
          ))}
        </>
      ) : (
        <NoResults
          iconName={'text-box'}
          message={'This payer does not require unique Staff ID Numbers'}
        />
      )}
    </ScrollView>
  );
};

export default PayerStaffForm;
