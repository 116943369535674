import React, {useState} from 'react';
import {FlatList, ScrollView} from 'react-native';
import NoResults from 'src/common-components/noResults';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {of, mergeMap} from 'rxjs';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import {BaseIndexScreen, DeleteModal, Modal} from 'src/design-system';
import PatientAuthorizationItem from 'src/modules/patients/components/authorization-item';
import PatientAuthorizationForm from 'src/modules/patients/components/authorization-form';
import * as Yup from 'yup';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import moment from 'moment';
import {Authorization, Instance} from 'src/models';
import {cptCodes} from 'src/common-utils/cptCodes';
import {useStyle} from 'src/providers/style';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import {Q} from '@nozbe/watermelondb';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

const Authorizations = ({patient, authorizations, role, instance}: any) => {
  const database = useDatabase();
  const styles = useStyle();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [submitting, setSubmitting] = useState(false);
  const [edit, setEdit] = useState<any | boolean>(false);
  const [itemId, setItemId] = useState<any | string>('');
  const [showDelete, setShowDelete] = useState<any | boolean>(false);

  const initialValues = {
    authorizationNumber: '',
    startDate: new Date(),
    endDate: new Date(),
    payerId: '',
    cpt97151: '0',
    cpt97152: '0',
    cpt97153: '0',
    cpt97154: '0',
    cpt97155: '0',
    cpt97156: '0',
    cpt97157: '0',
    cpt0373T: '0',
    cpt0362T: '0',
    cptT1024: '0',
    cpt92507: '0',
    cpt92508: '0',
    cpt92526: '0',
    cpt92609: '0',
    cpt92522: '0',
    cpt92523: '0',
    cpt92610: '0',
    cptH2019: '0',
    cptT2024: '0',
    cptH0032: '0',
    cpt90791: '0',
    cpt90837: '0',
    cptH0031: '0',
    cptH2012: '0',
    cptS5111: '0',
    cptH2014: '0',
    cptH0046: '0',
  };

  const validationSchema = Yup.object({
    authorizationNumber: Yup.string().required(
      'Authorization Number is required',
    ),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date()
      .required('End Date is required')
      .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
    payerId: Yup.string(),
    // TODO: Look up these names
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (payload: any) => {
    if (edit) {
      let value = {};
      for (const code of cptCodes) {
        value[code] = parseInt(payload[code], 10);
      }
      const entity = await database.get(Authorization.table).find(itemId);
      await entity.updateEntity({
        authorizationNumber: payload.authorizationNumber,
        startDate: payload.startDate,
        endDate: payload.endDate,
        payerId: payload.payerId,
        ...value,
      });
    } else {
      await database.write(async () => {
        await database.get(Authorization.table).create(entity => {
          entity.partition = selectedGroup;
          entity.authorizationNumber = payload.authorizationNumber;
          entity.startDate = payload.startDate;
          entity.endDate = payload.endDate;
          entity.payerId = payload.payerId;
          entity.patientId = patient.id;
          for (const code of cptCodes) {
            entity[code] = parseInt(payload[code], 10);
          }
          entity.patient.id = patient.id;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
    setSubmitting(false);
    methods.reset(initialValues);
    setEdit(false);
  };

  const handleCancel = () => {
    setSubmitting(!submitting);
    methods.reset(initialValues);
    setEdit(false);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (entity: any) => {
    entity.delete(userId);
    setShowDelete(false);
  };

  return (
    <BaseIndexScreen
      title={'Authorizations'}
      canAdd={role?.authorizationCreate}
      addButton={
        <RHButton
          onPress={() => {
            setEdit(false);
            methods.reset(initialValues);
            setSubmitting(true);
          }}
          mode={'contained'}
          secondary
          icon="plus">
          ADD AUTHORIZATION
        </RHButton>
      }>
      <Modal
        show={[submitting, setSubmitting]}
        title={edit ? 'Edit Authorization' : 'New Authorization'}>
        <FormProvider {...methods}>
          <PatientAuthorizationForm
            instance={instance}
            handleCancel={handleCancel}
            handleSubmit={methods.handleSubmit(handleSubmit)}
          />
        </FormProvider>
      </Modal>
      <DeleteModal
        model={'Authorization'}
        text={
          showDelete
            ? `${moment(showDelete.startDate).format('YYYY-MM-DD')} to ${moment(
                showDelete.endDate,
              ).format('YYYY-MM-DD')} Authorization`
            : null
        }
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <ScrollView style={[styles.flex]}>
        {authorizations.length === 0 ? (
          <NoResults message={'Client has no authorizations.'} />
        ) : (
          <FlatList
            scrollEnabled={true}
            data={[...authorizations].sort(
              (a: any, b: any) => a.startDate - b.startDate,
            )}
            keyExtractor={item => item.id}
            renderItem={({item}: any) => (
              <PatientAuthorizationItem
                authorizationItem={item}
                canEdit={role?.authorizationEdit}
                canDelete={role?.authorizationDelete}
                patient={patient}
                editSelected={() => {
                  setEdit(true);
                  setItemId(item.id);
                  methods.reset({
                    authorizationNumber: item.authorizationNumber,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    payerId: item.payerId,
                    cpt97151: item.cpt97151 || '0',
                    cpt97152: item.cpt97152 || '0',
                    cpt97153: item.cpt97153 || '0',
                    cpt97154: item.cpt97154 || '0',
                    cpt97155: item.cpt97155 || '0',
                    cpt97156: item.cpt97156 || '0',
                    cpt97157: item.cpt97157 || '0',
                    cpt0373T: item.cpt0373T || '0',
                    cpt0362T: item.cpt0362T || '0',
                    cptT1024: item.cptT1024 || '0',
                    cpt92507: item.cpt92507 || '0',
                    cpt92508: item.cpt92508 || '0',
                    cpt92526: item.cpt92526 || '0',
                    cpt92609: item.cpt92609 || '0',
                    cpt92522: item.cpt92522 || '0',
                    cpt92523: item.cpt92523 || '0',
                    cpt92610: item.cpt92610 || '0',
                    cptH2019: item.cptH2019 || '0',
                    cptT2024: item.cptT2024 || '0',
                    cptH0032: item.cptH0032 || '0',
                    cpt90791: item.cpt90791 || '0',
                    cpt90837: item.cpt90837 || '0',
                    cptH0031: item.cptH0031 || '0',
                    cptH2012: item.cptH2012 || '0',
                    cptS5111: item.cptS5111 || '0',
                    cptH2014: item.cptH2014 || '0',
                    cptH0046: item.cptH0046 || '0',
                  });
                  setSubmitting(true);
                }}
                deleteSelected={() => {
                  setShowDelete(item);
                }}
              />
            )}
          />
        )}
      </ScrollView>
    </BaseIndexScreen>
  );
};

export default compose(
  withState,
  withDatabase,
  withObservables([], ({patient, authentication, database}: any) => ({
    authorizations: patient?.activeAuthorizations ?? of([]),
    instance: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
)(Authorizations);
