import {Text} from 'react-native';
import {Typography} from 'src/styles';
import React from 'react';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';

const NpiDisplay = ({credential}: any) => {
  return <Text style={[Typography.P3]}>NPI #: {credential.npi}</Text>;
};

export default compose(
  withObservables([], ({user}: any) => ({
    credential: user.id ? user.credential : of({npi: '######'}),
  })),
)(NpiDisplay);
