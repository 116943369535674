import React, {useContext} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import FieldDetails from 'src/modules/form-builder/components/canvas/field-details';
import {FormContext} from '../../../context/form-context';
import {useStyle} from 'src/providers/style';
import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {IconButton} from 'react-native-paper';

const SortableItem = ({id, item, rowId, index}: any) => {
  const styles = useStyle();
  const {editField, handleSetEditField, removeField} = useContext(FormContext);

  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({
      id: id,
      data: {
        id: id,
        fieldData: item,
      },
    });

  const borderColor =
    editField?.id === item?.id ? Colors.SECONDARY_800 : '#ddd';

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: `1px solid ${borderColor}`,
    borderRadius: 15,
    margin: 10,
    paddingHorizontal: 8,
    paddingVertical: 4,
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundColor: Colors.RAVEN_WHITE,
    minWidth: 1 / 3,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <View
        style={[
          styles.row,
          styles.paddingMHorizontal,
          styles.paddingSMTop,
          styles.justifySpaceBetween,
          styles.alignCenter,
        ]}>
        <View style={[styles.paddingMHorizontal, styles.paddingMTop]}>
          <Text style={[Typography.P4, styles.textColorSecondary]}>
            {item.name}
          </Text>
        </View>
        <View style={[styles.row]}>
          {item?.componentType !== 'SEPARATOR' ? (
            <IconButton
              size={20}
              icon="pencil"
              color={Colors.PRIMARY_900}
              onPress={() => {
                handleSetEditField(item, rowId, index);
              }}
            />
          ) : (
            <></>
          )}
          <IconButton
            size={20}
            color={Colors.PRIMARY_900}
            icon="trash-can"
            onPress={() => {
              removeField(id);
            }}
          />
        </View>
      </View>
      <View style={[styles.paddingBottom, styles.paddingHorizontal]}>
        <FieldDetails field={item} />
      </View>
    </div>
  );
};

export default SortableItem;
