import React from 'react';
import {View, Text, ScrollView, Platform} from 'react-native';
import {DataTable} from 'react-native-paper';
import EStyleSheet from 'react-native-extended-stylesheet';
import {useDimensions} from '@react-native-community/hooks';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import moment from 'moment';
import _ from 'lodash';

import {Colors, Typography} from 'src/styles';
import {compareRank} from 'src/common-utils/rank';
import TaskAnalysisTargetColumnCell from './column-cell';

const TaskAnalysisTable = ({
  program,
  sets,
  targets,
  events,
  type = undefined,
  session,
  setEventId,
}: any) => {
  const dimensions = useDimensions();
  const sortedTargets = [...(targets || [])].sort(compareRank);
  const eventsBySetId = _.groupBy(events, 'set.id');
  const styles = getStyles(dimensions.window.width);
  const targetAggregate = {};
  const eventsByTargetId = _.groupBy(events, 'target.id');

  for (const target of targets) {
    let sum = 0;
    if (eventsByTargetId?.[target.id]) {
      for (const event of eventsByTargetId?.[target.id] || []) {
        sum = sum + (event.value === 'success' ? 1 : 0);
      }
      targetAggregate[target.id] = Math.ceil(
        (sum * 100) / (eventsByTargetId?.[target.id]?.length || 1),
      );
    }
  }
  if (type === 'edit-modal') {
    return (
      <ScrollView horizontal>
        <DataTable style={styles.table}>
          <DataTable.Header style={[styles.head, styles.firstRow]}>
            <View
              style={[
                styles.justifyCenter,
                styles.setHead,
                styles.borderRight,
              ]}>
              <Text style={styles.titleTxt}>{program?.name}</Text>
            </View>
            {sortedTargets.map((target: any, index: number) => {
              return (
                <DataTable.Title
                  style={[
                    styles.justifyCenter,
                    styles.borderRight,
                    styles.setHead,
                  ]}
                  textStyle={styles.titleTxt}
                  numberOfLines={3}
                  key={`taskanalysis-table-header-${target?.name}-${index}`}>
                  {target?.name} - {index + 1}
                </DataTable.Title>
              );
            })}
            <DataTable.Title
              style={[
                styles.justifyCenter,
                styles.alignCenter,
                styles.setHead,
              ]}>
              <Text style={styles.titleTxt}>Score</Text>
            </DataTable.Title>
          </DataTable.Header>
          {sets?.map((set: any, index: number) => {
            const setEvents = eventsBySetId[set?.id];
            return (
              <DataTable.Row
                style={[styles.head, styles.middleHead]}
                key={`taskanalysis-table-set-${set?.id}-${index}`}>
                <View
                  style={[
                    styles.justifyCenter,
                    styles.setHead,
                    styles.borderRight,
                  ]}>
                  <Text style={styles.cellBoldTxt}>{'Set ' + (index + 1)}</Text>
                  <Text style={styles.cellTxt}>
                    {moment(set?.startTimestamp).format('ll')}
                  </Text>
                  <Text style={[styles.cellTxt, styles.hideMobile]}>
                    {moment(set?.startTimestamp).format('hh:mm')} -{' '}
                    {set?.endTimestamp
                      ? moment(set?.endTimestamp).format('hh:mm A')
                      : 'Incomplete'}
                  </Text>
                </View>
                {sortedTargets.map((target: any, idx: number) => (
                  <TaskAnalysisTargetColumnCell
                    set={set}
                    target={target}
                    key={`taskanalysis-table-target-${set?.id}-${target?.id}-${idx}`}
                    setIndex={index + 1}
                    program={program}
                    type={'task_analysis_edit'}
                    session={session}
                    setEventId={setEventId}
                  />
                ))}
                <View
                  style={[
                    styles.justifyCenter,
                    styles.checkWidth,
                    styles.alignCenter,
                  ]}>
                  <Text style={styles.cellBoldTxt}>
                    {Math.ceil(
                      ((setEvents?.filter(
                        (event: any) => event.value === 'success',
                      ).length || 0) /
                        (setEvents?.length || 1)) *
                        100,
                    ) + '%'}
                  </Text>
                </View>
              </DataTable.Row>
            );
          })}
          <DataTable.Row style={[styles.lastHead, styles.lastRow]}>
            <View
              style={[
                styles.justifyCenter,
                styles.alignCenter,
                styles.setHead,
                styles.borderRight,
              ]}>
              <Text style={styles.titleTxt}>Average score</Text>
            </View>
            {sortedTargets.map((target: any) => {
              return (
                <DataTable.Cell
                  style={[
                    styles.justifyCenter,
                    styles.setHead,
                    styles.borderRight,
                  ]}
                  textStyle={styles.cellTxt}
                  key={`taskanalysis-table-avgscore-${target?.id}`}>
                  {targetAggregate?.[target.id] &&
                  eventsByTargetId[target.id].length > 0
                    ? (targetAggregate?.[target.id] || 0) + '%'
                    : '-'}
                </DataTable.Cell>
              );
            })}
            <DataTable.Cell
              style={[styles.justifyCenter, styles.setHead]}
              textStyle={styles.cellBoldTxt}>
              {Math.ceil(
                sets
                  .filter(set => !!set.endTimestamp)
                  .reduce((totalSum: number, set: any) => {
                    const setEvents = eventsBySetId[set?.id];
                    return (
                      totalSum +
                      Math.ceil(
                        (setEvents?.filter(
                          (event: any) => event.value === 'success',
                        ).length /
                          setEvents?.length) *
                          100,
                      )
                    );
                  }, 0) / sets.length,
              ) || 0}
              %
            </DataTable.Cell>
          </DataTable.Row>
        </DataTable>
      </ScrollView>
    );
  } else {
    return (
      <DataTable style={styles.table}>
        <DataTable.Header style={[styles.head, styles.firstRow]}>
          <View
            style={[styles.justifyCenter, styles.setHead, styles.borderRight]}>
            <Text style={styles.titleTxt}>{program?.name}</Text>
          </View>
          {sortedTargets.map((target: any, index: number) => {
            return (
              <DataTable.Title
                style={[
                  styles.justifyCenter,
                  styles.borderRight,
                  styles.setHead,
                ]}
                textStyle={styles.titleTxt}
                numberOfLines={3}
                key={`taskanalysis-table-header-${target?.name}-${index}`}>
                {target?.name} - {index + 1}
              </DataTable.Title>
            );
          })}
          <DataTable.Title
            style={[styles.justifyCenter, styles.alignCenter, styles.setHead]}>
            <Text style={styles.titleTxt}>Score</Text>
          </DataTable.Title>
        </DataTable.Header>
        {sets?.map((set: any, index: number) => {
          const setEvents = eventsBySetId[set?.id];
          return (
            <DataTable.Row
              style={[styles.head, styles.middleHead]}
              key={`taskanalysis-table-set-${set?.id}-${index}`}>
              <View
                style={[
                  styles.justifyCenter,
                  styles.setHead,
                  styles.borderRight,
                ]}>
                <Text style={styles.cellBoldTxt}>{'Set ' + (index + 1)}</Text>
                <Text style={styles.cellTxt}>
                  {moment(set?.startTimestamp).format('ll')}
                </Text>
                <Text style={[styles.cellTxt, styles.hideMobile]}>
                  {moment(set?.startTimestamp).format('hh:mm')} -{' '}
                  {set?.endTimestamp
                    ? moment(set?.endTimestamp).format('hh:mm A')
                    : 'Incomplete'}
                </Text>
              </View>
              {sortedTargets.map((target: any, idx: number) => (
                <TaskAnalysisTargetColumnCell
                  set={set}
                  target={target}
                  key={`taskanalysis-table-target-${set?.id}-${target?.id}-${idx}`}
                  setIndex={index + 1}
                  program={program}
                  session={session}
                  setEventId={setEventId}
                />
              ))}
              <DataTable.Cell
                style={[styles.justifyCenter, styles.setHead, styles.flex]}
                textStyle={styles.cellBoldTxt}>
                {Math.ceil(
                  ((setEvents?.filter((event: any) => event.value === 'success')
                    .length || 0) /
                    (setEvents?.length || 1)) *
                    100,
                ) + '%'}
              </DataTable.Cell>
            </DataTable.Row>
          );
        })}
        <DataTable.Row style={[styles.head, styles.lastRow]}>
          <View
            style={[
              styles.justifyCenter,
              styles.setHead,
              styles.cellBoldTxt,
              styles.borderRight,
            ]}>
            <Text>Average score</Text>
          </View>
          {sortedTargets.map((target: any) => {
            return (
              <DataTable.Cell
                style={[
                  styles.justifyCenter,
                  styles.setHead,
                  styles.borderRight,
                ]}
                textStyle={styles.cellTxt}
                key={`taskanalysis-table-avgscore-${target?.id}`}>
                {targetAggregate?.[target.id] &&
                eventsByTargetId[target.id].length > 0
                  ? (targetAggregate?.[target.id] || 0) + '%'
                  : '-'}
              </DataTable.Cell>
            );
          })}
          <DataTable.Cell
            style={[styles.justifyCenter, styles.setHead]}
            textStyle={styles.cellBoldTxt}>
            {Math.ceil(
              sets
                .filter(set => !!set.endTimestamp)
                .reduce((totalSum: number, set: any) => {
                  const setEvents = eventsBySetId[set?.id];
                  return (
                    totalSum +
                    Math.ceil(
                      (setEvents?.filter(
                        (event: any) => event.value === 'success',
                      ).length /
                        setEvents?.length) *
                        100,
                    )
                  );
                }, 0) / sets.length,
            ) || 0}
            %
          </DataTable.Cell>
        </DataTable.Row>
      </DataTable>
    );
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStyles = (width: number) =>
  EStyleSheet.create({
    checkWidth: {
      width: 155,
    },
    flex: {
      flex: 1,
    },
    table: {
      borderWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
      borderRadius: 10,
    },
    head: {
      minHeight: 80,
      backgroundColor: Colors.PRIMARY_50,
    },
    lastHead: {
      minHeight: 84,
      backgroundColor: Colors.PRIMARY_50,
    },
    middleHead: {
      minHeight: 60,
      backgroundColor: Colors.WHITE_OPACITY_60,
    },
    setHead: {
      width: 155,
      paddingVertical: 5,
    },
    firstRow: {
      borderTopStartRadius: 10,
      borderTopEndRadius: 10,
    },
    lastRow: {
      borderBottomStartRadius: 10,
      borderBottomEndRadius: 10,
      marginBottom: Platform.OS === 'ios' ? 32 : 0,
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    alignCenter: {
      alignItems: 'center',
    },
    titleTxt: {
      ...Typography.LABEL_MEDIUM,
      color: Colors.PRIMARY_900,
      alignSelf: 'center',
      textAlign: 'center',
      flexWrap: 'wrap',
      paddingHorizontal: 2,
    },
    cellBoldTxt: {
      ...Typography.CAPTION_MEDIUM,
      color: Colors.PRIMARY_900,
    },
    cellTxt: {
      ...Typography.CAPTION,
      color: Colors.PRIMARY_900,
    },
    borderRight: {
      borderRightWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
    },
    '@media (max-width: 768)': {
      head: {
        height: 60,
      },
      titleTxt: {
        fontSize: Typography.FONT_SIZE_10,
      },
      cellBoldTxt: {
        fontSize: Typography.FONT_SIZE_10,
      },
      cellTxt: {
        fontSize: Typography.FONT_SIZE_10,
      },
    },
    '@media (max-width: 1024)': {
      hideMobile: {
        display: 'none',
      },
      setHead: {
        width: 100,
      },
    },
  });

export default compose(
  withDatabase,
  withObservables(['sets'], ({sets, database}: any) => {
    const setIds = _.map(sets, 'id');
    return {
      events: database
        .get('events')
        .query(
          Q.where('set_id', Q.oneOf(setIds)),
          Q.where('deleted_at', null),
          Q.sortBy('created_at', Q.desc),
        ),
    };
  }),
)(TaskAnalysisTable);
