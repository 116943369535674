import React, {useState, useRef, useEffect, useContext} from 'react';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverEvent,
  DragEndEvent,
  UniqueIdentifier,
  pointerWithin,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {snapCenterToCursor} from '@dnd-kit/modifiers';
import DroppableRow from 'src/modules/form-builder/components/canvas/droppable-row';
import {arrayMove} from 'src/modules/form-builder/utils/array';
import ComponentSideBar from '../component-side-bar';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {Typography, Colors} from 'src/styles';
import {Field} from '../../../utils/types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {uuid} from 'src/common-utils/uuid';
import {useStyle} from 'src/providers/style';
import {Separator} from 'src/common-components/atoms';
import {useFormContext, useWatch} from 'react-hook-form';
import ConfigurationSideBar from '../../configuration-side-bar';
import {FormContext} from 'src/modules/form-builder/context/form-context';
import FieldDetails from 'src/modules/form-builder/components/canvas/field-details';
import {IconButton} from 'react-native-paper';

function getData(prop: any) {
  return prop?.data?.current ?? {};
}

interface ActiveData {
  id: string;
  fieldData: Field;
  fromSidebar?: boolean;
}
const Canvas = () => {
  const styles = useStyle();
  const [activeField, setActiveField] = useState<ActiveData | null>(null);
  const [rowDrag, setRowDrag] = useState<boolean>(false);

  const [sidebarFieldsRegenKey, setSidebarFieldsRegenKey] = useState(
    Date.now(),
  );

  const {editField, handleSetEditField} = useContext(FormContext);
  const {reset, control} = useFormContext();
  const watchedFormValues = useWatch({control});
  const containers = Object.keys(watchedFormValues);

  useEffect(() => {
    if (!containers.length) {
      const id = uuid();
      reset({
        ...watchedFormValues,
        [id]: {
          items: [],
          header: 'Section Header',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFormValues]);

  const currentDragFieldRef: any = useRef();

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor, {
      activationConstraint: {distance: 1}, // necessary for click events inside draggable elements
    }),
  );

  const findNewIndex = (itemsArray: any[], active: any, over: any) => {
    const overId = over.id;
    const lastPosition = itemsArray.length + 1;
    const itemIndex = itemsArray.findIndex((item: any) => item.id === overId);

    if (overId in watchedFormValues) {
      return lastPosition;
    } else {
      const isBelowArrayItem =
        over &&
        active.rect.current.translated &&
        active.rect.current.translated.top > over.rect.top + over.rect.height;

      const modifier = isBelowArrayItem ? 1 : 0;

      return itemIndex >= 0 ? itemIndex + modifier : lastPosition;
    }
  };

  const findContainer = (id: UniqueIdentifier) => {
    if (id in watchedFormValues) {
      return id;
    }

    return Object.keys(watchedFormValues).find(key =>
      watchedFormValues[key].items.find(item => item.id === id),
    );
  };

  const handleDragStart = ({active}: DragStartEvent) => {
    const activeData = getData(active);
    if (activeData.fromSidebar) {
      const {fieldData} = activeData;
      const {componentType} = fieldData;

      currentDragFieldRef.current = {
        id: active.id,
        componentType,
        parent: null,
      };
    }

    setActiveField(activeData);
  };

  const handleDragCancel = () => {
    setActiveField(null);
  };

  const handleDragOver = ({active, over}: DragOverEvent) => {
    if (!active) {
      return;
    }

    if (active?.id in watchedFormValues) {
      setRowDrag(true);
    }

    const activeData = getData(active);
    const overId = over?.id;

    if (overId == null || active.id in watchedFormValues) {
      return;
    }

    const overContainer = findContainer(overId);

    const activeContainer = findContainer(active.id);

    if (overContainer && activeContainer !== overContainer) {
      if (watchedFormValues[overContainer].items.length >= 3) {
        return;
      }
      const overItems = watchedFormValues[overContainer].items;

      const newIndex = findNewIndex(overItems, active, over);

      if (activeData.fromSidebar && overContainer) {
        const overItemsClone = [...overItems];
        overItemsClone.splice(newIndex, 0, {
          id: active.id,
          ...activeData.fieldData,
        });
        reset({
          ...watchedFormValues,
          [overContainer]: {
            header: watchedFormValues[overContainer].header,
            items: overItemsClone,
          },
        });
      } else {
        const activeItems = watchedFormValues[activeContainer].items;
        const activeIndex = activeItems.findIndex(
          item => item.id === active.id,
        );

        if (!overContainer || !activeContainer) {
          reset({
            ...watchedFormValues,
          });
        }
        reset({
          ...watchedFormValues,
          [activeContainer]: {
            header: watchedFormValues[activeContainer].header,
            items: activeItems.filter(item => item.id !== active.id),
          },
          [overContainer]: {
            header: watchedFormValues[overContainer].header,
            items: [
              ...watchedFormValues[overContainer].items.slice(0, newIndex),
              activeItems[activeIndex],
              ...watchedFormValues[overContainer].items.slice(
                newIndex,
                watchedFormValues[overContainer].items.length,
              ),
            ],
          },
        });
      }
    }
  };

  const handleUpdateContainers = (
    activeId: UniqueIdentifier,
    orderId: UniqueIdentifier,
  ) => {
    const activeIndex = Object.keys(watchedFormValues).indexOf(
      activeId as string,
    );
    const overIndex = Object.keys(watchedFormValues).indexOf(orderId as string);

    return arrayMove(containers, activeIndex, overIndex);
  };

  const handleDragEnd = ({active, over}: DragEndEvent) => {
    if (active.id in watchedFormValues && over?.id) {
      const updatedContainers = handleUpdateContainers(active.id, over.id);
      reset(
        updatedContainers.reduce((o, key) => {
          return {
            ...o,
            [key]: {
              header: watchedFormValues[key].header,
              items: watchedFormValues[key].items,
            },
          };
        }, {}),
      );
      setRowDrag(false);
    }

    const activeContainer = findContainer(active.id);

    if (!activeContainer) {
      setActiveField(null);

      return;
    }
    const overId = over?.id;

    if (!overId) {
      setActiveField(null);
      return;
    }

    const overContainer = findContainer(overId);

    if (overContainer) {
      const activeIndex = watchedFormValues[activeContainer].items.findIndex(
        item => item.id === active.id,
      );
      const overIndex = watchedFormValues[overContainer].items.findIndex(
        item => item.id === overId,
      );

      if (activeIndex !== overIndex) {
        reset({
          ...watchedFormValues,
          [overContainer]: {
            header: watchedFormValues[overContainer].header,
            items: arrayMove(
              watchedFormValues[overContainer].items,
              activeIndex,
              overIndex,
            ),
          },
        });
      }
    }
    handleSetEditField(null);
    currentDragFieldRef.current = null;
    // This forces the sidebar element to rerender and prduce new id's for side bar elements
    setSidebarFieldsRegenKey(Date.now());
    setActiveField(null);
  };

  return (
    <DndContext
      autoScroll={{layoutShiftCompensation: false, acceleration: 2}}
      collisionDetection={pointerWithin}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}>
      <View style={[styles.row, styles.height]}>
        <View style={[styles.flex05]}>
          <ComponentSideBar fieldsRegKey={sidebarFieldsRegenKey} />
        </View>
        <View style={[styles.paddingLHorizontal, styles.flex2]}>
          <ScrollView
            style={[styles.windowHeight75]}
            contentContainerStyle={[styles.affixPadding]}>
            <SortableContext
              items={Object.keys(watchedFormValues)}
              strategy={verticalListSortingStrategy}>
              {Object.keys(watchedFormValues).map(row => (
                <DroppableRow
                  id={row}
                  items={watchedFormValues[row].items}
                  header={watchedFormValues[row].header}
                  key={row}
                  rowDrag={rowDrag}
                />
              ))}
            </SortableContext>
            <TouchableOpacity
              style={[
                styles.row,
                styles.justifyCenter,
                styles.border,
                styles.borderColorGray400,
                styles.paddingVertical,
              ]}
              onPress={() => {
                const id = uuid();
                reset({
                  ...watchedFormValues,
                  [id]: {
                    header: 'Section Header',
                    items: [],
                  },
                });
              }}>
              <Text style={[Typography.P3_BOLD, styles.marginAutoTop]}>
                ADD ROW
              </Text>
              <Separator width={8} />
              <Icon
                name={'plus-circle-outline'}
                size={24}
                color={Colors.RAVEN_BLACK}
              />
            </TouchableOpacity>
          </ScrollView>

          <DragOverlay modifiers={[snapCenterToCursor]}>
            {activeField ? (
              <View style={[styles.backgroundColorWhite, styles.borderRadius]}>
                {activeField.fieldData ? (
                  activeField.fromSidebar ? (
                    <View
                      style={[
                        styles.paddingSM,
                        styles.justifyCenter,
                        styles.borderRadius,
                        styles.marginSM,
                        styles.elevation,
                      ]}>
                      <View
                        style={[
                          styles.row,
                          styles.justifySpaceBetween,
                          styles.alignCenter,
                        ]}>
                        <View style={[styles.row, styles.justifyCenter]}>
                          <Icon
                            size={24}
                            name={activeField.fieldData.icon}
                            style={[styles.paddingMRight]}
                          />
                          <Text style={[Typography.P3, styles.marginAutoTop]}>
                            {activeField.fieldData.name}
                          </Text>
                        </View>
                        <View style={[styles.justifyEnd]}>
                          <Icon
                            name={'drag'}
                            size={30}
                            color={Colors.GRAY_500}
                          />
                        </View>
                      </View>
                    </View>
                  ) : (
                    <>
                      <View
                        style={[
                          styles.row,
                          styles.paddingMHorizontal,
                          styles.paddingSMTop,
                          styles.justifySpaceBetween,
                          styles.alignCenter,
                        ]}>
                        <View
                          style={[
                            styles.paddingMHorizontal,
                            styles.paddingMTop,
                          ]}>
                          <Text
                            style={[Typography.P4, styles.textColorSecondary]}>
                            {activeField.fieldData.name}
                          </Text>
                        </View>
                        <View style={[styles.row]}>
                          <IconButton
                            size={20}
                            icon="pencil"
                            color={Colors.PRIMARY_900}
                          />
                          <IconButton
                            size={20}
                            color={Colors.PRIMARY_900}
                            icon="trash-can"
                          />
                        </View>
                      </View>
                      <View
                        style={[
                          styles.paddingBottom,
                          styles.paddingHorizontal,
                        ]}>
                        <FieldDetails field={activeField.fieldData} />
                      </View>
                    </>
                  )
                ) : (
                  <DroppableRow
                    id={activeField.id}
                    items={watchedFormValues[activeField.id].items}
                    header={watchedFormValues[activeField.id].header}
                  />
                )}
              </View>
            ) : (
              <></>
            )}
          </DragOverlay>
        </View>
        <View style={[styles.flex05]}>
          <ConfigurationSideBar editField={editField} />
        </View>
      </View>
    </DndContext>
  );
};

export default Canvas;
