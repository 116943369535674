import {View} from 'react-native';
import React from 'react';
import {useStyle} from 'src/providers/style';

const ListItemSeparator = () => {
  const styles = useStyle();

  return <View style={[styles.width, styles.borderBottom]} />;
};

export default ListItemSeparator;
