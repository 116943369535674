import React, {useState} from 'react';
import {
  createNavigationContainerRef,
  NavigationContainer,
  NavigationState,
  PartialState,
} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';
import {
  Audit,
  Account,
  EditTemplateScreen,
  ForgotPasswordScreen,
  LoginScreen,
  ResetPasswordScreen,
  ResetUsernameScreen,
  OrganizationSelectionScreen,
  ProgramLibraryScreen,
  ProgramBuilderScreen,
  ProgramProfile,
  PatientListScreen,
  PatientProfileScreen,
  SignupScreen,
  PatientFormScreen,
  UserProfileScreen,
  CalendarScreen,
  ScheduleAppointmentScreen,
  HomeScreen,
  BillingScreen,
  PayerFormScreen,
  SessionsNotesDueScreen,
  UserList,
  UserFormScreen,
  ReportsScreen,
  SessionMessageScreen,
  SessionRecapScreen,
  SessionCollectScreen,
  OrganizationProfileScreen,
  PostSessionReviewScreen,
  FormBuilderScreen,
  ClaimProfileScreen,
  RemitProfileScreen,
} from 'src/modules';
import {createDrawerNavigator} from '@react-navigation/drawer';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Linking, Platform} from 'react-native';
import {useAnalytics} from 'src/providers/segment';
import HeaderTitleLogo from 'src/navigation/components/header-logo';
import {useBranch} from '@ravenhealth/react-native-branch-provider';
import MenuButton from 'src/navigation/components/menu-button';
import BackButton from 'src/navigation/components/back-button';
import {DrawerContent} from 'src/navigation/components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';
import NetInfo from '@react-native-community/netinfo';
import {authApi} from 'src/services/auth';
import {identityApi} from 'src/services/identity';
import Notifications from 'src/modules/home/components/notifications';
import {createClaimBackButton} from './components/claims-back-button';

// const defaultOptions = {
//   title: 'Update Available',
// };
//
// const versionSpecificRules = [
//   {
//     forceUpgrade: false,
//     message:
//       'There is an updated version available on the App Store/Google Store. Would you like to upgrade?',
//   },
// ];

const RootStack = createDrawerNavigator();
const navigationRef = createNavigationContainerRef();
const Navigation = ({initialRoute}: any) => {
  const branch = useBranch();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const {selectedGroup} = useSelector(state => state.authentication);

  const [routeName, setRouteName] = useState('Unknown');

  const linking = {
    prefixes: [
      'https://app.ravenhealth.com',
      'https://dev.ravenhealth.com',
      'https://test.ravenhealth.com',
      'ravenhealth://',
    ],

    async getInitialURL() {
      return await Linking.getInitialURL();
    },

    subscribe(listener: any) {
      const onReceiveURL = ({url}: {url: string}) => {
        listener(url);
      };

      Linking.addEventListener('url', onReceiveURL);

      const subscription = branch.subscribe(async ({error, params}) => {
        if (
          error ||
          !params['+clicked_branch_link'] ||
          params['+non_branch_link']
        ) {
          return;
        }

        const url = params.$canonical_url;

        if (url && !params['+rn_cached_initial_event']) {
          listener(url);
        }
      });

      return () => {
        // Linking.removeEventListener('url', onReceiveURL);
        subscription();
      };
    },

    config: {
      screens: {
        Callback: {
          path: '/',
        },
        Audit: {
          path: '/audit',
          screens: {
            Sessions: {
              path: '/sessions',
            },
          },
        },
        Billing: {
          path: '/billing',
          screens: {
            Claims: {
              path: '/claims',
            },
            Payers: {
              path: '/payers',
            },
            Remits: {
              path: '/remits',
            },
          },
        },
        RemitProfile: {
          path: '/remits/:id',
        },
        ClaimProfile: {
          path: 'claims/:id',
          screens: {
            Claim: {
              path: '/claim',
            },
            Events: {
              path: '/events',
            },
          },
        },
        PayerFormScreen: {
          path: '/billing/payers/form',
        },
        OrganizationSelection: {
          path: 'organizations',
        },
        OrganizationProfile: {
          path: 'organizations/:id',
          screens: {
            Summary: {
              path: '/summary',
            },
            'Roles and Permissions': {
              path: '/roles-and-permissions',
            },
            Prompts: {
              path: '/prompts',
            },
            Tags: {
              path: '/tags',
            },
            Timesheet: {
              path: '/timesheet',
            },
            'Note Templates': {
              path: '/note-templates',
            },
            Diagnoses: {
              path: '/diagnoses',
            },
            Locations: {
              path: '/locations',
            },
          },
        },
        EditTemplateScreen: {
          path: '/template',
        },
        Dashboard: {
          path: 'launchpad',
        },
        SessionNotesDue: {
          path: 'session-notes',
        },
        Schedule: {
          path: 'appointments',
        },
        ScheduleAppointment: {
          path: 'appointments/form',
        },
        PatientList: {
          path: 'clients',
        },
        PatientFormScreen: {
          path: 'clients/form',
        },
        PatientProfile: {
          path: 'clients/:patientId',
          screens: {
            Dashboard: {
              path: '/dashboard',
            },
            Summary: {
              path: '/summary',
            },
            'Client Information': {
              path: '/client-information',
            },
            'Treatment Plan': {
              path: '/care-plan',
            },
            Authorizations: {
              path: '/authorizations',
            },
            History: {
              path: '/history',
            },
            'Environmental Factors': {
              path: '/environmental-factors',
            },
            Documents: {
              path: '/documents',
            },
            'Assigned Staff': {
              path: '/assigned-staff',
            },
          },
        },
        Users: {
          path: 'staff',
        },
        UserFormScreen: {
          path: 'staff/form',
        },
        UserProfile: {
          path: 'staff/:practitionerId',
          screens: {
            Summary: {
              path: '/summary',
            },
            'Assigned Clients': {
              path: '/assigned-clients',
            },
          },
        },
        Reports: {
          path: 'reports',
        },
        FormBuilder: {
          path: 'form_builder',
        },
        Programs: {
          path: 'programs',
        },
        ProgramBuilder: {
          path: 'programs/form',
        },
        ProgramProfile: {
          path: 'programs/:programId',
          screens: {
            Summary: {
              path: '/summary',
            },
            Collection: {
              path: '/collection',
            },
            Measurement: {
              path: '/measurement',
            },
            Dashboard: {
              path: '/dashboard',
            },
          },
        },
        SessionDetail: {
          path: 'sessions/:appointmentId/agenda',
        },
        SessionCollect: {
          path: 'sessions/:id/',
          screens: {
            Activity: {
              path: '/activity',
            },
            Collect: {
              path: '/collect',
            },
            Plan: {
              path: '/plan',
            },
            Client: {
              path: '/client',
            },
            'Data Summary': {
              path: '/data',
            },
            'Session Notes': {
              path: '/note',
            },
            PostSessionReview: {
              path: '/post-session-review',
            },
          },
        },
        SessionMessage: {
          path: 'sessions/:id/message',
        },
        SessionRecap: {
          path: 'sessions/:id/recap',
          screens: {
            Supervision: {
              path: 'supervision',
            },
            'Direct Therapy': {
              path: '',
            },
          },
        },
        Signup: {
          path: 'signup',
        },
        Account: {
          path: 'account',
        },
        Login: {
          path: 'login',
        },
        ForgotPassword: {
          path: 'forgot-password',
        },
        ResetPassword: {
          path: 'reset-password',
        },
        ResetUsername: {
          path: 'reset-email',
        },
      },
    },
  };

  const Theme = {
    dark: false,
    colors: {
      primary: '#ffffff',
      background: 'rgb(242, 242, 242)',
      card: 'rgb(255, 255, 255)',
      text: '#000000',
      border: 'rgb(216, 216, 216)',
      notification: 'rgb(255, 59, 48)',
    },
  };

  const getActiveRouteName = (
    state: NavigationState | PartialState<NavigationState> | undefined,
  ): string => {
    if (!state || typeof state.index !== 'number') {
      return 'Unknown';
    }

    const route = state.routes[state.index];

    if (route.state) {
      return getActiveRouteName(route.state);
    }

    return route.name;
  };

  const authenticationGuard = ({navigation}: any) => {
    return {
      state: async () => {
        const asyncAccessToken = await AsyncStorage.getItem('accessToken');
        const asyncRefreshToken = await AsyncStorage.getItem('refreshToken');
        if (_.isEmpty(asyncAccessToken) && _.isEmpty(asyncRefreshToken)) {
          navigation.navigate('Login');
        } else {
          const {isConnected} = await NetInfo.fetch();

          if (isConnected) {
            const identityResponse = await dispatch(
              identityApi.endpoints.getMe.initiate(),
            );

            if (identityResponse.status !== 'fulfilled') {
              const refreshData = new FormData();
              refreshData.append('refresh_token', asyncRefreshToken);
              refreshData.append('grant_type', 'refresh_token');

              const refreshTokenResponse = await dispatch(
                authApi.endpoints.token.initiate(refreshData),
              );
              if (
                refreshTokenResponse?.error &&
                refreshTokenResponse?.error.status >= 400
              ) {
                navigation.navigate('Login');
              }
            }
          }
        }
      },
    };
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={Theme}
      linking={linking}
      onStateChange={async state => {
        const newRouteName = getActiveRouteName(state);

        if (routeName !== newRouteName) {
          const group = {};
          if (selectedGroup) {
            group.groupId = selectedGroup;
          }
          if (Platform.OS === 'web') {
            analytics.page(newRouteName, group);
          } else {
            analytics.screen(newRouteName, group);
          }

          setRouteName(newRouteName);
        }
      }}>
      <RootStack.Navigator
        initialRouteName={initialRoute}
        detachInactiveScreens={true}
        headerMode="none"
        screenOptions={{
          drawerLabelStyle: {
            color: '#ffffff',
          },
          drawerActiveTintColor: '#000000',
          drawerActiveBackgroundColor: '#ffffff',
          drawerStyle: {
            backgroundColor: '#000000',
            width: 304,
          },
          headerTintColor: '#000',
          headerBackTitleVisible: false,
          headerTitle: HeaderTitleLogo,
          headerTitleAlign: 'center',
          headerTitleStyle: {
            alignSelf: 'center',
          },
          unmountOnBlur: true,
          unmountInactiveRoutes: true,
        }}
        unmountOnBlur={true}
        options={{unmountOnBlur: true}}
        backBehavior="history"
        drawerContent={(props: any) => DrawerContent(props)}>
        <RootStack.Screen
          name="Login"
          component={LoginScreen}
          options={{
            headerShown: false,
          }}
        />
        <RootStack.Screen
          name="ResetPassword"
          component={ResetPasswordScreen}
          options={{
            title: 'Reset Password',
            headerShown: false,
          }}
        />
        <RootStack.Screen
          name="ResetUsername"
          component={ResetUsernameScreen}
          options={{
            title: 'Confirm New Email',
            headerShown: false,
          }}
        />
        <RootStack.Screen
          name="ForgotPassword"
          component={ForgotPasswordScreen}
          options={{
            title: 'Forgot Password',
            headerShown: false,
          }}
        />
        <RootStack.Screen
          name="Signup"
          component={SignupScreen}
          options={{
            headerShown: false,
          }}
        />
        <RootStack.Screen
          name="Dashboard"
          component={HomeScreen}
          options={(props: any) => ({
            title: 'Launchpad',
            headerLeft: () => MenuButton(props),
            headerRight: Notifications,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="SessionNotesDue"
          component={SessionsNotesDueScreen}
          options={(navigation: any) => ({
            title: 'Notes Due',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="PatientList"
          component={PatientListScreen}
          options={(props: any) => ({
            title: 'Clients',
            headerLeft: () => MenuButton(props),
            headerRight: Notifications,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="PatientFormScreen"
          component={PatientFormScreen}
          options={(navigation: any) => ({
            title: 'Client Form',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="PatientProfile"
          component={PatientProfileScreen}
          options={(navigation: any) => ({
            title: 'Client Profile',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
            headerRight: Notifications,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="Users"
          component={UserList}
          options={(props: any) => ({
            title: 'Staff',
            headerLeft: () => MenuButton(props),
            headerRight: Notifications,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="UserFormScreen"
          component={UserFormScreen}
          options={(navigation: any) => ({
            title: 'Staff Member Form',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="UserProfile"
          component={UserProfileScreen}
          options={(navigation: any) => ({
            title: 'Staff Member Profile',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
            headerRight: Notifications,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="Reports"
          component={ReportsScreen}
          options={(props: any) => ({
            title: 'Reports',
            headerLeft: () => MenuButton(props),
            headerRight: Notifications,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="FormBuilder"
          component={FormBuilderScreen}
          options={(props: any) => ({
            title: 'Form Builder',
            headerLeft: () => MenuButton(props),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="SessionCollect"
          component={SessionCollectScreen}
          options={() => ({
            header: () => null,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="SessionMessage"
          component={SessionMessageScreen}
          options={(navigation: any) => ({
            title: 'Message',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="SessionRecap"
          component={SessionRecapScreen}
          options={(navigation: any) => ({
            title: 'Recap',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name={ProgramLibraryScreen.screenName}
          component={ProgramLibraryScreen}
          options={(props: any) => ({
            title: ProgramLibraryScreen.screenName,
            headerLeft: () => MenuButton(props),
            headerRight: Notifications,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name={ProgramBuilderScreen.screenName}
          component={ProgramBuilderScreen}
          options={(navigation: any) => ({
            title: ProgramBuilderScreen.title,
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name={ProgramProfile.screenName}
          component={ProgramProfile}
          options={(navigation: any) => ({
            title: ProgramProfile.title,
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="Schedule"
          component={CalendarScreen}
          options={(props: any) => ({
            title: 'Schedule',
            headerLeft: () => MenuButton(props),
            headerRight: Notifications,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="ScheduleAppointment"
          component={ScheduleAppointmentScreen}
          options={(navigation: any) => ({
            title: 'Schedule Appointment',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="OrganizationSelection"
          component={OrganizationSelectionScreen}
          options={{
            headerShown: false,
            title: 'Organizations',
          }}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="OrganizationProfile"
          component={OrganizationProfileScreen}
          options={(props: any) => ({
            title: 'Organization',
            headerLeft: () => MenuButton(props),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="PostSessionReview"
          component={PostSessionReviewScreen}
          options={() => ({
            header: () => null,
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="Billing"
          component={BillingScreen}
          options={(props: any) => ({
            title: 'Billing',
            headerLeft: () => MenuButton(props),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="ClaimProfile"
          component={ClaimProfileScreen}
          options={({navigation, route}) => ({
            title: 'Claim',
            headerLeft: () => createClaimBackButton(navigation, route),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="RemitProfile"
          component={RemitProfileScreen}
          options={(navigation: any) => ({
            title: 'Remit',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="PayerFormScreen"
          component={PayerFormScreen}
          options={(navigation: any) => ({
            title: 'Payer',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="EditTemplateScreen"
          component={EditTemplateScreen}
          options={(navigation: any) => ({
            title: 'Edit Note Template',
            headerBackImage: <Icon name="arrow-left" size={24} />,
            headerLeft: () => BackButton(navigation),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="Audit"
          component={Audit}
          options={(props: any) => ({
            headerLeft: () => MenuButton(props),
          })}
          listeners={authenticationGuard}
        />
        <RootStack.Screen
          name="Account"
          component={Account}
          options={(props: any) => ({
            headerLeft: () => MenuButton(props),
          })}
          listeners={authenticationGuard}
        />
      </RootStack.Navigator>
    </NavigationContainer>
  );
};

export default Navigation;
