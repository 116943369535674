import {json, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Caregiver extends Base {
  static table = 'caregivers';
  static associations = {
    patients: {type: 'belongs_to', key: 'patient_id'},
  };

  @relation('patients', 'patient_id') patient;

  @text('_partition') partition;
  @text('identifier') identifier;
  @text('first_name') firstName;
  @text('last_name') lastName;
  @text('relationship') relationship;
  @text('email') email;
  @text('mobile_phone') mobilePhone;
  @json('address', value => value) address;
  @json('work_address', value => value) workAddress;

  getName() {
    return this.firstName + ' ' + this.lastName;
  }
}
