import React, {useState} from 'react';
import {View, TouchableOpacity, Text} from 'react-native';
import Collapsible from 'react-native-collapsible';
import {IconButton} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import Map from 'src/design-system/map';
import addressFormat from 'src/common-utils/address-format';

const LocationListItem = ({
  location,
  canDelete,
  deleteCallback,
  canEdit,
  editCallBack,
}: any) => {
  const styles = useStyle();

  const [collapsed, setCollapsed] = useState(true);

  return (
    <View style={[styles.card]}>
      <TouchableOpacity onPress={() => setCollapsed(!collapsed)}>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignCenter,
            styles.paddingL,
          ]}>
          <Text style={[Typography.P2_MEDIUM]}>
            {location?.name} - {addressFormat(location.address)}
          </Text>
          <View
            style={[
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
            ]}>
            {canEdit ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="pencil"
                onPress={() => editCallBack(location)}
              />
            ) : (
              <></>
            )}
            {canDelete ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="delete"
                size={16}
                onPress={() => deleteCallback(location)}
              />
            ) : (
              <></>
            )}
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={!collapsed ? 'chevron-up' : 'chevron-down'}
              onPress={() => setCollapsed(!collapsed)}
            />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible
        collapsed={collapsed}
        style={[styles.paddingLBottom]}
        renderChildrenCollapsed={false}>
        <View style={[styles.row]}>
          <View
            style={[
              styles.flex,
              styles.column,
              styles.paddingHorizontal,
              styles.paddingLBottom,
            ]}>
            {location?.address?.placeId ? (
              <Map
                location={
                  location?.address?.location || {
                    latitude: 37.78825,
                    longitude: -122.4324,
                  }
                }
              />
            ) : null}
          </View>
          <View style={[styles.row, styles.width80]} />
        </View>
      </Collapsible>
    </View>
  );
};

export default LocationListItem;
