import React, {useCallback, useEffect, useState} from 'react';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import HistorySessionItem from 'src/modules/patients/components/history-session-item';
import NoResults from 'src/common-components/noResults';
import {of} from 'rxjs';
import {startOfDay} from 'date-fns';

const HistoricalSessions = ({
  sessions,
  filters,
  setExportItems,
  role,
  profile,
  deleteCallback,
}: any) => {
  const [items, setItems] = useState<any[]>([]);
  const recalculateAppointments = async () => {
    const exportItems = sessions.sort((a, b) => b.date - a.date);
    setItems(exportItems);
    setExportItems(exportItems);
  };

  useEffect(() => {
    recalculateAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.startDate,
    filters.endDate,
    sessions,
    filters.locations,
    filters.types,
    filters.staff,
    filters.status,
  ]);

  const renderItem = useCallback(
    item => {
      return (
        <HistorySessionItem
          key={item.id}
          showStaffParticipant={true}
          session={item}
          canEdit={role?.clientHistoryEdit}
          canDelete={role?.clientHistoryDelete}
          deleteCallback={deleteCallback}
          profile={profile}
          isSupervision={item.type === 'supervision'}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      {items.length > 0 ? (
        <>{items.map(renderItem)}</>
      ) : (
        <NoResults iconName={'calendar'} message={'No Previous Sessions'} />
      )}
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['filters', 'update'],
    ({filters, database, patient}: any) => {
      const locationQuery = [];
      if (filters?.locations && filters.locations.length) {
        locationQuery.push(Q.where('location', Q.oneOf(filters.locations)));
      }
      const userQueries = [];
      if (filters?.staff && filters.staff.length) {
        const userQuery = [];
        for (const staff of filters.staff) {
          userQuery.push(Q.where('user_id', Q.eq(staff)));
        }
        userQueries.push(Q.or(...userQuery));
      }
      const cptQuery = [];
      if (filters?.types && filters.types.length) {
        const cptQueries = [];
        for (const cpt of filters.types) {
          cptQueries.push(Q.where('cpt', Q.includes(cpt)));
        }
        cptQuery.push(Q.or(...cptQueries));
      }
      const statusQuery = [];
      if (filters?.status && filters.status.length) {
        const statuses = [];
        for (const status of filters.status) {
          if (status === 'not-started') {
            statuses.push(Q.where('start_timestamp', Q.eq(null)));
          } else if (status === 'in-progress') {
            statuses.push(
              Q.and(
                Q.where('start_timestamp', Q.notEq(null)),
                Q.where('end_timestamp', Q.eq(null)),
              ),
            );
          } else if (status === 'missing-values') {
            statuses.push(
              Q.and(
                Q.where('end_timestamp', Q.notEq(null)),
                Q.where('submission_timestamp', Q.eq(null)),
              ),
            );
          } else if (status === 'completed') {
            statuses.push(Q.where('submission_timestamp', Q.notEq(null)));
          }
        }
        statusQuery.push(Q.or(...statuses), Q.sortBy('date', Q.desc));
      }
      return {
        sessions: database
          .get('sessions')
          .query(
            Q.where(
              'date',
              Q.gte(startOfDay(filters.startDate).getTime() - 14400000),
            ),
            Q.where('date', Q.lte(filters.endDate.getTime())),
            Q.where('patient_id', patient.id),
            Q.where('deleted_at', null),
            ...locationQuery,
            ...userQueries,
            ...cptQuery,
            ...statusQuery,
          ),
      };
    },
  ),
  withObservables([], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
)(HistoricalSessions);
