import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Text, View} from 'react-native';
import React from 'react';
import {useStyle} from 'src/providers/style';
import {DataItem, FormSectionHeader} from 'src/design-system';
import ListItemSeparator from 'src/common-components/separator';
import {Typography} from 'src/styles';
import moment from 'moment';

const RemitClaimListItem = ({remitClaim, remit}: any) => {
  const styles = useStyle();

  return (
    <View>
      <FormSectionHeader title={'General'} />
      <View style={[styles.padding, styles.card, styles.margin0Horizontal]}>
        <View style={[styles.container]}>
          <View style={[styles.flex, styles.marginRightAuto, styles.container]}>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Import Date</Text>
                <Text style={[Typography.P3]}>
                  {moment(remitClaim.response.create_date).format(
                    'YYYY-MM-DD hh:mm a',
                  )}
                </Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Payment Method</Text>
                <Text style={[Typography.P3]}>{remit.response.pay_method}</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Check ETF Number</Text>
                <Text style={[Typography.P3]}>
                  {remitClaim.response.check_number}
                </Text>
              </DataItem>
            </View>
          </View>
        </View>

        <View style={[styles.container]}>
          <View style={[styles.flex, styles.marginRightAuto, styles.container]}>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Payment Date</Text>
                <Text style={[Typography.P3]}>
                  {moment(remitClaim.response.pay_date).format(
                    'YYYY-MM-DD hh:mm a',
                  )}
                </Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Total Claim Charges</Text>
                <Text style={[Typography.P3]}>
                  {remitClaim.response.claim_charge}
                </Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Claim Payments</Text>
                <Text style={[Typography.P3]}>
                  {remitClaim.response.claim_payment}
                </Text>
              </DataItem>
            </View>
          </View>
        </View>

        <View style={[styles.container]}>
          <View style={[styles.flex, styles.marginRightAuto, styles.container]}>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Provider Adjustments</Text>
                <Text style={[Typography.P3]}>
                  {remit.response.provider_adjustments}
                </Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Total Payment</Text>
                <Text style={[Typography.P3]}>{'???'}</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Patient Resp Amount</Text>
                <Text style={[Typography.P3]}>
                  {remit.response.patient_resp}
                </Text>
              </DataItem>
            </View>
          </View>
        </View>
      </View>

      <FormSectionHeader title={'Payer Info'} />
      <View style={[styles.card, styles.padding, styles.margin0Horizontal]}>
        <View style={[styles.container]}>
          <View style={[styles.flex]}>
            <DataItem>
              <Text style={[Typography.H6]}>Payer Name</Text>
              <Text style={[Typography.P3]}>
                {remitClaim.response.payer_name}
              </Text>
            </DataItem>
          </View>
          <View style={[styles.flex]}>
            <DataItem>
              <Text style={[Typography.H6]}>Payer Address</Text>
              <Text style={[Typography.P3]}>{remit.response.PayerAddr1}</Text>
              <Text style={[Typography.P3]}>
                {remit.response.PayerCity}, {remit.response.PayerState}{' '}
                {remit.response.PayerZip}
              </Text>
            </DataItem>
          </View>
        </View>
        <View style={[styles.flex]}>
          <DataItem>
            <Text style={[Typography.H6]}>Payment Contact</Text>
            <Text style={[Typography.P3]}>
              {remitClaim.response.PayContactName} -{' '}
              {remitClaim.response.PayContactPhone}
            </Text>
          </DataItem>
        </View>
      </View>

      <FormSectionHeader title={'Payee Info'} />
      <View style={[styles.padding, styles.card, styles.margin0Horizontal]}>
        <View style={[styles.container]}>
          <View style={[styles.flex]}>
            <DataItem>
              <Text style={[Typography.H6]}>Payee Name</Text>
              <Text style={[Typography.P3]}>
                {remitClaim.response.payee_name}
              </Text>
            </DataItem>
          </View>
          <View style={[styles.flex]}>
            <DataItem>
              <Text style={[Typography.H6]}>Payee Address</Text>
              <Text style={[Typography.P3]}>{remit.response.PayeeAddr1}</Text>
              <Text style={[Typography.P3]}>
                {remit.response.PayeeCity}, {remit.response.PayeeState}{' '}
                {remit.response.PayeeZip}
              </Text>
            </DataItem>
          </View>
        </View>
        <View style={[styles.container]}>
          <View style={[styles.flex]}>
            <DataItem>
              <Text style={[Typography.H6]}>Payee NPI</Text>
              <Text style={[Typography.P3]}>
                {remitClaim.response.payee_npi}
              </Text>
            </DataItem>
          </View>
          <View style={[styles.flex]}>
            <DataItem>
              <Text style={[Typography.H6]}>Payee TIN</Text>
              <Text style={[Typography.P3]}>
                {remit.response.PayeeID.TaxID}
              </Text>
            </DataItem>
          </View>
        </View>
      </View>

      <ListItemSeparator />

      {/* {transactions.map((transaction: any, index: number) => {
        return (
          <View style={[styles.paddingMTop]}>
            <Text>Transaction {index}</Text>
            <Text>{JSON.stringify(transaction.response)}</Text>
          </View>
        );
      })} */}
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({remitClaim}: any) => ({
    transactions: remitClaim.transactions,
  })),
)(RemitClaimListItem);
