// @ts-nocheck
import {PDFExporterInterface} from 'src/common-components/pdf-export/src';
import Worker from 'src/common-components/pdf-export/src/pdfExport.worker.js';

export class PDFGenerator implements PDFExporterInterface {
  async generatePdf(source: string, name: string) {
    return new Promise(resolve => {
      const worker = new Worker();
      worker.onmessage = ({data}) => {
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        if (data) {
          resolve(true);
        }
      };
      worker.postMessage(source);
    });
  }
}
