import React, {useState} from 'react';
import {FlatList} from 'react-native';
import {useSelector} from 'react-redux';
import {Searchbar} from 'react-native-paper';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {BaseIndexScreen} from 'src/design-system';
import NoResults from 'src/common-components/noResults';
import NoteTemplateItem from '../note-template-item';
import {NoteTemplate, NoteTemplate as Template} from 'src/models/note-template';
import {NoteTemplateVersion} from 'src/models';
import {fuzzy} from 'src/common-utils/fuzzy';
import {of} from 'rxjs';
import DeleteModal from 'src/design-system/delete-modal';
import {useNavigation} from '@react-navigation/native';
import {useTranslations} from 'src/providers/translation';

const NoteTemplateScreen = ({templates = [], role}: any) => {
  const database = useDatabase();
  const navigation = useNavigation();
  const translations = useTranslations();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [showDelete, setShowDelete] = useState<string | boolean>(false);

  const searchedItems = templates?.filter((template: any) => {
    return fuzzy(template?.title, searchFilter);
  });

  const navigateToUpsert = (templateId: any) => {
    navigation.navigate('EditTemplateScreen', {
      id: templateId,
    });
  };

  const addNewTemplate = async () => {
    let newNoteTemplateVersion = await database.write(async () => {
      const newTemplate = await database
        .get(NoteTemplate.table)
        .create((entity: any) => {
          entity.partition = selectedGroup;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });

      return await database
        .get(NoteTemplateVersion.table)
        .create((entity: any) => {
          entity.partition = selectedGroup;
          entity.title = '';
          entity.template =
            'Today’s @session.type session took place at the @appointment.location on @appointment.date between @appointment.startTime to @appointment.endTime. It was conducted by @staffmember.name';
          entity.version = 1;
          entity.organizationInformation = true;
          entity.organizationName = true;
          entity.organizationLogo = true;
          entity.organizationBillingAddress = true;
          entity.organizationAddress = true;
          entity.organizationTin = true;
          entity.organizationNpi = true;

          entity.clientInformation = true;
          entity.clientFirstName = true;
          entity.clientMiddleName = true;
          entity.clientLastName = true;
          entity.clientGender = true;
          entity.clientBirthDate = true;
          entity.clientAgeInMonths = false;
          entity.clientSsn = false;
          entity.clientDiagnoses = true;
          entity.clientAssignedStaff = true;
          entity.primaryMemberId = false;
          entity.secondaryMemberId = false;

          entity.sessionInformation = true;
          entity.sessionStaff = true;
          entity.staffNpi = true;
          entity.staffLicenseNumber = true;
          entity.sessionDateOfService = true;
          entity.sessionUnitsOfService = false;
          entity.sessionStartTime = true;
          entity.sessionEndTime = true;
          entity.sessionDuration = true;
          entity.sessionHourFormat = false;
          entity.sessionType = true;
          entity.sessionLocation = true;
          entity.sessionAddress = true;

          entity.clinicalNote = true;
          entity.showAuthorsAndTimestamps = false;

          entity.sessionData = true;
          entity.skill = true;
          entity.behavior = true;

          entity.additionalFields = false;
          entity.formBuilderValues = {};

          entity.signature = true;

          entity.publishedAt = null;

          entity.createdBy = userId;
          entity.updatedBy = userId;
          entity.noteTemplate.id = newTemplate.id;
        });
    });

    navigateToUpsert(newNoteTemplateVersion.id);
  };

  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = async (item: any) => {
    item.delete();
    setShowDelete(false);
  };

  const duplicateTemplate = async (templateToDuplicate: Template) => {
    const template = await database.write(async () => {
      const newTemplate = await database
        .get(NoteTemplate.table)
        .create((entity: any) => {
          entity.partition = selectedGroup;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      return await database.get(NoteTemplateVersion.table).create(entity => {
        entity.partition = selectedGroup;
        entity.title = `${templateToDuplicate.title} - (Copy)`;
        entity.template = templateToDuplicate.template;
        entity.version = 1;

        entity.organizationInformation =
          templateToDuplicate.organizationInformation;
        entity.organizationName = templateToDuplicate.organizationName;
        entity.organizationLogo = templateToDuplicate.organizationLogo;
        entity.organizationBillingAddress =
          templateToDuplicate.organizationBillingAddress;
        entity.organizationAddress = templateToDuplicate.organizationAddress;
        entity.organizationTin = templateToDuplicate.organizationTin;
        entity.organizationNpi = templateToDuplicate.organizationNpi;

        entity.clientInformation = templateToDuplicate.clientInformation;
        entity.clientFirstName = templateToDuplicate.clientFirstName;
        entity.clientMiddleName = templateToDuplicate.clientMiddleName;
        entity.clientLastName = templateToDuplicate.clientLastName;
        entity.clientGender = templateToDuplicate.clientGender;
        entity.clientBirthDate = templateToDuplicate.clientBirthDate;
        entity.clientSsn = templateToDuplicate.clientSsn;
        entity.clientDiagnoses = templateToDuplicate.clientDiagnoses;
        entity.clientAssignedStaff = templateToDuplicate.clientAssignedStaff;
        entity.primaryMemberId = templateToDuplicate.primaryMemberId;
        entity.secondaryMemberId = templateToDuplicate.secondaryMemberId;

        entity.sessionInformation = templateToDuplicate.sessionInformation;
        entity.sessionStaff = templateToDuplicate.sessionStaff;
        entity.staffNpi = templateToDuplicate.staffNpi;
        entity.staffLicenseNumber = templateToDuplicate.staffLicenseNumber;
        entity.sessionDateOfService = templateToDuplicate.sessionDateOfService;
        entity.sessionUnitsOfService =
          templateToDuplicate.sessionUnitsOfService;
        entity.sessionStartTime = templateToDuplicate.sessionStartTime;
        entity.sessionEndTime = templateToDuplicate.sessionEndTime;
        entity.sessionHourFormat = templateToDuplicate.sessionHourFormat;
        entity.sessionType = templateToDuplicate.sessionType;
        entity.sessionLocation = templateToDuplicate.sessionLocation;
        entity.sessionAddress = templateToDuplicate.sessionAddress;

        entity.clinicalNote = templateToDuplicate.clinicalNote;
        entity.showAuthorsAndTimestamps =
          templateToDuplicate.showAuthorsAndTimestamps;

        entity.sessionData = templateToDuplicate.sessionData;
        entity.skill = templateToDuplicate.skill;
        entity.behavior = templateToDuplicate.behavior;

        entity.additionalFields = templateToDuplicate.additionalFields;
        entity.formBuilderValues = templateToDuplicate.formBuilderValues;

        entity.signature = templateToDuplicate.signature;

        entity.publishedAt = null;

        entity.createdBy = userId;
        entity.updatedBy = userId;

        entity.noteTemplate.id = newTemplate.id;
      });
    });
    navigateToUpsert(template.id);
  };

  return (
    <BaseIndexScreen
      title={translations('note_templates')}
      search={
        <Searchbar
          value={searchFilter}
          onChangeText={setSearchFilter}
          placeholder={translations('search')}
        />
      }
      canAdd={role?.noteTemplateCreate}
      add={addNewTemplate}>
      <DeleteModal
        model={'Template'}
        text={showDelete ? `${showDelete?.title}` : null}
        show={[showDelete, setShowDelete]}
        cancelled={deleteCancelled}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      {searchedItems.length === 0 ? (
        <NoResults message={translations('no_templates_found')} />
      ) : (
        <FlatList
          scrollEnabled={true}
          data={searchedItems}
          keyExtractor={item => item.id}
          renderItem={({item}: any) => (
            <NoteTemplateItem
              template={item}
              canCreate={role?.noteTemplateCreate}
              canEdit={role?.noteTemplateEdit}
              canDelete={role?.noteTemplateDelete}
              deleteSelected={(noteTemplate: any) => {
                setShowDelete(noteTemplate);
              }}
              duplicateTemplate={duplicateTemplate}
            />
          )}
        />
      )}
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of({}),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({database, authentication}: any) => ({
    allTemplates: database
      .get(NoteTemplate.table)
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
      ),
  })),
  withObservables(
    ['allTemplates'],
    ({database, authentication, allTemplates}: any) => {
      const templateIds = allTemplates.map(
        (template: NoteTemplate) => template.id,
      );
      return {
        allVersions: database
          .get(NoteTemplateVersion.table)
          .query(
            Q.where('deleted_at', null),
            Q.where('_partition', authentication.selectedGroup),
            Q.where('note_template_id', Q.oneOf(templateIds)),
          ),
      };
    },
  ),
  withObservables(['allVersions'], ({allVersions}: any) => {
    const groupedVersions = _.groupBy(
      allVersions,
      item => item.noteTemplate.id,
    );
    const newestVersions = [];
    for (const key in groupedVersions) {
      newestVersions.push(
        groupedVersions[key].sort((a, b) => b.version - a.version)[0],
      );
    }
    return {
      templates: of(newestVersions),
    };
  }),
)(NoteTemplateScreen);
