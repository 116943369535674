import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {useDebounce} from 'src/providers/hooks';
import {Suggestion, useAutoComplete} from 'src/providers/google-places';
import {FlatList, Platform, Text, TouchableOpacity, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import Map from 'src/design-system/map';
import {Typography} from 'src/styles';
import RHSeparator from 'src/common-components/custom-ui-helpers/RHSeparator';
import {HelperText, TextInput, TouchableRipple} from 'react-native-paper';
import {Modal} from 'react-native';
import {fetchAddress} from 'src/providers/google-places/auto-complete';
import SecondLine from 'src/hook-form-inputs/second-line';
import City from 'src/hook-form-inputs/city';
import Country from 'src/hook-form-inputs/country';
import PostalCode from 'src/hook-form-inputs/postal-code';
import State from 'src/hook-form-inputs/state';
import {InputGroup} from 'src/design-system';
import {useFormContext} from 'react-hook-form';

const AddressAutocomplete = forwardRef(
  (
    {
      name,
      label,
      onChange = () => {},
      horizontal = false,
      required = false,
      map = false,
      value,
      fullAddressPreview = false,
      error,
      helper = '',
      disabled = false,
      showHelper = true,
    }: any,
    ref,
  ) => {
    const styles = useStyle();
    const {getSuggestions} = useAutoComplete();
    const {setValue, watch} = useFormContext();

    const touchable = useRef();
    const textInput = useRef();

    const [searchTerm, setSearchTerm] = useState<string>(
      value?.streetLine || '',
    );

    const [suggestions] = getSuggestions(useDebounce(searchTerm, 300));
    const [open, setOpen] = useState(false);
    const [inputLayout, setInputLayout] = useState({
      width: 0,
      left: 0,
      top: 0,
    });

    const watchedStreetLine = watch(`${name}.streetLine`);

    const handleAutoCompleteSelect = async (item: Suggestion) => {
      const nextAddress = await fetchAddress(item.id);
      const street = `${nextAddress.streetLine}`;
      setSearchTerm(street);
      onChange({
        placeId: item.id,
        fullAddress: street,
        ...nextAddress,
      });
      setValue(`${name}.streetLine`, nextAddress?.streetLine);
      setValue(`${name}.secondLine`, nextAddress?.secondLine);
      setValue(`${name}.city`, nextAddress?.city);
      setValue(`${name}.state`, nextAddress?.state);
      setValue(`${name}.postalCode`, nextAddress?.postalCode);
      setValue(`${name}.country`, nextAddress?.country);
      setValue(`${name}.placeId`, item?.id);
      setValue(`${name}.location`, nextAddress?.location);
      setOpen(false);
    };

    useEffect(() => {
      if (searchTerm) {
        setValue(`${name}.streetLine`, searchTerm);
      }
    }, [searchTerm, setValue, name]);

    useEffect(() => {
      setSearchTerm(watchedStreetLine || '');
    }, [watchedStreetLine]);

    const onLayout = (event: any) => {
      if (Platform.OS === 'web') {
        setInputLayout({
          width: event.nativeEvent.layout.width,
          left: event.nativeEvent.layout.left,
          top: event.nativeEvent.layout.top,
        });
      } else {
        touchable.current?.measure((fx, fy, width, height, px, py) => {
          setInputLayout({
            width: width,
            left: px,
            top: py + height,
          });
        });
      }
    };

    return (
      <View style={horizontal ? [styles.container] : [styles.column]}>
        <View style={[horizontal ? styles.flex : {}, styles.positionRelative]}>
          <TouchableRipple
            onPress={async e => {
              if (Platform.OS === 'web') {
                setInputLayout({
                  ...inputLayout,
                  left: e.target.getBoundingClientRect().x,
                  top: e.target.getBoundingClientRect().y,
                });
              } else {
                e.target.measureInWindow((x: number, y: number) => {
                  setInputLayout({
                    ...inputLayout,
                    left: x,
                    top: y,
                  });
                });
              }
              setOpen(true);
            }}
            onLayout={onLayout}
            accessibilityLabel={label}
            rippleColor="rgba(0, 0, 0, 0)"
            style={[styles.cursorText]}>
            <View
              ref={touchableRef => (touchable.current = touchableRef)}
              pointerEvents={'none'}>
              <TextInput
                ref={ref}
                onChangeText={setSearchTerm}
                value={searchTerm}
                mode={'outlined'}
                label={label + (required ? '*' : '')}
                placeholder={label + (required ? '*' : '')}
                pointerEvents={'none'}
                style={styles.input}
                disabled={disabled}
              />
              {showHelper ? (
                <HelperText
                  type={error ? 'error' : 'info'}
                  visible={true}
                  style={styles.helper}>
                  {error ? 'ⓧ ' + error.message : helper}
                </HelperText>
              ) : null}
            </View>
          </TouchableRipple>
          <Modal
            visible={open}
            transparent
            onShow={() => {
              textInput.current.focus();
            }}>
            <View
              style={[
                styles.positionRelative,
                styles.width,
                styles.flex,
                styles.elevation,
              ]}>
              <TouchableOpacity
                onPress={() => setOpen(false)}
                style={[
                  styles.width,
                  styles.height,
                  styles.positionAbsolute,
                  styles.backgroundColorTransparent,
                  styles.top0,
                  styles.left0,
                ]}
              />
              <View
                style={[
                  styles.positionAbsolute,
                  styles.backgroundColorWhite,
                  styles.borderRadiusSM,
                  styles.overflowHidden,
                  {
                    top: inputLayout.top,
                    width: inputLayout.width,
                    left: inputLayout.left,
                  },
                ]}>
                <TextInput
                  ref={touchableRef => (textInput.current = touchableRef)}
                  value={searchTerm}
                  onChangeText={setSearchTerm}
                  mode={'outlined'}
                  label={label + (required ? '*' : '')}
                  placeholder={label + (required ? '*' : '')}
                  // pointerEvents={'none'}
                  style={styles.input}
                  selectTextOnFocus={true}
                />
                <FlatList
                  data={suggestions}
                  renderItem={({item}) => {
                    return (
                      <TouchableOpacity
                        onPress={() => handleAutoCompleteSelect(item)}
                        style={[
                          styles.row,
                          styles.alignCenter,
                          styles.paddingL,
                        ]}>
                        <Text style={[Typography.P3]}>{item.fullAddress}</Text>
                      </TouchableOpacity>
                    );
                  }}
                  showsVerticalScrollIndicator={false}
                  keyboardShouldPersistTaps="always"
                  style={[styles.backgroundColorWhite, styles.elevation]}
                />
              </View>
            </View>
          </Modal>
          <SecondLine name={name} />
          <InputGroup
            left={<State name={name} />}
            right={<PostalCode name={name} />}
          />
          <InputGroup
            left={<City name={name} />}
            right={<Country name={name} />}
          />
        </View>
        {fullAddressPreview ? (
          <View style={[styles.column, styles.marginLVertical]}>
            <Text style={Typography.P3}>{value?.location?.latitude}</Text>
            <Text style={Typography.P3}>{value?.location?.longitude}</Text>
            <Text style={Typography.P3}>{value?.streetLine}</Text>
            <Text style={Typography.P3}>{value?.city}</Text>
            <Text style={Typography.P3}>{value?.state}</Text>
            <Text style={Typography.P3}>{value?.postalCode}</Text>
            <Text style={Typography.P3}>{value?.country}</Text>
          </View>
        ) : null}
        {map ? (
          <>
            <RHSeparator height={20} width={20} />
            <View style={[horizontal ? styles.flex : {}]}>
              <Map
                location={
                  value?.location || {
                    latitude: 37.78825,
                    longitude: -122.4324,
                  }
                }
              />
            </View>
            <RHSeparator height={20} width={20} />
          </>
        ) : null}
      </View>
    );
  },
);

export default AddressAutocomplete;
