import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {useNavigation} from '@react-navigation/native';
import QuickSession from 'src/modules/scheduling/components/quick-session';
import {useStyle} from 'src/providers/style';

const HomeSessions = ({
  total = 0,
  completed = 0,
  sessionNotesDue = 0,
  role,
}: any) => {
  const navigation = useNavigation();
  const styles = useStyle();

  const sessionCount = total || 0;
  const completedPercent = (completed / (total || 1)) * 100;
  const sessionNotesPercent = (sessionNotesDue / (total || 1)) * 100;
  const notStartedPercent = (completed + sessionNotesDue / (total || 1)) * 100;

  const completedStyleBar = {
    backgroundColor: '#0569F7',
    width: `${completedPercent}%`,
  };
  const sessionNotesStyleBar = {
    backgroundColor: '#F20F4B',
    width: `${sessionNotesPercent}%`,
  };
  const notStartedStyleBar = {
    width: `${notStartedPercent}%`,
  };

  return (
    <View
      style={[
        styles.width,
        styles.backgroundColorWhite,
        styles.paddingHorizontal,
        styles.paddingLVertical,
        styles.borderRadius,
        styles.elevation,
      ]}>
      <Text style={[Typography.H5]}>
        You have {sessionCount} sessions today
      </Text>
      <Text style={[Typography.H6, styles.marginLTop]}>
        Today’s Session Progress
      </Text>
      <View
        style={[
          styles.border,
          styles.borderColorPrimary300,
          styles.borderRadius,
          styles.height14,
          styles.width,
          styles.overflowHidden,
          styles.alignStretch,
          styles.row,
          styles.marginLTop,
        ]}>
        <View style={completedStyleBar} />
        <View style={sessionNotesStyleBar} />
        <View style={notStartedStyleBar} />
      </View>
      <View
        style={[
          styles.container,
          styles.justifySpaceBetween,
          styles.paddingLBottom,
        ]}>
        <View style={[styles.row, styles.marginLTop]}>
          <View style={[styles.dotL, styles.backgroundColorSecondary700]} />
          <Text
            style={[
              Typography.CAPTION,
              styles.textColorPrimary,
              styles.marginSMLeft,
            ]}>
            Completed ({completed})
          </Text>
        </View>
        <View style={[styles.row, styles.marginLTop]}>
          <View style={[styles.dotL, styles.backgroundColorError]} />
          <Text
            style={[
              Typography.CAPTION,
              styles.textColorPrimary,
              styles.marginSMLeft,
            ]}>
            Session Notes Due ({sessionNotesDue})
          </Text>
        </View>
        <View style={[styles.row, styles.marginLTop]}>
          <View
            style={[
              styles.dotL,
              styles.backgroundColorWhite,
              styles.border,
              styles.borderColorPrimary300,
            ]}
          />
          <Text
            style={[
              Typography.CAPTION,
              styles.textColorPrimary,
              styles.marginSMLeft,
            ]}>
            Not Started ({total - completed - sessionNotesDue})
          </Text>
        </View>
      </View>
      {role?.appointmentCreate ? (
        <QuickSession navigation={navigation} />
      ) : (
        <></>
      )}
    </View>
  );
};

export default HomeSessions;
