import React from 'react';
import {Platform, Text, TouchableOpacity} from 'react-native';
import SVG from 'src/styles/svg';
import {useStyle} from 'src/providers/style';

const GoogleButton = ({login}: any) => {
  const styles = useStyle();

  return (
    <TouchableOpacity
      style={[
        styles.row,
        styles.justifyCenter,
        styles.alignCenter,
        styles.paddingLVertical,
        styles.borderRadiusM,
        styles.elevationSM,
        styles.backgroundColorWhite,
      ]}
      onPress={() => login()}>
      <SVG height={23} name={'google'} />
      <Text
        style={[
          styles.paddingLLeft,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            color: '#757575',
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'center',
            fontFamily:
              Platform.OS === 'web'
                ? '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                : 'Helvetica Neue',
          },
        ]}>
        Login with Google
      </Text>
    </TouchableOpacity>
  );
};

export default GoogleButton;
