import React, {useEffect, useState} from 'react';
import {Modal} from 'src/design-system';
import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {TouchableOpacity} from 'react-native';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import {Set} from 'src/models';
import {useSelector} from 'react-redux';

const EndSessionModal = ({endCallback, session}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const [recordZeroPrompt, setRecordZeroPrompt] = useState(false);
  const [unrecordedPrograms, setUnrecordedPrograms] = useState<any>([]);
  const database = useDatabase();
  const [openEndSessionModal, setOpenEndSessionModal] =
    useState<boolean>(false);
  const {userId} = useSelector(state => state.authentication);

  const findPrograms = async () => {
    if (session?.type !== 'supervision') {
      const sessionPrograms = await database
        .get('programs')
        .query(
          Q.on(
            'session_programs',
            Q.and(Q.where('session_id', session.id), Q.where('enabled', true)),
          ),
          Q.or(Q.where('method', 'frequency'), Q.where('method', 'duration')),
          Q.and(Q.where('state', 'active')),
        );
      for (const program of sessionPrograms) {
        const set = await database
          .get('sets')
          .query(
            Q.and(
              Q.where('program_id', program.id),
              Q.where('session_id', session.id),
              Q.where('deleted_at', Q.eq(null)),
            ),
          );
        if (set.length === 0) {
          unrecordedPrograms.push(program);
        }
      }
      if (unrecordedPrograms.length > 0) {
        setRecordZeroPrompt(true);
      } else {
        endCallback();
      }
    } else {
      endCallback();
    }
  };

  const recordAsZero = async () => {
    for (const program of unrecordedPrograms) {
      if (program?.method === 'frequency') {
        await database?.write(async () => {
          return database?.get(Set.table).create(entity => {
            entity.partition = session?.partition;
            entity.sessionId = session?.id;
            entity.programId = program?.id;
            entity.startTimestamp = session?.startTimestamp;
            entity.endTimestamp = session?.startTimestamp;
            entity.event_count = 0;
            entity.createdBy = userId;
          });
        });
      } else {
        await database?.write(async () => {
          return database?.get(Set.table).create(entity => {
            entity.partition = session?.partition;
            entity.sessionId = session?.id;
            entity.programId = program?.id;
            entity.startTimestamp = session?.startTimestamp;
            entity.endTimestamp = session?.startTimestamp;
            entity.createdBy = userId;
          });
        });
      }
    }
    endCallback();
  };
  useEffect(() => {
    if (!openEndSessionModal) {
      setUnrecordedPrograms([]);
      setRecordZeroPrompt(false);
    }
  }, [openEndSessionModal]);
  return (
    <Modal
      show={[openEndSessionModal, setOpenEndSessionModal]}
      title={translations('end_session')}
      anchor={
        <TouchableOpacity
          style={[
            styles.border,
            styles.borderColorWhite,
            styles.borderRadiusSM,
            styles.paddingMHorizontal,
            styles.paddingXS,
          ]}>
          <Text
            style={[
              Typography.P3,
              styles.textColorWhite,
              styles.textTransformUppercase,
            ]}>
            {translations('end')}
          </Text>
        </TouchableOpacity>
      }>
      {recordZeroPrompt ? (
        <>
          <Text style={[Typography.P3, styles.paddingBottom]}>
            {translations('record_as_zero_prompt')}
          </Text>
          {unrecordedPrograms.map((program: any) => {
            return <Text style={Typography.P3}>{program.name}</Text>;
          })}
          <View
            style={[
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
              styles.paddingTop,
            ]}>
            <RHButton
              secondary
              onPress={endCallback}
              textColor={Colors.RAVEN_BLACK}
              color="black"
              mode="outlined">
              {'Ignore'}
            </RHButton>
            <RHButton mode="contained" onPress={recordAsZero}>
              {'Record as zero'}
            </RHButton>
          </View>
        </>
      ) : (
        <>
          <Text style={[Typography.P3, styles.paddingBottom]}>
            {translations('end_session_prompt')}
          </Text>
          <View
            style={[
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
              styles.paddingTop,
            ]}>
            <RHButton
              secondary
              onPress={() => setOpenEndSessionModal(false)}
              textColor={Colors.RAVEN_BLACK}
              color="black"
              mode="outlined">
              {translations('cancel_button')}
            </RHButton>
            <RHButton mode="contained" onPress={findPrograms}>
              {translations('end_session_confirm')}
            </RHButton>
          </View>
        </>
      )}
    </Modal>
  );
};

export default EndSessionModal;
