import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import React from 'react';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {User} from 'src/models';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

export const cancelReason = (cancellationReason: string) => {
  let result: any = {};
  switch (cancellationReason) {
    case 'staff':
      result.color = Colors.TEAL_500;
      result.name = 'Staff initiated';
      break;
    case 'client':
      result.color = Colors.LIME_500;
      result.name = 'Client initiated';
      break;
    case 'noShow':
      result.color = Colors.RUBY_500;
      result.name = 'No Show';
      break;
    case 'error':
      result.color = Colors.TORCH_RED_LIGHT;
      result.name = 'Added in Error';
      break;
    case 'absent':
      result.color = Colors.VIOLET_500;
      result.name = 'Student Absent';
      break;
    default:
      result.color = Colors.ORANGE;
      result.name = 'Other';
  }
  return result;
};
const CancellationListItem = ({
  cancellationReason,
  title,
  cancellationMoreInfo,
  deletedByUser,
}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.container, styles.flex]}>
      <View
        style={[
          styles.alignSelfCenter,
          styles.dotL,
          styles.marginMRight,
          {backgroundColor: cancelReason(cancellationReason).color},
        ]}
      />
      <View
        style={[
          styles.row,
          styles.marginHorizontal,
          styles.alignCenter,
          styles.flex,
        ]}>
        <Icon name={'account'} size={20} style={[styles.paddingMRight]} />
        <Text style={[Typography.P3_MEDIUM, styles.paddingXSTop]}>{title}</Text>
      </View>
      <View
        style={[
          styles.row,
          styles.marginHorizontal,
          styles.alignCenter,
          styles.flex,
        ]}>
        <Icon
          name={'card-account-mail'}
          size={20}
          style={[styles.paddingMRight]}
        />
        <Text
          style={[
            Typography.P3_MEDIUM,
            styles.paddingXSTop,
            styles.textColorError,
          ]}>
          {deletedByUser?.firstName} {deletedByUser?.lastName}
        </Text>
      </View>

      <View
        style={[
          styles.row,
          styles.marginHorizontal,
          styles.alignCenter,
          styles.flex,
        ]}>
        <Icon name={'cancel'} size={20} style={[styles.paddingMRight]} />
        <Text style={[Typography.P3_MEDIUM]}>
          {`${cancelReason(cancellationReason).name}${
            cancellationMoreInfo ? ` - ${cancellationMoreInfo}` : ''
          }`}
        </Text>
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['deletedBy'], ({deletedBy, database}: any) => ({
    deletedByUser: deletedBy
      ? database.get(User.table).findAndObserve(deletedBy)
      : of({}),
  })),
)(CancellationListItem);
