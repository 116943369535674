import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {authApi} from 'src/services/auth';
import {identityApi} from 'src/services/identity';
import authentication from './auth';
import session from './session';
import schedulingFilters from 'src/redux/scheduling-filters';
import claimFilters from './claim-filters';
import sessionHistoryFilters from 'src/redux/session-history-filters';

const combinedReducer = combineReducers({
  authentication: authentication,
  schedulingFilters: schedulingFilters,
  sessionHistoryFilters: sessionHistoryFilters,
  inSession: session,
  claimFilters: claimFilters,
  [authApi.reducerPath]: authApi.reducer,
  [identityApi.reducerPath]: identityApi.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout/fulfilled') {
    state = undefined;
  } else if (
    action.type === 'auth/checkLogin/fulfilled' ||
    action.type === 'auth/login/fulfilled'
  ) {
    state = {
      ...state,
      schedulingFilters: {
        ...state.schedulingFilters,
        selectedFilters: {
          ...state.schedulingFilters.selectedFilters,
          participants: [
            {
              type: 'user',
              value: action.payload.userId,
              color: '#000000',
              textColor: '#FFFFFF',
            },
          ],
          users: [action.payload.userId],
        },
      },
      inSession: {
        ...state.inSession,
        notes: {
          ...state.inSession.notes,
          ...action.payload.notes,
        },
      },
      claimFilters: {
        ...state.claimFilters,
        selectedFilters: {
          ...state.claimFilters.selectedFilters,
        },
      },
      sessionHistoryFilters: {
        ...state.sessionHistoryFilters,
        selectedFilters: {
          ...state.sessionHistoryFilters.selectedFilters,
        },
      },
    };
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  devTools: __DEV__,
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authApi.middleware)
      .concat(identityApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
