import React from 'react';
import PatientDetails from 'src/modules/patients/components/patientDetails';
import PatientPlan from 'src/modules/patients/components/patientPlan';
import Authorizations from 'src/modules/patients/components/authorizations';
import PatientDocuments from 'src/modules/patients/components/patientDocuments';
import {BaseScreen, TabBar} from 'src/design-system';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import PatientSummary from 'src/modules/patients/components/patient-summary';
import EnvironmentalFactors from 'src/modules/patients/components/environmental-factors';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {of, mergeMap} from 'rxjs';
import {Q} from '@nozbe/watermelondb';
import {History} from 'src/modules/patients/components';
import AssignStaffTab from 'src/modules/patients/components/assign-staff-tab';
import {Platform} from 'react-native';

const Navigator = createMaterialTopTabNavigator();
const isWeb = Platform.OS === 'web';

function PatientProfile({route, navigation, role, patient, organization}: any) {
  const navigateToUpsert = () => {
    navigation.navigate('PatientFormScreen', {
      id: patient.id,
    });
  };

  const isClient = patient.firstName && patient.lastName ? 'Client ' : null;
  const fullName = `| ${patient.firstName} ${patient.lastName}`;

  return (
    <BaseScreen title={isClient + fullName}>
      <TabBar navigator={Navigator} location={'PatientProfile'} route={route}>
        {isWeb && organization?.reporting ? (
          <Navigator.Screen name="Dashboard">
            {() => <PatientSummary patient={patient} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        <Navigator.Screen name="Summary">
          {() => (
            <PatientDetails
              patient={patient}
              canEdit={role?.patientEdit}
              navigateToUpsert={navigateToUpsert}
            />
          )}
        </Navigator.Screen>
        {role?.treatmentPlanView ? (
          <Navigator.Screen name="Treatment Plan">
            {() => (
              <PatientPlan
                navigation={navigation}
                patient={patient}
                role={role}
              />
            )}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.clientHistoryView ? (
          <Navigator.Screen name="History">
            {() => <History patient={patient} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.authorizationView ? (
          <Navigator.Screen name="Authorizations">
            {() => <Authorizations patient={patient} role={role} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.environmentalFactorView ? (
          <Navigator.Screen name="Environmental Factors">
            {() => <EnvironmentalFactors patient={patient} role={role} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.clientDocumentView ? (
          <Navigator.Screen name="Documents">
            {() => <PatientDocuments patient={patient} role={role} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.assignedStaffView ? (
          <Navigator.Screen name="Assigned Staff">
            {() => <AssignStaffTab patient={patient} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
      </TabBar>
    </BaseScreen>
  );
}

export default compose(
  withDatabase,
  withState,
  withObservables(['route'], ({route, database}: any) => {
    const patientId = route.params?.patientId;
    return {
      patient: database.get('patients').findAndObserve(patientId),
    };
  }),
  withObservables([], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
    organization: database
      ?.get('instances')
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
)(PatientProfile);
