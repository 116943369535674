import React from 'react';
import {ArrayInput, DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import _ from 'lodash';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: [];
}

interface Props {
  valueKey: string;
}

export const Import = (
  key: string = 'line',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Street Address',
  key,
});

const resolveDisplayValue = (value: []) => {
  return value ? _.capitalize(value.join('\r\n')) : '';
};

const AddressLineDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Street Address</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const AddressLineInput = ({valueKey}: Props) => {
  return (
    <ArrayInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'line'}
      name={'Street Address'}
      required={false}
    />
  );
};

export const validation = () =>
  Yup.array().of(Yup.string().required('Street address is required')).min(1);

export const Display = AddressLineDisplay;
export const Input = AddressLineInput;
export const Value = resolveDisplayValue;

export default AddressLineInput;
