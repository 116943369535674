import React from 'react';
import {DataItem, RadioInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import _ from 'lodash';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const addressUseValues = [
  {value: 'home', title: 'Home', label: 'Home'},
  {value: 'work', title: 'Work', label: 'Work'},
];

export const Import = (
  key: string = 'use',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Address Type (${addressUseValues.map(value => value.label).join(', ')})`,
  key,
  type: 'select',
  selectOptions: addressUseValues,
});

const resolveDisplayValue = (value: string) => {
  return _.capitalize(value);
};

const AddressUseDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Address</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const AddressUseInput = ({valueKey}: Props) => {
  return (
    <RadioInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'use'}
      name={'Address'}
      required={false}
      buttons={addressUseValues}
    />
  );
};

export const validation = () =>
  Yup.string().required('Address type is required');

export const Display = AddressUseDisplay;
export const Input = AddressUseInput;
export const Value = resolveDisplayValue;

export default AddressUseInput;
