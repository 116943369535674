import {json, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Transaction extends Base {
  static table = 'transactions';
  static associations = {
    remit_claim: {type: 'belongs_to', foreignKey: 'remit_claim_id'},
    service_lines: {type: 'belongs_to', foreignKey: 'service_line_id'},
  };

  @text('remit_claim_id') remitClaimId;
  @text('service_line_id') serviceLineId;
  @json('response', value => value) response;
}
