import React from 'react';
import {View, Text, TouchableOpacity, Platform} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, Typography} from 'src/styles';
import {ProgramMethodAvatar} from 'src/modules/programs/components';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import _ from 'lodash';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useStyle} from 'src/providers/style';

interface ProgramProps {
  drag: () => void;
  editing: boolean;
  sessionProgram: any;
  program: any;
  navigate: () => void;
}

const SessionBehaviorRow = ({
  sessionProgram,
  drag,
  // navigate,
  program,
}: ProgramProps) => {
  const styles = useStyle();

  const toggleEnabled = () => {
    sessionProgram.updateEntity({
      enabled: !sessionProgram.enabled,
    });
  };

  const isWeb = Platform.OS === 'web';

  return (
    <View style={[styles.card]}>
      <View
        style={[
          styles.row,
          styles.flex,
          styles.alignCenter,
          styles.backgroundColorWhite,
          styles.paddingRight,
        ]}>
        <TouchableOpacity
          onPressIn={sessionProgram.enabled && isWeb ? drag : () => {}}
          onLongPress={sessionProgram.enabled && !isWeb ? drag : () => {}}
          delayLongPress={50}>
          <Icon
            style={[styles.paddingL, styles.paddingLeft]}
            name="reorder-horizontal"
            size={20}
            color={
              sessionProgram.enabled ? Colors.GRAY_300 : Colors.RAVEN_WHITE
            }
          />
        </TouchableOpacity>
        <TouchableOpacity style={[styles.paddingL]} onPress={toggleEnabled}>
          <Icon
            name={
              sessionProgram.enabled
                ? 'checkbox-marked'
                : 'checkbox-blank-outline'
            }
            size={20}
            color={
              sessionProgram.enabled ? Colors.PRIMARY_900 : Colors.TEXT_DISABLED
            }
          />
        </TouchableOpacity>
        <View
          style={[
            styles.alignCenter,
            styles.justifyCenter,
            styles.marginLRight,
            styles.paddingL,
          ]}>
          <ProgramMethodAvatar type={program?.method} />
        </View>
        <View style={[styles.row]}>
          <View style={[styles.column]}>
            <Text
              style={[
                Typography.P3_MEDIUM,
                sessionProgram.enabled
                  ? styles.textColorPrimary
                  : styles.textColorDisabled,
              ]}>
              {program.name}
            </Text>
            <Text
              style={[
                Typography.CAPTION,
                sessionProgram.enabled
                  ? styles.textColorSecondary
                  : styles.textColorDisabled,
              ]}>
              {_.capitalize(program?.type) || ''} |{' '}
              {_.split(program?.method, '_')
                .map(value => _.capitalize(value))
                .join(' ')}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({sessionProgramId, database}: any) => ({
    sessionProgram: database
      .get('session_programs')
      .findAndObserve(sessionProgramId),
  })),
  withObservables([], ({sessionProgram}: any) => ({
    program: sessionProgram.program,
  })),
)(SessionBehaviorRow);
