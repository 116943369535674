import React, {forwardRef} from 'react';
import {Text, View} from 'react-native';
import {HelperText, TextInput} from 'react-native-paper';

import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

interface Props {
  label: string;
  required: boolean;
  error?: any;
  value: any;
  onChange: (value: any) => void;
}

const DurationInput = forwardRef(
  ({error, required = false, label, value, onChange}: Props, ref) => {
    const styles = useStyle();

    const d = Number(value || 0);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    const changeHour = (hourValue: string) => {
      let hour = 0;
      if (hourValue.length > 0) {
        hour = parseInt(hourValue, 10);
      }
      onChange(hour * 3600 + m * 60 + s);
    };

    const changeMinute = (minuteValue: string) => {
      let minute = 0;
      if (minuteValue.length > 0) {
        minute = parseInt(minuteValue, 10);
      }
      onChange(h * 3600 + minute * 60 + s);
    };

    const changeSecond = (secondValue: string) => {
      let second = 0;
      if (secondValue.length > 0) {
        second = parseInt(secondValue, 10);
      }
      onChange(h * 3600 + m * 60 + second);
    };

    return (
      <>
        <Text
          ref={ref}
          style={[
            Typography.LABEL,
            styles.textColorSecondary,
            styles.marginSMBottom,
          ]}>
          {label + (required ? '*' : '')}
        </Text>
        <View style={[styles.row]}>
          <TextInput
            mode="outlined"
            placeholder="Hours"
            theme={{colors: {primary: Colors.TEXT_SECONDARY}}}
            label="Hours"
            style={[styles.input]}
            keyboardType="numeric"
            maxLength={2}
            value={h.toString()}
            onChangeText={hour => changeHour(hour)}
          />
          <TextInput
            mode="outlined"
            placeholder="Minutes"
            theme={{colors: {primary: Colors.TEXT_SECONDARY}}}
            label="Minutes"
            style={[styles.input, styles.marginSMLeft]}
            keyboardType="numeric"
            maxLength={2}
            value={m.toString()}
            onChangeText={minute => changeMinute(minute)}
          />
          <TextInput
            mode="outlined"
            placeholder="Seconds"
            theme={{colors: {primary: Colors.TEXT_SECONDARY}}}
            label="Seconds"
            style={[styles.input, styles.marginSMLeft]}
            keyboardType="numeric"
            maxLength={2}
            value={s.toString()}
            onChangeText={second => changeSecond(second)}
          />
        </View>
        <HelperText type="error" visible={true} style={styles.helper}>
          {error && 'ⓧ ' + error.message}
        </HelperText>
      </>
    );
  },
);

export default DurationInput;
