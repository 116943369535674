import React from 'react';
import {View, Text} from 'react-native';
import {Typography, Colors} from 'src/styles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useStyle} from 'src/providers/style';

const NoResults = ({message, secondaryMessage, iconName, style = {}}: any) => {
  const styles = useStyle();

  return (
    <View
      style={[
        styles.flex,
        styles.alignCenter,
        styles.justifyCenter,
        styles.padding,
        style,
      ]}>
      <Icon
        color={Colors.GRAY_200}
        name={iconName}
        size={52}
        style={[styles.marginLBottom]}
      />
      {message ? <Text style={[Typography.P1_MEDIUM]}>{message}</Text> : <></>}
      {secondaryMessage ? (
        <Text style={[Typography.P3]}>{secondaryMessage}</Text>
      ) : (
        <></>
      )}
    </View>
  );
};

export default NoResults;
