import React from 'react';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {View} from 'react-native';
import {useForm, FormProvider} from 'react-hook-form';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
interface Props {
  onAssign: any;
}

const DiagnosisForm = ({onAssign}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  const methods = useForm({
    defaultValues: {
      diagnosis: '',
      name: '',
    },
  });

  return (
    <FormProvider {...methods}>
      <HookFormInput name={'diagnosis'} label={'Code'} />
      <HookFormInput name={'name'} label={'Name'} />
      <View style={[styles.row, styles.alignCenter, styles.justifyEnd]}>
        <RHButton
          secondary
          mode="contained"
          onPress={methods.handleSubmit(onAssign)}>
          {translations('save_button')}
        </RHButton>
      </View>
    </FormProvider>
  );
};

export default DiagnosisForm;
