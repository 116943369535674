import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {useTranslations} from 'src/providers/translation';
import HookFormRadioInput from 'src/hook-form-wrapper/radio-input';

interface DisplayProps {
  value: any;
  type: string;
  items: {value: any; label: string}[];
}

interface Props {
  name?: string;
  method?: string;
  type?: string;
  defaultValue?: any;
  items: {value: any; label: string}[];
}

export const Import = (
  key: string = 'unit',
  prefix?: string,
  items?: {value: any; label: string}[],
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Unit',
  key,
  type: 'select',
  selectOptions: items.map(item => ({
    ...item,
    value: item.value.toString(),
  })),
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: any, items: any) => {
  return items.find(item => item.value === value)?.label;
};

export const Display = ({value, items, type}: DisplayProps) => {
  const translations = useTranslations();
  const title =
    type === 'unit'
      ? 'Measurement'
      : type !== 'adjustment'
      ? translations('occurrences_per')
      : '';
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{title}</Text>
      <Text style={[Typography.P3]}>{Value(value, items)}</Text>
    </DataItem>
  );
};

export const Input = ({name, type, items}: Props) => {
  return (
    <HookFormRadioInput
      name={(name ? name + '.' : '') + type}
      label={''}
      required={false}
      items={items}
    />
  );
};

export const validation = () => Yup.string().required('Unit is required');

export default Input;
