import {IconButton, Menu} from 'src/design-system';
import React from 'react';

interface Props {
  canEdit: boolean;
  editCallback: () => void;
  canDelete: boolean;
  deleteCallback: () => void;
  canArchive?: boolean;
  archiveTxt?: string;
  archiveCallback?: () => void;
}

const MoreMenu = ({
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
  canArchive = false,
  archiveTxt = 'Archive',
  archiveCallback,
}: Props) => {
  const options = [];
  if (canEdit) {
    options.push({
      onPress: editCallback,
      title: 'Edit',
      icon: 'pencil',
    });
  }
  if (canArchive) {
    options.push({
      onPress: archiveCallback,
      title: archiveTxt,
      icon: 'archive',
    });
  }
  if (canDelete) {
    options.push({
      onPress: deleteCallback,
      title: 'Delete',
      icon: 'delete',
    });
  }
  return (
    <>
      {canEdit || canDelete ? (
        <Menu
          anchor={<IconButton type={'icon'} icon={'dots-vertical'} />}
          options={options}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MoreMenu;
