import React, {useState} from 'react';
import {Text, View, Platform, TouchableOpacity} from 'react-native';
import {IconButton, Checkbox, TextInput, HelperText} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  OpacityDecorator,
  ShadowDecorator,
} from 'react-native-draggable-flatlist';
import RHButton from 'src/common-components/custom-ui-helpers/RHButton';
import {Separator} from 'src/common-components/atoms';
import {useStyle} from 'src/providers/style';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';

interface Props {
  target: any;
  drag: any;
  isActive: boolean;
  index: number | undefined;
  setTarget: (target: any) => void;
  validName: (id: string, name: string) => Promise<boolean>;
}

const TargetItem = ({target, drag, validName, setTarget, isActive}: Props) => {
  const styles = useStyle();

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(target.name);
  const [error, setError] = useState<boolean | string>(false);

  const isWeb = Platform.OS === 'web';

  return (
    <OpacityDecorator activeOpacity={0.5}>
      <ShadowDecorator>
        <View
          style={[
            styles.row,
            styles.alignCenter,
            styles.card,
            isActive ? styles.backgroundColorSecondary200 : {},
          ]}>
          {isEditing ? (
            <>
              <View style={[styles.flex]}>
                <TextInput
                  style={[styles.input]}
                  theme={{
                    colors: {primary: Colors.TEXT_SECONDARY},
                  }}
                  value={name}
                  onChangeText={setName}
                  mode="outlined"
                  placeholder={target.name}
                />
                {error ? (
                  <HelperText
                    type={'error'}
                    visible={true}
                    style={[styles.helper]}>
                    {error ? 'ⓧ ' + error : ''}
                  </HelperText>
                ) : (
                  <></>
                )}
              </View>
              <Separator width={8} />
              <RHButton
                mode="contained"
                icon="content-save"
                color={Colors.RAVEN_BLACK}
                onPress={async () => {
                  if (await validName(target.id, name)) {
                    target.updateEntity({
                      name: name,
                    });
                    setIsEditing(false);
                    setError(false);
                  } else {
                    setError('Target Name already exists!');
                  }
                }}>
                SAVE
              </RHButton>
            </>
          ) : (
            <>
              <TouchableOpacity
                onPressIn={isWeb ? drag : () => {}}
                onLongPress={!isWeb ? drag : () => {}}
                delayLongPress={50}
                disabled={isEditing}>
                <Icon
                  color={Colors.GRAY_400}
                  style={[styles.padding]}
                  name="reorder-horizontal"
                  size={20}
                />
              </TouchableOpacity>
              <Checkbox.Android
                status={target?.enabled ? 'checked' : 'unchecked'}
                color={Colors.RAVEN_BLACK}
                uncheckedColor={Colors.PRIMARY_200}
                style={[styles.padding]}
                onPress={() => {
                  target.updateEntity({
                    enabled: !target?.enabled,
                  });
                }}
              />
              <Text style={[styles.flex, Typography.P2, styles.paddingLLeft]}>
                {target.name}
              </Text>
              <IconButton
                size={20}
                icon="pencil"
                style={[styles.paddingLRight]}
                onPress={() => {
                  setIsEditing(true);
                }}
              />
              <IconButton
                size={20}
                icon="trash-can"
                style={[styles.paddingLRight]}
                onPress={() => {
                  setTarget(target);
                }}
              />
            </>
          )}
        </View>
      </ShadowDecorator>
    </OpacityDecorator>
  );
};

export default compose(
  withObservables(['target'], ({target}: any) => ({
    target,
  })),
)(TargetItem);
