import React from 'react';
import {User} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';

interface Props {
  override?: string;
  name: string;
  label: string;
  items: any[];
  selectAll?: boolean;
  multiple?: boolean;
  showHelper?: boolean;
}

const UserSelect = ({
  override,
  name,
  label = 'Select Staff',
  items,
  selectAll = false,
  multiple = false,
  showHelper = true,
}: Props) => {
  return (
    <HookFormSelectInput
      name={override ?? (name ? name + '.' : '') + 'staff'}
      label={label}
      required={false}
      multiple={multiple}
      selectAll={selectAll}
      items={items}
      showHelper={showHelper}
    />
  );
};
export default compose(
  withDatabase,
  withState,
  withObservables(
    ['authentication'],
    ({authentication, database, includeArchived = false}: any) => {
      let queryConditions = [];
      if (includeArchived) {
        queryConditions.push(
          Q.or(Q.where('state', 'active'), Q.where('state', 'archived')),
        );
      } else {
        queryConditions.push(
          Q.where('state', 'active'),
          Q.where('deleted_at', null),
        );
      }
      return {
        users: database
          .get(User.table)
          .query(
            Q.where('deleted_at', null),
            ...queryConditions,
            Q.where('_partition', authentication.selectedGroup),
            Q.sortBy('first_name', Q.asc),
            Q.sortBy('last_name', Q.asc),
          ),
      };
    },
  ),
  withObservables([], ({users}) => ({
    items: of(
      users.map((user: User) => {
        return {
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        };
      }),
    ),
  })),
)(UserSelect);
