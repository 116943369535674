import React from 'react';
import {View, ScrollView, FlatList, Text} from 'react-native';
import {IconButton} from 'react-native-paper';
import {Typography} from 'src/styles';
import {staffStateArray} from '../staff-filter';
import {useStyle} from 'src/providers/style';

const StaffFilterChip = ({filters, setFilters, tags}: any) => {
  const styles = useStyle();
  const checkStaffState = (chip: string) => {
    return (
      staffStateArray.findIndex(
        (staffState: any) => staffState.value === chip,
      ) !== -1
    );
  };

  const deleteFilterChip = (_filter: string) => {
    if (checkStaffState(_filter)) {
      setFilters({
        ...filters,
        staffState: filters?.staffState.filter(
          (value: any) => value !== _filter,
        ),
      });
    } else if (
      filters?.relatedServices.findIndex(
        (txType: any) => txType === _filter,
        -1,
      )
    ) {
      setFilters({
        ...filters,
        relatedServices: filters?.relatedServices.filter(
          (value: any) => value !== _filter,
        ),
      });
    }
  };
  return (
    <View
      style={[styles.maxWidth, styles.paddingHorizontal, styles.paddingBottom]}>
      <ScrollView style={styles.scroll}>
        <FlatList
          horizontal
          data={[...filters?.staffState, ...filters?.relatedServices]}
          renderItem={({item: selectedChip}) => {
            return (
              <View
                style={[
                  styles.backgroundColorBlack,
                  styles.borderRadiusSM,
                  styles.marginSMVertical,
                  styles.marginLRight,
                  styles.displayFlex,
                  styles.row,
                ]}
                key={`filter-chip-key-${selectedChip}`}>
                <Text
                  style={[
                    Typography.P2,
                    styles.textColorWhite,
                    styles.alignSelfCenter,
                    styles.marginLHorizontal,
                  ]}>
                  {checkStaffState(selectedChip)
                    ? staffStateArray[
                        staffStateArray.findIndex(
                          (staffState: any) =>
                            staffState.value === selectedChip,
                        )
                      ]?.title
                    : tags.find((item: any) => item.id === selectedChip)?.name}
                </Text>
                <IconButton
                  size={15}
                  icon="close"
                  color="white"
                  onPress={() => deleteFilterChip(selectedChip)}
                />
              </View>
            );
          }}
        />
      </ScrollView>
    </View>
  );
};

export default StaffFilterChip;
