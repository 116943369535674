import React, {useCallback, useEffect, useState} from 'react';
import {FlatList, View} from 'react-native';
import {
  BaseIndexScreen,
  CollapsibleFilterItem,
  DeleteModal,
  FormSectionHeader,
  IconButton,
  RetractingDrawer,
} from 'src/design-system';
import Client from 'src/hook-form-inputs/client';
import {useTranslations} from 'src/providers/translation';
import {FormProvider, useForm} from 'react-hook-form';
import Staff from 'src/hook-form-inputs/staff';
import {useStyle} from 'src/providers/style';
import HookFormDateInput from 'src/hook-form-wrapper/date-input';
import ListItemSeparator from 'src/common-components/separator';
import SessionHistoryListItem from '../session-history-list-item';
import moment from 'moment';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import withState from 'src/redux/wrapper';
import {rrulestr} from 'rrule';
import {useDispatch, useSelector} from 'react-redux';
import {Appointment, Session, Set, Event, User, Patient} from 'src/models';
import {setFilters} from 'src/redux/session-history-filters';
import LocationCheckbox from 'src/hook-form-inputs/location-checkbox';
import StatusCheckbox from 'src/hook-form-inputs/status-checkbox';
import {useNavigation} from '@react-navigation/native';
import {Separator} from 'src/common-components/atoms';
import {startOfDay} from 'date-fns';
import {exportCsv} from 'src/common-utils/export-csv';
import {Colors} from 'src/styles';
import {Value as Location} from 'src/hook-form-inputs/location';
import _ from 'lodash';
import calculateSessionStatus from 'src/common-utils/calculate-session-status';

const SessionHistoryTab = ({role, profile}: any) => {
  const {selectedGroup} = useSelector(state => state.authentication);
  // TODO: Add a Cancelled Status
  // TODO: Export PDF / CSV - PDF Individualized for each Completed Session
  // TODO: Note History as Audit Screen
  // TODO: Navigate to Session
  const styles = useStyle();
  const translations = useTranslations();
  const database = useDatabase();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const {selectedFilters} = useSelector(state => state.sessionHistoryFilters);

  const [items, setItems] = useState<any>([]);
  const [showDelete, setShowDelete] = useState<string | boolean>(false);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [exportItems, setExportItems] = useState<any[]>([]);
  const methods = useForm({
    defaultValues: {
      ...selectedFilters,
      startDate: new Date(selectedFilters.startDate),
      endDate: new Date(selectedFilters.endDate),
    },
  });

  const recalculateAppointments = async () => {
    const locationQuery = [];
    if (selectedFilters?.locations && selectedFilters.locations.length) {
      locationQuery.push(
        Q.where('location', Q.oneOf(selectedFilters.locations)),
      );
    }

    const queriedAppointments = await database
      .get(Appointment.table)
      .query(
        Q.where('_partition', selectedGroup),
        Q.or(
          Q.and(
            Q.where('rrule', ''),
            Q.where('date', Q.gt(selectedFilters.startDate)),
            Q.where('date', Q.lt(selectedFilters.endDate)),
          ),
          Q.and(
            Q.where('rrule', Q.notEq('')),
            Q.or(
              Q.and(
                Q.where('start_timestamp', Q.gt(selectedFilters.startDate)),
                Q.where('start_timestamp', Q.lt(selectedFilters.endDate)),
              ),
              Q.and(
                Q.where('start_timestamp', Q.lt(selectedFilters.startDate)),
                Q.or(
                  Q.where('end_timestamp', Q.eq(0)),
                  Q.where('end_timestamp', Q.gt(selectedFilters.startDate)),
                ),
              ),
            ),
          ),
        ),
        Q.where('deleted_at', null),
        ...locationQuery,
        Q.sortBy('date', Q.asc),
      );

    const curatedAppointments = [];
    for (const appointment of queriedAppointments) {
      const staffParticipants = await appointment.staffParticipants.fetch();
      if (appointment.rrule) {
        const rruleSet = rrulestr(appointment.rrule, {
          forceset: true,
        });
        for (const date of rruleSet
          .between(
            new Date(startOfDay(selectedFilters.startDate)),
            new Date(selectedFilters.endDate),
          )
          .map(rruleDate => {
            const realDate = new Date(
              rruleDate.getFullYear(),
              rruleDate.getMonth(),
              rruleDate.getDate(),
            );
            realDate.setTime(
              rruleDate.getTime() +
                rruleSet.options.dtstart.getTimezoneOffset() * 60 * 1000,
            );
            return realDate;
          })) {
          const exdateFind = rruleSet
            .exdates()
            .findIndex(
              (exdate: any) =>
                moment(exdate).format('MMM DD YYYY') ===
                moment(date).format('MMM DD YYYY'),
            );
          if (exdateFind !== -1) {
            continue;
          }
          for (const staffParticipant of staffParticipants) {
            if (
              selectedFilters.staff.length > 0 &&
              !selectedFilters.staff.includes(staffParticipant.userId)
            ) {
              continue;
            }
            let sessions = await database
              .get(Session.table)
              .query(
                Q.where('appointment_id', appointment.id),
                Q.where('session_date', moment(date).format('YYYY-MM-DD')),
                Q.where('deleted_at', null),
                Q.take(1),
              )
              .fetch();

            let status = 'not-started';
            let session = null;
            if (sessions && sessions.length > 0) {
              session = sessions[0];
              status = calculateSessionStatus(session);
            }

            if (
              !session ||
              !session?.patientId ||
              !selectedFilters.status.includes(status) ||
              (selectedFilters.clients.length > 0 &&
                !selectedFilters.clients.includes(session?.patientId))
            ) {
              continue;
            }
            curatedAppointments.push({
              id: session.id,
              uniqueId: `${appointment.id}-${session.id}-${staffParticipant.userId}-${session.patientId}`,
              date: date,
              start:
                status === 'not-started'
                  ? staffParticipant.startTime
                  : session.editedStartTimestamp ?? session.startTimestamp,
              end:
                status === 'not-started'
                  ? staffParticipant.endTime
                  : status === 'in-progress'
                  ? staffParticipant.endTime
                  : session.editedEndTimestamp ?? session.endTimestamp,
              user: staffParticipant.userId,
              patient: session.patientId,
              status: status,
              location: appointment.location,
            });
          }
        }
      } else {
        for (const staffParticipant of staffParticipants) {
          if (
            selectedFilters.staff.length > 0 &&
            !selectedFilters.staff.includes(staffParticipant.userId)
          ) {
            continue;
          }

          let sessions = await database
            .get(Session.table)
            .query(
              Q.where('appointment_id', appointment.id),
              Q.where(
                'session_date',
                moment(appointment.date).format('YYYY-MM-DD'),
              ),
              Q.where('deleted_at', null),
              Q.take(1),
            )
            .fetch();

          let status = 'not-started';
          let session = null;
          if (sessions && sessions.length > 0) {
            session = sessions[0];
            status = calculateSessionStatus(session);
          }

          if (
            !session ||
            !session?.patientId ||
            !selectedFilters.status.includes(status) ||
            (selectedFilters.clients.length > 0 &&
              !selectedFilters.clients.includes(session?.patientId))
          ) {
            continue;
          }

          curatedAppointments.push({
            id: session.id,
            uniqueId: `${appointment.id}-${session.id}-${staffParticipant.userId}-${session.patientId}`,
            date: appointment.date,
            start:
              status === 'not-started'
                ? staffParticipant.startTime
                : session.editedStartTimestamp ?? session.startTimestamp,
            end:
              status === 'not-started'
                ? staffParticipant.endTime
                : status === 'in-progress'
                ? staffParticipant.endTime
                : session.editedEndTimestamp ?? session.endTimestamp,
            user: staffParticipant.userId,
            patient: session.patientId,
            status: status,
            location: appointment.location,
          });
        }
      }
    }
    setItems(
      curatedAppointments.sort(
        (a: any, b: any) => new Date(a.date) - new Date(b.date),
      ),
    );
    setExportItems(
      await Promise.all(
        curatedAppointments.map(async appointment => {
          const staffParticipant = await database
            .get(User.table)
            .find(appointment.user);
          const patient = await database
            .get(Patient.table)
            .find(appointment.patient);

          return {
            staffName:
              staffParticipant?.firstName + ' ' + staffParticipant?.lastName,
            clientName: patient?.firstName + ' ' + patient?.lastName,
            sessionDate: moment(appointment.date).format('YYYY-MM-DD'),
            startDate: moment(appointment.start).format('HH:mm:ss'),
            endDate: moment(appointment.end).format('HH:mm:ss'),
            location: Location(appointment.location),
            status: _.capitalize(appointment.status),
          };
        }),
      ),
    );
  };

  useEffect(
    () => {
      if (selectedFilters.startDate && selectedFilters.endDate) {
        recalculateAppointments();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedFilters.startDate,
      selectedFilters.endDate,
      selectedFilters.locations,
      selectedFilters.staff,
      selectedFilters.clients,
      selectedFilters.status,
    ],
  );

  const viewCallback = (item: any) => {
    if (item.status === 'not-started' || item.status === 'in-progress') {
      navigation.navigate('SessionCollect', {
        id: item.id,
      });
    } else if (item.status === 'missing-values') {
      navigation.navigate('SessionMessage', {
        id: item.id,
      });
    } else if (item.status === 'completed') {
      navigation.navigate('SessionRecap', {
        id: item.id,
      });
    }
  };

  const renderItem = useCallback(
    ({item}: any) => (
      <SessionHistoryListItem
        item={item}
        viewCallback={viewCallback}
        canDelete={role?.sessionHistoryDelete || true}
        deleteCallback={setShowDelete}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const subscription = methods.watch(() => {
      dispatch(
        setFilters({
          ...methods.getValues(),
          startDate: methods.getValues().startDate.getTime(),
          endDate: methods.getValues().endDate.getTime(),
        }),
      );
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch]);

  const deleteCancelled = () => {
    setShowDelete(false);
  };

  const deleteConfirmed = async (item: any) => {
    let session = await database.get(Session.table).find(item);

    await session.delete();

    let sets = await database
      .get(Set.table)
      .query(Q.where('session_id', session.id))
      .fetch();
    for (const set of sets) {
      await set.delete();

      let events = await database
        .get(Event.table)
        .query(Q.where('set_id', set.id))
        .fetch();

      for (const event of events) {
        await event.delete();
      }
    }
    setShowDelete(false);
    recalculateAppointments();
  };

  const initiateCsvExport = async (name: string) => {
    setLoading(true);
    let _exportData = [
      [
        'Staff Name',
        'Client Name',
        'Session Date',
        'Session Start Time',
        'Session End Time',
        'Session Location',
        'Session Status',
      ],
    ];

    for (const appointment of exportItems) {
      const staffName = appointment.staffName;
      const clientName = appointment.clientName;
      const sessionDate = appointment.sessionDate;
      const startTime = appointment.startDate;
      const endTime = appointment.endDate;
      const location = appointment.location;
      const status = appointment.status;

      _exportData.push([
        staffName,
        clientName,
        sessionDate,
        startTime,
        endTime,
        location,
        status,
      ]);
    }

    await exportCsv(name.toLowerCase() + '_session_audit', _exportData);

    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  return (
    <BaseIndexScreen header={false} title={translations('session_audit')}>
      <DeleteModal
        model={'Session'}
        customText={
          'You are about to delete a session that will delete the session note and collected data. Are you sure you want to continue?'
        }
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <View style={[styles.height, styles.flex, styles.row]}>
        <RetractingDrawer
          type={'overlay'}
          position={'left'}
          opening={() => setOpen(true)}
          closing={() => setOpen(false)}>
          {open ? (
            <FormProvider {...methods}>
              <View style={[styles.flex, styles.column]}>
                <Separator height={20} width={20} />
                <HookFormDateInput
                  name={'startDate'}
                  label={'Start Date'}
                  placeholder={'Start Date'}
                  showHelper={false}
                />
                <Separator height={20} width={20} />
                <HookFormDateInput
                  name={'endDate'}
                  label={'End Date'}
                  placeholder={'End Date'}
                  showHelper={false}
                />
                <Separator height={20} width={20} />
                <Client
                  selectAll={true}
                  showHelper={false}
                  includeArchived={true}
                />
                <Separator height={20} width={20} />
                <Staff
                  selectAll={true}
                  multiple={true}
                  showHelper={false}
                  includeArchived={true}
                />
                <CollapsibleFilterItem title={translations('place_of_service')}>
                  <LocationCheckbox allowSelectAll={true} search={true} />
                </CollapsibleFilterItem>
                <CollapsibleFilterItem title={translations('status')}>
                  <StatusCheckbox allowSelectAll={false} search={false} />
                </CollapsibleFilterItem>
              </View>
            </FormProvider>
          ) : null}
        </RetractingDrawer>
        <View style={[styles.flex, styles.backgroundColorWhite]}>
          <View
            style={[
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
              styles.paddingHorizontal,
              styles.paddingLVertical,
            ]}>
            <FormSectionHeader title={translations('session_history')} />
            <IconButton
              loading={loading}
              disabled={loading || success}
              icon={success ? 'check' : 'download'}
              type={'outlined'}
              color={success ? Colors.SUCCESS : Colors.RAVEN_BLACK}
              onPress={() =>
                initiateCsvExport(
                  `${profile.firstName}_${profile.lastName}`,
                  exportItems,
                )
              }
            />
          </View>
          <FlatList
            data={items}
            scrollEnabled={true}
            keyExtractor={item => item?.uniqueId}
            renderItem={renderItem}
            ItemSeparatorComponent={ListItemSeparator}
          />
        </View>
      </View>
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of({}),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
)(SessionHistoryTab);
