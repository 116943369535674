import React, {useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import withObservables from '@nozbe/with-observables';
import {Colors, Typography} from 'src/styles';
import Collapsible from 'react-native-collapsible';
import {compose} from 'recompose';
import {SessionProgramPrompt} from 'src/modules/session/components';
import {useStyle} from 'src/providers/style';

const TaskAnalysisTarget = ({
  currentTarget,
  total,
  index,
  target,
  prompts,
  value,
  onValue,
}: any) => {
  const styles = useStyle();

  const [open, setOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<number | null>(null);
  const [answered, setIsDisabled] = useState(!(currentTarget === index));

  const isDisabled = currentTarget < index;

  const error = () => {
    if (prompts?.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
      setIsDisabled(true);
      onValue(index, target, 'error', null);
    }
  };

  const success = () => {
    setSelectedPrompt(null);
    setOpen(false);
    setIsDisabled(true);
    onValue(index, target, 'success', selectedPrompt);
  };

  const promptSelected = (id: number) => {
    setSelectedPrompt(id);
    setOpen(false);
    setIsDisabled(true);
    onValue(index, target, 'error', id);
  };

  return (
    <View style={[styles.column, styles.width300, styles.paddingLHorizontal]}>
      <View
        style={[
          styles.border,
          styles.borderColorPrimary300,
          styles.borderRadiusSM,
          styles.padding,
          styles.marginLVertical,
        ]}>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignCenter,
            styles.marginBottom,
          ]}>
          <Text
            style={[
              Typography.P2_BOLD,
              isDisabled && answered ? styles.textColorDisabled : {},
            ]}
            numberOfLines={1}
            ellipsizeMode="tail">
            {target?.name || ''}
          </Text>
          <Text
            style={[
              Typography.P3,
              styles.paddingSMVertical,
              styles.paddingLHorizontal,
              styles.borderRadius,
              styles.backgroundColorPrimary50,
              styles.flexShrink0,
              isDisabled && answered ? styles.textColorDisabled : {},
            ]}>
            {`${
              isDisabled && answered ? index + 1 + ' of ' + total : index + 1
            }`}
          </Text>
        </View>

        <View
          style={[styles.row, styles.justifySpaceBetween, styles.marginTop]}
          pointerEvents={
            (isDisabled && answered) || index < currentTarget ? 'none' : 'auto'
          }>
          <TouchableOpacity
            style={[
              styles.width40,
              styles.height40,
              styles.borderRadius20,
              styles.alignCenter,
              styles.justifyCenter,
              styles.backgroundColorBlack,
              styles.border,
              isDisabled && answered
                ? styles.borderColorPrimary300
                : styles.borderColorError,
              value === 'error'
                ? styles.backgroundColorError
                : styles.backgroundColorWhite,
            ]}
            onPress={error}>
            <MaterialCommunityIcon
              size={20}
              name="close"
              color={
                isDisabled && answered
                  ? Colors.TEXT_DISABLED
                  : value === 'error'
                  ? Colors.RAVEN_WHITE
                  : Colors.ERROR
              }
            />
          </TouchableOpacity>

          <View
            style={[
              styles.flex,
              styles.row,
              styles.justifySpaceAround,
              styles.alignCenter,
              styles.paddingLHorizontal,
            ]}>
            <Text
              style={[
                Typography.P2_MEDIUM,
                isDisabled && answered ? styles.textColorDisabled : {},
              ]}>
              Independent
            </Text>
          </View>

          <TouchableOpacity
            style={[
              styles.width40,
              styles.height40,
              styles.borderRadius20,
              styles.alignCenter,
              styles.justifyCenter,
              styles.backgroundColorBlack,
              styles.border,
              isDisabled && answered
                ? styles.borderColorPrimary300
                : styles.borderColorSuccessDark,
              value === 'success'
                ? styles.backgroundColorSuccessDark
                : styles.backgroundColorWhite,
            ]}
            onPress={success}>
            <MaterialCommunityIcon
              size={20}
              name="check"
              color={
                isDisabled && answered
                  ? Colors.TEXT_DISABLED
                  : value === 'success'
                  ? Colors.RAVEN_WHITE
                  : Colors.SUCCESS_DARK
              }
            />
          </TouchableOpacity>
        </View>

        <Collapsible collapsed={!open}>
          <View style={[styles.paddingTop]}>
            <Text
              style={[
                Typography.CAPTION,
                styles.textColorSecondary,
                styles.marginLBottom,
              ]}>
              Prompt
            </Text>
            <View style={[styles.row, styles.flexWrap]}>
              {prompts?.map((prompt: any) => (
                <SessionProgramPrompt
                  key={`session-program-prompt-key-${prompt?.id ?? prompt}`}
                  selected={selectedPrompt === prompt}
                  prompt={prompt}
                  onClk={() => {
                    promptSelected(prompt.id);
                  }}
                />
              ))}
            </View>
          </View>
        </Collapsible>
      </View>
    </View>
  );
};

export default compose(
  withObservables([], ({enabledTarget}: any) => ({
    target: enabledTarget.target,
  })),
)(TaskAnalysisTarget);
