import React from 'react';
import JaspersoftDashboard from '../../components/jaspersoft-reports/index.web';
import {resourceUrls} from 'src/common-utils/resourceUrls';

const JaspersoftClientSummary = ({client, startDate, endDate}: any) => {
  return (
    <JaspersoftDashboard
      resource={resourceUrls.client_summary}
      params={{
        start_datetime_1_1: [startDate],
        start_datetime_1_2: [endDate],
        client_id_1: [client],
      }}
      container={'summary'}
    />
  );
};

export default React.memo(JaspersoftClientSummary);
