import React from 'react';
import {Text, View} from 'react-native';
import {FormSectionHeader} from 'src/design-system';
import {useStyle} from 'src/providers/style';
import {TIN, NPI, License} from 'src/hook-form-inputs';
import {Typography} from 'src/styles';
import CredentialPayerItem, {
  CredentialPayerItemDisplay,
} from 'src/modules/users/components/credential-payer-item';
import {useFormContext, useWatch} from 'react-hook-form';
import {
  Display as TaxonomyDisplay,
  Input as TaxonomyInput,
} from 'src/hook-form-inputs/taxonomy';

const RosterCredentialsDisplay = ({values, billing}: any) => {
  const styles = useStyle();
  return (
    <View style={[styles.column]}>
      <FormSectionHeader title={'Roster Credentials'} />
      <NPI.Display value={values?.npi} />
      <TIN.Display value={values?.tin} />
      <License.Display value={values?.licenseNumber} />
      <TaxonomyDisplay value={values?.taxonomy} />
      {billing ? (
        <View style={[styles.marginLVertical]}>
          <Text style={[Typography.P3_BOLD]}>Assigned Payers</Text>
          <View style={[styles.marginLVertical]}>
            <View
              style={[
                styles.row,
                styles.borderBottom,
                styles.alignCenter,
                styles.justifyCenter,
                styles.flex,
              ]}>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width]}>
                  <Text style={[Typography.P3]}>Payer</Text>
                </View>
              </View>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width]}>
                  <Text style={[Typography.P3]}>ID #</Text>
                </View>
              </View>
            </View>
            {values?.payers?.map((payer: any, index: number) => {
              return (
                <CredentialPayerItemDisplay
                  key={`credential-payer-${payer.id}-${index}`}
                  value={payer}
                />
              );
            })}
          </View>
        </View>
      ) : null}
    </View>
  );
};

const RosterCredentialsForm = ({...extras}: any) => {
  const styles = useStyle();
  const {control} = useFormContext();

  const payers = useWatch({
    control,
    name: 'payers',
  });

  return (
    <>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <FormSectionHeader title="Roster Credentials" />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <NPI.Input />
        </View>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <TIN.Input />
        </View>
      </View>
      <View style={[styles.paddingHorizontal]}>
        <License.Input />
      </View>
      <View style={[styles.flex, styles.paddingHorizontal]}>
        <TaxonomyInput />
      </View>
      {payers && extras?.billing ? (
        <View style={[styles.marginLVertical, styles.paddingHorizontal]}>
          <Text style={[Typography.P3_BOLD]}>Assigned Payers</Text>
          <View style={[styles.marginLVertical]}>
            <View
              style={[
                styles.row,
                styles.borderBottom,
                styles.alignCenter,
                styles.justifyCenter,
                styles.flex,
              ]}>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width]}>
                  <Text style={[Typography.P3]}>Payer</Text>
                </View>
              </View>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width]}>
                  <Text style={[Typography.P3]}>ID #</Text>
                </View>
              </View>
            </View>
            {payers.map((payer: any, index: number) => {
              return (
                <CredentialPayerItem
                  key={`credential-payer-${payer.id}-${index}`}
                  payerId={payer.id}
                  index={index}
                />
              );
            })}
          </View>
        </View>
      ) : null}
    </>
  );
};

export const Display = RosterCredentialsDisplay;

export default RosterCredentialsForm;
