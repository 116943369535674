import React from 'react';
import {useStyle} from 'src/providers/style';
import {View} from 'react-native';
import {FormSectionHeader} from 'src/design-system';
import {Permission, Position, Credential, Modifier} from 'src/hook-form-inputs';

export const fields = ['role'];

const PermissionsDisplay = ({values}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.column]}>
      <FormSectionHeader title={'Permissions'} />
      <Permission.Display value={values?.roleId} />
      <Position.Display value={values?.position} />
      <Credential.Display value={values?.credentials} />
      <Modifier.Display value={values?.modifier} />
    </View>
  );
};

const PermissionsForm = () => {
  const styles = useStyle();

  return (
    <>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <FormSectionHeader title="Status & Permissions" />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <Permission.Input />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <Position.Input />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <Credential.Input />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <Modifier.Input />
        </View>
      </View>
    </>
  );
};

export const Display = PermissionsDisplay;

export default PermissionsForm;
