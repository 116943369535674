import React, {useContext} from 'react';
import {
  rectSortingStrategy,
  useSortable,
  SortableContext,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import SortableItem from 'src/modules/form-builder/components/canvas/sortable-item';
import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {IconButton} from 'react-native-paper';
import {FormContext} from 'src/modules/form-builder/context/form-context';
import {useFormContext, useWatch} from 'react-hook-form';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Separator} from 'src/common-components/atoms';

const DroppableRow = ({
  id,
  items,
  header,
  rowDrag,
}: {
  id: string;
  items: any[];
  header: string;
  rowDrag: boolean;
}) => {
  const styles = useStyle();
  const {attributes, listeners, setNodeRef, transition, transform, index} =
    useSortable({
      id,
      data: {
        id,
      },
    });

  const {removeField, handleSetEditField, editField} = useContext(FormContext);
  const {control} = useFormContext();
  const watchedFormValues = useWatch({control});
  const borderColor =
    editField?.isRow && editField?.rowId === id ? Colors.SECONDARY_800 : '#ddd';

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginVertical: 50,
    padding: 4,
    display: 'flex',
    border: `1px solid ${borderColor}`,
    flexDirection: 'column' as 'column',
    backgroundColor: Colors.PRIMARY_50,
  };

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={rectSortingStrategy}
      disabled={rowDrag}>
      <ul style={style} ref={setNodeRef} {...attributes} {...listeners}>
        <View
          style={[
            styles.row,
            styles.justifySpaceBetween,
            styles.marginVertical,
            styles.paddingHorizontal,
            styles.alignCenter,
          ]}>
          <View style={[styles.row, styles.alignCenter]}>
            <Icon name={'drag-horizontal-variant'} size={22} />
            <Separator width={10} />
            <Text
              style={[
                Typography.P4,
                styles.textColorSecondary,
                styles.paddingXSTop,
              ]}>
              {header}
            </Text>
          </View>
          <View style={[styles.row]}>
            <IconButton
              size={20}
              icon="pencil"
              color={Colors.PRIMARY_900}
              onPress={() => {
                handleSetEditField(watchedFormValues[id], id, 0, true);
              }}
            />
            {index !== 0 ? (
              <IconButton
                size={20}
                icon="close"
                color={Colors.PRIMARY_900}
                onPress={() => {
                  removeField(id);
                }}
              />
            ) : (
              <></>
            )}
          </View>
        </View>
        {items.length ? (
          <View style={[styles.row]}>
            {items?.map((item, idx) => {
              return (
                <SortableItem
                  containerId={id}
                  key={item.id}
                  id={item.id}
                  item={item}
                  rowId={id}
                  index={idx}
                />
              );
            })}
          </View>
        ) : (
          <View
            style={[
              styles.justifyCenter,
              styles.alignCenter,
              styles.backgroundColorPrimary200,
              styles.height200,
              styles.marginL,
              styles.borderColorGray400,
              styles.border,
            ]}>
            <Text style={[Typography.P2, styles.textColorSecondary]}>
              Drag and drop additional components to build this section.
            </Text>
            <Text style={[Typography.P2, styles.textColorSecondary]}>
              Sections can have up to 3 columns.
            </Text>
          </View>
        )}
      </ul>
    </SortableContext>
  );
};

export default DroppableRow;
