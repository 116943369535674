import React from 'react';
import {Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

const DrawerItem = ({
  focused,
  label,
  icon,
  iconColor,
  right = () => {},
}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.row, styles.alignCenter]}>
      <Icon
        size={24}
        name={icon}
        color={iconColor ? iconColor : focused ? Colors.BLUE_RIBBON : '#ffffff'}
      />
      <View style={[styles.flex, styles.row, styles.alignCenter]}>
        <Text
          style={[
            Typography.P1,
            styles.paddingMHorizontal,
            focused ? styles.textColorPrimary : styles.textColorWhite,
          ]}>
          {label}
        </Text>
        {right({focused})}
      </View>
    </View>
  );
};

export default DrawerItem;
