import * as mudder from 'mudder';

export const updateRank = async (database: any, rankableItems: any) => {
  const batch: any[] = [];
  const newTargetsRanks = mudder.alphabet.mudder(rankableItems.length);
  await database.write(async () => {
    for (const [index, value] of rankableItems.entries()) {
      batch.push(
        value.prepareUpdate((rankableItem: any) => {
          rankableItem.rank = newTargetsRanks[index];
        }),
      );
    }
    await database.batch(batch);
  });
};

export const compareRank = (a: any, b: any) => {
  if (a.rank && b.rank) {
    return a.rank.localeCompare(b.rank);
  } else {
    if (a.rank) {
      return false;
    }
    if (b.rank) {
      return true;
    }
    return true;
  }
};

const nextLetter = (char: string) => {
  return char === 'Z' ? 'A' : String.fromCharCode(char.charCodeAt(0) + 1);
};

export const incrementChar = (l: string) => {
  let newCharArray = [];
  const lastChar = l[l.length - 1];
  const remString = l.slice(0, l.length - 1);
  const newChar = lastChar === undefined ? 'A' : nextLetter(lastChar);
  newCharArray.unshift(newChar);
  if (lastChar === 'Z') {
    return incrementChar(remString);
  }
  return remString + [...newCharArray].join('');
};
