import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Patient} from 'src/models';
import {getAge} from 'src/common-utils/age';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import ListItemSeparator from 'src/common-components/separator';

const ClientListItem = ({patient, onClose, canDelete = true}: any) => {
  const styles = useStyle();

  let avatar = '';
  let firstLineDisplay = '';
  let secondLineDisplay = '';
  const firstName = patient?.firstName || '';
  const lastName = patient?.lastName || '';
  firstLineDisplay = firstName + ' ' + lastName;
  const firstInitial = firstName.length > 0 ? firstName[0].toUpperCase() : 'N';
  const lastInitial = lastName.length > 0 ? lastName[0].toUpperCase() : 'N';
  avatar = firstInitial + lastInitial;

  const age = getAge(patient?.birthDate) || '';
  const gender = patient?.gender || '';
  secondLineDisplay = age && gender ? `Age ${age || 10}, ${gender}` : '';

  return (
    <View style={[styles.column, styles.paddingLVertical]}>
      <View
        style={[
          styles.row,
          styles.width,
          styles.justifySpaceBetween,
          styles.alignCenter,
          styles.marginLBottom,
        ]}>
        <View style={[styles.row, styles.alignCenter]}>
          <RHAvatar
            label={avatar}
            color={Colors.RAVEN_BLACK}
            textColor={Colors.RAVEN_WHITE}
            size={32}
          />
          <View style={[styles.marginLLeft]}>
            <Text style={[Typography.P2_MEDIUM, styles.textColorPrimary]}>
              {firstLineDisplay}
            </Text>
            <Text style={[Typography.P3, styles.textColorSecondary]}>
              {secondLineDisplay}
            </Text>
          </View>
        </View>
        {canDelete ? (
          <TouchableOpacity style={[]} onPress={onClose}>
            <Icon name="close" size={20} />
          </TouchableOpacity>
        ) : (
          <></>
        )}
      </View>
      <ListItemSeparator />
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables(['id'], ({id, database}: any) => ({
    patient: database.get(Patient.table).findAndObserve(id),
  })),
)(ClientListItem);
