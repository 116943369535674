import React from 'react';
import {View, Text} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';
import {
  InputProps,
  OutputProps,
  PreviewProps,
} from 'src/modules/form-builder/utils/types';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import SelectInput from 'src/hook-form/select-input';
import {DataItem} from 'src/design-system';
import {uuid} from 'src/common-utils/uuid';

export const Details = ({required, label, options}: any) => {
  return (
    <SelectInput
      multiple={true}
      items={options}
      label={required ? label + '*' : label}
      value={[]}
      onChange={() => {}}
      error={null}
    />
  );
};

export const Definition = () => ({
  name: 'Dropdown',
  label: '',
  placeholder: '',
  componentType: 'DROPDOWN',
  icon: 'chevron-down',
  options: [
    {
      value: uuid(),
      label: 'Option 1',
    },
  ],
  helper: '',
  required: false,
});

export const Input = ({item, name = ''}: InputProps) => {
  return (
    <HookFormSelectInput
      multiple={true}
      defaultValue={''}
      name={name}
      items={item?.options}
      label={item?.label}
      required={item.required}
      rules={
        item.required
          ? {
              required: 'This field is required.',
            }
          : {}
      }
    />
  );
};

export const Output = ({item, value}: OutputProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{item.label}</Text>
      <Text style={[Typography.P3]}>
        {value
          ? value
              ?.map(
                dropdownItem =>
                  item.options.find(option => option.value === dropdownItem)
                    ?.label,
              )
              .join(', ')
          : null}
      </Text>
    </DataItem>
  );
};

export const Preview = ({item}: PreviewProps) => {
  const styles = useStyle();

  return (
    <View style={[styles.marginXLTop]}>
      <SelectInput
        multiple={true}
        items={item.options}
        label={item.label}
        value={[]}
        onChange={() => {}}
        error={null}
      />
    </View>
  );
};
