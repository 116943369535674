import React from 'react';
import {StyleSheet, View, Platform, TouchableHighlight} from 'react-native';
import {HelperText, TextInput} from 'react-native-paper';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import DocumentPicker from 'react-native-document-picker';

import {Colors} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {checkAndroidPermissions} from 'src/navigation/components';

interface Props {
  formRef: any;
  onChange: (value: any) => void;
  value: any;
  error: any;
  style?: any;
}

const FileInput = ({formRef, onChange, value, error}: Props) => {
  const selectFileWeb = (event: any) => {
    const file = event.target.files[0];
    onChange(file);
  };

  const selectFileMobile = async () => {
    const canWrite = await checkAndroidPermissions();
    try {
      if (canWrite) {
        const file = await DocumentPicker.pickSingle({
          type: [DocumentPicker.types.images, DocumentPicker.types.pdf],
        });
        onChange(file);
      }
    } catch (e) {
      console.log('select exception:', e);
    }
  };

  return (
    <>
      <View style={[styles.row, styles.alignItemsCenter]}>
        {value ? (
          <TouchableHighlight
            onPress={() => onChange(null)}
            style={styles.mr_10}>
            <MaterialIcons name="delete" size={24} color={Colors.ERROR} />
          </TouchableHighlight>
        ) : (
          <MaterialIcons name="attach-file" size={24} style={styles.mr_10} />
        )}
        {value ? (
          <TextInput
            value={value?.name}
            mode="outlined"
            style={styles.fileNameInput}
            theme={{colors: {primary: Colors.BLUE_RIBBON}}}
            disabled
          />
        ) : Platform.OS === 'web' ? (
          <input
            type="file"
            onChange={selectFileWeb}
            accept="*/*"
            ref={formRef}
          />
        ) : (
          <RHButton secondary mode="contained" onPress={selectFileMobile}>
            Choose File
          </RHButton>
        )}
      </View>

      <HelperText type="error" visible={true} style={styles.helper}>
        {error && 'ⓧ ' + error.message}
      </HelperText>
    </>
  );
};

const styles = StyleSheet.create({
  fileNameInput: {
    backgroundColor: 'white',
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  helper: {},
  mr_10: {
    marginRight: 10,
  },
});

export default FileInput;
