import React, {useEffect, useState} from 'react';
import {Pressable, Text, TouchableOpacity, View} from 'react-native';
import {useSelector} from 'react-redux';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {useStopwatch} from 'react-timer-hook';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import {of} from 'rxjs';
import {Colors, Typography} from 'src/styles';
import {Event, Set} from 'src/models';
import {intervalTypes} from 'src/hook-form-inputs/interval-type';
import {IntervalModal, IntervalTable} from 'src/modules/session/components';
import ProgramMenu from 'src/design-system/program-menu';
import ProgramInstructionModal from '../program-instruction-modal';
import {Modal} from 'src/design-system';
import ProgramProgress from 'src/modules/programs/components/program-progress-legacy';
import {useStyle} from 'src/providers/style';
import IntervalEdit from 'src/modules/session/components/interval/interval-edit';
import _ from 'lodash';
import BehaviorProgramTooltip from '../behavior-program-tooltip';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';
import {useTranslations} from 'src/providers/translation';
import {default as playSound} from 'src/common-utils/playSound';

const Intervals = ({
  program,
  session,
  sets,
  currentSet,
  events,
  totalEvents,
  programPrompts,
}: any) => {
  const intervalOccurs = Number(program?.intervalOccurs || 120);
  const h = Math.floor(intervalOccurs / 3600);
  const m = Math.floor((intervalOccurs % 3600) / 60);
  const s = Math.floor((intervalOccurs % 3600) % 60);
  const translations = useTranslations();
  const database = useDatabase();
  const prompts = _.groupBy(programPrompts, 'promptType') || [];
  const styles = useStyle();

  const [expandPromptView, setExpandPromptView] = useState(false);
  const [shouldAutomateInterval, setShouldAutomateInterval] = useState(
    !!program.intervalAutomation,
  );
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);

  const [editEvent, setEditEvents] = useState<any>('');
  const [instanceNumber, setInstance] = useState('');
  const [module, setEditModule] = useState('');

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const {seconds, minutes, hours, isRunning, start, pause, reset} =
    useStopwatch({
      autoStart: false,
    });

  useEffect(() => {
    if (sets && sets?.length > 0) {
      let discrepancy = 0;
      const stopwatchOffset = new Date();
      // TODO: In the case when its greater than the interval seconds
      if (
        currentSet.startTimestamp !== null &&
        currentSet.endTimestamp !== null
      ) {
        // TODO: get the difference in seconds if its greater than program interval
        discrepancy += currentSet.endTimestamp - currentSet.startTimestamp;
        stopwatchOffset.setSeconds(
          stopwatchOffset.getSeconds() + discrepancy / 1000,
        );
        reset(stopwatchOffset);
        playSound();
        pause();
      } else if (
        currentSet.startTimestamp !== null &&
        currentSet.endTimestamp === null
      ) {
        discrepancy += new Date() - currentSet.startTimestamp;
        stopwatchOffset.setSeconds(
          stopwatchOffset.getSeconds() + discrepancy / 1000,
        );
        reset(stopwatchOffset);
        start();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sets, currentSet?.startTimestamp, currentSet?.endTimestamp]);

  useEffect(() => {
    const secs = hours * 3600 + minutes * 60 + seconds;
    if (
      currentSet?.startTimestamp !== null &&
      secs > intervalOccurs - 1 &&
      currentSet.endTimestamp === null
    ) {
      // TODO: Roll through sets that may not exist
      currentSet.endSet();
      database?.write(async () => {
        await database?.get(Event.table).create(entity => {
          entity.partition = selectedGroup;
          entity.set.id = currentSet.id;
          entity.value = '';
          entity.intensity = null;
          entity.prompts = null;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
      reset();
      if (
        shouldAutomateInterval &&
        (program.intervalRecurrences === 0 ||
          sets.length < program.intervalRecurrences)
      ) {
        database?.write(async () => {
          return await database?.get(Set.table).create(entity => {
            entity.partition = selectedGroup;
            entity.session.id = session.id;
            entity.program.id = program.id;
            entity.startTimestamp = new Date();
            entity.endTimestamp = null;
            entity.intensity = null;
            entity.prompts = [];
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        });
        start();
      } else {
        pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours, minutes, seconds]);

  // const undo = async () => {};

  const onValue = async () => {
    if (currentSet?.endTimestamp === null) {
      currentSet.endSet();
      database?.write(async () => {
        await database?.get(Event.table).create(entity => {
          entity.partition = selectedGroup;
          entity.set.id = currentSet.id;
          entity.value = '';
          entity.prompts = null;
          entity.intensity = null;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
      reset();
      setShouldAutomateInterval(false);
    } else if (currentSet?.startTimestamp === null) {
      start();
    } else {
      start();
      await database?.write(async () => {
        return await database?.get(Set.table).create(entity => {
          entity.partition = selectedGroup;
          entity.session.id = session.id;
          entity.program.id = program.id;
          entity.startTimestamp = new Date();
          entity.endTimestamp = null;
          entity.intensity = null;
          entity.prompts = null;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
  };

  const leadingZero = (value: number) => {
    return /^\d$/.test(value) ? '0' + value : value;
  };

  const showPrompts = () => {
    if (sets.length > 0) {
      setExpandPromptView(!expandPromptView);
    }
  };

  const viewInstructions = () => {
    setInstructionsModal(!instructionsModal);
  };
  const viewHistory = () => {
    setHistoryModal(!historyModal);
  };
  const viewData = () => {
    setDataModal(!dataModal);
  };

  return (
    <View style={[styles.flex]}>
      {events?.length ? (
        <IntervalModal program={program} events={events} />
      ) : (
        <></>
      )}
      <View
        style={[
          styles.borderRadius,
          styles.border,
          styles.borderColorGray,
          styles.marginLBottom,
          styles.backgroundColorGray,
          styles.width300,
          styles.paddingVertical,
          styles.paddingLeft,
        ]}>
        <Pressable onPress={showPrompts}>
          <View style={[styles.row, styles.justifySpaceBetween]}>
            <View style={[styles.flex]}>
              <BehaviorProgramTooltip name={program?.name} />
              {/*{sets.length > 0 ? (*/}
              {/*  <TouchableOpacity onPress={undo}>*/}
              {/*    <MaterialCommunityIcon*/}
              {/*      name={'undo'}*/}
              {/*      size={22}*/}
              {/*      color={Colors.RAVEN_WHITE}*/}
              {/*    />*/}
              {/*  </TouchableOpacity>*/}
              {/*) : (*/}
              {/*  <></>*/}
              {/*)}*/}
              <Text style={[Typography.CAPTION, styles.textColorSecondary]}>
                {
                  intervalTypes.find(item => item.value === program?.interval)
                    ?.label
                }
              </Text>
            </View>
            <ProgramMenu
              viewInstructions={viewInstructions}
              viewHistory={viewHistory}
              viewData={viewData}
              color={Colors.RAVEN_WHITE}
            />
          </View>
          <View style={[styles.column, styles.marginTop, styles.zIndex]}>
            <View
              style={[
                styles.row,
                styles.justifySpaceBetween,
                styles.alignCenter,
              ]}>
              <View style={[styles.row, styles.alignCenter]}>
                <TouchableOpacity style={styles.alignCenter} onPress={onValue}>
                  <View
                    style={[
                      styles.width40,
                      styles.height40,
                      styles.borderRadius20,
                      styles.alignCenter,
                      styles.justifyCenter,
                      styles.backgroundColorBlack,
                      styles.border,
                      !(sets?.length === 0)
                        ? styles.borderColorBlue
                        : styles.borderColorWhite,
                    ]}>
                    {sets?.length === 0 ? (
                      <Ionicons
                        name={'play'}
                        size={22}
                        color={Colors.RAVEN_WHITE}
                      />
                    ) : (
                      <Ionicons
                        name={isRunning ? 'stop' : 'play'}
                        size={22}
                        color={Colors.RAVEN_WHITE}
                      />
                    )}
                  </View>
                </TouchableOpacity>
                {!(sets?.length === 0) ? (
                  <Text
                    style={[
                      Typography.P2_MEDIUM,
                      styles.paddingHorizontal,
                      styles.textColorWhite,
                    ]}>
                    {leadingZero(hours)}:{leadingZero(minutes)}:
                    {leadingZero(seconds)}
                  </Text>
                ) : (
                  <></>
                )}
              </View>
              <View style={[styles.alignCenter, styles.marginRight]}>
                <Text style={[Typography.CAPTION, styles.textColorDisabled]}>
                  {program.intervalRecurrences
                    ? `${sets?.length} of ${program.intervalRecurrences}`
                    : `${sets?.length}`}
                </Text>
                <Text style={[Typography.CAPTION, styles.textColorDisabled]}>
                  {`${h > 0 ? h + 'h ' : ''}${m > 0 ? m + 'm ' : ''}${
                    s > 0 ? s + 's' : ''
                  }`}
                </Text>
              </View>
            </View>
          </View>
        </Pressable>
      </View>
      {instructionsModal ? (
        <ProgramInstructionModal
          show={[instructionsModal, setInstructionsModal]}
          program={program}
        />
      ) : null}
      {historyModal ? (
        <Modal
          show={[historyModal, setHistoryModal]}
          title={'Progress'}
          footer={
            <InformationTooltip
              value={translations('program_progress_month')}
            />
          }>
          <ProgramProgress program={program} />
        </Modal>
      ) : null}
      {dataModal ? (
        <Modal show={[dataModal, setDataModal]} title={`${program.name} Data`}>
          <IntervalTable
            setEditEvents={obj => {
              setEditEvents(obj);
            }}
            setInstance={idx => {
              setInstance(idx);
            }}
            setEditModule={mod => {
              setEditModule(mod);
            }}
            type={'edit-modal'}
            program={program}
            sets={sets.sort((a: Set, b: Set) => a?.createdAt - b?.createdAt)}
            events={totalEvents}
          />
          {module ? (
            <IntervalEdit
              setEditModule={show => {
                setEditModule(show);
              }}
              previousValue={editEvent?.value}
              instance={instanceNumber}
              event={editEvent}
              module={module}
              setEditEvents={setEditEvents}
              prompts={prompts}
            />
          ) : (
            <></>
          )}
        </Modal>
      ) : null}
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({session, program, database}: any) => ({
    sets: database
      .get('sets')
      .query(
        Q.and(
          Q.where('program_id', program.id),
          Q.where('session_id', session.id),
          Q.where('deleted_at', null),
        ),
        Q.sortBy('created_at', Q.desc),
      ),
    programPrompts: program?.prompts || of([]),
  })),
  withObservables(
    ['sets'],
    ({program, sets, programPrompts, database}: any) => {
      if (sets.length !== 0) {
        let queries = [];
        if (program?.intensity) {
          queries.push(Q.where('intensity', Q.eq(null)));
        }
        if (programPrompts.length > 0) {
          queries.push(Q.where('prompts', Q.eq(null)));
        }
        return {
          events: database
            .get(Event.table)
            .query(
              Q.or(Q.where('value', Q.eq('')), ...queries),
              Q.where('set_id', Q.oneOf(sets.map((set: {id: any}) => set.id))),
              Q.where('deleted_at', null),
              Q.sortBy('created_at', Q.asc),
            ),
          currentSet: sets?.[0],
          totalEvents: database
            .get(Event.table)
            .query(
              Q.where('set_id', Q.oneOf(sets.map((set: {id: any}) => set.id))),
              Q.where('deleted_at', null),
              Q.sortBy('created_at', Q.asc),
            ),
        };
      }
      return {
        events: of([]),
        currentSet: of({}),
        totalEvents: of([]),
      };
    },
  ),
)(Intervals);
