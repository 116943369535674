import React from 'react';
import {View, Text} from 'react-native';
import {Controller, useWatch} from 'react-hook-form';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import AddTargets from 'src/modules/programs/components/add-targets';
import {AvoidSoftInputView} from 'react-native-avoid-softinput';
import {NestableScrollContainer} from 'react-native-draggable-flatlist';
import {
  Behavior,
  SkillCollectionMethods,
  BehaviorCollectionMethods,
  IntervalAutomation,
  IntervalType,
  IntensityTracker,
  IntervalOccurs,
  IntervalRecurrences,
  NumberOfTrials,
  Prompts,
} from 'src/hook-form-inputs';
import {useStyle} from 'src/providers/style';
import SwitchInput from 'src/hook-form/switch-input';
import {useTranslations} from 'src/providers/translation';
import {DataItem} from 'src/design-system';
import FilterChip from 'src/design-system/filter-chip';
import _ from 'lodash';
import RadioInput from 'src/hook-form/radio-input';

interface Props {
  control: any;
  errors: any;
  edit: boolean;
  values: any;
  programType: string;
  setError: (name: string, error: {type: string; message: string}) => void;
  clearErrors: (name?: string | string[]) => void;
  targets: any[];
  prompts: any[];
  behaviors: any[];
}

const ProgramCollection = ({
  control,
  errors,
  edit,
  values,
  programType = 'skill',
  clearErrors,
  targets,
  prompts,
}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  const groupedPrompts = _.groupBy(prompts, 'promptType');
  const method = useWatch({control, name: 'method'});
  const intervalRecurrences = useWatch({control, name: 'intervalRecurrences'});
  return (
    <NestableScrollContainer
      style={[styles.flex, styles.paddingHorizontal]}
      contentContainerStyle={[styles.paddingVertical]}>
      <AvoidSoftInputView
        avoidOffset={100}
        easing="easeIn"
        hideAnimationDelay={100}
        hideAnimationDuration={300}
        showAnimationDelay={100}
        showAnimationDuration={800}>
        <Text style={[Typography.H4]}>{translations('collection')}</Text>

        <RHSeparator height={20} />
        {edit ? (
          <>
            {programType === 'skill' ? (
              <SkillCollectionMethods.Input />
            ) : (
              <BehaviorCollectionMethods.Input />
            )}
          </>
        ) : (
          <>
            {programType === 'skill' ? (
              <SkillCollectionMethods.Display value={values.method} />
            ) : (
              <BehaviorCollectionMethods.Display value={values.method} />
            )}
          </>
        )}

        {programType === 'behavior' && method === 'interval' ? (
          <>
            {edit ? (
              <IntervalType.Input />
            ) : (
              <IntervalType.Display value={values.interval.toString()} />
            )}

            {edit ? (
              <IntervalAutomation.Input />
            ) : (
              <IntervalAutomation.Display value={values.intervalAutomation} />
            )}

            {edit ? (
              <IntervalOccurs.Input />
            ) : (
              <IntervalOccurs.Display value={values.intervalOccurs} />
            )}

            <View style={styles.row}>
              <View style={styles.flex}>
                {edit ? (
                  <Controller
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <SwitchInput
                        label={translations('interval_fixed_occurrences')}
                        description={translations(
                          'interval_fixed_occurrences_description',
                        )}
                        value={!!value}
                        onChange={boolean =>
                          !boolean ? onChange(0) : onChange(1)
                        }
                      />
                    )}
                    name="intervalRecurrences"
                    defaultValue={values?.intervalRecurrences !== 0}
                  />
                ) : (
                  <DataItem>
                    <Text style={[Typography.H6]}>
                      {translations('interval_fixed_occurrences')}
                    </Text>
                    <Text
                      style={[Typography.CAPTION, styles.textColorSecondary]}>
                      {translations('interval_fixed_occurrences_description')}
                    </Text>
                    <Text style={[Typography.P3]}>
                      {values?.intervalRecurrences !== 0
                        ? translations('fixed')
                        : translations('unlimited')}
                    </Text>
                  </DataItem>
                )}
              </View>
            </View>

            {intervalRecurrences !== 0 ? (
              <>
                {edit ? (
                  <IntervalRecurrences.Input />
                ) : (
                  <IntervalRecurrences.Display
                    value={values?.intervalRecurrences}
                  />
                )}
              </>
            ) : null}
          </>
        ) : null}

        {programType === 'behavior' ? (
          <>
            {edit ? (
              <IntensityTracker.Input />
            ) : (
              <IntensityTracker.Display value={values.intensity} />
            )}
          </>
        ) : null}

        <>
          <RHSeparator height={20} />
          {edit ? (
            <>
              <Text style={[Typography.H6]}>
                {translations('data_display')}
              </Text>
              <Text
                style={[
                  Typography.P3,
                  styles.textColorSecondary,
                  styles.paddingLBottom,
                ]}>
                {translations('data_display_description')}
              </Text>
              <Controller
                control={control}
                render={({field: {onChange, value}}) => (
                  <RadioInput
                    items={
                      method === 'rate'
                        ? [
                            {value: 'per_day', label: 'By Day'},
                            {value: 'per_session', label: 'By Session'},
                            {value: 'per_min', label: 'By Hour'},
                            {value: 'per_hour', label: 'By Minute'},
                          ]
                        : [
                            {value: 'per_day', label: 'By Day'},
                            {value: 'per_session', label: 'By Session'},
                          ]
                    }
                    onChange={onChange}
                    value={value}
                  />
                )}
                name="unit"
                defaultValue={'per_session'}
              />
            </>
          ) : (
            <>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('data_display')}
                </Text>
                <Text style={[Typography.P3]}>
                  {values.unit === 'per_day' ? 'By Day' : 'By Session'}
                </Text>
              </DataItem>
            </>
          )}
        </>

        <RHSeparator height={20} />

        {programType === 'skill' ? (
          <>
            {edit ? (
              <Controller
                name="targets"
                control={control}
                render={({field: {onChange, ref}}) => {
                  return (
                    <AddTargets
                      programId={values.id}
                      inputRef={ref}
                      changeTargets={onChange}
                      error={errors.targets}
                      clearErrors={clearErrors}
                    />
                  );
                }}
                defaultValue={targets.map((target: any) => target.id) || []}
              />
            ) : (
              <>
                <Text style={[Typography.P3_BOLD]}>
                  {translations('targets')}
                </Text>
                <Text style={[Typography.P3, styles.textColorSecondary]}>
                  {translations('individual_data_points')}
                </Text>
                <View style={[styles.marginSMTop]}>
                  {targets?.map((target: any) => {
                    return (
                      <Text
                        key={target?.id}
                        style={[
                          Typography.INPUT,
                          styles.textColorPrimary,
                          styles.marginSMHorizontal,
                          styles.marginSMTop,
                        ]}>
                        {target.name}
                      </Text>
                    );
                  })}
                </View>
              </>
            )}
          </>
        ) : null}

        {method === 'trial_by_trial' ? (
          <>
            {edit ? (
              <NumberOfTrials.Input />
            ) : (
              <NumberOfTrials.Display value={values.numberOfTrials} />
            )}
          </>
        ) : null}

        <Text style={[Typography.P3_BOLD]}>
          {programType === 'skill'
            ? translations('prompt_list')
            : translations('abc_data')}
        </Text>
        <Text
          style={[
            Typography.P3,
            styles.textColorSecondary,
            styles.paddingLBottom,
          ]}>
          {translations('additional_response')}
        </Text>

        {edit ? (
          <>
            {programType === 'skill' ? <Prompts.Input /> : <Behavior.Input />}
          </>
        ) : (
          <></>
        )}

        {Object.keys(groupedPrompts).map(promptType => {
          return (
            <View style={[styles.column, styles.marginLBottom]}>
              <Text
                style={[
                  Typography.P3_BOLD,
                  styles.textColorPrimary,
                  styles.marginSMBottom,
                ]}>
                {promptType}
              </Text>
              <View style={[styles.row, styles.flexWrap]}>
                {groupedPrompts[promptType].map((prompt: any) => {
                  return (
                    <View style={[styles.marginSMRight]}>
                      <FilterChip
                        label={prompt?.name}
                        textColor={Colors.SECONDARY_800}
                        backgroundColor={'#DBE0FF'}
                      />
                    </View>
                  );
                })}
              </View>
            </View>
          );
        })}
      </AvoidSoftInputView>
    </NestableScrollContainer>
  );
};

export default compose(
  withObservables(['values'], ({values}: any) => ({
    values,
    // targets: values.enabledTargets,
    prompts: values.prompts,
  })),
)(ProgramCollection);
