import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useTranslations} from 'src/providers/translation';
import {Prompt} from 'src/models';
import _ from 'lodash';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  prompts?: any;
}
export const Import = (
  prompts: any[],
  key: string = 'prompts',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'prompts',
  key,
  type: 'select',
  selectOptions: prompts,
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('behaviors')}</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    prompts: database
      ?.get(Prompt.table)
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.or(
          Q.where('prompt_type', 'Consequences'),
          Q.where('prompt_type', 'Antecedents'),
        ),
        Q.sortBy('rank', Q.asc),
      ),
  })),
)(({name, prompts}: Props) => {
  const translations = useTranslations();
  const groupedPrompts = _.groupBy(prompts, 'promptType');

  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'prompts'}
      label={translations('behaviors')}
      required={false}
      sections={Object.keys(groupedPrompts).map(promptType => {
        const sortedPrompts = groupedPrompts[promptType].sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        return {
          label: promptType,
          data: sortedPrompts.map(prompt => {
            return {
              label: prompt.name,
              value: prompt.id,
            };
          }),
        };
      })}
      multiple={true}
    />
  );
});

export const validation = () => Yup.string().required('Behaviors are required');

export default Input;
