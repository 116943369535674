import {json, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class PayerPlans extends Base {
  static table = 'payer_plans';
  static associations = {
    payers: {type: 'belongs_to', key: 'payer_id'},
  };

  @relation('payers', 'payer_id') payer;

  @text('payer_id') payerId;
  @text('name') name;
  @text('notes') notes;
  @json('fee_schedule', value => value) feeSchedule;
}
