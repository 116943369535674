import React from 'react';
import {RRule} from 'rrule';
import SelectInput from 'src/hook-form-wrapper/select-input';

export const repeatFreqArr = [
  {
    label: 'Day',
    value: RRule.DAILY.toString(),
  },
  {
    label: 'Week',
    value: RRule.WEEKLY.toString(),
  },
  {
    label: 'Month',
    value: RRule.MONTHLY.toString(),
  },
];

const RepeatFrequency = () => {
  return (
    <SelectInput
      name={'repeatFreq'}
      label={'Meeting Recurrence'}
      required={true}
      items={repeatFreqArr}
    />
  );
};

export const Input = RepeatFrequency;

export default RepeatFrequency;
