import React from 'react';
import {DataItem, SelectInput, TextInput} from 'src/design-system';
import {UsStates} from 'src/common-utils/usStates';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {getIn, useFormikContext} from 'formik';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const Import = (
  key: string = 'state',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'State/Province/Region',
  key,
});

const resolveDisplayValue = (value: string) => {
  return UsStates().find(item => item.value === value)?.label ?? value;
};

const StateDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>State/Province/Region</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const StateInput = ({valueKey}: Props) => {
  const {values} = useFormikContext();

  const fieldValue = getIn(
    values,
    (valueKey ? valueKey + '.' : '') + 'country',
  );

  return fieldValue === 'US' ? (
    <SelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'state'}
      name={'State'}
      required={false}
      items={UsStates()}
    />
  ) : (
    <TextInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'state'}
      name={'State/Province/Region'}
      required={false}
    />
  );
};

export const validation = () => Yup.string().required('State is required');

export const Display = StateDisplay;
export const Input = StateInput;
export const Value = resolveDisplayValue;

export default StateInput;
