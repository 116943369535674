import React, {useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {Menu as PaperMenu, Text} from 'react-native-paper';
import {useNavigation} from '@react-navigation/core';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import moment from 'moment';
import _ from 'lodash';
import {IconButton} from 'src/design-system';
import {Colors, Typography} from 'src/styles';
import {APPOINTMENTCARDTYPE, SessionNotesIcon} from '../appointment-card';
import {boxShadow} from 'src/styles/mixins';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {mergeMap, of} from 'rxjs';
import StaffParticipant from '../staff-participant';
import AppointmentCardButton from 'src/modules/scheduling/components/appointment-card-button';
import {Appointment, Instance, Session} from 'src/models';
import Location from 'src/modules/scheduling/components/location';

const CalendarCard = ({
  organization,
  appointment,
  date,
  sessions,
  patients,
  users,
  event,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
  anchor,
  onClose,
  currentStaffParticipant,
  previousCompletedSession,
  deleteByUser,
}: any) => {
  const [visible, setVisible] = React.useState(false);
  const navigation = useNavigation();
  useEffect(() => {
    openMenu();
  }, [anchor]);

  const openMenu = () => setVisible(true);

  const closeMenu = () => {
    setVisible(false);
    onClose();
  };

  let session = sessions[0];
  const formattedDate = moment(date).format('ll');
  const formattedStartDate = moment(appointment?.start).format('h:mmA');
  const formattedEndDate = moment(appointment?.end).format('h:mmA');

  const isSupervision = currentStaffParticipant?.supervision;
  const {inProgress} = event.extendedProps;

  let cancellationMessage;

  if (appointment?.cancellationReason === 'client') {
    cancellationMessage = 'Client Initiated';
  } else if (appointment?.cancellationReason === 'staff') {
    cancellationMessage = 'Staff Initiated';
  } else if (appointment?.cancellationReason === 'error') {
    cancellationMessage = 'Added in Error';
  } else if (appointment?.cancellationReason === 'other') {
    cancellationMessage = 'Other';
  } else if (appointment?.cancellationReason === 'absent') {
    cancellationMessage = 'Student Absent';
  } else if (appointment?.cancellationReason === 'noShow') {
    cancellationMessage = 'No Show';
  } else {
    cancellationMessage = 'Other';
  }

  return (
    <PaperMenu
      visible={visible}
      onDismiss={closeMenu}
      anchor={anchor}
      style={styles.menu}>
      <View style={styles.innerContainer}>
        <View style={[styles.row, styles.alignSelfRight]}>
          {!appointment?.deletedAt && canEdit && !inProgress ? (
            <IconButton
              onPress={() => editCallback(appointment)}
              icon={'pencil'}
              type={'icon'}
            />
          ) : (
            <></>
          )}
          {!appointment?.deletedAt && canDelete && !inProgress ? (
            <IconButton
              onPress={() => deleteCallback(appointment)}
              icon={'trash-can'}
              type={'icon'}
            />
          ) : (
            <></>
          )}
          <IconButton onPress={closeMenu} icon={'close'} type={'icon'} />
        </View>

        <Text style={[Typography.P3_MEDIUM]}>
          {formattedDate} {formattedStartDate} - {formattedEndDate}
        </Text>
        <Text style={styles.clinician}>Assigned Staff</Text>
        {users.map((participant: any, index: any) => (
          <StaffParticipant
            participant={participant}
            key={index}
            navigation={navigation}
          />
        ))}
        <Text style={styles.clinician}>Assigned Clients</Text>
        {patients.map((participant: any, index: any) => {
          return (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('PatientProfile', {
                  patientId: participant?.id,
                });
              }}
              key={index}
              style={[styles.row, styles.alignCenter]}>
              <View style={[styles.icon]}>
                <Icon name={'account-circle-outline'} size={22} />
              </View>
              <Text style={styles.clinicianName}>
                {participant?.firstName} {participant?.lastName}
              </Text>
            </TouchableOpacity>
          );
        })}
        {appointment?.deletedBy && appointment?.deletedAt && (
          <>
            <Text style={styles.clinician}>
              Canceled by:
              <Text style={styles.cancellationReason}>
                {' '}
                {deleteByUser?.firstName} {deleteByUser?.lastName}
              </Text>
            </Text>
            <Text style={styles.clinician}>
              Cancellation Reason:
              <Text style={styles.cancellationReason}>
                {` ${cancellationMessage}`}
              </Text>
            </Text>
          </>
        )}
        <View style={styles.launchButton}>
          <View style={[styles.row, styles.alignCenter, styles.mr_10]}>
            <View style={styles.sessionNote}>
              {previousCompletedSession?.length > 0 && (
                <TouchableOpacity
                  onPress={() => {
                    if (previousCompletedSession?.length > 0) {
                      navigation.navigate('SessionRecap', {
                        id: previousCompletedSession[0]?.id,
                      });
                    }
                  }}>
                  <SessionNotesIcon type={APPOINTMENTCARDTYPE.DASHBOARD} />
                </TouchableOpacity>
              )}
            </View>
            <Location
              value={session?.location || event?.extendedProps?.location}
            />
          </View>
          {!appointment?.deletedAt && (
            <AppointmentCardButton
              appointment={appointment}
              organization={organization}
              patients={patients}
              session={session}
              date={date}
              navigation={navigation}
              isSupervision={isSupervision}
            />
          )}
        </View>
      </View>
    </PaperMenu>
  );
};

const styles = StyleSheet.create({
  menu: {
    flex: 1,
    borderRadius: 10,
    overflow: 'hidden',
    position: 'absolute',
    ...boxShadow(Colors.RAVEN_BLACK),
    minWidth: 350,
  },
  launchButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  alignSelfRight: {
    alignSelf: 'flex-end',
  },
  innerContainer: {
    flexDirection: 'column',
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  alignCenter: {
    alignItems: 'center',
  },
  paddingVertical: {
    paddingVertical: 20,
  },
  paddingRight: {
    paddingRight: 40,
  },
  icon: {
    marginRight: 5,
  },
  clinician: {
    ...Typography.H6,
    marginTop: 4,
  },
  clinicianName: {
    ...Typography.P3,
  },
  cancellationInfo: {
    ...Typography.P3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 200,
  },
  cancellationReason: {
    ...Typography.P3,
    color: Colors.TORCH_RED_REGULAR,
  },
  sessionNote: {
    width: 27,
    height: 27,
    marginRight: 7,
  },
  mr_10: {
    marginRight: 10,
  },
});

export default compose(
  withDatabase,
  withState,
  withObservables(['event'], ({event, database}: any) => ({
    appointment: database.get(Appointment.table).findAndObserve(event.id),
  })),
  withObservables(
    ['appointment'],
    ({appointment, database, event, authentication}: any) => {
      return {
        organization: database
          ?.get(Instance.table)
          .query(Q.where('_partition', authentication.selectedGroup))
          .observe()
          .pipe(mergeMap(x => x)),
        patients: appointment.patients,
        users: appointment.staffParticipants,
        currentStaffParticipants: appointment
          .currentStaffParticipant(event.extendedProps.user)
          .observe(),
        deleteByUser: appointment?.deletedBy
          ? database.get('users').findAndObserve(appointment?.deletedBy)
          : of({}),
      };
    },
  ),
  withObservables(
    [],
    ({database, currentStaffParticipants, authentication, patients}: any) => {
      const clientIds = _.map(patients, 'id');
      return {
        currentStaffParticipant: currentStaffParticipants.length
          ? currentStaffParticipants[0]
          : of({}),
        previousCompletedSession: database
          .get(Session.table)
          .query(
            Q.where('type', 'session'),
            Q.where('start_timestamp', Q.notEq(null)),
            Q.where('end_timestamp', Q.notEq(null)),
            Q.where('submission_timestamp', Q.notEq(null)),
            Q.where('deleted_at', null),
            Q.where('user_id', authentication?.userId),
            Q.where('patient_id', Q.oneOf(clientIds)),
            Q.sortBy('submission_timestamp', Q.desc),
            Q.take(1),
          ),
      };
    },
  ),
  withObservables(
    ['date'],
    ({database, date, appointment, currentStaffParticipant}: any) => ({
      sessions: database
        .get(Session.table)
        .query(
          Q.where(
            'type',
            currentStaffParticipant.supervision ? 'supervision' : 'session',
          ),
          Q.where('appointment_id', appointment.id),
          Q.where('session_date', moment(date).format('YYYY-MM-DD')),
          Q.where('deleted_at', null),
          Q.take(1),
        ),
    }),
  ),
)(CalendarCard);
