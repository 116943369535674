import React, {useReducer, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Collapsible from 'react-native-collapsible';
import {memoize} from 'lodash';

import {SessionProgramPrompt} from 'src/modules/session/components';
import {Colors, Typography} from 'src/styles';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {useDimensions} from '@react-native-community/hooks';
import {Prompt} from 'src/models';

const TrialByTrialTargetEdit = ({
  program,
  target,
  prompts,
  event,
  trialCount,
  currentSet,
  setEventId,
  currentPrompt,
}: any) => {
  const dimensions = useDimensions();
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const [eventId, setUpdatedEventId] = useState('');
  const total = program?.numberOfTrials;
  const [selectedPrompt, setSelectedPrompt] = useState<number | null>(null);
  const [open, setOpen] = useState(false);

  const styles = getStyles(dimensions.window.width);

  const onValue = async (value: string, selectedPrompts: any) => {
    if (selectedPrompts) {
      event.updateEntity({value, prompts: [selectedPrompts]});
    } else {
      event.updateEntity({value, prompts: []});
    }
    forceUpdate();
  };

  const error = () => {
    if (prompts?.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
      onValue('error', null);
      setUpdatedEventId(event.id);
      setEventId(eventId);
    }
  };

  const success = () => {
    setSelectedPrompt(null);
    onValue('success', null);
    setUpdatedEventId(event.id);
    setEventId(eventId);
  };

  const promptSelected = (id: number) => {
    setSelectedPrompt(id);
    onValue('error', id);
    setUpdatedEventId(event.id);
    setEventId(eventId);
  };

  return (
    <View style={[styles.column, styles.paddingHorizontal, styles.maxWidth]}>
      <View style={[styles.skillCard]}>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignCenter,
          ]}>
          <View style={[styles.flex, styles.column]}>
            <Text
              style={styles.skillCardTitle}
              numberOfLines={1}
              ellipsizeMode="tail">
              {target?.name || ''}
            </Text>
            <Text style={styles.subtitle}>Set {currentSet || 1}</Text>
          </View>

          <Text style={styles.pill}>{`Trial ${trialCount}/${total}`}</Text>
        </View>
        <>
          <View
            style={[
              styles.row,
              styles.paddingTop,
              styles.justifySpaceBetween,
              styles.alignCenter,
            ]}>
            <View style={[styles.row, styles.alignCenter]}>
              <TouchableOpacity
                style={[
                  styles.borderCircleBut,
                  {
                    borderColor: Colors.ERROR,
                    backgroundColor:
                      event.value === 'error'
                        ? Colors.ERROR
                        : Colors.RAVEN_WHITE,
                  },
                ]}
                onPress={error}>
                <MaterialCommunityIcon
                  size={20}
                  name="close"
                  color={
                    event.value === 'error' ? Colors.RAVEN_WHITE : Colors.ERROR
                  }
                />
              </TouchableOpacity>
              <Text style={[styles.measurementTxt, styles.paddingLeft]}>
                Fail
              </Text>
            </View>

            <View style={[styles.row, styles.alignCenter]}>
              <Text style={[styles.measurementTxt, styles.paddingRight]}>
                Pass
              </Text>
              <TouchableOpacity
                style={[
                  styles.borderCircleBut,
                  {
                    borderColor: Colors.SUCCESS_DARK,
                    backgroundColor:
                      event.value === 'success'
                        ? Colors.SUCCESS_DARK
                        : Colors.RAVEN_WHITE,
                  },
                ]}
                onPress={success}>
                <MaterialCommunityIcon
                  size={20}
                  name="check"
                  color={
                    event.value === 'success'
                      ? Colors.RAVEN_WHITE
                      : Colors.SUCCESS_DARK
                  }
                />
              </TouchableOpacity>
            </View>
          </View>
          {event.prompts.length ? (
            <View style={styles.paddingTop}>
              <Text style={styles.promptTxt}>Prompt</Text>
              <View style={styles.promptBoxes}>
                {prompts?.map((prompt: any) => (
                  <SessionProgramPrompt
                    key={`session-program-prompt-${prompt?.id ?? prompt}`}
                    selected={selectedPrompt === prompt}
                    prompt={prompt}
                    currentPrompt={currentPrompt}
                    onClk={() => {
                      promptSelected(prompt.id);
                    }}
                  />
                ))}
              </View>
            </View>
          ) : (
            <Collapsible collapsed={!open}>
              <View style={styles.paddingTop}>
                <Text style={styles.promptTxt}>Prompt</Text>
                <View style={styles.promptBoxes}>
                  {prompts?.map((prompt: any) => (
                    <SessionProgramPrompt
                      key={`session-program-prompt-key-${prompt?.id ?? prompt}`}
                      selected={selectedPrompt === prompt}
                      prompt={prompt}
                      onClk={() => {
                        promptSelected(prompt.id);
                      }}
                    />
                  ))}
                </View>
              </View>
            </Collapsible>
          )}
        </>
      </View>
    </View>
  );
};

const getStyles = memoize((width: number) =>
  EStyleSheet.create({
    maxWidth: {
      width: width > 400 ? 360 : width - 80,
    },
    skillCard: {
      borderWidth: 1,
      borderColor: Colors.PRIMARY_300,
      borderRadius: 4,
      padding: 20,
      marginVertical: 10,
    },
    addSetButton: {
      flex: 1,
      width: '100%',
    },
    paddingTop: {
      paddingTop: 40,
    },
    paddingHorizontal: {
      paddingHorizontal: 12,
    },
    paddingLeft: {
      paddingLeft: 12,
    },
    paddingRight: {
      paddingRight: 12,
    },
    pill: {
      ...Typography.P3,
      paddingVertical: 4,
      paddingHorizontal: 12,
      borderRadius: 12,
      backgroundColor: Colors.PRIMARY_50,
    },
    subtitle: {
      ...Typography.CAPTION,
      color: Colors.TEXT_SECONDARY,
    },
    flex: {
      flex: 1,
    },
    skillCardTitle: {
      ...Typography.P2_BOLD,
    },
    row: {
      flexDirection: 'row',
    },
    column: {
      flexDirection: 'column',
    },
    alignCenter: {
      alignItems: 'center',
      alignContent: 'center',
    },
    justifySpaceBetween: {
      justifyContent: 'space-between',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },
    borderCircleBut: {
      width: 44,
      height: 44,
      borderRadius: 22,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    measurementTxt: {
      ...Typography.P2_MEDIUM,
    },
    promptTxt: {
      ...Typography.CAPTION,
      color: Colors.TEXT_SECONDARY,
      marginBottom: 10,
    },
    promptBoxes: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    programGraph: {
      alignSelf: 'center',
      marginVertical: 40,
    },
  }),
);

export default compose(
  withDatabase,
  withObservables(['eventId'], ({database, eventId}: any) => {
    return {
      event: database.get('events').findAndObserve(eventId),
    };
  }),
  withObservables([], ({session, event, database}: any) => ({
    sets: database
      .get('sets')
      .query(
        Q.and(
          Q.where('target_id', event.targetId),
          Q.where('session_id', session.id),
          Q.where('deleted_at', null),
        ),
        Q.sortBy('created_at', Q.desc),
      ),
  })),
  withObservables([], ({database, event}: any) => {
    return {
      currentPrompt: event?.prompts.length
        ? database.get(Prompt.table).findAndObserve(event.prompts[0])
        : of({}),
    };
  }),
)(TrialByTrialTargetEdit);
