import React, {useCallback, useEffect, useState} from 'react';
import {FlatList, Text, View} from 'react-native';
import NoResults from 'src/common-components/noResults';
import {Typography} from 'src/styles';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {compose} from 'recompose';
import moment from 'moment';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {endOfDay, startOfDay} from 'date-fns';
import Spinner from 'react-native-loading-spinner-overlay';
import {Patient, Session, User} from 'src/models';
import {Value as Location} from 'src/hook-form-inputs/location';
import {cptCodes} from 'src/hook-form-inputs/cpt';

const TimesheetClients = ({sessions, setTimesheetItems}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [totalDuration, setTotalDuration] = useState<number>(0);

  const clientTabsTitles = [
    translations('service_date'),
    translations('service_time'),
    translations('staff_name'),
    translations('npi'),
    translations('client_name'),
    translations('identifier'),
    translations('cpt'),
    translations('billing_code_rate'),
    translations('place_of_service'),
    translations('status'),
    translations('time_worked_hrs'),
    translations('time_worked_mins'),
    translations('units_of_service'),
  ];

  const recalculateAppointments = async () => {
    setLoading(true);
    const curatedAppointments = [];
    let curatedDuration = 0;

    for (const session of sessions) {
      let time = '';
      let duration = 0;
      let cptLabels = '';
      if (session.cpt) {
        cptLabels = session.cpt
          .map((cptCode: string) => {
            const cptEntry = cptCodes.find(cpt => cpt.value === cptCode);
            return cptEntry ? `${cptCode} - ${cptEntry.label}` : cptCode;
          })
          .join(', ');
      }

      let user = null;
      if (session.userId) {
        user = await database.get(User.table).find(session.userId);
      }
      const credential = user ? (await user.userCredential)[0] : {};
      let client = await database.get(Patient.table).find(session.patientId);

      if (session?.endTimestamp && session?.startTimestamp) {
        const sessionStartTimestamp = session?.editedStartTimestamp
          ? session?.editedStartTimestamp
          : session?.startTimestamp;
        const startTimestamp = moment()
          .set({
            hour: sessionStartTimestamp.getHours(),
            minute: sessionStartTimestamp.getMinutes(),
            second: sessionStartTimestamp.getSeconds(),
          })
          .toDate();
        const sessionEndTimestamp = session?.editedEndTimestamp
          ? session?.editedEndTimestamp
          : session?.endTimestamp;
        const endTimestamp = moment()
          .set({
            hour: sessionEndTimestamp.getHours(),
            minute: sessionEndTimestamp.getMinutes(),
            second: sessionEndTimestamp.getSeconds(),
          })
          .toDate();
        time = `${moment(
          session?.editedStartTimestamp
            ? session?.editedStartTimestamp
            : session?.startTimestamp,
        ).format('hh:mm A')} - ${moment(
          session?.editedEndTimestamp
            ? session?.editedEndTimestamp
            : session?.endTimestamp,
        ).format('hh:mm A')}`;
        duration =
          Math.abs(
            moment()
              .set({
                hour: endTimestamp.getHours(),
                minute: endTimestamp.getMinutes(),
                second: endTimestamp.getSeconds(),
              })
              .toDate()
              .getTime() -
              moment()
                .set({
                  hour: startTimestamp.getHours(),
                  minute: startTimestamp.getMinutes(),
                  second: startTimestamp.getSeconds(),
                })
                .toDate()
                .getTime(),
          ) / 36e5;
      } else {
        const appointment = await session.appointment;

        let appointmentStart = appointment.start;
        let appointmentEnd = appointment.end;

        if (user) {
          const participants = await appointment.currentStaffParticipant(
            user.id,
          );
          if (participants.length > 0) {
            appointmentStart = participants[0].startTime;
            appointmentEnd = participants[0].endTime;
          }
        }

        time = `${moment(appointmentStart).format('hh:mm A')} - ${moment(
          appointmentEnd,
        ).format('hh:mm A')}`;
        duration =
          Math.abs(
            moment()
              .set({
                hour: appointmentEnd.getHours(),
                minute: appointmentEnd.getMinutes(),
                second: appointmentEnd.getSeconds(),
              })
              .toDate()
              .getTime() -
              moment()
                .set({
                  hour: appointmentStart.getHours(),
                  minute: appointmentStart.getMinutes(),
                  second: appointmentStart.getSeconds(),
                })
                .toDate()
                .getTime(),
          ) / 36e5;
      }

      let status = translations('not_started');
      if (!session?.startTimestamp) {
        status = translations('not_started');
      } else if (session?.startTimestamp && !session?.endTimestamp) {
        status = translations('in_progress');
      } else if (session?.endTimestamp && !session?.submissionTimestamp) {
        status = translations('missing_values');
      } else {
        status = translations('completed');
      }

      curatedAppointments.push([
        moment(session?.sessionDate).format('MM/DD/YYYY'),
        time,
        user ? `${user?.firstName} ${user?.lastName}` : '',
        credential?.npi,
        `${client.firstName} ${client.lastName}`,
        client.identifier,
        cptLabels,
        '0.00',
        Location(session.location),
        status,
        parseFloat(duration.toFixed(2)),
        parseFloat((duration * 60).toFixed(2)),
        parseFloat(duration.toFixed(2)) * 4,
      ]);

      curatedDuration += duration;
    }

    setTotalDuration(curatedDuration);
    setItems(
      curatedAppointments.sort((a, b) => new Date(b[0]) - new Date(a[0])),
    );
    setLoading(false);
  };

  useEffect(() => {
    setTimesheetItems([clientTabsTitles, ...items]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    setTotalDuration(0);
    recalculateAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions.length]);

  const renderItem = useCallback(
    ({item}: any) => (
      <View style={[styles.card]}>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignItemsCenter,
          ]}>
          {item.map((value: any, index: number) => (
            <View
              key={index}
              style={[styles.flex, styles.column, styles.justifyCenter]}>
              <Text
                style={[
                  styles.paddingVertical,
                  styles.textAlignCenter,
                  Typography.P3,
                  styles.textColorSecondary,
                ]}>
                {value}
              </Text>
            </View>
          ))}
        </View>
      </View>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Spinner visible={loading} />
      <View
        style={[
          styles.row,
          styles.justifySpaceBetween,
          styles.paddingLVertical,
          styles.paddingHorizontal,
        ]}>
        {clientTabsTitles.map((title: any, key) => (
          <View
            key={key}
            style={[styles.flex, styles.alignCenter, styles.justifyCenter]}>
            <Text
              style={[
                styles.textAlignCenter,
                Typography.P2_MEDIUM,
                styles.textColorPrimary,
              ]}>
              {title}
            </Text>
          </View>
        ))}
      </View>
      {items.length !== 0 ? (
        <>
          <FlatList
            scrollEnabled={true}
            data={items}
            style={[styles.height]}
            renderItem={renderItem}
          />
          <View
            style={[
              styles.row,
              styles.justifySpaceBetween,
              styles.paddingLVertical,
              styles.paddingHorizontal,
            ]}>
            <View style={[styles.flex, styles.marginHorizontal]}>
              <Text
                style={[
                  Typography.P3_MEDIUM,
                  styles.textColorPrimary,
                  styles.paddingLeft,
                ]}>
                {translations('totals')}
              </Text>
            </View>
            <View style={[styles.flex]} />
            <View style={[styles.flex]} />
            <View style={[styles.flex]} />
            <View style={[styles.flex]} />
            <View style={[styles.flex]} />
            <View style={[styles.flex]} />
            <View style={[styles.flex]} />
            <View style={[styles.flex]} />
            <View style={[styles.flex]} />
            <View
              style={[
                styles.flex,
                styles.textAlignCenter,
                styles.justifySpaceBetween,
              ]}>
              <Text style={[Typography.P3_MEDIUM, styles.textColorPrimary]}>
                {translations('time_worked_hrs')}
              </Text>
              <Text style={[Typography.P3_MEDIUM, styles.textColorSecondary]}>
                {parseFloat(totalDuration.toFixed(2))}
              </Text>
            </View>
            <View
              style={[
                styles.flex,
                styles.textAlignCenter,
                styles.justifySpaceBetween,
              ]}>
              <Text style={[Typography.P3_MEDIUM, styles.textColorPrimary]}>
                {translations('time_worked_mins')}
              </Text>
              <Text style={[Typography.P3_MEDIUM, styles.textColorSecondary]}>
                {parseFloat((totalDuration * 60).toFixed(2))}
              </Text>
            </View>
            <View
              style={[
                styles.flex,
                styles.textAlignCenter,
                styles.justifySpaceBetween,
              ]}>
              <Text style={[Typography.P3_MEDIUM, styles.textColorPrimary]}>
                {translations('units')}
              </Text>
              <Text style={[Typography.P3_MEDIUM, styles.textColorSecondary]}>
                {parseFloat(totalDuration.toFixed(2)) * 4}
              </Text>
            </View>
          </View>
        </>
      ) : (
        <NoResults message={translations('empty_timesheet_message')} />
      )}
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(
    ['filters', 'update'],
    ({filters, database, authentication, role, profile, user = null}: any) => {
      const locationQuery = [];
      if (filters?.locations && filters.locations.length) {
        locationQuery.push(Q.where('location', Q.oneOf(filters.locations)));
      }

      const userQueries = [];
      if (user) {
        userQueries.push(Q.where('user_id', Q.eq(user.id)));
      } else {
        if (filters?.staff && filters.staff.length) {
          const userQuery = [];
          for (const staff of filters.staff) {
            userQuery.push(Q.where('user_id', Q.eq(staff)));
          }
          userQueries.push(Q.or(...userQuery));
        }
      }

      const clientQuery = [];
      if (filters?.clients && filters.clients.length) {
        const patientQueries = [];
        for (const patient of filters.clients) {
          patientQueries.push(Q.where('patient_id', Q.eq(patient)));
        }
        clientQuery.push(Q.or(...patientQueries));
      }

      const cptQuery = [];
      if (filters?.types && filters.types.length) {
        const cptQueries = [];
        for (const cpt of filters.types) {
          cptQueries.push(Q.where('cpt', Q.includes(cpt)));
        }
        cptQuery.push(Q.or(...cptQueries));
      }

      const statusQuery = [];
      if (filters?.status && filters.status.length) {
        const statuses = [];
        for (const status of filters.status) {
          if (status === 'not-started') {
            statuses.push(Q.where('start_timestamp', Q.eq(null)));
          } else if (status === 'in-progress') {
            statuses.push(
              Q.and(
                Q.where('start_timestamp', Q.notEq(null)),
                Q.where('end_timestamp', Q.eq(null)),
              ),
            );
          } else if (status === 'missing-values') {
            statuses.push(
              Q.and(
                Q.where('end_timestamp', Q.notEq(null)),
                Q.where('submission_timestamp', Q.eq(null)),
              ),
            );
          } else if (status === 'completed') {
            statuses.push(Q.where('submission_timestamp', Q.notEq(null)));
          }
        }
        statusQuery.push(Q.or(...statuses));
      }

      return {
        sessions: !role?.appointmentAssignedOnly
          ? database
              .get(Session.table)
              .query(
                Q.where(
                  'date',
                  Q.gte(startOfDay(filters.startDate).getTime() - 86400000),
                ),
                Q.where('date', Q.lte(endOfDay(filters.endDate).getTime())),
                Q.where('_partition', authentication.selectedGroup),
                Q.where('deleted_at', null),
                ...locationQuery,
                ...userQueries,
                ...clientQuery,
                ...cptQuery,
                ...statusQuery,
              )
          : database
              .get(Session.table)
              .query(
                Q.where(
                  'date',
                  Q.gte(startOfDay(filters.startDate).getTime() - 86400000),
                ),
                Q.where('date', Q.lte(endOfDay(filters.endDate).getTime())),
                Q.where('user_id', Q.eq(profile.id)),
                Q.where('_partition', authentication.selectedGroup),
                Q.where('deleted_at', null),
                ...locationQuery,
                ...userQueries,
                ...clientQuery,
                ...cptQuery,
                ...statusQuery,
              ),
      };
    },
  ),
)(TimesheetClients);
