import {
  children,
  json,
  lazy,
  relation,
  text,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';
import {Q} from '@nozbe/watermelondb';

export class Claim extends Base {
  static table = 'claims';

  static associations = {
    sessions: {type: 'has_many', foreignKey: 'claim_id'},
    patients: {type: 'belongs_to', key: 'patient_id'},
    service_lines: {type: 'has_many', foreignKey: 'claim_id'},
    claim_events: {type: 'has_many', foreignKey: 'claim_id'},
    users: {type: 'belongs_to', foreignKey: 'updated_by'},
  };

  @children('sessions') sessions;
  @children('service_lines') services;
  @children('claim_events') events;

  @lazy
  claimEvents = this.events.extend(
    Q.where('deleted_at', null),
    Q.sortBy('timestamp', Q.desc),
  );

  @relation('patients', 'patient_id') patient;
  @relation('users', 'updated_by') user;

  @text('patient_id') patientId;
  @text('status') status;
  @text('box_8') box8;
  @text('box_9b') box9b;
  @text('box_9c') box9c;
  @text('box_10d') box10d;
  @text('box_19') box19;
  @text('box_30') box30;
  @text('kafka') kafka;
  @json('payload', value => value) payload;
}
