import React from 'react';
import {DataItem, RadioInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import _ from 'lodash';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const genderValues = [
  {value: 'male', title: 'Male', label: 'Male'},
  {value: 'female', title: 'Female', label: 'Female'},
  {value: 'other', title: 'Other', label: 'Other'},
];

export const Import = (
  key: string = 'gender',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Gender (${genderValues.map(value => value.label).join(', ')})`,
  key,
  type: 'select',
  selectOptions: genderValues,
});

const resolveDisplayValue = (value: string) => {
  return _.capitalize(value);
};

const GenderDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Gender</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const GenderInput = ({valueKey}: Props) => {
  return (
    <RadioInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'gender'}
      name={'Gender'}
      required={false}
      buttons={genderValues}
    />
  );
};

export const validation = () => Yup.string().required('Gender is required');

export const Display = GenderDisplay;
export const Input = GenderInput;
export const Value = resolveDisplayValue;

export default GenderInput;
