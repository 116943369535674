import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import React from 'react';
import {useTranslations} from 'src/providers/translation';

const Billable = ({name}: any) => {
  const translations = useTranslations();

  return (
    <HookFormSwitchInput
      name={(name ? name + '.' : '') + 'billable'}
      label={translations('billable')}
    />
  );
};

export default Billable;
