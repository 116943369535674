import React from 'react';
import {ActivityIndicator, FlatList, ListRenderItem, View} from 'react-native';
import NoResults from 'src/common-components/noResults';
import {useDimensions} from '@react-native-community/hooks';
import {useStyle} from 'src/providers/style';

interface Props {
  items: any[];
  loading: boolean;
  keyExtractor?: (item: any) => any;
  emptyIcon: string;
  emptyMessage: string;
  scrollEnabled?: boolean;
  renderItem: ListRenderItem<any>;
}

const getNumColumns = (width: number) => {
  if (width > 1920) {
    return 5;
  } else if (width > 1440) {
    return 4;
  } else if (width > 1024) {
    return 3;
  } else if (width > 768) {
    return 2;
  } else {
    return 1;
  }
};

const CardList = ({
  items,
  loading,
  keyExtractor = (item: any) => item.id,
  emptyIcon = '',
  emptyMessage = '',
  scrollEnabled = true,
  renderItem,
}: Props) => {
  const dimensions = useDimensions();
  const styles = useStyle();

  const numColumns = getNumColumns(dimensions.window.width);

  return (
    <>
      {!items?.length && !loading ? (
        <NoResults iconName={emptyIcon} message={emptyMessage} />
      ) : (
        <FlatList
          key={numColumns}
          data={items}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          numColumns={numColumns}
          style={[styles.flex]}
          scrollEnabled={scrollEnabled}
          columnWrapperStyle={
            numColumns === 1 ? null : [styles.flex, styles.alignStart]
          }
          initialNumToRender={1}
          onEndReachedThreshold={0.5}
          ListFooterComponent={
            <>
              {loading ? (
                <View style={[styles.flex]}>
                  <ActivityIndicator
                    style={[styles.flex]}
                    color="gray"
                    size="small"
                  />
                </View>
              ) : (
                <></>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default CardList;
