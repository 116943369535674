import React, {useEffect, useState} from 'react';
import {
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {Screen} from 'react-native-screens';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import {useNavigation} from '@react-navigation/native';
import {replace} from 'src/navigation/utils/replace';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import Stepper from 'src/design-system/stepper';
import {Q} from '@nozbe/watermelondb';
import {useForm} from 'react-hook-form';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  UnaddressedProgramList,
  SessionImpressions,
  SessionNoteReview,
} from '../../components';
import {IconButton} from 'react-native-paper';
import {RAVEN_WHITE} from 'src/styles/colors';

interface Props {
  session: any;
  currentStaffParticipant: any;
  totalPrograms: any[];
  imcompleteProgramSets: any[];
}

const PostSessionReview = ({
  session,
  currentStaffParticipant,
  totalPrograms = [],
  imcompleteProgramSets = [],
}: Props) => {
  const isSupervision = currentStaffParticipant?.supervision;
  const [emotionalStates, setEmotionalStates] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [clientName, setClientName] = useState<string>('');
  const navigation = useNavigation();
  const styles = useStyle();

  useEffect(() => {
    const getClientName = async () => {
      const patient = await session.patient.fetch();
      setClientName(`${patient?.firstName} ${patient?.lastName}`);
    };
    getClientName();
  }, [session]);

  const schema = Yup.object({
    unaddressedSets: Yup.array().of(
      Yup.object().shape({
        reason: Yup.string().required('A reason is required.'),
        description: Yup.string().when('reason', {
          is: 'O',
          then: () => Yup.string().required('Must enter a description.'),
        }),
      }),
    ),
  });

  const {control: notAddressedControl, handleSubmit: notAddressedHandleSubmit} =
    useForm({
      defaultValues: {
        unaddressedSets: imcompleteProgramSets.map(() => ({
          reason: undefined,
          description: '',
          includeNotAddressed: ['include'],
        })),
      },
      resolver: yupResolver(schema),
    });

  const {control, handleSubmit} = useForm({
    defaultValues: {
      sessionImpressionNotes: '',
      sessionNoteReview: isSupervision ? session.supervisionNote : session.note,
      includeNotAddressed: ['include'],
    },
    resolver: yupResolver(schema),
  });

  const onError = (tabStatusErrors: any) => {
    // TODO: Navigate to tab and validation failure
    console.log(tabStatusErrors);
  };

  const notAddressedOnSubmit = async (values: any) => {
    if (currentStep === 2) {
      for (let [index, set] of imcompleteProgramSets.entries()) {
        await set.updateEntity({
          notAddressedReason: values.unaddressedSets[index].reason,
          notAddressedDescription: values.unaddressedSets[index].description,
        });
      }
    } else {
      setCurrentStep(prevValue => prevValue + 1);
    }
  };

  const onSubmit = async (values: any) => {
    if (isSupervision) {
      await session.updateEntity({
        supervisionNote: values.sessionNoteReview,
        postSessionEmotions: emotionalStates,
        postSessionImpressions: values.sessionImpressionNotes,
      });
    } else {
      await session.updateEntity({
        note: values.sessionNoteReview,
        postSessionEmotions: emotionalStates,
        postSessionImpressions: values.sessionImpressionNotes,
      });
    }
    navigation.dispatch(
      replace('SessionMessage', {
        id: session?.id,
      }),
    );
  };

  const skipOnSubmit = async (values: any) => {
    if (values.unaddressedSets) {
      for (const [index, set] of imcompleteProgramSets.entries()) {
        await set.updateEntity({
          notAddressedReason: values.unaddressedSets[index].reason,
          notAddressedDescription: values.unaddressedSets[index].description,
        });
      }
    } else {
      if (isSupervision) {
        await session.updateEntity({
          supervisionNote: values.sessionNoteReview,
          postSessionEmotions: emotionalStates,
          postSessionImpressions: values.sessionImpressionNotes,
        });
      } else {
        await session.updateEntity({
          note: values.sessionNoteReview,
          postSessionEmotions: emotionalStates,
          postSessionImpressions: values.sessionImpressionNotes,
        });
      }
    }

    navigation.dispatch(
      replace('SessionMessage', {
        id: session?.id,
      }),
    );
  };

  const StepperOnClickCallback = (proceedStep: number) => {
    if (currentStep === 0 && proceedStep > 0) {
      notAddressedHandleSubmit(notAddressedOnSubmit, onError)();
    } else {
      setCurrentStep(proceedStep);
    }
  };

  return (
    <Screen
      style={[
        Platform.OS === 'web' ? styles.overflowHidden : {},
        styles.height,
      ]}>
      <SafeAreaView
        style={[
          styles.width,
          styles.backgroundColorBlack,
          styles.container,
          styles.justifyCenter,
        ]}>
        <View style={[styles.alignCenter, styles.row, styles.flex]}>
          <IconButton
            icon="arrow-left"
            color={RAVEN_WHITE}
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
                return;
              } else {
                navigation.navigate('Dashboard');
              }
            }}
          />
        </View>
        <View
          style={[
            styles.paddingVertical,
            styles.row,
            styles.alignSelfCenter,
            styles.positionAbsolute,
          ]}>
          <Text style={[Typography.P1, styles.textColorWhite]}>
            {clientName} |{' '}
          </Text>
          <Text style={[Typography.H5, styles.textColorWhite]}>
            Post Session Review
          </Text>
        </View>
      </SafeAreaView>
      <View style={styles.paddingHorizontal}>
        <Stepper
          currentStep={[currentStep, setCurrentStep]}
          steps={['Not Addressed', 'Session Impressions', 'Note Review']}
          onClickCallback={StepperOnClickCallback}
        />
      </View>
      {currentStep === 0 ? (
        <UnaddressedProgramList
          totalSessionNumber={totalPrograms.length}
          imcompleteProgramSets={imcompleteProgramSets}
          control={notAddressedControl}
        />
      ) : currentStep === 1 ? (
        <SessionImpressions
          emotionalStates={[emotionalStates, setEmotionalStates]}
          control={control}
        />
      ) : (
        <SessionNoteReview control={control} />
      )}
      <SafeAreaView
        style={[
          styles.width,
          styles.backgroundColorPrimary100,
          styles.row,
          currentStep > 0 ? styles.justifySpaceBetween : styles.justifyEnd,
          styles.positionAbsolute,
          styles.bottom0,
        ]}>
        {currentStep > 0 ? (
          <TouchableOpacity
            style={[
              styles.alignCenter,
              styles.paddingLVertical,
              styles.paddingHorizontal,
              styles.flex,
            ]}
            onPress={() => {
              notAddressedHandleSubmit(skipOnSubmit, onError)();
              handleSubmit(skipOnSubmit, onError)();
            }}>
            <Text style={[Typography.TERTIARY_BUTTON, styles.paddingSMTop]}>
              SKIP ALL
            </Text>
          </TouchableOpacity>
        ) : (
          <></>
        )}
        <View style={styles.paddingM}>
          <RHButton
            onPress={
              currentStep === 0
                ? () =>
                    notAddressedHandleSubmit(notAddressedOnSubmit, onError)()
                : currentStep === 1
                ? () => setCurrentStep(prevVal => prevVal + 1)
                : () => {
                    handleSubmit(onSubmit, onError)();
                    notAddressedHandleSubmit(notAddressedOnSubmit, onError)();
                  }
            }
            textColor={Colors.RAVEN_WHITE}
            style={[styles.row, styles.alignCenter]}
            mode={'contained'}>
            {currentStep !== 2 ? 'Next' : 'FINISH'}
            <Icon
              name="arrow-right"
              size={16}
              color={Colors.RAVEN_WHITE}
              style={styles.marginSMLeft}
            />
          </RHButton>
        </View>
      </SafeAreaView>
    </Screen>
  );
};

export default compose(
  withState,
  withDatabase,
  withObservables([], ({route, database}: any) => ({
    session: database.get('sessions').findAndObserve(route.params?.id),
    imcompleteSets:
      database
        .get('sets')
        .query(
          Q.where('end_timestamp', Q.eq(null)),
          Q.where('session_id', route.params?.id),
        ) || of([]),
  })),
  withObservables([], ({imcompleteSets, database}: any) => {
    const imcompleteProgramsId = imcompleteSets.map(
      (set: any) => set.programId,
    );
    return {
      unaddressedPrograms:
        database
          .get('programs')
          .query(
            Q.where('id', Q.oneOf(imcompleteProgramsId)),
            Q.where('type', Q.eq('skill')),
          ) || of([]),
    };
  }),
  withObservables([], ({unaddressedPrograms, database, route}: any) => {
    const unaddressedProgramsId = unaddressedPrograms.map(
      (program: any) => program.id,
    );
    return {
      imcompleteProgramSets:
        database
          .get('sets')
          .query(
            Q.where('end_timestamp', Q.eq(null)),
            Q.where('session_id', route.params?.id),
            Q.where('program_id', Q.oneOf(unaddressedProgramsId)),
          ) || of([]),
    };
  }),
  withObservables([], ({session}: any) => ({
    appointment: session.appointment,
  })),
  withObservables([], ({appointment}: any) => ({
    participants: appointment.participants,
  })),
  withObservables([], ({appointment, authentication}: any) => ({
    currentStaffParticipants: appointment
      .currentStaffParticipant(authentication.userId)
      .observe(),
  })),
  withObservables([], ({currentStaffParticipants}: any) => ({
    currentStaffParticipant: currentStaffParticipants.length
      ? currentStaffParticipants[0]
      : of({}),
  })),
)(PostSessionReview);
