import React, {useEffect, useMemo, useState} from 'react';
import {Text} from 'react-native-paper';
import {appointmentLocations} from 'src/form-inputs/appointment-location-input';
import {Controller, useForm} from 'react-hook-form';
import {CollapsibleFilterItem} from 'src/design-system';
import CheckboxItemsInput from 'src/hook-form/checkbox-input';
import {Keyboard, Platform, TouchableOpacity, View} from 'react-native';
import RadioInput from 'src/hook-form/radio-input';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {useDispatch, useSelector} from 'react-redux';
import {setFilters} from 'src/redux/scheduling-filters';
import FormInput from 'src/hook-form/form-input';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {Q} from '@nozbe/watermelondb';
import {fuzzy} from 'src/common-utils/fuzzy';
import SchedulingChip from 'src/modules/scheduling/components/scheduling-chip';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {cptCodesFilter} from 'src/hook-form-inputs/cpt';

interface Props {
  filters: any;
  patients: any[];
  users: any[];
}
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
function wc_hex_is_light(color: string) {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
}

export const scheduleByValues = [
  {value: 'status', label: 'Status'},
  {value: 'location', label: 'Location'},
  {value: 'cancel', label: 'Canceled'},
];

const isAndroid = Platform.OS === 'android';

const AppointmentFilter = ({filters, patients, users}: Props) => {
  const translations = useTranslations();
  const styles = useStyle();
  const dispatch = useDispatch();

  const {userId: imTheEldestBoy} = useSelector(state => state.authentication);

  const {control, getValues, watch} = useForm({
    defaultValues: filters,
  });

  const [search, setSearch] = useState('');
  const [focused, setFocused] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  const [keyboardShown, setKeyboardShown] = useState(false);

  useEffect(() => {
    const keyboardWillShowSub = Keyboard.addListener(
      isAndroid ? 'keyboardDidShow' : 'keyboardWillShow',
      keyboardWillShow,
    );
    const keyboardWillHideSub = Keyboard.addListener(
      isAndroid ? 'keyboardDidHide' : 'keyboardWillHide',
      keyboardWillHide,
    );

    return () => {
      keyboardWillShowSub.remove();
      keyboardWillHideSub.remove();
    };
  }, []);

  const keyboardWillShow = () => {
    setKeyboardShown(true);
  };

  const keyboardWillHide = () => {
    setKeyboardShown(false);
  };

  useEffect(() => {
    let entities = [];
    for (const patient of patients) {
      entities.push({
        type: 'patient',
        value: patient.id,
        label: `${patient.firstName} ${patient.lastName}`,
      });
    }
    for (const user of users) {
      entities.push({
        type: 'user',
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      });
    }
    setItems(entities.sort((a, b) => a.label.localeCompare(b.label)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchedItems = useMemo(() => {
    return items
      ?.filter(
        (item: any) =>
          filters.participants.findIndex(
            participant => participant.value === item.value,
          ) === -1,
      )
      .filter((item: any) => fuzzy(item?.label, search));
  }, [items, search, filters.participants]);

  useEffect(() => {
    const subscription = watch(() => {
      dispatch(setFilters(getValues()));
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <View style={[styles.paddingTop]}>
      {Platform.OS === 'web' ? (
        <>
          <Text style={[Typography.P3]}>{translations('schedule_by')}</Text>
          <Controller
            control={control}
            render={({field: {onChange, value}}) => (
              <RadioInput
                items={scheduleByValues}
                onChange={onChange}
                value={value}
                label={''}
              />
            )}
            name="scheduleBy"
          />
        </>
      ) : (
        <></>
      )}

      <Controller
        control={control}
        rules={{}}
        render={({field: {onChange, value}}) => (
          <>
            <View style={[styles.positionRelative, styles.flex, styles.zIndex]}>
              <FormInput
                onChange={setSearch}
                value={search}
                label={'Client / Staff Search'}
                onFocus={() => setFocused(true)}
                onBlur={() =>
                  setTimeout(
                    () => (!keyboardShown ? setFocused(false) : {}),
                    200,
                  )
                }
              />
              {focused ? (
                <View
                  style={[
                    styles.positionAbsolute,
                    styles.top64,
                    styles.right0,
                    styles.left0,
                    styles.backgroundColorWhite,
                    styles.elevation,
                  ]}>
                  <View style={[styles.backgroundColorWhite]}>
                    {searchedItems
                      ?.slice(0, 4)
                      .map((item: any, index: number) => {
                        const color = getRandomColor();
                        const textColor = wc_hex_is_light(color)
                          ? '#000000'
                          : '#FFFFFF';
                        return (
                          <TouchableOpacity
                            key={`search-item-${index}`}
                            onPress={() => {
                              onChange([
                                ...filters.participants,
                                {
                                  ...item,
                                  color:
                                    imTheEldestBoy === item.value
                                      ? '#000000'
                                      : item.type === 'patient'
                                      ? '#E3E3E3'
                                      : color,
                                  textColor:
                                    imTheEldestBoy === item.value
                                      ? '#FFFFFF'
                                      : item.type === 'patient'
                                      ? '#000000'
                                      : textColor,
                                },
                              ]);
                              setFocused(false);
                              setSearch('');
                            }}>
                            <View
                              style={[
                                styles.row,
                                styles.alignCenter,
                                styles.paddingL,
                              ]}>
                              <Icon
                                style={[styles.marginMRight]}
                                color={'#000000'}
                                size={12}
                                name={
                                  item.type === 'patient'
                                    ? 'account'
                                    : 'card-account-mail'
                                }
                              />
                              <Text
                                style={[
                                  Typography.LABEL,
                                  styles.textColorPrimary,
                                ]}>
                                {item.label}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        );
                      })}
                  </View>
                </View>
              ) : null}
            </View>
            <View style={[styles.column]}>
              {value.map((participant: any, index: number) => {
                return (
                  <SchedulingChip
                    key={`scheduling-chip-${index}`}
                    participant={participant}
                    onClose={() => {
                      const party = [...value];
                      party.splice(index, 1);
                      onChange(party);
                    }}
                  />
                );
              })}
            </View>
          </>
        )}
        name="participants"
      />

      <CollapsibleFilterItem title={translations('place_of_service')}>
        <Controller
          control={control}
          rules={{}}
          render={({field: {onChange, value}}) => (
            <CheckboxItemsInput
              allowSelectAll={true}
              value={value}
              onChange={onChange}
              items={appointmentLocations}
            />
          )}
          name="locations"
        />
      </CollapsibleFilterItem>
      <CollapsibleFilterItem title={translations('billing_codes')}>
        <Controller
          control={control}
          rules={{}}
          render={({field: {onChange, value}}) => (
            <CheckboxItemsInput
              allowSelectAll={true}
              value={value}
              onChange={onChange}
              items={cptCodesFilter}
            />
          )}
          name="types"
        />
      </CollapsibleFilterItem>
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({profile, database, authentication, role}: any) => ({
    patients: !role?.patientAssignedOnly
      ? database
          .get('patients')
          .query(
            Q.where('deleted_at', null),
            Q.where('state', 'active'),
            Q.where('_partition', authentication.selectedGroup),
          )
      : profile.patients,
    users: !role?.appointmentAssignedOnly
      ? database
          .get('users')
          .query(
            Q.where('deleted_at', null),
            Q.where('state', 'active'),
            Q.where('_partition', authentication.selectedGroup),
          )
      : of([profile]),
  })),
)(AppointmentFilter);
