import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {Colors, Typography} from 'src/styles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';
import {useStyle} from 'src/providers/style';

const StaffParticipant = ({participant, user, navigation}: any) => {
  const styles = useStyle();

  const isSupervision = participant.supervision;

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('UserProfile', {
          practitionerId: user?.id,
        });
      }}
      style={[styles.row, styles.alignCenter]}>
      <Icon
        name={isSupervision ? 'eye' : 'account-supervisor'}
        size={22}
        color={isSupervision ? Colors.SECONDARY_800 : Colors.PRIMARY_900}
      />
      <Text style={[Typography.P3, styles.marginSMLeft]}>
        {user?.firstName} {user?.lastName}
      </Text>
    </TouchableOpacity>
  );
};

export default compose(
  withState,
  withObservables(['participant'], ({participant}: any) => ({
    user: participant?.user || of({}),
  })),
)(StaffParticipant);
