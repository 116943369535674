import React from 'react';
import {View, ScrollView, FlatList, Text} from 'react-native';
import {IconButton} from 'react-native-paper';
import {appointmentLocations} from 'src/form-inputs/appointment-location-input';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

const SessionNoteFilterIndicator = ({
  filters,
  setFilters,
  clients,
  staff,
}: any) => {
  const styles = useStyle();

  const checkIfLocation = (chip: string) => {
    return (
      appointmentLocations.findIndex(
        (location: any) => location.value === chip,
      ) !== -1
    );
  };

  const checkIfClient = (chip: string) => {
    return clients.findIndex((client: any) => client.id === chip) !== -1;
  };

  const checkIfStaff = (chip: string) => {
    return staff.findIndex((_staff: any) => _staff.id === chip) !== -1;
  };

  const deleteFilterChip = (_chip: string) => {
    if (checkIfClient(_chip)) {
      setFilters({
        ...filters,
        clients: filters?.clients.filter((value: any) => value !== _chip),
      });
    } else if (checkIfStaff(_chip)) {
      setFilters({
        ...filters,
        staff: filters?.staff.filter((value: any) => value !== _chip),
      });
    } else if (checkIfLocation(_chip)) {
      setFilters({
        ...filters,
        locations: filters?.locations.filter((value: any) => value !== _chip),
      });
    }
  };

  return (
    <View
      style={[styles.width, styles.paddingHorizontal, styles.paddingBottom]}>
      <ScrollView style={[styles.maxWidth]}>
        <FlatList
          horizontal
          data={[...filters?.locations, ...filters?.clients, ...filters?.staff]}
          renderItem={({item: selectedChip}) => {
            let chipLabel: string = '';
            if (checkIfLocation(selectedChip)) {
              chipLabel =
                appointmentLocations[
                  appointmentLocations.findIndex(
                    (location: any) => location.value === selectedChip,
                  )
                ]?.label;
            } else if (checkIfClient(selectedChip)) {
              const selectedClient =
                clients[
                  clients.findIndex((client: any) => client.id === selectedChip)
                ];
              chipLabel = `${selectedClient?.firstName} ${selectedClient?.lastName}`;
            } else if (checkIfStaff(selectedChip)) {
              const selectedStaff =
                staff[
                  staff.findIndex((_staff: any) => _staff.id === selectedChip)
                ];
              chipLabel = `${selectedStaff?.firstName} ${selectedStaff?.lastName}`;
            }
            return (
              <View
                style={[
                  styles.backgroundColorBlack,
                  styles.borderRadiusSM,
                  styles.marginSMVertical,
                  styles.marginLRight,
                  styles.row,
                ]}
                key={`filter-chip-key-${selectedChip}`}>
                <Text
                  style={[
                    Typography.P2,
                    styles.textColorWhite,
                    styles.alignSelfCenter,
                    styles.marginLHorizontal,
                  ]}>
                  {chipLabel}
                </Text>
                <IconButton
                  size={15}
                  icon="close"
                  color="white"
                  onPress={() => deleteFilterChip(selectedChip)}
                />
              </View>
            );
          }}
        />
      </ScrollView>
    </View>
  );
};

export default SessionNoteFilterIndicator;
