import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import React from 'react';

const LocationLegend = () => {
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <View
      style={[
        styles.row,
        styles.alignCenter,
        styles.justifyCenter,
        styles.marginLTop,
      ]}>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[styles.dotL, styles.backgroundColorTeal, styles.marginLRight]}
        />
        <Text style={[Typography.P3]}>{translations('home')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[styles.dotL, styles.backgroundColorLime, styles.marginLRight]}
        />
        <Text style={[Typography.P3]}>{translations('office')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[
            styles.dotL,
            styles.backgroundColorArctic,
            styles.marginLRight,
          ]}
        />
        <Text style={[Typography.P3]}>{translations('school')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[styles.dotL, styles.backgroundColorRuby, styles.marginLRight]}
        />
        <Text style={[Typography.P3]}>{translations('telehealth')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[
            styles.dotL,
            styles.backgroundColorBlack,
            styles.marginLRight,
          ]}
        />
        <Text style={[Typography.P3]}>{translations('other')}</Text>
      </View>
    </View>
  );
};

export default LocationLegend;
