import React from 'react';
import {View, Text, ScrollView} from 'react-native';
import moment from 'moment';
import ClientReport from 'src/modules/reports/components/client-report';
import {useStyle} from 'src/providers/style';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {Separator} from 'src/common-components/atoms';
import DateLayout from 'src/hook-form-wrapper/date-input';
import {subtractNMonths} from 'src/common-utils/subtractNMonths';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {isSafari} from 'react-device-detect';
import {useTranslations} from 'src/providers/translation';

function PatientSummary({patient}: any) {
  const styles = useStyle();
  const translations = useTranslations();

  let firstLineDisplay = '';
  const firstName = patient?.firstName || '';
  const lastName = patient?.lastName || '';
  firstLineDisplay = firstName + ' ' + lastName;
  const firstInitial = firstName.length > 0 ? firstName[0].toUpperCase() : 'N';
  const lastInitial = lastName.length > 0 ? lastName[0].toUpperCase() : 'N';
  const avatar = firstInitial + lastInitial;

  const methods = useForm({
    defaultValues: {
      startDate: subtractNMonths(1),
      endDate: new Date(),
      program: '',
    },
  });

  const watchedStartDate = useWatch({
    control: methods.control,
    name: 'startDate',
  });
  const watchedEndDate = useWatch({control: methods.control, name: 'endDate'});
  const watchedProgram = useWatch({control: methods.control, name: 'program'});

  return (
    <FormProvider {...methods}>
      <ScrollView style={[styles.padding]}>
        {isSafari ? (
          <Text style={[Typography.P2, styles.paddingBottom]}>
            {translations('use_chrome')}
          </Text>
        ) : (
          <></>
        )}
        <View
          style={[
            styles.container,
            styles.justifySpaceBetween,
            styles.alignCenter,
          ]}>
          <View style={[styles.row, styles.alignCenter]}>
            <RHAvatar
              label={avatar}
              color={Colors.RAVEN_BLACK}
              textColor={Colors.RAVEN_WHITE}
              size={32}
            />
            <View style={[styles.marginLLeft]}>
              <Text style={[Typography.P2_BOLD, styles.textColorPrimary]}>
                {firstLineDisplay}
              </Text>
            </View>
          </View>
          <Separator width={20} height={20} />
          {patient?.id ? (
            <View style={[styles.container]}>
              <DateLayout
                name={'startDate'}
                label={'Start Date'}
                placeholder={''}
                helper={''}
              />
              <Separator width={20} />
              <DateLayout
                name={'endDate'}
                label={'End Date'}
                placeholder={''}
                helper={''}
              />
            </View>
          ) : (
            <></>
          )}
        </View>
        {patient?.id ? (
          <ClientReport
            startDate={moment(watchedStartDate).format()}
            endDate={moment(watchedEndDate).format()}
            client={patient?.id}
            selectedProgramId={watchedProgram}
          />
        ) : (
          <></>
        )}
      </ScrollView>
    </FormProvider>
  );
}

export default PatientSummary;
