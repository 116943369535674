import React, {useState} from 'react';
import {View, Text, TouchableOpacity, ScrollView} from 'react-native';
import {RadioButton, Searchbar} from 'react-native-paper';
import {Typography, Colors} from 'src/styles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import SVG from 'src/styles/svg';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {ProgramProfile} from 'src/modules';
import {useDimensions} from '@react-native-community/hooks';
import EStyleSheet from 'react-native-extended-stylesheet';
import replace from 'src/navigation/utils/replace';
import {boxShadow} from 'src/styles/mixins';
import ProgramList from 'src/modules/programs/components/program-list';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Program, ProgramPrompt, ProgramTag, Target} from 'src/models';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import ProgramFilterMenu from '../../components/filter-menu';
import ProgramFilterChipIndicator from '../../components/filter-chip-indicator';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';

const ProgramBuilder = ({navigation, route, tags}: any) => {
  const dimensions = useDimensions();
  const patientId = route.params?.patientId || null;

  const database = useDatabase();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [searchStr, setSearchStr] = useState('');
  const [filters, setFilters] = useState<any>({
    programType: [],
    programStatus: [],
    programState: [],
    assessments: [],
    skills: [],
    behaviors: [],
    relatedServices: [],
  });
  const [selectedType, setSelectedType] = useState('');
  const [selectedPrograms, setSelectedPrograms] = useState<any[]>([]);

  const selectType = (type: string) => {
    setSelectedType(type);
    setSelectedPrograms([]);
  };

  const toggle = (item: any) => {
    let copiedPrograms = [...selectedPrograms];
    let index = selectedPrograms.findIndex(v => {
      return v.id === item.id;
    });
    if (index !== -1) {
      copiedPrograms.splice(index, 1);
    } else {
      copiedPrograms = [...copiedPrograms, item];
    }
    setSelectedPrograms(copiedPrograms);
    setSelectedType('');
  };

  const defaultProgramValue = (type: string) => {
    switch (type) {
      case 'skill':
        return {
          name: 'New Skill Program',
          objective: '',
          stimulusDescription: '',
          teachingInstructions: '',
          materialsNeeded: '',
          numberOfTrials: 10,
          interval: 0,
          intervalAutomation: false,
          intervalOccurs: 0,
          intervalRecurrences: 0,
          type: 'skill',
          method: 'trial_by_trial',
          unit: 'target',
          baseline: false,
          baselineProbes: 3,
          baselineCorrectProbes: 3,
          masteryValue: 80,
          masterySessions: 3,
          masteryStaff: false,
          masteryStaffMinimum: 2,
          maintenance: false,
          maintenanceValue: 2,
          maintenanceSessions: 0,
          maintenanceCadence: 'weekly',
          prompts: [],
          tags: [],
          targets: [],
        };
      default:
        return {
          name: 'New Behavior Program',
          objective: '',
          stimulusDescription: '',
          teachingInstructions: '',
          materialsNeeded: '',
          numberOfTrials: 10,
          interval: 0,
          intervalAutomation: false,
          intervalOccurs: 60,
          intervalRecurrences: 0,
          intensity: false,
          type: 'behavior',
          method: 'frequency',
          unit: 'per_session',
          baseline: false,
          baselineProbes: 0,
          baselineCorrectProbes: 0,
          baselineSessions: 3,
          masteryValue: 5,
          masterySessions: 3,
          masteryStaff: false,
          masteryStaffMinimum: 2,
          maintenance: false,
          maintenanceValue: 0,
          maintenanceSessions: 2,
          maintenanceCadence: 'weekly',
          prompts: [],
          tags: [],
          targets: [],
        };
    }
  };

  const cancel = () => {
    setSelectedPrograms([]);
    setSelectedType('');
  };

  const next = async () => {
    if (selectedPrograms.length > 1) {
      await database?.write(async () => {
        for (const program of selectedPrograms) {
          const response = await database?.get(Program.table).create(entity => {
            entity.partition = selectedGroup;
            entity.type = program.type;
            entity.method = program.method;
            entity.unit = program.unit;
            entity.name = program.name + ' - Copy';
            entity.objective = program.objective;
            entity.stimulusDescription = program.stimulusDescription;
            entity.teachingInstructions = program.teachingInstructions;
            entity.materialsNeeded = program.materialsNeeded;
            entity.numberOfTrials = program.numberOfTrials;
            entity.interval = program.interval;
            entity.intervalAutomation = program.intervalAutomation;
            entity.intervalOccurs = program.intervalOccurs;
            entity.intervalRecurrences = program.intervalRecurrences;
            entity.intensity = program.intensity;
            entity.baseline = program.baseline;
            entity.baselineProbes = program.baselineProbes;
            entity.baselineCorrectProbes = program.baselineCorrectProbes;
            entity.masteryValue = program.masteryValue;
            entity.masterySessions = program.masterySessions;
            entity.masteryStaff = program.masteryStaff;
            entity.masteryStaffMinimum = program.masteryStaffMinimum;
            entity.maintenance = program.maintenance;
            entity.maintenanceValue = program.maintenanceValue;
            entity.maintenanceSessions = program.maintenanceSessions;
            entity.maintenanceCadence = program.maintenanceCadence;
            entity.state = 'active';
            if (_.isEmpty(patientId)) {
              entity.patient.set(null);
            } else {
              entity.patient.id = patientId;
            }
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
          for (const tag of await program.tags.fetch()) {
            await database.get(ProgramTag.table).create(entity => {
              entity.partition = selectedGroup;
              entity.tag.id = tag.id;
              entity.program.id = response.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          for (const prompt of await program.prompts.fetch()) {
            await database.get(ProgramPrompt.table).create(entity => {
              entity.partition = selectedGroup;
              entity.prompt.id = prompt.id;
              entity.program.id = response.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          for (const target of await program.targets.fetch()) {
            await database.get(Target.table).create(entity => {
              entity.partition = selectedGroup;
              entity.program.id = response.id;
              entity.name = target.name;
              entity.rank = target.rank;
              entity.enabled = target.enabled;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
        }
      });

      selectType('');
      navigation.goBack();
    } else {
      let response;
      if (selectedPrograms.length === 1) {
        const program = selectedPrograms[0];
        await database?.write(async () => {
          response = await database?.get(Program.table).create(entity => {
            entity.partition = selectedGroup;
            entity.type = program.type;
            entity.method = program.method;
            entity.unit = program.unit;
            entity.name = program.name + ' - Copy';
            entity.objective = program.objective;
            entity.stimulusDescription = program.stimulusDescription;
            entity.teachingInstructions = program.teachingInstructions;
            entity.materialsNeeded = program.materialsNeeded;
            entity.numberOfTrials = program.numberOfTrials;
            entity.interval = program.interval;
            entity.intervalAutomation = program.intervalAutomation;
            entity.intervalOccurs = program.intervalOccurs;
            entity.intervalRecurrences = program.intervalRecurrences;
            entity.intensity = program.intensity;
            entity.baseline = program.baseline;
            entity.baselineProbes = program.baselineProbes;
            entity.baselineCorrectProbes = program.baselineCorrectProbes;
            entity.masteryValue = program.masteryValue;
            entity.masterySessions = program.masterySessions;
            entity.masteryStaff = program.masteryStaff;
            entity.masteryStaffMinimum = program.masteryStaffMinimum;
            entity.maintenance = program.maintenance;
            entity.maintenanceValue = program.maintenanceValue;
            entity.maintenanceSessions = program.maintenanceSessions;
            entity.maintenanceCadence = program.maintenanceCadence;
            entity.state = 'active';
            if (_.isEmpty(patientId)) {
              entity.patient.set(null);
            } else {
              entity.patient.id = patientId;
            }
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
          for (const tag of await program.tags.fetch()) {
            await database.get(ProgramTag.table).create(entity => {
              entity.partition = selectedGroup;
              entity.tag.id = tag.id;
              entity.program.id = response.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          for (const prompt of await program.prompts.fetch()) {
            await database.get(ProgramPrompt.table).create(entity => {
              entity.partition = selectedGroup;
              entity.prompt.id = prompt.id;
              entity.program.id = response.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          for (const target of await program.targets.fetch()) {
            await database.get(Target.table).create(entity => {
              entity.partition = selectedGroup;
              entity.program.id = response.id;
              entity.name = target.name;
              entity.rank = target.rank;
              entity.enabled = target.enabled;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
        });
      } else {
        const program = defaultProgramValue(selectedType);
        await database?.write(async () => {
          response = await database?.get(Program.table).create(entity => {
            entity.partition = selectedGroup;
            entity.type = program.type;
            entity.method = program.method;
            entity.unit = program.unit;
            entity.name = program.name;
            entity.objective = program.objective;
            entity.stimulusDescription = program.stimulusDescription;
            entity.teachingInstructions = program.teachingInstructions;
            entity.materialsNeeded = program.materialsNeeded;
            entity.numberOfTrials = program.numberOfTrials;
            entity.interval = program.interval;
            entity.intervalAutomation = program.intervalAutomation;
            entity.intervalOccurs = program.intervalOccurs;
            entity.intervalRecurrences = program.intervalRecurrences;
            entity.intensity = program.intensity;
            entity.baseline = program.baseline;
            entity.baselineProbes = program.baselineProbes;
            entity.baselineCorrectProbes = program.baselineCorrectProbes;
            entity.masteryValue = program.masteryValue;
            entity.masterySessions = program.masterySessions;
            entity.masteryStaff = program.masteryStaff;
            entity.masteryStaffMinimum = program.masteryStaffMinimum;
            entity.maintenance = program.maintenance;
            entity.maintenanceValue = program.maintenanceValue;
            entity.maintenanceSessions = program.maintenanceSessions;
            entity.maintenanceCadence = program.maintenanceCadence;
            entity.state = 'active';
            if (_.isEmpty(patientId)) {
              entity.patient.set(null);
            } else {
              entity.patient.id = patientId;
            }
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        });
      }

      selectType('');
      navigation.dispatch(
        replace(ProgramProfile.screenName, {
          programId: response.id,
          edit: true,
          screen: 'Summary',
        }),
      );
    }
  };

  const styles = getStyles(dimensions.window.width);

  return (
    <View style={[styles.flex]}>
      <ScrollView>
        <View style={styles.marginVertical}>
          <View style={[styles.marginHorizontal]}>
            <Text style={Typography.H4}>Start from Scratch</Text>
            <Text style={styles.textSubHeader}>
              Select The Type Of Program You’d Like To Create
            </Text>
          </View>

          <View style={[styles.programTypes]}>
            <View
              style={[
                styles.card,
                selectedType === 'skill' ? styles.toggled : {},
              ]}>
              <TouchableOpacity onPress={() => selectType('skill')}>
                <View
                  style={[
                    styles.cardHeader,
                    styles.row,
                    styles.justifySpaceBetween,
                  ]}>
                  <View style={[styles.row, styles.alignContentCenter]}>
                    <SVG height={38} name={'skill'} style={styles.cardIcon} />
                    <Text style={styles.cardTitle}>Skill Acquisition</Text>
                  </View>
                  <RadioButton.Android
                    value={'skill'}
                    uncheckedColor={Colors.PRIMARY_200}
                    color={Colors.BLUE_700}
                    status={selectedType === 'skill' ? 'checked' : 'unchecked'}
                    style={styles.cardRadio}
                    onPress={() => selectType('skill')}
                  />
                </View>
                <Text style={styles.cardSubText}>
                  Typically best suited for when you need to group targets
                  together. Measure these skill-oriented targets collectively or
                  individually, customize response data, and control collection
                  methods when members use them during Sessions.
                </Text>
                <Text style={[styles.cardSubText, styles.marginVertical]}>
                  Helpful for collecting data such as:
                </Text>
                <View style={styles.cardDataContainer}>
                  <View style={styles.cardData}>
                    <Icon
                      name={'check'}
                      size={25}
                      color={'#8D96E8'}
                      style={styles.cardDataCheck}
                    />
                    <Text style={styles.cardDataText}>
                      Discrete Trial by Trial (DTT)
                    </Text>
                  </View>
                  <View style={styles.cardData}>
                    <Icon
                      name={'check'}
                      size={25}
                      color={'#8D96E8'}
                      style={styles.cardDataCheck}
                    />
                    <Text style={styles.cardDataText}>
                      Total Task Analysis (TTA)
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>

            <View
              style={[
                styles.card,
                selectedType === 'behavior' ? styles.toggled : {},
              ]}>
              <TouchableOpacity onPress={() => selectType('behavior')}>
                <View
                  style={[
                    styles.cardHeader,
                    styles.row,
                    styles.justifySpaceBetween,
                  ]}>
                  <View style={[styles.row, styles.alignContentCenter]}>
                    <SVG
                      height={38}
                      name={'behavior'}
                      style={styles.cardIcon}
                    />
                    <Text style={styles.cardTitle}>Behavior Tracking</Text>
                  </View>

                  <RadioButton.Android
                    value={'behavior'}
                    uncheckedColor={Colors.PRIMARY_200}
                    color={Colors.BLUE_700}
                    status={
                      selectedType === 'behavior' ? 'checked' : 'unchecked'
                    }
                    style={styles.cardRadio}
                    onPress={() => selectType('behavior')}
                  />
                </View>
                <Text style={styles.cardSubText}>
                  The easiest way to track every instance of a behavior. With a
                  simple way to track the duration and frequency of any
                  behavior, criteria can be set up during the building process
                  or after establishing a baseline with the patient.
                </Text>
                <Text style={[styles.cardSubText, styles.marginVertical]}>
                  Helpful for collecting data such as:
                </Text>
                <View style={[styles.column]}>
                  <View style={[styles.row, styles.justifySpaceBetween]}>
                    <View style={[styles.cardData, styles.width120]}>
                      <Icon
                        name={'check'}
                        size={25}
                        color={'#4DB6C3'}
                        style={styles.cardDataCheck}
                      />
                      <Text style={styles.cardDataText}>Duration</Text>
                    </View>
                    <View style={[styles.cardData, styles.width120]}>
                      <Icon
                        name={'check'}
                        size={25}
                        color={'#4DB6C3'}
                        style={styles.cardDataCheck}
                      />
                      <Text style={styles.cardDataText}>Frequency</Text>
                    </View>
                  </View>
                  <View style={[styles.row, styles.justifySpaceBetween]}>
                    <View style={[styles.cardData, styles.width120]}>
                      <Icon
                        name={'check'}
                        size={25}
                        color={'#4DB6C3'}
                        style={styles.cardDataCheck}
                      />
                      <Text style={styles.cardDataText}>Rate</Text>
                    </View>
                    <View style={[styles.cardData, styles.width120]}>
                      <Icon
                        name={'check'}
                        size={25}
                        color={'#4DB6C3'}
                        style={styles.cardDataCheck}
                      />
                      <Text style={styles.cardDataText}>Interval</Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <View style={[styles.marginHorizontal, styles.marginVertical]}>
          <Text style={Typography.H4}>Choose from a Template</Text>
          <Text style={styles.textSubHeader}>
            Start with existing treatment description and instructions
          </Text>
        </View>

        <View
          style={[
            styles.marginHorizontal,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignItemCenter,
          ]}>
          <Searchbar
            value={searchStr}
            onChangeText={setSearchStr}
            placeholder={'Search'}
            style={styles.searchBar}
          />
          <ProgramFilterMenu applyFilters={setFilters} filter={filters} />
        </View>
        <ProgramFilterChipIndicator
          filters={filters}
          setFilters={setFilters}
          tags={tags}
        />
        <ProgramList
          search={searchStr}
          filter={filters}
          toggleable={true}
          toggled={(item: any) =>
            selectedPrograms.findIndex(v => {
              return v.id === item.id;
            }) !== -1
          }
          viewCallback={toggle}
        />
      </ScrollView>
      {selectedType !== '' || selectedPrograms.length > 0 ? (
        <View
          style={[
            styles.flex,
            styles.paddingHorizontal,
            styles.affix,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignContentCenter,
          ]}>
          <RHButton
            secondary
            mode="outlined"
            textColor={Colors.RAVEN_BLACK}
            onPress={() => cancel()}
            style={styles.button}>
            Clear Selection
          </RHButton>
          <View style={[styles.column, styles.justifyCenter]}>
            <RHButton
              onPress={() => next()}
              mode={'contained'}
              icon={
                selectedPrograms.length > 1 ? 'content-copy' : 'arrow-right'
              }
              secondary>
              {selectedPrograms.length > 1
                ? `Copy ${selectedPrograms.length} Selected`
                : 'Next'}
            </RHButton>
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

ProgramBuilder.screenName = 'ProgramBuilder';
ProgramBuilder.title = 'Builder';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStyles = (width: number) =>
  EStyleSheet.create({
    container: {
      flex: 1,
    },
    flex: {
      flex: 1,
    },
    column: {
      flexDirection: 'column',
    },
    row: {
      flexDirection: 'row',
    },
    programTypes: {
      flexDirection: 'row',
    },
    justifySpaceBetween: {
      justifyContent: 'space-between',
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    alignItemCenter: {
      alignItems: 'center',
    },
    alignContentCenter: {
      alignContent: 'center',
    },
    rowReverse: {flexDirection: 'row-reverse'},
    marginVertical: {marginVertical: 20},
    marginTop: {marginTop: 20},
    paddingTop: {paddingTop: 20},
    marginHorizontal: {marginHorizontal: 20},
    paddingHorizontal: {paddingHorizontal: 20},
    textSubHeader: {
      ...Typography.LABEL,
      color: Colors.TEXT_SECONDARY,
      marginTop: 8,
    },
    card: {
      flex: 1,
      marginTop: 10,
      padding: 20,
      margin: 20,
      marginBottom: 20,
      backgroundColor: '#ffffff',
      borderRadius: 10,
      ...boxShadow(Colors.RAVEN_BLACK),
    },
    cardHeader: {
      marginBottom: 20,
    },
    cardTitle: {
      ...Typography.H4,
      alignSelf: 'center',
    },
    cardRadio: {
      alignSelf: 'center',
    },
    cardIcon: {
      alignSelf: 'center',
      marginHorizontal: 10,
    },
    cardSubText: {
      ...Typography.P3,
      color: Colors.TEXT_PRIMARY,
    },
    cardDataContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    cardData: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 10,
    },
    cardDataCheck: {
      alignSelf: 'center',
      marginRight: 5,
    },
    cardDataText: {
      ...Typography.P2,
      alignSelf: 'center',
    },
    nextBut: {
      width: 182,
      alignSelf: 'flex-end',
    },
    affix: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'white',
      height: 86,
    },
    toggled: {
      borderColor: Colors.BLUE_700,
      borderWidth: 1,
    },
    width120: {
      width: 120,
    },
    searchBar: {
      flex: 1,
      marginRight: 10,
    },
    '@media (max-width: 768)': {
      programTypes: {
        flexDirection: 'column',
      },
    },
  });

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('rank', Q.asc),
      ),
  })),
)(ProgramBuilder);
