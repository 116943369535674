import React from 'react';
import Payer, {Display as PayerDisplay} from 'src/hook-form-inputs/payer';
import {useFormContext, useWatch} from 'react-hook-form';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Display as PhoneNumberDisplay} from 'src/hook-form-inputs/phone-number';
import {Typography} from 'src/styles';
import {useTranslations} from 'src/providers/translation';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
import PhoneNumber from 'src/hook-form-inputs/phone-number';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {DataItem} from 'src/design-system';
import addressFormat from 'src/common-utils/address-format';

export const PayerFormDisplay = ({values}: any) => {
  const translations = useTranslations();

  return (
    <>
      <PayerDisplay value={values.payer} />
      <DataItem>
        <Text style={[Typography.H6]}>{translations('address')}</Text>
        <Text style={[Typography.P3]}>{addressFormat(values.address)}</Text>
      </DataItem>
      <PhoneNumberDisplay value={values.mobilePhone} />
    </>
  );
};

const PayerForm = ({}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const {control} = useFormContext();

  const payer = useWatch({control, name: 'payer'});

  return (
    <View style={[styles.padding]}>
      <View style={[styles.paddingLBottom]}>
        <Text style={[Typography.H5, styles.textColorPrimary]}>
          {translations('add_payer')}
        </Text>
      </View>
      <Payer />
      <PayerDisplay value={payer} />
      <AddressAutocompleteInput name={'address'} label={'Address'} />
      <PhoneNumber />
      <HookFormInput label={'Legacy ID'} name={'legacyId'} />
    </View>
  );
};

export default PayerForm;
