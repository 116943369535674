import React from 'react';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Claim} from 'src/models';
import {BaseScreen, TabBar} from 'src/design-system';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import ClaimEvents from 'src/modules/billing/components/claim-events';
import ClaimFormReview from 'src/modules/billing/components/claim-form-review';
import ClaimForm from 'src/modules/billing/components/claim-form';

const Navigator = createMaterialTopTabNavigator();

const ClaimProfileScreen = ({route, claim}: any) => {
  return (
    <BaseScreen header={false}>
      <TabBar navigator={Navigator} location={'ClaimProfile'} route={route}>
        <Navigator.Screen name="Claim">
          {() => {
            return claim.status === 'unsubmitted' ||
              claim.status === 'denied' ? (
              <ClaimForm claim={claim} />
            ) : (
              <ClaimFormReview claim={claim} />
            );
          }}
        </Navigator.Screen>
        <Navigator.Screen name="Events">
          {() => <ClaimEvents claim={claim} />}
        </Navigator.Screen>
      </TabBar>
    </BaseScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['route'], ({route, database}: any) => ({
    claim: database.get(Claim.table).findAndObserve(route.params.id),
  })),
)(ClaimProfileScreen);
