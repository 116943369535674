import React, {useReducer} from 'react';
import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import _ from 'lodash';
import {Checkbox, DataTable} from 'react-native-paper';
import {Controller, useForm} from 'react-hook-form';
import Form from 'src/hook-form/form';
import FormInput from 'src/hook-form/form-input';
import {Tooltip} from 'src/design-system';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useStyle} from 'src/providers/style';

interface Props {
  handleSubmit: (values: any, formik: any) => void;
  handleCancel?: () => void;
  initialValues: object;
  validationSchema: object;
  permissions: any;
}
const RoleForm = ({
  handleSubmit: onSubmit,
  handleCancel,
  initialValues,
  permissions,
}: Props) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const styles = useStyle();

  const supportedPermissions = [
    'Create',
    'Edit',
    'Delete',
    'View',
    'Assigned Only',
  ];

  const {control, handleSubmit, getValues, setValue} = useForm({
    defaultValues: initialValues,
  });

  return (
    <View>
      <Form handleSubmit={handleSubmit(onSubmit)} cancelled={handleCancel}>
        <>
          <Controller
            control={control}
            rules={{
              required: 'Role name is required.',
            }}
            render={({field: {onChange, value, ref}}) => (
              <FormInput
                formRef={ref}
                value={value}
                onChange={onChange}
                label={'Role Name'}
                placeholder={'Role Name'}
              />
            )}
            name="name"
            defaultValue=""
          />
          <DataTable.Header
            style={[
              styles.row,
              styles.width,
              styles.padding0Horizontal,
              styles.alignCenter,
              styles.borderBottomWidth0,
            ]}>
            <DataTable.Cell style={[styles.flex2]}>
              <Text style={[Typography.P3_MEDIUM]}>Feature</Text>
            </DataTable.Cell>
            <DataTable.Cell style={[styles.justifyCenter, styles.alignCenter]}>
              <View
                style={[
                  styles.column,
                  styles.justifyCenter,
                  styles.alignCenter,
                ]}>
                <Text style={[Typography.P3_MEDIUM]}>Create</Text>
                <Checkbox.Android
                  disabled={getValues().managed}
                  status={
                    Object.keys(permissions)
                      .filter(
                        entity =>
                          permissions[entity].permissions.indexOf('Create') !==
                          -1,
                      )
                      .map(entity => {
                        return !getValues()[permissions[entity].key + 'Create'];
                      })
                      .some(value => value)
                      ? 'unchecked'
                      : 'checked'
                  }
                  color={Colors.RAVEN_BLACK}
                  uncheckedColor={Colors.RAVEN_BLACK}
                  onPress={() => {
                    if (
                      Object.keys(permissions)
                        .filter(
                          entity =>
                            permissions[entity].permissions.indexOf(
                              'Create',
                            ) !== -1,
                        )
                        .map(entity => {
                          return !getValues()[
                            permissions[entity].key + 'Create'
                          ];
                        })
                        .some(value => value)
                    ) {
                      for (const entity of Object.keys(permissions)) {
                        if (
                          permissions[entity].permissions.indexOf('Create') !==
                          -1
                        ) {
                          setValue(permissions[entity].key + 'Create', true);
                          setValue(permissions[entity].key + 'View', true);
                        }
                      }
                    } else {
                      for (const entity of Object.keys(permissions)) {
                        setValue(permissions[entity].key + 'Create', false);
                      }
                    }

                    forceUpdate();
                  }}
                />
              </View>
            </DataTable.Cell>
            <DataTable.Cell style={[styles.justifyCenter, styles.alignCenter]}>
              <View
                style={[
                  styles.column,
                  styles.justifyCenter,
                  styles.alignCenter,
                ]}>
                <Text style={[Typography.P3_MEDIUM]}>Edit</Text>
                <Checkbox.Android
                  disabled={getValues().managed}
                  status={
                    Object.keys(permissions)
                      .map(entity => {
                        return !getValues()[permissions[entity].key + 'Edit'];
                      })
                      .some(value => value)
                      ? 'unchecked'
                      : 'checked'
                  }
                  color={Colors.RAVEN_BLACK}
                  uncheckedColor={Colors.RAVEN_BLACK}
                  onPress={() => {
                    if (
                      Object.keys(permissions)
                        .map(entity => {
                          return !getValues()[permissions[entity].key + 'Edit'];
                        })
                        .some(value => value)
                    ) {
                      for (const entity of Object.keys(permissions)) {
                        if (
                          permissions[entity].permissions.indexOf('Edit') !== -1
                        ) {
                          setValue(permissions[entity].key + 'Edit', true);
                          setValue(permissions[entity].key + 'View', true);
                        }
                      }
                    } else {
                      for (const entity of Object.keys(permissions)) {
                        setValue(permissions[entity].key + 'Edit', false);
                      }
                    }

                    forceUpdate();
                  }}
                />
              </View>
            </DataTable.Cell>
            <DataTable.Cell style={[styles.justifyCenter, styles.alignCenter]}>
              <View
                style={[
                  styles.column,
                  styles.justifyCenter,
                  styles.alignCenter,
                ]}>
                <Text style={[Typography.P3_MEDIUM]}>Delete</Text>
                <Checkbox.Android
                  disabled={getValues().managed}
                  status={
                    Object.keys(permissions)
                      .map(entity => {
                        return !getValues()[permissions[entity].key + 'Delete'];
                      })
                      .some(value => value)
                      ? 'unchecked'
                      : 'checked'
                  }
                  color={Colors.RAVEN_BLACK}
                  uncheckedColor={Colors.RAVEN_BLACK}
                  onPress={() => {
                    if (
                      Object.keys(permissions)
                        .map(entity => {
                          return !getValues()[
                            permissions[entity].key + 'Delete'
                          ];
                        })
                        .some(value => value)
                    ) {
                      for (const entity of Object.keys(permissions)) {
                        if (
                          permissions[entity].permissions.indexOf('Delete') !==
                          -1
                        ) {
                          setValue(permissions[entity].key + 'Delete', true);
                          setValue(permissions[entity].key + 'View', true);
                        }
                      }
                    } else {
                      for (const entity of Object.keys(permissions)) {
                        setValue(permissions[entity].key + 'Delete', false);
                      }
                    }

                    forceUpdate();
                  }}
                />
              </View>
            </DataTable.Cell>
            <DataTable.Cell style={[styles.justifyCenter, styles.alignCenter]}>
              <View
                style={[
                  styles.column,
                  styles.justifyCenter,
                  styles.alignCenter,
                ]}>
                <Text style={[Typography.P3_MEDIUM]}>View</Text>
                <Checkbox.Android
                  disabled={getValues().managed}
                  status={
                    Object.keys(permissions)
                      .map(entity => {
                        return !getValues()[permissions[entity].key + 'View'];
                      })
                      .some(value => value)
                      ? 'unchecked'
                      : 'checked'
                  }
                  color={Colors.RAVEN_BLACK}
                  uncheckedColor={Colors.RAVEN_BLACK}
                  onPress={() => {
                    if (
                      Object.keys(permissions)
                        .map(entity => {
                          return !getValues()[permissions[entity].key + 'View'];
                        })
                        .some(value => value)
                    ) {
                      for (const entity of Object.keys(permissions)) {
                        setValue(permissions[entity].key + 'View', true);
                      }
                    } else {
                      for (const entity of Object.keys(permissions)) {
                        setValue(permissions[entity].key + 'View', false);
                      }
                    }

                    forceUpdate();
                  }}
                />
              </View>
            </DataTable.Cell>
            <DataTable.Cell style={[styles.justifyCenter, styles.alignCenter]}>
              <View
                style={[styles.row, styles.justifyCenter, styles.alignCenter]}>
                <Tooltip
                  anchor={
                    <Text style={[Typography.P3_MEDIUM]}>
                      Assigned <Icon name={'help-outline'} />
                    </Text>
                  }>
                  <Text style={[Typography.H6, styles.paddingBottom]}>
                    Assigned Only Permissions
                  </Text>
                  <Text style={[Typography.P3]}>
                    Users can only view the associated entities that are
                    assigned to them.
                  </Text>
                </Tooltip>
              </View>
            </DataTable.Cell>
          </DataTable.Header>
          {Object.keys(permissions).map((entity: string, idx) => {
            return (
              <DataTable.Row
                key={`role-form-row-${entity}-${idx}`}
                style={[
                  styles.row,
                  styles.width,
                  styles.padding0Horizontal,
                  styles.alignCenter,
                  styles.borderBottomWidth0,
                  permissions[entity].nested ? {} : styles.borderTop,
                ]}>
                <DataTable.Cell style={[styles.flex2]}>
                  <Text
                    style={[
                      Typography.P3,
                      permissions[entity].nested ? styles.paddingLeft : {},
                    ]}>
                    {_.startCase(permissions[entity].title)}
                  </Text>
                </DataTable.Cell>
                {supportedPermissions.map((permission, index) => {
                  return (
                    <DataTable.Cell
                      key={`role-form-item-${permission}-${index}`}
                      style={[styles.justifyCenter]}>
                      {permissions[entity].permissions.indexOf(permission) !==
                      -1 ? (
                        <Controller
                          control={control}
                          rules={{}}
                          render={({field: {onChange, value}}) => (
                            <Checkbox.Android
                              disabled={getValues().managed}
                              status={value ? 'checked' : 'unchecked'}
                              color={Colors.RAVEN_BLACK}
                              uncheckedColor={Colors.RAVEN_BLACK}
                              onPress={() => {
                                const canDo = !value;
                                onChange(canDo);
                                switch (permission) {
                                  case 'Create':
                                    if (canDo) {
                                      setValue(
                                        permissions[entity].key + 'View',
                                        true,
                                      );
                                    }
                                    break;
                                  case 'Edit':
                                    if (canDo) {
                                      setValue(
                                        permissions[entity].key + 'View',
                                        true,
                                      );
                                    }
                                    break;
                                  case 'Delete':
                                    if (canDo) {
                                      setValue(
                                        permissions[entity].key + 'View',
                                        true,
                                      );
                                    }
                                    break;
                                  case 'View':
                                    break;
                                  case 'Assigned Only':
                                    break;
                                }
                                forceUpdate();
                              }}
                            />
                          )}
                          name={
                            permissions[entity].key +
                            permission.replace(' ', '')
                          }
                          defaultValue=""
                        />
                      ) : (
                        <></>
                      )}
                    </DataTable.Cell>
                  );
                })}
              </DataTable.Row>
            );
          })}
        </>
      </Form>
    </View>
  );
};

export default RoleForm;
