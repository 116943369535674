import React from 'react';
import {View} from 'react-native';
import {TaskAnalysis, TrialByTrial} from 'src/modules/session/components';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {useStyle} from 'src/providers/style';

const Skill = ({program, session, sessionProgram}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.card]}>
      {program?.method === 'trial_by_trial' ? (
        <TrialByTrial
          program={program}
          session={session}
          sessionProgram={sessionProgram}
        />
      ) : (
        <TaskAnalysis
          program={program}
          session={session}
          sessionProgram={sessionProgram}
        />
      )}
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({sessionProgram}: any) => {
    return {
      program: sessionProgram.program,
    };
  }),
)(Skill);
