import React from 'react';
import {useGoogleLogin} from '@react-oauth/google';
import GoogleButton from 'src/modules/authentication/components/google-button';

const GoogleSignIn = ({submit}: any) => {
  const login = useGoogleLogin({
    onSuccess: submit,
    flow: 'auth-code',
  });

  return <GoogleButton login={login} />;
};

export default GoogleSignIn;
