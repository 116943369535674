import React, {useState} from 'react';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import Spinner from 'react-native-loading-spinner-overlay';
import * as Yup from 'yup';
import {Modal} from 'src/design-system';
import Form from 'src/hook-form/form';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import Client from 'src/hook-form-inputs/client';
import Location from 'src/hook-form-inputs/location';
import Cpt from 'src/hook-form-inputs/cpt';
import NoteTemplate from 'src/hook-form-inputs/note-template';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Appointment,
  NoteTemplateVersion,
  Participant,
  Session,
} from 'src/models';
import {Q} from '@nozbe/watermelondb';
import Address from 'src/hook-form-inputs/address';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';

const QuickSession = ({navigation}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const navigateBack = (values: any) => {
    methods.reset();
    setShow(false);
    navigation.navigate('ScheduleAppointment', {
      location: values.location,
      patient: values.clients,
    });
  };

  const initialValues = {
    clients: '',
    location: '',
    noteTemplate: '',
    address: '',
    cpt: [],
  };

  const validationSchema = Yup.object({
    clients: Yup.string().required('Client is required'),
    location: Yup.string().required('Place of Service is required'),
    noteTemplate: Yup.string().required('Note Template is required'),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const patientId = methods.watch('clients');

  const handleSubmit = async (values: any) => {
    setLoading(true);
    let noteTemplate: any;
    const noteTemplateVersions = await database
      .get(NoteTemplateVersion.table)
      .query(
        Q.where('note_template_id', values.noteTemplate),
        Q.sortBy('published_at', 'desc'),
        Q.take(1),
      );
    if (noteTemplateVersions.length) {
      noteTemplate = noteTemplateVersions[0];
    }
    let finalizedSession = await database?.write(async () => {
      const createAppointment = await database
        ?.get(Appointment.table)
        .create(createdAppointment => {
          createdAppointment.partition = selectedGroup;
          createdAppointment.location = values.location;
          createdAppointment.address = values.address;
          createdAppointment.customAddress = values.customAddress;
          createdAppointment.date = new Date();
          createdAppointment.start = new Date();
          createdAppointment.end = new Date(new Date().getTime() + 30 * 60000);
          createdAppointment.noteTemplateId = noteTemplate.noteTemplateId;
          createdAppointment.createdBy = userId;
          createdAppointment.updatedBy = userId;
        });
      await database?.get(Participant.table).create(entity => {
        entity.partition = selectedGroup;
        entity.billable = true;
        entity.startTime = new Date();
        entity.endTime = new Date(new Date().getTime() + 30 * 60000);
        entity.cpt = values.cpt;
        entity.appointment.id = createAppointment.id;
        entity.userId = userId;
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
      await database?.get(Participant.table).create(entity => {
        entity.partition = selectedGroup;
        entity.startTime = new Date();
        entity.endTime = new Date(new Date().getTime() + 30 * 60000);
        entity.appointment.id = createAppointment.id;
        entity.patient.id = values.clients;
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
      return await database?.get(Session.table).create(entity => {
        entity.partition = selectedGroup;
        entity.type = 'session';
        entity.date = new Date();
        entity.sessionDate = moment().format('YYYY-MM-DD');
        entity.startTimestamp = null;
        entity.endTimestamp = null;
        entity.editedStartTimestamp = null;
        entity.editedEndTimestamp = null;
        entity.note = noteTemplate.template;
        entity.noteTemplateId = values.noteTemplate;
        entity.noteTemplateVersionId = noteTemplate.id;
        entity.address = values.address;
        entity.customAddress = values.customAddress;
        entity.additionalPostalCode = values.additionalPostalCode;
        entity.therapistSignature = null;
        entity.therapistSignature2 = null;
        entity.parentSignature1 = null;
        entity.parentSignature2 = null;
        entity.attestationTimestamp = null;
        entity.submissionTimestamp = null;
        entity.appointment.id = createAppointment.id;
        entity.patient.id = values.clients;
        entity.user.id = null;
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
    });
    setLoading(false);
    setShow(false);
    methods.reset();
    navigation.navigate('SessionCollect', {
      id: finalizedSession.id,
    });
  };
  const location = useWatch({
    control: methods.control,
    name: 'location',
  });
  const watchedAddress = useWatch({
    control: methods.control,
    name: 'address',
  });

  return (
    <>
      <Modal show={[show, setShow]} title={translations('create_session')}>
        <Spinner visible={loading} />
        <FormProvider {...methods}>
          <Form
            handleSubmit={methods.handleSubmit(handleSubmit)}
            cancelled={() => navigateBack(methods.getValues())}
            cancelText={translations('schedule_later')}
            saveText={translations('schedule_now')}>
            <>
              <Client multiple={false} />
              <Location />
              <Address
                patientId={
                  location === '12' || location === '10' ? patientId : undefined
                }
                combineLocations={location === '10' && patientId}
              />
              {watchedAddress === 'other' ? (
                <AddressAutocompleteInput
                  name={'customAddress'}
                  label={'Address Line 1'}
                />
              ) : null}
              <Cpt multiple={true} />
              <NoteTemplate />
            </>
          </Form>
        </FormProvider>
      </Modal>
      <RHButton
        style={[styles.marginTop]}
        onPress={() => setShow(true)}
        mode={'contained'}
        secondary>
        {translations('create_new_session')}
      </RHButton>
    </>
  );
};

export default QuickSession;
