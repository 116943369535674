import {Typography, Colors} from 'src/styles';
import React from 'react';
import {StyleSheet} from 'react-native';
import {Button} from 'react-native-paper';

const RHButton = ({loading = false, ...props}: any) => {
  const textColor = {color: props.textColor || Colors.RAVEN_WHITE};
  const textStyle = props.secondary
    ? Typography.SECONDARY_BUTTON
    : Typography.PRIMARY_BUTTON;

  const outlined =
    props.mode === 'outlined' && !props.disabled
      ? styles({color: props.color}).outlined
      : '';

  // here we are removing the style props via destructuring as to
  // not create any unintended bugs with destructured style props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {style, ...defaultProps} = props;

  return (
    <Button
      loading={loading}
      style={[outlined, props.style]}
      labelStyle={textColor}
      theme={{
        colors: {primary: props.color || Colors.RAVEN_BLACK},
        roundness: 40,
        fonts: {medium: textStyle},
      }}
      {...defaultProps}
    />
  );
};

const styles = ({color}: any) =>
  StyleSheet.create({
    outlined: {
      borderColor: color,
      borderWidth: 1,
      alignSelf: 'center',
    },
  });

export default RHButton;
