import React from 'react';
import {BaseScreen} from 'src/design-system';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {SessionMessage} from 'src/modules/session/components';
import {Session} from 'src/models';
import {ScrollView} from 'react-native';
import {useStyle} from 'src/providers/style';

const SessionMessageScreen = ({session}: any) => {
  const styles = useStyle();
  return (
    <BaseScreen header={false} spinner={!session}>
      <ScrollView
        style={[styles.width, styles.paddingHorizontal]}
        contentContainerStyle={[styles.affixPadding]}>
        <SessionMessage session={session} />
      </ScrollView>
    </BaseScreen>
  );
};

export default compose(
  withState,
  withDatabase,
  withObservables([], ({route, database}: any) => ({
    session: database.get(Session.table).findAndObserve(route.params?.id),
  })),
)(SessionMessageScreen);
