import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import {Separator} from 'src/common-components/atoms';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useFormContext, useWatch} from 'react-hook-form';
import {IconButton} from 'react-native-paper';
import {useContext} from 'react';
import {FormContext} from 'src/modules/form-builder/context/form-context';
import {uuid} from 'src/common-utils/uuid';
import ListItemSeparator from 'src/common-components/separator';
import {formInputs} from 'src/modules/form-builder/components/inputs';

const ConfigurationSideBar = ({editField}: any) => {
  const styles = useStyle();
  const {handleSetEditField} = useContext(FormContext);
  const {setValue, control} = useFormContext();

  const watchedOptions = useWatch({
    name: `${editField?.rowId}.items.${editField?.index}.options`,
    control,
  });

  return (
    <View style={[styles.height, styles.flex, styles.paddingLLeft]}>
      <View
        style={[styles.row, styles.justifySpaceBetween, styles.alignCenter]}>
        <Text style={[Typography.H3, styles.paddingTop, styles.paddingMBottom]}>
          Edit
        </Text>
        {editField ? (
          <IconButton
            size={20}
            icon="close"
            color={Colors.PRIMARY_900}
            onPress={() => {
              handleSetEditField(null);
            }}
          />
        ) : (
          <></>
        )}
      </View>

      <Text style={[Typography.P4, styles.textColorSecondary]}>
        {!editField
          ? 'Select a field to edit content'
          : editField?.isRow
          ? 'You are editing the Section header. Section headers are not required.'
          : formInputs[editField.componentType].text}
      </Text>
      {editField ? (
        <View style={[styles.marginVertical]}>
          {editField?.isRow ? (
            <HookFormInput
              placeholder={''}
              label={''}
              name={`${editField?.rowId}.header`}
              selectTextOnFocus={true}
            />
          ) : (
            <>
              {editField?.componentType !== 'TEXT' ? (
                <HookFormSwitchInput
                  placeholder={'Required'}
                  label={'Required'}
                  name={`${editField?.rowId}.items.${editField?.index}.required`}
                />
              ) : (
                <></>
              )}
              <HookFormInput
                placeholder={'Label'}
                label={'Label'}
                name={`${editField?.rowId}.items.${editField?.index}.label`}
                selectTextOnFocus={true}
                numberOfLines={3}
              />
              {watchedOptions?.length ? (
                <>
                  <ListItemSeparator />
                  <Text
                    style={[
                      Typography.P4,
                      styles.textColorSecondary,
                      styles.paddingMVertical,
                    ]}>
                    List Items:
                  </Text>
                </>
              ) : (
                <></>
              )}
              {watchedOptions?.map((item: any, optionsIndex: number) => (
                <View key={`option-${optionsIndex}`} style={[styles.row]}>
                  <View style={[styles.flex, styles.marginMRight]}>
                    <HookFormInput
                      required={optionsIndex < 1}
                      label={'Option Label'}
                      name={`${editField?.rowId}.items.${editField?.index}.options.${optionsIndex}.label`}
                      selectTextOnFocus={true}
                    />
                  </View>
                  <View style={[styles.paddingBottom, styles.justifyCenter]}>
                    <View style={[styles.width20]}>
                      <Icon
                        name={'minus-circle-outline'}
                        size={20}
                        color={Colors.RAVEN_BLACK}
                        onPress={() => {
                          const updatedOptions = [...watchedOptions];
                          updatedOptions.splice(optionsIndex, 1);
                          setValue(
                            `${editField?.rowId}.items.${editField?.index}.options`,
                            updatedOptions,
                          );
                        }}
                      />
                    </View>
                  </View>
                </View>
              ))}
              {editField?.options?.length ? (
                <TouchableOpacity
                  style={[styles.row, styles.paddingSMLeft]}
                  onPress={() => {
                    setValue(
                      `${editField?.rowId}.items.${editField?.index}.options`,
                      [
                        ...watchedOptions,
                        {
                          value: uuid(),
                          label: '',
                        },
                      ],
                    );
                  }}>
                  <Text style={[Typography.P3_BOLD, styles.marginAutoTop]}>
                    ADD
                  </Text>
                  <Separator width={8} />
                  <Icon
                    name={'plus-circle-outline'}
                    size={24}
                    color={Colors.RAVEN_BLACK}
                  />
                </TouchableOpacity>
              ) : (
                <></>
              )}
            </>
          )}
        </View>
      ) : null}
    </View>
  );
};
export default ConfigurationSideBar;
