import React from 'react';
import {ScrollView, StyleSheet} from 'react-native';

import TrialByTrial from './trialbytrial';
import TaskAnalysis from './taskanalysis';
import MeasurementFrequency from './measurement-frequency';
import MeasurementDuration from './measurement-duration';
import MeasurementInterval from './measurement-interval';
import {useWatch} from 'react-hook-form';
import MeasurementRate from './measurement-rate';

interface Props {
  control: any;
  errors: any;
  edit: boolean;
  values: any;
  programType: string;
  program: any;
}

const ProgramMeasurement = ({
  control,
  errors,
  edit,
  values,
  programType,
  program,
}: Props) => {
  const method = useWatch({control, name: 'method'});
  return (
    <ScrollView
      style={[styles.container]}
      contentContainerStyle={[styles.contentContainer]}>
      {programType === 'skill' ? (
        method === 'trial_by_trial' ? (
          <TrialByTrial control={control} edit={edit} values={values} />
        ) : (
          <TaskAnalysis
            control={control}
            edit={edit}
            values={values}
            program={program}
          />
        )
      ) : method === 'frequency' ? (
        <MeasurementFrequency
          control={control}
          errors={errors}
          edit={edit}
          values={values}
        />
      ) : method === 'interval' ? (
        <MeasurementInterval
          control={control}
          errors={errors}
          edit={edit}
          values={values}
        />
      ) : method === 'duration' ? (
        <MeasurementDuration
          control={control}
          errors={errors}
          edit={edit}
          values={values}
        />
      ) : (
        <MeasurementRate
          control={control}
          errors={errors}
          edit={edit}
          values={values}
        />
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingHorizontal: 20,
    borderRadius: 15,
  },
  contentContainer: {
    paddingVertical: 20,
  },
});

export default ProgramMeasurement;
