import React from 'react';
import {FlatList, Text, TouchableOpacity, View} from 'react-native';
import {Typography} from 'src/styles';
import {useNavigation} from '@react-navigation/core';
import {
  RenderAppointment,
  APPOINTMENTCARDTYPE,
} from 'src/modules/scheduling/components';
import {useStyle} from 'src/providers/style';
import NoResults from 'src/common-components/noResults';
import {useTranslations} from 'src/providers/translation';

const HomeTodaysSessions = ({
  profile = {},
  appointments = [],
  canEdit,
  canDelete,
  canView,
  deleteCallback = () => {},
}: any) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <View
      style={[
        styles.width,
        styles.backgroundColorWhite,
        styles.paddingHorizontal,
        styles.paddingLTop,
        styles.borderRadius,
        styles.elevation,
      ]}>
      <Text style={[Typography.H6, styles.marginMBottom]}>
        {translations('today_sessions')}
      </Text>
      <View style={[styles.separator]} />
      {appointments?.length === 0 ? (
        <>
          <NoResults
            iconName={'calendar-today'}
            secondaryMessage={translations('no_session')}
          />
          <View style={[styles.separator]} />
        </>
      ) : (
        <FlatList
          data={appointments.sort(
            (a: any, b: any) =>
              a.start.getHours() * 3600 +
              a.start.getMinutes() * 60 +
              a.start.getSeconds() -
              (b.start.getHours() * 3600 +
                b.start.getMinutes() * 60 +
                b.start.getSeconds()),
          )}
          keyExtractor={item => item.id}
          renderItem={({item}: any) => {
            return (
              <RenderAppointment
                date={new Date()}
                userId={profile?.id}
                appointment={item}
                type={APPOINTMENTCARDTYPE.DASHBOARD}
                canEdit={canEdit}
                canDelete={canDelete}
                deleteCallback={() => deleteCallback(item)}
              />
            );
          }}
        />
      )}
      {canView ? (
        <TouchableOpacity
          onPress={() => navigation.navigate('Schedule')}
          style={[styles.paddingVertical]}>
          <Text style={[Typography.H6, styles.textAlignCenter]}>
            {translations('view_schedule')}
          </Text>
        </TouchableOpacity>
      ) : (
        <></>
      )}
    </View>
  );
};

export default HomeTodaysSessions;
