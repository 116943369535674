import {text, relation} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Signature extends Base {
  static table = 'signatures';
  static associations = {
    sessions: {type: 'belongs_to', key: 'entity_id'},
  };

  @relation('sessions', 'entity_id') session;

  @text('_partition') partition;
  @text('entity') entity;
  @text('entity_id') entityId;
  @text('signature') signature;
  @text('type') type;
  @text('signatory') signatory;
}
