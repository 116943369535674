import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {analytics} from 'src/providers/segment';
import {authApi} from 'src/services/auth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {setUser} from 'src/common-utils/bugsnag-set-user';

export const login = createAsyncThunk('auth/login', async (args: any) => {
  await AsyncStorage.setItem('userId', args.userId);
  await AsyncStorage.setItem('selectedGroup', args.selectedGroup);

  return args;
});

export const checkLogin = createAsyncThunk('auth/checkLogin', async () => {
  let value = {};

  const userId = await AsyncStorage.getItem('userId');
  if (userId) {
    value = {
      userId,
    };
    setUser(userId);
  }
  const selectedGroup = await AsyncStorage.getItem('selectedGroup');
  if (selectedGroup) {
    value = {
      ...value,
      selectedGroup,
    };
  }

  return value;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  try {
    analytics.track('User Signed Out', {
      groupId: [await AsyncStorage.getItem('selectedGroup')],
    });
    analytics.reset(false);
  } catch (e) {
    console.log('Analytics Error', e);
  }

  try {
    const syncId = (await AsyncStorage.getItem('syncId'))?.toLowerCase();
    await AsyncStorage.clear();
    await AsyncStorage.setItem('syncId', syncId);
  } catch (e) {
    console.log('e', e);
  }

  return {};
});

const auth = createSlice({
  name: 'authentication',
  initialState: {
    userId: undefined,
    selectedGroup: undefined,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, {payload}) => {
      Object.assign(state, payload);
    });
    builder.addCase(checkLogin.fulfilled, (state, {payload}) => {
      Object.assign(state, payload);
    });
    builder.addCase(logout.fulfilled, () => {});
    builder.addMatcher(
      authApi.endpoints.token.matchFulfilled,
      (state, {payload}) => {
        AsyncStorage.setItem('id', payload.uid);
        AsyncStorage.setItem('accessToken', payload.access_token);
        AsyncStorage.setItem('refreshToken', payload.refresh_token);
      },
    );
  },
});

export default auth.reducer;
