import {Q} from '@nozbe/watermelondb';
import {
  children,
  date,
  json,
  lazy,
  relation,
  text,
} from '@nozbe/watermelondb/decorators';
import {endOfDay, startOfDay} from 'date-fns';
import {sanitizeStrings} from 'src/common-utils/sanitize-strings';
import {Base} from 'src/models/base';
import {mergeMap} from 'rxjs';

export class User extends Base {
  static table = 'users';
  static associations = {
    care_team_participants: {type: 'has_many', foreignKey: 'user_id'},
    participants: {type: 'has_many', foreignKey: 'user_id'},
    sessions: {type: 'has_many', foreignKey: 'user_id'},
    roles: {type: 'belongs_to', key: 'role'},
    documents: {type: 'has_many', foreignKey: 'user_id'},
    notifications: {type: 'has_many', foreignKey: 'user_id'},
    credentials: {type: 'has_many', foreignKey: 'user_id'},
    taggables: {type: 'has_many', foreignKey: 'entity_id'},
  };

  @lazy
  patients = this.collections
    .get('patients')
    .query(
      Q.where('deleted_at', null),
      Q.where('state', Q.notEq('inactive')),
      Q.where('state', Q.notEq('archived')),
      Q.on(
        'care_team_participants',
        Q.and(Q.where('user_id', Q.eq(this.id)), Q.where('deleted_at', null)),
      ),
    );

  @lazy
  appointments = this.collections
    .get('appointments')
    .query(
      Q.where('deleted_at', Q.eq(null)),
      Q.on('participants', 'user_id', this.id),
    );

  @lazy
  todaysAppointments = (startDate, endDate) =>
    this.collections
      .get('appointments')
      .query(
        Q.on(
          'participants',
          Q.and(Q.where('user_id', Q.eq(this.id)), Q.where('deleted_at', null)),
        ),
        Q.and(
          Q.where('rrule', Q.eq('')),
          Q.where('date', Q.gte(startDate.getTime())),
          Q.where('date', Q.lt(endDate.getTime())),
          Q.where('deleted_at', Q.eq(null)),
        ),
        Q.sortBy('start', Q.asc),
      );

  @lazy
  recurringAppointments = (startDate, endDate) =>
    this.collections
      .get('appointments')
      .query(
        Q.and(
          Q.where('rrule', Q.notEq('')),
          Q.where('deleted_at', Q.eq(null)),
          Q.or(
            Q.and(
              Q.where('start_timestamp', Q.gt(startDate.getTime())),
              Q.where('start_timestamp', Q.lt(endDate.getTime())),
            ),
            Q.and(
              Q.where('start_timestamp', Q.lt(startDate.getTime())),
              Q.or(
                Q.where('end_timestamp', Q.eq(0)),
                Q.where('end_timestamp', Q.gt(startDate.getTime())),
              ),
            ),
          ),
        ),
        Q.on(
          'participants',
          Q.and(Q.where('user_id', Q.eq(this.id)), Q.where('deleted_at', null)),
        ),
        Q.sortBy('start', Q.asc),
      );

  @lazy
  futureAppointments = this.collections
    .get('appointments')
    .query(
      Q.where('deleted_at', Q.eq(null)),
      Q.on('participants', 'user_id', this.id),
      Q.sortBy('created_at', Q.desc),
      Q.take(1),
    );

  @children('sessions') sessions;

  @lazy
  userSessions = (selectedGroup: string) =>
    this.sessions.extend(
      Q.where('deleted_at', null),
      Q.where('_partition', selectedGroup),
    );

  @lazy
  sessionsCompleted = this.sessions.extend(
    Q.and(
      Q.where('start_timestamp', Q.notEq(null)),
      Q.where('start_timestamp', Q.gt(startOfDay(new Date()).getTime())),
      Q.where('start_timestamp', Q.lt(endOfDay(new Date()).getTime())),
    ),
    Q.and(
      Q.where('end_timestamp', Q.notEq(null)),
      Q.where('end_timestamp', Q.gt(startOfDay(new Date()).getTime())),
      Q.where('end_timestamp', Q.lt(endOfDay(new Date()).getTime())),
    ),
    Q.where('submission_timestamp', Q.notEq(null)),
    Q.where('deleted_at', null),
    Q.where('user_id', this.id),
  );

  @children('documents') documents;

  @lazy activeDocuments = this.documents.extend(Q.where('deleted_at', null));

  @lazy starredDocuments = this.documents.extend(
    Q.where('starred', true),
    Q.where('deleted_at', null),
  );

  @relation('roles', 'role') role;

  @children('notifications') notifications;

  @lazy
  appointmentNotifications = this.notifications.extend(
    Q.where('subject_model', Q.eq('appointments')),
  );

  @lazy
  unreadNotifications = this.notifications.extend(
    Q.where('read_at', Q.eq(null)),
  );

  @children('credentials') userCredential;

  @lazy
  credential = this.userCredential
    .extend(Q.where('deleted_at', Q.eq(null)))
    .observe()
    .pipe(mergeMap(x => x));

  @lazy
  activeTags = this.collections
    .get('tags')
    .query(
      Q.where('enabled', true),
      Q.where('deleted_at', null),
      Q.on(
        'taggables',
        Q.and(Q.where('entity_id', this.id), Q.where('deleted_at', null)),
      ),
    );

  @text('identifier') identifier;
  @text('first_name') firstName;
  @text('middle_name') middleName;
  @text('last_name') lastName;
  @json('credentials', sanitizeStrings) credentials;
  @text('modifier') modifier;
  @text('email') email;
  @text('gender') gender;
  @text('role') roleId;
  @date('birth_date') birthDate;
  @text('permission') permission;
  @text('position') position;
  @text('license_number') licenseNumber;
  @text('mobile_phone') mobilePhone;
  @json('address', value => value) address;
  @text('state') state;
}
