import {
  date,
  field,
  json,
  relation,
  text,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';
import {sanitizeStrings} from 'src/common-utils/sanitize-strings';

export class Participant extends Base {
  static table = 'participants';
  static associations = {
    appointments: {type: 'belongs_to', key: 'appointment_id'},
    users: {type: 'belongs_to', key: 'user_id'},
    patients: {type: 'belongs_to', key: 'patient_id'},
  };

  @relation('appointments', 'appointment_id') appointment;
  @relation('users', 'user_id') user;
  @relation('patients', 'patient_id') patient;

  @text('user_id') userId;
  @text('patient_id') patientId;
  @field('billable') billable;
  @field('supervision') supervision;
  @date('start_time') startTime;
  @date('end_time') endTime;
  @json('cpt', sanitizeStrings) cpt;
}
