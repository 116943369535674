import React, {useState} from 'react';

import {CSVExportInterface} from 'src/common-components/csv-export';
import {IconButton} from 'src/design-system';
import {Colors} from 'src/styles';
import {exportCsv} from 'src/common-utils/export-csv';

const CsvExport = ({data, name}: CSVExportInterface) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const initiateCsvExport = async () => {
    setLoading(true);
    await exportCsv(name, await data());
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  return (
    <IconButton
      loading={loading}
      disabled={loading || success}
      icon={success ? 'check' : 'download'}
      type={'outlined'}
      color={success ? Colors.SUCCESS : Colors.RAVEN_BLACK}
      onPress={() => initiateCsvExport()}
    />
  );
};

export default CsvExport;
