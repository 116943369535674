import {Value as DateValue} from 'src/form-inputs/date-input';
import moment from 'moment/moment';
import {Appointment, Session} from 'src/models';
import {calculateDuration} from 'src/common-utils/duration-calculation';
import * as Type from 'src/hook-form-inputs/type';
import * as Location from 'src/hook-form-inputs/location';
import {Q} from '@nozbe/watermelondb';

export const sessionNoteReplacement = async (
  database: any,
  note: string,
  sessionId: string,
  appointmentId: string,
  appointmentType: string,
  appointmentLocation: string,
  start: Date,
  end: Date,
) => {
  const appointment = await database.get(Appointment.table).find(appointmentId);
  const session = await database.get(Session.table).find(sessionId);
  const staff = await appointment.staff;
  const diagnoses = await database
    .get('instance_diagnoses')
    .query(
      Q.where(
        'id',
        Q.oneOf(
          (
            await (
              await session.patient
            ).activeDiagnoses
          ).map(diagnosis => diagnosis.instanceDiagnosisId),
        ),
      ),
    );

  return note
    .replace(
      /@session.type/g,
      Type.Value(appointmentType)?.split(' - ')?.[1] || '',
    )
    .replace(
      /@session.code/g,
      Type.Value(appointmentType)?.split(' - ')?.[0] || '',
    )
    .replace(/@appointment.location/g, Location.Value(appointmentLocation))
    .replace(/@appointment.date/g, DateValue(session?.sessionDate))
    .replace(/@appointment.startTime24hr/g, moment(start).format('HH:mm'))
    .replace(/@appointment.endTime24hr/g, moment(end).format('HH:mm'))
    .replace(/@appointment.startTime/g, moment(start).format('hh:mm A'))
    .replace(/@appointment.endTime/g, moment(end).format('hh:mm A'))
    .replace(
      /@staffmember.name/g,
      staff
        .map((participant: any) => {
          return participant.firstName + ' ' + participant.lastName;
        })
        .join(', '),
    )
    .replace(
      /@staffmember/g,
      staff
        .map((participant: any) => {
          return participant.firstName + ' ' + participant.lastName;
        })
        .join(', '),
    )
    .replace(
      /@client.diagnoses/g,
      diagnoses
        .map((item: any) => {
          return item.diagnosis;
        })
        .join(', '),
    )
    .replace(/@session.duration/g, calculateDuration(start, end));
};
