import React from 'react';
import {Text, View} from 'react-native';
import {IconButton, Menu} from 'src/design-system';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {Typography} from 'src/styles';

const DiagnosisListItem = ({
  item: diagnosis,
  canDelete,
  deleteCallback,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const options = [];

  options.push({
    onPress: () => deleteCallback(diagnosis.id),
    title: translations('remove_from_list'),
    icon: 'trash-can',
  });

  return (
    <View
      style={[
        styles.row,
        styles.justifySpaceBetween,
        styles.paddingLHorizontal,
      ]}>
      <View style={[styles.row, styles.paddingLRight, styles.marginLVertical]}>
        <View style={[styles.paddingLLeft, styles.justifyCenter]}>
          <Text style={[Typography.P3, styles.textColorSecondary]}>
            {diagnosis.name}
          </Text>
        </View>
      </View>

      {canDelete ? (
        <View style={[styles.alignCenter, styles.justifyCenter]}>
          <Menu
            anchor={<IconButton type={'icon'} icon={'dots-vertical'} />}
            options={options}
          />
        </View>
      ) : null}
    </View>
  );
};

export default DiagnosisListItem;
