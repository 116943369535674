import {Q} from '@nozbe/watermelondb';
import {field, lazy, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Tag extends Base {
  static table = 'tags';
  static associations = {
    program_tag: {type: 'has_many', foreignKey: 'tag_id'},
    taggables: {type: 'has_many', foreignKey: 'tag_id'},
  };

  @lazy
  programs = this.collections
    .get('programs')
    .query(Q.on('program_tag', 'tag_id', this.id));

  @text('tag_type') tagType;
  @text('name') name;
  @text('acronym') acronym;
  @text('rank') rank;
  @field('enabled') enabled;
}
