import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import FormInput from 'src/hook-form-wrapper/form-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'teachingInstructions',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Instructions',
  key,
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Program Instructions</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  return (
    <FormInput
      name={(name ? name + '.' : '') + 'teachingInstructions'}
      label={'Program Instructions'}
      placeholder={'Program Instructions'}
      helper={'Detailed teaching instructions'}
      required={false}
      numberOfLines={5}
    />
  );
};

export const validation = () =>
  Yup.string().required('Program Instructions is required');

export default Input;
