import {Model} from '@nozbe/watermelondb';
import {date, readonly, text, writer} from '@nozbe/watermelondb/decorators';
import {store} from 'src/redux/store';

export class Base extends Model {
  @text('_partition') partition;

  @text('created_by') createdBy;
  @readonly @date('created_at') createdAt;
  @text('updated_by') updatedBy;
  @readonly @date('updated_at') updatedAt;
  @text('deleted_by') deletedBy;
  @date('deleted_at') deletedAt;

  // @writer async createEntity(value: any) {
  //   await this.createEntity(entity => {
  //     Object.assign(entity, value);
  //     entity.createdBy = store.getState().authentication.userId;
  //     entity.updatedBy = store.getState().authentication.userId;
  //   });
  // }

  @writer async updateEntity(value: any) {
    await this.update(entity => {
      Object.assign(entity, value);
      entity.updatedBy = store.getState().authentication.userId;
    });
    // sync(database);
  }

  @writer async delete() {
    await this.update(entity => {
      entity.deletedBy = store.getState().authentication.userId;
      entity.deletedAt = new Date();
    });
    // sync(database);
  }
}
