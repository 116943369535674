import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import _ from 'lodash';
import {IDeveloperField} from 'dromo-uploader-react';
import SelectInput from 'src/hook-form-wrapper/select-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  label?: string;
  multiple?: boolean;
  showHelper?: boolean;
  valueKey?: string;
}
export const cptCodes = [
  {
    value: '00000',
    label: 'Non-Billable',
  },
  {
    value: '92507',
    label: 'Speech/hearing therapy; individual',
  },
  {
    value: '92508',
    label: 'Speech/hearing therapy; group, two or more individuals',
  },
  {
    value: '92522',
    label: 'Evaluate speech production',
  },
  {
    value: '92523',
    label:
      'Evaluation of speech sound production; with evaluation of language comprehension and expression',
  },
  {
    value: '92526',
    label: 'Oral function therapy',
  },
  {value: '92609', label: 'Use of speech device service'},
  {value: '92610', label: 'Evaluate swallowing function'},
  // {value: '97151', label: 'Behavior identification assessment'},
  {value: '97151', label: 'Outcome measures'},
  {value: '97152', label: 'Behavior identification-supporting assessment'},
  {value: '97153', label: 'Adaptive behavior treatment by protocol'},
  {value: '97154', label: 'Group adaptive behavior treatment by protocol'},
  {
    value: '97155',
    label: 'Adaptive behavior treatment with protocol modification',
  },
  {value: '97156', label: 'Family adaptive behavior treatment guidance'},
  {
    value: '97157',
    label: 'Multiple-family group adaptive behavior treatment guidance',
  },
  {
    value: '97158',
    label: 'Group adaptive behavior treatment with protocol modification',
  },
  {value: '99366', label: 'Medical team conference'},
  {value: '99368', label: 'Medical team conference'},
  {value: '0362T', label: 'Behavior identification supporting assessment'},
  {
    value: '0373T',
    label: 'Adaptive behavior treatment with protocol modification',
  },
  {
    value: 'H0032',
    label: 'Mental health service plan development by non-physician',
  },
  {value: 'T1024', label: 'Team evaluation & management'},
  {value: 'T2024', label: 'Assessment/plan of care development'},
  {value: 'H2019', label: 'Therapeutic behavioral services'},
  {value: '90791', label: 'Psych diagnostic evaluation'},
  {value: '90837', label: 'Psytx w pt 60 minutes'},
  {value: 'H0031', label: 'Mental health assessment, by non-physician'},
  {value: 'H2012', label: 'Behavioral health day treatment, per hour'},
  {value: 'S5111', label: 'Home care training, family; per session'},
  {value: 'H2014', label: 'Skills training and development, per 15 minutes'},
  {value: 'H0046', label: 'Mental health services, not otherwise specified'},
];

export const cptCodesFilter = [
  {
    value: '00000',
    label: '00000 - Non-Billable',
  },
  {
    value: '92507',
    label: '92507 - Speech/hearing therapy; individual',
  },
  {
    value: '92508',
    label: '92508 - Speech/hearing therapy; group, two or more individuals',
  },
  {
    value: '92522',
    label: '92522 - Evaluate speech production',
  },
  {
    value: '92523',
    label:
      '92523 - Evaluation of speech sound production; with evaluation of language comprehension and expression',
  },
  {
    value: '92526',
    label: '92526 - Oral function therapy',
  },
  {value: '92609', label: '92609 - Use of speech device service'},
  {value: '92610', label: '92610 - Evaluate swallowing function'},
  {value: '97151', label: '97151 - Outcome measures'},
  {
    value: '97152',
    label: '97152 - Behavior identification-supporting assessment',
  },
  {value: '97153', label: '97153 - Adaptive behavior treatment by protocol'},
  {
    value: '97154',
    label: '97154 - Group adaptive behavior treatment by protocol',
  },
  {
    value: '97155',
    label: '97155 - Adaptive behavior treatment with protocol modification',
  },
  {
    value: '97156',
    label: '97156 - Family adaptive behavior treatment guidance',
  },
  {
    value: '97157',
    label: '97157 - Multiple-family group adaptive behavior treatment guidance',
  },
  {
    value: '97158',
    label:
      '97158 - Group adaptive behavior treatment with protocol modification',
  },
  {value: '99366', label: '99366 - Medical team conference'},
  {value: '99368', label: '99368 - Medical team conference'},
  {
    value: '0362T',
    label: '0362T - Behavior identification supporting assessment',
  },
  {
    value: '0373T',
    label: '0373T - Adaptive behavior treatment with protocol modification',
  },
  {
    value: 'H0032',
    label: 'H0032 - Mental health service plan development by non-physician',
  },
  {value: 'T1024', label: 'T1024 - Team evaluation & management'},
  {value: 'T2024', label: 'T2024 - Assessment/plan of care development'},
  {value: 'H2019', label: 'H2019 - Therapeutic behavioral services'},
  {value: '90791', label: '90791 - Psych diagnostic evaluation'},
  {value: '90837', label: '90837 - Psytx w pt 60 minutes'},
  {value: 'H0031', label: 'H0031 - Mental health assessment, by non-physician'},
  {value: 'H2012', label: 'H2012 - Behavioral health day treatment, per hour'},
  {value: 'S5111', label: 'S5111 - Home care training, family; per session'},
  {
    value: 'H2014',
    label: 'H2014 - Skills training and development, per 15 minutes',
  },
  {
    value: 'H0046',
    label: 'H0046 - Mental health services, not otherwise specified',
  },
];

export const Import = (
  key: string = 'cpt',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Code (${cptCodes.map(value => value.label).join(', ')})`,
  key,
  type: 'select',
  selectOptions: cptCodes,
  validators: [],
});

const resolveDisplayValue = value => {
  const codeObj = cptCodes.find(code => code.value === value);
  return codeObj ? `${codeObj.value} - ${codeObj.label}` : 'Unknown CPT Code';
};

export const Value = (value: string) => {
  return _.capitalize(value);
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('billing_codes')}</Text>
      <Text style={[Typography.P3]}>
        {Array.isArray(value) && value.length > 0
          ? value.map(cptCode => resolveDisplayValue(cptCode)).join('\n')
          : 'No CPT Codes'}
      </Text>
    </DataItem>
  );
};

export const Input = ({
  name,
  label,
  multiple = false,
  showHelper = true,
  valueKey,
}: Props) => {
  const translations = useTranslations();

  return (
    <SelectInput
      name={(name ? name + '.' : '') + 'cpt'}
      label={label ? label : translations('billing_code')}
      placeholder={label ? label : translations('billing_code')}
      multiple={multiple}
      required={false}
      showHelper={showHelper}
      items={cptCodes.map((code: any) => ({
        value: code.value,
        label: valueKey ? `${code[valueKey]}` : `${code.value} - ${code.label}`,
      }))}
    />
  );
};

export const validation = () => Yup.string().required('Code is required');

export default Input;
