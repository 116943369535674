import React, {useMemo, useState} from 'react';
import {BaseIndexScreen} from 'src/design-system';
import {Searchbar} from 'react-native-paper';
import {useNavigation} from '@react-navigation/native';
import {FlatList} from 'react-native';
import NoResults from 'src/common-components/noResults';
import PayerItem from 'src/modules/billing/components/payer-item';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import withState from 'src/redux/wrapper';
import {Q} from '@nozbe/watermelondb';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {DeleteModal} from 'src/design-system';
import {fuzzy} from 'src/common-utils/fuzzy';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {PayerCredential} from 'src/models';

const Payers = ({payers}: any) => {
  const navigation = useNavigation();
  const [searchStr, setSearchStr] = useState<string>('');
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const database = useDatabase();

  const searchedItems = useMemo(() => {
    return payers?.filter((item: any) => {
      return fuzzy(item?.name, searchStr);
    });
  }, [payers, searchStr]);

  const deleteCallback = (item: any) => {
    setShowDelete(item);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = async (item: any) => {
    item.delete();
    const payerCredentials = await database
      .get(PayerCredential.table)
      .query(Q.where('payer_id', Q.eq(item.id)));
    for (const payerCredential of payerCredentials) {
      payerCredential.delete();
    }
    setShowDelete(false);
  };

  return (
    <BaseIndexScreen
      title={'Payers'}
      search={
        <Searchbar
          value={searchStr}
          onChangeText={setSearchStr}
          placeholder={'Search'}
        />
      }
      canAdd={true}
      add={() => navigation.navigate('PayerFormScreen')}>
      <DeleteModal
        model={'Payers'}
        text={showDelete.name}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      {payers.length === 0 ? (
        <NoResults message="No Payers Configured." />
      ) : (
        <FlatList
          scrollEnabled={true}
          data={searchedItems}
          keyExtractor={item => item.id}
          renderItem={({item}: any) => (
            <PayerItem
              item={item}
              canEdit={true}
              editSelected={() => {
                navigation.navigate('PayerFormScreen', {
                  id: item.id,
                });
              }}
              canDelete={true}
              deleteCallback={() => deleteCallback(item)}
            />
          )}
        />
      )}
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    payers: database
      .get('payers')
      .query(
        Q.where('_partition', authentication.selectedGroup),
        Q.where('deleted_at', Q.eq(null)),
      ),
  })),
)(Payers);
