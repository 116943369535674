import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {useTranslations} from 'src/providers/translation';
import HookFormTimerInput from 'src/hook-form-wrapper/timer-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  type?: string;
}

const durationArray = [
  {
    value: 'average_time',
    label: 'Average Time',
  },
  {
    value: 'total_time',
    label: 'Total Time',
  },
];

export const Import = (
  key: string = 'unit',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Duration',
  key,
  type: 'select',
  selectOptions: durationArray.map(item => ({
    ...item,
    value: item.value.toString(),
  })),
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return durationArray.find(item => item.value.toString() === value)?.label;
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('duration')}</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, type}: Props) => {
  return (
    <HookFormTimerInput
      name={(name ? name + '.' : '') + type}
      label={''}
      required={false}
      items={durationArray}
    />
  );
};

export const validation = () => Yup.string().required('Duration is required');

export default Input;
