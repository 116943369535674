import React, {ReactNode} from 'react';
import {StyleSheet, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {Field} from 'formik';
import Dropdown from 'src/hook-form/select-input';

interface Props {
  valueKey: string;
  name: string;
  required?: boolean;
  multiple?: boolean;
  items: {
    label: string;
    value: any | string | number;
    custom?: ReactNode;
  }[];
}

const SelectInput = ({
  valueKey,
  name,
  required = false,
  items = [],
  multiple = false,
}: Props) => {
  return (
    <View style={styles.container}>
      <Field name={valueKey}>
        {({field, meta: {error}}: any) => {
          return (
            <Dropdown
              label={name}
              multiple={multiple}
              required={required}
              searchable={false}
              items={items}
              onChange={field.onChange(field.name)}
              value={
                typeof field.value === 'object'
                  ? field.value.toString()
                  : field.value
              }
              error={error}
            />
          );
        }}
      </Field>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  label: {
    ...Typography.LABEL,
    color: Colors.TEXT_SECONDARY,
  },
  helper: {},
});

export default SelectInput;
