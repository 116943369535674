import React, {useEffect, useState} from 'react';
import {Text, View} from 'react-native';
import * as Yup from 'yup';
import ListItemSeparator from 'src/common-components/separator';
import {Typography} from 'src/styles';
import {CollapsibleFilterItem, Filters} from 'src/design-system';
import {Separator} from 'src/common-components/atoms';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import Form from 'src/hook-form/form';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import CheckboxItemsInput from 'src/hook-form/checkbox-input';
import DateInput from 'src/hook-form/date-input';
import {appointmentLocations} from 'src/form-inputs/appointment-location-input';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {cptCodesFilter} from 'src/hook-form-inputs/cpt';
import StatusCheckbox from 'src/hook-form-inputs/status-checkbox';

const HistoryFilterMenu = ({
  filters,
  applyFilters,
  users = [],
  defaultFilters,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [visible, setVisible] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: filters,
  });
  useEffect(() => {
    methods.reset(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const validationSchema = Yup.object({
    startDate: Yup.date().max(
      Yup.ref('endDate'),
      translations('start_date_end_date_validation'),
    ),
    endDate: Yup.date().min(
      Yup.ref('startDate'),
      translations('end_date_start_date_validation'),
    ),
  });

  const notificationNumberCount = () => {
    const selectedFilter = filters;
    let result = 0;
    for (const key of Object.keys(selectedFilter)) {
      if (Array.isArray(selectedFilter[key])) {
        result += selectedFilter[key].length;
      }
    }
    return result;
  };

  const filterSubmit = (newFilters: any) => {
    applyFilters(newFilters);
    setVisible(false);
  };

  const filterCancelled = () => {
    applyFilters(defaultFilters);
    setVisible(false);
  };

  const closeMenu = () => {
    setVisible(false);
  };

  return (
    <Filters
      open={visible}
      setOpen={setVisible}
      notificationNumberCount={notificationNumberCount}
      onDismiss={closeMenu}>
      <FormProvider {...methods}>
        <Form
          handleSubmit={methods.handleSubmit(filterSubmit, filterCancelled)}
          cancelled={filterCancelled}
          initialValues={filters}
          validationSchema={validationSchema}
          cancelText={translations('reset')}
          saveText={translations('apply')}>
          <Text
            style={[
              Typography.P1_MEDIUM,
              styles.textColorPrimary,
              styles.marginSMBottom,
            ]}>
            Date Range
          </Text>
          <Separator height={10} />
          <View style={[styles.flex, styles.container]}>
            <Controller
              control={methods.control}
              rules={{}}
              render={({field: {onChange, value}}) => (
                <DateInput
                  label={translations('start_date')}
                  value={value}
                  onChange={onChange}
                  required={false}
                  style={styles.flex}
                />
              )}
              name="startDate"
            />
            <Separator width={10} />
            <Controller
              control={methods.control}
              rules={{}}
              render={({field: {onChange, value}}) => (
                <DateInput
                  label={translations('end_date')}
                  value={value}
                  onChange={onChange}
                  required={false}
                  style={styles.flex}
                />
              )}
              name="endDate"
            />
          </View>
          <Separator height={10} />
          <ListItemSeparator />
          {users.length ? (
            <>
              <CollapsibleFilterItem title={translations('staff')}>
                <Controller
                  control={methods.control}
                  rules={{}}
                  render={({field: {onChange, value}}) => {
                    return (
                      <CheckboxItemsInput
                        allowSelectAll={true}
                        value={value}
                        onChange={onChange}
                        items={users.map((user: any) => ({
                          value: user.id,
                          label: user.firstName + ' ' + user.lastName,
                        }))}
                      />
                    );
                  }}
                  name="staff"
                />
              </CollapsibleFilterItem>
              <ListItemSeparator />
            </>
          ) : (
            <></>
          )}
          <CollapsibleFilterItem title={translations('billing_codes')}>
            <Controller
              control={methods.control}
              rules={{}}
              render={({field: {onChange, value}}) => (
                <CheckboxItemsInput
                  allowSelectAll={true}
                  value={value}
                  onChange={onChange}
                  items={cptCodesFilter}
                />
              )}
              name="types"
            />
          </CollapsibleFilterItem>
          <ListItemSeparator />
          <CollapsibleFilterItem title={translations('place_of_service')}>
            <Controller
              control={methods.control}
              rules={{}}
              render={({field: {onChange, value}}) => (
                <CheckboxItemsInput
                  allowSelectAll={true}
                  value={value}
                  onChange={onChange}
                  items={appointmentLocations}
                />
              )}
              name="locations"
            />
          </CollapsibleFilterItem>
          <ListItemSeparator />
          <CollapsibleFilterItem title={translations('status')}>
            <StatusCheckbox allowSelectAll={false} search={false} />
          </CollapsibleFilterItem>
        </Form>
      </FormProvider>
    </Filters>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => {
    return {
      // this needs to be users that have had sessions with the given patient
      users: database
        .get('users')
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
        )
        .observe(),
    };
  }),
)(HistoryFilterMenu);
