import React, {useState} from 'react';
import {Platform, View} from 'react-native';
import {Searchbar} from 'react-native-paper';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import ProgramBuilder from 'src/modules/programs/screens/program-builder';
import {ProgramProfile} from 'src/modules';
import {DeleteModal, IconButton} from 'src/design-system';
import ProgramFilterMenu from 'src/modules/programs/components/filter-menu';
import ProgramFilterChipIndicator from 'src/modules/programs/components/filter-chip-indicator';
import ProgramList from 'src/modules/programs/components/program-list';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {of} from 'rxjs';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import SortByMenu from 'src/modules/programs/components/sort-by-menu';
import {Program} from 'src/models';
import {useStyle} from 'src/providers/style';
import {
  exportInterval,
  exportRate,
  exportTaskAnalysis,
  exportTrialByTrial,
} from 'src/common-utils/program-evaluation';
import moment from 'moment/moment';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import _ from 'lodash';
import JSZip from 'jszip';
import {downloadFile} from 'src/common-utils/downloadFile';

interface Props {
  navigation: any;
  patient: any;
  role: any;
  tags: any;
}

function PatientPlan({navigation, patient, role, tags}: Props) {
  const styles = useStyle();
  const database = useDatabase();

  const [showDelete, setShowDelete] = useState<string | boolean>(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortByStr, setSortByStr] = useState('');
  const [filters, setFilters] = useState<any>({
    programType: [],
    programStatus: [],
    programState: [],
    assessments: [],
    skills: [],
    behaviors: [],
    relatedServices: [],
  });

  const viewCallback = (program: any) => {
    navigation.navigate(ProgramProfile.screenName, {
      programId: program.id,
      initial: false,
    });
  };
  const editCallback = (program: any) => {
    navigation.navigate(ProgramProfile.screenName, {
      programId: program.id,
      edit: true,
      initial: false,
    });
  };
  const deleteCallback = async (program: any) => {
    setShowDelete(program);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (program: any) => {
    program.delete();
    setShowDelete(false);
  };
  const archiveCallback = (program: Program) => {
    if (program?.state !== 'archived') {
      program.updateEntity({state: 'archived'});
    } else {
      program.updateEntity({state: 'active'});
    }
  };

  const exportCSVData = async () => {
    setLoading(true);
    const programs = await database
      .get(Program.table)
      .query(
        Q.and(Q.where('patient_id', patient.id), Q.where('deleted_at', null)),
      )
      .fetch();

    const zip = new JSZip();
    for (const program of programs) {
      let sets = null;
      let data = null;
      let sessions = null;
      let _exportData = null;

      switch (program.method) {
        case 'duration':
          sessions = await database
            .get('sessions')
            .query(
              Q.where('start_timestamp', Q.gt(program.createdAt.getTime())),
              Q.where('patient_id', patient?.id ? patient.id : null),
              Q.where('start_timestamp', Q.notEq(null)),
              Q.sortBy('start_timestamp', Q.asc),
              Q.where('deleted_at', null),
            );

          _exportData = [['Date', 'Total Duration (Minutes)']];

          for (const session of sessions) {
            sets = await database
              .get('sets')
              .query(
                Q.where('program_id', program.id),
                Q.where('session_id', session.id),
                Q.where('deleted_at', null),
                Q.sortBy('start_timestamp', Q.asc),
              )
              .fetch();

            const sum =
              sets?.reduce((a: number, b: any) => {
                return (
                  a +
                  moment
                    .duration(
                      moment(
                        !b.endTimestamp
                          ? moment(session.endTimestamp)
                          : b.endTimestamp,
                      ).diff(moment(b.startTimestamp)),
                    )
                    .asSeconds()
                );
              }, 0) || 0;

            _exportData.push([
              moment(session.date).format('YYYY-MM-DD'),
              Math.ceil(sum) / 60,
            ]);
          }

          zip.file(
            `${patient?.lastName}_${_.snakeCase(
              program?.name,
            )}_${moment().format('YYYY-MM-DD')}.csv`,
            toCsv(_exportData),
          );
          break;
        case 'frequency':
          sessions = await database
            .get('sessions')
            .query(
              Q.where('start_timestamp', Q.gt(program.createdAt.getTime())),
              Q.where('patient_id', patient?.id ? patient.id : null),
              Q.where('start_timestamp', Q.notEq(null)),
              Q.sortBy('start_timestamp', Q.asc),
              Q.where('deleted_at', null),
            );

          _exportData = [['Date', 'Number of Instances']];

          for (const session of sessions) {
            sets = await database
              .get('sets')
              .query(
                Q.where('program_id', program.id),
                Q.where('session_id', session.id),
                Q.where('deleted_at', null),
                Q.sortBy('start_timestamp', Q.asc),
              )
              .fetch();

            const setIds = _.map(sets, 'id');

            const events = await database
              .get('events')
              .query(
                Q.where('set_id', Q.oneOf(setIds)),
                Q.where('deleted_at', null),
                Q.sortBy('created_at', Q.asc),
              )
              .fetchCount();

            _exportData.push([
              moment(session.date).format('YYYY-MM-DD'),
              events,
            ]);
          }

          zip.file(
            `${patient?.lastName}_${_.snakeCase(
              program?.name,
            )}_${moment().format('YYYY-MM-DD')}.csv`,
            toCsv(_exportData),
          );
          break;
        case 'interval':
          sets = await database
            .get('sets')
            .query(
              Q.where('program_id', program.id),
              Q.where('deleted_at', null),
              Q.sortBy('start_timestamp', Q.asc),
            )
            .fetch();

          data = await exportInterval(database, sets);

          zip.file(
            `${patient?.lastName}_${_.snakeCase(
              program?.name,
            )}_${moment().format('YYYY-MM-DD')}.csv`,
            toCsv(data),
          );
          break;
        case 'rate':
          sets = await database
            .get('sets')
            .query(
              Q.where('program_id', program.id),
              Q.where('deleted_at', null),
              Q.sortBy('start_timestamp', Q.asc),
            )
            .fetch();

          const setIds = _.map(sets, 'id');

          const events = await database
            .get('events')
            .query(
              Q.where('set_id', Q.oneOf(setIds)),
              Q.where('deleted_at', null),
              Q.sortBy('created_at', Q.asc),
            );

          data = await exportRate(database, sets, events);

          zip.file(
            `${patient?.lastName}_${_.snakeCase(
              program?.name,
            )}_${moment().format('YYYY-MM-DD')}.csv`,
            toCsv(data),
          );
          break;
        case 'trial_by_trial':
          for (const target of await program.enabledTargets) {
            sets = await database
              .get('sets')
              .query(
                Q.where('program_id', program.id),
                Q.where('target_id', target.id),
                Q.where('deleted_at', null),
                Q.sortBy('start_timestamp', Q.asc),
                Q.where('end_timestamp', Q.notEq(null)),
              )
              .fetch();

            data = await exportTrialByTrial(
              database,
              sets,
              target.id,
              program?.numberOfTrials,
            );

            zip.file(
              `${patient?.lastName}_${_.snakeCase(program?.name)}_${_.snakeCase(
                target.name,
              )}_${moment().format('YYYY-MM-DD')}.csv`,
              toCsv(data),
            );
          }
          break;
        case 'task_analysis':
          sets = await database
            .get('sets')
            .query(
              Q.where('program_id', program.id),
              Q.where('deleted_at', null),
              Q.sortBy('start_timestamp', Q.asc),
              Q.where('end_timestamp', Q.notEq(null)),
            )
            .fetch();

          data = await exportTaskAnalysis(
            database,
            sets,
            await program.enabledTargets,
          );

          zip.file(
            `${patient?.lastName}_${_.snakeCase(
              program?.name,
            )}_${moment().format('YYYY-MM-DD')}.csv`,
            toCsv(data),
          );
          break;
      }
    }

    const content = await zip.generateAsync({type: 'blob'});

    await downloadFile(content, `${patient.lastName}.zip`);

    setLoading(false);
  };

  const toCsv = (content: string[][]) => {
    const rowString = content
      .map((d: any) => {
        return (
          _.values(d)
            .map(String)
            .map(v => v.replaceAll('"', '""'))
            .map(v => `"${v}"`)
            .join(',') + '\n'
        );
      })
      .join('');
    return `${rowString}`;
  };

  return (
    <View style={[styles.flex]}>
      <View
        style={[
          styles.container,
          styles.width,
          styles.alignCenter,
          styles.justifySpaceBetween,
          styles.marginBottom,
          styles.paddingHorizontal,
          styles.flexWrap,
        ]}>
        <View style={[styles.flex, styles.width, styles.marginTop]}>
          <Searchbar
            value={searchValue}
            onChangeText={setSearchValue}
            placeholder="Search"
          />
        </View>
        <View
          style={[
            styles.row,
            styles.alignCenter,
            styles.marginTop,
            styles.paddingLeft,
          ]}>
          {role?.treatmentPlanCreate ? (
            <RHButton
              onPress={() =>
                navigation.navigate(ProgramBuilder.screenName, {
                  patientId: patient.id,
                  initial: false,
                })
              }
              icon="plus"
              mode={'contained'}>
              Create New Program
            </RHButton>
          ) : null}
          {Platform.OS === 'web' ? (
            <View style={[styles.paddingLLeft]}>
              <IconButton
                loading={loading}
                disabled={loading}
                icon={'download'}
                type={'outlined'}
                onPress={() => exportCSVData()}
              />
            </View>
          ) : null}
          <View style={[styles.paddingLHorizontal]}>
            <ProgramFilterMenu applyFilters={setFilters} filter={filters} />
          </View>
          <SortByMenu setSortBy={setSortByStr} sortByStr={sortByStr} />
        </View>
      </View>
      <ProgramFilterChipIndicator
        filters={filters}
        setFilters={setFilters}
        tags={tags}
      />
      <DeleteModal
        model={'Program'}
        text={showDelete ? `${showDelete?.name}` : null}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <ProgramList
        search={searchValue}
        filter={filters}
        tags={tags}
        patientId={patient.id}
        viewCallback={viewCallback}
        canEdit={role?.treatmentPlanEdit}
        editCallback={editCallback}
        canDelete={role?.treatmentPlanDelete}
        deleteCallback={deleteCallback}
        canArchive={role?.treatmentPlanEdit}
        archiveCallback={archiveCallback}
        sortBy={sortByStr}
      />
    </View>
  );
}

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({database, authentication}: any) => ({
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('rank', Q.asc),
      ),
  })),
)(PatientPlan);
