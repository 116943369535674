import React from 'react';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';
import Programs from 'src/hook-form-inputs/programs';
import JaspersoftClientSummary from '../jaspersoft-client-summary';
import JaspersoftProgramReport from '../jaspersoft-program-report';
import JaspersoftTargetReport from '../jaspersoft-target-report';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {of} from 'rxjs';
import {Program} from 'src/models';
import {useFormContext, useWatch} from 'react-hook-form';
import Targets from 'src/hook-form-inputs/targets';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import {useTranslations} from 'src/providers/translation';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';

interface Props {
  client: String;
  startDate: Date;
  endDate: Date;
  selectedProgramId: String;
  program: Program;
}

const ClientReport = ({
  client,
  startDate,
  endDate,
  selectedProgramId,
  program,
}: Props) => {
  const styles = useStyle();

  const {control} = useFormContext();
  const watchedTarget = useWatch({control, name: 'target'});
  const objective = useWatch({control, name: 'objective'});
  const format = useWatch({control, name: 'format'});
  const translations = useTranslations();
  return (
    <View style={[styles.windowHeight75]}>
      <JaspersoftClientSummary
        client={client}
        startDate={startDate}
        endDate={endDate}
      />
      <View style={[styles.row, styles.paddingVertical]}>
        <Programs
          patient={client}
          label={'Program Name'}
          defaultValue={selectedProgramId}
        />
        {selectedProgramId ? (
          <View style={[styles.paddingTop, styles.row]}>
            <HookFormSwitchInput
              name={'objective'}
              label={translations('display_objective')}
            />
            <InformationTooltip value={translations('objective_disclaimer')} />
            <RHSeparator width={15} />
            <HookFormSwitchInput
              name={'format'}
              label={translations('per_session')}
            />
            <InformationTooltip
              value={translations('toggle_on_graphed_data')}
            />
          </View>
        ) : (
          <></>
        )}
      </View>

      {selectedProgramId ? (
        <>
          <View style={[styles.paddingBottom, styles.windowHeight]}>
            <JaspersoftProgramReport
              programId={selectedProgramId}
              startDate={startDate}
              endDate={endDate}
              objectiveEnabled={objective ? objective : false}
              formatDisplay={format ? format : false}
            />
          </View>
          {program?.method === 'trial_by_trial' ? (
            <View style={[styles.paddingVertical]}>
              <View style={[styles.row, styles.paddingBottom]}>
                <Targets programId={selectedProgramId} />
              </View>
              {watchedTarget ? (
                <View style={[styles.windowHeight75]}>
                  <JaspersoftTargetReport
                    programId={selectedProgramId}
                    startDate={startDate}
                    endDate={endDate}
                    target={watchedTarget}
                    objectiveEnabled={objective ? objective : false}
                    formatDisplay={format ? format : false}
                  />
                </View>
              ) : (
                <></>
              )}
            </View>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables(
    ['selectedProgramId'],
    ({database, selectedProgramId}: any) => ({
      program: selectedProgramId
        ? database.get('programs').findAndObserve(selectedProgramId)
        : of({}),
    }),
  ),
)(ClientReport);
