import React from 'react';
import JaspersoftDashboard from '../../components/jaspersoft-reports/index.web';
import {resourceUrls} from 'src/common-utils/resourceUrls';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';

const JaspersoftTargetReport = ({
  program,
  startDate,
  endDate,
  target,
  patient,
  formatDisplay,
  objectiveEnabled,
}: any) => {
  const objective =
    program?.objective && objectiveEnabled ? program?.objective : '';
  return (
    <JaspersoftDashboard
      resource={
        formatDisplay ? resourceUrls.target_per_session : resourceUrls.target
      }
      params={{
        start_datetime_1_1: [startDate],
        start_datetime_1_2: [endDate],
        program_id_1: [program?.id],
        target_id_1: [target?.id],
        name_2: [target?.name],
        name_1: [program?.name],
        first_name_1: [patient?.firstName],
        last_name_1: [patient?.lastName],
        objective: [objective],
      }}
      container={'target'}
    />
  );
};

export default React.memo(
  compose(
    withDatabase,
    withState,
    withObservables(
      ['programId', 'target'],
      ({programId, target, database}: any) => ({
        program: database.get('programs').findAndObserve(programId),
        target: database.get('targets').findAndObserve(target),
      }),
    ),
    withObservables(['program'], ({program}: any) => ({
      patient: program.patient,
    })),
  )(JaspersoftTargetReport),
);
