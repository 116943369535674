import {field, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Target extends Base {
  static table = 'targets';
  static associations = {
    programs: {type: 'belongs_to', key: 'program_id'},
    session_targets: {type: 'has_many', foreignKey: 'target_id'},
  };

  @relation('programs', 'program_id') program;

  @text('name') name;
  @text('rank') rank;
  @field('enabled') enabled;
}
