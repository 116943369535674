import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, Typography} from 'src/styles';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useStyle} from 'src/providers/style';

interface TargetProps {
  drag: () => void;
  sessionTarget: any;
  target: any;
  sessionTargetId: string;
}

const SessionTargetRow = ({sessionTarget, target}: TargetProps) => {
  const styles = useStyle();

  const toggleEnabled = () => {
    sessionTarget.updateEntity({
      enabled: !sessionTarget.enabled,
    });
  };

  return (
    <View
      style={[
        styles.flex,
        styles.row,
        styles.alignCenter,
        styles.backgroundColorWhite,
        styles.paddingLeft,
        styles.marginLeft,
      ]}>
      <TouchableOpacity
        style={[styles.paddingLeft, styles.paddingL, styles.marginSMLeft]}
        onPress={toggleEnabled}>
        <Icon
          name={
            sessionTarget.enabled ? 'checkbox-marked' : 'checkbox-blank-outline'
          }
          color={
            sessionTarget.enabled
              ? Colors.TEXT_PRIMARY_GRAY
              : Colors.PRIMARY_300_SESSION_PLAN
          }
          size={20}
        />
      </TouchableOpacity>
      <View style={[styles.flex, styles.row, styles.justifySpaceBetween]}>
        <View>
          <Text
            style={[
              Typography.P3_MEDIUM,
              sessionTarget.enabled
                ? styles.textColorPrimary
                : styles.textColorDisabled,
            ]}>
            {target.name}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({database, sessionTargetId}: any) => ({
    sessionTarget: database
      .get('session_targets')
      .findAndObserve(sessionTargetId),
  })),
  withObservables([], ({sessionTarget}: any) => ({
    target: sessionTarget.target,
  })),
)(SessionTargetRow);
