import {IconButton, Menu} from 'src/design-system';
import React from 'react';
import {Colors} from 'src/styles';

interface Props {
  viewInstructions: any;
  viewHistory: () => void;
  viewData?: () => void;
  color?: string;
}

const ProgramMenu = ({
  viewInstructions,
  viewData,
  viewHistory,
  color = Colors.RAVEN_BLACK,
}: Props) => {
  const options = [];

  options.push({
    onPress: viewInstructions,
    title: 'Instructions',
    icon: 'view-headline',
  });
  options.push({
    onPress: viewHistory,
    title: 'Progress History',
    icon: 'chart-line-variant',
  });

  if (viewData) {
    options.push({
      onPress: viewData,
      title: 'Program Data',
      icon: 'poll',
    });
  }

  return (
    <Menu
      anchor={<IconButton type={'icon'} icon={'dots-vertical'} color={color} />}
      options={options}
    />
  );
};

export default ProgramMenu;
