import {date, text, writer} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Notification extends Base {
  static table = 'notifications';
  static associations = {
    users: {type: 'has_many', foreignKey: 'user_id'},
  };

  @text('user_id') userId;
  @text('subject_model') subjectModel;
  @text('subject_id') subjectId;
  @text('title') title;
  @text('body') body;
  @date('read_at') readAt;

  @writer async read() {
    await this.update(entity => {
      entity.readAt = new Date();
    });
  }
}
