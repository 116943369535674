import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import FormInput from 'src/hook-form-wrapper/form-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
  label?: string;
}

interface Props {
  name?: string;
  label?: string;
  disabled?: boolean;
}

export const Import = (
  key: string = 'middleName',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Middle Name',
  key,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value, label}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>
        {label ? label : translations('middle_name')}
      </Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, disabled = false, label}: Props) => {
  const translations = useTranslations();

  return (
    <FormInput
      name={(name ? name + '.' : '') + 'middleName'}
      label={label ? label : translations('middle_name')}
      placeholder={label ? label : translations('middle_name')}
      helper={''}
      required={false}
      disabled={disabled}
    />
  );
};

export const validation = () =>
  Yup.string().required('Middle name is required');

export default Input;
