import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import TimeInput from 'src/hook-form/time-input';

interface Props {
  rules?: any;
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
  defaultValue?: string;
  helper?: string;
}

const HookFormTimeInput = (props: Props) => {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      rules={props.rules}
      render={({field}) => <TimeInput {...props} {...field} />}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
};

export default HookFormTimeInput;
