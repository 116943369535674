import React from 'react';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {useStyle} from 'src/providers/style';

export const GestureProvider = ({children}: {children: any}) => {
  const styles = useStyle();

  return (
    <GestureHandlerRootView style={[styles.flex]}>
      {children}
    </GestureHandlerRootView>
  );
};
