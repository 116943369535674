import React, {useState} from 'react';
import {Text, View, StyleSheet, TouchableOpacity} from 'react-native';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Modal} from 'src/design-system';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import moment from 'moment';
import {TextInput} from 'react-native-paper';
import {BLUE_RIBBON} from 'src/styles/colors';
import {Controller, useFormContext} from 'react-hook-form';

interface Props {
  label: string;
  name: string;
}

const EditTime = ({label, name}: Props) => {
  const {control} = useFormContext();
  const [show, setShow] = useState(false);

  return (
    <Controller
      control={control}
      render={({field}) => (
        <>
          <Modal
            show={[show, setShow]}
            title={label}
            footer={
              <>
                <RHButton
                  mode="contained"
                  secondary
                  onPress={() => setShow(false)}>
                  {'UPDATE'}
                </RHButton>
              </>
            }>
            <View style={[styles.content]}>
              <DatePicker
                selected={moment(field.value).toDate()}
                onChange={(date: any) => field.onChange(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                dateFormat="h:mm aa"
                customInput={
                  <TextInput
                    value={moment(field.value).toDate()}
                    placeholder={label}
                    label={label + '*'}
                    mode="outlined"
                    style={[styles.input]}
                    secureTextEntry={false}
                    theme={{colors: {primary: BLUE_RIBBON}}}
                  />
                }
              />
            </View>
          </Modal>
          <View style={[styles.row]}>
            <Text style={[Typography.P3, styles.marginMRight]}>
              {moment(field.value).format('hh:mm A')}
            </Text>
            <TouchableOpacity onPress={() => setShow(true)}>
              <MaterialCommunityIcon
                name="pencil"
                size={16}
                color={Colors.RAVEN_BLACK}
              />
            </TouchableOpacity>
          </View>
        </>
      )}
      name={name}
    />
  );
};

const styles = StyleSheet.create({
  content: {
    height: 280,
  },
  row: {flexDirection: 'row'},
  input: {
    backgroundColor: 'white',
  },
  marginMRight: {
    marginRight: 8,
  },
});

export default EditTime;
