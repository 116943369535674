import React, {useState} from 'react';
import {View, ScrollView, TouchableOpacity, Text} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {Display as PersonalInformationDisplay} from 'src/modules/users/components/personal-information-form';
import {Display as PermissionsDisplay} from 'src/modules/users/components/permissions-form';
import {Display as RosterCredentialsDisplay} from 'src/modules/users/components/roster-credentials-form';
import {RetractingDrawer} from 'src/design-system';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useDimensions} from '@react-native-community/hooks';

function UserDetails({
  canEdit,
  credential,
  user,
  instance,
  payers,
  navigateToUpsert,
  tags,
}: any) {
  const dimensions = useDimensions();
  const styles = useStyle();

  const tabs: any[] = [
    {
      type: 'local',
      icon: 'person',
      value: 'personal',
      title: 'Personal Information',
    },
    {
      type: 'local',
      icon: 'supervisor-account',
      value: 'permissions',
      title: 'Permissions',
    },
    {
      type: 'local',
      icon: 'list',
      value: 'roster',
      title: 'Roster Credentials',
    },
  ];

  if (canEdit) {
    tabs.unshift({type: 'separator', value: 'separator'});
    tabs.unshift({
      type: 'navigation',
      icon: 'edit',
      value: 'edit',
      title: 'Edit',
    });
  }
  const [selectedTab, setSelectedTab] = useState<string>(tabs[2].value);

  const tabSelected = (tab: any) => {
    if (tab.type === 'local') {
      setSelectedTab(tab.value);
    } else {
      navigateToUpsert();
    }
  };

  return (
    <View style={[styles.row, styles.flex, styles.width]}>
      <RetractingDrawer
        type={dimensions.window.width > 1024 ? 'persistent' : 'overlay'}
        position={'left'}>
        {tabs.map((tab: any) => {
          if (tab.type === 'separator') {
            return <View key={tab.value} style={[styles.separator]} />;
          }
          return (
            <TouchableOpacity key={tab.value} onPress={() => tabSelected(tab)}>
              <View
                style={[
                  styles.marginMVertical,
                  styles.row,
                  styles.alignCenter,
                  styles.borderRadiusSM,
                  styles.overflowHidden,
                ]}>
                <View style={[styles.positionRelative, styles.paddingM]}>
                  {tab.notifications ? (
                    <View style={[styles.circle]}>
                      <Text
                        style={[
                          styles.alignCenter,
                          styles.alignSelfCenter,
                          styles.textColorWhite,
                        ]}>
                        {tab.notifications >= 9 ? '9+' : tab.notifications}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                  <Icon name={tab.icon} size={20} color={Colors.PRIMARY_700} />
                </View>
                <Text
                  style={[
                    Typography.CAPTION_MEDIUM,
                    styles.textColorPrimary700,
                    styles.marginMLeft,
                    styles.marginSMTop,
                  ]}
                  numberOfLines={1}>
                  {tab.title}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </RetractingDrawer>
      <ScrollView
        style={[styles.column, styles.flex, styles.width, styles.padding]}>
        {selectedTab === 'personal' ? (
          <PersonalInformationDisplay values={user} tags={tags} />
        ) : null}
        {selectedTab === 'permissions' ? (
          <PermissionsDisplay values={user} />
        ) : null}
        {selectedTab === 'roster' ? (
          <RosterCredentialsDisplay
            values={{
              npi: credential.npi,
              tin: credential.tin,
              licenseNumber: user.licenseNumber,
              payers: payers.map(payer => {
                return {
                  id: payer.payerId,
                  legacyId: payer.legacyId,
                };
              }),
              taxonomy: credential.taxonomy,
            }}
            billing={instance.billing}
          />
        ) : null}
      </ScrollView>
    </View>
  );
}

export default UserDetails;
