import {DataItem, TextInput} from 'src/design-system';
import React from 'react';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const Import = (
  key: string = 'identifier',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Identifier',
  key,
  validators: [],
});

const resolveDisplayValue = (value: string) => {
  return value;
};

const IdentifierDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Identifier</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const IdentifierInput = ({valueKey}: Props) => {
  return (
    <TextInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'identifier'}
      name={'Identifier'}
      required={false}
    />
  );
};

export const validation = () => Yup.string().required('Identifier is required');

export const Display = IdentifierDisplay;
export const Input = IdentifierInput;
export const Value = resolveDisplayValue;

export default IdentifierInput;
