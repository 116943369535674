import React from 'react';
import {
  AssignPatientsTab,
  UserDetails,
  StaffDocuments,
  StaffHistory,
  StaffOnlyTimesheet,
} from 'src/modules/users/components';
import {BaseScreen, TabBar} from 'src/design-system';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {mergeMap, of} from 'rxjs';
import {Instance, User} from 'src/models';
import {Q} from '@nozbe/watermelondb';
import {Success, Error} from 'src/common-components/atoms';
import {Platform} from 'react-native';

const Navigator = createMaterialTopTabNavigator();

function UserProfileScreen({
  route,
  instance,
  navigation,
  profile,
  credential,
  user,
  payers,
  role,
  tags,
}: any) {
  const navigateToUpsert = () => {
    navigation.navigate('UserFormScreen', {
      id: user.id,
    });
  };
  const isStaff = user.firstName && user.lastName ? 'Staff ' : null;
  const fullName = `| ${user.firstName} ${user.lastName}`;

  return (
    <BaseScreen title={isStaff + fullName}>
      <TabBar navigator={Navigator} location={'UserProfile'} route={route}>
        <Navigator.Screen name="Summary">
          {() => (
            <UserDetails
              canEdit={role?.userEdit || profile.id === user.id}
              credential={credential}
              instance={instance}
              user={user}
              payers={payers}
              navigateToUpsert={navigateToUpsert}
              tags={tags}
            />
          )}
        </Navigator.Screen>
        {role?.assignedClientView ? (
          <Navigator.Screen name="Assigned Clients">
            {() => <AssignPatientsTab user={user} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.staffDocumentView ? (
          <Navigator.Screen name="Documents">
            {() => <StaffDocuments user={user} role={role} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.staffHistoryView ? (
          <Navigator.Screen name="History">
            {() => <StaffHistory user={user} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}

        {Platform.OS === 'web' && role?.timesheetView ? (
          <Navigator.Screen name="Timesheet">
            {() => <StaffOnlyTimesheet user={user} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
      </TabBar>
      {route?.params?.alert ? (
        <Success title={'Processing Request'} message={route?.params?.alert} />
      ) : route?.params?.error ? (
        <Error title={'Error'} message={route?.params?.error} />
      ) : (
        <></>
      )}
    </BaseScreen>
  );
}

export default compose(
  withDatabase,
  withState,
  withObservables(['route'], ({route, database}: any) => ({
    user: database.get(User.table).findAndObserve(route.params?.practitionerId),
  })),
  withObservables([], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
    instance: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
  withObservables([], ({profile, user}: any) => {
    return {
      role: profile.role,
      credential: user.credential,
      tags: user.activeTags,
    };
  }),
  withObservables([], ({credential}: any) => {
    return {
      payers: credential.activePayerCredentials,
    };
  }),
)(UserProfileScreen);
