import React, {useState, useReducer} from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import _ from 'lodash';
import {Typography, Colors} from 'src/styles';
import {Checkbox, RadioButton} from 'react-native-paper';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useTranslations} from 'src/providers/translation';
import {useStyle} from 'src/providers/style';
import TimerComponent from 'src/hook-form/timer-input';
import moment from 'moment';

const DurationTotalEdit = ({
  set,
  row = '',
  instance,
  program,
  prompts,
  intensityArray,
  setEvents,
}: any) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const translations = useTranslations();
  const editableIntensity = set?.intensity;
  const [intensity] = useState<string>(editableIntensity);
  const [previousPrompts] = useState<string>(set?.prompts);
  const duration = Math.round(
    (set?.endTimestamp - set?.startTimestamp) * 0.001,
  );

  const [durationEdit, setDuration] = useState(duration);
  const styles = useStyle();
  const onSave = () => {
    if (duration !== durationEdit) {
      set.updateEntity({
        endTimestamp: moment(set?.startTimestamp).add(durationEdit, 's'),
      });
    }
    setEvents('');
  };
  const onCancel = () => {
    set.updateEntity({
      prompts: previousPrompts,
      intensity: intensity,
    });
    setEvents('');
  };

  const checkPrompt = (id: any) => {
    const index = [...set?.prompts].findIndex((v: any) => {
      return v === id;
    });

    if (index === -1) {
      const values = [...set?.prompts];
      values.push(id);
      set.updateEntity({
        prompts: values,
      });
    } else {
      const values = [...set?.prompts];
      values.splice(index, 1);
      set.updateEntity({
        prompts: values,
      });
    }
    forceUpdate();
  };

  const changeIntensity = (val: string) => {
    set.updateEntity({
      intensity: val,
    });
    forceUpdate();
  };

  return (
    <View>
      <View
        style={[
          styles.border,
          styles.borderColorPrimary300,
          styles.borderRadiusSM,
          styles.padding,
          styles.marginLVertical,
        ]}>
        {program?.intensity && row === 'intensity' ? (
          <View>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <Text style={[styles.marginSMVertical, Typography.P2]}>
                {translations('intensity')} - {translations('instance')}{' '}
                {instance}
              </Text>
              <Text
                style={[
                  styles.textTransformCapitalize,
                  styles.marginSMVertical,
                  Typography.P2,
                  editableIntensity === 'mild'
                    ? styles.mildColor
                    : editableIntensity === 'moderate'
                    ? styles.moderateColor
                    : styles.severeColor,
                ]}>
                {_.capitalize(editableIntensity)}
              </Text>
            </View>
            <View style={styles.paddingVertical}>
              <RadioButton.Group
                onValueChange={changeIntensity}
                value={editableIntensity}>
                <View style={[styles.row, styles.justifySpaceBetween]}>
                  {intensityArray.map((button: any, index: number) => {
                    return (
                      <View
                        key={`radio-key-${button?.value}-${index}`}
                        style={styles.radioButton}>
                        <RadioButton.Android
                          value={button.value}
                          color={
                            editableIntensity === 'mild'
                              ? '#4FF5AF'
                              : editableIntensity === 'moderate'
                              ? '#F7AA4F'
                              : '#F12E5D'
                          }
                          uncheckedColor={'#909093'}
                        />
                        <Text
                          style={[
                            Typography.P2,
                            button.title === _.capitalize(editableIntensity)
                              ? button.title === 'Mild'
                                ? styles.mildColor
                                : button.title === 'Moderate'
                                ? styles.moderateColor
                                : styles.severeColor
                              : styles.noResultsColor,
                          ]}>
                          {button.title}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </RadioButton.Group>
            </View>
          </View>
        ) : row === 'total' ? (
          <View>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <Text style={[styles.marginSMVertical, Typography.P2]}>
                {translations('duration')} - {translations('instance')}{' '}
                {instance}
              </Text>
              <TimerComponent
                value={durationEdit}
                onChange={value => setDuration(value)}
              />
            </View>
            <View style={styles.paddingTop}>
              {Object.keys(prompts).map((promptType: any, index: number) => {
                return (
                  <View
                    style={[
                      styles.positionRelative,
                      styles.zIndex100,
                      styles.borderRadius,
                      index === Object.keys(prompts).length - 1
                        ? styles.paddingHorizontal
                        : styles.paddingHorizontal,
                    ]}
                    key={`promptType-key-${promptType}`}>
                    <Text style={[styles.marginSMVertical, Typography.P2]}>
                      {promptType}
                    </Text>
                    {prompts[promptType].map((prompt: any) => {
                      return (
                        <TouchableOpacity
                          key={`prompt-key-${promptType}-${prompt?.id}`}
                          style={[
                            styles.row,
                            styles.alignCenter,
                            styles.marginSMVertical,
                          ]}
                          onPress={() => {
                            checkPrompt(prompt?.id);
                          }}>
                          <Checkbox.Android
                            status={
                              (set?.prompts || []).findIndex(v => {
                                return v === prompt?.id;
                              }) !== -1
                                ? 'checked'
                                : 'unchecked'
                            }
                            color={Colors.RAVEN_BLACK}
                            uncheckedColor={Colors.RAVEN_BLACK}
                          />
                          <Text style={[Typography.P2]}>{prompt?.name}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </View>
        ) : row === 'duration' ? (
          <View style={[styles.row, styles.justifySpaceBetween]}>
            <Text style={[styles.marginSMVertical, Typography.P2]}>
              {translations('duration')} - {translations('instance')} {instance}
            </Text>
            <TimerComponent
              value={durationEdit}
              onChange={value => setDuration(value)}
            />
          </View>
        ) : row === 'rate' ? (
          <View>
            {Object.keys(prompts).map((promptType: any, index: number) => {
              return (
                <View
                  style={[
                    styles.positionRelative,
                    styles.zIndex100,
                    styles.borderRadius,
                    index === Object.keys(prompts).length - 1
                      ? styles.paddingHorizontal
                      : styles.paddingHorizontal,
                  ]}
                  key={`promptType-key-${promptType}`}>
                  <Text style={[styles.marginSMVertical, Typography.P2]}>
                    {promptType}
                  </Text>
                  {prompts[promptType].map((prompt: any) => {
                    return (
                      <TouchableOpacity
                        key={`prompt-key-${promptType}-${prompt?.id}`}
                        style={[
                          styles.row,
                          styles.alignCenter,
                          styles.marginSMVertical,
                        ]}
                        onPress={() => {
                          checkPrompt(prompt?.id);
                        }}>
                        <Checkbox.Android
                          status={
                            (set?.prompts || []).findIndex(v => {
                              return v === prompt?.id;
                            }) !== -1
                              ? 'checked'
                              : 'unchecked'
                          }
                          color={Colors.RAVEN_BLACK}
                          uncheckedColor={Colors.RAVEN_BLACK}
                        />
                        <Text style={[Typography.P2]}>{prompt?.name}</Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              );
            })}
          </View>
        ) : (
          <></>
        )}
      </View>
      <View style={[styles.justifySpaceBetween, styles.row]}>
        <RHButton
          secondary
          onPress={() => {
            onCancel();
          }}
          textColor={Colors.RAVEN_BLACK}
          color="black"
          mode="outlined">
          {translations('cancel_button')}
        </RHButton>
        <RHButton mode="contained" onPress={() => onSave()}>
          {translations('save_button')}
        </RHButton>
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({set}: any) => {
    return {
      events: set?.events,
    };
  }),
)(DurationTotalEdit);
