import React from 'react';
import {View, Text} from 'react-native';
import {Separator} from 'src/common-components/atoms';
import {Typography} from 'src/styles';
import Form from 'src/hook-form/form';
import {cptCodes} from 'src/common-utils/cptCodes';
import {useStyle} from 'src/providers/style';
import {appointmentTypes} from 'src/form-inputs/appointment-type-input';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import DateInput from 'src/hook-form-wrapper/date-input';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import ConfiguredPayers from 'src/hook-form-inputs/configured-payers';

interface Props {
  handleSubmit: (values: any, formik: any) => void;
  handleCancel?: () => void;
  instance?: any;
}

const PatientAuthorizationForm = ({
  handleSubmit,
  handleCancel,
  instance,
}: Props) => {
  const styles = useStyle();
  return (
    <View style={[styles.paddingBottom]}>
      <Form
        handleSubmit={handleSubmit}
        cancelled={handleCancel}
        saveText={'Save'}
        cancelText={'Cancel'}>
        <>
          <HookFormInput
            label={'Authorization Number'}
            name={'authorizationNumber'}
          />
          <View style={[styles.row, styles.paddingBottom]}>
            <SafeAreaProvider>
              <DateInput
                name={'startDate'}
                label={'Start Date'}
                placeholder={'Start Date'}
                showHelper={false}
                required={false}
              />
            </SafeAreaProvider>
            <Separator width={50} />
            <SafeAreaProvider>
              <DateInput
                name={'endDate'}
                label={'End Date'}
                placeholder={'End Date'}
                showHelper={false}
                required={false}
              />
            </SafeAreaProvider>
          </View>
          {instance?.billing ? <ConfiguredPayers /> : <></>}
          {cptCodes.map((code, index) => {
            return (
              <View
                key={`${index}-auth-item-input`}
                style={[styles.row, styles.alignItemsCenter]}>
                <View
                  style={[styles.flex3, styles.column, styles.justifyCenter]}>
                  <Text style={[Typography.P3_BOLD, styles.paddingRight]}>
                    {
                      appointmentTypes.find(
                        appointmentType =>
                          appointmentType.label.indexOf(
                            code.replace('cpt', ''),
                          ) !== -1,
                      ).label
                    }
                  </Text>
                </View>
                <View
                  style={[styles.flex, styles.column, styles.justifyCenter]}>
                  <HookFormInput name={code} label={'Units'} />
                </View>
              </View>
            );
          })}
        </>
      </Form>
    </View>
  );
};

export default PatientAuthorizationForm;
