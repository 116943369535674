import {DataItem, TextInput} from 'src/design-system';
import React from 'react';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const Import = (
  key: string = 'email',
  prefix?: string,
  required: boolean = true,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Email',
  key,
  validators: required
    ? [
        {
          validate: 'required',
        },
        {
          validate: 'regex_match',
          regex:
            '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
          errorMessage: 'Must be a valid email.',
        },
      ]
    : [
        {
          validate: 'regex_match',
          regex:
            '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
          errorMessage: 'Must be a valid email.',
        },
      ],
});

const resolveDisplayValue = (value: string) => {
  return value;
};

const EmailDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Email</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const EmailInput = ({valueKey}: Props) => {
  return (
    <TextInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'email'}
      name={'Email'}
      required={true}
      keyboardType={'email-address'}
    />
  );
};

export const validation = () =>
  Yup.string()
    .required('Please enter a valid email')
    .email('Must be a valid email');

export const Display = EmailDisplay;
export const Input = EmailInput;
export const Value = resolveDisplayValue;

export default EmailInput;
