import React, {useEffect, useState} from 'react';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Document} from 'src/models';
import {useSelector} from 'react-redux';
import {UTILITY_SERVER} from 'react-native-dotenv';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Documents from 'src/modules/patients/components/documents';

const StaffDocuments = ({user, documents, starredDocuments, role}: any) => {
  const database = useDatabase();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [accessToken, setAccessToken] = useState('');

  const getAccessToken = async () => {
    setAccessToken(await AsyncStorage.getItem('accessToken'));
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  const uploadFile = async (values: any) => {
    const path = `${selectedGroup}/staff/${user.id}`;

    const data = new FormData();
    data.append('file', values.file);
    data.append('path', path);

    const response = await fetch(`${UTILITY_SERVER}/upload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }

    const value = await response.json();

    database.write(async () => {
      database.get(Document.table).create(entity => {
        entity.partition = selectedGroup;
        entity.fileName = values.fileName;
        entity.fileLocation = value.url;
        entity.localFileLocation = '';
        entity.syncStatus = 'synced';
        entity.starred = !!values.intakeType;

        entity.user.id = user.id;
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
    });
  };

  return (
    <Documents
      accessToken={accessToken}
      canAdd={role?.clientDocumentCreate}
      canEdit={role?.clientDocumentEdit}
      canDelete={role?.clientDocumentDelete}
      starredDocuments={starredDocuments}
      documents={documents}
      uploadFile={uploadFile}
    />
  );
};

export default compose(
  withObservables([], ({user}: any) => ({
    starredDocuments: user?.starredDocuments,
    documents: user?.activeDocuments,
  })),
)(StaffDocuments);
