import React, {useEffect, useReducer, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {
  HomeHeader,
  HomeNotes,
  HomeSession,
  HomePatients,
  HomeTodaysSessions,
} from 'src/modules/home/components';
import {BaseScreen} from 'src/design-system';
import {useDimensions} from '@react-native-community/hooks';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {of} from 'rxjs';
import withState from 'src/redux/wrapper';
import {rrulestr} from 'rrule';
import {endOfDay, startOfDay} from 'date-fns';
import DeleteSessionModal from 'src/modules/scheduling/components/delete-session-modal';
import moment from 'moment';
import {useStyle} from 'src/providers/style';

const HomeScreen = ({
  profile,
  patients,
  appointments,
  recurringAppointments,
  role,
  sessions,
}: any) => {
  const dimensions = useDimensions();
  const styles = useStyle();

  const [update, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const [showDelete, setShowDelete] = useState<string | boolean>(false);
  const [notesDueCount, setNotesDueCount] = useState<number>(0);
  const [notesDueTodayCount, setNotesDueTodayCount] = useState<number>(0);
  const [todayCompletedCount, setTodayCompletedCount] = useState<number>(0);

  const firstName = profile?.firstName || '';

  const isSmallSize = dimensions.window.width < 768;

  const [todayAppointments, setTodayAppointments] = useState([]);

  const calcNotesDue = async () => {
    let notesCount = 0;
    let notesTodayCount = 0;
    let completedCount = 0;
    for (const session of sessions) {
      const currentStaffParticipant = await session
        .currentStaffParticipant(profile?.id)
        .fetch();
      if (currentStaffParticipant?.length > 0) {
        const isSupervision = currentStaffParticipant[0]?.supervision;
        if (isSupervision) {
          if (
            session?.supervisionStartTimestamp &&
            session?.supervisionEndTimestamp &&
            !session?.supervisionSubmissionTimestamp
          ) {
            notesCount++;
            if (
              session.supervisionStartTimestamp > startOfDay(new Date()) &&
              session.supervisionEndTimestamp < endOfDay(new Date())
            ) {
              notesTodayCount++;
            }
          }
          if (
            session?.supervisionSubmissionTimestamp &&
            session?.supervisionStartTimestamp &&
            session?.supervisionEndTimestamp &&
            session?.supervisionStartTimestamp > startOfDay(new Date()) &&
            session?.supervisionEndTimestamp < endOfDay(new Date())
          ) {
            completedCount++;
          }
        } else {
          if (
            session?.startTimestamp &&
            session?.endTimestamp &&
            !session?.submissionTimestamp
          ) {
            notesCount++;
            if (
              session.startTimestamp > startOfDay(new Date()) &&
              session.endTimestamp < endOfDay(new Date())
            ) {
              notesTodayCount++;
            }
          }
          if (
            session?.submissionTimestamp &&
            session?.startTimestamp &&
            session?.endTimestamp &&
            session?.startTimestamp > startOfDay(new Date()) &&
            session?.endTimestamp < endOfDay(new Date())
          ) {
            completedCount++;
          }
        }
      }
    }
    setNotesDueCount(notesCount);
    setNotesDueTodayCount(notesTodayCount);
    setTodayCompletedCount(completedCount);
  };

  useEffect(() => {
    calcNotesDue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  useEffect(() => {
    const curateAppointments = async () => {
      const curatedAppointments = [];
      for (const recurringAppointment of recurringAppointments) {
        const rrule = rrulestr(recurringAppointment.rrule, {
          forceset: true,
        });

        // Accommodate Daylight Savings
        let endTime = endOfDay(new Date());
        let startTime = startOfDay(new Date());
        const dtstartHours = rrule._dtstart.getHours();
        switch (dtstartHours) {
          case 22:
            startTime = new Date(startTime.setHours(startTime.getHours() - 3));
            endTime = new Date(endTime.setHours(endTime.getHours() - 3));
            break;
          case 23:
            startTime = new Date(startTime.setHours(startTime.getHours() - 2));
            endTime = new Date(endTime.setHours(endTime.getHours() - 2));
            break;
          case 0:
            startTime = new Date(startTime.setHours(startTime.getHours() - 1));
            endTime = new Date(endTime.setHours(endTime.getHours() - 1));
            break;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const _ of rrule
          .between(startTime, endTime, true)
          .map(rruleDate => {
            const realDate = new Date(
              rruleDate.getFullYear(),
              rruleDate.getMonth(),
              rruleDate.getDate(),
            );
            realDate.setTime(
              rruleDate.getTime() +
                rrule.options.dtstart.getTimezoneOffset() * 60 * 1000,
            );
            return realDate;
          })) {
          const exdateFind = rrule
            .exdates()
            .findIndex(
              (exdate: any) =>
                moment(exdate).format('MMM DD YYYY') ===
                moment(new Date()).format('MMM DD YYYY'),
            );
          if (exdateFind === -1) {
            curatedAppointments.push(recurringAppointment);
          }
        }
      }
      for (const appointment of appointments) {
        curatedAppointments.push(appointment);
      }

      setTodayAppointments(curatedAppointments);
    };
    curateAppointments();
  }, [appointments, recurringAppointments, update]);

  const deleteCallback = (item: any) => {
    setShowDelete({appointment: item, date: new Date()});
  };
  const deleteCancelled = () => {
    setShowDelete(false);
    forceUpdate();
  };

  return (
    <BaseScreen header={false}>
      <DeleteSessionModal
        show={[showDelete, setShowDelete]}
        deleteCancelled={() => deleteCancelled()}
      />
      <ScrollView>
        <HomeHeader name={firstName} />
        <View style={[styles.container, styles.flex, styles.marginVertical]}>
          {isSmallSize ? (
            <>
              <View style={[styles.paddingHorizontal, styles.paddingBottom]}>
                <HomeSession
                  total={todayAppointments?.length || 0}
                  completed={todayCompletedCount}
                  sessionNotesDue={notesDueTodayCount}
                  role={role}
                />
              </View>
              <View style={[styles.paddingHorizontal, styles.paddingBottom]}>
                <HomeNotes notesDue={notesDueCount} />
              </View>
              <View style={[styles.paddingHorizontal, styles.paddingBottom]}>
                <HomeTodaysSessions
                  profile={profile}
                  appointments={todayAppointments}
                  canEdit={role?.appointmentEdit}
                  canDelete={role?.appointmentDelete}
                  deleteCallback={deleteCallback}
                />
              </View>
              <View style={[styles.paddingHorizontal, styles.paddingBottom]}>
                <HomePatients patients={patients} />
              </View>
            </>
          ) : (
            <>
              <View style={[styles.flex13]}>
                <View style={[styles.paddingHorizontal, styles.paddingBottom]}>
                  <HomeSession
                    total={todayAppointments?.length || 0}
                    completed={todayCompletedCount}
                    sessionNotesDue={notesDueTodayCount}
                    role={role}
                  />
                </View>
                <View style={[styles.paddingHorizontal, styles.paddingBottom]}>
                  <HomeTodaysSessions
                    profile={profile}
                    appointments={todayAppointments}
                    canEdit={role?.appointmentEdit}
                    canDelete={role?.appointmentDelete}
                    deleteCallback={deleteCallback}
                    canView={role?.appointmentView}
                  />
                </View>
              </View>
              <View style={[styles.flex]}>
                <View style={[styles.paddingHorizontal, styles.paddingBottom]}>
                  <HomeNotes notesDue={notesDueCount} />
                </View>
                <View style={[styles.paddingHorizontal, styles.paddingBottom]}>
                  <HomePatients
                    patients={patients}
                    canView={!role?.patientAssignedOnly}
                  />
                </View>
              </View>
            </>
          )}
        </View>
      </ScrollView>
    </BaseScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => {
    return {
      profile: authentication?.userId
        ? database.get('users').findAndObserve(authentication.userId)
        : of(),
    };
  }),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
      todaysDate: of(new Date()),
    };
  }),
  withObservables(['profile'], ({profile, authentication, todaysDate}: any) => {
    return {
      sessions: profile.userSessions(authentication.selectedGroup),
      appointments: profile.todaysAppointments(
        startOfDay(todaysDate),
        endOfDay(todaysDate),
      ),
      recurringAppointments: profile.recurringAppointments(
        startOfDay(todaysDate),
        endOfDay(todaysDate),
      ),
      patients: profile.patients,
    };
  }),
)(HomeScreen);
