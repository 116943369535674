import React from 'react';
import {View, Text} from 'react-native';
import {IconButton} from 'react-native-paper';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';

interface Props {
  envFactItem: any;
  canEdit: boolean;
  editClk: any;
  canDelete: boolean;
  deleteSelected: (item: any) => void;
}

export const environmentalType = [
  {value: 'factor', title: 'Environmental Factor'},
  {value: 'change', title: 'Environmental Change'},
];

const EnvironmentalFactorListItem = ({
  envFactItem,
  canEdit,
  editClk,
  canDelete,
  deleteSelected,
}: Props) => {
  const styles = useStyle();

  const type =
    environmentalType.find((item: any) => item?.value === envFactItem?.type)
      ?.title || '';

  return (
    <View>
      <View
        style={[styles.row, styles.justifySpaceBetween, styles.alignCenter]}>
        <Text
          style={[
            Typography.CAPTION,
            styles.marginLLeft,
            styles.textColorPrimary,
          ]}>
          {type}
        </Text>
        <View style={[styles.row, styles.alignCenter]}>
          {canEdit ? (
            <IconButton
              size={18}
              color={Colors.GRAY_500}
              icon="pencil"
              onPress={editClk}
            />
          ) : (
            <></>
          )}
          {canDelete ? (
            <IconButton
              size={18}
              color={Colors.GRAY_500}
              icon="trash-can"
              onPress={deleteSelected}
            />
          ) : (
            <></>
          )}
        </View>
      </View>
      <Text
        style={[
          Typography.CAPTION,
          styles.marginLVertical,
          styles.marginLLeft,
          styles.textColorPrimary,
        ]}>
        {envFactItem?.title}
      </Text>
      <Text
        style={[
          Typography.CAPTION,
          styles.marginBottom,
          styles.marginLLeft,
          styles.textColorPrimary,
        ]}>
        {envFactItem?.description}
      </Text>
    </View>
  );
};

export default EnvironmentalFactorListItem;
