import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import DisplayUserDataSnippet from 'src/common-components/displayUserDataSnippet';
import {useStyle} from 'src/providers/style';

interface Props {
  technicians: [];
}

const Technician = ({tech}: any) => {
  const firstName = tech?.firstName || '';
  const lastName = tech?.lastName || '';
  const gender = tech?.gender || '';

  return (
    <DisplayUserDataSnippet
      firstName={firstName}
      lastName={lastName}
      resourceType={'Practitioner'}
      gender={gender}
    />
  );
};

const Technicians = ({technicians}: Props) => {
  const styles = useStyle();

  return (
    <View style={[styles.column]}>
      <Text style={[Typography.P1, styles.textColorSecondary]}>
        Technicians
      </Text>
      <View style={[styles.paddingLVertical, styles.justifyStart]}>
        {technicians.map(tech => (
          <Technician tech={tech} key={`tech-key-${tech.id}`} />
        ))}
      </View>
    </View>
  );
};

export default Technicians;
