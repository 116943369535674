import React, {useEffect, useState} from 'react';
import {FlatList, Text, TouchableOpacity, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {Checkbox, IconButton} from 'react-native-paper';
import Collapsible from 'react-native-collapsible';
import ListItemSeparator from 'src/common-components/separator';
import PatientClaimSessionsItem from 'src/modules/billing/components/patient-claim-sessions-item';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Payer, Insurance, PayerPlans, Session} from 'src/models';
import {Q} from '@nozbe/watermelondb';
import {Separator} from 'src/common-components/atoms';
import {of} from 'rxjs';
import {endOfDay, startOfDay} from 'date-fns';

const PatientClaim = ({
  item,
  selectedItems,
  setSelectedItems,
  sessions,
  payers,
  plans,
  payerIds,
  filters,
  insurances,
}: any) => {
  const styles = useStyle();
  const [open, setOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [filteredSessions, setFilteredSessions] = useState([]);

  const sessionIds = sessions.map((session: any) => session.id);

  useEffect(() => {
    if (!selectedItems.some((id: string) => sessionIds.includes(id))) {
      setSelectAll(false);
    }
  }, [selectedItems, sessionIds]);

  const idToIndexMap: any = {};
  payerIds.forEach((id: string, index: number) => {
    idToIndexMap[id] = index;
  });

  const sortedPayers = payers.sort(
    (a: any, b: any) => idToIndexMap[a.id] - idToIndexMap[b.id],
  );
  const sortedPlans = plans.sort(
    (a: any, b: any) => idToIndexMap[a.payerId] - idToIndexMap[b.payerId],
  );

  useEffect(() => {
    const applyAdditionalFilters = () => {
      const filtered = sessions.filter((session: any) => {
        const matchesCpt =
          !filters.types.length ||
          session.cpt.some((cpt: string) => filters.types.includes(cpt));
        const matchesLocation =
          !filters.locations.length ||
          filters.locations.includes(session.location);

        const matchesStaff =
          !filters.staff.length || filters.staff.includes(session.userId);
        const matchesAddress =
          !filters.addresses.length ||
          filters.addresses.includes(session.address);

        const isPayerSelected =
          filters.payerId.length === 0 ||
          insurances.some(
            (insurance: any) =>
              filters.payerId.includes(insurance.payerId) &&
              insurance.patientId === item.id,
          );

        return (
          matchesCpt &&
          matchesLocation &&
          matchesStaff &&
          matchesAddress &&
          isPayerSelected
        );
      });
      setFilteredSessions(filtered);
    };

    applyAdditionalFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  return (
    <View
      style={[
        styles.border,
        styles.borderColorGray400,
        styles.borderRadius,
        styles.marginMVertical,
        filters.hideEmptyClients && !filteredSessions.length
          ? styles.hide
          : styles.show,
      ]}>
      <TouchableOpacity
        onPress={() => setOpen(!open)}
        style={[styles.row, styles.justifySpaceBetween, styles.alignCenter]}>
        <View style={[styles.padding, styles.row, styles.alignCenter]}>
          <TouchableOpacity
            onPress={() => {
              setSelectAll(!selectAll);
              setSelectedItems(selectAll ? [] : sessionIds);
            }}
            style={[styles.row, styles.alignCenter, styles.marginRight]}>
            <Checkbox.Android
              disabled={!filteredSessions.length}
              status={selectAll ? 'checked' : 'unchecked'}
              color={Colors.RAVEN_BLACK}
              uncheckedColor={Colors.RAVEN_BLACK}
            />
          </TouchableOpacity>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            {item.firstName} {item.lastName}
          </Text>
          <Separator width={20} />
          <Text style={[Typography.CAPTION_BOLD, styles.textColorPrimary]}>
            {filteredSessions.length} Sessions
          </Text>
          {sortedPayers.length ? (
            <>
              <Text style={[Typography.CAPTION_BOLD, styles.textColorPrimary]}>
                {` | ${sortedPayers[0]?.name}`}
              </Text>
              <Text style={[Typography.CAPTION_BOLD, styles.textColorPrimary]}>
                {sortedPayers[1] ? `, ${sortedPayers[1]?.name}` : null}
              </Text>
            </>
          ) : (
            <></>
          )}
        </View>
        <IconButton
          color={Colors.RAVEN_BLACK}
          icon={!open ? 'chevron-up' : 'chevron-down'}
          onPress={() => setOpen(!open)}
          style={[styles.paddingRight]}
        />
      </TouchableOpacity>
      <Collapsible collapsed={!open} renderChildrenCollapsed={false}>
        <ListItemSeparator />
        <FlatList
          data={filteredSessions}
          renderItem={({item: session}: any) => {
            return (
              <PatientClaimSessionsItem
                item={session}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                plans={sortedPlans}
              />
            );
          }}
        />
      </Collapsible>
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['item', 'filters', 'showExcludedSessions'],
    ({item, authentication, database, filters}: any) => {
      return {
        sessions: database
          .get(Session.table)
          .query(
            Q.where('patient_id', item?.id),
            Q.where('deleted_at', null),
            Q.where('_partition', authentication.selectedGroup),
            Q.where(
              'claim_id',
              Q.eq(filters.showExcludedSessions ? 'exclude' : ''),
            ),
            Q.where('submission_timestamp', Q.notEq(null)),
            Q.where(
              'date',
              Q.between(
                startOfDay(filters.startDate).getTime(),
                endOfDay(filters.endDate).getTime(),
              ),
            ),
            Q.sortBy('session_date', Q.desc),
          ),
        insurances: item.activeInsurances,
      };
    },
  ),
  withObservables(['insurances'], ({insurances}: any) => ({
    payerIds: of(insurances.map((insurance: Insurance) => insurance.payerId)),
    planIds: of(insurances.map((insurance: Insurance) => insurance.planId)),
  })),
  withObservables(['payerIds'], ({payerIds, planIds, database}: any) => ({
    payers: database
      .get(Payer.table)
      .query(Q.where('id', Q.oneOf(payerIds)))
      .observe(),
    plans: database
      .get(PayerPlans.table)
      .query(
        Q.where('payer_id', Q.oneOf(payerIds)),
        Q.where('id', Q.oneOf(planIds)),
        Q.where('deleted_at', null),
      )
      .observe(),
  })),
)(PatientClaim);
