import React from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';

import {Colors, Typography} from 'src/styles';

const SessionProgramPrompt = ({
  selected,
  prompt,
  onClk,
  currentPrompt = undefined,
}: any) => {
  if (currentPrompt) {
    return (
      <TouchableOpacity
        style={[
          styles.promptCard,
          currentPrompt === prompt
            ? styles.selectedPromptCard
            : styles.unselectedPromptCard,
        ]}
        onPress={onClk}>
        <Text
          style={[
            Typography.P2_BOLD,
            currentPrompt === prompt
              ? styles.selectedPromptTxt
              : styles.unselectedPromptTxt,
          ]}>
          {prompt?.acronym || ''}
        </Text>
      </TouchableOpacity>
    );
  } else {
    return (
      <TouchableOpacity
        style={[
          styles.promptCard,
          selected ? styles.selectedPromptCard : styles.unselectedPromptCard,
        ]}
        onPress={onClk}>
        <Text
          style={[
            Typography.P2_BOLD,
            selected ? styles.selectedPromptTxt : styles.unselectedPromptTxt,
          ]}>
          {prompt?.acronym || ''}
        </Text>
      </TouchableOpacity>
    );
  }
};

const styles = StyleSheet.create({
  promptCard: {
    borderRadius: 7,
    width: 65,
    height: 35,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    marginBottom: 20,
  },
  selectedPromptCard: {
    backgroundColor: Colors.TERTIARY_LAVENDER,
  },
  unselectedPromptCard: {
    backgroundColor: Colors.GRAY_50,
    borderColor: Colors.GRAY_100,
    borderWidth: 1,
  },
  selectedPromptTxt: {
    color: Colors.RAVEN_WHITE,
  },
  unselectedPromptTxt: {
    color: Colors.TEXT_PRIMARY,
  },
});

export default SessionProgramPrompt;
