import React from 'react';
import {DataItem, TimeInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import moment from 'moment';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey?: string;
  name?: string;
  date?: Date | undefined;
}

const resolveDisplayValue = (value: string) => {
  return moment(value).format('ll');
};

const EndTimeDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>End Time</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const EndTimeInput = ({valueKey, name = 'End Time', date}: Props) => {
  return (
    <TimeInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'endTime'}
      name={name}
      date={date}
      required={true}
    />
  );
};

export const validation = () => Yup.string().required('End time is required');

export const Display = EndTimeDisplay;
export const Input = EndTimeInput;
export const Value = resolveDisplayValue;

export default EndTimeInput;
