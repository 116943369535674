import React, {useEffect, useState} from 'react';
import {RetractingDrawer} from 'src/design-system';
import {Text, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useDimensions} from '@react-native-community/hooks';
import CreateClaims from 'src/modules/billing/components/create-claims';
import ClaimList from 'src/modules/billing/components/claim-list';
import {compose} from 'recompose';
import {useTranslations} from 'src/providers/translation';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {CollapsibleFilterItem} from 'src/design-system';
import HookFormDateInput from 'src/hook-form-wrapper/date-input';
import CptCheckbox from 'src/hook-form-inputs/cpt-checkbox';
import LocationCheckbox from 'src/hook-form-inputs/location-checkbox';
import ConfiguredPayersCheckBox from 'src/hook-form-inputs/configured-payers-checkbox';
import {Separator} from 'src/common-components/atoms';
import ClientSelect from 'src/hook-form-inputs/client';
import StaffCheckbox from 'src/hook-form-inputs/staff-checkbox';
import {Claim} from 'src/models';
import {FormProvider, useForm, Controller, useWatch} from 'react-hook-form';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import CheckboxItemsInput from 'src/hook-form/checkbox-input';
import FormLabel from 'src/design-system/form-label';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useDispatch, useSelector} from 'react-redux';
import {setFilters} from 'src/redux/claim-filters';

const Claims = ({
  unsubmittedCount,
  submittedCount,
  approvedCount,
  deniedCount,
}: any) => {
  const dimensions = useDimensions();
  const styles = useStyle();
  const translations = useTranslations();
  const dispatch = useDispatch();
  const {selectedFilters} = useSelector(state => state.claimFilters);
  const [open, setOpen] = useState(true);

  const [tabs, setTabs] = useState([
    {
      type: 'local',
      icon: 'list',
      value: 'list',
      title: 'Claim List',
    },
    {type: 'separator', value: 'separator'},
  ]);
  const claimsStatuses = [
    {value: 'unsubmitted', label: `To Be Submitted (${unsubmittedCount})`},
    {value: 'submitted', label: `Submitted (${submittedCount})`},
    {value: 'approved', label: `Approved (${approvedCount})`},
    {value: 'denied', label: `Denied (${deniedCount})`},
  ];
  const [selectedTab, setSelectedTab] = useState<string>('create');
  const methods = useForm({
    defaultValues: {
      ...selectedFilters,
      startDate: new Date(selectedFilters.startDate),
      endDate: new Date(selectedFilters.endDate),
    },
  });

  const filters = useWatch({
    control: methods.control,
  });

  useEffect(() => {
    const subscription = methods.watch(() => {
      dispatch(
        setFilters({
          ...methods.getValues(),
          startDate: methods.getValues().startDate.getTime(),
          endDate: methods.getValues().endDate.getTime(),
        }),
      );
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dispatch]);

  const tabSelected = (tab: any) => {
    if (tab.value === 'list') {
      setTabs([
        {
          type: 'local',
          icon: 'add',
          value: 'create',
          title: 'Create Claim',
        },
        {type: 'separator', value: 'separator'},
      ]);
    } else {
      setTabs([
        {
          type: 'local',
          icon: 'list',
          value: 'list',
          title: 'Claim List',
        },
        {type: 'separator', value: 'separator'},
      ]);
    }
    setSelectedTab(tab.value);
  };

  return (
    <View style={[styles.row, styles.flex, styles.width]}>
      <RetractingDrawer
        opening={() => setOpen(true)}
        closing={() => setOpen(false)}
        type={dimensions.window.width > 1024 ? 'persistent' : 'overlay'}
        position={'left'}>
        {tabs.map((tab: any) => {
          if (tab.type === 'separator') {
            return <View key={tab?.value} style={[styles.separator]} />;
          }
          return (
            <TouchableOpacity key={tab.value} onPress={() => tabSelected(tab)}>
              <View
                style={[
                  styles.marginMVertical,
                  styles.row,
                  styles.alignCenter,
                  styles.borderRadiusSM,
                  styles.overflowHidden,
                ]}>
                <View style={[styles.positionRelative, styles.paddingM]}>
                  <Icon name={tab.icon} size={20} color={Colors.PRIMARY_700} />
                </View>
                <Text
                  style={[
                    Typography.CAPTION_MEDIUM,
                    styles.textColorPrimary700,
                    styles.marginMLeft,
                    styles.marginSMTop,
                  ]}
                  numberOfLines={1}>
                  {tab.title}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
        {open ? (
          <FormProvider {...methods}>
            <View style={[styles.flex, styles.column]}>
              <Separator height={20} width={20} />
              <ClientSelect multiple={true} label={'Select Client'} />
              <HookFormDateInput
                name={'startDate'}
                label={'Start Date'}
                placeholder={'Start Date'}
                showHelper={false}
              />
              <Separator height={20} width={20} />
              <HookFormDateInput
                name={'endDate'}
                label={'End Date'}
                placeholder={'End Date'}
                showHelper={false}
              />
              <CollapsibleFilterItem title={translations('billing_codes')}>
                <CptCheckbox allowSelectAll={true} search={true} />
              </CollapsibleFilterItem>
              <CollapsibleFilterItem title={translations('place_of_service')}>
                <LocationCheckbox allowSelectAll={true} search={true} />
              </CollapsibleFilterItem>
              <CollapsibleFilterItem title={translations('payers')}>
                <ConfiguredPayersCheckBox allowSelectAll={true} search={true} />
              </CollapsibleFilterItem>
              <CollapsibleFilterItem title={translations('staff')}>
                <StaffCheckbox allowSelectAll={true} search={true} />
              </CollapsibleFilterItem>
              {selectedTab === 'create' ? (
                <>
                  <HookFormSwitchInput
                    label={'Show Excluded Sessions'}
                    name={'showExcludedSessions'}
                    showHelper={false}
                  />
                  <HookFormSwitchInput
                    label={'Hide Clients with 0 Available Sessions'}
                    name={'hideEmptyClients'}
                    showHelper={false}
                  />
                </>
              ) : (
                <>
                  <FormLabel label={translations('claim_status')} />
                  <Controller
                    control={methods.control}
                    rules={{}}
                    render={({field: {onChange, value}}) => (
                      <CheckboxItemsInput
                        allowSelectAll={true}
                        value={value}
                        onChange={onChange}
                        items={claimsStatuses}
                      />
                    )}
                    name="status"
                  />
                </>
              )}
              <RHButton
                secondary
                onPress={() => methods.reset()}
                textColor={Colors.RAVEN_BLACK}
                color="black"
                mode="outlined"
                style={[styles.marginTop, styles.width]}>
                {translations('reset')}
              </RHButton>
            </View>
          </FormProvider>
        ) : null}
      </RetractingDrawer>
      {selectedTab === 'create' ? <CreateClaims filters={filters} /> : null}
      {selectedTab === 'list' ? <ClaimList filters={filters} /> : null}
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    unsubmittedCount: database
      .get(Claim.table)
      .query(
        Q.where('_partition', authentication.selectedGroup),
        Q.where('deleted_at', Q.eq(null)),
        Q.where('status', 'unsubmitted'),
      )
      .observeCount(),
    submittedCount: database
      .get(Claim.table)
      .query(
        Q.where('_partition', authentication.selectedGroup),
        Q.where('deleted_at', Q.eq(null)),
        Q.where('status', 'submitted'),
      )
      .observeCount(),
    approvedCount: database
      .get(Claim.table)
      .query(
        Q.where('_partition', authentication.selectedGroup),
        Q.where('deleted_at', Q.eq(null)),
        Q.where('status', 'approved'),
      )
      .observeCount(),
    deniedCount: database
      .get(Claim.table)
      .query(
        Q.where('_partition', authentication.selectedGroup),
        Q.where('deleted_at', Q.eq(null)),
        Q.where('status', 'denied'),
      )
      .observeCount(),
  })),
)(Claims);
