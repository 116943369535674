import React from 'react';
import {PreviewProps} from 'src/modules/form-builder/utils/types';
import ListItemSeparator from 'src/common-components/separator';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';

export const Details = ({}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.paddingMVertical]}>
      <ListItemSeparator />
    </View>
  );
};

export const Definition = () => ({
  name: 'Separator',
  label: '',
  placeholder: '',
  componentType: 'SEPARATOR',
  icon: 'ray-start-end',
  options: [],
  helper: '',
});

export const Preview = ({}: PreviewProps) => <ListItemSeparator />;
