import React from 'react';
import {Payer} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import CheckboxInput from 'src/hook-form-wrapper/checkbox-input';

interface Props {
  items: any[];
  name: string;
  allowSelectAll: boolean;
  search: boolean;
}

const ConfiguredPayersCheckBox = ({
  name,
  allowSelectAll,
  search = false,
  items,
}: Props) => {
  return (
    <CheckboxInput
      name={(name ? name + '.' : '') + 'payerId'}
      items={items}
      allowSelectAll={allowSelectAll}
      search={search}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    payers: database
      .get(Payer.table)
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('name', Q.asc),
      ),
  })),
  withObservables([], ({payers}: any) => ({
    items: of(
      payers.map((payer: Payer) => {
        return {
          label: payer.name,
          value: payer.id,
        };
      }),
    ),
  })),
)(ConfiguredPayersCheckBox);
