import {Q} from '@nozbe/watermelondb';
import {text, relation, field, lazy} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class SessionProgram extends Base {
  static table = 'session_programs';
  static associations = {
    programs: {type: 'belongs_to', key: 'program_id'},
    sessions: {type: 'belongs_to', key: 'session_id'},
    session_targets: {type: 'has_many', foreignKey: 'session_program_id'},
  };

  @relation('programs', 'program_id') program;
  @relation('sessions', 'session_id') session;

  // @children('session_target') targets;

  @lazy
  sessionTargets = this.collections
    .get('session_targets')
    .query(Q.where('session_program_id', this.id), Q.sortBy('rank', Q.asc));

  @text('rank') rank;
  @field('enabled') enabled;
  @text('program_id') programId;
}
