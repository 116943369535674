import React from 'react';
import SVG from 'src/styles/svg';
import {TouchableOpacity} from 'react-native';
import {useNavigation} from '@react-navigation/core';
import {useStyle} from 'src/providers/style';

const HeaderTitleLogo = () => {
  const navigation = useNavigation();
  const styles = useStyle();

  return (
    <TouchableOpacity
      style={[
        styles.width109,
        styles.row,
        styles.justifyCenter,
        styles.alignCenter,
        styles.overflowHidden,
      ]}
      onPress={() => navigation.navigate('Dashboard')}>
      <SVG height={30} name={'logo'} />
    </TouchableOpacity>
  );
};

export default HeaderTitleLogo;
