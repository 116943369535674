import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {
  Animated,
  LayoutRectangle,
  Modal,
  ScrollView,
  TouchableOpacity,
  View,
  Dimensions,
} from 'react-native';
import {useStyle} from 'src/providers/style';
import {useDimensions} from '@react-native-community/hooks';
import {Easing} from 'react-native-reanimated';

interface Props {
  visible: boolean;
  anchor: JSX.Element;
  children: JSX.Element | JSX.Element[];
  onDismiss?: () => void;
}

const Menu = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({visible, onDismiss, anchor, children}: Props, ref) => {
    const styles = useStyle();
    const dimensions = useDimensions();

    const anchorRef = useRef();
    const menuRef = useRef();
    const opacity = useRef(new Animated.Value(0)).current;

    const [inputLayout, setInputLayout] = useState<{
      show: boolean;
      maxHeight: any;
      left: number;
      top: number;
    }>({
      show: false,
      maxHeight: 'auto',
      left: 0,
      top: 0,
    });

    const measureMenuLayout = () =>
      new Promise<LayoutRectangle>(resolve => {
        if (menuRef?.current) {
          menuRef?.current.measureInWindow((x, y, width, height) => {
            resolve({x, y, width, height});
          });
        } else {
          resolve({x: 0, y: 0, width: 0, height: 0});
        }
      });

    const measureAnchorLayout = () =>
      new Promise<LayoutRectangle>(resolve => {
        if (anchorRef?.current) {
          anchorRef?.current.measureInWindow((x, y, width, height) => {
            resolve({x, y, width, height});
          });
        } else {
          resolve({x: 0, y: 0, width: 0, height: 0});
        }
      });

    const show = async () => {
      const [anchorLayout, menuLayout] = await Promise.all([
        measureAnchorLayout(),
        measureMenuLayout(),
      ]);

      if (
        !menuLayout.width ||
        !menuLayout.height ||
        !anchorLayout.width ||
        !anchorLayout.height
      ) {
        requestAnimationFrame(show);
        return;
      }

      setInputLayout({
        show: true,
        top:
          anchorLayout.y + menuLayout.height > dimensions.window.height
            ? anchorLayout.y - menuLayout.height + anchorLayout.height < 0
              ? 0
              : anchorLayout.y - menuLayout.height + anchorLayout.height
            : anchorLayout.y,
        maxHeight:
          dimensions.window.height -
          (anchorLayout.y + menuLayout.height > dimensions.window.height
            ? anchorLayout.y - menuLayout.height + anchorLayout.height < 0
              ? 0
              : anchorLayout.y - menuLayout.height
            : anchorLayout.y),
        left:
          anchorLayout.x + menuLayout.width > dimensions.window.width
            ? anchorLayout.x - menuLayout.width + anchorLayout.width < 0
              ? 0
              : anchorLayout.x - menuLayout.width + anchorLayout.width
            : anchorLayout.x,
      });
    };

    useEffect(() => {
      if (visible) {
        requestAnimationFrame(show);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
      const handleDimensionChange = () => {
        if (visible) {
          requestAnimationFrame(show);
        }
      };

      const resizeSubscription = Dimensions.addEventListener(
        'change',
        handleDimensionChange,
      );

      return () => {
        resizeSubscription.remove();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
      if (inputLayout.show) {
        Animated.timing(opacity, {
          toValue: 1,
          duration: 250,
          easing: Easing.out(Easing.cubic),
          useNativeDriver: true,
        }).start();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputLayout.show]);

    const close = () => {
      // setOpen(false);
      onDismiss?.();
    };

    return (
      <View ref={assignedRef => (anchorRef.current = assignedRef)}>
        {anchor}

        <Modal visible={visible} transparent>
          <View style={[styles.positionRelative, styles.width, styles.flex]}>
            <TouchableOpacity
              onPress={() => close()}
              style={[
                styles.width,
                styles.height,
                styles.positionAbsolute,
                styles.backgroundColorTransparent,
                styles.top0,
                styles.left0,
              ]}
            />
            <Animated.View
              style={[
                styles.positionAbsolute,
                styles.borderRadiusSM,
                styles.flex,
                styles.elevation,
                styles.backgroundColorWhite,
                {
                  maxHeight: inputLayout.maxHeight,
                  top: inputLayout.top,
                  left: inputLayout.left,
                  opacity,
                },
              ]}
              ref={assignedRef => {
                menuRef.current = assignedRef;
              }}>
              <ScrollView>{children}</ScrollView>
            </Animated.View>
          </View>
        </Modal>
      </View>
    );
  },
);

export default Menu;
