import React from 'react';
import {Email, Name, PhoneNumber, Title} from 'src/hook-form-inputs';

interface Props {}

const SignupForm = ({}: Props) => {
  return (
    <>
      <Name.Input required={true} />
      <Email.Input required={true} />
      <PhoneNumber.Input />
      <Title.Input />
    </>
  );
};

export default SignupForm;
