import React from 'react';
import {FlatList, Text, View} from 'react-native';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {default as MUITimelineItem} from '@mui/lab/TimelineItem';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import moment from 'moment';
import {Colors, Typography} from 'src/styles';
import TimelineItem from 'src/design-system/timeline-item';
import {useStyle} from 'src/providers/style';

interface Props {
  items: any[];
  canEdit: boolean;
  editItem: (item: any) => void;
  canDelete: boolean;
  deleteItem: (item: any) => void;
}

const Timeline = ({items, canEdit, editItem, canDelete, deleteItem}: Props) => {
  const styles = useStyle();

  return (
    <FlatList
      data={items}
      keyExtractor={item => item?.id}
      renderItem={({item, index}: any) => {
        return (
          <MUITimelineItem
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}>
            <TimelineOppositeContent>
              <View style={[styles.borderRadius, styles.backgroundColorBlack]}>
                <Text
                  style={[
                    Typography.P3_MEDIUM,
                    styles.textColorWhite,
                    styles.textAlignCenter,
                    styles.paddingMVertical,
                  ]}>
                  {moment(item?.createdAt).format('l')}
                </Text>
              </View>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  backgroundColor: Colors.RAVEN_BLACK,
                  mt: '18px',
                  mb: '4px',
                }}
              />
              {index < items?.length - 1 && (
                <TimelineConnector
                  sx={{
                    backgroundColor: Colors.RAVEN_BLACK,
                    width: '.25px',
                  }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <View
                style={[
                  styles.borderRadius,
                  styles.backgroundColorPrimary50,
                  styles.textColorPrimary,
                  styles.paddingLHorizontal,
                  styles.marginBottom,
                ]}>
                <TimelineItem
                  item={item}
                  canEdit={canEdit}
                  editCallback={() => editItem(item)}
                  canDelete={canDelete}
                  deleteCallback={() => deleteItem(item)}
                />
              </View>
            </TimelineContent>
          </MUITimelineItem>
        );
      }}
    />
  );
};

export default Timeline;
