import * as FormField from './form-field';
import * as Checklist from './check-list';
import * as Dropdown from './dropdown';
import * as Radio from './radio';
import * as DateInput from './date';
import * as TextDisplay from './text-display';
import * as Separator from './separator';

export const formInputs = {
  RADIO: {
    details: Radio.Details,
    definition: Radio.Definition(),
    input: Radio.Input,
    preview: Radio.Preview,
    output: Radio.Output,
    text: 'A radio button must contain at least two options. The first option is defaulted as “on.”',
  },
  CHECKLIST: {
    details: Checklist.Details,
    definition: Checklist.Definition(),
    input: Checklist.Input,
    preview: Checklist.Preview,
    output: Checklist.Output,
    text: 'Checklists allow for multiple selections.',
  },
  INPUT: {
    details: FormField.Details,
    definition: FormField.Definition(),
    input: FormField.Input,
    preview: FormField.Preview,
    output: FormField.Output,
    text: 'Form fields are editable, free form text fields',
  },
  DATE: {
    details: DateInput.Details,
    definition: DateInput.Definition(),
    input: DateInput.Input,
    preview: DateInput.Preview,
    output: DateInput.Output,
    text: 'Date picker component',
  },
  TEXT: {
    details: TextDisplay.Details,
    definition: TextDisplay.Definition(),
    input: TextDisplay.Preview,
    preview: TextDisplay.Preview,
    output: TextDisplay.Preview,
    text: 'This text display will not be clickable or editable',
  },
  DROPDOWN: {
    details: Dropdown.Details,
    definition: Dropdown.Definition(),
    input: Dropdown.Input,
    preview: Dropdown.Preview,
    output: Dropdown.Output,
    text: 'Dropdown menus allow for multiple selections.',
  },
  SEPARATOR: {
    details: Separator.Details,
    definition: Separator.Definition(),
    input: Separator.Preview,
    preview: Separator.Preview,
    output: Separator.Preview,
    text: '',
  },
};
