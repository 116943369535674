import React from 'react';
import {View, StyleSheet, Text, TouchableOpacity} from 'react-native';
import {HelperText} from 'react-native-paper';
import {useField, FastField} from 'formik';
import {RRule} from 'rrule';

import {Typography, Colors} from 'src/styles';

const RepeatByWeekDayInput = () => {
  const repeatFreqField = useField('repeatFreq');

  const weekArr = [
    {value: RRule.SU, label: 'S'},
    {value: RRule.MO, label: 'M'},
    {value: RRule.TU, label: 'T'},
    {value: RRule.WE, label: 'W'},
    {value: RRule.TH, label: 'Th'},
    {value: RRule.FR, label: 'F'},
    {value: RRule.SA, label: 'S'},
  ];

  if (repeatFreqField[1].value === RRule.WEEKLY.toString()) {
    return (
      <>
        <Text style={styles.label}>Repeat on*</Text>
        <FastField name="repeatByWeekDay">
          {({field, form: {setFieldValue}, meta: {error, touched}}: any) => {
            const weekValues = field.value || [];
            return (
              <>
                <View style={[styles.flexRow, styles.flexWrap]}>
                  {weekArr.map(week => (
                    <TouchableOpacity
                      onPress={() => {
                        if (weekValues.includes(week.value)) {
                          setFieldValue(
                            field.name,
                            weekValues.filter(
                              (item: string) => item !== week.value,
                            ),
                          );
                        } else {
                          setFieldValue(field.name, [
                            ...weekValues,
                            week.value,
                          ]);
                        }
                      }}
                      style={[
                        styles.button,
                        weekValues?.includes(week.value) ? styles.selected : {},
                        styles.mv_5,
                      ]}>
                      <Text
                        key={`byweekday-key-${week.value}`}
                        style={[
                          weekValues?.includes(week.value)
                            ? styles.selectedButtonText
                            : styles.buttonText,
                        ]}>
                        {week.label}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </View>
                <HelperText type="error" visible={true}>
                  {error && touched && 'ⓧ ' + error}
                </HelperText>
              </>
            );
          }}
        </FastField>
      </>
    );
  } else {
    return <></>;
  }
};

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  mv_5: {
    marginVertical: 5,
  },
  label: {
    ...Typography.LABEL,
    color: Colors.TEXT_SECONDARY,
    paddingBottom: 8,
  },
  buttonText: {
    color: Colors.TEXT_PRIMARY,
  },
  selectedButtonText: {
    color: Colors.RAVEN_WHITE,
  },
  button: {
    height: 44,
    borderColor: Colors.PRIMARY_900,
    borderWidth: 1,
    borderRadius: 22,
    paddingVertical: 10,
    paddingHorizontal: 24,
    marginRight: 20,
  },
  selected: {
    backgroundColor: Colors.PRIMARY_900,
  },
});

export const Input = RepeatByWeekDayInput;

export default RepeatByWeekDayInput;
