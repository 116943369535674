import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
  VictoryVoronoiContainer,
} from 'victory-native';
import {Colors, Typography} from 'src/styles';
import React from 'react';
import _ from 'lodash';
import {useDimensions} from '@react-native-community/hooks';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import {CustomBar} from '../trial_by_trial_summary_graph';

const TaskAnalysisSummaryGraph = ({
  yAxisLabel = '',
  targets = [],
  events = [],
}: any) => {
  const dimensions = useDimensions();

  const domain = {
    x: [0, (targets.length || 1) + 1],
    y: [0, 100],
  };

  const targetAggregate = {};
  const eventsByTargetId = _.groupBy(events, 'target.id');

  for (const target of targets) {
    let sum = 0;
    if (eventsByTargetId?.[target.id]) {
      for (const event of eventsByTargetId?.[target.id] || []) {
        sum = sum + (event.value === 'success' ? 1 : 0);
      }
      targetAggregate[target.id] = Math.ceil(
        (sum * 100) / (eventsByTargetId?.[target.id]?.length || 1),
      );
    }
  }

  return (
    <>
      <VictoryChart
        theme={VictoryTheme.material}
        width={dimensions.screen.width * 0.95}
        domain={domain}
        padding={{
          bottom: 130,
          left: 50,
        }}
        domainPadding={{x: 40, y: 30}}
        containerComponent={<VictoryVoronoiContainer />}>
        <VictoryAxis
          domain={{y: [0, 100]}}
          domainPadding={{x: [10, -10], y: 5}}
          tickCount={10}
          tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          dependentAxis
          label={yAxisLabel}
          style={{
            axis: {stroke: 'transparent'},
          }}
          tickFormat={x => `${x}%`}
          axisLabelComponent={
            <VictoryLabel
              style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
              x={10}
            />
          }
        />
        <VictoryAxis
          style={{
            axis: {stroke: Colors.GRAY_100},
            tickLabels: {
              ...Typography.CAPTION,
              fill: Colors.PRIMARY_600,
              angle: 45,
              fontSize: 10,
            },
            grid: {stroke: 'transparent'},
          }}
          fixLabelOverlap={true}
          tickCount={targets?.length}
          tickValues={_.range(1, targets.length + 1)}
          tickFormat={x => {
            return targets[x - 1]?.name;
          }}
          tickLabelComponent={
            <VictoryLabel
              textAnchor={'start'}
              style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
              dy={-10}
            />
          }
        />
        return (
        <VictoryBar
          data={targets.map((target, index) => ({
            x: index + 1,
            y: targetAggregate[target?.id] || 0,
          }))}
          dataComponent={<CustomBar />}
          style={{
            data: {fill: Colors.GRAY_400},
          }}
          barWidth={30}
          labels={({data, index}) => `${data[index]?.y}%`}
          labelComponent={
            <VictoryLabel
              style={{...Typography.H8, fill: Colors.TEXT_PRIMARY}}
            />
          }
        />
        );
      </VictoryChart>
    </>
  );
};

export default compose(
  withDatabase,
  withObservables(['sets'], ({sets, database}: any) => {
    const setIds = _.map(sets, 'id');
    return {
      events: database
        .get('events')
        .query(
          Q.where('set_id', Q.oneOf(setIds)),
          Q.where('deleted_at', null),
          Q.sortBy('created_at', Q.desc),
        ),
    };
  }),
)(TaskAnalysisSummaryGraph);
