import React from 'react';
import {FlatList, Text, TouchableOpacity, View} from 'react-native';
import {Typography} from 'src/styles';
import PatientComponent from './patientComponent';
import {useNavigation} from '@react-navigation/core';
import ListItemSeparator from 'src/common-components/separator';
import {useStyle} from 'src/providers/style';
import NoResults from 'src/common-components/noResults';
import {useTranslations} from 'src/providers/translation';

const HomePatients = ({patients = [], canView}: any) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <View
      style={[
        styles.width,
        styles.backgroundColorWhite,
        styles.paddingHorizontal,
        styles.paddingLTop,
        styles.borderRadius,
        styles.elevation,
      ]}>
      <Text style={[Typography.H6, styles.marginMBottom]}>
        {translations('assigned_clients')}
      </Text>
      <View style={styles.separator} />
      {patients?.length > 0 ? (
        <FlatList
          style={styles.scroll}
          keyExtractor={item => item.id}
          data={patients}
          renderItem={({item}) => (
            <PatientComponent key={item.id} patient={item} />
          )}
          ItemSeparatorComponent={ListItemSeparator}
        />
      ) : (
        <NoResults
          iconName={'user'}
          secondaryMessage={translations('no_clients')}
        />
      )}
      <View style={styles.separator} />
      {canView ? (
        <TouchableOpacity
          onPress={() => navigation.navigate('PatientList')}
          style={[styles.paddingVertical]}>
          <Text style={[Typography.H6, styles.textAlignCenter]}>
            {translations('view_client_list')}
          </Text>
        </TouchableOpacity>
      ) : (
        <></>
      )}
    </View>
  );
};

export default HomePatients;
