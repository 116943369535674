import {TouchableOpacity, View, Platform} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors} from 'src/styles';
import React from 'react';
import {useStyle} from 'src/providers/style';

interface Props {
  content: JSX.Element;
  drag: () => void;
  isActive: boolean;
  enabled: boolean;
  toggle: () => void;
  canEdit: boolean;
  editCallback: () => void;
  canDelete: boolean;
  deleteCallback: () => void;
}

const ListItem = ({
  content,
  drag,
  isActive,
  enabled,
  toggle,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
}: Props) => {
  const styles = useStyle();
  const isWeb = Platform.OS === 'web';

  return (
    <View
      style={[
        styles.elevationSM,
        styles.borderRadius,
        styles.flex,
        styles.row,
        styles.marginHorizontal,
        styles.alignItemsCenter,
        styles.marginLBottom,
        isActive
          ? styles.backgroundColorSecondary200
          : styles.backgroundColorWhite,
      ]}>
      {canEdit ? (
        <TouchableOpacity
          onPressIn={isWeb ? drag : () => {}}
          onLongPress={!isWeb ? drag : () => {}}
          delayLongPress={50}>
          <Icon
            name="reorder-horizontal"
            size={20}
            color={Colors.PRIMARY_400}
            style={[styles.padding]}
          />
        </TouchableOpacity>
      ) : null}
      {canEdit ? (
        <TouchableOpacity style={[styles.paddingVertical]} onPress={toggle}>
          <Icon
            name={enabled ? 'checkbox-marked' : 'checkbox-blank-outline'}
            size={20}
            color={Colors.PRIMARY_900}
          />
        </TouchableOpacity>
      ) : null}
      <View
        style={[
          styles.flex,
          styles.paddingLeft,
          styles.alignItemsCenter,
          styles.justifyCenter,
        ]}>
        {content}
      </View>
      {canEdit ? (
        <TouchableOpacity style={styles.padding} onPress={editCallback}>
          <Icon name={'pencil'} size={20} color={Colors.PRIMARY_900} />
        </TouchableOpacity>
      ) : (
        <></>
      )}
      {canDelete ? (
        <TouchableOpacity
          style={[styles.paddingVertical, styles.paddingRight]}
          onPress={deleteCallback}>
          <Icon name={'trash-can'} size={20} color={Colors.PRIMARY_900} />
        </TouchableOpacity>
      ) : (
        <></>
      )}
    </View>
  );
};

export default ListItem;
