import React from 'react';
import {HelperText} from 'react-native-paper';
import {Platform, Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {FastField} from 'formik';
import {memoize} from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';

interface Props {
  valueKey: string;
  name: string;
  required?: boolean;
  buttons: {value: string; label: string}[];
  style?: any;
}

const ToggleButtonInput = ({
  valueKey,
  name,
  required = false,
  buttons = [],
  style = {},
}: Props) => {
  const styles = getStyles();

  return (
    <View style={styles.container}>
      <FastField name={valueKey}>
        {({field, form: {setFieldValue}, meta: {error, touched}}: any) => {
          return (
            <>
              <Text
                style={[
                  styles.label,
                  {
                    color:
                      error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY,
                  },
                ]}>
                {name + (required ? '*' : '')}
              </Text>
              <View style={styles.toggleButtonContainer}>
                {buttons.map(({value, label}) => {
                  return (
                    <Text
                      key={`toggle-key-${name}-${value}-${label}`}
                      style={[
                        styles.toggleButton,
                        field.value === value
                          ? styles.toggleButtonSelected
                          : {},
                        style,
                      ]}
                      onPress={() => setFieldValue(field.name, value)}>
                      {label}
                    </Text>
                  );
                })}
              </View>
              <HelperText type="error" visible={true} style={styles.helper}>
                {error && touched && 'ⓧ ' + error}
              </HelperText>
            </>
          );
        }}
      </FastField>
    </View>
  );
};

const getStyles = memoize(() => {
  return EStyleSheet.create({
    container: {
      flexWrap: 'wrap',
    },
    toggleButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    toggleButton: {
      ...Typography.P3,
      color: Colors.RAVEN_BLACK,
      backgroundColor: Colors.RAVEN_WHITE,
      borderWidth: 1,
      borderColor: Colors.RAVEN_BLACK,
      paddingHorizontal: 10,
      paddingVertical: 5,
      marginBottom: 10,
      marginRight: 16,
      alignSelf: 'flex-start',
      ...(Platform.OS === 'ios'
        ? {overflow: 'hidden', borderRadius: 15}
        : {borderRadius: 30}),
    },
    toggleButtonSelected: {
      color: Colors.RAVEN_WHITE,
      backgroundColor: Colors.RAVEN_BLACK,
      borderColor: 'transparent',
    },
    label: {
      ...Typography.LABEL,
      color: Colors.TEXT_SECONDARY,
      paddingBottom: 10,
    },
    helper: {},
  });
});

export default ToggleButtonInput;
