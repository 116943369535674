import React, {forwardRef, useMemo, useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {Checkbox, HelperText, Searchbar} from 'react-native-paper';
import _ from 'lodash';
import {fuzzy} from 'src/common-utils/fuzzy';
import {useStyle} from 'src/providers/style';

interface Props {
  style?: any;
  value: string[];
  name?: string;
  required?: boolean;
  items: {label: string; value: string}[];
  onChange: (value: string[]) => void;
  allowSelectAll?: boolean;
  showTitle?: boolean;
  itemStyle?: any;
  search?: boolean;
  error?: any;
  helper?: string;
  showHelper?: boolean;
}

const CheckboxInput = forwardRef(
  ({
    style = {},
    value = [],
    items = [],
    onChange,
    showTitle = true,
    allowSelectAll = false,
    itemStyle = {},
    search = false,
    error = null,
    helper,
    showHelper = true,
  }: Props) => {
    const styles = useStyle();

    const [searchStr, setSearchStr] = useState<string>('');

    const searchedItems = useMemo(() => {
      return items?.filter((item: any) => fuzzy(item?.label, searchStr));
    }, [items, searchStr]);

    const checkClk = (item: any) => {
      if (value.includes(item)) {
        onChange(value.filter(selected => selected !== item));
      } else {
        onChange([...value, item]);
      }
    };

    return (
      <View style={style}>
        {search ? (
          <View style={[styles.flex, styles.marginLBottom]}>
            <Searchbar
              value={searchStr}
              onChangeText={setSearchStr}
              placeholder="Search"
            />
          </View>
        ) : (
          <></>
        )}
        {allowSelectAll ? (
          <TouchableOpacity
            onPress={() => {
              if (value?.length === items?.length) {
                onChange([]);
              } else {
                onChange(_.map(items, 'value'));
              }
            }}
            style={[styles.row, styles.alignCenter]}>
            <Checkbox.Android
              status={value?.length === items?.length ? 'checked' : 'unchecked'}
              color={Colors.RAVEN_BLACK}
              uncheckedColor={Colors.RAVEN_BLACK}
            />
            <Text style={[Typography.P2, styles.textColorPrimary]}>
              Select All
            </Text>
          </TouchableOpacity>
        ) : (
          <></>
        )}
        {searchedItems.length > 0 ? (
          searchedItems.map((item: any) => (
            <TouchableOpacity
              key={`check-key-${item.value}`}
              onPress={() => checkClk(item.value)}
              style={[
                styles.row,
                styles.alignCenter,
                itemStyle,
                allowSelectAll ? styles.marginMLeft : {},
              ]}>
              <Checkbox.Android
                status={value.includes(item.value) ? 'checked' : 'unchecked'}
                onPress={() => checkClk(item.value)}
                color={Colors.RAVEN_BLACK}
                uncheckedColor={Colors.RAVEN_BLACK}
              />
              {showTitle ? (
                <Text style={[Typography.P2, {color: Colors.TEXT_PRIMARY}]}>
                  {item.label}
                </Text>
              ) : (
                <></>
              )}
            </TouchableOpacity>
          ))
        ) : (
          <></>
        )}
        {showHelper ? (
          <HelperText
            type={error ? 'error' : 'info'}
            visible={true}
            style={[styles.helper]}>
            {error ? 'ⓧ ' + error.message : helper}
          </HelperText>
        ) : null}
      </View>
    );
  },
);

export default CheckboxInput;
