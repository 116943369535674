import {View} from 'react-native';
import {Separator} from 'src/common-components/atoms';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors} from 'src/styles';
import React from 'react';
import {useStyle} from 'src/providers/style';

const Form = ({
  // errors = undefined,
  handleSubmit = () => {},
  cancelled = () => {},
  children,
  saveText = 'Save',
  cancelText = 'Cancel',
  style = {},
  actionStyle = {},
}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.flex, style]}>
      {/*{errors ? <Error title={'Invalid Request'} message={errors} /> : <></>}*/}
      {children}
      <Separator height={10} />
      <View
        style={[
          styles.flex,
          styles.row,
          styles.justifySpaceBetween,
          styles.alignCenter,
          actionStyle,
        ]}>
        {/*{error ? <Text style={[P3, styles.message]}>{error}</Text> : <></>}*/}
        <RHButton
          secondary
          onPress={cancelled}
          textColor={Colors.RAVEN_BLACK}
          color="black"
          mode="outlined">
          {cancelText}
        </RHButton>
        <RHButton mode="contained" onPress={handleSubmit}>
          {saveText}
        </RHButton>
      </View>
    </View>
  );
};

export default Form;
