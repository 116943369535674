import React from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {Colors, Typography} from 'src/styles';

const ProgramCardSummary = ({program}: any) => {
  return (
    <View style={styles.container}>
      <Text style={styles.headerTxt}>Summary</Text>
      <Text style={styles.titleTxt}>Description (SD)</Text>
      <Text style={styles.descriptionTxt}>
        Specify the discriminative stimulus
      </Text>
      <Text style={styles.labelTxt}>{program?.stimulusDescription}</Text>

      <Text style={styles.titleTxt}>Objective</Text>
      <Text style={styles.descriptionTxt}>
        The overall purpose and/or goal to be accomplished in this program
      </Text>
      <Text style={styles.labelTxt}>{program?.objective}</Text>

      <Text style={styles.titleTxt}>Instructions</Text>
      <Text style={styles.descriptionTxt}>Detailed teaching instructions</Text>
      <Text style={styles.labelTxt}>{program?.teachingInstructions}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 15,
    paddingBottom: 30,
  },
  headerTxt: {
    ...Typography.P2_MEDIUM,
    color: Colors.TEXT_DISABLED,
  },
  titleTxt: {
    ...Typography.P3_BOLD,
    color: Colors.TEXT_DISABLED,
    marginTop: 20,
  },
  descriptionTxt: {
    ...Typography.CAPTION,
    color: Colors.TEXT_DISABLED,
    marginVertical: 5,
  },
  labelTxt: {
    ...Typography.TAB,
    color: Colors.TEXT_DISABLED,
  },
  spacer: {
    marginTop: 15,
  },
});

export default ProgramCardSummary;
