import React from 'react';
import {Caregiver, Location} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {DataItem} from 'src/design-system';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {useTranslations} from 'src/providers/translation';
import {catchError} from 'rxjs/operators';
import addressFormat from 'src/common-utils/address-format';

interface DisplayProps {
  value: string;
  type?: string;
}
interface Props {
  name: string;
  items: any[];
  label?: string;
  showHelper?: boolean;
  canCancel?: boolean;
}

export const Value = (address: any) => {
  return addressFormat(address);
};

export const Display = compose(
  withDatabase,
  withState,
  withObservables([], ({value, database}: any) => {
    if (!value) {
      return {value: of()};
    } else if (typeof value === 'object') {
      return {value: of(value)};
    } else {
      return {
        entity: database
          .get(Caregiver.table)
          .findAndObserve(value)
          .pipe(
            catchError(() =>
              database.get(Location.table).findAndObserve(value),
            ),
          ),
      };
    }
  }),
  withObservables([], ({entity, value}: any) => {
    return {
      value: entity ? of(entity.address) : of(value),
    };
  }),
)(({value, type = ''}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>
        {type ? type + ' ' + translations('address') : translations('address')}
      </Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
});

const LocationSelect = ({
  name,
  items,
  label = 'Location',
  showHelper = true,
  canCancel = false,
}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'address'}
      label={label}
      required={false}
      multiple={false}
      selectAll={false}
      items={items}
      showHelper={showHelper}
      canCancel={canCancel}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['patientId'],
    ({database, authentication, patientId}: any) => {
      return {
        caregivers: patientId
          ? database
              .get(Caregiver.table)
              .query(
                Q.where(
                  'patient_id',
                  Q.oneOf(Array.isArray(patientId) ? patientId : [patientId]),
                ),
                Q.where('deleted_at', null),
                Q.sortBy('address', Q.asc),
              )
          : of([]),
        locations: database
          .get(Location.table)
          .query(
            Q.where('deleted_at', null),
            Q.where('_partition', authentication.selectedGroup),
            Q.sortBy('name', Q.asc),
          ),
      };
    },
  ),
  withObservables(
    ['patientId', 'combineLocations', 'locations'],
    ({
      locations,
      caregivers,
      showOther = true,
      combineLocations = false,
    }: any) => {
      let items = [];
      if (caregivers.length) {
        if (!combineLocations) {
          items = caregivers
            .map((caregiver: any) => ({
              label: addressFormat(caregiver?.address),
              value: caregiver.id,
            }))
            .filter(item => item.label !== undefined);
        } else {
          const caregiverAddresses = caregivers
            .map((caregiver: any) => ({
              label: addressFormat(caregiver?.address),
              value: caregiver.id,
            }))
            .filter(item => item.label !== undefined);

          const locationEntities = locations.map((location: Location) => {
            return {
              label: `${location.name}`,
              value: location.id,
            };
          });

          items = [...caregiverAddresses, ...locationEntities];
        }
      } else {
        items = locations.map((location: Location) => {
          return {
            label: `${location.name}`,
            value: location.id,
          };
        });
      }

      if (showOther) {
        items.push({
          value: 'other',
          label: 'Other',
        });
      }

      return {
        items: of(items),
      };
    },
  ),
)(LocationSelect);
