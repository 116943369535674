import React, {useCallback, useEffect, useState} from 'react';
import {FlatList, Text, View} from 'react-native';
import NoResults from 'src/common-components/noResults';
import {Typography} from 'src/styles';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {compose} from 'recompose';
import moment from 'moment';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import Spinner from 'react-native-loading-spinner-overlay';
import {endOfDay, startOfDay} from 'date-fns';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Patient, Session, Tag, User} from 'src/models';

const schoolCptCodes = [
  {
    value: '97153',
    label: 'Individual',
  },
  {
    value: '97155',
    label: 'Consultation/Supervision',
  },
];

const TimesheetSchool = ({sessions, setTimesheetItems}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any>([]);
  const staffTabsTitles = [
    translations('service_date'),
    translations('start_time'),
    translations('end_time'),
    translations('staff_name'),
    translations('student_name'),
    translations('student_id'),
    translations('service'),
    translations('delivery'),
    translations('student_attendance'),
    translations('case_notes'),
  ];

  const recalculateAppointments = async () => {
    setLoading(true);
    const curatedAppointments = [];
    for (const session of sessions) {
      const appointment = await session.appointment;
      if (appointment) {
        let startTime = '';
        let endTime = '';
        let cptLabels;
        const staffNames = [];
        const staffParticipants = await appointment?.staffParticipants.fetch();
        for (const participant of staffParticipants) {
          const participantUser = await participant.user;
          staffNames.push(
            `${participantUser.firstName} ${participantUser.lastName}`,
          );
        }

        if (session.cpt.length) {
          cptLabels = session.cpt
            .map((cptCode: string) => {
              const cptEntry = schoolCptCodes.find(
                cpt => cpt.value === cptCode,
              );
              return cptEntry ? `${cptEntry.label}` : cptCode;
            })
            .join(', ');
        } else {
          const cptParticipants = staffParticipants.map(participant => {
            return participant.cpt.map((cptCode: string) => {
              const cptEntry = schoolCptCodes.find(
                cpt => cpt.value === cptCode,
              );
              return cptEntry ? `${cptEntry.label}` : cptCode;
            });
          });
          cptLabels = [...new Set(...cptParticipants)].join(', ');
        }
        let user = null;
        if (session.userId) {
          user = await database.get(User.table).find(session.userId);
        }
        let client = await database.get(Patient.table).find(session.patientId);
        const clientTags = await client?.activeTags.fetch();
        let clientTagLabels = clientTags.map((tag: Tag) => tag.name).join(', ');

        if (session?.endTimestamp && session?.startTimestamp) {
          startTime = `${moment(
            session?.editedStartTimestamp
              ? session?.editedStartTimestamp
              : session?.startTimestamp,
          ).format('hh:mm A')}`;
          endTime = `${moment(
            session?.editedEndTimestamp
              ? session?.editedEndTimestamp
              : session?.endTimestamp,
          ).format('hh:mm A')}`;
        } else {
          let appointmentStart = appointment?.start;
          let appointmentEnd = appointment?.end;

          if (user) {
            const participants = await appointment?.currentStaffParticipant(
              user.id,
            );
            if (participants.length > 0) {
              appointmentStart = participants[0].startTime;
              appointmentEnd = participants[0].endTime;
            }
          }
          startTime = `${moment(appointmentStart).format('hh:mm A')}`;
          endTime = `${moment(appointmentEnd).format('hh:mm A')}`;
        }

        let attendance = translations('not_started');
        if (
          !session?.startTimestamp &&
          appointment?.cancellationReason === 'absent'
        ) {
          attendance = translations('absent');
        } else if (!session?.startTimestamp) {
          attendance = translations('not_started');
        } else if (session?.startTimestamp && !session?.endTimestamp) {
          attendance = translations('in_progress');
        } else if (session?.endTimestamp && !session?.submissionTimestamp) {
          attendance = translations('missing_values');
        } else {
          attendance = translations('attended');
        }

        curatedAppointments.push([
          moment(session?.sessionDate).format('MM/DD/YYYY'),
          startTime,
          endTime,
          user ? `${user?.firstName} ${user?.lastName}` : staffNames.join(', '),
          `${client.lastName}, ${client.firstName}`,
          client.identifier,
          clientTagLabels,
          cptLabels,
          attendance,
          session?.note,
        ]);
      }
    }
    setItems(
      curatedAppointments.sort((a, b) => new Date(b[0]) - new Date(a[0])),
    );
    setLoading(false);
  };

  useEffect(() => {
    setTimesheetItems([staffTabsTitles, ...items]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    recalculateAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions.length]);

  const renderItem = useCallback(
    ({item}: any) => {
      return (
        <View style={[styles.card]}>
          <View
            style={[
              styles.flex,
              styles.row,
              styles.justifySpaceBetween,
              styles.alignItemsCenter,
            ]}>
            {item.map((value: any, index: number) => (
              <View
                key={index}
                style={[styles.flex, styles.column, styles.justifyCenter]}>
                <Text
                  style={[
                    styles.paddingVertical,
                    styles.textAlignCenter,
                    Typography.P3,
                    styles.textColorSecondary,
                  ]}>
                  {value}
                </Text>
              </View>
            ))}
          </View>
        </View>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Spinner visible={loading} />
      <View
        style={[
          styles.row,
          styles.justifySpaceBetween,
          styles.paddingLVertical,
          styles.paddingHorizontal,
        ]}>
        {staffTabsTitles.map((title: any, key) => (
          <View
            key={key}
            style={[styles.flex, styles.alignCenter, styles.justifyCenter]}>
            <Text
              style={[
                styles.textAlignCenter,
                Typography.P2_MEDIUM,
                styles.textColorPrimary,
              ]}>
              {title}
            </Text>
          </View>
        ))}
      </View>
      {items.length !== 0 ? (
        <>
          <FlatList
            scrollEnabled={true}
            data={items}
            style={[styles.height]}
            renderItem={renderItem}
          />
        </>
      ) : (
        <NoResults message={translations('empty_timesheet_message_school')} />
      )}
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(
    ['filters', 'update'],
    ({filters, database, authentication, role, profile, user = null}: any) => {
      const locationQuery = [];
      if (filters?.locations && filters.locations.length) {
        locationQuery.push(Q.where('location', Q.oneOf(filters.locations)));
      }

      const userQueries = [];
      if (user) {
        userQueries.push(Q.where('user_id', Q.eq(user.id)));
      } else {
        if (filters?.staff && filters.staff.length) {
          const userQuery = [];
          for (const staff of filters.staff) {
            userQuery.push(Q.where('user_id', Q.eq(staff)));
          }
          userQueries.push(Q.or(...userQuery));
        }
      }

      const clientQuery = [];
      if (filters?.clients && filters.clients.length) {
        const patientQueries = [];
        for (const patient of filters.clients) {
          patientQueries.push(Q.where('patient_id', Q.eq(patient)));
        }
        clientQuery.push(Q.or(...patientQueries));
      }

      const cptQuery = [];
      if (filters?.types && filters.types.length) {
        const cptQueries = [];
        for (const cpt of filters.types) {
          cptQueries.push(Q.where('cpt', Q.includes(cpt)));
        }
        cptQuery.push(Q.or(...cptQueries));
      }

      const statusQuery = [];
      if (filters?.status && filters.status.length) {
        const statuses = [];
        for (const status of filters.status) {
          if (status === 'not-started') {
            statuses.push(Q.where('start_timestamp', Q.eq(null)));
          } else if (status === 'in-progress') {
            statuses.push(
              Q.and(
                Q.where('start_timestamp', Q.notEq(null)),
                Q.where('end_timestamp', Q.eq(null)),
              ),
            );
          } else if (status === 'missing-values') {
            statuses.push(
              Q.and(
                Q.where('end_timestamp', Q.notEq(null)),
                Q.where('submission_timestamp', Q.eq(null)),
              ),
            );
          } else if (status === 'completed') {
            statuses.push(Q.where('submission_timestamp', Q.notEq(null)));
          }
        }
        statusQuery.push(Q.or(...statuses));
      }

      return {
        sessions: !role?.appointmentAssignedOnly
          ? database
              .get(Session.table)
              .query(
                Q.where(
                  'date',
                  Q.gte(startOfDay(filters.startDate).getTime() - 14400000),
                ),
                Q.where('date', Q.lte(endOfDay(filters.endDate).getTime())),
                Q.where('_partition', authentication.selectedGroup),
                Q.where('deleted_at', null),
                ...locationQuery,
                ...userQueries,
                ...clientQuery,
                ...cptQuery,
                ...statusQuery,
              )
          : database
              .get(Session.table)
              .query(
                Q.where(
                  'date',
                  Q.gte(startOfDay(filters.startDate).getTime() - 14400000),
                ),
                Q.where('date', Q.lte(endOfDay(filters.endDate).getTime())),
                Q.where('user_id', Q.eq(profile.id)),
                Q.where('_partition', authentication.selectedGroup),
                Q.where('deleted_at', null),
                ...locationQuery,
                ...userQueries,
                ...clientQuery,
                ...cptQuery,
                ...statusQuery,
              ),
      };
    },
  ),
)(TimesheetSchool);
