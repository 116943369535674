import React from 'react';
import {useStyle} from 'src/providers/style';
import {FlatList, ScrollView, Text, View} from 'react-native';
import {Typography} from 'src/styles';
import NoResults from 'src/common-components/noResults';
import NotAddressedProgramItem from '../not-addressed-program-item';

interface Props {
  totalSessionNumber: number;
  imcompleteProgramSets: any[];
  control: any;
}

const UnaddressedProgramList = ({
  totalSessionNumber,
  imcompleteProgramSets,
  control,
}: Props) => {
  const styles = useStyle();

  return (
    <View
      style={[styles.marginHorizontal, styles.card, styles.paddingVertical]}>
      <Text
        style={[Typography.H5, styles.marginHorizontal, styles.marginVertical]}>
        Not Addressed
      </Text>
      {imcompleteProgramSets?.length ? (
        <ScrollView>
          <FlatList
            scrollEnabled={true}
            data={imcompleteProgramSets}
            keyExtractor={item => item.id}
            renderItem={({item: set, index}: any) => {
              return (
                <NotAddressedProgramItem
                  index={index}
                  set={set}
                  control={control}
                  totalProgramNumber={totalSessionNumber}
                />
              );
            }}
          />
        </ScrollView>
      ) : (
        <NoResults
          message={'No unaddressed program available.'}
          iconName={'clipboard-text-outline'}
        />
      )}
    </View>
  );
};

export default UnaddressedProgramList;
