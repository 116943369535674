import React from 'react';
import {ScrollView, Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import JaspersoftProgramReport from 'src/modules/reports/components/jaspersoft-program-report';
import {Program} from 'src/models';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {subtractNMonths} from 'src/common-utils/subtractNMonths';
import moment from 'moment';
import DateLayout from 'src/hook-form-wrapper/date-input';
import {Separator} from 'src/common-components/atoms';
import {Typography} from 'src/styles';
import Targets from 'src/hook-form-inputs/targets';
import JaspersoftTargetReport from 'src/modules/reports/components/jaspersoft-target-report';
import {isSafari} from 'react-device-detect';
import {useTranslations} from 'src/providers/translation';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';

interface Props {
  program: Program;
}

const Progress = ({program}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();
  const methods = useForm({
    defaultValues: {
      startDate: subtractNMonths(6),
      endDate: new Date(),
      target: '',
      objective: false,
      format: false,
    },
  });

  const watchedStartDate = useWatch({
    control: methods.control,
    name: 'startDate',
  });
  const watchedEndDate = useWatch({control: methods.control, name: 'endDate'});
  const watchedTarget = useWatch({control: methods.control, name: 'target'});
  const objective = useWatch({control: methods.control, name: 'objective'});
  const format = useWatch({control: methods.control, name: 'format'});

  return (
    <FormProvider {...methods}>
      <ScrollView style={[styles.padding]}>
        <>
          {isSafari ? (
            <Text style={[Typography.P2, styles.paddingBottom]}>
              {translations('use_chrome')}
            </Text>
          ) : (
            <></>
          )}
          <View
            style={[
              styles.row,
              styles.alignCenter,
              styles.justifySpaceBetween,
            ]}>
            <Text style={[Typography.P2_BOLD]}>{program?.name}</Text>
          </View>
          <View
            style={[
              styles.row,
              styles.alignCenter,
              styles.justifySpaceBetween,
            ]}>
            <View style={[styles.row]}>
              <HookFormSwitchInput
                name={'objective'}
                label={translations('display_objective')}
              />
              <InformationTooltip
                value={translations('objective_disclaimer')}
              />
              <RHSeparator width={15} />
              <HookFormSwitchInput
                name={'format'}
                label={translations('per_session')}
              />
              <InformationTooltip
                value={translations('toggle_on_graphed_data')}
              />
            </View>
            <View style={[styles.row]}>
              <DateLayout
                name={'startDate'}
                label={'Start Date'}
                placeholder={''}
                helper={''}
              />
              <Separator width={20} />
              <DateLayout
                name={'endDate'}
                label={'End Date'}
                placeholder={''}
                helper={''}
              />
            </View>
          </View>
          <View style={[styles.windowHeight]}>
            <JaspersoftProgramReport
              programId={program?.id}
              startDate={moment(watchedStartDate).format()}
              endDate={moment(watchedEndDate).format()}
              objectiveEnabled={objective ? objective : false}
              formatDisplay={format ? format : false}
            />
          </View>
          {program?.method === 'trial_by_trial' ? (
            <View style={[styles.paddingVertical]}>
              <View style={[styles.row, styles.paddingBottom]}>
                <Targets programId={program?.id} />
              </View>
              {watchedTarget ? (
                <View style={[styles.windowHeight75]}>
                  <JaspersoftTargetReport
                    startDate={moment(watchedStartDate).format()}
                    endDate={moment(watchedEndDate).format()}
                    programId={program?.id}
                    target={watchedTarget}
                    formatDisplay={format ? format : false}
                  />
                </View>
              ) : (
                <></>
              )}
            </View>
          ) : (
            <></>
          )}
        </>
      </ScrollView>
    </FormProvider>
  );
};

export default Progress;
