import React, {useEffect, useReducer} from 'react';
import {View, Text, FlatList} from 'react-native';
import ListItemSeparator from 'src/common-components/separator';
import NoResults from 'src/common-components/noResults';
import {Typography} from 'src/styles';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import {useSelector} from 'react-redux';
import {of} from 'rxjs';
import withState from 'src/redux/wrapper';
import StaffListItem from './components/staff-list-item';
import {CareTeamParticipant} from 'src/models';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import _ from 'lodash';
import UserSelect from 'src/hook-form-inputs/staff';

interface Props {
  patient: any;
  staff: any[];
  role: any;
  user: any;
}

const AssignStaffTab = ({patient, staff, role}: Props) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const methods = useForm({
    defaultValues: {
      staff: staff.map((member: any) => member.id),
    },
  });
  const staffMembers = useWatch({control: methods.control, name: 'staff'});

  useEffect(() => {
    const deleteStaff = _.differenceWith(
      staff,
      staffMembers,
      (value1: any, value2: string) => {
        return value1.id === value2;
      },
    );
    const differenceStaff = _.differenceWith(
      staffMembers,
      staff,
      (value1: string, value2: any) => {
        return value1 === value2.id;
      },
    );

    for (const member of deleteStaff) {
      onAssign(member.id);
    }
    for (const member of differenceStaff) {
      onAssign(member);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, staffMembers]);

  const onAssign = async (staffMember: any) => {
    const staffPatientRelation = await database
      .get(CareTeamParticipant.table)
      .query(
        Q.and(
          Q.where('patient_id', patient.id),
          Q.where('user_id', staffMember),
          Q.where('deleted_at', null),
        ),
      )
      .fetch();

    if (staffPatientRelation.length === 0) {
      await database.write(async () => {
        return await database.get(CareTeamParticipant.table).create(entity => {
          entity.partition = selectedGroup;
          entity.patient.id = patient.id;
          entity.user.id = staffMember;
          entity.primary = false;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    } else {
      for (const relation of staffPatientRelation) {
        relation.delete();
      }
    }
  };

  const togglePrimary = async (id: string) => {
    await patient.updateEntity({
      renderingId: id,
    });
    forceUpdate();
  };

  return (
    <View style={[styles.flex, styles.width, styles.padding]}>
      <>
        <Text style={[Typography.H5, styles.marginLTop, styles.marginBottom]}>
          {staff.length} {translations('staff_assigned')}
        </Text>
        {role?.assignedStaffCreate ? (
          <FormProvider {...methods}>
            <UserSelect selectAll={true} multiple={true} />
          </FormProvider>
        ) : (
          <></>
        )}
        <View
          style={[
            styles.flex,
            styles.width,
            styles.justifyCenter,
            styles.backgroundColorWhite,
            styles.borderRadius,
          ]}>
          {staff?.length ? (
            <>
              <View
                style={[
                  styles.row,
                  styles.width,
                  styles.alignSelfCenter,
                  styles.justifyCenter,
                  styles.marginLVertical,
                ]}>
                <Text style={[Typography.H6, styles.paddingLLeft, styles.flex]}>
                  {translations('name')}
                </Text>
              </View>
              <FlatList
                data={staff}
                keyExtractor={item => item?.id?.toString()}
                renderItem={({item}: any) => {
                  return (
                    <StaffListItem
                      item={item}
                      patient={patient}
                      canEdit={role?.assignedStaffEdit}
                      togglePrimary={(credential: any) =>
                        togglePrimary(credential.id)
                      }
                      onAssign={(staffMember: any) => onAssign(staffMember.id)}
                      canDelete={role?.assignedStaffDelete}
                    />
                  );
                }}
                scrollEnabled={true}
                ItemSeparatorComponent={ListItemSeparator}
              />
            </>
          ) : (
            <NoResults
              iconName="account-supervisor"
              message={translations('no_staff_assigned')}
            />
          )}
        </View>
      </>
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(['patient'], ({patient}: any) => ({
    staff: patient.careTeam,
  })),
)(AssignStaffTab);
