import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
  label?: string;
}

interface Props {
  name?: string;
  label?: string;
}

export const Import = (
  key: string = 'NPI',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'NPI',
  key,
});

const resolveDisplayValue = (value: string) => {
  return value;
};

const NPIDisplay = ({value, label}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>{label ? label : translations('npi')}</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const NPIInput = ({name, label}: Props) => {
  const translations = useTranslations();

  return (
    <HookFormInput
      name={(name ? name + '.' : '') + 'npi'}
      label={label ? label : translations('npi')}
      required={false}
    />
  );
};
const INTEGER_REGEX = /^\d*$/;
export const validation = (isRequired = false) =>
  Yup.string().test('is-valid', 'Invalid NPI', value => {
    if (isRequired && (value?.length === 0 || !value)) {
      return false;
    }
    if (value?.length === 0 || !value) {
      return true;
    }
    if (!INTEGER_REGEX.test(value) || value.length !== 10) {
      return false;
    }
    if (['1', '2', '3', '4'].indexOf(value.charAt(0)) < 0) {
      return false;
    }
    const digit = Number.parseInt(value.charAt(9), 10);
    value = value.substring(0, 9);
    value = `80840${value}`;

    let alternate = true;
    let total = 0;

    for (let i = value.length; i > 0; i--) {
      let next = Number.parseInt(value.charAt(i - 1), 10);
      if (alternate) {
        next *= 2;
        if (next > 9) {
          next = (next % 10) + 1;
        }
      }
      total += next;
      alternate = !alternate;
    }
    const roundUp = Math.ceil(total / 10) * 10;
    const calculatedCheck = roundUp - total;

    return calculatedCheck === digit;
  });

export const Display = NPIDisplay;
export const Input = NPIInput;
export const Value = resolveDisplayValue;

export default NPIInput;
