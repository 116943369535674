import React from 'react';
import {View, ScrollView, FlatList, StyleSheet, Text} from 'react-native';
import {IconButton} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import {
  programStatusArray,
  programTypeArray,
  programStateArray,
} from '../filter-menu';

const ProgramFilterChipIndicator = ({filters, setFilters, tags}: any) => {
  const checkIfProgramType = (chip: string) => {
    return (
      programTypeArray.findIndex(
        (programType: any) => programType.value === chip,
      ) !== -1
    );
  };

  const checkIfProgramState = (chip: string) => {
    return (
      programStateArray.findIndex(
        (programState: any) => programState.value === chip,
      ) !== -1
    );
  };

  const checkIfProgramStatus = (chip: string) => {
    return (
      programStatusArray.findIndex(
        (programStatus: any) => programStatus.value === chip,
      ) !== -1
    );
  };
  const deleteFilterChip = (_filter: string) => {
    if (checkIfProgramType(_filter)) {
      setFilters({
        ...filters,
        programType: filters?.programType.filter(
          (value: any) => value !== _filter,
        ),
      });
    } else if (checkIfProgramStatus(_filter)) {
      setFilters({
        ...filters,
        programStatus: filters?.programStatus.filter(
          (value: any) => value !== _filter,
        ),
      });
    } else if (checkIfProgramState(_filter)) {
      setFilters({
        ...filters,
        programState: filters?.programState.filter(
          (value: any) => value !== _filter,
        ),
      });
    } else if (
      filters?.assessments.findIndex(
        (assessment: any) => assessment === _filter,
      ) !== -1
    ) {
      setFilters({
        ...filters,
        assessments: filters?.assessments.filter(
          (value: any) => value !== _filter,
        ),
      });
    } else if (
      filters?.skills.findIndex((skill: any) => skill === _filter) !== -1
    ) {
      setFilters({
        ...filters,
        skills: filters?.skills.filter((value: any) => value !== _filter),
      });
    } else if (
      filters?.behaviors.findIndex((behavior: any) => behavior === _filter) !==
      -1
    ) {
      setFilters({
        ...filters,
        behaviors: filters?.behaviors.filter((value: any) => value !== _filter),
      });
    } else if (
      filters?.relatedServices.findIndex(
        (txType: any) => txType === _filter,
      ) !== -1
    ) {
      setFilters({
        ...filters,
        relatedServices: filters?.relatedServices.filter(
          (value: any) => value !== _filter,
        ),
      });
    }
  };

  return (
    <View style={styles.filterChipContainer}>
      <ScrollView style={styles.scroll}>
        <FlatList
          horizontal
          data={[
            ...filters?.programType,
            ...filters?.programStatus,
            ...filters?.programState,
            ...filters?.assessments,
            ...filters?.skills,
            ...filters?.behaviors,
            ...filters?.relatedServices,
          ]}
          renderItem={({item: selectedChip}) => {
            return (
              <View
                style={[styles.selectedChipDiv]}
                key={`filter-chip-key-${selectedChip}`}>
                <Text style={styles.selectedChipTxt}>
                  {checkIfProgramType(selectedChip)
                    ? programTypeArray[
                        programTypeArray.findIndex(
                          (programType: any) =>
                            programType.value === selectedChip,
                        )
                      ]?.title
                    : checkIfProgramStatus(selectedChip)
                    ? programStatusArray[
                        programStatusArray.findIndex(
                          (programStatus: any) =>
                            programStatus.value === selectedChip,
                        )
                      ]?.title
                    : checkIfProgramState(selectedChip)
                    ? programStateArray[
                        programStateArray.findIndex(
                          (programState: any) =>
                            programState.value === selectedChip,
                        )
                      ]?.title
                    : tags.find((item: any) => item.id === selectedChip)?.name}
                </Text>
                <IconButton
                  size={15}
                  icon="close"
                  color="white"
                  onPress={() => deleteFilterChip(selectedChip)}
                />
              </View>
            );
          }}
        />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  selectedChipDiv: {
    backgroundColor: Colors.RAVEN_BLACK,
    borderRadius: 4,
    marginVertical: 5,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  selectedChipTxt: {
    ...Typography.P2,
    color: Colors.RAVEN_WHITE,
    alignSelf: 'center',
    marginHorizontal: 10,
  },
  filterChipContainer: {
    width: '100%',
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  scroll: {
    maxWidth: '100%',
  },
});

export default ProgramFilterChipIndicator;
