import React from 'react';
import {View} from 'react-native';
import {HelperText, TextInput} from 'react-native-paper';
import {useStyle} from 'src/providers/style';

import {Colors} from 'src/styles';

interface Props {
  error?: any;
  value: any;
  onChange: (value: any) => void;
}

const TimerComponent = ({error, value, onChange}: Props) => {
  const styles = useStyle();

  const d = Number(value || 0);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  const changeHour = (time: string) => {
    let hour = 0;
    if (time.length > 0) {
      hour = parseInt(time, 10);
    }
    onChange(hour * 3600 + m * 60 + s);
  };

  const changeMinute = (time: string) => {
    let minute = 0;
    if (time.length > 0) {
      minute = parseInt(time, 10);
    }
    onChange(h * 3600 + minute * 60 + s);
  };

  const changeSecond = (time: string) => {
    let second = 0;
    if (time.length > 0) {
      second = parseInt(time, 10);
    }
    onChange(h * 3600 + m * 60 + second);
  };

  return (
    <>
      <View style={[styles.row]}>
        <TextInput
          mode="outlined"
          placeholder="Hours"
          theme={{colors: {primary: Colors.TEXT_SECONDARY}}}
          label="Hours"
          style={[
            styles.backgroundColorWhite,
            styles.marginLRight,
            styles.width100,
          ]}
          keyboardType="numeric"
          maxLength={2}
          value={h.toString()}
          onChangeText={time => changeHour(time)}
        />
        <TextInput
          mode="outlined"
          placeholder="Minutes"
          theme={{colors: {primary: Colors.TEXT_SECONDARY}}}
          label="Minutes"
          style={[
            styles.backgroundColorWhite,
            styles.marginLRight,
            styles.width100,
          ]}
          keyboardType="numeric"
          maxLength={2}
          value={m.toString()}
          onChangeText={time => changeMinute(time)}
        />
        <TextInput
          mode="outlined"
          placeholder="Seconds"
          theme={{colors: {primary: Colors.TEXT_SECONDARY}}}
          label="Seconds"
          style={[
            styles.backgroundColorWhite,
            styles.marginLRight,
            styles.width100,
          ]}
          keyboardType="numeric"
          maxLength={2}
          value={s.toString()}
          onChangeText={time => changeSecond(time)}
        />
      </View>
      <HelperText type="error" visible={true} style={styles.helper}>
        {error && 'ⓧ ' + error.message}
      </HelperText>
    </>
  );
};

export default TimerComponent;
