import React from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';
import moment from 'moment';
import _ from 'lodash';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const tableHeaders = ['Date', 'Type', 'Short Description', 'Long Description'];

const EnvironmentalItemsTable = ({environmentalItems}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.flex]}>
      <View style={[styles.row, styles.borderBottom]}>
        {tableHeaders.map((header: any, index: number) => (
          <View
            key={`header-${header}-${index}`}
            style={[
              styles.paddingL,
              styles.borderRight,
              styles.justifyCenter,
              styles.flex,
              styles.alignStart,
            ]}>
            <Text style={[Typography.P3]}>{header}</Text>
          </View>
        ))}
      </View>
      {environmentalItems.length ? (
        environmentalItems.map((item, index) => {
          return (
            <View
              key={`${item.id}-${index}-env-item`}
              style={[
                styles.row,
                styles.borderBottom,
                styles.alignCenter,
                styles.justifyCenter,
              ]}>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width, styles.alignStart]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {moment(item?.date).format('MM/DD/YYYY')}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width, styles.alignStart]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {_.capitalize(item?.type)}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width, styles.alignStart]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {item?.title}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.paddingL,
                  styles.borderRight,
                  styles.flex,
                  styles.alignCenter,
                  styles.justifyCenter,
                ]}>
                <View style={[styles.width, styles.alignStart]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {item?.description}
                  </Text>
                </View>
              </View>
            </View>
          );
        })
      ) : (
        <View
          style={[
            styles.maxHeight100,
            styles.justifyCenter,
            styles.alignCenter,
            styles.paddingTop,
          ]}>
          <Icon name="table-cancel" size={30} color={'grey'} />
          <Text style={styles.emptyTitle}>No Items Found</Text>
        </View>
      )}
    </View>
  );
};

export default EnvironmentalItemsTable;
