import React, {useEffect, useState} from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import Modal from 'src/design-system/modal';
import {FormProvider, useForm} from 'react-hook-form';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {compose} from 'recompose';
import withDatabase from '@nozbe/watermelondb/DatabaseProvider/withDatabase';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import SignatureForm from 'src/modules/session/components/signature-form';
import moment from 'moment';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {credentialsList} from 'src/hook-form-inputs/credential';

const SignatureDisplay = compose(
  withDatabase,
  withObservables(['value.signatory'], ({value, database}: any) => {
    if (!value || !value?.type) {
      return {
        user: of([]),
      };
    } else {
      return {
        user:
          value?.type === 'staff'
            ? database.get('users').findAndObserve(value?.signatory)
            : value.type === 'caregiver'
            ? database.get('caregivers').findAndObserve(value?.signatory)
            : of([]),
      };
    }
  }),
)(({value, label, user}: any) => {
  const styles = useStyle();

  return (
    <View
      style={[
        styles.row,
        styles.width,
        styles.alignCenter,
        styles.marginVertical,
      ]}>
      <View style={[styles.width, styles.maxSkillCardWidth]}>
        <View
          style={[
            styles.height76,
            styles.column,
            styles.justifyCenter,
            styles.alignCenter,
          ]}>
          {value?.signature?.length ? (
            <Image
              style={[styles.resizeModeContain, styles.width, styles.height76]}
              source={{uri: value.signature}}
            />
          ) : (
            <View
              style={[styles.resizeModeContain, styles.width, styles.height76]}
            />
          )}
        </View>
        <View style={[styles.separator, styles.marginLBottom]} />
        <View style={[styles.row, styles.justifySpaceBetween]}>
          {label ? (
            <Text style={[Typography.P2, styles.alignSelfStart]}>{label}</Text>
          ) : user && value?.type === 'staff' ? (
            <Text style={[Typography.P2, styles.alignSelfStart]}>{`${
              user?.firstName
            } ${user?.lastName}, ${user.credentials
              .map(
                val => credentialsList.find(item => item.value === val)?.value,
              )
              .join(', ')}`}</Text>
          ) : user && value?.type === 'caregiver' ? (
            <Text
              style={[
                Typography.P2,
                styles.alignSelfStart,
              ]}>{`${user?.firstName} ${user?.lastName}, Caregiver`}</Text>
          ) : value?.type === 'other' ? (
            <Text style={[styles.alignSelfStart, Typography.P2]}>
              {value?.signatory ? value?.signatory : ''}
            </Text>
          ) : (
            <View />
          )}
        </View>
      </View>
      <Text
        style={[
          Typography.P1,
          styles.textColorPrimary,
          styles.marginLeft,
          styles.paddingLeft,
        ]}>
        {value?.updatedAt ? moment(value.updatedAt).format('MM/DD/YY') : ''}
      </Text>
    </View>
  );
});

const SignatureItem = ({
  index,
  signature,
  patient,
  user,
  update,
  remove,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [show, setShow] = useState(!!signature?.show);
  const [scrollEnabled, setScrollEnabled] = useState<boolean>(true);

  const validationSchema = Yup.object({
    type: Yup.string().required('Type is required.'),
    signatory: Yup.string().required('A Signatory is required.'),
    signature: Yup.string().required('A Signature is required.'),
  });

  const methods = useForm({
    defaultValues: {
      type: signature.type,
      signatory: signature.signatory,
      signature: signature.signature,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const subscription = methods.watch((value, {name}) => {
      if (name === 'type') {
        methods.reset({
          ...methods.getValues(),
          signatory: '',
        });
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch]);

  const onSubmit = (values: any) => {
    update({
      ...signature,
      ...values,
    });
    setShow(false);
  };

  if (signature?.deletedAt) {
    return null;
  }
  return (
    <>
      <Modal
        title={translations('draw_signature')}
        show={[show, setShow]}
        scrollEnabled={scrollEnabled}
        footer={
          <RHButton
            mode="contained"
            icon={'plus'}
            onPress={methods.handleSubmit(onSubmit)}>
            {translations('add_signature')}
          </RHButton>
        }>
        <FormProvider {...methods}>
          <SignatureForm
            index={index}
            patient={patient}
            setScrollEnabled={setScrollEnabled}
          />
        </FormProvider>
      </Modal>
      <View
        style={[
          styles.row,
          styles.width,
          styles.alignCenter,
          styles.marginVertical,
        ]}>
        <View style={[styles.width, styles.maxSkillCardWidth]}>
          <View
            style={[
              styles.height76,
              styles.column,
              styles.justifyCenter,
              styles.alignCenter,
            ]}>
            {signature.signature?.length > 0 ? (
              <TouchableOpacity
                onPress={() => setShow(true)}
                style={[
                  styles.resizeModeContain,
                  styles.width,
                  styles.height76,
                ]}>
                <Image
                  style={[
                    styles.resizeModeContain,
                    styles.width,
                    styles.height76,
                  ]}
                  source={{uri: signature.signature}}
                />
              </TouchableOpacity>
            ) : (
              <RHButton
                mode="outlined"
                icon={'plus'}
                onPress={() => setShow(true)}
                style={[styles.marginVertical]}
                textColor={Colors.TEXT_PRIMARY}>
                ADD SIGNATURE
              </RHButton>
            )}
          </View>
          <View style={[styles.separator, styles.marginLBottom]} />
          <View style={[styles.row, styles.justifySpaceBetween]}>
            {user && signature?.type === 'staff' ? (
              <Text style={[Typography.P2, styles.alignSelfStart]}>{`${
                user?.firstName
              } ${user?.lastName}, ${user.credentials
                .map(
                  val =>
                    credentialsList.find(item => item.value === val)?.value,
                )
                .join(', ')}`}</Text>
            ) : user && signature?.type === 'caregiver' ? (
              <Text
                style={[
                  Typography.P2,
                  styles.alignSelfStart,
                ]}>{`${user?.firstName} ${user?.lastName}, Caregiver`}</Text>
            ) : signature?.type === 'other' ? (
              <Text style={[styles.alignSelfStart, Typography.P2]}>
                {signature?.signatory ? signature?.signatory : ''}
              </Text>
            ) : (
              <View />
            )}
            {remove !== undefined ? (
              <TouchableOpacity
                onPress={() => remove()}
                style={[styles.justifyCenter]}>
                <Icon
                  name={'delete'}
                  size={16}
                  color={Colors.TEXT_PRIMARY}
                  style={[styles.alignSelfCenter]}
                />
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
        <Text
          style={[
            Typography.P1,
            styles.textColorPrimary,
            styles.marginLeft,
            styles.paddingLeft,
          ]}>
          {signature.updatedAt
            ? moment(signature.updatedAt).format('MM/DD/YY')
            : ''}
        </Text>
      </View>
    </>
  );
};

export const Display = SignatureDisplay;

export default compose(
  withDatabase,
  withObservables(['signature'], ({signature, database}: any) => {
    if (!signature || !signature?.type || !signature?.signatory) {
      return {
        user: of(undefined),
      };
    } else {
      return {
        user:
          signature?.type === 'staff'
            ? database.get('users').findAndObserve(signature?.signatory)
            : signature.type === 'caregiver'
            ? database.get('caregivers').findAndObserve(signature?.signatory)
            : of(undefined),
      };
    }
  }),
)(SignatureItem);
