import React, {forwardRef} from 'react';
import {HelperText, TextInput} from 'react-native-paper';
import {Platform, View} from 'react-native';
import {Colors} from 'src/styles';
import {RenderProps} from 'react-native-paper/lib/typescript/components/TextInput/types';
import {useStyle} from 'src/providers/style';

interface Props {
  onChange: (value: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onSubmit?: () => void;
  value: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  helper?: string;
  showHelper?: boolean;
  numberOfLines?: number;
  selectTextOnFocus?: boolean;
  style?: any;
  error?: any;
  render?: (props: RenderProps) => React.ReactNode;
  disabled?: boolean;
  right?: any;
  keyboardType?: any;
}

const FormInput = forwardRef(
  (
    {
      onChange,
      onBlur,
      onFocus,
      onSubmit,
      value,
      label,
      placeholder,
      required,
      helper,
      showHelper = true,
      numberOfLines = 1,
      selectTextOnFocus = false,
      style = {},
      error = null,
      render = undefined,
      disabled = false,
      right = undefined,
      keyboardType = 'default',
    }: Props,
    ref,
  ) => {
    const styles = useStyle();

    return (
      <View style={style}>
        <TextInput
          ref={ref}
          style={[styles.backgroundColorWhite]}
          theme={{colors: {primary: Colors.TEXT_SECONDARY}}}
          onBlur={onBlur}
          disabled={disabled}
          autoComplete={Platform.OS === 'web' ? 'none' : 'off'}
          onChangeText={onChange}
          secureTextEntry={false}
          value={value}
          mode="outlined"
          placeholder={
            placeholder
              ? placeholder + (required ? '*' : '')
              : label + (required ? '*' : '')
          }
          selectTextOnFocus={selectTextOnFocus}
          multiline={numberOfLines > 1}
          numberOfLines={numberOfLines}
          label={label + (required ? '*' : '')}
          error={!!error}
          onSubmitEditing={onSubmit}
          onFocus={onFocus}
          returnKeyType={'next'}
          render={render}
          right={right}
          keyboardType={keyboardType}
        />
        {showHelper ? (
          <HelperText
            type={error ? 'error' : 'info'}
            visible={true}
            style={[styles.helper]}>
            {error ? 'ⓧ ' + error.message : helper}
          </HelperText>
        ) : null}
      </View>
    );
  },
);

export default FormInput;
