import React from 'react';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {BaseScreen, TabBar} from 'src/design-system';
import Claims from 'src/modules/billing/components/claims';
import Payers from 'src/modules/billing/components/payers';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {compose} from 'recompose';
import {of} from 'rxjs';
import {useRoute} from '@react-navigation/native';
// import Remits from '../../components/remits';

const Navigator = createMaterialTopTabNavigator();

const BillingScreen = ({role}: any) => {
  const route = useRoute();

  return (
    <BaseScreen header={false}>
      <TabBar navigator={Navigator} location={'Billing'} route={route}>
        {role?.billingClaimView ? (
          <Navigator.Screen name="Claims">{() => <Claims />}</Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.billingPayerView ? (
          <Navigator.Screen name="Payers">{() => <Payers />}</Navigator.Screen>
        ) : (
          <></>
        )}
        {/* {role?.billingPayerView ? ( */}
        {/* <Navigator.Screen name="Remits">{() => <Remits />}</Navigator.Screen> */}
        {/* ) : (
          <></>
        )} */}
      </TabBar>
    </BaseScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => {
    return {
      profile: authentication?.userId
        ? database.get('users').findAndObserve(authentication.userId)
        : of(),
    };
  }),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
)(BillingScreen);
