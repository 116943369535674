import React from 'react';
import {DataItem} from 'src/design-system';
import {UsStates} from 'src/common-utils/usStates';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {useFormContext} from 'react-hook-form';

interface DisplayProps {
  value: string;
}

interface Props {
  name: string;
}

export const Import = (
  key: string = 'state',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'State/Province/Region',
  key,
});

export const Value = (value: string) => {
  return UsStates().find(item => item.value === value)?.label ?? value;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>State/Province/Region</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  const {watch} = useFormContext();
  const countryPath = (name ? name + '.' : '') + 'country';
  const fieldValue = watch(countryPath);
  return fieldValue === 'US' ? (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'state'}
      label={'State'}
      required={false}
      items={UsStates()}
    />
  ) : (
    <HookFormInput
      name={(name ? name + '.' : '') + 'state'}
      label={'State'}
      required={false}
    />
  );
};

export const validation = () => Yup.string().required('State is required');

export default Input;
