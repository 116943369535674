import React, {useEffect, useState} from 'react';
import {FlatList, Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import FormSectionHeader from '../../../../design-system/form-section-header';
import {useTranslations} from 'src/providers/translation';
import ClaimListItem from 'src/modules/billing/components/claim-list-item';
import {Typography} from 'src/styles';
import {useNavigation} from '@react-navigation/native';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {Claim, Insurance, ServiceLine} from 'src/models';
import {endOfDay, startOfDay} from 'date-fns';

const ClaimList = ({claims, insurances, filters}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const navigation = useNavigation();

  const confirmDelete = async (item: any, sessions: any, services: any) => {
    for (const service of services) {
      await service.delete();
    }
    for (const session of sessions) {
      await session.updateEntity({
        claimId: '',
      });
    }
    await item.delete();
  };

  const [filteredClaims, setFilteredClaims] = useState([]);

  useEffect(() => {
    const newFilteredClaims = filters.payerId.length
      ? claims.filter(claim =>
          insurances.some(insurance => insurance.patientId === claim.patientId),
        )
      : claims;
    setFilteredClaims(newFilteredClaims);
  }, [claims, insurances, filters.payerId]);

  return (
    <View style={[styles.flex, styles.paddingLeft]}>
      <FormSectionHeader title={translations('claims_list')} />
      <View style={[styles.row]}>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>PCN</Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Created/Submitted
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Client Name
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Payer
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Services
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Billed Amount
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Paid Amount
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Last Updated
          </Text>
        </View>
        <View style={[styles.flex]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Status
          </Text>
        </View>
        <View style={[styles.flex05]} />
      </View>
      <FlatList
        data={filteredClaims}
        renderItem={({item}: any) => {
          return (
            <ClaimListItem
              item={item}
              onPress={() => {
                navigation.navigate('ClaimProfile', {
                  id: item.id,
                });
              }}
              confirmDelete={confirmDelete}
            />
          );
        }}
      />
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['authentication', 'filters'],
    ({authentication, database, filters}) => {
      let queryConditions = [
        Q.where('_partition', authentication.selectedGroup),
        Q.where('deleted_at', Q.eq(null)),
      ];

      if (filters.staff?.length) {
        queryConditions.push(
          Q.on('sessions', Q.where('user_id', Q.oneOf(filters.staff))),
        );
      }

      if (filters.locations?.length) {
        queryConditions.push(
          Q.on('sessions', Q.where('location', Q.oneOf(filters.locations))),
        );
      }
      if (filters.types?.length) {
        const types = [];
        for (const code of filters.types) {
          types.push(Q.where('cpt', code));
        }
        queryConditions.push(Q.on(ServiceLine.table, Q.or(types)));
      }

      if (filters.clients?.length) {
        queryConditions.push(Q.where('patient_id', Q.oneOf(filters.clients)));
      }

      if (filters.status?.length) {
        queryConditions.push(Q.where('status', Q.oneOf(filters.status)));
      }

      if (filters.startDate && filters.endDate) {
        const startTime = new Date(filters.startDate).getTime();
        const endTime = new Date(filters.endDate).getTime();

        queryConditions.push(
          Q.where(
            'created_at',
            Q.between(
              startOfDay(startTime).getTime(),
              endOfDay(endTime).getTime(),
            ),
          ),
        );
      }

      const claimsQuery = database.get(Claim.table).query(...queryConditions);

      return {claims: claimsQuery};
    },
  ),

  withObservables(['claims', 'filters'], ({claims, database, filters}: any) => {
    const patientIds = claims.map(claim => claim.patientId);
    const insuranceConditions = [
      Q.where('deleted_at', Q.eq(null)),
      Q.where('patient_id', Q.oneOf(patientIds)),
    ];

    if (filters.payerId?.length) {
      insuranceConditions.push(Q.where('payer_id', Q.oneOf(filters.payerId)));
    }

    const insurancesQuery = database
      .get(Insurance.table)
      .query(...insuranceConditions);

    return {insurances: insurancesQuery};
  }),
)(ClaimList);
