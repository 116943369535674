import React from 'react';
import withObservables from '@nozbe/with-observables';
import {
  APPOINTMENTCARDTYPE,
  RenderAppointment,
} from 'src/modules/scheduling/components';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';

const Session = ({session, appointment, deleteCallback, role}: any) => {
  return (
    <RenderAppointment
      date={session.date}
      session={session}
      appointment={appointment}
      type={APPOINTMENTCARDTYPE.SESSIONNOTEDUE}
      canEdit={role?.appointmentEdit}
      canDelete={role?.appointmentDelete}
      deleteCallback={deleteCallback}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(['session'], ({session}: any) => ({
    appointment: session.appointment,
  })),
)(Session);
