import React, {Dispatch, SetStateAction} from 'react';
import {ScrollView, Text, View, TouchableOpacity} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Controller} from 'react-hook-form';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import CheckboxInput from 'src/hook-form/checkbox-input';
import FormInput from 'src/hook-form/form-input';

export const emotionalStateList = [
  {label: 'HAPPY', value: 'happy'},
  {label: 'TIRED', value: 'tired'},
  {label: 'SAD', value: 'sad'},
  {label: 'CRYING', value: 'crying'},
  {label: 'ANGRY', value: 'angry'},
  {label: 'ANXIOUS', value: 'anxious'},
  {label: 'ATTENTIVE', value: 'attentive'},
  {label: 'ENERGETIC', value: 'energetic'},
  {label: 'COOPERATIVE', value: 'cooperative'},
  {label: 'FRUSTRATED', value: 'frustrated'},
  {label: 'DISTRACTED', value: 'distracted'},
];

interface Props {
  emotionalStates: [any[], Dispatch<SetStateAction<any[]>>];
  control: any;
}

const SessionImpressions = ({
  emotionalStates: [emotionalStates, setEmotionalStates],
  control,
}: Props) => {
  const styles = useStyle();

  const selectEmotion = (selectedEmotion: any) => {
    const existed = emotionalStates.includes(selectedEmotion.value);
    if (existed) {
      setEmotionalStates(prevState =>
        prevState.filter((emotion: any) => emotion !== selectedEmotion.value),
      );
    } else {
      setEmotionalStates(prevState => [...prevState, selectedEmotion.value]);
    }
  };

  return (
    <View
      style={[styles.marginHorizontal, styles.card, styles.paddingVertical]}>
      <Text
        style={[Typography.H5, styles.marginHorizontal, styles.marginVertical]}>
        Session Impressions
      </Text>
      <ScrollView>
        <View style={styles.marginHorizontal}>
          <Text style={[Typography.P3, styles.textColorSecondary]}>
            Please note any observed arousal behavior displayed by the client
            throughout the session (this is a marker indicating patient's
            distance from optimal state of mind/body to engage with external
            stimuli). You can edit these options at the end of the session as
            part of the Daily Note.
          </Text>
          <Text style={[styles.paddingTop, Typography.P1_MEDIUM]}>
            Emotional State
          </Text>
          <Text
            style={[
              Typography.P3,
              styles.textColorSecondary,
              styles.paddingSMTop,
            ]}>
            Please indicate emotional state of the client upon arrival.
          </Text>
          <View style={[styles.row, styles.flexWrap, styles.paddingVertical]}>
            {emotionalStateList.map((emotion: any) => {
              const selected = emotionalStates.includes(emotion.value);
              return (
                <View key={`emotion-${emotion.value}`} style={styles.paddingM}>
                  <RHButton
                    onPress={() => selectEmotion(emotion)}
                    textColor={
                      selected ? Colors.RAVEN_WHITE : Colors.RAVEN_BLACK
                    }
                    style={[styles.row, styles.alignCenter]}
                    mode={selected ? 'contained' : 'outlined'}>
                    {selected ? (
                      <TouchableOpacity
                        style={[
                          {
                            backgroundColor: selected
                              ? Colors.RAVEN_WHITE
                              : Colors.RAVEN_BLACK,
                          },
                        ]}>
                        <Icon
                          name={'check'}
                          size={14}
                          color={Colors.RAVEN_BLACK}
                        />
                      </TouchableOpacity>
                    ) : (
                      <></>
                    )}
                    {emotion.label}
                  </RHButton>
                </View>
              );
            })}
          </View>
          <Controller
            control={control}
            render={({field: {onChange, value, ref}}) => (
              <FormInput
                formRef={ref}
                style={[styles.noteInput]}
                value={value}
                onChange={onChange}
                label={'Notes'}
                placeholder={'Please enter notes.'}
                numberOfLines={4}
              />
            )}
            name="sessionImpressionNotes"
          />
          <Controller
            control={control}
            render={({field: {onChange, value}}) => (
              <CheckboxInput
                name={''}
                items={[{label: 'Include in Clinical Note', value: 'include'}]}
                value={value}
                onChange={onChange}
              />
            )}
            name="includeNotAddressed"
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default SessionImpressions;
