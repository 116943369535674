import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {endOfDay, startOfDay, subMonths} from 'date-fns';

export const setFilters = createAsyncThunk(
  'sessionHistoryFilters/setFilters',
  async (args: any) => {
    return args;
  },
);

const sessionHistoryFilters = createSlice({
  name: 'sessionHistoryFilters',
  initialState: {
    selectedFilters: {
      startDate: startOfDay(subMonths(new Date(), 1)).getTime(),
      endDate: endOfDay(new Date()).getTime(),
      staff: [],
      clients: [],
      locations: [],
      status: ['completed'],
    },
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setFilters.fulfilled, (state, {payload}) => {
      Object.assign(state, {selectedFilters: payload});
    });
  },
});

export default sessionHistoryFilters.reducer;
