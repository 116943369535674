import React, {useState} from 'react';
import {RetractingDrawer} from 'src/design-system';
import {Text, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {Colors, Typography} from 'src/styles';
import AppointmentFilter from 'src/modules/scheduling/components/appointment-filter';
import {useStyle} from 'src/providers/style';
import {useDimensions} from '@react-native-community/hooks';
import {exportCsv} from 'src/common-utils/export-csv';
import {useNavigation} from '@react-navigation/native';
import moment from 'moment/moment';
import {AppointmentLocation, AppointmentType} from 'src/form-inputs';

const SchedulingDrawer = ({selectedFilters, role}: any) => {
  const styles = useStyle();
  const dimensions = useDimensions();
  const navigation = useNavigation();

  const [open, setOpen] = useState(true);

  const tabs = [
    ...(role?.appointmentCreate
      ? [
          {
            type: 'navigation',
            icon: 'add',
            value: 'add',
            title: 'Add New Event',
          },
          {type: 'separator'},
          // ...(Platform.OS === 'web'
          //   ? [
          //       {
          //         type: 'local',
          //         icon: 'file-upload',
          //         value: 'import',
          //         title: 'Import Calendar',
          //       },
          //       {type: 'separator'},
          //     ]
          //   : []),
        ]
      : []),
    // {
    //   type: 'local',
    //   icon: 'file-download',
    //   value: 'export',
    //   title: 'Export Calendar',
    // },
  ];

  const navigateToUpsert = () => {
    navigation.navigate('ScheduleAppointment');
  };

  const tabSelected = async (tab: any) => {
    if (tab.type === 'local') {
      switch (tab.value) {
        case 'import':
          // importRef.current.click();
          break;
        case 'export':
          await exportCsv('appointment_export', await exportAppointments());
          break;
        case 'filter':
          break;
      }
    } else {
      navigateToUpsert();
    }
  };

  const exportAppointments = async () => {
    let flatAppointments = [
      [
        'Client',
        'Staff',
        'Appointment Location',
        'Appointment Type',
        'Date',
        'Start Time',
        'End Time',
      ],
    ];
    for (const appointment of []) {
      const appointmentPatients = await appointment.patients;
      const staff = await appointment.staff;
      flatAppointments.push([
        appointmentPatients
          .map((patient: any) => patient?.firstName + ' ' + patient?.lastName)
          .join(','),
        staff
          .map(
            (practitioner: any) =>
              practitioner?.firstName + ' ' + practitioner?.lastName,
          )
          .join(','),
        AppointmentLocation.Value(appointment?.location),
        AppointmentType.Value(appointment.type),
        moment(appointment?.date).format('MM/DD/YYYY'),
        moment(appointment?.start).format('HH:mm'),
        moment(appointment?.end).format('HH:mm'),
      ]);
    }
    return flatAppointments;
  };

  return (
    <RetractingDrawer
      type={dimensions.window.width > 1024 ? 'persistent' : 'overlay'}
      position={'left'}
      opening={() => setOpen(true)}
      closing={() => setOpen(false)}>
      <>
        {tabs.map((tab: any, index: number) => {
          if (tab.type === 'separator') {
            return <View key={index} style={[styles.separator]} />;
          }
          return (
            <TouchableOpacity key={tab.value} onPress={() => tabSelected(tab)}>
              <View
                style={[
                  styles.marginMVertical,
                  styles.row,
                  styles.alignCenter,
                  styles.borderRadiusSM,
                  styles.overflowHidden,
                ]}>
                <View style={[styles.positionRelative, styles.paddingM]}>
                  {tab.notifications ? (
                    <View style={[styles.circle]}>
                      <Text
                        style={[
                          styles.alignCenter,
                          styles.alignSelfCenter,
                          styles.textColorWhite,
                        ]}>
                        {tab.notifications >= 9 ? '9+' : tab.notifications}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                  <Icon name={tab.icon} size={20} color={Colors.PRIMARY_700} />
                </View>
                <Text
                  style={[
                    Typography.CAPTION_MEDIUM,
                    styles.textColorPrimary700,
                    styles.marginMLeft,
                    styles.marginSMTop,
                  ]}
                  numberOfLines={1}>
                  {tab.title}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </>
      {open ? <AppointmentFilter filters={selectedFilters} /> : null}
    </RetractingDrawer>
  );
};

export default SchedulingDrawer;
