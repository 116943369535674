import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import React from 'react';
import {useTranslations} from 'src/providers/translation';
import {IDeveloperField} from 'dromo-uploader-react';
import {DataItem} from 'src/design-system';
import {Text} from 'react-native';
import {Typography} from 'src/styles';

interface DisplayProps {
  value: boolean;
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'intervalAutomation',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Interval Automation',
  key,
  type: 'checkbox',
});

export const Value = (value: boolean) => {
  return value ? 'Automatic' : 'Manual';
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('interval_automation')}</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  const translations = useTranslations();

  return (
    <HookFormSwitchInput
      name={(name ? name + '.' : '') + 'intervalAutomation'}
      label={translations('interval_automation')}
      description={translations('interval_automation_description')}
    />
  );
};

export default Input;
