export default function calculateSessionStatus(session) {
  if (!session.startTimestamp) {
    return 'not-started';
  } else if (session.startTimestamp && !session.endTimestamp) {
    return 'in-progress';
  } else if (session.endTimestamp && !session.submissionTimestamp) {
    return 'missing-values';
  } else {
    return 'completed';
  }
}
