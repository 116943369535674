import React, {ReactNode} from 'react';
import Spinner from 'react-native-loading-spinner-overlay';
import {Text, View} from 'react-native';
import {Screen} from 'react-native-screens';
import OfflineNotification from 'src/design-system/offline-notification';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';

interface Props {
  title?: string;
  right?: JSX.Element;
  children: ReactNode;
  snackbar?: JSX.Element;
  header?: boolean;
  spinner?: boolean;
}

const BaseScreen = ({
  title = '',
  right = <></>,
  children,
  snackbar = <></>,
  header = true,
  spinner = false,
}: Props) => {
  const styles = useStyle();

  return (
    <Screen style={[styles.flex, styles.windowWidth, styles.windowHeight]}>
      <Spinner visible={spinner} />
      <View style={[styles.flex]}>
        {header ? (
          <View
            style={[
              styles.width,
              styles.row,
              styles.justifySpaceBetween,
              styles.paddingHorizontal,
              styles.flexWrap,
              styles.paddingVertical,
            ]}>
            <Text style={[Typography.H1]}>{title}</Text>
            <View style={[styles.flexWrap, styles.alignSelfCenter]}>
              {right}
            </View>
          </View>
        ) : null}
        <View
          style={[
            styles.flex,
            styles.borderRadius,
            styles.height,
            header ? styles.backgroundColorWhite : {},
          ]}>
          {children}
        </View>
        {snackbar}
        <OfflineNotification />
      </View>
    </Screen>
  );
};

export default BaseScreen;
