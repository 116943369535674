import {Q} from '@nozbe/watermelondb';
import {
  text,
  relation,
  children,
  date,
  writer,
  json,
  lazy,
  field,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

const sanitizePrompts = prompts => {
  return Array.isArray(prompts) ? prompts.map(String) : [];
};

export class Set extends Base {
  static table = 'sets';
  static associations = {
    sessions: {type: 'belongs_to', key: 'session_id'},
    programs: {type: 'belongs_to', key: 'program_id'},
    targets: {type: 'belongs_to', key: 'target_id'},
    events: {type: 'has_many', foreignKey: 'set_id'},
  };

  @children('events') events;

  preloadEvents = this.events.extend(
    Q.where('deleted_at', null),
    Q.sortBy('created_at', Q.desc),
  );

  @lazy orderedEvents = this.events.extend(
    Q.where('deleted_at', null),
    Q.sortBy('created_at', Q.desc),
  );

  @lazy ascendingEvents = this.events.extend(
    Q.where('deleted_at', null),
    Q.sortBy('created_at', Q.asc),
  );

  @relation('sessions', 'session_id') session;
  @relation('programs', 'program_id') program;
  @relation('targets', 'target_id') target;

  @text('program_id') programId;
  @text('session_id') sessionId;
  @text('target_id') targetId;
  @date('start_timestamp') startTimestamp;
  @date('end_timestamp') endTimestamp;
  @json('prompts', sanitizePrompts) prompts;
  @text('intensity') intensity;
  @field('number_of_trials') numberOfTrials;
  @text('not_addressed_reason') notAddressedReason;
  @text('not_addressed_description') notAddressedDescription;

  @writer async endSet(numberOfTrials = null) {
    await this.update(set => {
      set.endTimestamp = new Date();
      set.numberOfTrials = numberOfTrials;
    });
  }
}
