import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BASE_AUTH_URL} from 'react-native-dotenv';
import {REHYDRATE} from 'redux-persist';

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_AUTH_URL,
  }),
  tagTypes: ['UNAUTHORIZED'],
  endpoints: builder => ({
    token: builder.mutation({
      query: data => ({
        headers: {
          Accept: 'application/json',
        },
        url: '/oauth/token',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['UNAUTHORIZED'],
    }),
  }),
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.payload) {
      if (action.type === REHYDRATE) {
        return action.payload[reducerPath];
      }
    }
  },
});

export const {useTokenMutation} = authApi;
