import React, {useReducer, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {Checkbox, RadioButton} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useTranslations} from 'src/providers/translation';
import _ from 'lodash';
import {useStyle} from 'src/providers/style';

const FrequencyEdit = ({instance, prompts, program, event, setEvents}: any) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const styles = useStyle();
  const edittableIntensity = event?.intensity;
  const [intensity] = useState<string>(edittableIntensity);
  const [previousPrompts] = useState<string>(event?.prompts);
  const translations = useTranslations();
  const intensityArray = [
    {title: 'Mild', value: 'mild'},
    {title: 'Moderate', value: 'moderate'},
    {title: 'Severe', value: 'severe'},
  ];

  const changeIntensity = (val: string) => {
    event.updateEntity({
      intensity: val,
    });
    forceUpdate();
  };

  const onSave = () => {
    setEvents('');
  };

  const onCancel = () => {
    event.updateEntity({
      prompts: previousPrompts,
      intensity: intensity,
    });
    setEvents('');
  };

  const checkPrompt = (id: any) => {
    const index = [...event?.prompts].findIndex((v: any) => {
      return v === id;
    });

    if (index === -1) {
      const values = [...event?.prompts];
      values.push(id);
      event.updateEntity({
        prompts: values,
      });
    } else {
      const values = [...event?.prompts];
      values.splice(index, 1);
      event.updateEntity({
        prompts: values,
      });
    }
    forceUpdate();
  };

  return (
    <View>
      <View
        style={[
          styles.border,
          styles.borderColorPrimary300,
          styles.borderRadiusSM,
          styles.paddingL,
          styles.marginLVertical,
        ]}>
        {program?.intensity ? (
          <View>
            <View style={[styles.container, styles.justifySpaceBetween]}>
              <Text style={[Typography.P2, styles.marginSMVertical]}>
                {translations('intensity')} - {translations('instance')}
                {` ${instance}`}
              </Text>
              <Text
                style={[
                  styles.intensityText,
                  edittableIntensity === 'mild'
                    ? styles.mildColor
                    : edittableIntensity === 'moderate'
                    ? styles.moderateColor
                    : styles.severeColor,
                ]}>
                {_.capitalize(edittableIntensity)}
              </Text>
            </View>
            <View style={styles.radio}>
              <RadioButton.Group
                onValueChange={changeIntensity}
                value={edittableIntensity}>
                <View style={[styles.row, styles.justifySpaceBetween]}>
                  {intensityArray.map((button: any, index: number) => {
                    return (
                      <View
                        key={`radio-key-${button?.value}-${index}`}
                        style={styles.radioButton}>
                        <RadioButton.Android
                          value={button.value}
                          color={
                            edittableIntensity === 'mild'
                              ? '#4FF5AF'
                              : edittableIntensity === 'moderate'
                              ? '#F7AA4F'
                              : '#F12E5D'
                          }
                          uncheckedColor={'#909093'}
                        />
                        <Text
                          style={[
                            Typography.P2,
                            button.title === _.capitalize(edittableIntensity)
                              ? button.title === 'Mild'
                                ? styles.mildColor
                                : button.title === 'Moderate'
                                ? styles.moderateColor
                                : styles.severeColor
                              : styles.noResultsColor,
                          ]}>
                          {button.title}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </RadioButton.Group>
            </View>
          </View>
        ) : (
          <Text style={styles.abcDataCategoryTxt}>
            {translations('instance')} {instance}
          </Text>
        )}
        {prompts ? (
          <View style={styles.paddingTop}>
            {Object.keys(prompts).map((promptType: any, index: number) => {
              return (
                <View
                  style={[
                    styles.zIndex100,
                    styles.borderRadius,
                    styles.positionRelative,
                    index === Object.keys(prompts).length - 1
                      ? styles.lastPaddingBottom
                      : styles.paddingBottom,
                  ]}
                  key={`promptType-key-${promptType}`}>
                  <Text style={styles.abcDataCategoryTxt}>{promptType}</Text>
                  {prompts[promptType].map((prompt: any) => {
                    return (
                      <TouchableOpacity
                        key={`prompt-key-${promptType}-${prompt?.id}`}
                        style={[
                          styles.row,
                          styles.alignCenter,
                          styles.marginSMVertical,
                        ]}
                        onPress={() => {
                          checkPrompt(prompt?.id);
                        }}>
                        <Checkbox.Android
                          status={
                            (event?.prompts || []).findIndex(v => {
                              return v === prompt?.id;
                            }) !== -1
                              ? 'checked'
                              : 'unchecked'
                          }
                          color={Colors.RAVEN_BLACK}
                          uncheckedColor={Colors.RAVEN_BLACK}
                        />
                        <Text style={Typography.P2}>{prompt?.name}</Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              );
            })}
          </View>
        ) : (
          <></>
        )}
      </View>
      <View style={[styles.justifySpaceBetween, styles.row]}>
        <RHButton
          secondary
          onPress={() => {
            onCancel();
          }}
          textColor={Colors.RAVEN_BLACK}
          color="black"
          mode="outlined">
          {translations('cancel_button')}
        </RHButton>
        <RHButton mode="contained" onPress={() => onSave()}>
          {translations('save_button')}
        </RHButton>
      </View>
    </View>
  );
};

export default FrequencyEdit;
