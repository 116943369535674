import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import FormInput from 'src/hook-form-wrapper/form-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'medication',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Medication',
  key,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Medication</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  return (
    <FormInput
      name={(name ? name + '.' : '') + 'medication'}
      label={'Medication'}
      placeholder={'Medication'}
      helper={''}
      required={false}
    />
  );
};

export const validation = () => Yup.string().required('Medication is required');

export default Input;
