import React, {useRef, useState} from 'react';
import {Platform, ScrollView, Text} from 'react-native';
import {useSelector} from 'react-redux';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import EnvironmentalFactorTimeline from 'src/modules/patients/components/environmental-factor-timeline';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {
  BaseIndexScreen,
  DateInput,
  DeleteModal,
  Form,
  Menu,
  Modal,
  RadioInput,
} from 'src/design-system';
import EnvDescriptionLabel from 'src/form-inputs/description-label';
import EnvShortDescriptionInput from 'src/form-inputs/short-description-input';
import EnvDescriptionInput from 'src/form-inputs/description-input';
import * as Yup from 'yup';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import NoResults from 'src/common-components/noResults';
import {EnvironmentalFactor} from 'src/models';
import {useStyle} from 'src/providers/style';
import ViewShot from 'react-native-view-shot';
import moment from 'moment';
import {exportGraph} from 'src/common-utils/export-graph';
import {Colors, Typography} from 'src/styles';
import {useTranslations} from 'src/providers/translation';

export const environmentalType = [
  {value: 'factor', title: 'Environmental Factor'},
  {value: 'change', title: 'Environmental Change'},
];

const EnvironmentalFactors = ({patient, environmentalFactors, role}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const screenRef = useRef();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [submitting, setSubmitting] = useState(false);
  const [showDelete, setShowDelete] = useState<any | boolean>(false);
  const [edit, setEdit] = useState<any | boolean>(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    type: edit ? edit?.type : '',
    date: edit ? edit?.date : new Date(),
    title: edit ? edit?.title : '',
    description: edit ? edit?.description : '',
  };

  const validationSchema = Yup.object({
    type: Yup.string().required('Environmental Factor Type is required'),
    date: Yup.date().required('Date is required'),
    title: Yup.string().required('Short Description is required'),
    description: Yup.string().required('Description is required'),
  });

  const handleSubmit = async (payload: any, formik: any) => {
    if (edit) {
      await edit.updateEntity({
        type: payload.type,
        date: payload.date,
        title: payload.title,
        description: payload.description,
      });
    } else {
      database.write(async () => {
        await database.get(EnvironmentalFactor.table).create(entity => {
          entity.partition = selectedGroup;
          entity.type = payload.type;
          entity.date = payload.date;
          entity.title = payload.title;
          entity.description = payload.description;
          entity.patient.id = patient.id;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
    setSubmitting(false);
    formik.resetForm();
    setEdit(false);
  };

  const handleCancel = () => {
    setSubmitting(!submitting);
    setEdit(false);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (environmentalFactor: EnvironmentalFactor) => {
    environmentalFactor.delete(userId);
    setShowDelete(false);
  };
  const exportPDF = async () => {
    const fileName = `${patient.firstName}_${
      patient.lastName
    }_environmental_factor_export_${moment().format('L')}_${moment().format(
      'LT',
    )}`.replace(/[:/ ]/g, '_');
    setLoading(true);
    await exportGraph(screenRef, fileName, 'pdf');
    setLoading(false);
  };
  const options = [
    {
      onPress: exportPDF,
      title: translations('pdf'),
      icon: 'file-pdf-box',
    },
  ];

  return (
    <BaseIndexScreen
      title={translations('environmental_factors')}
      filters={false}
      canAdd={role?.environmentalFactorCreate}
      exportBut={
        <Menu
          anchor={
            <RHButton
              loading={loading}
              secondary
              textColor={Colors.RAVEN_BLACK}
              icon={'export-variant'}
              mode="outlined">
              {translations('export')}
            </RHButton>
          }
          options={options}
        />
      }
      addButton={
        <RHButton
          onPress={() => setSubmitting(true)}
          mode={'contained'}
          secondary
          icon="plus">
          {translations('add_environmental_factor')}
        </RHButton>
      }>
      <Modal
        title={
          edit
            ? translations('update_environmental_factor')
            : translations('new_environmental_factor')
        }
        show={[submitting, handleCancel]}>
        <Form
          handleSubmit={handleSubmit}
          cancelled={handleCancel}
          initialValues={initialValues}
          validationSchema={validationSchema}
          saveText={translations('save_button')}
          cancelText={translations('cancel_button')}
          style={[styles.paddingBottom]}>
          <RadioInput
            name={translations('type')}
            valueKey={'type'}
            required={true}
            buttons={environmentalType}
          />
          <EnvDescriptionLabel />
          <RHSeparator height={10} />
          <DateInput
            valueKey={'date'}
            name={translations('date')}
            required={true}
          />
          <EnvShortDescriptionInput
            valueKey={'title'}
            name={translations('short_description')}
            required={true}
          />
          <EnvDescriptionInput
            valueKey={'description'}
            name={translations('description')}
            required={true}
          />
        </Form>
      </Modal>
      <DeleteModal
        model={'Environmental Factor'}
        text={showDelete ? `${showDelete?.title}` : null}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <ScrollView style={[styles.flex]}>
        <ViewShot ref={screenRef} options={{format: 'png', quality: 0.8}}>
          <Text
            style={[
              Typography.H2,
              styles.paddingHorizontal,
              styles.paddingLVertical,
            ]}>
            {patient?.firstName} {patient?.lastName}
          </Text>
          {environmentalFactors.length === 0 ? (
            <NoResults message={translations('no_environmental_factors')} />
          ) : (
            <EnvironmentalFactorTimeline
              envFactors={environmentalFactors}
              canEdit={role?.environmentalFactorEdit}
              canDelete={role?.environmentalFactorDelete}
              editEnvironmentalFactor={item => {
                if (Platform.OS === 'web') {
                  setEdit(item);
                } else {
                  setEdit(item.payload);
                }
                setSubmitting(true);
              }}
              deleteSelected={item => {
                if (Platform.OS === 'web') {
                  setShowDelete(item);
                } else {
                  setShowDelete(item.payload);
                }
              }}
            />
          )}
        </ViewShot>
      </ScrollView>
    </BaseIndexScreen>
  );
};

export default compose(
  withObservables([], ({patient}: any) => ({
    environmentalFactors: patient?.activeEnvironmentalFactors ?? of([]),
  })),
)(EnvironmentalFactors);
