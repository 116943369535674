import React, {useState} from 'react';
import {TextInput} from 'react-native-paper';
import DropDown from 'react-native-paper-dropdown';

import {dropdownTheme} from 'src/styles/theme';

interface Props {
  target: string | undefined;
  targets: any[];
  onSelect(target: string | undefined): void;
}

const TargetSelect = ({target, targets, onSelect}: Props) => {
  const [openStateDropDown, setOpenStateDropDown] = useState(false);

  return (
    <DropDown
      label="Target"
      value={target}
      placeholder="Select Target"
      setValue={onSelect}
      list={targets}
      visible={openStateDropDown}
      showDropDown={() => setOpenStateDropDown(true)}
      onDismiss={() => setOpenStateDropDown(false)}
      theme={dropdownTheme}
      mode={'outlined'}
      inputProps={{
        right: <TextInput.Icon name={'menu-down'} />,
      }}
    />
  );
};

export default TargetSelect;
