import React from 'react';
import {StyleSheet, View} from 'react-native';
import {HelperText, TextInput as PaperInput} from 'react-native-paper';
import {FastField} from 'formik';
import {BLUE_RIBBON} from 'src/styles/colors';

interface Props {
  valueKey: string;
  name: string;
  required: boolean;
  style?: any;
}

const ArrayInput = ({valueKey, name, required = false, style = {}}: Props) => {
  return (
    <View style={styles.container}>
      <FastField name={valueKey}>
        {({field, meta: {error, touched}}: any) => {
          return (
            <>
              {field.value.map((value: string, index: number) => {
                return (
                  <PaperInput
                    key={`${name}-key-${index}`}
                    onChangeText={field.onChange(field.name + `.${index}`)}
                    onBlur={field.onBlur(field.name + `.${index}`)}
                    value={value}
                    placeholder={name}
                    label={name + (required ? '*' : '')}
                    error={!!(error && touched)}
                    theme={{colors: {primary: BLUE_RIBBON}}}
                    mode={'outlined'}
                    style={[styles.input, style]}
                  />
                );
              })}
              <HelperText type="error" visible={true} style={styles.helper}>
                {error && touched && 'ⓧ ' + error}
              </HelperText>
            </>
          );
        }}
      </FastField>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  input: {
    backgroundColor: 'white',
  },
  helper: {},
});

export default ArrayInput;
