import React, {useReducer, useState} from 'react';
import * as Yup from 'yup';
import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {FormProvider, useForm} from 'react-hook-form';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';
import HookFormRadioInput from 'src/hook-form-wrapper/radio-input';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import Modal from 'src/design-system/modal';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
  label?: string;
}

interface Props {
  name?: string;
  label?: string;
  entity: any;
}

export const Import = (
  key: string = 'taxonomy',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Taxonomy Selector',
  key,
});

const resolveDisplayValue = (value: string) => {
  return value;
};

const TaxonomySelectorDisplay = ({value}: DisplayProps) => {
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <>
      <Text style={[Typography.P3_BOLD]}>
        {label ? label : translations('taxonomy')}
      </Text>
      <Text
        style={[
          Typography.INPUT,
          styles.textColorPrimary,
          styles.marginSMVertical,
        ]}>
        {resolveDisplayValue(value)}
      </Text>
    </>
  );
};

const TaxonomySelectorInput = ({name, entity, label}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [show, setShow] = useState(false);
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const methods = useForm({
    defaultValues: {
      value: '',
    },
  });

  const handleSubmit = (values: any) => {
    entity.updateEntity({
      taxonomy: [...entity.taxonomy, values.value],
    });
    setShow(false);

    forceUpdate();
  };

  return (
    <View style={[styles.column]}>
      <Modal
        show={[show, setShow]}
        title={translations('add_taxonomy')}
        footer={
          <>
            <RHButton
              secondary
              mode="outlined"
              textColor={Colors.RAVEN_BLACK}
              onPress={() => setShow(false)}>
              {translations('cancel')}
            </RHButton>
            <RHSeparator width={16} />
            <RHButton
              secondary
              mode="contained"
              onPress={methods.handleSubmit(handleSubmit)}>
              {translations('save')}
            </RHButton>
          </>
        }>
        <FormProvider {...methods}>
          <HookFormInput name={'value'} label={translations('taxonomy')} />
        </FormProvider>
      </Modal>
      <HookFormRadioInput
        name={(name ? name + '.' : '') + 'taxonomy'}
        label={label ? label : translations('taxonomy')}
        items={[
          {
            label: translations('none'),
            value: '',
          },
          ...entity.taxonomy.map((value: string) => ({
            label: value,
            value,
          })),
        ]}
        showHelper={false}
      />
      <View style={[styles.row, styles.marginLVertical]}>
        <RHButton
          icon="plus-circle-outline"
          mode="outlined"
          color={Colors.RAVEN_WHITE}
          textColor={Colors.RAVEN_BLACK}
          onPress={() => {
            setShow(true);
          }}>
          {translations('add_more')}
        </RHButton>
      </View>
    </View>
  );
};

export const validation = () => Yup.string().required('Taxonomy is required');

export const Display = TaxonomySelectorDisplay;
export const Input = compose(
  withObservables(['entity'], ({entity}: any) => ({
    items: of(
      entity.taxonomy.map((value: string) => ({
        label: value,
        value,
      })),
    ),
  })),
)(TaxonomySelectorInput);
export const Value = resolveDisplayValue;

export default TaxonomySelectorInput;
