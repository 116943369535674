import React from 'react';
import {Patient, User} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';

interface Props {
  name: string;
  items: any[];
  multiple?: boolean;
  label?: string;
  selectAll?: boolean;
  showHelper?: boolean;
  disabled?: boolean;
}

const ClientSelect = ({
  name,
  items,
  multiple = true,
  label = 'Select Client(s)',
  selectAll = false,
  showHelper = true,
  disabled = false,
}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'clients'}
      label={label}
      required={false}
      multiple={multiple}
      selectAll={selectAll}
      items={items}
      showHelper={showHelper}
      disabled={disabled}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(
    [],
    ({profile, database, authentication, role, includeArchived}: any) => {
      let queryConditions = [];
      if (includeArchived) {
        queryConditions.push(
          Q.or(Q.where('state', 'active'), Q.where('state', 'archived')),
        );
      } else {
        queryConditions.push(Q.where('state', 'active'));
      }
      return {
        patients: !role?.patientAssignedOnly
          ? database
              .get(Patient.table)
              .query(
                Q.where('deleted_at', null),
                ...queryConditions,
                Q.where('_partition', authentication.selectedGroup),
                Q.sortBy('first_name', Q.asc),
                Q.sortBy('last_name', Q.asc),
              )
          : profile.patients,
      };
    },
  ),
  withObservables([], ({patients}: any) => ({
    items: of(
      patients.map((patient: Patient) => {
        return {
          label: `${patient.firstName} ${patient.lastName}`,
          value: patient.id,
        };
      }),
    ),
  })),
)(ClientSelect);
