import {View} from 'react-native';
import FilterChip from 'src/design-system/filter-chip';
import React from 'react';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {Patient, User} from 'src/models';

const SchedulingChip = ({participant, onClose, person}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.paddingXSBottom, styles.marginAutoRight]}>
      <FilterChip
        textColor={participant.textColor}
        backgroundColor={participant.color}
        icon={participant.type === 'patient' ? 'account' : 'card-account-mail'}
        label={`${person.firstName} ${person.lastName}`}
        close={onClose}
      />
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables(['participant'], ({database, participant}: any) => {
    return {
      person: database
        .get(participant.type === 'patient' ? Patient.table : User.table)
        .findAndObserve(participant.value),
    };
  }),
)(SchedulingChip);
