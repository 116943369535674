import moment from 'moment/moment';
import _ from 'lodash';
import {Q} from '@nozbe/watermelondb';
import {intervalTypes} from 'src/hook-form-inputs/interval-type';

export const exportInterval = async (database: any, sets: any[]) => {
  const groupSessionSets = _.groupBy(sets, 'session.id');
  let _exportData = [
    [
      'Session Date',
      'Interval Number',
      'Interval Type',
      'Time Stamp',
      'Behavior Occurred',
      'Intensity (If Toggled On)',
      'ABC (When Selected)',
    ],
  ];
  for (const sessionId of Object.keys(groupSessionSets)) {
    const session = (
      await database.get('sessions').query(Q.where('id', sessionId)).fetch()
    )[0];
    let sumY = 0;
    const sessionSets = groupSessionSets[sessionId];
    for (const set of sessionSets) {
      const program = (
        await database
          .get('programs')
          .query(Q.where('id', set?.programId))
          .fetch()
      )[0];

      const index: any = sessionSets.indexOf(set);
      const event = (
        await database.get('events').query(Q.where('set_id', set?.id)).fetch()
      )[0];
      if (event?.value === 'Y') {
        sumY++;
      }
      let abcStr = '';
      if (event?.prompts && event?.prompts?.length > 0) {
        for (const promptId of event?.prompts) {
          const prompt = await database.get('prompts').find(promptId);
          abcStr += prompt.name.replaceAll('"', "'") + '\n';
        }
      }

      _exportData.push([
        index === 0 ? `${moment(session?.date).format('MM/DD/YY')}` : '',
        `${index + 1}`,
        intervalTypes.find((item: any) => item.value === program?.interval)
          ?.label,
        `${moment(set?.startTimestamp).format('HH:mm:ss')} - ${moment(
          set?.endTimestamp,
        ).format('HH:mm:ss')}`,
        event?.value,
        _.capitalize(event?.intensity),
        abcStr,
      ]);
    }
    _exportData.push([
      '',
      '',
      '',
      '',
      Math.ceil((sumY / sessionSets?.length) * 100) + '%',
      '',
      '',
    ]);
  }

  return _exportData;
};
