import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const severity = [
  {label: 'Mild', value: 'mild'},
  {label: 'Moderate', value: 'moderate'},
  {label: 'Severe', value: 'severe'},
];

export const Import = (
  key: string = 'severity',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Severity',
  key,
  type: 'select',
  selectOptions: severity,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return severity.find(item => item.value === value)?.label;
};

export const Display = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Severity</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'severity'}
      label={'Severity'}
      required={false}
      items={severity}
    />
  );
};

export const validation = () => Yup.string().required('Severity is required');

export default Input;
