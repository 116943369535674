import React, {useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {Divider} from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, Typography} from 'src/styles';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {Permission, Position} from 'src/form-inputs';
import {Patients, Sessions} from 'src/modules/users/components';
import {MoreMenu} from 'src/design-system';
import Collapsible from 'react-native-collapsible';
import {User} from 'src/models';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {useStyle} from 'src/providers/style';
import {Display as PersonalInformationDisplay} from 'src/modules/users/components/personal-information-form';
import {Credential, Tags} from 'src/hook-form-inputs';

interface Props {
  user: User;
  patients: any[];
  tags: any[];
  viewCallback: () => void;
  canEdit: boolean;
  editCallback: () => void;
  canDelete: boolean;
  deleteCallback: () => void;
  canArchive: boolean;
  archiveCallback?: () => void;
}

const UserCard = ({
  user,
  patients,
  tags,
  viewCallback,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
  canArchive,
  archiveCallback,
}: Props) => {
  const [expansionContent, setExpansionContent] = useState<string | null>(null);
  const [collapsed, setCollapsed] = useState(true);
  const styles = useStyle();

  const toggle = (identifier: string) => {
    if (expansionContent === identifier) {
      setExpansionContent(null);
      setCollapsed(true);
    } else {
      setExpansionContent(identifier);
      setCollapsed(false);
    }
  };

  const firstName = user?.firstName || '';
  const lastName = user?.lastName || '';
  const firstInitial = firstName.length > 0 ? firstName[0].toUpperCase() : 'N';
  const lastInitial = lastName.length > 0 ? lastName[0].toUpperCase() : 'N';
  const initials = firstInitial + lastInitial;
  const secondLineDisplay = Position.Value(user?.position);

  return (
    <View style={[styles.cardColumn]}>
      <View style={[styles.card]}>
        <View style={[styles.overflowHidden]}>
          <TouchableOpacity onPress={viewCallback} style={[styles.padding]}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={[styles.row, styles.alignCenter]}>
                <RHAvatar
                  label={initials}
                  color={Colors.RAVEN_BLACK}
                  textColor={Colors.RAVEN_WHITE}
                  size={32}
                />
                <View style={[styles.paddingLLeft, styles.justifyCenter]}>
                  <Text
                    style={[
                      Typography.P3,
                      styles.textColorPrimary,
                    ]}>{`${firstName} ${lastName}`}</Text>
                  <Text style={[Typography.P3, styles.textColorSecondary]}>
                    {secondLineDisplay}
                  </Text>
                </View>
              </View>

              <MoreMenu
                canEdit={canEdit}
                editCallback={editCallback}
                canDelete={canDelete}
                deleteCallback={deleteCallback}
                canArchive={canArchive}
                archiveTxt={
                  user?.state !== 'archived' ? 'Archive' : 'Unarchive'
                }
                archiveCallback={archiveCallback}
              />
            </View>
            <View style={[styles.paddingTop]}>
              <Permission.Display value={user} />
              <Credential.Display value={user.credentials} />
              <Tags.CardDisplay value={tags.map((tag: any) => tag?.id)} />
            </View>
          </TouchableOpacity>
          <Divider />
          <View style={[styles.row, styles.justifySpaceBetween]}>
            <TouchableOpacity
              onPress={() => toggle('patients')}
              style={[styles.padding]}>
              <View style={[styles.row, styles.alignCenter]}>
                <Icon
                  size={24}
                  name="account"
                  color={
                    expansionContent === 'patients'
                      ? Colors.GRAY_500
                      : Colors.TEXT_PRIMARY
                  }
                />
                <Text style={[styles.paddingSMHorizontal]}>
                  {patients.length}
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => toggle('contactInformation')}
              style={[styles.padding]}>
              <Icon
                size={24}
                name="card-account-phone"
                color={
                  expansionContent === 'contactInformation'
                    ? Colors.GRAY_500
                    : Colors.TEXT_PRIMARY
                }
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => toggle('schedule')}
              style={[styles.padding]}>
              <Icon
                size={24}
                name="calendar-range"
                color={
                  expansionContent === 'schedule'
                    ? Colors.GRAY_500
                    : Colors.TEXT_PRIMARY
                }
              />
            </TouchableOpacity>
          </View>
          <Collapsible collapsed={collapsed} style={[styles.paddingHorizontal]}>
            {expansionContent === 'patients' ? (
              <Patients entities={patients} />
            ) : (
              <></>
            )}
            {expansionContent === 'contactInformation' ? (
              <PersonalInformationDisplay values={user} tags={tags} />
            ) : (
              <></>
            )}
            {expansionContent === 'schedule' ? <Sessions user={user} /> : <></>}
          </Collapsible>
        </View>
      </View>
    </View>
  );
};

export default compose(
  withObservables([], ({user}: any) => ({
    user,
    patients: user.patients,
    tags: user.activeTags,
  })),
)(UserCard);
