import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import {Typography} from 'src/styles';

const FormSectionHeader = ({title}: {title: string}) => (
  <View style={styles.container}>
    <Text style={Typography.H5}>{title || ''}</Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
});

export default FormSectionHeader;
