import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import moment from 'moment';
import {IDeveloperField} from 'dromo-uploader-react';
import DateInput from 'src/hook-form-wrapper/date-input';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'diagnosisDate',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Diagnosis Date (MM/DD/YYYY)',
  key,
  validators: [
    {
      validate: 'required',
    },
    {
      validate: 'regex_match',
      regex:
        '(0[1-9]|1[012])[- \\/.](0[1-9]|[12][0-9]|3[01])[- \\/.](19|20)\\d\\d$',
      errorMessage: 'Must be a valid date.',
    },
  ],
});

const resolveDisplayValue = (value: string) => {
  return value ? moment(value).format('ll') : '';
};

const DiagnosisDateDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Diagnosis Date</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const DiagnosisDateInput = ({name}: Props) => {
  return (
    <DateInput
      name={(name ? name + '.' : '') + 'diagnosisDate'}
      label={'Diagnosis Date'}
      placeholder={'Diagnosis Date'}
      helper={''}
      required={false}
      maximumDate={new Date()}
    />
  );
};

export const validation = () =>
  Yup.string().required('Diagnosis date is required');

export const Display = DiagnosisDateDisplay;
export const Input = DiagnosisDateInput;
export const Value = resolveDisplayValue;

export default DiagnosisDateInput;
