import {StyleSheet, View} from 'react-native';
import {DataTable} from 'react-native-paper';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import IconMaterial from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors} from 'src/styles';
import React from 'react';
import _ from 'lodash';

import SkillTableTooltip from 'src/modules/session/components/skill-table-tooltip';

const TaskAnalysisTargetColumnCell = ({
  program,
  events,
  target,
  setIndex,
  type,
  setEventId,
}: any) => {
  const eventsByTargetId = _.groupBy(events, 'target.id');
  if (eventsByTargetId[target.id]?.length > 0) {
    const event = eventsByTargetId[target.id][0];
    if (type) {
      return (
        <View
          style={[
            styles.justifyCenter,
            styles.borderRight,
            styles.flex,
            styles.setHead,
          ]}>
          <SkillTableTooltip
            type={'task_analysis_edit'}
            setEventId={setEventId}
            setIndex={setIndex}
            event={event}
            target={program?.name}
            task={target?.name}
          />
          <View style={styles.cornerTab} />
        </View>
      );
    } else {
      if (event?.value) {
        return (
          <View style={[styles.justifyCenter, styles.borderRight, styles.flex]}>
            <SkillTableTooltip
              type={'task_analysis'}
              setIndex={setIndex}
              event={event}
              target={program?.name}
              task={target?.name}
            />
            <View style={styles.cornerTab} />
          </View>
        );
      } else {
        return (
          <DataTable.Cell
            style={[
              styles.justifyCenter,
              styles.borderRight,
              styles.setHead,
              styles.flex,
            ]}>
            <IconMaterial
              name="minus"
              size={12}
              color={Colors.TEXT_SECONDARY}
            />
          </DataTable.Cell>
        );
      }
    }
  } else {
    return (
      <DataTable.Cell
        style={[
          styles.justifyCenter,
          styles.borderRight,
          styles.setHead,
          styles.flex,
        ]}>
        <IconMaterial name="minus" size={12} color={Colors.TEXT_SECONDARY} />
      </DataTable.Cell>
    );
  }
};

const styles = StyleSheet.create({
  alignCenter: {
    alignItems: 'center',
    alignContent: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  column: {
    flexDirection: 'column',
  },
  cornerTab: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 8,
    borderTopWidth: 8,
    borderRightColor: 'transparent',
    borderTopColor: '#B5B5B8',
    transform: [{rotate: '90deg'}],
    position: 'absolute',
    top: 0,
    right: 0,
  },
  borderRight: {
    borderRightWidth: 1,
    borderColor: Colors.PRIMARY_300_SESSION_PLAN,
  },
  flex: {
    flex: 1,
  },
  pt_10: {
    paddingTop: 5,
  },
  setHead: {
    width: 155,
    paddingVertical: 5,
  },
});

export default compose(
  withDatabase,
  withObservables(['set'], ({set, database}: any) => ({
    events: database
      .get('events')
      .query(Q.where('set_id', set.id), Q.where('deleted_at', null)),
  })),
)(TaskAnalysisTargetColumnCell);
