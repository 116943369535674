export const CamptonBlack = require('../../assets/fonts/Campton/ReneBieder-CamptonBlack.otf');
export const CamptonBlackItalic = require('../../assets/fonts/Campton/ReneBieder-CamptonBlackItalic.otf');
export const CamptonBold = require('../../assets/fonts/Campton/ReneBieder-CamptonBold.otf');
export const CamptonBoldItalic = require('../../assets/fonts/Campton/ReneBieder-CamptonBoldItalic.otf');
export const CamptonBook = require('../../assets/fonts/Campton/ReneBieder-CamptonBook.otf');
export const CamptonBookItalic = require('../../assets/fonts/Campton/ReneBieder-CamptonBookItalic.otf');
export const CamptonExtraBold = require('../../assets/fonts/Campton/ReneBieder-CamptonExtraBold.otf');
export const CamptonExtraBoldItalic = require('../../assets/fonts/Campton/ReneBieder-CamptonExtraBoldItalic.otf');
export const CamptonExtraLight = require('../../assets/fonts/Campton/ReneBieder-CamptonExtraLight.otf');
export const CamptonExtraLightItalic = require('../../assets/fonts/Campton/ReneBieder-CamptonExtraLightItalic.otf');
export const CamptonLight = require('../../assets/fonts/Campton/ReneBieder-CamptonLight.otf');
export const CamptonLightItalic = require('../../assets/fonts/Campton/ReneBieder-CamptonLightItalic.otf');
export const CamptonMedium = require('../../assets/fonts/Campton/ReneBieder-CamptonMedium.otf');
export const CamptonMediumItalic = require('../../assets/fonts/Campton/ReneBieder-CamptonMediumItalic.otf');
export const CamptonSemiBold = require('../../assets/fonts/Campton/ReneBieder-CamptonSemiBold.otf');
export const CamptonSemiBoldItalic = require('../../assets/fonts/Campton/ReneBieder-CamptonSemiBoldItalic.otf');
export const CamptonThin = require('../../assets/fonts/Campton/ReneBieder-CamptonThin.otf');
export const CamptonThinItalic = require('../../assets/fonts/Campton/ReneBieder-CamptonThinItalic.otf');
export const SegoeUIRegular = require('../../assets/fonts/SegoeUIRegular.ttf');
