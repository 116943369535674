import React from 'react';
import {Text, View, Linking} from 'react-native';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {BaseScreen} from 'src/design-system';
import {Button} from 'react-native-paper';

const Account = ({}: any) => {
  const styles = useStyle();

  return (
    <BaseScreen header={false}>
      <View style={[styles.backgroundColorWhite, styles.flex, styles.padding]}>
        <View
          style={[
            styles.row,
            styles.alignCenter,
            styles.marginTop,
            styles.flexWrap,
          ]}>
          <Text
            style={[
              Typography.P3,
              styles.textAlignCenter,
              styles.textColorPrimary,
            ]}>
            For information regarding account or data deletion, please refer to
            our
          </Text>
          <Button
            onPress={() =>
              Linking.openURL(
                'https://www.ravenhealth.com/legal/#:~:text=c.%20Return%2C%20Destruction,Protected%20Health%20Information.',
              )
            }
            style={[
              styles.backgroundColorWhite,
              styles.textColorLink,
              styles.marginSMBottom,
            ]}
            labelStyle={styles.textColorLink}>
            HIPAA Business Associate Agreement (BAA)
          </Button>
        </View>
      </View>
    </BaseScreen>
  );
};

export default Account;
