import React, {forwardRef, useState} from 'react';
import moment from 'moment';
import SelectInput from 'src/hook-form/select-input';
import FormInput from 'src/hook-form/form-input';
import {useStyle} from 'src/providers/style';
import {Text, TouchableOpacity, View} from 'react-native';
import {Separator} from 'src/common-components/atoms';
import FormLabel from 'src/design-system/form-label';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, Typography} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';

interface Props {
  valueKey: string;
  name: string;
  required: boolean;
  maximumDate?: Date | undefined;
  style?: any;
  date?: Date;
}

const times = [
  {label: 'am', value: 'am'},
  {label: 'pm', value: 'pm'},
];

interface Props {
  label: string;
  value?: Date;
  onChange?: (value: Date) => {};
  error?: string | any;
}

const TimeInput = forwardRef(
  ({label, required = false, onChange, value, error}: Props, ref) => {
    const styles = useStyle();

    const [edit, setEdit] = useState<boolean>(false);
    const [hour, setHour] = useState(moment(value).format('hh'));
    const [minute, setMinute] = useState(moment(value).format('mm'));
    const [amPmString, setAmPmString] = useState(
      moment(value).format('hh:mm a').slice(-2),
    );

    const setTime = () => {
      const hourInt = parseInt(hour, 10);
      const minuteInt = parseInt(minute, 10);
      const date = moment(value);
      const isValidHour = hourInt > 0 && hourInt <= 12;
      const isValidMinute = minuteInt >= 0 && minuteInt <= 59;

      const validateHour = () => {
        if (amPmString === 'pm' && hourInt !== 12) {
          return hourInt + 12;
        } else if (amPmString === 'am' && hourInt >= 12) {
          return hourInt - 12;
        } else {
          return hourInt;
        }
      };

      if (isValidHour && isValidMinute) {
        date.set({
          hour: validateHour(),
          minute: minuteInt,
        });
        onChange(date.toDate());
      }
      setEdit(false);
    };

    const cancelChange = () => {
      setHour(moment(value).format('hh'));
      setMinute(moment(value).format('mm'));
      setAmPmString(moment(value).format('hh:mm a').slice(-2));
      setEdit(false);
    };

    return (
      <>
        <FormLabel label={label + (required ? '*' : '')} />
        {edit ? (
          <>
            <View style={[styles.container]}>
              <View
                style={[
                  styles.flex,
                  styles.row,
                  styles.alignCenter,
                  styles.maxSkillCardWidth,
                ]}>
                <View style={[styles.width50]}>
                  <FormInput
                    ref={ref}
                    label={'H'}
                    value={hour.toString()}
                    keyboardType={'number-pad'}
                    onChange={onChangeValue => {
                      if (
                        (onChangeValue &&
                          Number.isNaN(Number(onChangeValue))) ||
                        onChangeValue === '00' ||
                        onChangeValue.length > 2 ||
                        (onChangeValue && Number(onChangeValue) > 12)
                      ) {
                        return;
                      } else {
                        setHour(onChangeValue);
                      }
                    }}
                    showHelper={false}
                    error={error}
                  />
                </View>
                <Separator width={6} />
                <Text>:</Text>
                <Separator width={6} />
                <View style={[styles.width50]}>
                  <FormInput
                    label={'M'}
                    keyboardType={'number-pad'}
                    value={minute.toString()}
                    onChange={onChangeValue => {
                      if (
                        (onChangeValue &&
                          Number.isNaN(Number(onChangeValue))) ||
                        onChangeValue.length > 2 ||
                        (onChangeValue && Number(onChangeValue) > 59)
                      ) {
                        return;
                      } else {
                        setMinute(onChangeValue);
                      }
                    }}
                    showHelper={false}
                    error={error}
                  />
                </View>
                <Separator width={12} />
                <View style={[styles.width100]}>
                  <SelectInput
                    searchable={false}
                    items={times}
                    showHelper={false}
                    value={amPmString}
                    label={''}
                    onChange={onChangeValue => setAmPmString(onChangeValue)}
                  />
                </View>
              </View>
            </View>
            <View
              style={[
                styles.row,
                styles.alignCenter,
                styles.flex,
                styles.paddingMVertical,
              ]}>
              <RHButton
                secondary
                onPress={() => cancelChange()}
                textColor={Colors.RAVEN_BLACK}
                color="black"
                mode="outlined">
                Cancel
              </RHButton>
              <Separator width={12} />
              <RHButton mode="contained" onPress={() => setTime()}>
                SAVE
              </RHButton>
            </View>
          </>
        ) : (
          <View style={[styles.row, styles.alignCenter, styles.flex]}>
            <View>
              <Text style={[Typography.P3]}>
                {moment(value).format('hh:mm a')}
              </Text>
            </View>
            <View style={[styles.row]}>
              <TouchableOpacity
                style={styles.padding}
                onPress={() => setEdit(true)}>
                <Icon name={'pencil'} size={20} color={Colors.PRIMARY_900} />
              </TouchableOpacity>
            </View>
          </View>
        )}
      </>
    );
  },
);

export default TimeInput;
