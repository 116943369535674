import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {Text, View} from 'react-native';
import {IconButton} from 'react-native-paper';
import Cpt, {cptCodes} from 'src/hook-form-inputs/cpt';
import FormInput from 'src/hook-form-wrapper/form-input';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';

const PayerPlanFeeScheduleItem = ({item, index, name, remove}: any) => {
  const styles = useStyle();
  const {control, setValue} = useFormContext();
  const watchedCpt = useWatch({
    control,
    name: `${name}.feeSchedule.${index}.cpt`,
  });

  useEffect(() => {
    if (item.cpt === watchedCpt) {
      return;
    }
    if (watchedCpt) {
      setValue(
        `${name}.feeSchedule.${index}.description`,
        cptCodes.find(cpt => cpt.value === watchedCpt)?.label,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedCpt]);

  return (
    <View
      key={item.id}
      style={[
        styles.row,
        styles.borderBottom,
        styles.alignCenter,
        styles.justifyCenter,
        styles.flex,
      ]}>
      <View
        style={[
          styles.paddingLTop,
          styles.paddingLRight,
          styles.paddingLBottom,
          styles.borderRight,
          styles.flex05,
          styles.alignCenter,
          styles.justifyCenter,
        ]}>
        <View style={[styles.width]}>
          <Cpt
            name={`${name}.feeSchedule.${index}`}
            showHelper={false}
            valueKey={'value'}
          />
        </View>
      </View>
      <View
        style={[
          styles.paddingL,
          styles.borderRight,
          styles.flex,
          styles.alignCenter,
          styles.justifyCenter,
          styles.height,
        ]}>
        <View style={[styles.width]}>
          <HookFormInput
            name={`${name}.feeSchedule.${index}.description`}
            label={'Service Description'}
            showHelper={false}
          />
        </View>
      </View>
      <View
        style={[
          styles.paddingL,
          styles.borderRight,
          styles.flex05,
          styles.alignCenter,
        ]}>
        <View style={[styles.width]}>
          <HookFormInput
            name={`${name}.feeSchedule.${index}.modifier1`}
            label={'Mod #1'}
            showHelper={false}
          />
        </View>
      </View>
      <View
        style={[
          styles.paddingL,
          styles.borderRight,
          styles.flex05,
          styles.alignCenter,
        ]}>
        <View style={[styles.width]}>
          <HookFormInput
            name={`${name}.feeSchedule.${index}.modifier2`}
            label={'Mod #2'}
            showHelper={false}
          />
        </View>
      </View>
      <View
        style={[
          styles.paddingL,
          styles.borderRight,
          styles.flex05,
          styles.alignCenter,
        ]}>
        <View style={[styles.width]}>
          <HookFormInput
            name={`${name}.feeSchedule.${index}.modifier3`}
            label={'Mod #3'}
            showHelper={false}
          />
        </View>
      </View>
      <View
        style={[
          styles.paddingL,
          styles.borderRight,
          styles.flex05,
          styles.alignCenter,
        ]}>
        <View style={[styles.width]}>
          <HookFormInput
            name={`${name}.feeSchedule.${index}.modifier4`}
            label={'Mod #4'}
            showHelper={false}
          />
        </View>
      </View>
      <View
        style={[
          styles.paddingLLeft,
          styles.borderRight,
          styles.flex07,
          styles.alignCenter,
        ]}>
        <View style={[styles.row, styles.alignCenter, styles.width]}>
          <View style={[styles.width, styles.flex]}>
            <FormInput
              name={`${name}.feeSchedule.${index}.rate`}
              label={''}
              showHelper={false}
              required={false}
              keyboardType="numeric"
            />
          </View>
          <Text
            style={[
              Typography.P3,
              styles.textColorPrimary,
              styles.marginSMLeft,
            ]}>
            / 1 unit
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.paddingLLeft,
          styles.borderRight,
          styles.flex07,
          styles.alignCenter,
        ]}>
        <View style={[styles.row, styles.alignCenter, styles.width]}>
          <View style={[styles.width, styles.flex]}>
            <FormInput
              name={`${name}.feeSchedule.${index}.billingRate`}
              label={''}
              showHelper={false}
              required={false}
              keyboardType="numeric"
            />
          </View>
          <Text
            style={[
              Typography.P3,
              styles.textColorPrimary,
              styles.marginSMLeft,
            ]}>
            / 1 unit
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.borderRight,
          styles.alignCenter,
          styles.justifyCenter,
          styles.height,
        ]}>
        <IconButton
          color={Colors.RAVEN_BLACK}
          icon="trash-can"
          onPress={() => remove(index)}
        />
      </View>
    </View>
  );
};

export default PayerPlanFeeScheduleItem;
