import React, {useEffect, useMemo, useState} from 'react';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {Q} from '@nozbe/watermelondb';
import {CardList} from 'src/design-system';
import {ProgramCard} from 'src/modules/programs/components';
import withState from 'src/redux/wrapper';
import {fuzzy} from 'src/common-utils/fuzzy';

const ProgramList = ({
  toggleable = false,
  toggled = () => false,
  viewCallback,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
  canArchive,
  archiveCallback,
  search,
  filteredPrograms,
  filter,
}: any) => {
  const [sortedPrograms, setSortedPrograms] = useState(filteredPrograms);
  const tagIds = [
    ...filter?.assessments,
    ...filter?.skills,
    ...filter?.behaviors,
    ...filter?.relatedServices,
  ];

  useEffect(() => {
    const curatePrograms = async () => {
      const curatedPrograms = [];
      for (const program of filteredPrograms) {
        const programTags = await program.tags.fetch();
        const tags = programTags.map(tag => tag.id);
        if (tagIds.every(id => tags.includes(id))) {
          curatedPrograms.push(program);
        }
      }
      setSortedPrograms(curatedPrograms);
    };
    curatePrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filteredPrograms]);

  const searchedPrograms = useMemo(() => {
    return sortedPrograms?.filter((program: any) =>
      fuzzy(program.name, search),
    );
  }, [sortedPrograms, search]);

  return (
    <CardList
      items={search ? searchedPrograms : sortedPrograms}
      loading={false}
      emptyIcon={'view-grid-outline'}
      emptyMessage={'No programs found.'}
      renderItem={({item}) => (
        <ProgramCard
          program={item}
          viewCallback={() => viewCallback(item)}
          canEdit={canEdit}
          editCallback={() => editCallback(item)}
          canDelete={canDelete}
          deleteCallback={() => deleteCallback(item)}
          canArchive={canArchive}
          archiveCallback={() => archiveCallback(item)}
          toggleable={toggleable}
          toggled={toggled(item)}
          toggleFunction={() => viewCallback(item)}
        />
      )}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['filter', 'patientId', 'sortBy'],
    ({patientId, database, authentication, filter, sortBy}: any) => {
      const queries = [];
      if (patientId) {
        queries.push(Q.where('patient_id', Q.eq(patientId)));
      } else {
        queries.push(
          Q.or(
            Q.where('patient_id', Q.eq('')),
            Q.where('patient_id', Q.eq(null)),
          ),
        );
      }
      if (filter?.startDate) {
        queries.push(Q.where('created_at', Q.gte(filter.startDate.getTime())));
      }
      if (filter?.endDate) {
        queries.push(Q.where('created_at', Q.lte(filter.endDate.getTime())));
      }
      if (filter?.programState && filter?.programState.includes('archived')) {
        queries.push(Q.where('state', 'archived'));
      } else {
        queries.push(Q.where('state', Q.notEq('archived')));
      }

      const hasType = filter?.programType && filter.programType.length;

      if (hasType) {
        queries.push(Q.where('type', Q.oneOf(filter.programType)));
      }

      const tagIds = [
        ...filter.assessments,
        ...filter.skills,
        ...filter.behaviors,
        ...filter.relatedServices,
      ];

      let sortByQuery;

      if (sortBy === 'name-asc') {
        sortByQuery = Q.sortBy('name', Q.asc);
      } else if (sortBy === 'name-desc') {
        sortByQuery = Q.sortBy('name', Q.desc);
      } else {
        sortByQuery = Q.sortBy('updated_at', Q.desc);
      }

      return {
        filteredPrograms: database
          .get('programs')
          .query(
            ...(tagIds.length
              ? [Q.on('program_tag', Q.where('tag_id', Q.oneOf(tagIds)))]
              : []),
            Q.and(
              ...queries,
              Q.where('deleted_at', Q.eq(null)),
              Q.where('_partition', authentication.selectedGroup),
            ),
            sortByQuery,
          )
          .observe(),
      };
    },
  ),
)(ProgramList);
