import React, {createContext, useContext, useEffect, useState} from 'react';
import {useDimensions} from '@react-native-community/hooks';
import getStyles from 'src/styles/styles';

const context = createContext<any>({});

export default context;

export const Provider = context.Provider;
export const Consumer = context.Consumer;

export const useStyle = () => useContext(context);

export const StyleProvider = ({children}: {children: any}) => {
  const dimensions = useDimensions();

  const [styles, setStyles] = useState(
    getStyles(dimensions.window.width, dimensions.window.height),
  );

  useEffect(() => {
    setStyles(getStyles(dimensions.window.width, dimensions.window.height));
  }, [dimensions.window.width, dimensions.window.height]);

  return <Provider value={styles}>{children}</Provider>;
};
