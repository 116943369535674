import React from 'react';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {Platform, Text, TouchableOpacity, View} from 'react-native';
import {FormSectionHeader} from 'src/design-system';
import {useStyle} from 'src/providers/style';
import InsuranceItem, {
  Display as InsuranceItemDisplay,
  getInsuranceTitle,
} from 'src/modules/billing/components/insurance-item';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {Insurance, Payer, PayerPlans} from 'src/models';
import DraggableFlatList from 'react-native-draggable-flatlist';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {useTranslations} from 'src/providers/translation';

const isWeb = Platform.OS === 'web';

export const defaultPayer = {
  groupNumber: '',
  memberId: '',
  planId: '',
  payerId: '',
};

export const defaultSubscriber = {
  firstName: '',
  lastName: '',
  address: {},
  ssn: '',
  relationship: '',
  mobilePhone: '',
  birthDate: undefined,
};

const InsuranceFormDisplay = ({insurances, showTitle = true}: any) => {
  const styles = useStyle();

  return (
    <View style={[styles.column, styles.paddingBottom]}>
      {showTitle ? <FormSectionHeader title={'Insurance'} /> : <></>}
      {insurances.map((insurance: any, index: number) =>
        insurance.payerId && !insurance.deletedAt ? (
          <InsuranceItemDisplay
            key={`insurance-${index}`}
            value={insurance}
            index={index}
          />
        ) : null,
      )}
    </View>
  );
};

const InsuranceForm = ({
  showAuthorizations = false,
  showTitle = true,
  showBoxNumbers = false,
}) => {
  const styles = useStyle();
  const translations = useTranslations();
  const {control, setValue} = useFormContext();

  const {fields, remove, append, replace} = useFieldArray({
    name: 'insurances',
    control,
  });

  const watchedPayers = useWatch({
    control,
    name: 'insurances',
  });

  const reorderPayers = useWatch({
    control,
    name: 'reorderPayers',
  });

  const deleteCallback = async (item: any, index: any) => {
    if (item._id) {
      const insuranceFields = [...fields];
      insuranceFields[index] = {
        ...item,
        deletedAt: new Date(),
      };
      replace(insuranceFields);
    } else {
      remove(index);
    }
  };

  return (
    <>
      <View
        style={[styles.row, styles.alignCenter, styles.justifySpaceBetween]}>
        {showTitle ? (
          <FormSectionHeader title={translations('insurance')} />
        ) : null}
        {Platform.OS === 'web' ? (
          <HookFormSwitchInput
            name={'reorderPayers'}
            label={translations('drag_to_reorder')}
            showHelper={false}
          />
        ) : null}
      </View>
      {reorderPayers ? (
        <DraggableFlatList
          data={watchedPayers}
          keyExtractor={(item, index) => `item-${index}`}
          onDragEnd={({data}) => {
            setValue('insurances', data);
          }}
          renderItem={({item, drag, getIndex}) => {
            const index = getIndex();
            return <InsuranceDragItem value={item} index={index} drag={drag} />;
          }}
        />
      ) : (
        <>
          {fields.map((item: any, index: number) => {
            if (item?.deletedAt) {
              return null;
            }
            return (
              <InsuranceItem
                key={`insurance-${index}`}
                item={item}
                index={index}
                deleteCallback={deleteCallback}
                showAuthorizations={showAuthorizations}
                showBoxNumbers={showBoxNumbers}
              />
            );
          })}
          <View style={[styles.row, styles.marginBottom]}>
            <RHButton
              icon="plus-circle-outline"
              mode="outlined"
              color={Colors.RAVEN_WHITE}
              textColor={Colors.RAVEN_BLACK}
              onPress={() => {
                append(defaultPayer);
              }}>
              {translations('add_more')}
            </RHButton>
          </View>
        </>
      )}
    </>
  );
};

const InsuranceDragItem = compose(
  withDatabase,
  withObservables(['value'], ({value, database}: any) => {
    return {
      payer: value.payerId
        ? database.get(Payer.table).findAndObserve(value?.payerId)
        : of({}),
      payerPlan: value.planId
        ? database.get(PayerPlans.table).findAndObserve(value?.planId)
        : of({}),
      insurance: value._id
        ? database.get(Insurance.table).findAndObserve(value?._id)
        : of({
            subscriber: value.subscriber,
          }),
    };
  }),
)(({index, drag, payer, payerPlan, insurance}: any) => {
  const styles = useStyle();
  return (
    <View
      key={`item-${index}`}
      style={[styles.row, styles.backgroundColorWhite]}>
      <TouchableOpacity
        style={[styles.paddingRight, styles.justifyCenter]}
        onPressIn={isWeb ? drag : () => {}}
        onLongPress={!isWeb ? drag : () => {}}
        delayLongPress={50}>
        <Icon
          color={Colors.GRAY_400}
          style={[styles.padding]}
          name="reorder-horizontal"
          size={20}
        />
      </TouchableOpacity>
      <View style={[styles.justifyCenter]}>
        <Text style={[Typography.H6]}>{getInsuranceTitle(index)}</Text>
      </View>
      <View
        style={[
          styles.container,
          styles.marginHorizontal,
          styles.alignSelfCenter,
          styles.justifyEvenly,
          styles.flex,
        ]}>
        <View style={[styles.paddingHorizontal, styles.flex05]}>
          <Text style={[Typography.H6]}>Payer</Text>
          <Text style={[Typography.P3]}>
            {payer?.name || 'No Payer Selected'}
          </Text>
        </View>
        <View style={[styles.paddingHorizontal, styles.flex13]}>
          <Text style={[Typography.H6]}>Plan</Text>
          <Text style={[Typography.P3]}>
            {payerPlan?.name || 'No Plan Selected'}
          </Text>
        </View>
        <View style={[styles.paddingHorizontal, styles.flex13]}>
          <Text style={[Typography.H6]}>Subscriber</Text>
          <Text style={[Typography.P3]}>
            {insurance?.subscriber?.firstName && insurance?.subscriber?.lastName
              ? insurance?.subscriber?.firstName +
                ' ' +
                insurance?.subscriber?.lastName
              : 'No Subscriber'}
          </Text>
        </View>
      </View>
    </View>
  );
});

export const Display = InsuranceFormDisplay;

export default InsuranceForm;
