import React from 'react';
import {View} from 'react-native';
import {Table, Row, Rows} from 'react-native-table-component';
import {useStyle} from 'src/providers/style';

const CsvViewer = ({csvData}: any) => {
  const styles = useStyle();
  const tableHeaders = csvData[0];
  const tableData = csvData.slice(1, csvData.length - 1);

  return (
    <View
      style={[
        styles.backgroundColorWhite,
        styles.flex,
        styles.padding,
        styles.paddingLHorizontal,
        styles.paddingLBottom,
      ]}>
      <Table borderStyle={[styles.border, styles.borderColorPrimary300]}>
        <Row
          data={tableHeaders}
          style={[styles.flex, styles.height40]}
          textStyle={[
            styles.alignCenter,
            styles.justifyCenter,
            styles.textAlignCenter,
            styles.marginSM,
          ]}
        />
        <Rows
          data={tableData}
          textStyle={[
            styles.alignCenter,
            styles.justifyCenter,
            styles.textAlignCenter,
            styles.marginSM,
          ]}
        />
      </Table>
    </View>
  );
};

export default CsvViewer;
