import React, {useEffect} from 'react';
import {View, Text, FlatList} from 'react-native';
import ListItemSeparator from 'src/common-components/separator';
import PatientListItem from 'src/modules/users/components/assign-patients/components/PatientListItem';
import NoResults from 'src/common-components/noResults';
import {Typography} from 'src/styles';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import {useSelector} from 'react-redux';
import {of} from 'rxjs';
import withState from 'src/redux/wrapper';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {CareTeamParticipant, User} from 'src/models';
import ClientSelect from 'src/hook-form-inputs/client';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import _ from 'lodash';

interface Props {
  user: any;
  patients: any[];
  role: any;
}

const AssignPatientsTab = ({user, patients, role}: Props) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const {selectedGroup} = useSelector(state => state.authentication);
  const methods = useForm({
    defaultValues: {
      clients: patients.map((patient: any) => patient.id),
    },
  });
  const clients = useWatch({control: methods.control, name: 'clients'});

  useEffect(() => {
    const deleteClients = _.differenceWith(
      patients,
      clients,
      (value1: any, value2: string) => {
        return value1.id === value2;
      },
    );
    const differenceClients = _.differenceWith(
      clients,
      patients,
      (value1: string, value2: any) => {
        return value1 === value2.id;
      },
    );

    for (const client of deleteClients) {
      onAssign(client.id);
    }
    for (const client of differenceClients) {
      onAssign(client);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, clients]);

  const onAssign = async (patient: any) => {
    const careTeam = await database
      .get(CareTeamParticipant.table)
      .query(
        Q.and(
          Q.where('patient_id', patient),
          Q.where('user_id', user.id),
          Q.where('deleted_at', null),
        ),
      )
      .fetch();
    if (careTeam.length === 0) {
      await database.write(async () => {
        await database
          .get(CareTeamParticipant.table)
          .create(careTeamParticipant => {
            careTeamParticipant.partition = selectedGroup;
            careTeamParticipant.patient.id = patient;
            careTeamParticipant.user.id = user.id;
          });
      });
    } else {
      for (const participant of careTeam) {
        participant.delete();
      }
    }
  };

  return (
    <View style={[styles.flex, styles.width, styles.padding]}>
      <Text style={[Typography.H5, styles.marginLTop, styles.marginBottom]}>
        {patients.length} {translations('clients_assigned')}
      </Text>
      {role?.assignedClientCreate ? (
        <FormProvider {...methods}>
          <ClientSelect selectAll={true} />
        </FormProvider>
      ) : (
        <></>
      )}
      <View
        style={[
          styles.flex,
          styles.width,
          styles.justifyContentCenter,
          styles.backgroundColorWhite,
          styles.borderRadius,
        ]}>
        {patients?.length ? (
          <>
            <View
              style={[
                styles.row,
                styles.alignSelfCenter,
                styles.justifyCenter,
                styles.width,
                styles.marginLVertical,
              ]}>
              <Text style={[Typography.H6, styles.flex, styles.paddingLLeft]}>
                {translations('name')}
              </Text>
            </View>
            <ListItemSeparator />
            <FlatList
              data={patients}
              keyExtractor={item => item?.id?.toString()}
              renderItem={({item}: any) => {
                return (
                  <PatientListItem
                    item={item}
                    canEdit={role?.assignedClientEdit}
                    onAssign={(patient: any) => onAssign(patient.id)}
                    canDelete={role?.assignedClientDelete}
                  />
                );
              }}
              scrollEnabled={true}
              ItemSeparatorComponent={ListItemSeparator}
            />
          </>
        ) : (
          <NoResults
            iconName="account-supervisor"
            message={translations('no_clients_assigned')}
          />
        )}
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(['user'], ({user}: any) => ({
    patients: user.patients,
  })),
)(AssignPatientsTab);
