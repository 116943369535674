import React from 'react';
import {Payer} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';

interface Props {
  name: string;
  selectAll?: boolean;
  multiple?: boolean;
  items: any[];
  required?: boolean;
}

const PayerPlans = ({
  name,
  selectAll = false,
  multiple = false,
  items,
  required = false,
}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'planId'}
      label={'Select Plan'}
      required={required}
      multiple={multiple}
      selectAll={selectAll}
      items={items}
    />
  );
};

export default compose(
  withState,
  withDatabase,
  withObservables(['id'], ({database, id}: any) => ({
    payer: id ? database.get(Payer.table).findAndObserve(id) : of({}),
  })),
  withObservables(['payer'], ({payer}: any) => ({
    plans: Object.keys(payer).length ? payer.activePlans : of([]),
  })),
  withObservables(['plans'], ({plans}: any) => ({
    items: of(
      plans
        .map(plan => ({
          label: plan.name || '(No Plan Name)',
          value: plan.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ),
  })),
)(PayerPlans);
