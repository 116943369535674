import React, {useEffect, useRef, useState} from 'react';
import {boxShadow} from 'src/styles/mixins';
import {useDimensions} from '@react-native-community/hooks';
import EStyleSheet from 'react-native-extended-stylesheet';
import {Colors} from 'src/styles';
import {Animated, TouchableOpacity, View} from 'react-native';
import {Portal} from 'react-native-paper';
import {Easing} from 'react-native-reanimated';

const Drawer = ({children, open, setOpen}: any) => {
  const dimensions = useDimensions();

  const [width, setWidth] = useState(5000);
  const [rendered, setRendered] = useState(false);

  const drawerLeft = useRef(new Animated.Value(750)).current;
  const opacity = useRef(new Animated.Value(0)).current;

  const styles = getStyles(dimensions.window.height, dimensions.window.width);

  useEffect(() => {
    if (!rendered) {
      drawerLeft.setValue(dimensions.window.width);
      setRendered(true);
    } else {
      Animated.timing(drawerLeft, {
        toValue: open
          ? dimensions.window.width - width
          : dimensions.window.width,
        duration: 250,
        easing: Easing.out(Easing.cubic),
        useNativeDriver: true,
      }).start();
    }
  }, [open, drawerLeft, dimensions.window.width, width, rendered]);

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: open ? 1 : 0,
      duration: 250,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: true,
    }).start();
  }, [open, opacity]);

  return (
    <Portal>
      {open ? (
        <TouchableOpacity
          // accessibilityLabel={overlayAccessibilityLabel}
          // accessibilityRole="button"
          // disabled={!dismissable}
          onPress={() => (setOpen ? setOpen(false) : undefined)}
          importantForAccessibility="no">
          <View
            // testID={`${testID}-backdrop`}
            style={[
              styles.overlay,
              // {
              //   opacity,
              // },
            ]}
          />
        </TouchableOpacity>
      ) : null}
      <View
        pointerEvents="box-none"
        style={[styles.width, styles.height, styles.overflowHidden]}>
        <Animated.View
          onLayout={e => {
            setWidth(e.nativeEvent.layout.width);
          }}
          style={[
            {transform: [{translateX: drawerLeft}]},
            styles.absolute,
            styles.top,
            styles.bottom,
            styles.container,
            styles.backgroundColorWhite,
            styles.drawerZIndex,
            styles.maxWidth,
            styles.width,
          ]}>
          {children}
        </Animated.View>
      </View>
    </Portal>
  );
};

const getStyles = (height: number, width: number) =>
  EStyleSheet.create({
    drawerZIndex: {
      zIndex: 10,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    height: {
      height: height,
    },
    maxWidth: {
      maxWidth: 750,
    },
    overflowHidden: {
      overflow: 'hidden',
    },
    drawerWidth: {
      width: 750,
    },
    width: {
      width: width,
    },
    absolute: {
      position: 'absolute',
    },
    left: {
      left: width,
    },
    right: {
      right: 0,
    },
    top: {
      top: 0,
    },
    bottom: {
      bottom: 0,
    },
    container: {
      flex: 1,
    },
    backgroundColorWhite: {
      backgroundColor: 'white',
      ...boxShadow(Colors.RAVEN_BLACK),
    },
  });

export default Drawer;
