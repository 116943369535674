import React from 'react';
import {View, Text} from 'react-native';
import {Checkbox} from 'react-native-paper';
import {Field} from 'formik';
import EStyleSheet from 'react-native-extended-stylesheet';
import {memoize} from 'lodash';

import {InputGroup} from 'src/design-system';
import {Colors, Typography} from 'src/styles';
import {
  AppointmentRepeatInterval,
  AppointmentRepeatFreq,
  AppointmentRepeatEndMode,
  AppointmentRepeatByWeekDay,
  Date,
  StartTime,
  EndTime,
} from 'src/form-inputs';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';

const AppointmentDateTime = ({repeatCheck = true}) => {
  const styles = getStyles();

  return (
    <Field name="repeat">
      {({field, form: {setFieldValue}}: any) => {
        return (
          <>
            {repeatCheck && (
              <View style={styles.checkboxContainer}>
                <Checkbox.Android
                  status={field.value ? 'checked' : 'unchecked'}
                  onPress={() => setFieldValue(field.name, !field.value)}
                  color={Colors.RAVEN_BLACK}
                  uncheckedColor={Colors.RAVEN_BLACK}
                />
                <Text
                  style={Typography.P3}
                  onPress={() => setFieldValue(field.name, !field.value)}>
                  Event Repeats
                </Text>
              </View>
            )}
            {field.value ? (
              <>
                <InputGroup
                  left={<AppointmentRepeatInterval.Input />}
                  right={<AppointmentRepeatFreq.Input />}
                />
                <AppointmentRepeatByWeekDay.Input />
                <RHSeparator height={20} />
                <AppointmentRepeatEndMode.Input />
              </>
            ) : (
              <></>
            )}
            <InputGroup left={<Date.Input />} right={<></>} />
            <InputGroup left={<StartTime.Input />} right={<EndTime.Input />} />
          </>
        );
      }}
    </Field>
  );
};

const getStyles = memoize(() =>
  EStyleSheet.create({
    checkboxContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      color: Colors.TEXT_PRIMARY_GRAY,
      marginVertical: 20,
    },
    row: {
      flexDirection: 'row',
    },
  }),
);

export const Input = AppointmentDateTime;

export default AppointmentDateTime;
