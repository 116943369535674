import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {numberRange} from 'src/common-utils/nuberRange';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  items?: any;
  type?: string;
}

export const items = numberRange(0, 100).map(num => ({
  label: num.toString(),
  value: num,
}));

export const Import = (
  key: string = 'masteryValue',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Number of Occurrences',
  key,
  validators: [
    // {validate: 'require_with_values', fieldValues: {method: 'trial_by_trial'}},
  ],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('num_of_occurrences')}</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, type}: Props) => {
  const translations = useTranslations();
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + type}
      label={translations('num_of_occurrences')}
      placeholder={translations('num_of_occurrences')}
      helper={''}
      required={false}
      items={items}
    />
  );
};

export const validation = () =>
  Yup.string().required('Number of Occurrences is required');

export default Input;
