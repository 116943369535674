import React from 'react';
import {View} from 'react-native';
import {
  Input as BirthDateInput,
  Display as BirthDateDisplay,
} from 'src/hook-form-inputs/birth-date';
import {
  Input as IdentifierInput,
  Display as IdentifierDisplay,
} from 'src/hook-form-inputs/identifier';
import {
  Input as FirstNameInput,
  Display as FirstNameDisplay,
} from 'src/hook-form-inputs/first-name';
import {
  Input as GenderInput,
  Display as GenderDisplay,
} from 'src/hook-form-inputs/gender';
import {
  Input as MiddleNameInput,
  Display as MiddleNameDisplay,
} from 'src/hook-form-inputs/middle-name';
import {
  Input as LastNameInput,
  Display as LastNameDisplay,
} from 'src/hook-form-inputs/last-name';
import {Display as RelationshipDisplay} from 'src/hook-form-inputs/relationship';
import {Display as PhoneNumberDisplay} from 'src/hook-form-inputs/phone-number';
import {Display as EmailDisplay} from 'src/hook-form-inputs/email';
import {
  Input as SSNInput,
  Display as SSNDisplay,
} from 'src/hook-form-inputs/social-security-number';
import FormSectionHeader from 'src/design-system/form-section-header';
import {Tags} from 'src/hook-form-inputs';
import {Input as RelationshipInput} from 'src/hook-form-inputs/relationship';
import {Input as PhoneNumberInput} from 'src/hook-form-inputs/phone-number';
import {Input as EmailInput} from 'src/hook-form-inputs/email';
import Address from 'src/hook-form-inputs/address';
import {useStyle} from 'src/providers/style';

export const Value = (value: string) => {
  return value;
};

export const fields = ['firstName', 'lastName'];

const ClientFormDisplay = ({
  identifier,
  firstName,
  middleName,
  lastName,
  gender,
  birthDate,
  ssn,
  contact,
  tags,
}: any) => {
  const styles = useStyle();
  return (
    <View style={[styles.flex, styles.column]}>
      <FormSectionHeader title={'Client Information'} />
      <IdentifierDisplay value={identifier} />
      <FirstNameDisplay value={firstName} />
      <MiddleNameDisplay value={middleName} />
      <LastNameDisplay value={lastName} />
      <GenderDisplay value={gender} />
      <BirthDateDisplay value={birthDate} />
      <SSNDisplay value={ssn} />
      <FormSectionHeader title={'Contact Information'} />
      <FirstNameDisplay value={contact?.firstName} />
      <LastNameDisplay value={contact?.lastName} />
      <RelationshipDisplay value={contact?.relationship} />
      <PhoneNumberDisplay value={contact?.mobilePhone} />
      <EmailDisplay value={contact?.email} />
      <Tags.Display value={tags} type={'profile'} />
    </View>
  );
};

export const ClientFormInput = () => {
  const styles = useStyle();
  return (
    <>
      <View style={[styles.paddingHorizontal]}>
        <FormSectionHeader title={'Client Information'} />
      </View>
      <View style={[styles.paddingHorizontal]}>
        <IdentifierInput />
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <FirstNameInput required={true} />
        </View>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <MiddleNameInput />
        </View>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <LastNameInput required={true} />
        </View>
      </View>
      <View style={[styles.paddingHorizontal]}>
        <GenderInput />
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <BirthDateInput />
        </View>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <SSNInput />
        </View>
      </View>
      <View style={[styles.paddingHorizontal]}>
        <Address
          name={'schoolId'}
          label={'School'}
          showOther={false}
          canCancel={true}
        />
      </View>
      <View style={[styles.paddingHorizontal]}>
        <FormSectionHeader title={'Contact Information'} />
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <FirstNameInput name={'caregivers.0'} />
        </View>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <LastNameInput name={'caregivers.0'} />
        </View>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <RelationshipInput name={'caregivers.0'} />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <PhoneNumberInput name={'caregivers.0'} />
        </View>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <EmailInput name={'caregivers.0'} />
        </View>
      </View>
      <View style={[styles.flex, styles.paddingHorizontal]}>
        <FormSectionHeader title={'Tags'} />
      </View>
      <View style={[styles.container, styles.width]}>
        <View
          style={[styles.flex, styles.paddingHorizontal, styles.paddingBottom]}>
          <Tags.Input type={'profile'} />
        </View>
      </View>
    </>
  );
};

export const Display = ClientFormDisplay;
export const Input = ClientFormInput;

export default ClientFormInput;
