import React from 'react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GOOGLE_WEB_OAUTH} from 'react-native-dotenv';

export const GoogleProvider = ({children}: {children: any}) => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_WEB_OAUTH}>
      {children}
    </GoogleOAuthProvider>
  );
};
