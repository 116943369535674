import React, {useState} from 'react';
import {View, Text, TouchableOpacity, FlatList, ScrollView} from 'react-native';
import {Colors, Typography} from 'src/styles';
import TimesheetStaff from 'src/modules/organization/components/timesheet-staff';
import TimesheetFilterMenu from '../timesheet-filter';
import {IconButton} from 'src/design-system';
import TimesheetClients from 'src/modules/organization/components/timesheet-clients';
import TimesheetSchool from 'src/modules/organization/components/timesheet-school';
import {endOfWeek, startOfWeek} from 'date-fns';
import {exportCsv} from 'src/common-utils/export-csv';
import {appointmentLocations} from 'src/form-inputs/appointment-location-input';
import {IconButton as PaperIconButton} from 'react-native-paper';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of, mergeMap} from 'rxjs';
import {Q} from '@nozbe/watermelondb';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {statusArray} from 'src/hook-form-inputs/status-checkbox';
import {cptCodes} from 'src/hook-form-inputs/cpt';
import {Instance} from 'src/models';

const Timesheet = ({patients, users, instance}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState<any>({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
    allTime: [],
    staff: [],
    clients: [],
    locations: [],
    types: [],
    status: ['completed'],
  });

  const showSchoolTab =
    // Account number '77777' === Raven ABA Services dev instance
    instance?.accountNumber === '77777' || instance?.accountNumber === '6594';
  const [currentTab, setCurrentTab] = useState<string>(
    showSchoolTab ? 'SCHOOL' : 'STAFF',
  );

  const initiateCsvExport = async (name: string, content: string[][]) => {
    setLoading(true);
    await exportCsv(name.toLowerCase() + '_timesheet_export', content);
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const getData = () => {
    let result = [];
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        result.push(...filters[key]);
      }
    }
    return result;
  };

  const deleteTag = (tag: any) => {
    let result: any = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        result[key] = filters[key].filter((item: any) => item !== tag);
      } else {
        result[key] = filters[key];
      }
    }
    setFilters(result);
  };

  const getName = (collection, tag) => {
    const selectedUser = collection.find(member => member.id === tag);
    return `${selectedUser.firstName} ${selectedUser.lastName}`;
  };

  return (
    <>
      <View style={[styles.row, styles.justifySpaceBetween, styles.padding]}>
        <View>
          <Text
            style={[
              Typography.H1,
              styles.row,
              styles.textAlignCenter,
              styles.paddingTop,
              styles.textColorPrimary,
            ]}>
            {translations('timesheet')}
          </Text>
        </View>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifyEnd,
            styles.alignSelfCenter,
          ]}>
          <View style={[styles.paddingLRight]}>
            <IconButton
              loading={loading}
              disabled={loading || success}
              icon={success ? 'check' : 'download'}
              type={'outlined'}
              color={success ? Colors.SUCCESS : Colors.RAVEN_BLACK}
              onPress={() => initiateCsvExport(currentTab, items)}
            />
          </View>
          <TimesheetFilterMenu applyFilters={setFilters} filters={filters} />
        </View>
      </View>

      <View
        style={[styles.width, styles.paddingHorizontal, styles.paddingBottom]}>
        <ScrollView style={[styles.maxWidth]}>
          <FlatList
            horizontal
            data={getData()}
            renderItem={({item: selectedTag}) => (
              <View
                style={[
                  styles.backgroundColorBlack,
                  styles.textColorWhite,
                  styles.borderRadiusSM,
                  styles.marginSMVertical,
                  styles.marginLRight,
                  styles.row,
                ]}
                key={`filter-tag-key-${selectedTag}`}>
                <Text
                  style={[
                    Typography.P2,
                    styles.textColorWhite,
                    styles.alignSelfCenter,
                    styles.marginLHorizontal,
                  ]}>
                  {appointmentLocations.findIndex(
                    location => location.value === selectedTag,
                  ) !== -1
                    ? appointmentLocations[
                        appointmentLocations.findIndex(
                          location => location.value === selectedTag,
                        )
                      ].label
                    : cptCodes.findIndex(type => type.value === selectedTag) !==
                      -1
                    ? cptCodes[
                        cptCodes.findIndex(type => type.value === selectedTag)
                      ].label
                    : statusArray.findIndex(
                        type => type.value === selectedTag,
                      ) !== -1
                    ? statusArray[
                        statusArray.findIndex(
                          type => type.value === selectedTag,
                        )
                      ].label
                    : patients.findIndex(
                        patient => patient.id === selectedTag,
                      ) !== -1
                    ? getName(patients, selectedTag)
                    : getName(users, selectedTag)}
                </Text>
                <PaperIconButton
                  size={15}
                  icon="close"
                  color="white"
                  onPress={() => deleteTag(selectedTag)}
                />
              </View>
            )}
          />
        </ScrollView>
      </View>
      <View style={[styles.row, styles.paddingHorizontal]}>
        {showSchoolTab ? (
          <TouchableOpacity onPress={() => setCurrentTab('SCHOOL')}>
            <Text
              style={[
                styles.height40,
                styles.marginL,
                styles.justifyCenter,
                currentTab === 'SCHOOL' ? Typography.P3_BOLD : Typography.P3,
                currentTab === 'SCHOOL' ? styles.tabActive : {},
              ]}>
              {'School'}
            </Text>
          </TouchableOpacity>
        ) : (
          <>
            <TouchableOpacity onPress={() => setCurrentTab('STAFF')}>
              <Text
                style={[
                  styles.height40,
                  styles.marginL,
                  styles.justifyCenter,
                  currentTab === 'STAFF' ? Typography.P3_BOLD : Typography.P3,
                  currentTab === 'STAFF' ? styles.tabActive : {},
                ]}>
                {translations('staff')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setCurrentTab('CLIENT')}>
              <Text
                style={[
                  styles.height40,
                  styles.marginL,
                  styles.justifyCenter,
                  currentTab === 'CLIENT' ? Typography.P3_BOLD : Typography.P3,
                  currentTab === 'CLIENT' ? styles.tabActive : {},
                ]}>
                {translations('clients')}
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
      {currentTab === 'STAFF' ? (
        <TimesheetStaff filters={filters} setTimesheetItems={setItems} />
      ) : currentTab === 'CLIENT' ? (
        <TimesheetClients filters={filters} setTimesheetItems={setItems} />
      ) : (
        <TimesheetSchool filters={filters} setTimesheetItems={setItems} />
      )}
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
    instance: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({database, role, profile, authentication}: any) => {
    return {
      patients: !role?.patientAssignedOnly
        ? database
            .get('patients')
            .query(
              Q.where('deleted_at', null),
              Q.where('_partition', authentication.selectedGroup),
            )
        : profile.patients,
      users: database
        .get('users')
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
        )
        .observe(),
    };
  }),
)(Timesheet);
