import React from 'react';
import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import {Provider as PaperProvider} from 'react-native-paper';
import {Provider} from 'react-redux';
import {Theme} from './styles/theme';
import {store} from './redux/store';
import {analytics, AnalyticsProvider} from 'src/providers/segment';
import {BranchProvider} from '@ravenhealth/react-native-branch-provider';
import EStyleSheet from 'react-native-extended-stylesheet';
import {LogBox, StatusBar} from 'react-native';
import {NetworkProvider} from 'react-native-offline';
import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider';
import Application from 'src/application';
import {StyleProvider} from 'src/providers/style';
import {TranslationProvider} from 'src/providers/translation';
import database from 'src/providers/watermelondb';
import {GoogleProvider} from 'src/providers/google/context';
import {PushyProvider} from 'src/providers/pushy';
import {GestureProvider} from 'src/providers/gesture';

LogBox.ignoreLogs([
  'Warning: ...',
  'EventEmitter.removeListener',
  'VirtualizedLists',
]); // Ignore log notification by message

LogBox.ignoreAllLogs(); //Ignore all log notifications

EStyleSheet.build({});

const App = () => {
  return (
    <AnalyticsProvider client={analytics}>
      <BranchProvider>
        <Provider store={store}>
          <DatabaseProvider database={database}>
            <PushyProvider>
              <StyleProvider>
                <GestureProvider>
                  <TranslationProvider>
                    <PaperProvider theme={Theme}>
                      <NetworkProvider>
                        <GoogleProvider>
                          <StatusBar barStyle="dark-content" />
                          <Application />
                        </GoogleProvider>
                      </NetworkProvider>
                    </PaperProvider>
                  </TranslationProvider>
                </GestureProvider>
              </StyleProvider>
            </PushyProvider>
          </DatabaseProvider>
        </Provider>
      </BranchProvider>
    </AnalyticsProvider>
  );
};

export default App;
