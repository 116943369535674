import React from 'react';
import {IconButton} from 'react-native-paper';

export const createClaimBackButton = (navigation, route) => {
  return <ClaimBackButton navigation={navigation} route={route} />;
};

const ClaimBackButton = ({navigation, route}) => {
  const navigateBack = () => {
    const origin = route?.params?.origin;

    if (origin === 'create' || origin === 'claims') {
      navigation.navigate('Billing', {initialTab: 'create'});
    } else if (
      origin === 'submitted' ||
      origin === 'denied' ||
      origin === 'approved'
    ) {
      navigation.navigate('Billing', {initialTab: origin});
    } else {
      navigation.navigate('Billing');
    }
  };

  return <IconButton icon="arrow-left" onPress={navigateBack} />;
};

export default ClaimBackButton;
