import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import Cpt, {cptCodes} from 'src/hook-form-inputs/cpt';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {IconButton} from 'react-native-paper';
import DiagnosisPointer from 'src/hook-form-inputs/diagnosis-pointer';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {PayerPlans} from 'src/models';
import {of} from 'rxjs';
import moment from 'moment/moment';

const ServiceLineForm = compose(
  withDatabase,
  withObservables(['planId'], ({database, planId}: any) => ({
    plan: planId
      ? database.get(PayerPlans.table).findAndObserve(planId)
      : of({
          feeSchedule: [],
        }),
  })),
)(({name, plan}: any) => {
  const styles = useStyle();

  const {control} = useFormContext();

  const {
    fields: servicesFields,
    append: servicesAppend,
    remove: servicesRemove,
  } = useFieldArray({
    name: (name ? name + '.' : '') + 'services',
    control,
  });

  const serviceLines = useWatch({
    control,
    name: `${(name ? name + '.' : '') + 'services'}`,
  });
  const session = useWatch({
    control,
    name: `${name ? name + '.' : ''}`,
  });

  const durationInMins = moment(session.endDate).diff(
    session.startDate,
    'minutes',
  );

  const units = Math.round(durationInMins / 15);

  const serviceLineCurrency = serviceLines.reduce(
    (previousValue: number, currentValue: any) => {
      return (
        previousValue +
        (isNaN(parseFloat(currentValue.amount))
          ? 0
          : parseFloat(currentValue.amount))
      );
    },
    0,
  );

  const formattedNumber = serviceLineCurrency.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <View style={[styles.column]}>
      <View
        style={[
          styles.row,
          styles.marginLTop,
          styles.paddingLVertical,
          styles.backgroundColorPrimary50,
          styles.borderRadius,
        ]}>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Billing Code (Box 24D)
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Service Description
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Modifier 1 (Box 24D)
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Modifier 2 (Box 24D)
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Modifier 3 (Box 24D)
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Modifier 4 (Box 24D)
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Diagnosis Pointer (Box 24E)
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Units (Box 24G)
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Amount (Box 24F)
          </Text>
        </View>
        <View style={[styles.width42]} />
      </View>
      {servicesFields.map((item: any, index: number) => {
        return (
          <ServiceLineFormItem
            key={item.id}
            item={item}
            index={index}
            name={name}
            servicesRemove={servicesRemove}
            plan={plan}
          />
        );
      })}
      <View
        style={[styles.row, styles.justifySpaceBetween, styles.alignCenter]}>
        <View style={[styles.flex, styles.alignCenter]}>
          <RHButton
            icon="plus-circle-outline"
            mode="outlined"
            color={Colors.RAVEN_WHITE}
            textColor={Colors.RAVEN_BLACK}
            onPress={() => {
              servicesAppend({
                cpt: '',
                description: '',
                modifier1: '',
                modifier2: '',
                modifier3: '',
                modifier4: '',
                diagnosisPointer: '1',
                units: '1',
                amount: '',
              });
            }}>
            ADD MORE
          </RHButton>
        </View>
        <View style={[styles.flex, styles.alignCenter]} />
        <View style={[styles.flex, styles.alignCenter]} />
        <View style={[styles.flex, styles.alignCenter]} />
        <View style={[styles.flex, styles.alignCenter]} />
        <View style={[styles.flex, styles.alignCenter]} />
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            Total:
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            {serviceLines.reduce((previousValue: number, currentValue: any) => {
              return (
                previousValue +
                (isNaN(parseInt(currentValue.units, 10))
                  ? 0
                  : parseInt(currentValue.units, 10))
              );
            }, 0)}
            /{units}
          </Text>
        </View>
        <View style={[styles.flex, styles.alignCenter]}>
          <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
            {formattedNumber}
          </Text>
        </View>
        <View style={[styles.width42]} />
      </View>
    </View>
  );
});

const ServiceLineFormItem = ({
  item,
  index,
  name,
  servicesRemove,
  plan,
}: any) => {
  const styles = useStyle();

  const {control, setValue} = useFormContext();

  const watchedDiagnoses = useWatch({
    control,
    name: 'diagnoses',
  });

  const watchedCpt = useWatch({
    control,
    name: `${(name ? name + '.' : '') + `services.${index}`}.cpt`,
  });

  const watchedModifier1 = useWatch({
    control,
    name: `${(name ? name + '.' : '') + `services.${index}`}.modifier1`,
  });

  const watchedModifier2 = useWatch({
    control,
    name: `${(name ? name + '.' : '') + `services.${index}`}.modifier2`,
  });

  const watchedModifier3 = useWatch({
    control,
    name: `${(name ? name + '.' : '') + `services.${index}`}.modifier3`,
  });

  const watchedModifier4 = useWatch({
    control,
    name: `${(name ? name + '.' : '') + `services.${index}`}.modifier4`,
  });

  const watchedUnits = useWatch({
    control,
    name: `${(name ? name + '.' : '') + `services.${index}`}.units`,
  });

  useEffect(() => {
    if (item.cpt === watchedCpt) {
      return;
    }
    if (watchedCpt) {
      setValue(
        `${(name ? name + '.' : '') + `services.${index}`}.description`,
        cptCodes.find(cpt => cpt.value === watchedCpt)?.label,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedCpt]);

  useEffect(() => {
    const serviceLine = plan.feeSchedule.find(
      (line: any) =>
        line.cpt === watchedCpt &&
        line.modifier1 === watchedModifier1 &&
        line.modifier2 === watchedModifier2 &&
        line.modifier3 === watchedModifier3 &&
        line.modifier4 === watchedModifier4,
    );

    if (serviceLine?.billingRate) {
      // setValue(
      //   `${(name ? name + '.' : '') + `services.${index}`}.description`,
      //   serviceLine.description,
      // );
      setValue(
        `${(name ? name + '.' : '') + `services.${index}`}.amount`,
        (
          parseFloat(serviceLine?.billingRate || '0') *
          parseInt(watchedUnits, 10)
        ).toFixed(2),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    plan,
    watchedCpt,
    watchedModifier1,
    watchedModifier2,
    watchedModifier3,
    watchedModifier4,
    watchedUnits,
  ]);

  return (
    <View key={item.id} style={[styles.row, styles.marginSMVertical]}>
      <View style={[styles.flex]}>
        <Cpt
          name={`${(name ? name + '.' : '') + `services.${index}`}`}
          label={'Billing Code (Box 24D)'}
          showHelper={false}
          valueKey={'value'}
        />
      </View>
      <View style={[styles.flex]}>
        <HookFormInput
          name={`${(name ? name + '.' : '') + `services.${index}`}.description`}
          label={'Service Description'}
          showHelper={false}
        />
      </View>
      <View style={[styles.flex]}>
        <HookFormInput
          name={`${(name ? name + '.' : '') + `services.${index}`}.modifier1`}
          label={'Mod #1 (Box 24D)'}
          showHelper={false}
        />
      </View>
      <View style={[styles.flex]}>
        <HookFormInput
          name={`${(name ? name + '.' : '') + `services.${index}`}.modifier2`}
          label={'Mod #2 (Box 24D)'}
          showHelper={false}
        />
      </View>
      <View style={[styles.flex]}>
        <HookFormInput
          name={`${(name ? name + '.' : '') + `services.${index}`}.modifier3`}
          label={'Mod #3 (Box 24D)'}
          showHelper={false}
        />
      </View>
      <View style={[styles.flex]}>
        <HookFormInput
          name={`${(name ? name + '.' : '') + `services.${index}`}.modifier4`}
          label={'Mod #4 (Box 24D)'}
          showHelper={false}
        />
      </View>
      <View style={[styles.flex]}>
        <DiagnosisPointer
          name={`${(name ? name + '.' : '') + `services.${index}`}`}
          label={'Diagnosis Pointer (Box 24E)'}
          diagnoses={watchedDiagnoses}
        />
      </View>
      <View style={[styles.flex]}>
        <HookFormInput
          name={`${(name ? name + '.' : '') + `services.${index}`}.units`}
          label={'Units (Box 24G)'}
          showHelper={false}
        />
      </View>
      <View style={[styles.flex]}>
        <HookFormInput
          name={`${(name ? name + '.' : '') + `services.${index}`}.amount`}
          label={'Amount (Box 24F)'}
          showHelper={false}
        />
      </View>
      <View style={[styles.column, styles.justifyCenter]}>
        <IconButton
          color={Colors.RAVEN_BLACK}
          icon="trash-can"
          onPress={() => servicesRemove(index)}
        />
      </View>
    </View>
  );
};

export default ServiceLineForm;
