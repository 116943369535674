import React from 'react';
import FormInput from 'src/hook-form/form-input';
import {Controller, useFormContext} from 'react-hook-form';

interface Props {
  rules?: any;
  name: string;
  label: string;
  placeholder?: string;
  keyboardType?: string;
  required?: boolean;
  defaultValue?: string;
  selectTextOnFocus?: boolean;
  numberOfLines?: number;
  helper?: string;
  showHelper?: boolean;
  render?: (props: any) => any;
  disabled?: boolean;
  right?: any;
  onSubmit?: any;
  onBlur?: any;
}

const HookFormInput = (props: Props) => {
  const {control} = useFormContext();
  return (
    <Controller
      control={control}
      rules={props.rules}
      render={({field, fieldState: {error}}) => (
        <FormInput
          formRef={field.ref}
          {...props}
          {...field}
          error={error}
          onSubmit={props.onSubmit}
          onBlur={props.onBlur}
        />
      )}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
};

export default HookFormInput;
