import {TouchableRipple} from 'react-native-paper';
import {Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import React from 'react';
import {Typography} from 'src/styles';
import SVG from 'src/styles/svg';
import {useStyle} from 'src/providers/style';

interface Props {
  onPress: () => void;
  title: string;
  icon: string;
  svg: boolean;
  color?: string;
}

const MenuItem = ({
  onPress = () => console.log('Menu Item Pressed'),
  title = '',
  icon = 'calendar',
  svg = false,
  color = '',
}: Props) => {
  const styles = useStyle();

  return (
    <TouchableRipple onPress={onPress}>
      <View
        style={[
          styles.row,
          styles.alignCenter,
          styles.justifyStart,
          styles.paddingL,
        ]}>
        {svg ? (
          <SVG width={24} height={24} name={icon} fill={''} />
        ) : (
          <Icon size={20} name={icon} color={color} />
        )}
        <Text style={[styles.paddingLLeft, Typography.P3, {color}]}>
          {title}
        </Text>
      </View>
    </TouchableRipple>
  );
};

export default MenuItem;
