import React from 'react';
import {DataItem, SelectInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {IDeveloperField} from 'dromo-uploader-react';
import {Typography} from 'src/styles';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import withState from 'src/redux/wrapper';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey?: string;
  patients: any[];
}

export const Import = (
  key: string = 'patient',
  patients: any[] = [],
): IDeveloperField => {
  let values = patients.map((patient: any) => {
    return {
      label: `${patient.firstName} ${patient.lastName}`,
      value: patient.id,
    };
  });

  return {
    label: 'Client',
    key,
    type: 'select',
    selectOptions: values,
    validators: [
      {
        validate: 'required',
      },
    ],
  };
};

const resolveDisplayValue = (value: string) => {
  return value;
};

const PatientDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Client</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const PatientInput = compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({profile, database, authentication, role}: any) => ({
    patients: !role?.patientAssignedOnly
      ? database
          .get('patients')
          .query(
            Q.where('deleted_at', null),
            Q.where('state', 'active'),
            Q.where('_partition', authentication.selectedGroup),
          )
      : profile.patients,
  })),
)(({valueKey, patients}: Props) => {
  const items = (patients || []).map((patient: any) => {
    return {
      label: `${patient.firstName} ${patient.lastName}`,
      value: patient.id,
    };
  });

  return (
    <SelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'patient'}
      name={'Client'}
      required={true}
      items={items}
    />
  );
});

export const validation = () => Yup.string().required('Client is required');

export const Display = PatientDisplay;
export const Input = PatientInput;
export const Value = resolveDisplayValue;

export default PatientInput;
