import React from 'react';
import {View} from 'react-native';
import {Typography} from 'src/styles';
import {Text} from 'react-native';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {AppointmentLocation, AppointmentType} from 'src/form-inputs';
import {Value as DateValue} from 'src/form-inputs/date-input';
import moment from 'moment';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

const SessionNoteTemplatePreview = ({
  template,
  staff,
  appointment,
  session,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const macroMap = {
    '@session.type':
      AppointmentType.Value(appointment?.type)?.split(' - ')?.[1] || '',
    '@session.code':
      AppointmentType.Value(appointment?.type)?.split(' - ')?.[0] || '',
    '@appointment.location': AppointmentLocation.Value(appointment?.location),
    '@appointment.date': DateValue(session?.date),
    '@appointment.startTime24hr': moment(appointment?.start).format('HH:mm'),
    '@appointment.startTime': moment(appointment?.start).format('hh:mm A'),
    '@appointment.endTime24hr': moment(appointment?.end).format('HH:mm'),
    '@appointment.endTime': moment(appointment?.end).format('hh:mm A'),
    '@staffmember.name': staff.map(
      (participant: any) => `${participant.firstName} ${participant.lastName}`,
    ),
  };

  const regex = /@\w+\.\w+\b/g;
  const substrings = template.template?.split(new RegExp(`(${regex.source})`));
  const formattedTemplate = substrings?.map(
    (substring: string, index: number) => {
      if (regex.test(substring)) {
        return (
          <Text key={index} style={[]}>
            {macroMap[substring] || ''}
          </Text>
        );
      } else {
        return substring;
      }
    },
  );

  return template?.template ? (
    <View>
      <Text style={[Typography.H6, styles.paddingMBottom]}>
        {translations('preview')}
      </Text>
      <Text style={[Typography.P3]} numberOfLines={5} ellipsizeMode="tail">
        {formattedTemplate}
      </Text>
    </View>
  ) : (
    <></>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({session}: any) => ({
    appointment: session.appointment,
  })),
  withObservables(
    ['templateId'],
    ({appointment, templateId, database}: any) => ({
      staff: appointment.staff,
      template: templateId
        ? database.get('note_template_versions').findAndObserve(templateId)
        : of({}),
    }),
  ),
)(SessionNoteTemplatePreview);
