import React from 'react';
// import Pushy from 'pushy-sdk-web';

export const PushyProvider = ({children}: {children: any}) => {
  // Pushy.register({appId: '63b869b27446e48a2a0e8cc1'})
  //   .then(function (deviceToken: string) {
  //     // Print device token to console
  //     console.log('Pushy device token: ' + deviceToken);
  //
  //     Pushy.setNotificationListener(function (data) {
  //       // Print notification payload data
  //       console.log('Received notification: ' + JSON.stringify(data));
  //
  //       // Attempt to extract the "message" property from the payload: {"message":"Hello World!"}
  //       let message = data.message || 'Test notification';
  //
  //       // Display an alert with message sent from server
  //       console.log(message);
  //     });
  //   })
  //   .catch(function (err) {
  //     // Handle registration errors
  //     console.error(err);
  //   });
  return <>{children}</>;
};
