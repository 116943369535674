import {text, relation, date, field} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class EnvironmentalFactor extends Base {
  static table = 'environmental_factors';
  static associations = {
    patients: {type: 'belongs_to', key: 'patient_id'},
  };

  @relation('patients', 'patient_id') patient;

  @text('type') type;
  @date('date') date;
  @field('title') title;
  @field('description') description;
}
