import React, {
  createContext,
  useContext,
  // useState
} from 'react';
import en from 'src/translations/en';
// import es from 'src/translations/es';

const context = createContext<any>({});

export default context;

export const Provider = context.Provider;
export const Consumer = context.Consumer;

export const useTranslations = () => useContext(context);

export const TranslationProvider = ({children}: {children: any}) => {
  // const locale = 'en';
  // const [locale, setCurrentLocale] = useState('en');
  // const locales = {
  //   en: en,
  //   es: es,
  // };
  const translations = (key: string) => {
    return en.hasOwnProperty(key) ? en[key] : '';
    // return locales[locale].hasOwnProperty(key) ? locales[locale][key] : '';
  };

  return <Provider value={translations}>{children}</Provider>;
};
