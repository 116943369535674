import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import Collapsible from 'react-native-collapsible';
import {IconButton} from 'react-native-paper';
import {compose} from 'recompose';
import moment from 'moment';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import SessionRecap from 'src/modules/session/components/session-recap';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import {useNavigation} from '@react-navigation/native';
import Location from 'src/modules/scheduling/components/location';
import {SessionMessage} from 'src/modules/session/components';
import calculateSessionStatus from 'src/common-utils/calculate-session-status';

const HistorySessionItem = ({
  session,
  appointment,
  canEdit,
  canDelete,
  deleteCallback,
  profile,
}: any) => {
  const styles = useStyle();
  const navigation = useNavigation();
  const [collapsed, setCollapsed] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const formattedDate = moment(
    session?.sessionDate ? session?.sessionDate : appointment?.date,
  ).format('ll');
  const startTime =
    session?.editedStartTimestamp ||
    session?.startTimestamp ||
    appointment?.start;
  const endTime =
    session?.editedEndTimestamp || session?.endTimestamp || appointment?.end;

  const formattedStartDate = moment(startTime).format('h:mmA');
  const formattedEndDate = moment(endTime).format('h:mmA');

  const viewCallback = async (item: any) => {
    const status = calculateSessionStatus(item);
    if (status === 'not-started' || status === 'in-progress') {
      navigation.navigate('SessionCollect', {
        id: item.id,
      });
    } else if (status === 'missing-values') {
      navigation.navigate('SessionMessage', {
        id: item.id,
      });
    } else if (status === 'completed') {
      navigation.navigate('SessionRecap', {
        id: item.id,
      });
    }
  };

  return (
    <View style={[styles.card]}>
      <TouchableOpacity
        style={[styles.flex, styles.justifyCenter]}
        onPress={() => setCollapsed(!collapsed)}>
        <View
          style={[
            styles.row,
            styles.justifySpaceBetween,
            styles.marginHorizontal,
          ]}>
          <View style={[styles.row, styles.alignCenter]}>
            <Text style={[Typography.P2_MEDIUM, styles.textColorPrimary700]}>
              {`${formattedDate} | `}
            </Text>
            <Text style={[Typography.P2, styles.textColorPrimary700]}>
              {formattedStartDate} - {formattedEndDate}
            </Text>
          </View>

          <View style={[styles.row, styles.alignCenter]}>
            <Location value={session?.location || appointment?.location} />
            <IconButton icon={'eye'} onPress={() => viewCallback(session)} />
            {canEdit ? (
              editMode ? (
                <IconButton
                  color={Colors.TERTIARY_TEAL}
                  icon={'content-save'}
                  onPress={() => setEditMode(false)}
                />
              ) : (
                <IconButton
                  color={Colors.RAVEN_BLACK}
                  icon={'pencil'}
                  onPress={() => {
                    setEditMode(true);
                    setCollapsed(false);
                  }}
                />
              )
            ) : null}
            {canDelete ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon={'trash-can'}
                onPress={() => deleteCallback(session)}
              />
            ) : null}
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={!collapsed ? 'chevron-up' : 'chevron-down'}
            />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible
        collapsed={collapsed}
        renderChildrenCollapsed={true}
        style={[]}>
        <View style={[styles.paddingHorizontal]}>
          {editMode ? (
            <SessionMessage
              session={session}
              showSubmitButton={false}
              fromRecap={true}
            />
          ) : (
            <SessionRecap
              session={session}
              sessionHistory={true}
              canEdit={canEdit}
              profile={profile}
              isSupervision={session?.type === 'supervision'}
            />
          )}
        </View>
      </Collapsible>
    </View>
  );
};

export default compose(
  withState,
  withDatabase,
  withObservables([], ({session}: any) => {
    return {
      appointment: session.appointment,
    };
  }),
)(HistorySessionItem);
