import React from 'react';
import {StyleSheet, View} from 'react-native';
import {DataTable} from 'react-native-paper';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import _ from 'lodash';
import {Colors, Typography} from 'src/styles';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';
import FrequencyTableRow from 'src/modules/session/components/frequency/frequency-table-row';

const tableHeaders = [
  'Set',
  'Collector',
  '# of Instances',
  'Antecedents',
  'Consequences',
  'Mild',
  'Moderate',
  'Severe',
];

const FrequencyTable = ({events, program, sets}: any) => {
  const eventsBySetId = _.groupBy(events, 'set.id');

  return (
    <>
      <View style={[styles.alignSelf]}>
        <InformationTooltip value="Frequency tables are not inclusive of 0 events" />
      </View>
      <DataTable style={[styles.table]}>
        <DataTable.Header style={[styles.head, styles.ph_0, styles.firstRow]}>
          {tableHeaders.map((header: any, index: number) => (
            <DataTable.Title
              style={[
                styles.justifyCenter,
                index !== tableHeaders.length - 1 ? styles.borderRight : {},
              ]}
              key={`frequency-header-key-${index}`}
              textStyle={styles.titleTxt}>
              {header}
            </DataTable.Title>
          ))}
        </DataTable.Header>
        {sets?.map((set: any, index: any) => (
          <FrequencyTableRow
            key={set?.id}
            set={set}
            title={`Set ${index + 1}`}
            events={eventsBySetId[set?.id] || []}
            showIntensity={program?.intensity}
          />
        ))}
      </DataTable>
    </>
  );
};

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
  },
  table: {
    borderWidth: 1,
    borderColor: Colors.PRIMARY_300_SESSION_PLAN,
    borderRadius: 10,
  },
  head: {
    height: 50,
    backgroundColor: Colors.PRIMARY_50,
  },
  firstRow: {
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  lastRow: {
    borderBottomStartRadius: 10,
    borderBottomEndRadius: 10,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignSelf: {
    alignSelf: 'flex-start',
  },
  titleTxt: {
    ...Typography.LABEL_MEDIUM,
    color: Colors.PRIMARY_900,
    alignSelf: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
  },
  cellBoldTxt: {
    ...Typography.CAPTION_MEDIUM,
    color: Colors.PRIMARY_900,
  },
  cellTxt: {
    ...Typography.CAPTION,
    color: Colors.PRIMARY_900,
  },
  spaceTop: {
    marginTop: 5,
  },
  borderRight: {
    borderRightWidth: 1,
    borderColor: Colors.PRIMARY_300_SESSION_PLAN,
  },
  flex: {
    flex: 1,
  },
  cornerTab: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 8,
    borderTopWidth: 8,
    borderRightColor: 'transparent',
    borderTopColor: '#B5B5B8',
    transform: [{rotate: '90deg'}],
    position: 'absolute',
    top: 0,
    right: 0,
  },
  ph_0: {
    paddingHorizontal: 0,
  },
});

export default compose(
  withDatabase,
  withObservables(['sets'], ({database, sets}: any) => {
    return {
      events: database
        .get('events')
        .query(
          Q.where('set_id', Q.oneOf(sets.map((set: any) => set.id))),
          Q.where('deleted_at', null),
          Q.sortBy('created_at', Q.asc),
        ),
    };
  }),
)(FrequencyTable);
