import React from 'react';
import {DataItem, ToggleButtonInput, SelectInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey?: string;
}

export const appointmentLocations = [
  {
    label: '12 - Home',
    value: '12',
    color: Colors.TEAL_500,
    chipColor: Colors.TEAL_300,
    textColor: Colors.TEAL_700,
    icon: 'home',
  },
  {
    label: '11 - Office',
    value: '11',
    color: Colors.LIME_500,
    chipColor: '#EAFDC1',
    textColor: '#437700',
    icon: 'office-building',
  },
  {
    label: '02 - Telehealth',
    value: '02',
    color: Colors.RUBY_500,
    chipColor: '#F7B8C7',
    textColor: '#A31034',
    icon: 'video',
  },
  {
    label: '10 - Telehealth In Home',
    value: '10',
    color: Colors.RUBY_500,
    chipColor: '#F7B8C7',
    textColor: '#A31034',
    icon: 'video',
  },
  {
    label: '03 - School',
    value: '03',
    color: '#4C9EFF',
    chipColor: Colors.ARCTIC_300,
    textColor: Colors.ARCTIC_700,
    icon: 'school',
  },
  {
    label: '99 - Other',
    value: '99',
    color: Colors.GRAY_900,
    chipColor: Colors.GRAY_900,
    textColor: Colors.GRAY_300,
    icon: 'google-circles-communities',
  },
  {
    label: '01 - Pharmacy',
    value: '01',
  },
  {
    label: '04 - Homeless Shelter',
    value: '04',
  },
  {
    label: '05 - Indian Health Service Free-standing Facility',
    value: '05',
  },
  {
    label: '06 - Indian Health Service Provider-based Facility',
    value: '06',
  },
  {
    label: '07 - Tribal 638 Free-standing Facility',
    value: '07',
  },
  {
    label: '08 - Tribal 638 Provider-based Facility',
    value: '08',
  },
  {
    label: '09 - Prison/Correctional Facility',
    value: '09',
  },
  {
    label: '13 - Assisted Living Facility',
    value: '13',
  },
  {
    label: '14 - Group Home',
    value: '14',
  },
  {
    label: '15 - Mobile Unit',
    value: '15',
  },
  {
    label: '16 - Temporary Lodging',
    value: '16',
  },
  {
    label: '17 - Walk-in Retail Health Clinic',
    value: '17',
  },
  {
    label: '18 - Place of Employment-Worksite',
    value: '18',
  },
  {
    label: '19 - Off Campus-Outpatient Hospital',
    value: '19',
  },
  {
    label: '20 - Urgent Care Facility',
    value: '20',
  },
  {
    label: '21 - Inpatient Hospital',
    value: '21',
  },
  {
    label: '22 - On Campus-Outpatient Hospital',
    value: '22',
  },
  {
    label: '23 - Emergency Room – Hospital',
    value: '23',
  },
  {
    label: '24 - Ambulatory Surgical Center',
    value: '24',
  },
  {
    label: '25 - Birthing Center',
    value: '25',
  },
  {
    label: '26 - Military Treatment Facility',
    value: '26',
  },
  {
    label: '27 - Outreach Site/ Street',
    value: '27',
  },
  {
    label: '31 - Skilled Nursing Facility',
    value: '31',
  },
  {
    label: '32 - Nursing Facility',
    value: '32',
  },
  {
    label: '33 - Custodial Care Facility',
    value: '33',
  },
  {
    label: '34 - Hospice',
    value: '34',
  },
  {
    label: '41 - Ambulance – Land',
    value: '41',
  },
  {
    label: '42 - Ambulance – Air or Water',
    value: '42',
  },
  {
    label: '49 - Independent Clinic',
    value: '49',
  },
  {
    label: '50 - Federally Qualified Health Center',
    value: '50',
  },
  {
    label: '51 - Inpatient Psychiatric Facility',
    value: '51',
  },
  {
    label: '52 - Psychiatric Facility – Partial Hospitalization',
    value: '52',
  },
  {
    label: '53 - Community Mental Health Center',
    value: '53',
  },
  {
    label:
      '54 - Intermediate Care Facility/Individuals with Intellectual Disabilities',
    value: '54',
  },
  {
    label: '55 - Residential Substance Abuse Treatment Facility',
    value: '55',
  },
  {
    label: '56 - Psychiatric Residential Treatment Center',
    value: '56',
  },
  {
    label: '57 - Non-residential Substance Abuse Treatment Facility',
    value: '57',
  },
  {
    label: '58 - Non-residential Opioid Treatment Facility',
    value: '58',
  },
  {
    label: '60 - Mass Immunization Center',
    value: '60',
  },
  {
    label: '61 - Comprehensive Inpatient Rehabilitation Facility',
    value: '61',
  },
  {
    label: '62 - Comprehensive Outpatient Rehabilitation Facility',
    value: '62',
  },
  {
    label: '65 - End-Stage Renal Disease Treatment Facility',
    value: '65',
  },
  {
    label: '71 - Public Health Clinic',
    value: '71',
  },
  {
    label: '72 - Rural Health Clinic',
    value: '72',
  },
  {
    label: '81 - Independent Laboratory',
    value: '81',
  },
];

export const Import = (
  key: string = 'appointmentLocation',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Appointment Location (${appointmentLocations
      .map(value => value.label)
      .join(', ')})`,
  key,
  type: 'select',
  selectOptions: appointmentLocations,
  validators: [
    {
      validate: 'required',
    },
  ],
});

const locationFilters = [
  {
    label: 'All',
    value: 'ALL',
  },
  ...appointmentLocations,
];

const resolveDisplayValue = (value: string) => {
  return appointmentLocations.find(item => item.value === value)?.label;
};
const LocationDisplay = ({value}: DisplayProps) => {
  const translations = useTranslations();
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('place_of_service')}</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const LocationInput = ({valueKey}: Props) => {
  return (
    <SelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'location'}
      name={'Location'}
      required={true}
      items={appointmentLocations}
    />
  );
};

const LocationFilter = ({valueKey}: Props) => {
  return (
    <ToggleButtonInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'location'}
      name={'Location'}
      required={true}
      buttons={locationFilters}
    />
  );
};

export const validation = () => Yup.string().required('Location is required');

export const Display = LocationDisplay;
export const Input = LocationInput;
export const Filter = LocationFilter;
export const Value = resolveDisplayValue;

export default LocationInput;
