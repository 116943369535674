import LinearGradient from 'react-native-linear-gradient';
import {Colors, Typography} from 'src/styles';
import {Text, View} from 'react-native';
import moment from 'moment/moment';
import React from 'react';
import {useStyle} from 'src/providers/style';

const InProgressCalendarItem = ({
  title,
  type,
  start,
  end,
  apptDuration,
  staffColor,
  textColor,
  flagColor,
}: any) => {
  const styles = useStyle();

  return (
    <LinearGradient
      start={{x: 0.0, y: 0.0}}
      end={{x: 1.0, y: 1.0}}
      colors={Array(5)
        .fill([
          type !== 'dayGridMonth'
            ? staffColor || Colors.PRIMARY_200
            : Colors.RAVEN_WHITE,
          (type !== 'dayGridMonth'
            ? staffColor || Colors.PRIMARY_200
            : Colors.RAVEN_WHITE) === '#25262F'
            ? '#808080'
            : Colors.RAVEN_WHITE,
        ])
        .flat()}
      style={[
        ...(type !== 'dayGridMonth'
          ? [
              styles.row,
              styles.positionAbsolute,
              styles.top0,
              styles.left0,
              styles.right0,
              styles.bottom0,
            ]
          : [styles.row, styles.flex, styles.paddingLRight]),
        styles.height,
      ]}>
      <View
        style={[
          ...(type !== 'dayGridMonth'
            ? [
                styles.borderBottomLeftRadius,
                styles.borderTopLeftRadius,
                styles.marginSMRight,
                {borderLeftWidth: 4, right: 3},
              ]
            : [
                styles.alignSelfCenter,
                styles.dotL,
                styles.marginLLeft,
                styles.marginSMRight,
              ]),
          {backgroundColor: flagColor, borderLeftColor: flagColor},
        ]}
      />
      <View
        style={[
          styles.flex,
          styles.alignStart,
          styles.justifyCenter,
          styles.textOverflowClip,
        ]}>
        <Text
          numberOfLines={apptDuration >= 45 ? 2 : 1}
          style={[
            Typography.P3_MEDIUM,
            {
              color:
                type !== 'dayGridMonth'
                  ? textColor || Colors.PRIMARY_600
                  : textColor === Colors.RAVEN_WHITE
                  ? Colors.RAVEN_BLACK
                  : textColor,
            },
          ]}>
          {title} {moment(start).format('LT')} - {moment(end).format('LT')}
        </Text>
      </View>
    </LinearGradient>
  );
};

export default InProgressCalendarItem;
