import React from 'react';
import {Text, View} from 'react-native';
import {Typography, Colors} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {DataItem} from 'src/design-system';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {getAge} from 'src/common-utils/age';
import {getAgeInYearsMonths} from 'src/common-utils/ageInYearsMonths';
import moment from 'moment';
import {credentialsList} from 'src/hook-form-inputs/credential';
import NpiDisplay from 'src/modules/organization/components/npi-display';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import {of, mergeMap} from 'rxjs';
import withState from 'src/redux/wrapper';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';

const ClientInfoDisplay = ({
  currentNoteTemplate,
  patient,
  diagnoses,
  careTeam,
  organization,
  insurances,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const firstName = patient?.firstName;
  const middleName = patient?.middleName;
  const lastName = patient?.lastName;
  const gender = patient?.gender;
  const firstInitial = firstName?.length > 0 ? firstName[0].toUpperCase() : 'N';
  const lastInitial = lastName?.length > 0 ? lastName[0].toUpperCase() : 'N';
  const initials = firstInitial + lastInitial;

  return (
    <>
      <Text style={[Typography.H5]}>{translations('client_information')}</Text>
      <View
        style={[
          styles.marginLVertical,
          styles.cardFlat,
          styles.padding,
          styles.borderRadius,
        ]}>
        <View style={[styles.row, styles.alignCenter]}>
          <RHAvatar
            label={initials}
            color={Colors.RAVEN_BLACK}
            textColor={Colors.RAVEN_WHITE}
            size={32}
          />
          <View style={[styles.paddingLLeft, styles.justifyCenter]}>
            <View style={[styles.row]}>
              {currentNoteTemplate.clientFirstName ? (
                <Text
                  style={[
                    Typography.P3,
                    styles.textColorPrimary,
                  ]}>{`${firstName}${
                  currentNoteTemplate.clientMiddleName ? ' ' + middleName : ''
                }${
                  currentNoteTemplate.clientLastName ? ' ' + lastName : ''
                }`}</Text>
              ) : null}
            </View>
            <View style={[styles.row]}>
              {currentNoteTemplate.clientBirthDate ? (
                <Text style={[Typography.P3, styles.textColorSecondary]}>
                  {currentNoteTemplate.clientAgeInMonths
                    ? getAgeInYearsMonths(patient?.birthDate)
                    : getAge(patient?.birthDate)}
                </Text>
              ) : null}
              {currentNoteTemplate.clientGender ? (
                <Text style={[Typography.P3, styles.textColorSecondary]}>
                  {`, ${gender}`}
                </Text>
              ) : null}
            </View>
          </View>
        </View>
        <View style={[styles.row, styles.width]}>
          {currentNoteTemplate.clientAssignedStaff ? (
            <View
              style={[
                styles.flex,
                styles.column,
                styles.marginRightAuto,
                styles.paddingLVertical,
              ]}>
              {careTeam.map((assignedStaffMember: any, index: number) => (
                <View key={`care-team-${index}`}>
                  <Text style={[Typography.H6]}>
                    Assigned Staff
                    {` ${index + 1}`}
                  </Text>
                  <DataItem>
                    <View
                      key={`assignedStaffMember-${assignedStaffMember?.id}`}>
                      <Text style={[Typography.P3]}>
                        {assignedStaffMember.firstName}{' '}
                        {assignedStaffMember.lastName}
                        {assignedStaffMember.credentials
                          ? ', ' +
                            assignedStaffMember.credentials
                              .map(
                                val =>
                                  credentialsList.find(
                                    item => item.value === val,
                                  )?.value,
                              )
                              .join(', ')
                          : ''}
                      </Text>
                      {currentNoteTemplate.staffLicenseNumber ? (
                        <Text style={[Typography.P3]}>
                          License #:
                          {assignedStaffMember.licenseNumber
                            ? assignedStaffMember.licenseNumber
                            : ''}
                        </Text>
                      ) : (
                        <></>
                      )}
                      {currentNoteTemplate.staffNpi ? (
                        <NpiDisplay user={assignedStaffMember} />
                      ) : (
                        <></>
                      )}
                    </View>
                  </DataItem>
                </View>
              ))}
            </View>
          ) : null}

          {currentNoteTemplate.clientBirthDate ? (
            <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('birth_date')}
                </Text>
                <Text style={[Typography.P3]}>
                  {moment(patient?.birthDate).format('MM/DD/YYYY')}
                </Text>
              </DataItem>
            </View>
          ) : null}

          {currentNoteTemplate.clientSsn ? (
            <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
              <DataItem>
                <Text style={[Typography.H6]}>{translations('ssn')}</Text>
                <Text style={[Typography.P3]}>{patient?.ssn}</Text>
              </DataItem>
            </View>
          ) : null}

          {currentNoteTemplate?.clientDiagnoses ? (
            <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
              <DataItem>
                <Text style={[Typography.H6]}>Diagnoses</Text>
                <Text style={[Typography.P3, styles.flexWrap]}>
                  {diagnoses.length > 0
                    ? diagnoses.map(diagnosis => diagnosis.diagnosis).join(', ')
                    : 'Undiagnosed'}
                </Text>
              </DataItem>
            </View>
          ) : null}
          {organization?.billing && currentNoteTemplate?.primaryMemberId ? (
            <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
              <DataItem>
                <Text style={[Typography.H6]}>Primary Member ID</Text>
                <Text style={[Typography.P3, styles.flexWrap]}>
                  {insurances[0]?.memberId || ''}
                </Text>
              </DataItem>
            </View>
          ) : (
            <></>
          )}

          {organization?.billing && currentNoteTemplate?.secondaryMemberId ? (
            <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
              <DataItem>
                <Text style={[Typography.H6]}>Secondary Member ID</Text>
                <Text style={[Typography.P3, styles.flexWrap]}>
                  {insurances[1]?.memberId || ''}
                </Text>
              </DataItem>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    organization: database
      ?.get('instances')
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
  withObservables([], ({patient}: any) => ({
    insurances: patient.id ? patient.activeInsurances : of([]),
  })),
)(ClientInfoDisplay);
