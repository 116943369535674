import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  items?: any;
  type?: string;
}

const items = [
  {value: 'Sunday', label: 'Sunday'},
  {value: 'Monday', label: 'Monday'},
  {value: 'Tuesday', label: 'Tuesday'},
  {value: 'Wednesday', label: 'Wednesday'},
  {value: 'Thursday', label: 'Thursday'},
  {value: 'Friday', label: 'Friday'},
  {value: 'Saturday', label: 'Saturday'},
];

export const Import = (
  key: string = 'masterySessions',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Consecutive Sessions',
  key,
  validators: [
    // {validate: 'require_with_values', fieldValues: {method: 'trial_by_trial'}},
  ],
});

export const Value = (value: string) => {
  return value;
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('day_of_week')}</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, type}: Props) => {
  const translations = useTranslations();
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + type}
      label={translations('day_of_week')}
      placeholder={translations('day_of_week')}
      helper={''}
      required={false}
      items={items}
    />
  );
};

export const validation = () =>
  Yup.string().required('Day of Week is required');

export default Input;
