import AsyncStorage from '@react-native-async-storage/async-storage';

export const toDataURL = async (url: string, callback: any) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${await AsyncStorage.getItem('accessToken')}`,
    },
  });
  const blob = await response.blob();

  const reader = new FileReader();
  reader.onloadend = () => callback(reader.result);
  reader.onerror = () => console.log('error');
  reader.readAsDataURL(blob);
};
