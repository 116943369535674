import React from 'react';
import {View, ScrollView} from 'react-native';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import SessionSummaryDataTable from 'src/modules/session/components/summary-data-table';
import {useStyle} from 'src/providers/style';
import SessionNoteTemplateModal from '../session-note-template-modal';
import {FormProvider, useForm} from 'react-hook-form';
import {Session} from 'src/models';
import {of} from 'rxjs';

const SupervisionSessionCollect = ({session, supervisedSession}: any) => {
  const styles = useStyle();
  const methods = useForm({});

  return (
    <View style={[styles.flex, styles.padding]}>
      <ScrollView>
        <SessionSummaryDataTable
          session={supervisedSession}
          showGraphs={false}
          showAllPrograms={true}
          showProgramMenu={true}
        />
      </ScrollView>
      {session?.startTimestamp === null ? (
        <FormProvider {...methods}>
          <SessionNoteTemplateModal session={session} />
        </FormProvider>
      ) : null}
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({session, database}: any) => ({
    supervisedSessions: database
      .get(Session.table)
      .query(
        Q.where('type', 'session'),
        Q.where('appointment_id', session.appointmentId),
        Q.where('session_date', session.sessionDate),
        Q.where('deleted_at', null),
      ),
  })),
  withObservables([], ({supervisedSessions}: any) => ({
    supervisedSession: supervisedSessions.length
      ? supervisedSessions[0]
      : of({}),
  })),
)(SupervisionSessionCollect);
