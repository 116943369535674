import moment from 'moment/moment';
import _ from 'lodash';
import {Event, User} from 'src/models';
import {Q} from '@nozbe/watermelondb';

export const exportFrequency = async (database: any, sets: any[]) => {
  let _exportData = [
    [
      'Date',
      'Time Stamp',
      'Collector',
      'Number of Instances',
      'Antecedents',
      'Consequences',
      'Mild',
      'Moderate',
      'Severe',
    ],
  ];
  const events = await database
    .get(Event.table)
    .query(
      Q.where('set_id', Q.oneOf(sets.map((set: any) => set.id))),
      Q.where('deleted_at', null),
      Q.sortBy('created_at', Q.asc),
    );

  const eventsBySetId = _.groupBy(events, 'set.id');

  for (const set of sets) {
    const setEvents = eventsBySetId[set?.id] || [];
    let collector;
    const intensityCount = {
      mild: 0,
      moderate: 0,
      severe: 0,
    };
    let antecedentsArr = [];
    let consequencesArr = [];

    if (setEvents.length) {
      collector = await database.get(User.table).find(setEvents[0].createdBy);
    }

    for (const event of setEvents) {
      intensityCount[event?.intensity]++;

      for (const promptId of event?.prompts) {
        const prompt = await database.get('prompts').find(promptId);
        if (prompt?.promptType === 'Antecedents') {
          antecedentsArr.push(prompt.name);
        }
        if (prompt?.promptType === 'Consequences') {
          consequencesArr.push(prompt.name);
        }
      }
    }

    const antecedentsStr = antecedentsArr.length
      ? [...new Set(antecedentsArr)].join(', ')
      : '-';
    const consequencesStr = consequencesArr.length
      ? [...new Set(consequencesArr)].join(', ')
      : '-';

    _exportData.push([
      moment(set?.startTimestamp).format('ll'),
      `${moment(set?.startTimestamp).format('hh:mm A')} - ${
        set?.endTimestamp
          ? moment(set?.endTimestamp).format('hh:mm A')
          : 'Incomplete'
      }`,
      collector ? `${collector?.firstName} ${collector?.lastName}` : '-',
      `${setEvents.length}`,
      `${antecedentsStr}`,
      `${consequencesStr}`,
      `${intensityCount.mild}`,
      `${intensityCount.moderate}`,
      `${intensityCount.severe}`,
    ]);
  }
  return _exportData;
};
