import React from 'react';
import {View, Text} from 'react-native';
import {Typography} from 'src/styles';
import {IconButton, Menu} from 'src/design-system';
import {useTranslations} from 'src/providers/translation';
import FilterChip from 'src/design-system/filter-chip';
import {useNavigation} from '@react-navigation/native';
import {useStyle} from 'src/providers/style';

const NoteTemplateItem = ({
  template,
  canCreate,
  canEdit,
  canDelete,
  deleteSelected,
  duplicateTemplate,
}: any) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const translations = useTranslations();

  const navigateToUpsert = () => {
    navigation.navigate('EditTemplateScreen', {
      id: template.id,
    });
  };

  let options = [];
  if (template.managed) {
    if (canCreate) {
      options.push({
        onPress: () => {
          duplicateTemplate(template);
        },
        title: 'Duplicate',
        icon: 'content-copy',
      });
    }
  } else {
    if (canEdit) {
      options.push({
        onPress: navigateToUpsert,
        title: 'Edit',
        icon: 'pencil',
      });
    }

    if (canCreate) {
      options.push({
        onPress: () => {
          duplicateTemplate(template);
        },
        title: 'Duplicate',
        icon: 'content-copy',
      });
    }

    if (canDelete) {
      options.push({
        onPress: () => deleteSelected(template),
        title: 'Delete',
        icon: 'delete',
      });
    }
  }

  return (
    <View
      style={[
        styles.card,
        styles.row,
        styles.justifySpaceBetween,
        styles.alignCenter,
        styles.paddingL,
      ]}>
      <View
        style={[
          styles.flex,
          styles.paddingLVertical,
          styles.row,
          styles.justifyStart,
        ]}>
        <Text style={[Typography.P2_MEDIUM]}>
          <Text style={[Typography.P2_MEDIUM]}>{template.title}</Text>
          {template?.publishedAt ? (
            <View style={[styles.marginLeft]}>
              <FilterChip
                icon={'check-circle'}
                textColor={'#0F5C4A'}
                backgroundColor={'#B4FFD6'}
                label={translations('published')}
              />
            </View>
          ) : (
            <View style={[styles.marginLeft]}>
              <FilterChip
                icon={'alert-circle'}
                textColor={'#25262F'}
                backgroundColor={'#DEDEE1'}
                label={translations('draft')}
              />
            </View>
          )}
        </Text>
      </View>
      {options.length > 0 ? (
        <Menu
          anchor={<IconButton type={'icon'} icon={'dots-vertical'} />}
          options={options}
        />
      ) : null}
    </View>
  );
};

export default NoteTemplateItem;
