import {text, field, json, relation} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class NoteTemplateVersion extends Base {
  static table = 'note_template_versions';

  static associations = {
    note_templates: {type: 'belongs_to', key: 'note_template_id'},
  };

  @relation('note_templates', 'note_template_id') noteTemplate;

  @field('note_template_id') noteTemplateId;
  @field('managed') managed;
  @text('title') title;
  @text('template') template;
  @field('version') version;

  @field('organization_information') organizationInformation;
  @field('organization_name') organizationName;
  @field('organization_logo') organizationLogo;
  @field('organization_address') organizationAddress;
  @field('organization_billing_address') organizationBillingAddress;
  @field('organization_tin') organizationTin;
  @field('organization_npi') organizationNpi;
  @field('organization_account_number') organizationAccountNumber;

  @field('client_information') clientInformation;
  @field('client_first_name') clientFirstName;
  @field('client_middle_name') clientMiddleName;
  @field('client_last_name') clientLastName;
  @field('client_gender') clientGender;
  @field('client_birth_date') clientBirthDate;
  @field('client_age_in_months') clientAgeInMonths;
  @field('client_ssn') clientSsn;
  @field('client_diagnoses') clientDiagnoses;
  @field('client_assigned_staff') clientAssignedStaff;
  @field('primary_member_id') primaryMemberId;
  @field('secondary_member_id') secondaryMemberId;

  @field('session_address') sessionAddress;
  @field('session_information') sessionInformation;
  @field('session_staff') sessionStaff;
  @field('session_date_of_service') sessionDateOfService;
  @field('session_start_time') sessionStartTime;
  @field('session_end_time') sessionEndTime;
  @field('session_duration') sessionDuration;
  @field('session_hour_format') sessionHourFormat;
  @field('session_type') sessionType;
  @field('session_location') sessionLocation;
  @field('staff_npi') staffNpi;
  @field('staff_license_number') staffLicenseNumber;
  @field('session_units_of_service') sessionUnitsOfService;

  @field('clinical_note') clinicalNote;
  @field('show_authors_and_timestamps') showAuthorsAndTimestamps;

  @field('session_data') sessionData;
  @field('skill') skill;
  @field('behavior') behavior;

  @field('additional_fields') additionalFields;
  @json('form_builder_values', value => value) formBuilderValues;

  @field('signature') signature;

  @field('published_at') publishedAt;
}
