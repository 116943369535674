import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {VictoryLine, VictoryTooltip} from 'victory-native';
import {Colors, Typography} from 'src/styles';
import {boxShadow} from 'src/styles/mixins';
import {G, ForeignObject} from 'react-native-svg';
import moment from 'moment';

export const HTMLFlyOut = ({x, y, point}: any) => {
  return (
    <G>
      <ForeignObject
        x={x - 130}
        y={y / 2}
        width="260"
        height="600"
        /* eslint-disable-next-line react-native/no-inline-styles */
        style={{overflow: 'visible'}}>
        <View style={styles.card}>
          {Object.keys(point.values).map(key => {
            return (
              <View key={key} style={[styles.paddingLTop]}>
                <Text style={[Typography.P3]}>{moment(key).format('ll')}</Text>
                {point.values[key].map((env, index) => {
                  return (
                    <View
                      key={`env-${env.title}-${index}`}
                      style={[styles.paddingLTop]}>
                      <View style={[styles.row]}>
                        <Text numberOfLines={1} style={[Typography.H5]}>
                          {env.title}
                        </Text>
                      </View>
                      <Text
                        numberOfLines={3}
                        style={[Typography.P3, styles.paddingMTop]}>
                        {env.description}
                      </Text>
                    </View>
                  );
                })}
              </View>
            );
          })}
        </View>
      </ForeignObject>
    </G>
  );
};

const EnvironmentalFactorItems = ({
  items,
  showEnvChange,
  showEnvFactor,
}: any) => {
  return items.map((point, index: number) => {
    if (point.type === 'change' && showEnvChange) {
      return (
        <VictoryLine
          key={`env-${index}`}
          style={{
            data: {
              stroke: Colors.TEAL_600,
              strokeWidth: 0.5,
              strokeDasharray: '4, 2',
            },
            labels: {
              fill: Colors.RAVEN_BLACK,
              fontSize: 10,
            },
          }}
          labels={() => ' '}
          labelComponent={
            <VictoryTooltip
              constrainToVisibleArea={true}
              renderInPortal={false}
              flyoutComponent={<HTMLFlyOut point={point} />}
            />
          }
          data={[
            {x: point.x - 0.5, y: 0},
            {x: point.x - 0.5, y: 105},
          ]}
        />
      );
    } else if (point.type === 'factor' && showEnvFactor) {
      return (
        <VictoryLine
          key={`env-${index}`}
          style={{
            data: {
              stroke: Colors.ORANGE_300,
              strokeWidth: 0.5,
              strokeDasharray: '4, 2',
            },
            labels: {
              fill: Colors.RAVEN_BLACK,
              fontSize: 10,
            },
          }}
          labels={() => ' '}
          labelComponent={
            <VictoryTooltip
              constrainToVisibleArea={true}
              renderInPortal={false}
              flyoutComponent={<HTMLFlyOut point={point} />}
            />
          }
          data={[
            {x: point.x - 0.4, y: 0},
            {x: point.x - 0.4, y: 105},
          ]}
        />
      );
    } else {
      return <></>;
    }
  });
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  card: {
    borderRadius: 20,
    backgroundColor: Colors.RAVEN_WHITE,
    padding: 15,
    ...boxShadow(Colors.RAVEN_BLACK),
  },
  paddingLTop: {
    paddingTop: 12,
  },
  paddingMTop: {
    paddingTop: 8,
  },
  overflowVisible: {overflow: 'visible'},
  pointerEventsNone: {pointerEvents: 'none'},
});

export default EnvironmentalFactorItems;
