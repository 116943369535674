import React, {createContext, useContext} from 'react';
import {SegmentClient} from 'src/providers/segment';

const context = createContext<any | null>(null);

export default context;

export const Provider = context.Provider;
export const Consumer = context.Consumer;

export const useAnalytics = () => useContext(context);

export const AnalyticsProvider = ({
  children,
  client,
}: {
  children: any;
  client: SegmentClient;
}) => {
  return <Provider value={client}>{children}</Provider>;
};
