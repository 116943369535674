import React from 'react';
import {Text} from 'react-native';
import {DataItem, MultiSelectInput} from 'src/design-system';
import {sortedDiagnosisList} from 'src/common-utils/diagnosisCodes';
import * as Yup from 'yup';
import {IDeveloperField} from 'dromo-uploader-react';
import {Typography} from 'src/styles';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const Import = (
  key: string = 'diagnoses',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Diagnoses',
  key,
  type: 'select',
  selectOptions: sortedDiagnosisList(),
});

const resolveDisplayValue = (value: string) => {
  return sortedDiagnosisList().find(item => item.value === value)?.label;
};

const DiagnosisDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Diagnoses</Text>
      <Text style={[Typography.P3]}>
        {Array.isArray(value) && value.length > 0
          ? value.map(item => resolveDisplayValue(item)).join('\n')
          : 'Undiagnosed'}
      </Text>
    </DataItem>
  );
};

const DiagnosisInput = ({valueKey}: Props) => {
  return (
    <MultiSelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'diagnoses'}
      name={'Diagnoses'}
      items={sortedDiagnosisList()}
      required={false}
    />
  );
};

export const validation = () =>
  Yup.array()
    .of(Yup.string().required())
    .min(1, 'At least 1 diagnosis is required');

export const Display = DiagnosisDisplay;
export const Input = DiagnosisInput;
export const Value = resolveDisplayValue;

export default DiagnosisInput;
