import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useTranslations} from 'src/providers/translation';
import _ from 'lodash';
import FilterChip from 'src/design-system/filter-chip';
import {useStyle} from 'src/providers/style';

interface Props {
  name?: string;
  tags?: any[];
  type?: string;
}

interface DisplayProps {
  tags?: any[];
  type?: string;
}

interface CardDisplayProps {
  tags?: any[];
  type?: string;
}
export const Import = (
  tags: any[],
  key: string = 'tags',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Tags',
  key,
  type: 'select',
  selectOptions: tags,
});

export const Value = (value: string) => {
  return value;
};

export const Display = compose(
  withDatabase,
  withState,
  withObservables([], ({database, value}: any) => ({
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('id', Q.oneOf(value)),
        Q.sortBy('rank', Q.asc),
      ),
  })),
)(({tags, type}: DisplayProps) => {
  const translations = useTranslations();
  const styles = useStyle();

  const groupedTags = _.groupBy(tags, 'tagType');
  return (
    <DataItem>
      <Text style={[Typography.H6, styles.marginLBottom]}>
        {translations('tags')}
      </Text>
      {Object.keys(groupedTags).map(tagType => {
        return (
          <View
            key={`tag-type-${tagType}`}
            style={[styles.column, styles.marginLBottom]}>
            <Text
              style={[
                Typography.P3_BOLD,
                styles.textColorPrimary,
                styles.marginSMBottom,
              ]}>
              {type ? '' : tagType}
            </Text>
            <View style={[styles.row, styles.flexWrap]}>
              {groupedTags[tagType].map((tag: any) => {
                return (
                  <View
                    key={`tag-${tag?.acronym}-${tag?.name}`}
                    style={[styles.marginSMRight]}>
                    <FilterChip
                      label={type === 'card' ? tag?.acronym : tag?.name}
                      textColor={Colors.SECONDARY_800}
                      backgroundColor={'#DBE0FF'}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        );
      })}
    </DataItem>
  );
});

export const CardDisplay = compose(
  withDatabase,
  withState,
  withObservables([], ({database, value}: any) => ({
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('id', Q.oneOf(value)),
        Q.sortBy('rank', Q.asc),
      ),
  })),
)(({tags}: CardDisplayProps) => {
  const styles = useStyle();
  const groupedTags = _.groupBy(tags, 'tagType');
  return (
    <DataItem>
      {Object.keys(groupedTags).map(tagType => {
        return (
          <View key={`tag-type-${tagType}`} style={[styles.column]}>
            <View style={[styles.row, styles.flexWrap]}>
              {groupedTags[tagType].map((tag: any) => {
                return (
                  <View
                    key={`tag-${tag?.acronym}-${tag?.name}`}
                    style={[styles.marginSMRight]}>
                    <FilterChip
                      label={tag?.acronym}
                      textColor={Colors.SECONDARY_800}
                      backgroundColor={'#DBE0FF'}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        );
      })}
    </DataItem>
  );
});

export const Input = compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.where('enabled', true),
        Q.sortBy('rank', Q.asc),
      ),
    profileTags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
        Q.where('enabled', true),
        Q.where('tag_type', Q.eq('Related Services')),
        Q.sortBy('rank', Q.asc),
      ),
  })),
)(({name, tags, profileTags, type}: Props) => {
  const translations = useTranslations();
  const selectedTags = type === 'profile' ? profileTags : tags;
  const groupedTags = _.groupBy(selectedTags, 'tagType');
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'tags'}
      label={translations('tags')}
      required={false}
      sections={Object.keys(groupedTags).map(tagType => {
        const sortedTags = groupedTags[tagType].sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        return {
          label: tagType,
          data: sortedTags.map(tag => {
            return {
              label: tag.name,
              value: tag.id,
            };
          }),
        };
      })}
      multiple={true}
    />
  );
});

export const validation = () => Yup.string().required('Tags are required');

export default Input;
