import React from 'react';
import {NoteTemplate, NoteTemplateVersion} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import _ from 'lodash';

interface Props {
  name: string;
  items: any[];
  label?: string;
}

const NoteTemplateSelect = ({
  name,
  items,
  label = 'Select Note Template',
}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'noteTemplate'}
      label={label}
      required={false}
      multiple={false}
      selectAll={false}
      items={items}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    noteTemplates: database
      .get(NoteTemplate.table)
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
      ),
  })),
  withObservables(
    ['noteTemplates'],
    ({database, authentication, noteTemplates}: any) => {
      const templateIds = noteTemplates.map(
        (template: NoteTemplate) => template.id,
      );
      return {
        noteTemplateVersions: database
          .get(NoteTemplateVersion.table)
          .query(
            Q.where('deleted_at', null),
            Q.where('_partition', authentication.selectedGroup),
            Q.where('published_at', Q.notEq(0)),
            Q.where('note_template_id', Q.oneOf(templateIds)),
          ),
      };
    },
  ),
  withObservables(['noteTemplateVersions'], ({noteTemplateVersions}: any) => {
    const groupedVersions = _.groupBy(
      noteTemplateVersions,
      (item: any) => item.noteTemplateId,
    );
    const newestVersions = [];
    for (const key in groupedVersions) {
      newestVersions.push(
        groupedVersions[key].sort((a: any, b: any) => b.version - a.version)[0],
      );
    }
    return {
      items: of(
        newestVersions.map((template: NoteTemplateVersion) => {
          return {
            label: `${template.title}`,
            value: template.noteTemplateId,
          };
        }),
      ),
    };
  }),
)(NoteTemplateSelect);
