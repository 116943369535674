import {field} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Note extends Base {
  static table = 'notes';

  @field('entity') entity;
  @field('entity_id') entityId;
  @field('description') description;
}
