import React, {useReducer, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Collapsible from 'react-native-collapsible';
import {SessionProgramPrompt} from 'src/modules/session/components';
import {Colors, Typography} from 'src/styles';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {Q} from '@nozbe/watermelondb';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Event, Set} from 'src/models';
import {of} from 'rxjs';
import {useSelector} from 'react-redux';
import {IconButton} from 'src/design-system';
import SetComplete from 'src/modules/session/components/set-complete';
import {useStyle} from 'src/providers/style';
import ProgramTooltip from '../../program-tooltip';

const TrialByTrialTargetCard = ({
  session,
  program,
  target,
  prompts,
  sets,
  events,
}: any) => {
  const database = useDatabase();
  const styles = useStyle();

  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const total = program?.numberOfTrials;
  const [selectedPrompt, setSelectedPrompt] = useState<number | null>(null);

  const currentSet = sets?.[0] || {
    startTimestamp: null,
    endTimestamp: null,
  };
  const onValue = async (
    selectedTarget: any,
    response: string,
    prompt: any,
  ) => {
    if (!currentSet || currentSet.startTimestamp === null) {
      await database?.write(async () => {
        const set = await database?.get(Set.table).create(entity => {
          entity.partition = selectedGroup;
          entity.session.id = session.id;
          entity.target.id = selectedTarget.id;
          entity.program.id = program.id;
          entity.startTimestamp = new Date();
          entity.endTimestamp = events?.length + 1 >= total ? new Date() : null;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
        await database.get(Event.table).create(entity => {
          entity.partition = selectedGroup;
          entity.set.id = set.id;
          entity.target.id = selectedTarget.id;
          entity.value = response;
          entity.prompts = prompt ? [prompt] : [];
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
        return set;
      });
    } else {
      await database?.write(async () => {
        await database.get(Event.table).create(entity => {
          entity.partition = selectedGroup;
          entity.set.id = currentSet.id;
          entity.target.id = selectedTarget.id;
          entity.value = response;
          entity.prompts = prompt ? [prompt] : [];
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
      if (events?.length + 1 >= total) {
        currentSet.endSet();
      }
    }
    setValue('');
    setSelectedPrompt(null);
    forceUpdate();
  };

  const addSet = async () => {
    await database?.write(async () => {
      return await database?.get(Set.table).create(entity => {
        entity.partition = selectedGroup;
        entity.session.id = session.id;
        entity.target.id = target.id;
        entity.program.id = program.id;
        entity.startTimestamp = new Date();
        entity.endTimestamp = null;
        entity.createdBy = userId;
        entity.updatedBy = userId;
      });
    });
  };

  const error = () => {
    setValue('error');
    if (prompts?.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
      onValue(target, 'error', null);
    }
  };

  const success = () => {
    setValue('success');
    setSelectedPrompt(null);
    setOpen(false);
    onValue(target, 'success', null);
  };

  const promptSelected = (id: number) => {
    setSelectedPrompt(id);
    setOpen(false);
    onValue(target, 'error', id);
  };

  const undo = async () => {
    if (currentSet.endTimestamp !== null) {
      currentSet.updateEntity({
        endTimestamp: null,
      });
    } else {
      if (currentSet.startTimestamp !== null) {
        const orderedEvents = await currentSet.orderedEvents.fetch();
        if (orderedEvents.length > 1) {
          const event = orderedEvents[0];
          event.delete();
        } else {
          const event = orderedEvents?.[0];
          if (event) {
            event.delete();
          }
          currentSet.delete();
        }
      }
    }
  };

  const trials = events?.length || 0;

  return (
    <View style={[styles.column, styles.width300, styles.paddingLHorizontal]}>
      <View
        style={[
          styles.border,
          styles.borderColorPrimary300,
          styles.borderRadiusSM,
          styles.padding,
          styles.marginLVertical,
        ]}>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignCenter,
            styles.marginBottom,
          ]}>
          <View style={[styles.flex, styles.column]}>
            <ProgramTooltip name={target?.name} />
            <Text style={[Typography.CAPTION, styles.textColorSecondary]}>
              Set {sets?.length || 1}
            </Text>
          </View>
          {sets.length > 0 ? (
            <IconButton
              onPress={undo}
              icon={'undo'}
              type={'icon'}
              color={Colors.RAVEN_BLACK}
            />
          ) : null}
          <Text
            style={[
              Typography.P3,
              styles.paddingSMVertical,
              styles.paddingLHorizontal,
              styles.borderRadius,
              styles.backgroundColorPrimary50,
              styles.flexShrink0,
            ]}>{`Trial ${trials}/${total}`}</Text>
        </View>

        {events?.length === total ? (
          <View style={[styles.alignCenter, styles.justifyCenter]}>
            <SetComplete
              fill={
                events?.length === 0
                  ? 100
                  : Math.ceil(
                      (events?.filter((event: any) => event.value === 'success')
                        .length /
                        events?.length) *
                        100,
                    )
              }
              addSet={addSet}
            />
          </View>
        ) : (
          <>
            <View
              style={[
                styles.row,
                styles.paddingTop,
                styles.justifySpaceBetween,
                styles.alignCenter,
              ]}>
              <View style={[styles.row, styles.alignCenter]}>
                <TouchableOpacity
                  style={[
                    styles.width40,
                    styles.height40,
                    styles.borderRadius20,
                    styles.alignCenter,
                    styles.justifyCenter,
                    styles.backgroundColorBlack,
                    styles.border,
                    styles.borderColorError,
                    value === 'error'
                      ? styles.backgroundColorError
                      : styles.backgroundColorWhite,
                  ]}
                  onPress={error}>
                  <MaterialCommunityIcon
                    size={20}
                    name="close"
                    color={
                      value === 'error' ? Colors.RAVEN_WHITE : Colors.ERROR
                    }
                  />
                </TouchableOpacity>
              </View>
              <View
                style={[
                  styles.flex,
                  styles.row,
                  styles.justifySpaceAround,
                  styles.alignCenter,
                  styles.paddingLHorizontal,
                ]}>
                <Text style={[Typography.P2_MEDIUM]}>Correct</Text>
              </View>
              <View style={[styles.row, styles.alignCenter]}>
                <TouchableOpacity
                  style={[
                    styles.width40,
                    styles.height40,
                    styles.borderRadius20,
                    styles.alignCenter,
                    styles.justifyCenter,
                    styles.backgroundColorBlack,
                    styles.border,
                    styles.borderColorSuccessDark,
                    value === 'success'
                      ? styles.backgroundColorSuccessDark
                      : styles.backgroundColorWhite,
                  ]}
                  onPress={success}>
                  <MaterialCommunityIcon
                    size={20}
                    name="check"
                    color={
                      value === 'success'
                        ? Colors.RAVEN_WHITE
                        : Colors.SUCCESS_DARK
                    }
                  />
                </TouchableOpacity>
              </View>
            </View>
            <Collapsible collapsed={!open}>
              <View style={[styles.paddingTop]}>
                <Text
                  style={[
                    Typography.CAPTION,
                    styles.textColorSecondary,
                    styles.marginLBottom,
                  ]}>
                  Prompt
                </Text>
                <View style={[styles.row, styles.flexWrap]}>
                  {prompts?.map((prompt: any) => (
                    <SessionProgramPrompt
                      key={`session-program-prompt-${prompt?.id ?? prompt}`}
                      selected={selectedPrompt === prompt}
                      prompt={prompt}
                      onClk={() => {
                        promptSelected(prompt.id);
                      }}
                    />
                  ))}
                </View>
              </View>
            </Collapsible>
          </>
        )}
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({sessionTarget}: any) => ({
    target: sessionTarget.target,
  })),
  withObservables([], ({session, target, database}: any) => ({
    sets: database
      .get('sets')
      .query(
        Q.and(
          Q.where('target_id', target.id),
          Q.where('session_id', session.id),
          Q.where('deleted_at', null),
        ),
        Q.sortBy('created_at', Q.desc),
      ),
  })),
  withObservables(['sets'], ({sets}: any) => {
    if (sets.length !== 0) {
      return {
        events: sets?.[0]?.orderedEvents,
      };
    }

    return {
      events: of([]),
    };
  }),
)(TrialByTrialTargetCard);
