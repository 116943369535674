import React, {Dispatch, SetStateAction} from 'react';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {Modal} from 'src/design-system';
import {Program, Target} from 'src/models';
import {useTranslations} from 'src/providers/translation';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';

interface Props {
  show: [boolean, Dispatch<SetStateAction<boolean>>];
  program: Program;
  targets: Target[];
}

const ProgramInstructionModal = ({show, program, targets}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <Modal show={show} title={program.name}>
      <Text
        style={[
          Typography.H5,
          styles.textColorPrimary900,
          styles.marginMBottom,
        ]}>
        {translations('description')}
      </Text>
      <Text
        style={[
          Typography.P3,
          styles.textColorPrimary900,
          styles.marginBottom,
        ]}>
        {program?.stimulusDescription}
      </Text>
      <Text
        style={[
          Typography.H5,
          styles.textColorPrimary900,
          styles.marginMBottom,
        ]}>
        {translations('objective')}
      </Text>
      <Text
        style={[
          Typography.P3,
          styles.textColorPrimary900,
          styles.marginBottom,
        ]}>
        {program.objective}
      </Text>
      {program?.teachingInstructions ? (
        <>
          <Text
            style={[
              Typography.H5,
              styles.textColorPrimary900,
              styles.marginMBottom,
            ]}>
            {translations('instructions')}
          </Text>
          <Text
            style={[
              Typography.P3,
              styles.textColorPrimary900,
              styles.marginMBottom,
            ]}>
            {program.teachingInstructions}
          </Text>
        </>
      ) : (
        <></>
      )}
      {program?.type === 'skill' ? (
        <>
          <Text
            style={[
              Typography.H5,
              styles.textColorPrimary900,
              styles.marginMBottom,
            ]}>
            {translations('targets')}
          </Text>
          {targets.map(target => {
            return (
              <Text
                key={`target-id-${target.id}`}
                style={[
                  Typography.P3,
                  styles.textColorPrimary900,
                  styles.marginSMBottom,
                ]}>
                {target.name}
              </Text>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default compose(
  withObservables([], ({program}: any) => {
    return {
      targets: program.enabledTargets,
    };
  }),
)(ProgramInstructionModal);
