import React from 'react';
import {Text, View} from 'react-native';
import {RHAvatar} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {getAge} from 'src/common-utils/age';
import {useStyle} from 'src/providers/style';

const PatientAvatar = ({patient}: any) => {
  const styles = useStyle();

  const firstName = patient?.firstName;
  const lastName = patient?.lastName;
  const age = getAge(patient?.birthDate);
  const gender = patient?.gender;
  const firstInitial = firstName?.length > 0 ? firstName[0].toUpperCase() : 'N';
  const lastInitial = lastName?.length > 0 ? lastName[0].toUpperCase() : 'N';
  const initials = firstInitial + lastInitial;
  const secondLineDisplay = age && gender ? `Age ${age}, ${gender}` : '';

  return (
    <View style={[styles.row, styles.justifySpaceBetween]}>
      <View style={[styles.row, styles.alignCenter]}>
        <RHAvatar
          label={initials}
          color={Colors.RAVEN_BLACK}
          textColor={Colors.RAVEN_WHITE}
          size={32}
        />
        <View style={[styles.paddingLLeft, styles.justifyCenter]}>
          <Text
            style={[
              Typography.P3,
              styles.textColorPrimary,
            ]}>{`${firstName} ${lastName}`}</Text>
          <Text style={[Typography.P3, styles.textColorSecondary]}>
            {secondLineDisplay}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PatientAvatar;
