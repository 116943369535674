import React from 'react';
import {View} from 'react-native';
import DisplayUserDataSnippet from 'src/common-components/displayUserDataSnippet';
import {useNavigation} from '@react-navigation/native';
import {IconButton, Menu} from 'src/design-system';
import {getAge} from 'src/common-utils/age';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

const PatientListItem = ({
  item: patient,
  canEdit,
  onAssign,
  canDelete,
}: any) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const translations = useTranslations();

  const firstName = patient?.firstName || '';
  const lastName = patient?.lastName || '';
  const resourceType = 'Patient';
  const gender = patient?.gender || '';
  const age = getAge(patient?.birthDate) || '';

  const options = [];
  if (canEdit || canDelete) {
    options.push({
      onPress: () => onAssign(patient),
      title: translations('unassign_client'),
      icon: 'account-remove',
    });
  }
  options.push({
    onPress: () => {
      navigation.navigate('PatientProfile', {
        patientId: patient.id,
      });
    },
    title: translations('view_client_profile'),
    icon: 'eye',
  });

  return (
    <View
      style={[
        styles.flex,
        styles.width,
        styles.row,
        styles.justifySpaceBetween,
        styles.paddingLHorizontal,
      ]}>
      <DisplayUserDataSnippet
        firstName={firstName}
        lastName={lastName}
        resourceType={resourceType}
        gender={gender}
        age={age}
      />
      <View style={[styles.alignCenter, styles.justifyCenter]}>
        {canEdit || canDelete ? (
          <Menu
            anchor={<IconButton type={'icon'} icon={'dots-vertical'} />}
            options={options}
          />
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

export default PatientListItem;
