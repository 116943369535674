import React from 'react';
import Lottie from 'react-lottie';

const ReactNativeLottie = ({src}: any) => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: src,
        rendererSettings: {
          // preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={200}
      width={100}
    />
  );
};

export default ReactNativeLottie;
