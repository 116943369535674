import React from 'react';
import {Credential, User} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import _ from 'lodash';

interface Props {
  override?: string;
  name: string;
  label: string;
  items: any[];
  selectAll?: boolean;
  multiple?: boolean;
  showHelper?: boolean;
}

const UserCredentialSelect = ({
  override,
  name,
  label = 'Select Staff',
  items,
  selectAll = false,
  multiple = false,
  showHelper = true,
}: Props) => {
  return (
    <HookFormSelectInput
      name={override ?? (name ? name + '.' : '') + 'credentials'}
      label={label}
      required={false}
      multiple={multiple}
      selectAll={selectAll}
      items={items}
      showHelper={showHelper}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    credentials: database
      .get(Credential.table)
      .query(
        Q.where('deleted_at', null),
        Q.where('user_id', Q.notEq(null)),
        Q.where('_partition', authentication.selectedGroup),
      ),
  })),
  withObservables([], ({database, credentials}: any) => {
    return {
      users: database
        .get(User.table)
        .query(
          Q.where('deleted_at', null),
          Q.where(
            'id',
            Q.oneOf(credentials.map(credential => credential.userId)),
          ),
          Q.sortBy('first_name', Q.asc),
          Q.sortBy('last_name', Q.asc),
        ),
    };
  }),
  withObservables([], ({users, credentials}: any) => {
    const groupedCredentials = _.keyBy(credentials, 'userId');

    return {
      items: of(
        users.map((user: User) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: groupedCredentials[user.id].id,
          };
        }),
      ),
    };
  }),
)(UserCredentialSelect);
