import React from 'react';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import {of} from 'rxjs';
import SelectInput from 'src/hook-form/select-input';

interface Props {
  programs: any[];
  selectProgram: any;
  selected: any;
}

const ProgramSelect = ({programs, selectProgram, selected}: Props) => {
  const dropdownPrograms = programs.map(program => ({
    label: program.name,
    value: program.id,
  }));

  return (
    <SelectInput
      label={'Select Program'}
      items={dropdownPrograms}
      value={selected}
      onChange={selectProgram}
      error={undefined}
    />
  );
};

export default compose(
  withDatabase,
  withObservables(['patientId'], ({database, patientId}: any) => {
    return {
      programs: patientId
        ? database
            .get('programs')
            .query(
              Q.where('patient_id', patientId),
              Q.where('state', Q.notEq('archived')),
              Q.where('deleted_at', null),
            )
        : of([]),
    };
  }),
)(ProgramSelect);
