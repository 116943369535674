import {Q} from '@nozbe/watermelondb';
import {field, lazy, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Prompt extends Base {
  static table = 'prompts';
  static associations = {
    program_prompt: {type: 'has_many', foreignKey: 'prompt_id'},
  };

  @lazy
  programs = this.collections
    .get('programs')
    .query(Q.on('program_prompt', 'prompt_id', this.id));

  @text('prompt_type') promptType;
  @text('name') name;
  @text('acronym') acronym;
  @text('rank') rank;
  @field('enabled') enabled;
}
