import {text, relation} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Taggable extends Base {
  static table = 'taggables';
  static associations = {
    users: {type: 'belongs_to', key: 'entity_id'},
    patients: {type: 'belongs_to', key: 'entity_id'},
    tags: {type: 'belongs_to', key: 'tag_id'},
  };

  @relation('users', 'entity_id') user;
  @relation('clients', 'entity_id') patient;

  @text('_partition') partition;
  @text('entity') entity;
  @text('entity_id') entityId;
  @text('tag_id') tagId;
}
