import React from 'react';
import PublicClientApplication from 'react-native-msal';
import MicrosoftButton from 'src/modules/authentication/components/microsoft-button';
import {Platform} from 'react-native';
import {MICROSOFT_OAUTH} from 'react-native-dotenv';

const MicrosoftSignIn = ({submit}: any) => {
  const login = async () => {
    const msalConfig = {
      auth: {
        clientId: MICROSOFT_OAUTH,
        authority: 'https://login.microsoftonline.com/common/',
        redirectUri: Platform.select({
          android:
            'msauth://com.ravenhealth.app/JmzYOYRSU9Vu4j6v3FKvgDNFvak%3D',
          default: undefined,
        }),
      },
    };

    const msalInstance = new PublicClientApplication(msalConfig);
    try {
      await msalInstance.init();
    } catch (e) {
      console.log(e);
    }
    const authResult = await msalInstance.acquireToken({
      scopes: ['User.Read'],
    });

    submit({code: authResult.accessToken});
  };

  return <MicrosoftButton login={login} />;
};

export default MicrosoftSignIn;
