import React, {Dispatch, SetStateAction} from 'react';
import {Modal} from 'src/design-system';
import {Text, View} from 'react-native';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import FormInput from 'src/hook-form/form-input';
import RadioInput from 'src/hook-form/radio-input';
import {Controller, useForm} from 'react-hook-form';
import {useTranslations} from 'src/providers/translation';
import {RRule, RRuleSet, rrulestr} from 'rrule';
import {Appointment, Participant} from 'src/models';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import moment from 'moment';

interface Props {
  show: [any, Dispatch<SetStateAction<any>>];
  deleteCancelled: () => void;
}

const DeleteSessionModal = ({show, deleteCancelled}: Props) => {
  const database = useDatabase();
  const translations = useTranslations();

  const {control, handleSubmit, reset} = useForm({});

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const onSubmit = async (data: any) => {
    if (show[0].appointment?.rrule) {
      const rruleSet = rrulestr(show[0].appointment.rrule, {
        forceset: true,
      });
      const todaysDate = new Date(show[0].date);
      switch (data.action) {
        case 'event':
          rruleSet.exdate(
            new Date(
              todaysDate.getFullYear(),
              todaysDate.getMonth(),
              todaysDate.getDate(),
              0,
              0,
              0,
              0,
            ),
          );
          await show[0].appointment.updateEntity({
            rrule: rruleSet.toString(),
          });
          await database?.write(async () => {
            const createAppointment = await database
              ?.get(Appointment.table)
              .create(entity => {
                entity.partition = selectedGroup;
                entity.location = show[0].appointment.location;
                entity.type = show[0].appointment.type;
                entity.date = show[0].date;
                entity.start = show[0].appointment.start;
                entity.end = show[0].appointment.end;
                entity.noteTemplateId = show[0].appointment.noteTemplateId;
                entity.cancellationReason = data.cancellationReason;
                entity.cancellationMoreInfo = data.cancellationMoreInfo;
                entity.deletedBy = userId;
                entity.deletedAt = new Date();
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            for (const participant of await show[0].appointment.participants) {
              await database?.get(Participant.table).create(entity => {
                entity.partition = selectedGroup;
                entity.appointment.id = createAppointment.id;
                entity.userId = participant.userId;
                entity.patientId = participant.patientId;
                entity.billable = participant.billable;
                entity.supervision = participant.supervision;
                entity.startTime = participant.startTime;
                entity.endTime = participant.endTime;
                entity.deletedBy = participant.deletedBy;
                entity.deletedAt = participant.deletedAt;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            }
          });
          break;
        case 'following':
          const ruleSet = new RRuleSet();
          for (const exrule of rruleSet.exrules()) {
            ruleSet.exrule(exrule);
          }
          for (const rdate of rruleSet.rdates()) {
            ruleSet.rdate(rdate);
          }
          for (const exdate of rruleSet.exdates()) {
            ruleSet.exdate(exdate);
          }

          ruleSet.rrule(
            new RRule({
              ...rruleSet.rrules()[0].options,
              until: new Date(
                todaysDate.getFullYear(),
                todaysDate.getMonth(),
                todaysDate.getDate() - 1,
                22,
                59,
              ).setMinutes(moment(rruleSet._dtstart).utcOffset()),
            }),
          );

          await show[0].appointment.updateEntity({
            rrule: ruleSet.toString(),
          });

          const rrule = new RRuleSet();
          for (const exrule of rruleSet.exrules()) {
            rrule.exrule(exrule);
          }
          for (const rdate of rruleSet.rdates()) {
            rrule.rdate(rdate);
          }
          for (const exdate of rruleSet.exdates()) {
            rrule.exdate(exdate);
          }

          rrule.rrule(
            new RRule({
              ...rruleSet.rrules()[0].options,
              dtstart: new Date(
                todaysDate.getFullYear(),
                todaysDate.getMonth(),
                todaysDate.getDate(),
              ),
            }),
          );

          await database?.write(async () => {
            const createAppointment = await database
              ?.get(Appointment.table)
              .create(entity => {
                entity.partition = selectedGroup;
                entity.location = show[0].appointment.location;
                entity.type = show[0].appointment.type;
                entity.date = show[0].date;
                entity.start = show[0].appointment.start;
                entity.end = show[0].appointment.end;
                entity.noteTemplateId = show[0].appointment.noteTemplateId;
                entity.cancellationReason = data.cancellationReason;
                entity.cancellationMoreInfo = data.cancellationMoreInfo;
                entity.rrule = rrule.toString();
                entity.deletedBy = userId;
                entity.deletedAt = new Date();
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            for (const participant of await show[0].appointment.participants) {
              await database?.get(Participant.table).create(entity => {
                entity.partition = selectedGroup;
                entity.appointment.id = createAppointment.id;
                entity.userId = participant.userId;
                entity.patientId = participant.patientId;
                entity.billable = participant.billable;
                entity.supervision = participant.supervision;
                entity.startTime = participant.startTime;
                entity.endTime = participant.endTime;
                entity.deletedBy = participant.deletedBy;
                entity.deletedAt = participant.deletedAt;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            }
          });
          break;
        case 'all':
          show[0].appointment.cancel(
            data.cancellationReason,
            data.cancellationMoreInfo,
          );
          break;
      }
    } else {
      show[0].appointment?.cancel(
        data.cancellationReason,
        data.cancellationMoreInfo,
      );
    }

    reset();
    deleteCancelled();
  };
  return (
    <Modal
      show={show}
      title={'Delete Session'}
      footer={
        <>
          <RHButton
            secondary
            mode="outlined"
            textColor={Colors.RAVEN_BLACK}
            onPress={deleteCancelled}>
            {translations('cancel_button')}
          </RHButton>
          <RHSeparator width={16} />
          <RHButton secondary mode="contained" onPress={handleSubmit(onSubmit)}>
            {translations('yes_delete')}
            {translations('session')}
          </RHButton>
        </>
      }>
      {show[0].appointment?.rrule !== '' ? (
        <Controller
          control={control}
          render={({field: {onChange, value}}) => (
            <RadioInput
              column={true}
              name={''}
              items={[
                {value: 'event', label: 'This Event'},
                {value: 'following', label: 'This and Following Events'},
                {value: 'all', label: 'All Events'},
              ]}
              onChange={onChange}
              value={value}
            />
          )}
          name="action"
          defaultValue={'event'}
        />
      ) : null}
      <Text style={[Typography.P1]}>
        {translations('session_delete_reason')}
      </Text>
      <View>
        <Controller
          control={control}
          render={({field: {onChange, value}}) => (
            <RadioInput
              name={''}
              items={[
                {value: 'client', label: 'Client-Initiated Cancellation'},
                {value: 'staff', label: 'Staff-Initiated Cancellation'},
                {value: 'noShow', label: 'No-Show'},
                {value: 'absent', label: 'Student Absent'},
                {value: 'error', label: 'Added in Error'},
                {value: 'other', label: 'Other'},
              ]}
              value={value}
              onChange={onChange}
            />
          )}
          name="cancellationReason"
          defaultValue={'client'}
        />
        <Controller
          control={control}
          render={({field: {onChange, value, ref}}) => (
            <FormInput
              onSubmit={() => {}}
              placeholder={translations('more_information')}
              label={translations('description_optional')}
              onChange={onChange}
              value={value}
              formRef={ref}
            />
          )}
          name="cancellationMoreInfo"
          defaultValue={''}
        />
      </View>
    </Modal>
  );
};

export default DeleteSessionModal;
