import React from 'react';
import {DataItem, SelectInput} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  valueKey?: string;
  users: any[];
}

export const Import = (
  key: string = 'staff',
  practitioners: any[] = [],
): IDeveloperField => {
  let values = practitioners.map((practitioner: any) => {
    return {
      label: `${practitioner.firstName} ${practitioner.lastName}`,
      value: practitioner.id,
    };
  });

  return {
    label: 'Staff',
    key,
    type: 'select',
    selectOptions: values,
    validators: [],
  };
};

const resolveDisplayValue = (value: string) => {
  return value;
};

const StaffDisplay = ({value, name = 'Staff'}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{name}</Text>
      <Text style={[Typography.P3]}>
        {value?.firstName + ' ' + value?.lastName}
      </Text>
    </DataItem>
  );
};

const StaffInput = compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    users: database
      .get('users')
      .query(
        Q.where('deleted_at', null),
        Q.where('state', 'active'),
        Q.where('_partition', authentication.selectedGroup),
      ),
  })),
)(({valueKey, name = 'Staff', users}: Props) => {
  let values = (users || []).map((practitioner: any) => {
    return {
      label: `${practitioner.firstName} ${practitioner.lastName}`,
      value: practitioner.id,
    };
  });

  return (
    <SelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'staff'}
      name={name}
      required={true}
      items={values}
    />
  );
});

export const validation = () => Yup.string().required('Staff is required');

export const Display = StaffDisplay;
export const Input = StaffInput;
export const Value = resolveDisplayValue;

export default StaffInput;
