import {
  Bar,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryVoronoiContainer,
} from 'victory-native';
import {Colors, Typography} from 'src/styles';
import React from 'react';
import _ from 'lodash';
import {useDimensions} from '@react-native-community/hooks';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import {stateColor} from '../graph';
import {Platform} from 'react-native';

const isWeb = Platform.OS === 'web';

export const CustomBar = (props: any) => {
  const {datum, scale, y} = props;
  const isZero = datum.y === 0;
  const height = isZero ? 5 : y - scale.y(datum.y);
  return <Bar {...props} y={y - height} height={height} />;
};

const TrialByTrialSummaryGraph = ({
  yAxisLabel = '',
  targets = [],
  events = [],
  analyzeSets = () => {},
  recentSetsByTargetId = {},
}: any) => {
  const dimensions = useDimensions();

  const domain = {
    x: [0, (targets.length || 1) + 1],
    y: [0, 100],
  };

  const targetAggregate = {};
  const eventsBySetId = _.groupBy(events, 'set.id');
  const eventsByTargetId = _.groupBy(events, 'target.id');

  for (const target of targets) {
    let sum = 0;
    if (recentSetsByTargetId[target.id]) {
      recentSetsByTargetId[target.id]?.map((set: any) => {
        const avg = Math.ceil(
          (eventsBySetId[set?.id]?.filter(
            (event: any) => event.value === 'success',
          ).length /
            eventsBySetId[set?.id]?.length) *
            100,
        );
        const average = isNaN(avg) ? 0 : avg;
        sum = sum + average;
      });
    }

    if (eventsByTargetId?.[target.id]) {
      const analyzedSets = analyzeSets(target.id);
      const keys = Object.keys(analyzedSets);
      const lastKey = keys[keys.length - 1];
      const lastValue = analyzedSets[lastKey];
      const sessionObject = {
        total: Math.ceil(sum / (recentSetsByTargetId[target.id].length || 1)),
        state: lastValue.state,
      };
      targetAggregate[target.id] = sessionObject;
    }
  }

  return (
    <>
      <VictoryChart
        theme={VictoryTheme.material}
        width={dimensions.screen.width * 0.95}
        domain={domain}
        domainPadding={{x: 40, y: 0}}
        containerComponent={<VictoryVoronoiContainer />}>
        <VictoryLegend
          x={0}
          y={0}
          orientation="horizontal"
          gutter={20}
          titleComponent={<></>}
          style={{border: {stroke: 'black', strokeWidth: 0.1}}}
          data={[
            {
              name: 'Baseline',
              symbol: {fill: Colors.SECONDARY_600, type: 'minus'},
              labels: {fontSize: isWeb ? 10 : 9},
            },
            {
              name: 'In Treatment',
              symbol: {fill: Colors.SECONDARY_800, type: 'minus'},
              labels: {fontSize: isWeb ? 10 : 9},
            },
            {
              name: 'Maintenance',
              symbol: {fill: Colors.VIOLET_500, type: 'minus'},
              labels: {fontSize: isWeb ? 10 : 9},
            },
            {
              name: 'Mastered',
              symbol: {fill: Colors.LIME_700, type: 'minus'},
              labels: {fontSize: isWeb ? 10 : 9},
            },
          ]}
        />
        <VictoryAxis
          domain={{y: [0, 100]}}
          domainPadding={{x: [10, -10], y: 5}}
          tickCount={10}
          tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          dependentAxis
          label={yAxisLabel}
          style={{
            axis: {stroke: 'transparent'},
          }}
          tickFormat={x => `${x}%`}
          axisLabelComponent={
            <VictoryLabel
              style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
              x={10}
            />
          }
        />
        <VictoryAxis
          style={{
            axis: {stroke: Colors.GRAY_100},
            tickLabels: {
              ...Typography.CAPTION,
              fill: Colors.PRIMARY_600,
              angle: 45,
              fontSize: 10,
              padding: 5,
            },
            grid: {stroke: 'transparent'},
          }}
          fixLabelOverlap={true}
          tickCount={targets?.length}
          tickValues={_.range(1, targets.length + 1)}
          tickFormat={x => {
            const maxLineLength = 8;
            if (targets[x - 1]?.name?.length > maxLineLength) {
              const words = targets[x - 1]?.name.split(' ');
              let lines = [];
              let currentLine = words[0];

              for (let i = 1; i < words.length; i++) {
                if (currentLine.length + words[i].length + 1 <= maxLineLength) {
                  currentLine += ` ${words[i]}`;
                } else {
                  lines.push(currentLine);
                  currentLine = words[i];
                }
              }
              lines.push(currentLine);
              let finalStr = lines.join('\n');
              return finalStr.length > 20
                ? `${finalStr.substring(0, 20)}...`
                : finalStr;
            }
            return targets[x - 1]?.name || '';
          }}
          tickLabelComponent={
            <VictoryLabel
              textAnchor={'start'}
              style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
              dy={-10}
            />
          }
        />
        return (
        <VictoryBar
          data={targets.map((target, index) => ({
            x: index + 1,
            y: targetAggregate[target?.id]?.total || 0,
          }))}
          dataComponent={<CustomBar />}
          style={{
            data: {
              fill: ({datum}) => {
                return (
                  stateColor(
                    targetAggregate[targets[datum.x - 1].id]?.state || '',
                  ) || Colors.GRAY_400
                );
              },
            },
          }}
          barWidth={30}
          labels={({data, index}) => `${data[index]?.y}%`}
          labelComponent={
            <VictoryLabel
              style={{...Typography.H8, fill: Colors.TEXT_PRIMARY}}
            />
          }
        />
        );
      </VictoryChart>
    </>
  );
};

export default compose(
  withDatabase,
  withObservables(['sets'], ({sets, database}: any) => {
    const setIds = _.map(sets, 'id');
    return {
      events: database
        .get('events')
        .query(
          Q.where('set_id', Q.oneOf(setIds)),
          Q.where('deleted_at', null),
          Q.sortBy('created_at', Q.desc),
        ),
    };
  }),
)(TrialByTrialSummaryGraph);
