import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import moment from 'moment/moment';
import React from 'react';
import {useStyle} from 'src/providers/style';

const AppointmentCalendarItem = ({
  title,
  type,
  start,
  end,
  apptDuration,
  staffColor,
  textColor,
  flagColor,
}: any) => {
  const styles = useStyle();
  return (
    <View
      style={[
        ...(type !== 'dayGridMonth'
          ? [styles.row]
          : [styles.row, styles.paddingLRight, styles.flex]),
        styles.height,
        {
          backgroundColor:
            type !== 'dayGridMonth'
              ? staffColor || Colors.PRIMARY_200
              : Colors.RAVEN_WHITE,
        },
      ]}>
      <View
        style={[
          ...(type !== 'dayGridMonth'
            ? [
                styles.borderBottomLeftRadius,
                styles.borderTopLeftRadius,
                styles.marginSMRight,
                {borderLeftWidth: 4, right: 3},
              ]
            : [
                styles.alignSelfCenter,
                styles.dotL,
                styles.marginLLeft,
                styles.marginSMRight,
              ]),
          {backgroundColor: flagColor, borderLeftColor: flagColor},
        ]}
      />
      <View
        style={[
          styles.flex,
          styles.alignStart,
          styles.justifyCenter,
          styles.textOverflowClip,
        ]}>
        <Text
          numberOfLines={apptDuration >= 45 ? 2 : 1}
          style={[
            Typography.P3_MEDIUM,
            {
              color:
                type !== 'dayGridMonth'
                  ? textColor || Colors.PRIMARY_600
                  : textColor === Colors.RAVEN_WHITE
                  ? Colors.RAVEN_BLACK
                  : textColor,
            },
          ]}>
          {title} {moment(start).format('LT')} - {moment(end).format('LT')}
        </Text>
      </View>
    </View>
  );
};

export default AppointmentCalendarItem;
