import React from 'react';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';
import SelectInput from 'src/hook-form-wrapper/select-input';

interface Props {
  programId: string;
  items: any[];
}
const TargetInput = (props: Props) => {
  return (
    <SelectInput name={'target'} label={'Select Target'} items={props.items} />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['programId'], ({database, programId}: any) => ({
    program: database.get('programs').findAndObserve(programId),
  })),
  withObservables(['program'], ({program}: any) => ({
    targets: program.activeTargets,
  })),
  withObservables(['targets'], ({targets}: any) => ({
    items: of(
      targets.map((target: any, index: number) => ({
        label: `${index + 1} - ${target.name}`,
        value: target.id,
      })),
    ),
  })),
)(TargetInput);
