import React, {forwardRef} from 'react';
import {View} from 'react-native';
import {HelperText} from 'react-native-paper';
import {
  DatePickerInput,
  en,
  registerTranslation,
} from 'react-native-paper-dates';
import {useStyle} from 'src/providers/style';

registerTranslation('en', en);

interface Props {
  label: string;
  required: boolean;
  value: Date | string;
  minimumDate?: Date | undefined;
  maximumDate?: Date | undefined;
  showHelper?: boolean;
  helper?: string;
  error?: string;
  onChange: (value: any) => void;
  style?: any;
  disabled?: boolean;
}

const DateLayout = forwardRef(
  (
    {
      label,
      required = false,
      value,
      minimumDate = undefined,
      maximumDate = undefined,
      helper = '',
      showHelper = true,
      error = undefined,
      onChange = () => {},
      style = {},
      disabled = false,
    }: Props,
    ref,
  ) => {
    const styles = useStyle();
    // const [update, forceUpdate] = useReducer((x: number) => x + 1, 0);

    return (
      <View style={[]}>
        <DatePickerInput
          ref={ref}
          locale="en"
          label={label + (required ? '*' : '')}
          value={typeof value === 'string' ? new Date(value) : value}
          onChange={onChange}
          // onChangeText={onChangeValue => {
          //   if (isNaN(Date.parse(onChangeValue))) {
          //     onChange(value);
          //     forceUpdate();
          //   }
          // }}
          presentationStyle={'overFullScreen'}
          validRange={{
            startDate: minimumDate,
            endDate: maximumDate,
          }}
          disabled={disabled}
          inputMode="start"
          mode="outlined"
          style={[styles.input, style]}
        />
        {showHelper ? (
          <HelperText
            type={error ? 'error' : 'info'}
            visible={true}
            style={styles.helper}>
            {error ? 'ⓧ ' + error.message : helper}
          </HelperText>
        ) : null}
      </View>
    );
  },
);

export default DateLayout;
