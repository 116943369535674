import React from 'react';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {formInputs} from 'src/modules/form-builder/components/inputs';
import {FormSectionHeader} from 'src/design-system';

const RenderFormInputs = ({
  values,
  name = '',
  mode = 'input',
  outputValues = {},
}: any) => {
  const styles = useStyle();
  const rowIds = Object.keys(values || {});

  return rowIds.length ? (
    <>
      {rowIds.map(rowId => (
        <View style={[styles.paddingSMVertical]} key={rowId}>
          {values[rowId].header ? (
            <FormSectionHeader title={values[rowId].header} />
          ) : (
            <></>
          )}
          <View
            style={[
              styles.container,
              styles.flex,
              styles.paddingMVertical,
              styles.alignCenter,
            ]}>
            {values[rowId].items.map((item: any) => {
              const InputType = formInputs[item.componentType][mode];
              return (
                <View
                  style={[styles.flex, styles.width, styles.paddingM]}
                  key={item?.id}>
                  <InputType
                    name={`${name}.${item.id}`}
                    item={item}
                    value={outputValues[item.id]}
                  />
                </View>
              );
            })}
          </View>
        </View>
      ))}
    </>
  ) : (
    <></>
  );
};

export default RenderFormInputs;
