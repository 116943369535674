import React, {useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {TouchableOpacity, Text, View} from 'react-native';
import {FormInput, OfflineNotification} from 'src/design-system';
import {TextInput} from 'react-native-paper';
import {APP_VERSION, PASSWORD, USERNAME} from 'react-native-dotenv';
import {Typography} from 'src/styles';
import RHButton from '../../../../common-components/custom-ui-helpers/RHButton';
import GoogleSignIn from '../google-sign-in';
import MicrosoftSignIn from '../microsoft-sign-in';
import AppleSignIn from '../apple-sign-in';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

interface Props {
  handleSubmit: (payload: any, formik: any) => void;
  forgotPassword: () => void;
  signup: () => void;
}

const LoginForm = ({handleSubmit, forgotPassword, signup}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [hidePassword, setHidePassword] = useState(true);

  return (
    <>
      <View style={[styles.paddingLBottom]}>
        <GoogleSignIn
          submit={({code}: any) =>
            handleSubmit({
              type: 'code',
              code,
              clientId: 'google',
            })
          }
        />
      </View>
      <View style={[styles.paddingLBottom]}>
        <MicrosoftSignIn
          submit={({code}: any) =>
            handleSubmit({
              type: 'code',
              code,
              clientId: 'msal',
            })
          }
        />
      </View>
      <View style={[styles.paddingLBottom]}>
        <AppleSignIn
          submit={({code}: any) =>
            handleSubmit({
              type: 'code',
              code,
              clientId: 'apple',
            })
          }
        />
      </View>
      <View style={[styles.row, styles.alignCenter, styles.paddingVertical]}>
        <View
          style={[styles.flex, styles.separator, styles.marginMHorizontal]}
        />
        <Text style={[Typography.P1, styles.alignCenter, styles.justifyCenter]}>
          {translations('or')}
        </Text>
        <View
          style={[styles.flex, styles.separator, styles.marginMHorizontal]}
        />
      </View>
      <Formik
        initialValues={{
          username: USERNAME,
          password: PASSWORD,
        }}
        validationSchema={Yup.object({
          username: Yup.string()
            .email(translations('email_invalid'))
            .required(translations('email_required')),
          password: Yup.string().required(translations('password_valid')),
        })}
        onSubmit={(values, formik) =>
          handleSubmit({...values, type: 'password'}, formik)
        }>
        {({
          errors,
          // setErrors,
          values,
          setValues,
          touched,
          setTouched,
          // resetForm,
          submitForm,
        }) => (
          <>
            <FormInput
              valueKey={'username'}
              text={translations('email')}
              required={true}
              errors={errors}
              values={values}
              setValues={setValues}
              touched={touched}
              setTouched={setTouched}
            />
            <FormInput
              secureTextEntry={hidePassword}
              valueKey={'password'}
              text={translations('password')}
              required={true}
              errors={errors}
              values={values}
              setValues={setValues}
              touched={touched}
              setTouched={setTouched}
              right={
                <TextInput.Icon
                  name={hidePassword ? 'eye-off' : 'eye'}
                  onPress={() => setHidePassword(!hidePassword)}
                  touchSoundDisabled={true}
                />
              }
            />
            <View style={[]}>
              <RHButton mode="contained" onPress={submitForm}>
                LOGIN
              </RHButton>
              <OfflineNotification
                type="text"
                message={translations('offline_required')}
              />
              <View style={[styles.paddingLTop, styles.alignCenter]}>
                <TouchableOpacity onPress={forgotPassword}>
                  <Text
                    style={[
                      Typography.TERTIARY_BUTTON,
                      styles.paddingVertical,
                      styles.textColorPrimary700,
                      styles.textTransformUppercase,
                    ]}>
                    {translations('forgot_password')}
                  </Text>
                </TouchableOpacity>
                <View style={[styles.separator, styles.width]} />
                <View
                  style={[
                    styles.row,
                    styles.alignCenter,
                    styles.paddingVertical,
                  ]}>
                  <Text
                    style={[
                      Typography.TERTIARY_BUTTON,
                      styles.textColorPrimary700,
                      styles.textTransformUppercase,
                    ]}>
                    {translations('auth_form_no_account')}
                  </Text>
                  <TouchableOpacity onPress={signup}>
                    <Text
                      style={[
                        Typography.TERTIARY_BUTTON,
                        styles.paddingMLeft,
                        styles.textAlignCenter,
                        styles.textColorLink,
                        styles.textTransformUppercase,
                      ]}>
                      {translations('auth_form_sign_up')}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={[styles.paddingMTop, styles.alignCenter]}>
                <Text style={[Typography.CAPTION]}>{`V. ${APP_VERSION}`}</Text>
              </View>
            </View>
          </>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
