import React from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';
import {Controller} from 'react-hook-form';
import FormInput from 'src/hook-form/form-input';

interface Props {
  control: any;
}

const SessionNoteReview = ({control}: Props) => {
  const styles = useStyle();

  return (
    <View
      style={[styles.marginHorizontal, styles.card, styles.paddingVertical]}>
      <Text
        style={[Typography.H5, styles.marginHorizontal, styles.marginVertical]}>
        Session Note Review
      </Text>
      <View style={styles.marginHorizontal}>
        <Controller
          control={control}
          render={({field: {onChange, value, ref}}) => (
            <FormInput
              formRef={ref}
              style={[styles.noteInput]}
              value={value}
              onChange={onChange}
              label={'Notes'}
              placeholder={'Please enter notes.'}
              numberOfLines={10}
            />
          )}
          name="sessionNoteReview"
        />
      </View>
    </View>
  );
};

export default SessionNoteReview;
