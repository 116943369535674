import React, {useState} from 'react';
import {memoize} from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';
import {useDimensions} from '@react-native-community/hooks';
import {Text, TouchableOpacity, View} from 'react-native';
import Collapsible from 'react-native-collapsible';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import moment from 'moment';
import {boxShadow} from 'src/styles/mixins';
import {Colors, Typography} from 'src/styles';
import {ProgramMethodAvatar} from 'src/modules/programs/components';
import {Checkbox, Divider, RadioButton} from 'react-native-paper';
import {MoreMenu} from 'src/design-system';
import {Program} from 'src/models';
import Summary from './summary';
import Collection from './collection';
import Measurement from './measurement';
import _ from 'lodash';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';

interface Props {
  program: Program;
  user: any;
  viewCallback: () => void;
  canEdit: boolean;
  editCallback?: () => void;
  canDelete: boolean;
  deleteCallback?: () => void;
  canArchive: boolean;
  archiveCallback?: () => void;
  toggleable?: boolean;
  toggled?: boolean;
  toggleType?: string;
  toggleFunction?: () => void;
}

const ProgramCard = ({
  program,
  // user,
  viewCallback,
  canEdit,
  editCallback = () => {},
  canDelete,
  deleteCallback = () => {},
  canArchive,
  archiveCallback = () => {},
  toggleable,
  toggled,
  toggleType,
  toggleFunction,
}: Props) => {
  const dimensions = useDimensions();

  const [expansionContent, setExpansionContent] = useState<string | null>(null);
  const [collapsed, setCollapsed] = useState(true);
  const user = {
    firstName: 'System',
    lastName: '',
  };
  const styles = getStyles(dimensions.window.width);

  const toggle = (identifier: string) => {
    if (expansionContent === identifier) {
      setExpansionContent(null);
      setCollapsed(true);
    } else {
      setExpansionContent(identifier);
      setCollapsed(false);
    }
  };

  return (
    <View style={[styles.column]}>
      <View style={[styles.card, toggled ? styles.toggled : {}]}>
        <View style={[styles.overflowHidden]}>
          <TouchableOpacity onPress={viewCallback} style={[styles.cardHeader]}>
            <View style={[styles.row, styles.justifyContentSpaceBetween]}>
              <View style={[styles.row, styles.alignItemsCenter, styles.flex]}>
                <ProgramMethodAvatar type={program?.method} />
                <View style={[styles.headerContainer, styles.flexShrink]}>
                  <Text style={[Typography.P2_MEDIUM, styles.flexWrap]}>
                    {program?.name}
                  </Text>
                  <Text style={[styles.subHeaderTxt]}>
                    {program?.type} | {program?.method.split('_').join(' ')}
                  </Text>
                </View>
              </View>

              {toggleable ? (
                toggleType === 'single' ? (
                  <RadioButton.Android
                    value={program.id}
                    uncheckedColor={Colors.PRIMARY_200}
                    color={Colors.BLUE_700}
                    status={toggled ? 'checked' : 'unchecked'}
                    style={styles.cardRadio}
                    onPress={() => toggleFunction(program.id)}
                  />
                ) : (
                  <Checkbox.Android
                    status={toggled ? 'checked' : 'unchecked'}
                    onPress={() => toggleFunction(program.id)}
                    color={Colors.BLUE_700}
                    uncheckedColor={Colors.PRIMARY_200}
                  />
                )
              ) : (
                <MoreMenu
                  canEdit={canEdit}
                  editCallback={editCallback}
                  canDelete={canDelete}
                  deleteCallback={deleteCallback}
                  canArchive={canArchive}
                  archiveTxt={
                    program?.state !== 'archived' ? 'Archive' : 'Unarchive'
                  }
                  archiveCallback={archiveCallback}
                />
              )}
            </View>
            {program?.state === 'archived' ? (
              <>
                <Text style={[Typography.P3_BOLD, styles.cardSpacer]}>
                  Program State
                </Text>
                <Text style={[Typography.P3]}>
                  {_.capitalize(program?.state)}
                </Text>
              </>
            ) : (
              <>
                <Text style={[Typography.P3_BOLD, styles.cardSpacer]}>
                  Created
                </Text>
                <Text style={[Typography.P3]}>
                  {user && user.firstName + ' ' + user.lastName + ' - '}
                  {moment(program?.createdAt).format('MM/DD/YY')}
                </Text>
              </>
            )}
          </TouchableOpacity>
          <View style={styles.cardSpacer} />
          <Divider />
          <View style={[styles.row, styles.justifyContentSpaceBetween]}>
            <TouchableOpacity
              onPress={() => toggle('summary')}
              style={styles.iconButton}>
              <Icon
                size={24}
                name="format-list-bulleted"
                color={
                  expansionContent === 'summary'
                    ? Colors.GRAY_500
                    : Colors.TEXT_PRIMARY
                }
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => toggle('collection')}
              style={styles.iconButton}>
              <Icon
                size={24}
                name="layers"
                color={
                  expansionContent === 'collection'
                    ? Colors.GRAY_500
                    : Colors.TEXT_PRIMARY
                }
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => toggle('measurement')}
              style={styles.iconButton}>
              <Icon
                size={24}
                name="timelapse"
                color={
                  expansionContent === 'measurement'
                    ? Colors.GRAY_500
                    : Colors.TEXT_PRIMARY
                }
              />
            </TouchableOpacity>
          </View>
          <Collapsible collapsed={collapsed} style={styles.expansionPanel}>
            {expansionContent === 'summary' ? (
              <Summary program={program} />
            ) : expansionContent === 'collection' ? (
              <Collection program={program} />
            ) : expansionContent === 'measurement' ? (
              <Measurement program={program} />
            ) : (
              <></>
            )}
          </Collapsible>
        </View>
      </View>
    </View>
  );
};

const getNumColumns = (width: number) => {
  if (width > 1920) {
    return 5;
  } else if (width > 1440) {
    return 4;
  } else if (width > 1024) {
    return 3;
  } else if (width > 768) {
    return 2;
  } else {
    return 1;
  }
};

const getStyles = memoize((width: number) =>
  EStyleSheet.create({
    flex: {
      flex: 1,
    },
    flexShrink: {flexShrink: 1},
    flexWrap: {
      flexWrap: 'wrap',
    },
    column: {
      flex: 1 / getNumColumns(width),
    },
    card: {
      marginTop: 10,
      margin: 20,
      marginBottom: 20,
      backgroundColor: '#ffffff',
      borderRadius: 10,
      ...boxShadow(Colors.RAVEN_BLACK),
    },
    toggled: {
      borderColor: Colors.BLUE_700,
      borderWidth: 1,
    },
    overflowHidden: {
      overflow: 'hidden',
    },
    cardHeader: {
      padding: 20,
      paddingBottom: 10,
    },
    cardSpacer: {
      paddingTop: 20,
    },
    toggleButtonContainer: {
      flex: 1,
      margin: 20,
    },
    toggleButton: {
      width: '100%',
    },
    cardFooter: {
      margin: 20,
    },
    iconButton: {
      padding: 20,
    },
    expansionPanel: {
      paddingHorizontal: 20,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    alignItemsCenter: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    justifyContentSpaceBetween: {
      justifyContent: 'space-between',
    },
    headerContainer: {
      paddingLeft: 12,
      justifyContent: 'center',
    },
    subHeaderTxt: {
      textTransform: 'capitalize',
      color: Colors.TEXT_DISABLED,
      ...Typography.CAPTION,
    },
    programGraph: {
      alignSelf: 'center',
      marginVertical: 30,
    },
    nameGray: {
      flex: 1,
      height: 40,
      marginLeft: 10,
      alignSelf: 'stretch',
    },
    permissionGray: {
      height: 50,
      marginTop: 20,
    },
    grayBack: {
      backgroundColor: Colors.PRIMARY_100,
      borderRadius: 5,
    },
    iconButtonGray: {
      height: 40,
      width: 60,
    },
    '@media (max-width: 768)': {},
  }),
);

export default compose(
  withObservables([], ({program}: any) => ({
    program,
  })),
)(ProgramCard);
