import React, {useState} from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import PaperMenu from 'src/design-system/paper-menu';
import IconMaterial from 'react-native-vector-icons/MaterialCommunityIcons';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import moment from 'moment';
import {of} from 'rxjs';

import {Typography, Colors} from 'src/styles';
import {Prompt} from 'src/models';
import {PromptView} from 'src/modules/session/components';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

const SkillTableTooltip = ({
  type,
  setIndex,
  event,
  task,
  target,
  prompt,
  collector,
  setEventId,
  setTrialCount,
  setCurrentSet,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const [visible, setVisible] = useState(false);

  const openToolTip = () => setVisible(true);

  const closeTooltip = () => setVisible(false);

  if (type === 'task_analysis_edit') {
    return (
      <TouchableOpacity
        onPress={() => {
          setEventId(event.id);
        }}
        style={[styles.justifyCenter, styles.alignCenter]}>
        {event?.value === 'success' ? (
          <IconMaterial
            name="check"
            size={20}
            color={Colors.TERTIARY_SPRING_GREEN}
          />
        ) : (
          <>
            <IconMaterial
              name="close"
              size={20}
              color={Colors.ELECTRIC_SALMON}
            />
            {event?.prompts.length ? (
              <View style={[styles.paddingTop]}>
                {event.prompts.map((promptId: string) => (
                  <PromptView promptId={promptId} />
                ))}
              </View>
            ) : (
              <></>
            )}
          </>
        )}
      </TouchableOpacity>
    );
  } else if (type === 'trial_by_trial_edit') {
    return (
      <TouchableOpacity
        onPress={() => {
          setEventId(event.id);
          setTrialCount(task);
          setCurrentSet(setIndex);
        }}
        style={[styles.justifyCenter, styles.alignCenter]}>
        {event?.value === 'success' ? (
          <IconMaterial
            name="check"
            size={20}
            color={Colors.TERTIARY_SPRING_GREEN}
          />
        ) : (
          <>
            <IconMaterial
              name="close"
              size={20}
              color={Colors.ELECTRIC_SALMON}
            />
            {event?.prompts.length ? (
              <View style={[styles.paddingTop]}>
                {event.prompts.map((promptId: string) => (
                  <PromptView promptId={promptId} />
                ))}
              </View>
            ) : (
              <></>
            )}
          </>
        )}
      </TouchableOpacity>
    );
  } else {
    return (
      <PaperMenu
        visible={visible}
        onDismiss={closeTooltip}
        anchor={
          event?.value === 'success' ? (
            <TouchableOpacity
              onPress={openToolTip}
              style={[styles.justifyCenter, styles.alignCenter]}>
              <IconMaterial
                name="check"
                size={20}
                color={Colors.TERTIARY_SPRING_GREEN}
              />
            </TouchableOpacity>
          ) : event?.value === 'error' ? (
            <TouchableOpacity
              style={[styles.justifyCenter, styles.alignCenter]}
              onPress={openToolTip}>
              <IconMaterial
                name="close"
                size={20}
                color={Colors.ELECTRIC_SALMON}
              />
              {event?.prompts.length ? (
                <View style={[styles.paddingLTop]}>
                  {event.prompts.map((promptId: string) => (
                    <PromptView promptId={promptId} />
                  ))}
                </View>
              ) : (
                <></>
              )}
            </TouchableOpacity>
          ) : (
            <></>
          )
        }>
        <View style={[styles.padding]}>
          <Text style={[Typography.CAPTION_MEDIUM]}>
            {translations('target')}
          </Text>
          <Text style={[Typography.CAPTION]}>{target}</Text>

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
            {translations('set')}
          </Text>
          <Text style={[Typography.CAPTION]}>{setIndex}</Text>

          {collector && (
            <>
              <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
                {translations('collector')}
              </Text>
              <Text
                style={[
                  Typography.CAPTION,
                ]}>{`${collector?.firstName} ${collector?.lastName}`}</Text>
            </>
          )}

          {type === 'task_analysis' ? (
            <>
              <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
                {translations('task')}
              </Text>
              <Text style={[Typography.CAPTION]}>{task}</Text>
            </>
          ) : (
            <>
              <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
                {translations('instance')}
              </Text>
              <Text style={[Typography.CAPTION]}>{task}</Text>
            </>
          )}

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
            {translations('date')}
          </Text>
          <Text style={[Typography.CAPTION]}>
            {moment(event?.createdAt).format('LL')}
          </Text>

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
            {translations('time')}
          </Text>
          <Text style={[Typography.CAPTION]}>
            {moment(event?.createdAt).format('hh:mm a')}
          </Text>

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginLTop]}>
            {translations('value')}
          </Text>
          <Text style={[Typography.CAPTION]}>
            {event?.value === 'success'
              ? translations('correct')
              : translations('incorrect')}
            {event?.value === 'error' && prompt?.acronym
              ? ' (' + prompt?.acronym + ') '
              : ''}
          </Text>
        </View>
      </PaperMenu>
    );
  }
};

export default compose(
  withDatabase,
  withObservables([], ({database, event}: any) => {
    const promptId = event?.prompts.length > 0 ? event?.prompts[0] : undefined;
    const prompt = promptId
      ? database.get(Prompt.table).findAndObserve(promptId)
      : of({});

    const collector =
      event?.createdBy &&
      event?.createdBy !== '' &&
      event?.createdBy !== 'System'
        ? database.get('users').findAndObserve(event.createdBy)
        : of(undefined);
    return {
      prompt,
      collector,
    };
  }),
)(SkillTableTooltip);
