import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import CheckboxInput from 'src/hook-form/checkbox-input';

interface Props {
  style?: any;
  rules?: any;
  search?: boolean;
  name: string;
  required?: boolean;
  items: {label: string; value: string}[];
  allowSelectAll?: boolean;
  showTitle?: boolean;
  itemStyle?: any;
}

const HookFormCheckboxInput = (props: Props) => {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      rules={props.rules}
      render={({field, fieldState: {error}}) => (
        <CheckboxInput {...props} {...field} error={error} />
      )}
      name={props.name}
    />
  );
};

export default HookFormCheckboxInput;
