import React, {useState} from 'react';
import {Platform, ScrollView, View} from 'react-native';
import {useSelector} from 'react-redux';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import Timeline from 'src/design-system/timeline';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {DeleteModal, FormSectionHeader, Modal} from 'src/design-system';
import Form from 'src/hook-form/form';
import * as Yup from 'yup';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import NoResults from 'src/common-components/noResults';
import {Note} from 'src/models';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {FormProvider, useForm} from 'react-hook-form';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {yupResolver} from '@hookform/resolvers/yup';

const PatientNotes = ({notes, patient}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();
  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const [submitting, setSubmitting] = useState(false);
  const [showDelete, setShowDelete] = useState<any | boolean>(false);
  const [edit, setEdit] = useState<any | boolean>(false);

  const methods = useForm({
    defaultValues: {
      description: '',
    },
    resolver: yupResolver(
      Yup.object({
        description: Yup.string().required('Note is required.'),
      }),
    ),
  });

  const handleSubmit = async (payload: any) => {
    if (edit) {
      await edit.updateEntity({
        description: payload.description,
      });
    } else {
      await database.write(async () => {
        await database.get(Note.table).create(entity => {
          entity.partition = selectedGroup;
          entity.entity = 'patient';
          entity.entityId = patient?.id;
          entity.description = payload.description;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
    setSubmitting(false);
    methods.reset({});
    setEdit(false);
  };

  const handleCancel = () => {
    methods.reset({
      description: '',
    });
    setSubmitting(!submitting);
    setEdit(false);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = (item: Note) => {
    item.delete();
    setShowDelete(false);
  };

  return (
    <>
      <View
        style={[
          styles.row,
          styles.justifySpaceBetween,
          styles.alignCenter,
          styles.paddingRight,
          styles.paddingBottom,
        ]}>
        <FormSectionHeader title={translations('client_notes')} />
        <RHButton
          onPress={() => setSubmitting(true)}
          mode={'contained'}
          secondary
          icon="plus">
          {translations('add')}
        </RHButton>
      </View>
      <Modal
        title={edit ? translations('edit_note') : translations('add_note')}
        show={[submitting, handleCancel]}>
        <FormProvider {...methods}>
          <Form
            handleSubmit={() => methods.handleSubmit(handleSubmit)()}
            cancelled={handleCancel}>
            <HookFormInput
              name={'description'}
              label={translations('note')}
              multiline={true}
              numberOfLines={3}
            />
          </Form>
        </FormProvider>
      </Modal>
      <DeleteModal
        model={translations('note')}
        text={`this ${translations('note')}`}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <ScrollView style={[styles.flex]}>
        {notes?.length ? (
          <Timeline
            items={notes}
            canEdit={true} // TODO: change later
            canDelete={true} // TODO: change later
            editItem={(item: any) => {
              if (Platform.OS === 'web') {
                setEdit(item);
              } else {
                setEdit(item.payload);
              }
              methods.setValue('description', item?.description);
              setSubmitting(true);
            }}
            deleteItem={(item: any) => {
              if (Platform.OS === 'web') {
                setShowDelete(item);
              } else {
                setShowDelete(item.payload);
              }
            }}
          />
        ) : (
          <NoResults message={'No Notes Found'} />
        )}
      </ScrollView>
    </>
  );
};

export default compose(
  withObservables([], ({patient}: any) => ({
    notes: patient?.activeNotes ?? of([]),
  })),
)(PatientNotes);
