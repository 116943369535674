import React from 'react';
import CheckboxInput from 'src/hook-form-wrapper/checkbox-input';
import {cptCodes} from '../cpt';

interface Props {
  name: string;
  allowSelectAll: boolean;
  search: boolean;
  valueKey: string;
}

const CptCheckbox = ({
  name,
  allowSelectAll,
  search = false,
  valueKey,
}: Props) => {
  return (
    <CheckboxInput
      name={(name ? name + '.' : '') + 'types'}
      items={cptCodes.map((code: any) => ({
        value: code.value,
        label: valueKey ? `${code[valueKey]}` : `${code.value} - ${code.label}`,
      }))}
      allowSelectAll={allowSelectAll}
      search={search}
    />
  );
};

export default CptCheckbox;
