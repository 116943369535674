import React from 'react';
import {RRule} from 'rrule';

import {SelectInput} from 'src/design-system';

export const repeatFreqArr = [
  {
    label: 'Day',
    value: RRule.DAILY.toString(),
  },
  {
    label: 'Week',
    value: RRule.WEEKLY.toString(),
  },
  {
    label: 'Month',
    value: RRule.MONTHLY.toString(),
  },
];

const RepeatFreq = () => {
  return (
    <SelectInput
      valueKey="repeatFreq"
      name={'Meeting Recurrence'}
      required={true}
      items={repeatFreqArr}
    />
  );
};

export const Input = RepeatFreq;

export default RepeatFreq;
