import {StyleSheet, Text, View} from 'react-native';
import {PromptTooltip} from 'src/modules/session/components';
import React from 'react';
import {Colors} from 'src/styles';

const PromptsDisplay = ({prompts}: any) => {
  return (
    <View style={styles.iconContainer}>
      <PromptTooltip prompts={prompts} />
      <View style={styles.icon}>
        <Text style={styles.iconText}>{prompts.length}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    position: 'absolute',
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    borderColor: Colors.SECONDARY_800,
    borderWidth: 1,
    left: 40,
    bottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconText: {
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.SECONDARY_800,
    fontSize: 10,
  },
});

export default PromptsDisplay;
