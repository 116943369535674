import React from 'react';
import {Caregiver} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';

interface Props {
  override?: string;
  name: string;
  items: any[];
  selectAll?: boolean;
  multiple?: boolean;
}

const CaregiverSelect = ({
  override,
  name,
  items,
  selectAll = false,
  multiple,
}: Props) => {
  return (
    <HookFormSelectInput
      name={override ?? (name ? name + '.' : '') + 'caregiver'}
      label={'Select Caregiver'}
      required={false}
      multiple={multiple}
      selectAll={selectAll}
      items={items}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({patient}: any) => ({
    caregivers: patient.activeCaregivers,
  })),
  withObservables([], ({caregivers}: any) => ({
    items: of(
      caregivers
        .map((caregiver: Caregiver) => {
          return {
            label: `${caregiver.firstName} ${caregiver.lastName}`,
            value: caregiver.id,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    ),
  })),
)(CaregiverSelect);
