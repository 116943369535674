import React, {useState} from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import PaperMenu from 'src/design-system/paper-menu';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import moment from 'moment';
import {of} from 'rxjs';
import _ from 'lodash';
import {Typography} from 'src/styles';
import {Prompt} from 'src/models';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

const DurationTableTooltip = ({
  method,
  promptItems,
  set,
  instance,
  intensity,
  events,
  collector,
  unit,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const promptTypes = _.groupBy(promptItems, 'promptType');
  const [visible, setVisible] = useState(false);
  const openToolTip = () => setVisible(true);
  const closeTooltip = () => setVisible(false);

  const duration = moment.duration(
    moment(set?.endTimestamp).diff(moment(set?.startTimestamp)),
  );
  const durationAsMinutes = duration.asMinutes();
  const min = duration.minutes();
  const sec = duration.seconds();

  let denominator = durationAsMinutes;

  switch (unit) {
    case 'per_min':
      denominator = durationAsMinutes;
      break;
    case 'per_hour':
      denominator = durationAsMinutes / 60;
      break;
    case 'per_session':
      denominator = 1;
      break;
  }

  return (
    <PaperMenu
      visible={visible}
      onDismiss={closeTooltip}
      anchor={
        method !== 'frequency' ? (
          <TouchableOpacity
            onPress={openToolTip}
            style={[styles.justifyCenter, styles.alignCenter]}>
            <Text style={[Typography.CAPTION_MEDIUM]}>
              {moment(set?.startTimestamp).format('ll')}
            </Text>
          </TouchableOpacity>
        ) : (
          <></>
        )
      }>
      <View style={styles.paddingM}>
        <Text style={[Typography.CAPTION_MEDIUM]}>{translations('date')}</Text>
        <Text style={Typography.CAPTION}>
          {moment(set?.startTimestamp).format('LL')}
        </Text>

        <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
          {translations('instance')}
        </Text>
        <Text style={Typography.CAPTION}>{instance}</Text>

        {collector && (
          <>
            <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
              {translations('collector')}
            </Text>
            <Text
              style={
                Typography.CAPTION
              }>{`${collector?.firstName} ${collector?.lastName}`}</Text>
          </>
        )}

        <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
          {translations('start_time')}
        </Text>
        <Text style={Typography.CAPTION}>
          {moment(set?.startTimestamp).format('hh:mm:ss A')}
        </Text>

        <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
          {translations('end_time')}
        </Text>
        <Text style={Typography.CAPTION}>
          {moment(set?.endTimestamp).format('hh:mm:ss A')}
        </Text>

        {(method === 'duration' || method === 'rate') && (
          <>
            <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
              {translations('total_duration')}
            </Text>
            <Text style={Typography.CAPTION}>
              {min > 0 && min + ' min '}
              {sec > 0 && sec + ' sec'}
            </Text>
          </>
        )}

        {method === 'rate' && (
          <>
            <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
              {translations('count')}
            </Text>
            <Text style={Typography.CAPTION}>{events?.length} Times</Text>

            <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
              {translations('rate')}
            </Text>
            <Text style={Typography.CAPTION}>
              {denominator !== 0
                ? (events?.length / denominator).toFixed(2)
                : 0.01}{' '}
            </Text>
          </>
        )}

        {method === 'interval' && (
          <>
            <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
              {translations('behavior_occurrence')}
            </Text>
            <Text style={Typography.CAPTION}>
              {events[0]?.value === 'Y'
                ? 'Yes'
                : events[0]?.value === 'N'
                ? 'No'
                : ''}
            </Text>
          </>
        )}

        {intensity !== '' && (
          <>
            <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
              {translations('intensity')}
            </Text>
            <Text style={Typography.CAPTION}>{intensity}</Text>
          </>
        )}
        {promptItems?.length > 0 ? (
          <>
            <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
              {translations('abc')}
            </Text>
            {Object.keys(promptTypes).map((promptType: string) => {
              return (
                <View key={`promptType-key-${promptType}`}>
                  <Text
                    style={(Typography.CAPTION_MEDIUM, styles.marginSMBottom)}>
                    {promptType}
                  </Text>
                  <View>
                    {promptTypes[promptType].map((prompt: any) => (
                      <Text
                        style={Typography.CAPTION}
                        key={`prompt-${prompt?.id}`}>
                        {prompt?.name}
                      </Text>
                    ))}
                  </View>
                </View>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </View>
    </PaperMenu>
  );
};

export default compose(
  withDatabase,
  withObservables(['set'], ({database, set}: any) => {
    const collector =
      set?.createdBy && set?.createdBy !== '' && set?.createdBy !== 'System'
        ? database.get('users').findAndObserve(set.createdBy)
        : of(undefined);
    return {
      promptItems: set?.prompts
        ? database.get(Prompt.table).query(Q.where('id', Q.oneOf(set?.prompts)))
        : of([]),
      events: set?.events,
      collector,
    };
  }),
)(DurationTableTooltip);
