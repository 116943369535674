import React, {useState} from 'react';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {Display as ClientFormDisplay} from 'src/modules/patients/components/client-form';
import {Display as CaregiverFormDisplay} from 'src/modules/patients/components/caregiver-form';
import {Display as MedicalFormDisplay} from 'src/modules/patients/components/medical-form';
import {Display as ClinicalFormDisplay} from 'src/modules/patients/components/clinical-form';
import {Display as InsuranceFormDisplay} from 'src/modules/billing/components/insurance-form';
import PatientNotes from 'src/modules/patients/components/patient-notes';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {mergeMap, of} from 'rxjs';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {RetractingDrawer} from 'src/design-system';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {Colors, Typography} from 'src/styles';
import {useDimensions} from '@react-native-community/hooks';
import withState from 'src/redux/wrapper';
import {Q} from '@nozbe/watermelondb';
import {Instance} from 'src/models';

function PatientDetails({
  instance,
  patient,
  caregivers,
  canEdit,
  navigateToUpsert,
  diagnoses,
  medications,
  insurances,
  primary,
  referring,
  tags,
}: any) {
  const dimensions = useDimensions();
  const styles = useStyle();

  const tabs: any[] = [
    {type: 'local', icon: 'person', value: 'client', title: 'Client'},
    {type: 'local', icon: 'home', value: 'caregiver', title: 'Caregiver'},
    {type: 'local', icon: 'note', value: 'notes', title: 'Notes'},
    {
      type: 'local',
      icon: 'medical-services',
      value: 'medical',
      title: 'Medical',
    },
    {
      type: 'local',
      icon: 'badge',
      value: 'clinical',
      title: 'Clinical',
    },
  ];

  if (instance.billing) {
    tabs.push({
      type: 'local',
      icon: 'featured-play-list',
      value: 'insurance',
      title: 'Insurance',
    });
  }

  if (canEdit) {
    tabs.unshift({type: 'separator', value: 'separator'});
    tabs.unshift({
      type: 'navigation',
      icon: 'edit',
      value: 'edit',
      title: 'Edit',
    });
  }
  const [selectedTab, setSelectedTab] = useState<string>(tabs[2].value);

  const tabSelected = (tab: any) => {
    if (tab.type === 'local') {
      setSelectedTab(tab.value);
    } else {
      navigateToUpsert();
    }
  };

  return (
    <View style={[styles.row, styles.flex, styles.width]}>
      <RetractingDrawer
        type={dimensions.window.width > 1024 ? 'persistent' : 'overlay'}
        position={'left'}>
        {tabs.map((tab: any) => {
          if (tab.type === 'separator') {
            return <View key={tab.value} style={[styles.separator]} />;
          }
          return (
            <TouchableOpacity key={tab.value} onPress={() => tabSelected(tab)}>
              <View
                style={[
                  styles.marginMVertical,
                  styles.row,
                  styles.alignCenter,
                  styles.borderRadiusSM,
                  styles.overflowHidden,
                ]}>
                <View style={[styles.positionRelative, styles.paddingM]}>
                  {tab.notifications ? (
                    <View style={[styles.circle]}>
                      <Text
                        style={[
                          styles.alignCenter,
                          styles.alignSelfCenter,
                          styles.textColorWhite,
                        ]}>
                        {tab.notifications >= 9 ? '9+' : tab.notifications}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                  <Icon name={tab.icon} size={20} color={Colors.PRIMARY_700} />
                </View>
                <Text
                  style={[
                    Typography.CAPTION_MEDIUM,
                    styles.textColorPrimary700,
                    styles.marginMLeft,
                    styles.marginSMTop,
                  ]}
                  numberOfLines={1}>
                  {tab.title}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </RetractingDrawer>
      <ScrollView
        style={[styles.column, styles.flex, styles.width, styles.padding]}>
        {selectedTab === 'client' ? (
          <ClientFormDisplay
            {...{
              identifier: patient?.identifier || '',
              firstName: patient?.firstName || '',
              middleName: patient?.middleName || '',
              lastName: patient?.lastName || '',
              patientId: patient?.identifier ?? (patient?.id || ''),
              gender: patient?.gender || '',
              address: patient?.address || {},
              birthDate: patient?.birthDate || '',
              ssn: patient?.ssn || '',
              contact: caregivers[0] || {},
              tags: tags.map((tag: any) => tag?.id) || [],
            }}
          />
        ) : null}
        {selectedTab === 'caregiver' ? (
          <CaregiverFormDisplay caregivers={caregivers} />
        ) : null}
        {selectedTab === 'notes' ? <PatientNotes patient={patient} /> : null}
        {selectedTab === 'medical' ? (
          <MedicalFormDisplay
            {...{
              medications,
              diagnoses,
            }}
          />
        ) : null}
        {selectedTab === 'clinical' ? (
          <ClinicalFormDisplay
            {...{
              intakeDate: patient.intakeDate,
              sameAsPrimary: patient.sameAsPrimary,
            }}
            staffId={patient.staffId}
            primary={{
              firstName: primary?.firstName,
              lastName: primary?.lastName,
              mobilePhone: primary?.mobilePhone,
              email: primary?.email,
              address: primary?.address,
            }}
            referring={{
              firstName: referring?.firstName,
              lastName: referring?.lastName,
              mobilePhone: referring?.mobilePhone,
              email: referring?.email,
              address: referring?.address,
            }}
          />
        ) : null}
        {selectedTab === 'insurance' ? (
          <InsuranceFormDisplay insurances={insurances} />
        ) : null}
      </ScrollView>
    </View>
  );
}

export default compose(
  withDatabase,
  withState,
  withObservables([], ({authentication, database}: any) => ({
    instance: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
  withObservables(['patient', 'route'], ({patient}) => {
    if (patient.id) {
      return {
        caregivers: patient.activeCaregivers,
        medications: patient.activeMedications,
        diagnoses: patient.activeDiagnoses,
        insurances: patient.activeInsurances,
        primary: patient.primary,
        referring: patient.referring,
        tags: patient.activeTags,
      };
    }
    return {
      caregivers: of([]),
      medications: of([]),
      diagnoses: of([]),
      insurances: of([]),
      primary: of({}),
      referring: of({}),
      tags: of({}),
    };
  }),
)(PatientDetails);
