import React from 'react';
import {View, Text} from 'react-native';
import {Controller, useWatch} from 'react-hook-form';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import {Typography} from 'src/styles';
import Collapsible from 'react-native-collapsible';
import SwitchInput from 'src/hook-form/switch-input';
import {useTranslations} from 'src/providers/translation';
import {
  Adjustment,
  Cadence,
  ConsecutiveSessions,
  DayOfWeek,
  NumberOfInstances,
} from 'src/hook-form-inputs';
import {useStyle} from 'src/providers/style';
import Picker from 'src/hook-form/number-picker';
import {numberRange} from 'src/common-utils/nuberRange';

interface Props {
  control: any;
  edit: boolean;
  values: any;
}

const MeasurementFrequency = ({control, edit, values}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();
  const baseline = useWatch({control, name: 'baseline'});
  const maintenance = useWatch({control, name: 'maintenance'});
  const masteryStaff = useWatch({control, name: 'masteryStaff'});
  const maintenanceCadence = useWatch({control, name: 'maintenanceCadence'});

  return (
    <>
      <Text style={Typography.H4}>{translations('measurement')}</Text>
      <RHSeparator height={20} />
      <View
        style={[
          styles.width,
          styles.border,
          styles.borderRadiusSM,
          styles.borderColorPrimary300,
          styles.padding,
        ]}>
        <View
          style={[
            styles.container,
            styles.flexWrap,
            styles.justifySpaceBetween,
            styles.alignCenter,
          ]}>
          <View>
            <Text style={[Typography.H5, styles.textColorBlack]}>
              {translations('behavior_adjustment')}
            </Text>
            <Text style={[Typography.P3, styles.textColorBlack]}>
              {translations('rate_adjustment')}
            </Text>
          </View>
        </View>
        <View style={styles.paddingTop}>
          {edit ? (
            <Adjustment.Input type={'adjustment'} />
          ) : (
            <Adjustment.Display value={values?.adjustment} />
          )}
        </View>
      </View>
      <RHSeparator height={20} />
      <View
        style={[
          styles.width,
          styles.overflowHidden,
          styles.border,
          styles.borderRadiusSM,
          styles.borderColorPrimary300,
          styles.padding,
        ]}>
        {edit ? (
          <Controller
            control={control}
            render={({field: {onChange, value}}) => (
              <SwitchInput
                label={'Baseline'}
                description={'Frequency of a behavior prior to intervention.'}
                value={value}
                onChange={onChange}
              />
            )}
            name="baseline"
            defaultValue={baseline}
          />
        ) : (
          <View>
            <Text style={[Typography.H5, styles.textColorBlack]}>
              {translations('baseline')}
            </Text>
            <Text style={[Typography.P3, styles.textColorBlack]}>
              {translations('frequency_baseline')}
            </Text>
          </View>
        )}
        <Collapsible style={styles.paddingTop} collapsed={!baseline}>
          {edit ? (
            <NumberOfInstances.Input type={'baselineProbes'} />
          ) : (
            <NumberOfInstances.Display value={values?.baselineProbes} />
          )}

          <RHSeparator height={20} />
          {edit ? (
            <ConsecutiveSessions.Input type={'baselineSessions'} />
          ) : (
            <ConsecutiveSessions.Display value={values?.baselineSessions} />
          )}
        </Collapsible>
      </View>

      <RHSeparator height={20} />
      <View
        style={[
          styles.width,
          styles.border,
          styles.borderRadiusSM,
          styles.borderColorPrimary300,
          styles.padding,
        ]}>
        <View
          style={[
            styles.container,
            styles.flexWrap,
            styles.justifySpaceBetween,
            styles.alignCenter,
          ]}>
          <View>
            <Text style={[Typography.H5, styles.textColorBlack]}>
              {translations('mastery_criteria')}
            </Text>
            <Text style={[Typography.P3, styles.textColorBlack]}>
              {translations('number_of_occurrences')}
            </Text>
          </View>
        </View>

        <View style={styles.paddingTop}>
          {edit ? (
            <NumberOfInstances.Input type={'masteryValue'} />
          ) : (
            <NumberOfInstances.Display value={values?.masteryValue} />
          )}

          <RHSeparator height={20} />
          {edit ? (
            <ConsecutiveSessions.Input type={'masterySessions'} />
          ) : (
            <ConsecutiveSessions.Display value={values?.masterySessions} />
          )}

          <RHSeparator height={20} />
          {edit ? (
            <Controller
              control={control}
              render={({field: {onChange, value}}) => (
                <SwitchInput
                  label={'Staff Minimum'}
                  description={
                    'Members needed to collect and verify before mastery.'
                  }
                  value={value}
                  onChange={onChange}
                />
              )}
              name={'masteryStaff'}
              defaultValue={values?.masteryStaff ?? false}
            />
          ) : masteryStaff ? (
            <Text style={[Typography.H6, styles.textColorBlack]}>
              {translations('staff_min')}
            </Text>
          ) : (
            <></>
          )}

          {masteryStaff ? (
            <>
              {edit ? (
                <Controller
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Picker
                      items={numberRange(2, 5)}
                      selected={value}
                      setSelected={onChange}
                      value="masteryStaffMinimum"
                    />
                  )}
                  name="masteryStaffMinimum"
                  defaultValue={values?.masteryStaffMinimum ?? 2}
                />
              ) : (
                <Text style={Typography.P3}>{values?.masteryStaffMinimum}</Text>
              )}
            </>
          ) : (
            <></>
          )}
        </View>
      </View>

      <RHSeparator height={20} />
      <View
        style={[
          styles.width,
          styles.border,
          styles.borderRadiusSM,
          styles.borderColorPrimary300,
          styles.padding,
        ]}>
        {edit ? (
          <Controller
            control={control}
            render={({field: {onChange, value}}) => (
              <SwitchInput
                label={'Maintenance Criteria'}
                description={
                  'Prompted to confirm the behavior did not occur over mastery threshold.'
                }
                value={value}
                onChange={onChange}
              />
            )}
            name="maintenance"
            defaultValue={values?.maintenance ?? false}
          />
        ) : (
          <View>
            <Text style={[Typography.H5, styles.textColorBlack]}>
              {translations('maintenance_criteria')}
            </Text>
            <Text style={[Typography.P3, styles.textColorBlack]}>
              {translations('mastered_targets')}
            </Text>
          </View>
        )}

        <Collapsible style={styles.paddingTop} collapsed={!maintenance}>
          {edit ? (
            <NumberOfInstances.Input type={'maintenanceValue'} />
          ) : (
            <NumberOfInstances.Display value={values?.maintenanceValue} />
          )}

          {edit && maintenanceCadence !== 'daily' ? (
            <ConsecutiveSessions.Input type={'maintenanceSessions'} />
          ) : maintenanceCadence !== 'daily' ? (
            <ConsecutiveSessions.Display value={values?.maintenanceSessions} />
          ) : null}
          {edit ? (
            <>
              <Cadence.Input />
              {maintenanceCadence === 'weekly' ? (
                <DayOfWeek.Input type={'maintenanceCadenceWeekly'} />
              ) : maintenanceCadence === 'monthly' ? (
                <>
                  <Text style={Typography.P3}>
                    {translations('day_of_month')}
                  </Text>
                  <Controller
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <Picker
                        items={numberRange(1, 31)}
                        selected={value}
                        setSelected={onChange}
                        value="maintenanceCadenceMonthly"
                      />
                    )}
                    name="maintenanceCadenceMonthly"
                    defaultValue={values?.maintenanceCadenceMonthly ?? 1}
                  />
                </>
              ) : null}
            </>
          ) : (
            <Cadence.Display
              value={values?.maintenanceCadence}
              month={values?.maintenanceCadenceMonthly}
              day={values?.maintenanceCadenceWeekly}
            />
          )}
          <RHSeparator height={20} />
          {edit ? (
            <>
              <Text style={[Typography.P3_BOLD, styles.textColorPrimary]}>
                Allowance of Fails
              </Text>
              <Controller
                control={control}
                render={({field: {onChange, value}}) => (
                  <Picker
                    items={numberRange(0, 5)}
                    selected={value}
                    setSelected={onChange}
                    value="maintenanceAllowance"
                  />
                )}
                name="maintenanceAllowance"
                defaultValue={values?.maintenanceAllowance ?? 1}
              />
            </>
          ) : null}
        </Collapsible>
      </View>
    </>
  );
};

export default MeasurementFrequency;
