import React from 'react';
import {Platform, ScrollView, Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {Typography} from 'src/styles';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import {useFormContext, useWatch} from 'react-hook-form';
import Collapsible from 'react-native-collapsible';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {Tooltip} from 'src/design-system';
import Icon from 'react-native-vector-icons/MaterialIcons';
import FormBuilder from 'src/modules/form-builder/screens/form-builder-screen';

const isWeb = Platform.OS === 'web';

const EditNoteTemplateForm = () => {
  const styles = useStyle();
  const translations = useTranslations();
  const {control} = useFormContext();

  const showOrganizationInfo = useWatch({
    control,
    name: 'organizationInformation',
  });
  const showClientInfo = useWatch({
    control,
    name: 'clientInformation',
  });
  const showSessionInfo = useWatch({
    control,
    name: 'sessionInformation',
  });
  const showClinicalNote = useWatch({
    control,
    name: 'clinicalNote',
  });
  const showSessionData = useWatch({
    control,
    name: 'sessionData',
  });
  const showAdditionalFields = useWatch({
    control,
    name: 'additionalFields',
  });

  return (
    <>
      <Text
        style={[
          Typography.H4,
          styles.textColorPrimary,
          styles.paddingLeft,
          styles.paddingTop,
          styles.paddingRight,
        ]}>
        {translations('edit_note_template')}
      </Text>
      <Text style={[Typography.P3, styles.textColorPrimary, styles.padding]}>
        Enter in a template name and toggle on the features to display for any
        sessions using this note template. The Save As Draft option exists if
        this template is still in progress and not ready to be displayed as an
        option for sessions. When all options are suitable for this note
        template, select the Next option to review the session note template
        with mock data. To make this session note template available for
        sessions, select the Publish button from the Review screen.{' '}
      </Text>
      <View style={[styles.paddingHorizontal]}>
        <HookFormInput
          name={'title'}
          label={'Note Template Title'}
          required={true}
        />
      </View>
      <ScrollView style={[styles.flex, styles.width, styles.paddingHorizontal]}>
        <View
          style={[
            styles.marginLVertical,
            styles.cardFlat,
            styles.padding,
            styles.borderRadius,
          ]}>
          <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
            <HookFormSwitchInput
              name={'organizationInformation'}
              label={translations('organization')}
            />
          </View>
          <Collapsible
            collapsed={!showOrganizationInfo}
            style={[styles.paddingLeft]}>
            <HookFormSwitchInput
              name={'organizationName'}
              label={'Organization Name'}
            />
            <HookFormSwitchInput name={'organizationLogo'} label={'Logo'} />
            <HookFormSwitchInput
              name={'organizationAddress'}
              label={'Address'}
            />
            <HookFormSwitchInput name={'organizationTin'} label={'TIN'} />
            <HookFormSwitchInput name={'organizationNpi'} label={'NPI'} />
            <HookFormSwitchInput
              name={'organizationBillingAddress'}
              label={'Billing Address'}
            />
          </Collapsible>
        </View>
        <View
          style={[
            styles.marginLVertical,
            styles.cardFlat,
            styles.padding,
            styles.borderRadius,
          ]}>
          <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
            <HookFormSwitchInput
              name={'clientInformation'}
              label={translations('client_information')}
            />
          </View>
          <Collapsible collapsed={!showClientInfo} style={[styles.paddingLeft]}>
            <HookFormSwitchInput
              name={'clientFirstName'}
              label={'First Name'}
            />
            <HookFormSwitchInput
              name={'clientMiddleName'}
              label={'Middle Name'}
            />
            <HookFormSwitchInput name={'clientLastName'} label={'Last Name'} />
            <HookFormSwitchInput name={'clientGender'} label={'Gender'} />
            <HookFormSwitchInput
              name={'clientBirthDate'}
              label={'Birth Date'}
            />
            <HookFormSwitchInput
              name={'clientAgeInMonths'}
              label={translations('age_in_months')}
            />
            <HookFormSwitchInput
              name={'clientSsn'}
              label={'Social Security Number'}
            />
            <HookFormSwitchInput name={'clientDiagnoses'} label={'Diagnoses'} />

            <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
              <HookFormSwitchInput
                name={'primaryMemberId'}
                label={'Primary Member ID'}
              />
              <Tooltip
                anchor={
                  <Icon
                    name={'info'}
                    style={[styles.marginBottom, styles.paddingBottom]}
                  />
                }>
                <Text style={[Typography.P3]}>
                  Applicable to Raven Billing users only
                </Text>
              </Tooltip>
            </View>
            <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
              <HookFormSwitchInput
                name={'secondaryMemberId'}
                label={'Secondary Member ID'}
              />
              <Tooltip
                anchor={
                  <Icon
                    name={'info'}
                    style={[styles.marginBottom, styles.paddingBottom]}
                  />
                }>
                <Text style={[Typography.P3]}>
                  Applicable to Raven Billing users only
                </Text>
              </Tooltip>
            </View>

            <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
              <HookFormSwitchInput
                name={'clientAssignedStaff'}
                label={'Assigned Staff'}
              />
              <Tooltip
                anchor={
                  <Icon
                    name={'info'}
                    style={[styles.marginBottom, styles.paddingBottom]}
                  />
                }>
                <Text style={[Typography.P3]}>
                  Displays all staff assigned to the client
                </Text>
              </Tooltip>
            </View>
          </Collapsible>
        </View>
        <View
          style={[
            styles.marginLVertical,
            styles.cardFlat,
            styles.padding,
            styles.borderRadius,
          ]}>
          <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
            <HookFormSwitchInput
              name={'sessionInformation'}
              label={translations('session_information')}
            />
          </View>
          <Collapsible
            collapsed={!showSessionInfo}
            style={[styles.paddingLeft]}>
            <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
              <HookFormSwitchInput
                name={'sessionStaff'}
                label={'Session Staff'}
              />
              <Tooltip
                anchor={
                  <Icon
                    name={'info'}
                    style={[styles.marginBottom, styles.paddingBottom]}
                  />
                }>
                <Text style={[Typography.P3]}>
                  Displays all staff assigned to the session
                </Text>
              </Tooltip>
            </View>
            <View style={[styles.paddingLeft]}>
              <HookFormSwitchInput name={'staffNpi'} label={'Staff NPI'} />
              <HookFormSwitchInput
                name={'staffLicenseNumber'}
                label={'Staff License #'}
              />
            </View>
            <HookFormSwitchInput
              name={'sessionUnitsOfService'}
              label={'Units of Service'}
            />
            <HookFormSwitchInput
              name={'sessionDateOfService'}
              label={'Date of Service'}
            />
            <HookFormSwitchInput
              name={'sessionStartTime'}
              label={'Start Time'}
            />
            <HookFormSwitchInput name={'sessionEndTime'} label={'End Time'} />
            <HookFormSwitchInput name={'sessionDuration'} label={'Duration'} />

            <HookFormSwitchInput
              name={'sessionHourFormat'}
              label={'24 Hour Format'}
            />
            <HookFormSwitchInput name={'sessionType'} label={'Session Type'} />
            <HookFormSwitchInput
              name={'sessionLocation'}
              label={translations('place_of_service')}
            />
            <HookFormSwitchInput
              name={'sessionAddress'}
              label={translations('address')}
            />
          </Collapsible>
        </View>
        <View
          style={[
            styles.marginLVertical,
            styles.cardFlat,
            styles.padding,
            styles.borderRadius,
          ]}>
          <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
            <HookFormSwitchInput
              name={'clinicalNote'}
              label={translations('clinical_note')}
            />
          </View>
          <Collapsible
            collapsed={!showClinicalNote}
            style={[styles.paddingLeft]}>
            <View
              style={[
                styles.padding,
                styles.borderRadius,
                styles.marginLVertical,
                styles.backgroundColorPrimary50,
              ]}>
              <Text style={[Typography.P3_BOLD]}>List of usable @commands</Text>
              <View style={[styles.row, styles.marginLVertical]}>
                <View style={styles.marginRight}>
                  <Text style={[Typography.P3, styles.textColorPrimary700]}>
                    @session.type
                  </Text>
                  <Text style={[Typography.P3, styles.textColorPrimary700]}>
                    @appointment.location
                  </Text>
                  <Text style={[Typography.P3, styles.textColorPrimary700]}>
                    @appointment.date
                  </Text>
                  <Text style={[Typography.P3, styles.textColorPrimary700]}>
                    @staffmember.name
                  </Text>
                </View>
                <View>
                  <Text style={[Typography.P3, styles.textColorPrimary700]}>
                    @appointment.startTime
                  </Text>
                  <Text style={[Typography.P3, styles.textColorPrimary700]}>
                    @appointment.endTime
                  </Text>
                  <Text style={[Typography.P3, styles.textColorPrimary700]}>
                    @appointment.startTime24hr
                  </Text>
                  <Text style={[Typography.P3, styles.textColorPrimary700]}>
                    @appointment.endTime24hr
                  </Text>
                </View>
              </View>

              <Text style={Typography.P3}>
                <Text style={Typography.P3_BOLD}>Example: </Text>“@session.type
                on @appointment.date started at @appointment.startTime with
                @staffmember.name” equates to “Direct 1:1 on January 27, 2020
                started at 1:00pm with Amy Kelly”.
              </Text>
            </View>
            <HookFormInput name={'template'} label={''} numberOfLines={5} />
            <HookFormSwitchInput
              name={'showAuthorsAndTimestamps'}
              label={translations('show_authors_and_timestamps')}
            />
          </Collapsible>
        </View>
        <View
          style={[
            styles.marginLVertical,
            styles.cardFlat,
            styles.padding,
            styles.borderRadius,
          ]}>
          <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
            <HookFormSwitchInput
              name={'sessionData'}
              label={translations('session_data')}
            />
          </View>
          <Collapsible
            collapsed={!showSessionData}
            style={[styles.paddingLeft]}>
            <HookFormSwitchInput name={'skill'} label={'Skill Acquisition '} />
            <HookFormSwitchInput name={'behavior'} label={'Behavior'} />
          </Collapsible>
        </View>
        {isWeb ? (
          <View
            style={[
              styles.marginLVertical,
              styles.cardFlat,
              styles.padding,
              styles.borderRadius,
            ]}>
            <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
              <HookFormSwitchInput
                name={'additionalFields'}
                label={translations('add_additional_fields')}
              />
            </View>
            <Collapsible collapsed={!showAdditionalFields} style={[]}>
              <FormBuilder name={'formBuilder'} />
            </Collapsible>
          </View>
        ) : null}
        <View
          style={[
            styles.marginLVertical,
            styles.cardFlat,
            styles.padding,
            styles.borderRadius,
          ]}>
          <View style={[styles.row, styles.alignCenter, styles.marginTop]}>
            <HookFormSwitchInput
              name={'signature'}
              label={translations('sigs')}
              isDisabled={true}
            />
            <Tooltip
              anchor={
                <Icon
                  name={'info'}
                  style={[styles.marginBottom, styles.paddingBottom]}
                />
              }>
              <Text style={[Typography.P3]}>
                The Signatures section will always be visible as it contains the
                required signatures for a session.
              </Text>
            </Tooltip>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default EditNoteTemplateForm;
