import React from 'react';
import InsuranceForm from 'src/modules/billing/components/insurance-form';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';

const InsuranceFormWrapper = () => {
  const styles = useStyle();

  return (
    <View style={[styles.paddingHorizontal]}>
      <InsuranceForm />
    </View>
  );
};

export default InsuranceFormWrapper;
