import React from 'react';
import {RRule} from 'rrule';
import HookFormToggleInput from 'src/hook-form-wrapper/toggle-input';

const items = [
  {value: RRule.SU, label: 'S'},
  {value: RRule.MO, label: 'M'},
  {value: RRule.TU, label: 'T'},
  {value: RRule.WE, label: 'W'},
  {value: RRule.TH, label: 'Th'},
  {value: RRule.FR, label: 'F'},
  {value: RRule.SA, label: 'S'},
];

const RepeatByWeekDayInput = () => {
  return (
    <HookFormToggleInput
      name={'repeatByWeekDay'}
      label={'Repeat On'}
      items={items}
    />
  );
};

export const Input = RepeatByWeekDayInput;

export default RepeatByWeekDayInput;
