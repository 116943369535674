import React from 'react';
import {DataItem, SelectInput} from 'src/design-system';
import {relationshipList} from 'src/common-utils/commonPatientData';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const Import = (
  key: string = 'text',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Relationship',
  key,
  type: 'select',
  selectOptions: relationshipList,
  validators: [],
});

const resolveDisplayValue = (value: string) => {
  return relationshipList.find(item => item.value === value)?.label;
};

const RelationshipDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Relationship</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const RelationshipInput = ({valueKey}: Props) => {
  return (
    <SelectInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'text'}
      name={'Relationship'}
      required={true}
      items={relationshipList}
    />
  );
};

export const validation = () =>
  Yup.string().required('Relationship is required');

export const Display = RelationshipDisplay;
export const Input = RelationshipInput;
export const Value = resolveDisplayValue;

export default RelationshipInput;
