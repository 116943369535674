import React from 'react';
import * as Yup from 'yup';
import {PhoneNumberType, PhoneNumber} from 'src/form-inputs';

interface DisplayProps {
  value: {use: string; value: string};
}

interface Props {
  valueKey: string;
}

const resolveDisplayValue = (value: string) => {
  return value;
};

const ContactPointDisplay = ({value}: DisplayProps) => {
  return (
    <>
      <PhoneNumberType.Display value={value.use} />
      <PhoneNumber.Display value={value.value} />
    </>
  );
};

const ContactPointInput = ({valueKey}: Props) => {
  return (
    <>
      <PhoneNumberType.Input
        valueKey={(valueKey ? valueKey + '.' : '') + 'contactPoint'}
      />
      <PhoneNumber.Input
        valueKey={(valueKey ? valueKey + '.' : '') + 'contactPoint'}
      />
    </>
  );
};

export const validation = () =>
  Yup.object({
    use: PhoneNumberType.validation(),
    value: PhoneNumber.validation(),
  });

export const Display = ContactPointDisplay;
export const Input = ContactPointInput;
export const Value = resolveDisplayValue;

export default ContactPointInput;
