import React, {useEffect, useState} from 'react';
import {ScrollView, Text, View} from 'react-native';
import {FormProvider, useForm} from 'react-hook-form';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import EditNoteTemplateForm from '../../components/edit-note-template-form';
import ReviewTemplateForm from '../../components/review-template-form';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {of} from 'rxjs';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {BaseScreen, Modal} from 'src/design-system';
import {useNavigation} from '@react-navigation/native';
import {Q} from '@nozbe/watermelondb';
import {mergeMap} from 'rxjs';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import Stepper from 'src/design-system/stepper';
import BackButton from 'src/navigation/components/back-button';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import {NoteTemplateVersion} from 'src/models';

const EditTemplateScreen = ({selectedTemplate, organization}: any) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const translations = useTranslations();
  const database = useDatabase();
  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [currentStep, setCurrentStep] = useState(0);
  const [confirmation, setConfirmation] = useState(false);

  const changeTitle = () => {
    navigation.setOptions({
      // eslint-disable-next-line react/no-unstable-nested-components
      headerLeft: () => <BackButton customPress={() => navigateBack(true)} />,
    });
  };

  useEffect(() => {
    changeTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm({
    defaultValues: {
      title: selectedTemplate.title || '',
      template: selectedTemplate.template,
      organizationInformation: selectedTemplate.organizationInformation,
      organizationName: selectedTemplate.organizationName,
      organizationLogo: selectedTemplate.organizationLogo,
      organizationAddress: selectedTemplate.organizationAddress,
      organizationBillingAddress: selectedTemplate.organizationBillingAddress,
      organizationTin: selectedTemplate.organizationTin,
      organizationNpi: selectedTemplate.organizationNpi,
      clientInformation: selectedTemplate.clientInformation,
      clientFirstName: selectedTemplate.clientFirstName,
      clientMiddleName: selectedTemplate.clientMiddleName,
      clientLastName: selectedTemplate.clientLastName,
      clientGender: selectedTemplate.clientGender,
      clientBirthDate: selectedTemplate.clientBirthDate,
      clientAgeInMonths: selectedTemplate.clientAgeInMonths,
      clientDiagnoses: selectedTemplate.clientDiagnoses,
      clientSsn: selectedTemplate.clientSsn,
      clientAssignedStaff: selectedTemplate.clientAssignedStaff,
      primaryMemberId: selectedTemplate.primaryMemberId,
      secondaryMemberId: selectedTemplate.secondaryMemberId,
      sessionInformation: selectedTemplate.sessionInformation,
      sessionStaff: selectedTemplate.sessionStaff,
      staffNpi: selectedTemplate.staffNpi,
      staffLicenseNumber: selectedTemplate.staffLicenseNumber,
      sessionDateOfService: selectedTemplate.sessionDateOfService,
      sessionUnitsOfService: selectedTemplate.sessionUnitsOfService,
      sessionStartTime: selectedTemplate.sessionStartTime,
      sessionEndTime: selectedTemplate.sessionEndTime,
      sessionDuration: selectedTemplate.sessionDuration,
      sessionHourFormat: selectedTemplate.sessionHourFormat,
      sessionType: selectedTemplate.sessionType,
      sessionLocation: selectedTemplate.sessionLocation,
      sessionAddress: selectedTemplate.sessionAddress,

      clinicalNote: selectedTemplate.clinicalNote,
      showAuthorsAndTimestamps: selectedTemplate.showAuthorsAndTimestamps,

      sessionData: selectedTemplate.sessionData,

      skill: selectedTemplate.skill,
      behavior: selectedTemplate.behavior,

      additionalFields: selectedTemplate.additionalFields,
      formBuilder: selectedTemplate.formBuilderValues || {},
      signature: selectedTemplate.signature,
      version: selectedTemplate.version,
    },
  });
  methods.formState.isDirty;
  const steps = [
    {
      step: translations('edit_template'),
      fields: [],
      form: EditNoteTemplateForm,
      validated: true,
      touched: false,
    },
    {
      step: translations('review'),
      fields: [],
      form: ReviewTemplateForm,
      validated: true,
      touched: false,
    },
  ];

  const FormPart = steps[currentStep].form;

  const dlgSubmit = async (values: any, isPublished: boolean) => {
    if (!selectedTemplate.publishedAt) {
      await selectedTemplate.updateEntity({
        title: values.title,
        template: values.template,
        organizationInformation: values.organizationInformation,
        organizationName: values.organizationName,
        organizationLogo: values.organizationLogo,
        organizationAddress: values.organizationAddress,
        organizationBillingAddress: values.organizationBillingAddress,
        organizationTin: values.organizationTin,
        organizationNpi: values.organizationNpi,

        clientInformation: values.clientInformation,
        clientFirstName: values.clientFirstName,
        clientMiddleName: values.clientMiddleName,
        clientLastName: values.clientLastName,
        clientGender: values.clientGender,
        clientBirthDate: values.clientBirthDate,
        clientAgeInMonths: values.clientAgeInMonths,
        clientSsn: values.clientSsn,
        clientDiagnoses: values.clientDiagnoses,
        clientAssignedStaff: values.clientAssignedStaff,
        primaryMemberId: values.primaryMemberId,
        secondaryMemberId: values.secondaryMemberId,

        sessionInformation: values.sessionInformation,
        sessionStaff: values.sessionStaff,
        staffNpi: values.staffNpi,
        staffLicenseNumber: values.staffLicenseNumber,
        sessionDateOfService: values.sessionDateOfService,
        sessionUnitsOfService: values.sessionUnitsOfService,
        sessionStartTime: values.sessionStartTime,
        sessionEndTime: values.sessionEndTime,
        sessionDuration: values.sessionDuration,
        sessionHourFormat: values.sessionHourFormat,
        sessionType: values.sessionType,
        sessionLocation: values.sessionLocation,
        sessionAddress: values.sessionAddress,

        clinicalNote: values.clinicalNote,
        showAuthorsAndTimestamps: values.showAuthorsAndTimestamps,

        sessionData: values.sessionData,
        skill: values.skill,
        behavior: values.behavior,

        additionalFields: values.additionalFields,
        formBuilderValues: values.formBuilder,

        signature: values.signature,

        publishedAt: isPublished ? new Date().getTime() : null,
      });
    } else {
      await database.write(async () => {
        return await database
          .get(NoteTemplateVersion.table)
          .create((entity: any) => {
            Object.assign(entity, values);
            entity.partition = selectedGroup;
            entity.version = selectedTemplate.version + 1;
            entity.formBuilderValues = values.formBuilder;
            entity.publishedAt = isPublished ? new Date().getTime() : null;
            entity.createdBy = userId;
            entity.updatedBy = userId;
            entity.noteTemplate.id = selectedTemplate.noteTemplate.id;
          });
      });
    }
  };
  const navigateBack = (validate: boolean = false) => {
    if (validate && methods.formState.isDirty) {
      setConfirmation(true);
    } else {
      navigation.navigate('OrganizationProfile', {
        screen: 'Note Templates',
        id: organization.id,
      });
    }
  };
  return (
    <BaseScreen header={false}>
      <Modal
        show={[confirmation, setConfirmation]}
        title={'Unsaved Changes'}
        footer={
          <>
            <RHButton
              secondary
              mode="outlined"
              textColor={Colors.RAVEN_BLACK}
              onPress={() => navigateBack(false)}>
              Exit & Lose Changes
            </RHButton>
            <RHSeparator width={16} />
            <RHButton
              secondary
              mode="contained"
              onPress={() => setConfirmation(false)}>
              Resume Editing
            </RHButton>
          </>
        }>
        <Text style={[Typography.P1]}>
          Warning - Any changes made will be lost unless the template is saved
          as a draft or published.
        </Text>
      </Modal>
      <Stepper
        currentStep={[currentStep, setCurrentStep]}
        steps={steps}
        onClickCallback={setCurrentStep}
      />
      <ScrollView
        contentContainerStyle={[styles.affixPadding]}
        style={[styles.flex]}>
        <FormProvider {...methods}>
          <FormPart key={steps[currentStep].step} />
        </FormProvider>
      </ScrollView>
      <View
        style={[
          styles.affix,
          styles.paddingHorizontal,
          styles.row,
          styles.justifySpaceBetween,
        ]}>
        <View style={[styles.column, styles.justifyCenter]}>
          <RHButton
            mode="contained"
            onPress={() => {
              if (currentStep === 0) {
                navigateBack(true);
              } else {
                setCurrentStep(prevStep => prevStep - 1);
              }
            }}>
            {currentStep === 0 ? (
              translations('cancel_button')
            ) : (
              <>
                <Icon name="arrow-left" size={16} style={styles.marginMRight} />
                {translations('back')}
              </>
            )}
          </RHButton>
        </View>
        <View style={[styles.row]}>
          <RHButton
            style={styles.marginLRight}
            textColor={Colors.RAVEN_BLACK}
            color="black"
            mode="outlined"
            onPress={() => {
              methods.handleSubmit(async values => {
                await dlgSubmit(values, false);
                navigateBack();
              })();
            }}>
            {translations('save_as_draft')}
          </RHButton>

          <View style={[styles.column, styles.justifyCenter]}>
            <RHButton
              mode="contained"
              onPress={() => {
                if (currentStep !== steps.length - 1) {
                  setCurrentStep(prevStep => prevStep + 1);
                } else {
                  methods.handleSubmit(async values => {
                    await dlgSubmit(values, true);
                    navigateBack();
                  })();
                }
              }}>
              {currentStep !== steps.length - 1 ? (
                <>
                  {translations('next')}
                  <Icon
                    name="arrow-right"
                    size={16}
                    style={styles.marginMLeft}
                  />
                </>
              ) : (
                <>
                  {translations('publish')}
                  <Icon
                    name="check-circle"
                    size={16}
                    style={styles.marginMLeft}
                  />
                </>
              )}
            </RHButton>
          </View>
        </View>
      </View>
    </BaseScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['route'], ({route, database}) => ({
    selectedTemplate: route.params?.id
      ? database.get('note_template_versions').findAndObserve(route.params.id)
      : of(null),
  })),
  withObservables(['authentication'], ({authentication, database}: any) => ({
    organization: database
      ?.get('instances')
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
)(EditTemplateScreen);
