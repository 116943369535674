const addressFormat = (address: any): string => {
  let addressString = address?.streetLine || '';
  if (address?.secondLine) {
    addressString += `, ${address.secondLine}`;
  }
  if (address?.city) {
    addressString += `, ${address.city}`;
  }
  if (address?.state) {
    addressString += `, ${address.state}`;
  }
  if (address?.postalCode) {
    addressString += `, ${address.postalCode}`;
  }
  if (address?.country) {
    addressString += `, ${address.country}`;
  }
  return addressString;
};

export default addressFormat;
