import React, {useEffect, useState} from 'react';
import {Text, View, TouchableOpacity, FlatList} from 'react-native';
import {IconButton} from 'react-native-paper';
import {useDimensions} from '@react-native-community/hooks';
import {Q} from '@nozbe/watermelondb';
import TrialByTrialTarget from 'src/modules/session/components/trial-by-trial/target';
import TrialByTrialTargetEdit from 'src/modules/session/components/trial-by-trial/target-edit';
import {Colors, Typography} from 'src/styles';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {ProgramMethodAvatar} from 'src/modules/programs/components';
import ProgramMenu from 'src/design-system/program-menu';
import Modal from 'src/design-system/modal';
import ProgramProgress from 'src/modules/programs/components/program-progress-legacy';
import TargetSelect from 'src/form-inputs/target-select';
import {Target} from 'src/models';
import _ from 'lodash';
import TrialByTrialEdit from './table-edit';
import ProgramInstructionModal from '../program-instruction-modal';
import {useStyle} from 'src/providers/style';
import ProgramTooltip from '../program-tooltip';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';
import {useTranslations} from 'src/providers/translation';

const getNumColumns = (width: number) => {
  if (width > 1920) {
    return 4;
  } else if (width > 1440) {
    return 3;
  } else if (width > 1024) {
    return 2;
  } else if (width > 768) {
    return 1;
  } else {
    return 1;
  }
};

const TrialByTrialProgram = ({
  program,
  session,
  prompts,
  enabledTargets,
  events,
  setCurrentTarget,
  sets,
  targets,
}: any) => {
  const dimensions = useDimensions();
  const styles = useStyle();
  const numColumns = getNumColumns(dimensions.window.width);

  const [open, setOpen] = useState(false);
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [eventId, setEventId] = useState('');
  const [trialCount, setTrialCount] = useState('');
  const [currentSet, setCurrentSet] = useState('');
  const [programTargets, setTargets] = useState(enabledTargets);

  const translations = useTranslations();
  const viewData = () => {
    setDataModal(!dataModal);
  };

  const viewHistory = () => {
    setHistoryModal(!historyModal);
  };
  const viewInstructions = () => {
    setInstructionsModal(!instructionsModal);
  };

  const setsByTargetId = _.groupBy(sets, 'target.id');
  const targetById = _.groupBy(targets, 'id');

  const [target, setTarget] = useState<string | undefined>(
    Object.keys(setsByTargetId)[0],
  );

  useEffect(() => {
    const filteredTargets = enabledTargets.filter(
      (programTarget: any, index: number) => {
        return (
          index ===
          enabledTargets.findIndex(
            obj => programTarget.targetId === obj.targetId,
          )
        );
      },
    );

    setTargets(filteredTargets);
  }, [enabledTargets]);

  useEffect(() => {
    setTarget(Object.keys(setsByTargetId)[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  useEffect(() => {
    if (setCurrentTarget) {
      setCurrentTarget(target);
    }
  }, [setCurrentTarget, target]);

  const dropdownTargets = targets.map((_target: Target) => {
    return {
      label: _target.name,
      value: _target.id,
    };
  });

  return (
    <>
      <TouchableOpacity
        style={[
          styles.row,
          styles.justifySpaceBetween,
          styles.marginTop,
          styles.marginHorizontal,
        ]}
        onPress={() => setOpen(!open)}>
        <View style={[styles.flex, styles.row, styles.alignCenter]}>
          <ProgramMethodAvatar type={program?.method} />
          <View style={[styles.column, styles.marginLeft, styles.flex]}>
            <ProgramTooltip name={program?.name} />
          </View>
        </View>
        {instructionsModal ? (
          <ProgramInstructionModal
            show={[instructionsModal, setInstructionsModal]}
            program={program}
          />
        ) : null}
        {historyModal ? (
          <Modal
            show={[historyModal, setHistoryModal]}
            title={'Progress'}
            footer={
              <InformationTooltip
                value={translations('program_progress_month')}
              />
            }>
            <ProgramProgress program={program} />
          </Modal>
        ) : null}
        {dataModal ? (
          <Modal
            show={[dataModal, setDataModal]}
            title={`${program.name} Data`}>
            <View style={[styles.paddingBottom]}>
              <TargetSelect
                target={target}
                targets={dropdownTargets}
                onSelect={setTarget}
              />
            </View>
            <TrialByTrialEdit
              program={program}
              events={events}
              type={'modal'}
              setEventId={id => {
                setEventId(id);
              }}
              setTrialCount={i => {
                setTrialCount(i);
              }}
              setCurrentSet={s => {
                setCurrentSet(s);
              }}
              target={targetById?.[target]?.[0]}
              sets={setsByTargetId?.[target] || []}
            />
            {eventId ? (
              <TrialByTrialTargetEdit
                eventId={eventId}
                prompts={prompts}
                session={session}
                program={program}
                trialCount={trialCount}
                currentSet={currentSet}
                setEventId={setEventId}
              />
            ) : (
              <></>
            )}
          </Modal>
        ) : null}
        <ProgramMenu
          viewInstructions={viewInstructions}
          viewHistory={viewHistory}
          viewData={viewData}
        />
        <IconButton
          color={Colors.RAVEN_BLACK}
          icon={!open ? 'chevron-right' : 'chevron-down'}
          onPress={() => setOpen(!open)}
        />
      </TouchableOpacity>
      <Text style={[Typography.P3, styles.marginTop, styles.marginHorizontal]}>
        {program?.stimulusDescription}
      </Text>
      <FlatList
        key={open ? numColumns : 1}
        numColumns={open ? numColumns : 1}
        horizontal={!open}
        data={programTargets}
        style={[styles.marginL, styles.paddingRight]}
        keyExtractor={item => item?.id?.toString()}
        initialNumToRender={1}
        onEndReachedThreshold={0.5}
        columnWrapperStyle={
          !open ? null : numColumns === 1 ? null : styles.columnWrapperStyle
        }
        persistentScrollbar={true}
        renderItem={({item}) => (
          <TrialByTrialTarget
            sessionTarget={item}
            prompts={prompts}
            session={session}
            program={program}
          />
        )}
      />
    </>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({program}: any) => ({
    targets: program.enabledTargets,
    prompts: program.prompts,
  })),
  withObservables([], ({database, targets, sessionProgram}: any) => {
    const target_id = targets.map((target: any) => target.id);
    return {
      enabledTargets: database
        .get('session_targets')
        .query(
          Q.where('enabled', true),
          Q.where('session_program_id', sessionProgram.id),
          Q.on('targets', Q.and(Q.where('id', Q.oneOf(target_id)))),
          Q.sortBy('rank', Q.asc),
        ),
    };
  }),
  withObservables([], ({database, program, session}: any) => {
    return {
      sets: database
        .get('sets')
        .query(
          Q.where('program_id', program.id),
          Q.where('deleted_at', null),
          Q.where('session_id', session.id),
          Q.sortBy('start_timestamp', Q.asc),
        ),
    };
  }),
  withObservables(['sets'], ({sets, database}: any) => {
    const setIds = _.map(sets, 'id');
    return {
      events: database
        .get('events')
        .query(
          Q.where('set_id', Q.oneOf(setIds)),
          Q.where('deleted_at', null),
          Q.sortBy('created_at', Q.asc),
        ),
    };
  }),
)(TrialByTrialProgram);
