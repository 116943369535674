import React, {ReactNode} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import SelectInput from 'src/hook-form/select-input';

interface Props {
  rules?: any;
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  multiple?: boolean;
  searchable?: boolean;
  items?: {
    label: string;
    value: any | string | number;
    custom?: ReactNode;
  }[];
  sections?:
    | {
        label: string;
        data: {
          label: string;
          value: any | string | number;
          custom?: ReactNode;
        }[];
      }[];
  selectAll?: boolean;
  defaultValue?: string;
  helper?: string;
  showHelper?: boolean;
  disabled?: boolean;
  canCancel?: boolean;
}

const HookFormSelectInput = (props: Props) => {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      rules={props.rules}
      render={({field, fieldState: {error}}) => (
        <SelectInput {...props} {...field} error={error} />
      )}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
};

export default HookFormSelectInput;
