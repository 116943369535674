import React from 'react';
import PaperMenu from 'src/design-system/paper-menu';
import {View} from 'react-native';
import {useStyle} from 'src/providers/style';

interface Props {
  anchor: JSX.Element;
  children: JSX.Element | JSX.Element[];
}

const Tooltip = ({anchor, children}: Props) => {
  const styles = useStyle();

  const [visible, setVisible] = React.useState(false);

  const openToolTip = () => setVisible(true);

  const closeTooltip = () => setVisible(false);

  return (
    <PaperMenu
      visible={visible}
      onDismiss={closeTooltip}
      anchor={React.cloneElement(anchor, {
        onPress: openToolTip,
        onMouseEnter: openToolTip,
      })}>
      <View onMouseLeave={closeTooltip} style={[styles.padding]}>
        {children}
      </View>
    </PaperMenu>
  );
};

export default Tooltip;
