import React from 'react';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {Text} from 'react-native';

const FormLabel = ({label}: any) => {
  const styles = useStyle();

  return (
    <Text
      style={[styles.textColorPrimary, Typography.P3, styles.paddingLBottom]}>
      {label}
    </Text>
  );
};

export default FormLabel;
