import React, {useEffect, useState} from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {Prompt, User} from 'src/models';
import {Colors, Typography} from 'src/styles';
import {DataTable} from 'react-native-paper';
import moment from 'moment';
import {of} from '@nozbe/watermelondb/utils/rx';
import {Q} from '@nozbe/watermelondb';

const PromptCell = compose(
  withDatabase,
  withState,
  withObservables(['events'], ({events, database}: any) => {
    const promptIds = events?.flatMap((event: any) => {
      if (event.prompts.length) {
        return event.prompts;
      }
    });
    return {
      promptItems: database
        .get(Prompt.table)
        .query(Q.where('deleted_at', null), Q.where('id', Q.oneOf(promptIds))),
    };
  }),
)(({type, promptItems}: any) => {
  const antecedents = promptItems.filter(
    (prompt: any) => prompt.promptType === 'Antecedents',
  );
  const consequences = promptItems.filter(
    (prompt: any) => prompt.promptType === 'Consequences',
  );
  return (
    <View
      style={[
        styles.justifyCenter,
        styles.alignCenter,
        styles.borderRight,
        styles.flex,
        styles.paddingSMVertical,
      ]}>
      {type === 'antecedents' ? (
        antecedents.length ? (
          antecedents.map((antecedent: any) => (
            <Text style={[styles.cellBoldTxt, styles.textAlignCenter]}>
              {antecedent?.name}
            </Text>
          ))
        ) : (
          <Text style={[styles.cellBoldTxt, styles.textAlignCenter]}>-</Text>
        )
      ) : consequences.length ? (
        consequences.map((consequence: any) => (
          <Text style={[styles.cellBoldTxt, styles.textAlignCenter]}>
            {consequence?.name}
          </Text>
        ))
      ) : (
        <Text style={[styles.cellBoldTxt, styles.textAlignCenter]}>-</Text>
      )}
    </View>
  );
});

const FrequencyTableRow = ({
  collector,
  title,
  events,
  set,
  showIntensity,
}: any) => {
  const [intensityCount, setIntensityCount] = useState<any>({
    mild: 0,
    moderate: 0,
    severe: 0,
  });

  useEffect(() => {
    if (events.length) {
      events?.forEach((event: any) => {
        setIntensityCount((prevCount: any) => ({
          ...prevCount,
          [event.intensity]: prevCount[event.intensity] + 1,
        }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTable.Row style={[styles.ph_0]}>
      <DataTable.Cell
        style={[styles.justifyCenter, styles.borderRight]}
        textStyle={[styles.cellBoldTxt]}>
        <View>
          <Text style={styles.cellBoldTxt}>{title}</Text>
          <Text style={styles.cellTxt}>
            {moment(set?.startTimestamp).format('ll')}
          </Text>
          <Text style={styles.cellTxt}>
            {moment(set?.startTimestamp).format('hh:mm A')} -{' '}
            {set?.endTimestamp
              ? moment(set?.endTimestamp).format('hh:mm A')
              : 'Incomplete'}
          </Text>
        </View>
      </DataTable.Cell>

      <DataTable.Cell
        style={[styles.justifyCenter, styles.borderRight]}
        textStyle={[styles.cellBoldTxt]}>
        {collector ? `${collector.firstName} ${collector.lastName}` : '-'}
      </DataTable.Cell>

      <DataTable.Cell
        style={[styles.justifyCenter, styles.borderRight]}
        textStyle={[styles.cellBoldTxt]}>
        {events?.length}
      </DataTable.Cell>

      <PromptCell type={'antecedents'} events={events} />

      <PromptCell type={'consequences'} events={events} />

      <DataTable.Cell
        style={[styles.justifyCenter, styles.borderRight]}
        textStyle={[styles.cellBoldTxt]}>
        {showIntensity ? intensityCount.mild : '-'}
      </DataTable.Cell>

      <DataTable.Cell
        style={[styles.justifyCenter, styles.borderRight]}
        textStyle={[styles.cellBoldTxt]}>
        {showIntensity ? intensityCount.moderate : '-'}
      </DataTable.Cell>

      <DataTable.Cell
        style={[styles.justifyCenter]}
        textStyle={[styles.cellBoldTxt]}>
        {showIntensity ? intensityCount.severe : '-'}
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
  },
  table: {
    borderWidth: 1,
    borderColor: Colors.PRIMARY_300_SESSION_PLAN,
    borderRadius: 10,
  },
  head: {
    height: 50,
    backgroundColor: Colors.PRIMARY_50,
  },
  firstRow: {
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  lastRow: {
    borderBottomStartRadius: 10,
    borderBottomEndRadius: 10,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignSelf: {
    alignSelf: 'flex-start',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  paddingSMVertical: {
    paddingVertical: 4,
  },
  titleTxt: {
    ...Typography.LABEL_MEDIUM,
    color: Colors.PRIMARY_900,
    alignSelf: 'center',
    textAlign: 'center',
    width: 100,
    flexWrap: 'wrap',
  },
  cellBoldTxt: {
    ...Typography.CAPTION_MEDIUM,
    color: Colors.PRIMARY_900,
  },
  cellTxt: {
    ...Typography.CAPTION,
    color: Colors.PRIMARY_900,
  },
  spaceTop: {
    marginTop: 5,
  },
  borderRight: {
    borderRightWidth: 1,
    borderColor: Colors.PRIMARY_300_SESSION_PLAN,
  },
  flex: {
    flex: 1,
  },
  cornerTab: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 8,
    borderTopWidth: 8,
    borderRightColor: 'transparent',
    borderTopColor: '#B5B5B8',
    transform: [{rotate: '90deg'}],
    position: 'absolute',
    top: 0,
    right: 0,
  },
  ph_0: {
    paddingHorizontal: 0,
  },
});

export default compose(
  withDatabase,
  withState,
  withObservables(['events'], ({events, database}: any) => ({
    collector: events.length
      ? database.get(User.table).findAndObserve(events[0]?.updatedBy)
      : of(null),
  })),
)(FrequencyTableRow);
