import React from 'react';
import HookFormRadioInput from 'src/hook-form-wrapper/radio-input';

const items = [
  {value: 'never', label: 'Never'},
  {value: 'on', label: 'On'},
  {value: 'after', label: 'After'},
];

const RepeatEndMode = () => {
  return (
    <HookFormRadioInput name={'repeatEndMode'} label={'Ends'} items={items} />
  );
};

export const Input = RepeatEndMode;

export default RepeatEndMode;
