import React from 'react';
import {Colors} from 'src/styles';
import {DrawerItem as ReactNavigationDrawerItem} from '@react-navigation/drawer';
import DrawerItem from '../drawer-item';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

// const PremiumIcon = () => {
//   return <Icon name={'star-circle'} color={'#8D96E8'} size={24} />;
// };
//
const BetaIcon = ({}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <View
      style={[styles.border, styles.borderRadius, styles.backgroundColorBlue]}>
      <Text
        style={[
          styles.textTransformCapitalize,
          styles.paddingLHorizontal,
          styles.paddingSMVertical,
          styles.textColorWhite,
        ]}>
        {translations('beta')}
      </Text>
    </View>
  );
};
//
// const ComingIcon = () => {
//   const styles = useStyle();
//   const translations = useTranslations();
//
//   return (
//     <Text
//       style={[
//         styles.textTransformCapitalize,
//         styles.borderRadius,
//         styles.border,
//         styles.paddingLHorizontal,
//         styles.paddingSMVertical,
//       ]}>
//       {translations('coming_soon')}
//     </Text>
//   );
// };

const DrawerContentItem = ({
  navigationKey,
  label,
  icon,
  iconColor,
  navigation,
  state,
  onPress,
  beta = false,
  right = () => {},
}: any) => {
  return (
    <ReactNavigationDrawerItem
      key={navigationKey}
      label={({focused}) =>
        DrawerItem({
          label: label,
          focused,
          icon: icon,
          iconColor,
          right: beta ? BetaIcon : right,
        })
      }
      activeBackgroundColor={Colors.RAVEN_WHITE}
      inactiveBackgroundColor={Colors.RAVEN_BLACK}
      focused={
        state.routes.findIndex((e: any) => e.name === navigationKey) ===
        state.index
      }
      onPress={() => (onPress ? onPress() : navigation.navigate(navigationKey))}
    />
  );
};

export default DrawerContentItem;
