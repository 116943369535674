import {Mutex} from 'async-mutex';
import {fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {authApi} from 'src/services/auth';
import {logout} from 'src/redux/auth';
import AsyncStorage from '@react-native-async-storage/async-storage';

const mutex = new Mutex();
export const query = fetchBaseQuery({
  baseUrl: '',
  prepareHeaders: async headers => {
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    headers.set(
      'Authorization',
      `Bearer ${(await AsyncStorage.getItem('accessToken')) || ''}`,
    );

    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await query(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = await AsyncStorage.getItem('refreshToken');
        if (!refreshToken) {
          api.dispatch(logout());
        }
        const refreshData = new FormData();
        refreshData.append('refresh_token', refreshToken);
        refreshData.append('grant_type', 'refresh_token');
        await api.dispatch(authApi.endpoints.token.initiate(refreshData));
        result = await query(args, api, extraOptions);
      } catch (error) {
        api.dispatch(logout());
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await query(args, api, extraOptions);
    }
  }
  return result;
};
