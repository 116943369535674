import React, {useEffect, useState} from 'react';
import {Card} from 'react-native-paper';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import FoundationIcon from 'react-native-vector-icons/Foundation';
import {ActivityIndicator, Image, Platform, Text, View} from 'react-native';
import {IconButton, Menu} from 'src/design-system';
import {Colors, Typography} from 'src/styles';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {downloadFile} from 'src/common-utils/downloadFile';
import {toDataURL} from 'src/common-utils/toDataURL';
import {useStyle} from 'src/providers/style';

const DocumentCard = ({
  item,
  viewCallback,
  canAdd,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
}: any) => {
  const styles = useStyle();

  const [image, setImage] = useState<undefined | any>(undefined);

  useEffect(() => {
    if (item.fileLocation) {
      toDataURL(item.fileLocation, setImage);
    }
  }, [item.fileLocation]);

  const download = () => {
    const extension = item.fileLocation.substring(
      item.fileLocation.lastIndexOf('.') + 1,
    );

    downloadFile(
      Platform.OS === 'web' ? image : item.fileLocation,
      `${item.fileName}${
        extension
          ? item.fileName.endsWith('.' + extension)
            ? ''
            : '.' + extension
          : ''
      }`,
    );
  };

  const options = [
    {
      onPress: () => download(),
      title: 'Download',
      icon: 'download',
    },
  ];
  if (canEdit) {
    options.push({
      onPress: () => editCallback(item),
      title: 'Rename',
      icon: 'pencil',
    });
  }
  if (canDelete) {
    options.push({
      onPress: () => deleteCallback(item),
      title: 'Delete',
      icon: 'delete',
    });
  }

  return (
    <Card
      style={[
        styles.positionRelative,
        styles.cardColumn,
        styles.marginLHorizontal,
        styles.marginMBottom,
        styles.border,
        styles.borderRadius,
        styles.borderColorPrimary50,
      ]}>
      <TouchableOpacity
        style={[styles.height200, styles.alignCenter, styles.justifyCenter]}
        onPress={() => viewCallback(item)}>
        {!item.id ? (
          <MaterialCommunityIcon
            name="image-size-select-actual"
            size={80}
            color={Colors.PRIMARY_400}
          />
        ) : (
          <>
            {item.fileLocation.endsWith('.pdf') ? (
              <MaterialCommunityIcon
                name="file-pdf-box"
                size={120}
                color={Colors.PRIMARY_800}
              />
            ) : item.fileLocation.endsWith('.csv') ? (
              <FoundationIcon
                name="page-csv"
                size={120}
                color={Colors.PRIMARY_800}
              />
            ) : (
              <>
                {image !== undefined ? (
                  <Image
                    source={{uri: image}}
                    style={[
                      styles.width,
                      styles.height,
                      styles.resizeModeContain,
                      styles.backgroundColorTransparent,
                    ]}
                  />
                ) : (
                  <ActivityIndicator />
                )}
              </>
            )}
          </>
        )}
      </TouchableOpacity>
      <View style={[styles.positionAbsolute, styles.right0, styles.top0]}>
        <IconButton
          type={'icon'}
          icon={item.starred ? 'star' : 'star-outline'}
          color={item.starred ? Colors.SECONDARY_600 : Colors.PRIMARY_300}
          onPress={() => item.updateEntity({starred: !item.starred})}
        />
      </View>
      <View
        style={[
          styles.width,
          styles.backgroundColorWhite,
          styles.row,
          styles.alignCenter,
          styles.paddingSMVertical,
          styles.paddingLLeft,
          styles.justifySpaceBetween,
          styles.borderBottomRightRadius,
          styles.borderBottomLeftRadius,
        ]}>
        <View style={[styles.flex]}>
          <Text
            style={[
              Typography.P3,
              styles.textColorPrimary,
              styles.overflowHidden,
            ]}
            numberOfLines={1}>
            {item.fileName}
          </Text>
        </View>

        {!item.id && canAdd ? (
          <IconButton
            type={'icon'}
            icon={'upload'}
            onPress={() => viewCallback(item)}
          />
        ) : (
          <Menu
            anchor={<IconButton type={'icon'} icon={'dots-vertical'} />}
            options={options}
          />
        )}
      </View>
    </Card>
  );
};

export default DocumentCard;
