import React from 'react';
import {View} from 'react-native';
import {BaseScreen} from 'src/design-system';
import {useStyle} from 'src/providers/style';
import {RHButton} from 'src/common-components/custom-ui-helpers';

interface Props {
  header?: boolean;
  noScroll?: boolean;
  isLoading?: boolean;
  title: string;
  canAdd?: boolean;
  filterIconNumber?: number;
  add?: () => void;
  addButton?: JSX.Element;
  search?: JSX.Element[] | JSX.Element;
  sortByMenu?: JSX.Element[] | JSX.Element;
  filters?: JSX.Element[] | JSX.Element;
  children?: JSX.Element[] | JSX.Element;
  snackbar?: JSX.Element;
  importBut?: JSX.Element[] | JSX.Element;
  exportBut?: JSX.Element[] | JSX.Element;
}

const BaseIndexScreen = ({
  header = true,
  title = '',
  canAdd = false,
  add = () => console.log('add'),
  addButton,
  search = undefined,
  sortByMenu = undefined,
  filters = undefined,
  children = <></>,
  snackbar = <></>,
  importBut = undefined,
  exportBut = undefined,
  isLoading = false,
}: Props) => {
  const styles = useStyle();

  return (
    <BaseScreen
      header={header}
      title={title}
      right={
        <View style={[styles.row]}>
          {importBut && canAdd ? (
            <View style={[styles.marginLRight]}>{importBut}</View>
          ) : null}
          {exportBut ? (
            <View style={[styles.marginLRight]}>{exportBut}</View>
          ) : null}
          <>
            {canAdd ? (
              <>
                {addButton ? (
                  <>{addButton}</>
                ) : (
                  <RHButton
                    secondary
                    onPress={add}
                    color="black"
                    icon={'plus'}
                    mode={'contained'}>
                    ADD
                  </RHButton>
                )}
              </>
            ) : null}
          </>
        </View>
      }
      snackbar={snackbar}
      spinner={isLoading}>
      {header ? (
        <View style={[styles.row, styles.justifySpaceBetween, styles.padding]}>
          {search ? <View style={[styles.flex]}>{search}</View> : null}
          {filters ? (
            <View style={[styles.paddingLHorizontal]}>{filters}</View>
          ) : null}
          {sortByMenu ? <View>{sortByMenu}</View> : null}
        </View>
      ) : null}
      {children}
    </BaseScreen>
  );
};

export default BaseIndexScreen;
