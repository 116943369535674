import {Button} from 'react-native-paper';
import {Colors} from 'src/styles';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import React from 'react';
import {memoize} from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';

interface Props {
  onPress?: () => void;
  icon: string;
  iconType?: string;
  type?: string;
  color?: string;
  style?: any;
  loading?: boolean;
  disabled?: boolean;
}

const IconButton = ({
  onPress = () => console.log('Icon Button Pressed'),
  icon,
  iconType = 'material_community',
  type = 'flat',
  color = Colors.RAVEN_BLACK,
  style = {},
  loading = false,
  disabled = false,
}: Props) => {
  let underlayColor = Colors.RAVEN_BLACK;
  let borderColor = Colors.RAVEN_BLACK;
  switch (type) {
    case 'outlined':
      underlayColor = Colors.RAVEN_WHITE;
      break;
    case 'icon':
      underlayColor = '#ff000000';
      borderColor = '#ff000000';
      break;
  }

  const styles = getStyles(underlayColor, borderColor);

  return (
    <Button
      loading={loading}
      disabled={disabled}
      onPress={onPress}
      style={[styles.iconButton, style]}
      contentStyle={styles.size}
      //eslint-disable-next-line react/no-unstable-nested-components
      icon={() =>
        iconType === 'material_community' ? (
          <MaterialCommunityIcons name={icon} size={22} color={color} />
        ) : (
          <MaterialIcons name={icon} size={22} color={color} />
        )
      }
    />
  );
};

const getStyles = memoize((color: string, borderColor: string) =>
  EStyleSheet.create({
    size: {
      minWidth: 44,
      width: 44,
      height: 44,
      borderRadius: 22,
      marginLeft: 7,
    },
    iconButton: {
      width: 44,
      minWidth: 44,
      height: 44,
      backgroundColor: color,
      borderRadius: 22,
      borderWidth: 1,
      borderColor,
    },
  }),
);

export default IconButton;
