import React, {useEffect, useRef, useState} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {View} from 'react-native';
import NoResults from 'src/common-components/noResults';
import {useStyle} from 'src/providers/style';

const JaspersoftDashboard = ({
  resource,
  params = {},
  container = 'container',
}: any) => {
  const styles = useStyle();
  const webViewRef = useRef(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const renderReports = async () => {
      try {
        visualize(
          {
            auth: {
              token: await AsyncStorage.getItem('accessToken'),
              preAuth: true,
              tokenName: 'pp',
            },
          },
          function (v) {
            if (webViewRef.current) {
              webViewRef.current.id = `jasper-${container}`;
              v(`#jasper-${container}`).dashboard({
                resource,
                params,
                error: function (e: any) {
                  console.log(e);
                  setError(true);
                },
              });
            }
          },
        );
      } catch (err) {
        console.log(err);
        setError(true);
      }
    };
    renderReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, params]);

  return error ? (
    <NoResults
      message={
        'An error has occurred. Please refresh your screen and if the problem persists, contact your site administrator.'
      }
    />
  ) : (
    <View style={[styles.height]} ref={webViewRef} />
  );
};

export default React.memo(JaspersoftDashboard);
