import React, {useRef, useState} from 'react';
import {StyleSheet, View, Platform} from 'react-native';
import {HelperText} from 'react-native-paper';
import DocumentPicker from 'react-native-document-picker';

import {RHButton} from 'src/common-components/custom-ui-helpers';
import {checkAndroidPermissions} from 'src/navigation/components';
import {FileImage, IconButton} from 'src/design-system';
import {UTILITY_SERVER} from 'react-native-dotenv';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Colors} from 'src/styles';
import Spinner from 'react-native-loading-spinner-overlay';

interface Props {
  path: string;
  formRef: any;
  onChange: (value: any) => void;
  value: any;
  error: any;
  style?: any;
}

const FileUpload = ({path, onChange, value, error}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileRef = useRef();

  const uploadFile = async (file: any) => {
    setIsLoading(true);

    const data = new FormData();
    data.append('file', file);
    data.append('path', path);

    const response = await fetch(`${UTILITY_SERVER}/upload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await AsyncStorage.getItem('accessToken')}`,
      },
      body: data,
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const responseValue = await response.json();

    onChange(responseValue.url);

    setIsLoading(false);
  };

  const selectFileWeb = (event: any) => {
    const file = event.target.files[0];

    uploadFile(file);
  };

  const selectFileMobile = async () => {
    const canWrite = await checkAndroidPermissions();
    try {
      if (canWrite) {
        const file = await DocumentPicker.pickSingle({
          type: [DocumentPicker.types.images, DocumentPicker.types.pdf],
        });

        uploadFile(file);
      }
    } catch (e) {
      console.log('select exception:', e);
    }
  };

  const openFileViewer = () => {
    if (Platform.OS === 'web') {
      fileRef.current.click();
    } else {
      selectFileMobile();
    }
  };

  return (
    <>
      <Spinner visible={isLoading} />
      <View style={[styles.row, styles.alignItemsCenter]}>
        {value ? (
          <View style={styles.image}>
            <FileImage src={value} />
            <IconButton
              icon={'pencil'}
              color={Colors.GRAY_700}
              style={styles.editIcon}
              onPress={() => openFileViewer()}
            />
          </View>
        ) : (
          <RHButton secondary mode="contained" onPress={() => openFileViewer()}>
            Choose File
          </RHButton>
        )}
        {Platform.OS === 'web' ? (
          <input
            ref={fileRef}
            type="file"
            onChange={selectFileWeb}
            accept="*/*"
            /* eslint-disable-next-line react-native/no-inline-styles */
            style={{
              display: 'none',
            }}
          />
        ) : (
          <></>
        )}
      </View>

      <HelperText type="error" visible={true} style={styles.helper}>
        {error && 'ⓧ ' + error.message}
      </HelperText>
    </>
  );
};

const styles = StyleSheet.create({
  fileNameInput: {
    backgroundColor: 'white',
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  helper: {},
  mr_10: {
    marginRight: 10,
  },
  image: {
    position: 'relative',
    width: 200,
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',
  },
  editIcon: {
    position: 'absolute',
    top: 75,
    left: 75,
  },
});

export default FileUpload;
