import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import SwitchInput from 'src/hook-form/switch-input';

interface Props {
  rules?: any;
  name: string;
  description?: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: boolean;
  helper?: string;
  isDisabled?: boolean;
  showHelper?: boolean;
}

const HookFormSwitchInput = (props: Props) => {
  const {control} = useFormContext();
  return (
    <Controller
      control={control}
      rules={props.rules}
      render={({field}) => <SwitchInput {...props} {...field} />}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
};

export default HookFormSwitchInput;
