import React from 'react';
import {TouchableOpacity, Text, View} from 'react-native';
import {OfflineNotification} from 'src/design-system';
import {Typography} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

interface Props {
  handleSubmit: () => void;
  login: () => void;
}

const ResetUsernameForm = ({handleSubmit, login}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <View style={[styles.paddingLVertical]}>
      <RHButton onPress={handleSubmit} mode={'contained'} secondary>
        {translations('reset')}
      </RHButton>
      <OfflineNotification
        type="text"
        message={translations('auth_form_internet_connection')}
      />
      <View style={[styles.row, styles.justifyCenter, styles.paddingTop]}>
        <TouchableOpacity onPress={login}>
          <Text
            style={[
              Typography.TERTIARY_BUTTON,
              styles.textColorPrimary,
              styles.textTransformUppercase,
            ]}>
            {translations('cancel_button')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ResetUsernameForm;
