import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import React from 'react';
import {useStyle} from 'src/providers/style';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {Appointment, Caregiver, Location, User, Participant} from 'src/models';
import addressFormat from 'src/common-utils/address-format';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const AppointmentListItem = ({
  title,
  flagColor,
  appointment,
  staff,
  addressEntity,
}: any) => {
  const styles = useStyle();
  const staffNames = staff
    .map((user: User) => `${user.firstName} ${user.lastName}`)
    .join(', ');

  return (
    <View style={[styles.container, styles.flex]}>
      <View
        style={[
          styles.alignSelfCenter,
          styles.dotL,
          styles.marginMRight,
          {backgroundColor: flagColor},
        ]}
      />
      <View
        style={[
          styles.row,
          styles.marginHorizontal,
          styles.alignCenter,
          styles.flex,
        ]}>
        <Icon name={'account'} size={20} style={[styles.paddingMRight]} />
        <Text style={[Typography.P3_MEDIUM, styles.paddingXSTop]}>{title}</Text>
      </View>
      <View
        style={[
          styles.row,
          styles.marginHorizontal,
          styles.alignCenter,
          styles.flex,
        ]}>
        <Icon
          name={'card-account-mail'}
          size={20}
          style={[styles.paddingMRight]}
        />
        <Text style={[Typography.P3_MEDIUM, styles.paddingXSTop]}>
          {staffNames}
        </Text>
      </View>
      <View
        style={[
          styles.row,
          styles.marginHorizontal,
          styles.alignCenter,
          styles.flex,
        ]}>
        {addressEntity?.address || appointment.customAddress ? (
          <>
            <Icon
              name={'home-circle'}
              size={20}
              style={[styles.paddingMRight]}
            />
            <Text style={[Typography.P3_MEDIUM, styles.paddingXSTop]}>
              {addressFormat(
                addressEntity.address || appointment.customAddress || '',
              )}
            </Text>
          </>
        ) : null}
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, appointmentId}: any) => ({
    appointment: database.get(Appointment.table).findAndObserve(appointmentId),
  })),
  withObservables([], ({database, appointment}: any) => ({
    staffParticipants: appointment.staffParticipants,
    addressEntity:
      appointment.address && appointment.address !== 'other'
        ? database
            .get(Caregiver.table)
            .findAndObserve(appointment.address)
            .pipe(
              catchError(() =>
                database
                  .get(Location.table)
                  .findAndObserve(appointment.address),
              ),
            )
        : of({}),
  })),
  withObservables([], ({database, staffParticipants}: any) => {
    const staffIds = staffParticipants.map(
      (participant: Participant) => participant.userId,
    );
    return {
      staff: database.get(User.table).query(Q.where('id', Q.oneOf(staffIds))),
    };
  }),
)(AppointmentListItem);
