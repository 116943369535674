import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {compose} from 'recompose';
import {of} from 'rxjs';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
  multiple?: boolean;
  enabledDiagnoses: any;
}

export const Value = (value: any) => {
  return value?.name;
};
export const Display = compose(
  withDatabase,
  withState,
  withObservables([], ({value, database}: any) => {
    if (!value) {
      return {value: of(null)};
    }
    return {
      value: database.get('instance_diagnoses').findAndObserve(value),
    };
  }),
)(({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Diagnosis Code</Text>
      <Text style={[Typography.P3]}>{Value(value) || ''}</Text>
    </DataItem>
  );
});
export const Input = ({name, multiple = false, enabledDiagnoses}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'instanceDiagnosisId'}
      label={'Diagnosis Code'}
      required={false}
      items={enabledDiagnoses.map(
        (enabledDiagnosis: {id: string; name: string}) => {
          return {
            value: enabledDiagnosis.id,
            label: enabledDiagnosis.name,
          };
        },
      )}
      multiple={multiple}
    />
  );
};

export const validation = () => Yup.string().required('Diagnosis is required');

export default compose(
  withDatabase,
  withState,
  withObservables(['instance'], ({database, authentication}: any) => {
    return {
      enabledDiagnoses: database
        .get('instance_diagnoses')
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
        ),
    };
  }),
)(Input);
