import React from 'react';
import {HelperText, TextInput as PaperInput} from 'react-native-paper';
import {KeyboardTypeOptions, View} from 'react-native';
import {BLUE_RIBBON} from 'src/styles/colors';
import {Field, useFormikContext} from 'formik';
import {RenderProps} from 'react-native-paper/lib/typescript/components/TextInput/types';
import {useStyle} from 'src/providers/style';

interface Props {
  valueKey: string;
  name: string;
  keyboardType?: KeyboardTypeOptions;
  required?: boolean;
  secureTextEntry?: boolean;
  right?: () => void;
  render?: (props: RenderProps) => React.ReactNode;
  style?: any;
}

const EnvShortDescriptionInput = ({
  valueKey,
  name,
  keyboardType = 'default',
  required = false,
  secureTextEntry = false,
  right = () => {},
  render = undefined,
  style = {},
}: Props) => {
  const styles = useStyle();

  const {values} = useFormikContext();
  const placeholder =
    values?.type === 'change'
      ? 'E.g. Medication Change'
      : values?.type === 'factor'
      ? 'E.g. Could not Sleep'
      : 'Short Description*';

  return (
    <View style={[]}>
      <Field name={valueKey}>
        {({field, meta: {error}}: any) => (
          <>
            <PaperInput
              onChangeText={field.onChange(field.name)}
              onBlur={field.onBlur(field.name)}
              keyboardType={keyboardType}
              value={field.value}
              placeholder={placeholder}
              label={name + (required ? '*' : '')}
              error={!!error}
              secureTextEntry={secureTextEntry}
              theme={{colors: {primary: BLUE_RIBBON}}}
              right={right}
              mode={'outlined'}
              style={[styles.input, style]}
              render={render}
            />
            <HelperText type="error" visible={true} style={styles.helper}>
              {error && 'ⓧ ' + error}
            </HelperText>
          </>
        )}
      </Field>
    </View>
  );
};

export default EnvShortDescriptionInput;
