import {relation} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class ProgramPrompt extends Base {
  static table = 'program_prompt';
  static associations = {
    program: {type: 'belongs_to', key: 'program_id'},
    prompt: {type: 'belongs_to', key: 'prompt_id'},
  };

  @relation('programs', 'program_id') program;
  @relation('prompts', 'prompt_id') prompt;
}
