import {Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import React from 'react';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import LinearGradient from 'react-native-linear-gradient';

const CancellationLegend = () => {
  const styles = useStyle();
  const translations = useTranslations();

  return (
    <View
      style={[
        styles.row,
        styles.alignCenter,
        styles.justifyCenter,
        styles.marginLTop,
      ]}>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <LinearGradient
          start={{x: 0.0, y: 0.0}}
          end={{x: 1.0, y: 1.0}}
          colors={Array(5)
            .fill([
              Colors.RAVEN_WHITE,
              '#25262F' ? '#808080' : Colors.RAVEN_WHITE,
            ])
            .flat()}
          style={[styles.dotL, styles.marginLRight]}
        />
        <Text style={[Typography.P3]}>{translations('locked_session')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[styles.dotL, styles.backgroundColorTeal, styles.marginLRight]}
        />
        <Text style={[Typography.P3]}>{translations('staff_initiated')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[styles.dotL, styles.backgroundColorLime, styles.marginLRight]}
        />
        <Text style={[Typography.P3]}>{translations('client_initiated')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[styles.dotL, styles.backgroundColorRuby, styles.marginLRight]}
        />
        <Text style={[Typography.P3]}>{translations('no_show')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[
            styles.dotL,
            styles.backgroundColorViolet,
            styles.marginLRight,
          ]}
        />
        <Text style={[Typography.P3]}>{translations('absent')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[
            styles.dotL,
            styles.backgroundColorTorchRedLight,
            styles.marginLRight,
          ]}
        />
        <Text style={[Typography.P3]}>{translations('added_in_error')}</Text>
      </View>
      <View style={[styles.row, styles.alignCenter, styles.marginRight]}>
        <View
          style={[
            styles.dotL,
            styles.backgroundColorOrange,
            styles.marginLRight,
          ]}
        />
        <Text style={[Typography.P3]}>{translations('other')}</Text>
      </View>
    </View>
  );
};

export default CancellationLegend;
