import React from 'react';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {Display as RelationshipDisplay} from 'src/hook-form-inputs/relationship';
import {Display as PhoneNumberDisplay} from 'src/hook-form-inputs/phone-number';
import {Display as EmailDisplay} from 'src/hook-form-inputs/email';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {FormSectionHeader} from 'src/design-system';
import {useStyle} from 'src/providers/style';

interface Props {
  caregivers: any;
}

const ContactInformation = ({caregivers}: Props) => {
  const styles = useStyle();

  return (
    <View style={[styles.column]}>
      <Text style={[Typography.P1, styles.textColorSecondary]}>
        Contact Information
      </Text>
      <View style={[styles.paddingVertical]}>
        {caregivers?.map((caregiver, index) => (
          <React.Fragment key={index}>
            <FormSectionHeader title={`Caregiver ${index + 1}`} />

            <Text style={[Typography.H6]}>Caregiver's Name</Text>
            <Text style={[Typography.P3]}>
              {caregiver.firstName} {caregiver.lastName}
            </Text>
            <RelationshipDisplay value={caregiver?.relationship} />
            <PhoneNumberDisplay value={caregiver?.mobilePhone} />
            <EmailDisplay value={caregiver?.email} />
          </React.Fragment>
        ))}
      </View>
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables(['patient'], ({patient}) => {
    if (patient.id) {
      return {
        caregivers: patient.caregivers,
      };
    }
    return {
      caregivers: of([]),
    };
  }),
)(ContactInformation);
