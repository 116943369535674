import React, {useState, useEffect} from 'react';
import {StyleSheet, Text} from 'react-native';
import {Snackbar} from 'react-native-paper';
import NetInfo from '@react-native-community/netinfo';
import {Colors, Typography} from 'src/styles';

interface Props {
  message?: string;
  type?: string;
}

const OfflineNotification = ({
  message = 'You are now offline',
  type = 'snackbar',
}: Props) => {
  const [isOffline, setOfflineStatus] = useState(false);

  useEffect(() => {
    const netInfoSubscription = NetInfo.addEventListener(state => {
      const offline = !(state.isConnected && state.isInternetReachable);
      setOfflineStatus(offline);
    });

    return () => netInfoSubscription();
  }, []);

  if (type === 'snackbar') {
    return (
      <Snackbar
        visible={isOffline}
        onDismiss={() => setOfflineStatus(false)}
        duration={50000}
        action={{
          label: 'Close',
          onPress: () => {
            setOfflineStatus(false);
          },
        }}>
        {message}
      </Snackbar>
    );
  } else {
    return (
      <>
        {isOffline && (
          <Text style={styles.errorMsg}>
            * Please connect to an internet connection to sign in
          </Text>
        )}
      </>
    );
  }
};

const styles = StyleSheet.create({
  errorMsg: {
    ...Typography.LABEL,
    color: Colors.ERROR,
    marginTop: 20,
    alignSelf: 'center',
    fontSize: 16,
  },
});

export default OfflineNotification;
