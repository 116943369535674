import {DataItem, TextInput} from 'src/design-system';
import React from 'react';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';

interface DisplayProps {
  value: string;
}

interface Props {
  valueKey: string;
}

export const Import = (
  key: string = 'lastName',
  prefix?: string,
  required: boolean = true,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Last Name',
  key,
  validators: required
    ? [
        {
          validate: 'required',
        },
      ]
    : [],
});

const resolveDisplayValue = (value: string) => {
  return value;
};

const FirstNameDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Last Name</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const FirstNameInput = ({valueKey}: Props) => {
  return (
    <TextInput
      valueKey={(valueKey ? valueKey + '.' : '') + 'lastName'}
      name={'Last Name'}
      required={true}
    />
  );
};

export const validation = () => Yup.string().required('Last name is required');

export const Display = FirstNameDisplay;
export const Input = FirstNameInput;
export const Value = resolveDisplayValue;

export default FirstNameInput;
