import React, {useEffect, useState} from 'react';
import {FlatList, View} from 'react-native';
import {Searchbar} from 'react-native-paper';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import moment from 'moment';
import {of} from 'rxjs';

import {appointmentLocations} from 'src/form-inputs/appointment-location-input';
import CsvExport from 'src/common-components/csv-export/components';
import NoResults from 'src/common-components/noResults';
import {BaseIndexScreen, DeleteModal} from 'src/design-system';
import {Session} from 'src/modules/home/components';
import {fuzzy} from 'src/common-utils/fuzzy';
import withState from 'src/redux/wrapper';
import SessionNoteFilterIndicator from '../../components/filter-chip-indicator';
import NotesDueFilterMenu from '../../components/filter-menu';
import {useStyle} from 'src/providers/style';

const SessionsNotesDueScreen = ({profile, sessions, patients, staff}: any) => {
  const styles = useStyle();

  const [showDelete, setShowDelete] = useState<string | boolean>(false);
  const [searchStr, setSearchStr] = useState('');
  const [filteredNotes, setFilteredNotes] = useState<any>([]);
  const [filters, setFilters] = useState<any>({
    clients: [],
    staff: [],
    locations: [],
  });

  const calcFilteredNotes = async () => {
    const notes = [];
    for (const session of sessions) {
      const currentStaffParticipant = await session
        .currentStaffParticipant(profile?.id)
        .fetch();
      if (currentStaffParticipant?.length > 0) {
        const isSupervision = currentStaffParticipant[0]?.supervision;
        if (
          (isSupervision &&
            session?.supervisionStartTimestamp &&
            session?.supervisionEndTimestamp &&
            !session?.supervisionSubmissionTimestamp) ||
          (!isSupervision &&
            session?.startTimestamp &&
            session?.endTimestamp &&
            !session?.submissionTimestamp)
        ) {
          const appointment = await session.appointment.fetch();
          if (
            filters.locations.length > 0 &&
            filters.locations.indexOf(appointment.location) === -1
          ) {
            continue;
          }

          let staffParticipants = await appointment.staff.fetch();
          if (filters.staff.length > 0) {
            staffParticipants = staffParticipants.filter((staffMember: any) =>
              filters.staff.includes(staffMember.id),
            );
            if (staffParticipants?.length === 0) {
              continue;
            }
          }

          let clientParticipants = await appointment.patients.fetch();
          if (filters.clients.length > 0) {
            clientParticipants = clientParticipants.filter((patient: any) =>
              filters.clients.includes(patient.id),
            );
            if (clientParticipants?.length === 0) {
              continue;
            }
          }

          if (searchStr.length > 0) {
            staffParticipants = staffParticipants.filter((staffMember: any) =>
              fuzzy(
                `${staffMember?.firstName} ${staffMember?.lastName}`,
                searchStr,
              ),
            );
            clientParticipants = clientParticipants.filter((patient: any) =>
              fuzzy(`${patient?.firstName} ${patient?.lastName}`, searchStr),
            );
            if (
              staffParticipants?.length === 0 &&
              clientParticipants?.length === 0
            ) {
              continue;
            }
          }
          notes.push(session);
        }
      }
    }
    const sortedNotes = [...notes].sort((a, b) => b.date - a.date);
    setFilteredNotes(sortedNotes);
  };

  useEffect(() => {
    calcFilteredNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions, searchStr, filters]);

  const deleteCallback = (item: any) => {
    setShowDelete(item);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = async (item: any) => {
    item.delete();
    setShowDelete(false);
  };
  const exportNotes = async () => {
    let _exportNotes = [
      ['Assigned Staff', 'Assigned Client', 'Location', 'Date'],
    ];
    for (const session of sessions) {
      const currentStaffParticipant = await session
        .currentStaffParticipant(profile?.id)
        .fetch();
      if (currentStaffParticipant?.length > 0) {
        const isSupervision = currentStaffParticipant[0]?.supervision;
        if (
          (isSupervision &&
            session?.supervisionStartTimestamp &&
            session?.supervisionEndTimestamp &&
            !session?.supervisionSubmissionTimestamp) ||
          (!isSupervision &&
            session?.startTimestamp &&
            session?.endTimestamp &&
            !session?.submissionTimestamp)
        ) {
          const appointment = await session.appointment.fetch();
          const staffParticipants = await appointment.staff.fetch();
          const clientParticipants = await appointment.patients.fetch();

          const staffStr = staffParticipants.reduce((a: string, b: any) => {
            return a + `${b.firstName} ${b.lastName}\n`;
          }, '');
          const clientStr = clientParticipants.reduce((a: string, b: any) => {
            return a + `${b.firstName} ${b.lastName}\n`;
          }, '');
          const locationLabel = appointmentLocations.find(
            item => item.value === appointment?.location,
          )?.label;
          const formattedDate = moment(
            session?.date ? session?.date : appointment?.date,
          ).format('ll');
          const formattedStartDate = moment(appointment?.start).format('h:mmA');
          const formattedEndDate = moment(appointment?.end).format('h:mmA');
          _exportNotes.push([
            staffStr,
            clientStr,
            locationLabel,
            `${formattedDate} ${formattedStartDate} - ${formattedEndDate}`,
          ]);
        }
      }
    }
    return _exportNotes;
  };

  return (
    <BaseIndexScreen
      title={'Notes Due'}
      search={
        <Searchbar
          value={searchStr}
          onChangeText={setSearchStr}
          placeholder={'Search'}
        />
      }
      exportBut={<CsvExport data={exportNotes} name={'notesdue_export'} />}
      filters={
        <NotesDueFilterMenu
          filters={filters}
          setFilters={setFilters}
          clients={patients}
          staff={staff}
        />
      }>
      <SessionNoteFilterIndicator
        filters={filters}
        setFilters={setFilters}
        clients={patients}
        staff={staff}
      />
      <DeleteModal
        model={'Session'}
        text={'session'}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      {filteredNotes?.length ? (
        <FlatList
          data={filteredNotes}
          keyExtractor={item => item.id}
          renderItem={({item}: any) => {
            return (
              <View style={[styles.paddingHorizontal]}>
                <Session
                  session={item}
                  deleteCallback={() => deleteCallback(item)}
                />
              </View>
            );
          }}
        />
      ) : (
        <NoResults
          message={'No session notes due.'}
          iconName={'clipboard-text-outline'}
        />
      )}
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => {
    return {
      profile: authentication?.userId
        ? database.get('users').findAndObserve(authentication.userId)
        : of(),
    };
  }),
  withObservables(['profile'], ({profile, authentication}: any) => ({
    role: profile.role,
    sessions: profile.userSessions(authentication.selectedGroup),
  })),
  withObservables([], ({role, profile, database, authentication}: any) => {
    return {
      patients: !role?.patientAssignedOnly
        ? database
            .get('patients')
            .query(
              Q.where('deleted_at', null),
              Q.where('_partition', authentication.selectedGroup),
            )
        : profile.patients,
      staff: database
        .get('users')
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
        )
        .observe(),
    };
  }),
)(SessionsNotesDueScreen);
