import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PercentageSlider from 'src/hook-form/percentage-slider';

interface Props {
  rules?: any;
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  helper?: string;
}

const HookFormPercentageSlider = (props: Props) => {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      rules={props.rules}
      render={({field}) => <PercentageSlider {...props} {...field} />}
      name={props.name}
      defaultValue={props.defaultValue}
    />
  );
};

export default HookFormPercentageSlider;
