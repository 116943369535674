import React, {forwardRef} from 'react';
import {Field} from 'src/modules/form-builder/utils/types';
import {View} from 'react-native';
import {formInputs} from 'src/modules/form-builder/components/inputs';

const FieldDetails = forwardRef(({field}: {field: Partial<Field>}) => {
  const FieldType = formInputs[field.componentType].details;

  return (
    <View>
      <FieldType {...field} />
    </View>
  );
});

export default FieldDetails;
