import moment from 'moment/moment';
import _ from 'lodash';

export const exportRate = async (database: any, sets: any[], events: any[]) => {
  let _exportData = [
    [
      'Instance',
      'Date',
      'Time Stamp',
      'Duration Time',
      'Count',
      'Intensity',
      'Rate',
      'ABC',
    ],
  ];

  const eventsBySetId = _.groupBy(events, 'set.id');
  for (const set of sets) {
    const index: number = sets.indexOf(set);
    const duration = moment.duration(
      moment(set?.endTimestamp).diff(moment(set?.startTimestamp)),
    );
    const min = duration.minutes();
    const sec = duration.seconds();
    let durationTime = min > 0 ? min.toString() + ' min ' : '';
    if (sec > 0) {
      durationTime += sec.toString() + ' sec';
    }

    const count = eventsBySetId[set?.id]?.length || 0;
    const durationSecs = duration.seconds() || 0;
    const rate = durationSecs !== 0 ? (count / durationSecs).toFixed(2) : 0;

    let abcStr = '';
    for (const promptId of set?.prompts) {
      const prompt = await database.get('prompts').find(promptId);
      abcStr += prompt.name.replaceAll('"', "'") + '\n';
    }

    _exportData.push([
      `${index + 1}`,
      moment(set?.startTimestamp).format('MM/DD/YY'),
      moment(set?.startTimestamp).format('HH:mm:ss') +
        ' - ' +
        (set?.endTimestamp
          ? moment(set?.endTimestamp).format('HH:mm:ss')
          : 'Incomplete'),
      durationTime,
      count.toString(),
      _.capitalize(set?.intensity),
      rate > 0 ? rate + ' per seconds' : '',
      abcStr,
    ]);
  }
  return _exportData;
};
