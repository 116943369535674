import React from 'react';
import {useTranslations} from 'src/providers/translation';
import {IDeveloperField} from 'dromo-uploader-react';
import {DataItem} from 'src/design-system';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import HookFormDurationInput from 'src/hook-form-wrapper/duration-input';

interface DisplayProps {
  value: boolean;
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'intervalOccurs',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Interval Occurs',
  key,
  type: 'string',
  description: 'Time should be put in as seconds ex. 2000',
});

export const Value = (value: string) => {
  const d = Number(value || 0);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  return (
    (h > 0 ? h + ' hours ' : '') +
    (m > 0 ? m + ' minutes ' : '') +
    (s > 0 ? s + ' seconds ' : '')
  );
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>{translations('interval_occurs')}</Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name}: Props) => {
  const translations = useTranslations();

  return (
    <HookFormDurationInput
      name={(name ? name + '.' : '') + 'intervalOccurs'}
      label={translations('interval_occurs')}
    />
  );
};

export default Input;
