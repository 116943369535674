import React from 'react';
import {Form, FormSectionHeader} from 'src/design-system';
import {
  Address,
  AddressLine,
  AddressUse,
  BirthDate,
  City,
  Diagnosis,
  Email,
  FirstName,
  Gender,
  IntakeDate,
  LastName,
  Name,
  PhoneNumber,
  PostalCode,
  Relationship,
  State,
  Identifier,
  Staff,
  Country,
} from 'src/form-inputs';
import {IDeveloperField, IRowHook} from 'dromo-uploader-react';
import {MaskService} from 'react-native-masked-text';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';

interface Props {
  error?: string;
  enableReinitialize?: boolean;
  handleSubmit?: (payload: any, formik: any) => void;
  validationSchema: any;
  initialValues: any;
  cancelled: (formik: any) => void;
}

export const Import: (...args: any) => IDeveloperField[] = (
  practitioners: any[],
) => [
  Identifier.Import('identifier'),
  FirstName.Import('firstName'),
  LastName.Import('lastName'),
  Gender.Import('gender'),
  BirthDate.Import('birthDate'),
  AddressUse.Import('use'),
  Country.Import('country'),
  AddressLine.Import('line'),
  City.Import('city'),
  State.Import('state'),
  PostalCode.Import('postalCode'),
  FirstName.Import('caregiverFirstName', 'Caregiver', false),
  LastName.Import('caregiverLastName', 'Caregiver', false),
  PhoneNumber.Import('caregiverMobilePhone', 'Caregiver'),
  Email.Import('caregiverEmail', 'Caregiver', false),
  Relationship.Import('caregiverRelationship', 'Caregiver'),
  IntakeDate.Import('startDate'),
  Staff.Import('staff', practitioners),
];

export const RowHooks: IRowHook[] = [
  record => {
    const newRecord = record;
    newRecord.row.birthDate.value = MaskService.toMask(
      'datetime',
      newRecord.row.birthDate.value,
      {
        format: 'MM/DD/YYYY',
      },
    );

    newRecord.row.caregiverMobilePhone.value = MaskService.toMask(
      'custom',
      newRecord.row.caregiverMobilePhone.value,
      {
        mask: '(999) 999-9999',
      },
    );

    newRecord.row.startDate.value = MaskService.toMask(
      'datetime',
      newRecord.row.startDate.value,
      {
        format: 'MM/DD/YYYY',
      },
    );

    return newRecord;
  },
];

const PatientForm = ({
  error,
  enableReinitialize,
  handleSubmit,
  cancelled,
  initialValues,
  validationSchema,
}: Props) => {
  return (
    <Form
      error={error}
      enableReinitialize={enableReinitialize}
      handleSubmit={handleSubmit}
      cancelled={cancelled}
      initialValues={initialValues}
      validationSchema={validationSchema}>
      <>
        <FormSectionHeader title="Personal Information" />
        <Identifier.Input valueKey={'patient'} />
        <Name.Input valueKey={'patient'} />
        <Gender.Input valueKey={'patient'} />
        <BirthDate.Input valueKey={'patient'} />
        <Address.Input valueKey={'patient'} />
        <FormSectionHeader title="Guardian Information" />
        <Name.Input valueKey={'guardian'} />
        <Relationship.Input valueKey={'guardian'} />
        <PhoneNumber.Input valueKey={'guardian'} />
        <Email.Input valueKey={'guardian'} />
        <FormSectionHeader title="Clinic Information" />
        <IntakeDate.Input valueKey={'clinic'} />
        <Staff.Input name={'Clinician'} valueKey={'clinic'} />
        <Diagnosis.Input valueKey={'clinic'} />
        <RHSeparator height={20} />
      </>
    </Form>
  );
};

export default PatientForm;
