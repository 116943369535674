import React, {useMemo} from 'react';
import {useDraggable} from '@dnd-kit/core';
import {nanoid} from 'nanoid';
import {useRef} from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {formInputs} from 'src/modules/form-builder/components/inputs';

const DraggableSidebarField = ({fieldData}: any) => {
  const styles = useStyle();
  const memoId = useMemo(() => nanoid(), []);
  const id = useRef(memoId);

  const {attributes, listeners, setNodeRef} = useDraggable({
    id: id.current,
    data: {
      id: id.current,
      fieldData,
      fromSidebar: true,
    },
  });

  return (
    <View
      style={[
        styles.paddingM,
        styles.justifyCenter,
        styles.borderRadius,
        styles.marginSM,
        styles.elevation,
      ]}
      ref={setNodeRef}
      {...listeners}
      {...attributes}>
      <View
        style={[styles.row, styles.justifySpaceBetween, styles.alignCenter]}>
        <View style={[styles.row, styles.justifyCenter]}>
          <Icon
            size={24}
            name={fieldData.icon}
            style={[styles.paddingMRight]}
          />
          <Text style={[Typography.P3, styles.marginAutoTop]}>
            {fieldData.name}
          </Text>
        </View>
        <View style={[styles.justifyEnd]}>
          <Icon name={'drag'} size={30} color={Colors.GRAY_500} />
        </View>
      </View>
    </View>
  );
};

const ComponentSideBar = ({fieldsRegKey}: {fieldsRegKey: number}) => {
  const styles = useStyle();
  const fields = Object.keys(formInputs).map(
    (key: string) => formInputs[key].definition,
  );

  return (
    <View key={fieldsRegKey} style={[styles.paddingSM, styles.height]}>
      {fields.map(fieldData => {
        return (
          <DraggableSidebarField key={fieldData.name} fieldData={fieldData} />
        );
      })}
    </View>
  );
};
export default ComponentSideBar;
