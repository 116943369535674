import React from 'react';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {Q} from '@nozbe/watermelondb';
import {CardList} from 'src/design-system';
import {UserCard} from 'src/modules/users/components';
import withState from 'src/redux/wrapper';

const UserList = ({
  users,
  viewCallback,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
  canArchive,
  archiveCallback,
}: any) => {
  return (
    <CardList
      items={users}
      loading={false}
      emptyIcon={'card-account-mail'}
      emptyMessage={'No results for staff found'}
      renderItem={({item}) => (
        <UserCard
          user={item}
          viewCallback={() => viewCallback(item.id)}
          canEdit={canEdit}
          editCallback={() => editCallback(item.id)}
          canDelete={canDelete}
          deleteCallback={() => deleteCallback(item)}
          canArchive={canArchive}
          archiveCallback={() => archiveCallback(item)}
        />
      )}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['search', 'authentication', 'filter'],
    ({search, database, authentication, filter}: any) => {
      const queries = [];
      if (filter?.staffState && filter?.staffState.includes('archived')) {
        queries.push(Q.where('state', 'archived'));
      } else {
        queries.push(Q.where('deleted_at', null));
      }
      const tagIds = [...filter.relatedServices];
      return {
        users: search
          ? database
              .get('users')
              .query(
                ...(tagIds.length
                  ? [Q.on('taggables', Q.where('tag_id', Q.oneOf(tagIds)))]
                  : []),
                Q.or(
                  Q.where(
                    'first_name',
                    Q.like(`%${Q.sanitizeLikeString(search)}%`),
                  ),
                  Q.where(
                    'last_name',
                    Q.like(`%${Q.sanitizeLikeString(search)}%`),
                  ),
                ),
                ...queries,
                Q.where('_partition', authentication.selectedGroup),
                Q.sortBy('last_name', Q.asc),
              )
              .observe()
          : database
              .get('users')
              .query(
                ...(tagIds.length
                  ? [Q.on('taggables', Q.where('tag_id', Q.oneOf(tagIds)))]
                  : []),
                ...queries,
                Q.where('_partition', authentication.selectedGroup),
                Q.sortBy('last_name', Q.asc),
              )
              .observe(),
      };
    },
  ),
)(UserList);
