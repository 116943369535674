import React from 'react';
import {IconButton} from 'react-native-paper';

const MenuButton = (props: any) => {
  return (
    <IconButton
      icon="menu"
      onPress={() => {
        props.navigation.toggleDrawer();
      }}
    />
  );
};

export default MenuButton;
