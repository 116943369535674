import React, {useEffect, useRef, useState} from 'react';
import {View, Text, FlatList} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import ViewShot from 'react-native-view-shot';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Menu} from 'src/design-system';
import {Colors, Typography} from 'src/styles';
import SessionSummaryDataTable from 'src/modules/session/components/summary-data-table';
import {SessionMessage} from 'src/modules/session/components';
import {useNavigation} from '@react-navigation/core';
import {Separator} from 'src/common-components/atoms';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import moment from 'moment';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {exportCsv} from 'src/common-utils/export-csv';
import {exportGraph} from 'src/common-utils/export-graph';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import SessionInfoDisplay from 'src/modules/organization/components/session-info-display';
import ClientInfoDisplay from 'src/modules/organization/components/client-info-display';
import OrganizationDisplay from 'src/modules/organization/components/organization-display';
import {mergeMap, of} from 'rxjs';
import {Q} from '@nozbe/watermelondb';
import RenderFormInputs from 'src/modules/form-builder/components/render-form-inputs';
import {
  Instance,
  InstanceDiagnosis,
  Note,
  NoteTemplateVersion,
  Session,
  User,
} from 'src/models';
import {Display as SignatureDisplay} from 'src/modules/session/components/signature-item';
import SessionNoteListItem from '../session-note-list-item';
import {map} from '@nozbe/watermelondb/utils/rx';

const SessionRecap = ({
  session,
  appointment,
  patient,
  staff,
  // canEdit = false,
  isSupervision = false,
  diagnoses,
  sessionHistory = false,
  noteTemplate,
  careTeam,
  organization,
  noteTemplateValue,
  signatures,
  notes,
  billingCodes,
  supervisedSession,
  role,
}: any) => {
  const screenRef = useRef();
  const navigation = useNavigation();
  const styles = useStyle();
  const translations = useTranslations();

  const [loading, setLoading] = useState(false);
  const methods = useForm({
    defaultValues: {
      note: session?.note,
      address: session?.address ?? '',
    },
  });

  const updateNote = async () => {
    await session.updateEntity({
      submissionTimestamp: new Date(), //?
    });
  };

  const watchedNote = useWatch({
    control: methods.control,
    name: 'note',
  });

  const [concattedNotes, setConcattedNotes] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    methods.setValue('note', session?.note);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const concatNotes = (notesArr: Note[]) => {
    let resultStr = '';

    for (const note of notesArr) {
      resultStr += `${note?.description} \n`;
    }

    return resultStr;
  };

  useEffect(() => {
    setConcattedNotes(concatNotes(notes));
  }, [notes]);

  const exportPDF = async () => {
    const fileName = `${patient.firstName}_${
      patient.lastName
    }_session_export_${moment(session.date).format('L')}_${moment(
      session.date,
    ).format('LT')}`.replace(/[:/ ]/g, '_');
    setLoading(true);
    await exportGraph(screenRef, fileName, 'pdf');
    setLoading(false);
  };

  const createCSVFile = () => {
    let _exportData = [
      [
        'Client Name',
        'Staff Name',
        'Session Date',
        'Session Start Time',
        'Session Edited Start Time ',
        'Session End Time',
        'Session Edited End Time',
        'Session Note',
      ],
      [
        `${patient.firstName} ${patient.lastName}`,
        `${staff[0].firstName} ${staff[0].lastName}`,
        `${session.date}`,
        `${session.startTimestamp}`,
        `${session.editedStartTimestamp}`,
        `${session.endTimestamp}`,
        `${session.editedEndTimestamp}`,
        `${session.note} ${
          notes ? notes.map((note: any) => note?.description) : ''
        }`,
      ],
    ];
    exportCsv(
      `${patient.firstName}_${patient.lastName}_session_export_${moment(
        session.date,
      )
        .format('L')
        .split('/')
        .join('_')}`,
      _exportData,
    );
  };

  const options = [
    {
      onPress: exportPDF,
      title: translations('pdf'),
      icon: 'file-pdf-box',
    },
    {
      onPress: createCSVFile,
      title: translations('csv'),
      icon: 'file-excel',
    },
  ];

  return (
    <View>
      <View
        style={[
          styles.container,
          styles.justifyEnd,
          styles.marginTop,
          styles.marginHorizontal,
        ]}>
        {!sessionHistory ? (
          <>
            {role?.sessionEdit ? (
              <View style={[styles.paddingRight]}>
                {!editMode ? (
                  <RHButton
                    mode="outlined"
                    textColor={Colors.RAVEN_BLACK}
                    onPress={() => {
                      setEditMode(true);
                    }}>
                    <Icon
                      name="pencil"
                      size={18}
                      style={[styles.marginSMRight]}
                    />
                    Edit
                  </RHButton>
                ) : (
                  <>
                    <RHButton
                      mode="contained"
                      color={Colors.TERTIARY_TEAL}
                      onPress={() => setEditMode(false)}>
                      <Icon
                        name="content-save"
                        size={18}
                        style={[styles.marginSMRight]}
                      />
                      SAVE
                    </RHButton>
                  </>
                )}
              </View>
            ) : (
              <></>
            )}
            <Menu
              anchor={
                <RHButton
                  loading={loading}
                  secondary
                  textColor={Colors.RAVEN_BLACK}
                  mode="outlined">
                  <Icon
                    name={'export-variant'}
                    size={18}
                    style={[styles.marginSMRight]}
                  />
                  {translations('export_recap')}
                </RHButton>
              }
              options={options}
            />
            <Separator width={16} />
            <RHButton
              onPress={() => {
                methods.handleSubmit(updateNote);
                navigation.navigate('Dashboard');
              }}
              secondary
              textColor={Colors.RAVEN_BLACK}
              mode="outlined">
              <Icon name={'home'} size={18} style={[styles.marginSMRight]} />
              {translations('finalize_session')}
            </RHButton>
          </>
        ) : (
          <></>
        )}
      </View>
      {editMode ? (
        <SessionMessage
          session={session}
          showSubmitButton={false}
          fromRecap={true}
        />
      ) : (
        <ViewShot ref={screenRef}>
          <View
            style={[
              styles.width,
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
            ]}>
            <Text style={[Typography.H4, styles.textColorPrimary]}>
              {isSupervision
                ? translations('supervision_summary')
                : translations('summary')}
            </Text>
          </View>
          <Text style={[Typography.H5, styles.marginTop]}>
            {translations('selected_template')}
          </Text>
          <View
            style={[
              styles.marginLVertical,
              styles.cardFlat,
              styles.padding,
              styles.borderRadius,
            ]}>
            <Text style={[Typography.P3]}>
              {noteTemplate?.title || translations('no_template_selected')}
            </Text>
          </View>
          {noteTemplate?.organizationInformation ? (
            <OrganizationDisplay
              currentNoteTemplate={noteTemplate}
              organization={organization}
            />
          ) : null}
          {noteTemplate?.clientInformation ? (
            <ClientInfoDisplay
              currentNoteTemplate={noteTemplate}
              patient={patient}
              staff={staff}
              diagnoses={diagnoses}
              careTeam={careTeam}
            />
          ) : null}
          {noteTemplate?.sessionInformation ? (
            <FormProvider {...methods}>
              <SessionInfoDisplay
                cptCodes={billingCodes}
                currentNoteTemplate={noteTemplate}
                appointment={appointment}
                session={session}
                staff={staff}
                startDate={'editedStartTimestamp'}
                endDate={'editedEndTimestamp'}
              />
            </FormProvider>
          ) : null}
          {noteTemplate?.clinicalNote ? (
            <>
              <Text style={[Typography.H5, styles.marginLVertical]}>
                {translations('note')}
              </Text>
              <View
                style={[
                  styles.cardFlat,
                  styles.row,
                  styles.justifySpaceBetween,
                  styles.padding,
                  styles.marginTop,
                ]}>
                <View style={[styles.paddingTop, styles.width]}>
                  <FormProvider {...methods}>
                    <Text style={[Typography.P3, styles.paddingBottom]}>
                      {watchedNote}
                    </Text>
                    {session.showAuthorsAndTimestamps ? (
                      <FlatList
                        keyExtractor={item => item.id}
                        data={notes}
                        renderItem={({item}) => (
                          <View style={[styles.paddingSMVertical]}>
                            <SessionNoteListItem
                              item={item}
                              canEdit={false}
                              showAuthorsAndTimestamps={true}
                            />
                          </View>
                        )}
                      />
                    ) : (
                      <Text style={[Typography.P3, styles.paddingBottom]}>
                        {concattedNotes}
                      </Text>
                    )}
                  </FormProvider>
                </View>
              </View>
            </>
          ) : null}
          <View
            style={[
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
              styles.marginHorizontal,
              styles.marginLVertical,
            ]}
          />
          <View style={[styles.marginLVertical]}>
            {noteTemplate?.sessionData ? (
              <>
                <Text style={[Typography.H5]}>{translations('data')}</Text>
                <View style={[styles.marginLVertical]}>
                  <SessionSummaryDataTable
                    session={
                      session.type === 'supervision'
                        ? supervisedSession
                        : session
                    }
                    filters={{
                      types: [
                        noteTemplate?.skill ? 'skill' : '',
                        noteTemplate?.behavior ? 'behavior' : '',
                      ],
                      programs: [],
                    }}
                  />
                </View>
              </>
            ) : null}
          </View>

          {noteTemplate.additionalFields ? (
            <View style={[styles.marginLVertical]}>
              <>
                <Text style={[Typography.H5]}>
                  {translations('additional_fields')}
                </Text>
                <View
                  style={[
                    styles.cardFlat,
                    styles.padding,
                    styles.marginLVertical,
                  ]}>
                  <RenderFormInputs
                    values={noteTemplateValue?.formBuilderValues}
                    mode={'output'}
                    outputValues={session?.additionalFields}
                  />
                </View>
              </>
            </View>
          ) : (
            <></>
          )}

          <Text style={[Typography.H5, styles.marginLVertical]}>
            {isSupervision ? 'Signature' : 'Signatures'}
          </Text>
          <View
            style={[
              styles.marginLVertical,
              styles.padding,
              styles.borderRadius,
              styles.backgroundColorWhite,
            ]}>
            {isSupervision ? (
              <>
                {session?.supervisionSignature ? (
                  <SignatureDisplay
                    value={{signature: session?.supervisionSignature}}
                    label={'Supervision Signature'}
                  />
                ) : (
                  <View style={[styles.container]}>
                    <View style={[styles.flex, styles.marginAutoRight]}>
                      {signatures.map((signature: any) => {
                        return <SignatureDisplay value={signature} />;
                      })}
                    </View>
                  </View>
                )}
              </>
            ) : (
              <>
                {session?.therapistSignature ? (
                  <>
                    <View style={[styles.container]}>
                      <View style={[styles.flex, styles.marginAutoRight]}>
                        <SignatureDisplay
                          value={{signature: session?.therapistSignature}}
                          label={'Therapist Signature'}
                        />
                      </View>
                      <View style={[styles.flex, styles.marginAutoRight]}>
                        <SignatureDisplay
                          value={{signature: session?.therapistSignature2}}
                          label={'Second Therapist Signature'}
                        />
                      </View>
                    </View>
                    <View style={[styles.container]}>
                      <View style={[styles.flex, styles.marginAutoRight]}>
                        <SignatureDisplay
                          value={{signature: session?.parentSignature1}}
                          label={'Parent Signature'}
                        />
                      </View>
                      <View style={[styles.flex, styles.marginAutoRight]}>
                        <SignatureDisplay
                          value={{signature: session?.parentSignature2}}
                          label={'Second Parent Signature'}
                        />
                      </View>
                    </View>
                  </>
                ) : (
                  <View style={[styles.container]}>
                    <View style={[styles.flex, styles.marginAutoRight]}>
                      {signatures.map((signature: any) => {
                        return <SignatureDisplay value={signature} />;
                      })}
                    </View>
                  </View>
                )}
              </>
            )}
          </View>
        </ViewShot>
      )}
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({session, database, profile}: any) => ({
    appointment: session.appointment,
    patient: session.patient,
    signatures: session.sessionSignatures,
    editor: session.noteEditedBy
      ? database.get(User.table).findAndObserve(session.noteEditedBy)
      : database.get(User.table).findAndObserve(profile.id),
    notes: session.activeNotes,
    billingCodes: session
      .observe()
      .pipe(map(currentSession => currentSession.cpt || [])),
    supervisedSessions:
      session.type === 'supervision'
        ? database
            .get(Session.table)
            .query(
              Q.where('type', 'session'),
              Q.where('appointment_id', session.appointmentId),
              Q.where('session_date', session.sessionDate),
              Q.where('deleted_at', null),
            )
        : of([]),
    role: profile?.role,
  })),
  withObservables([], ({appointment, supervisedSessions}: any) => ({
    staff: appointment.allStaff,
    supervisedSession: supervisedSessions.length
      ? supervisedSessions[0]
      : of({}),
  })),

  withObservables([], ({patient}: any) => {
    if (patient.id) {
      return {
        patientDiagnoses: patient.activeDiagnoses,
      };
    }
    return {
      patientDiagnoses: of([]),
    };
  }),
  withObservables([], ({database, patientDiagnoses}: any) => {
    return {
      diagnoses: database
        .get(InstanceDiagnosis.table)
        .query(
          Q.where(
            'id',
            Q.oneOf(
              patientDiagnoses.map(diagnosis => diagnosis.instanceDiagnosisId),
            ),
          ),
        ),
    };
  }),
  withObservables(['session'], ({database, session}: any) => ({
    noteTemplate: session.noteTemplateVersion,
    noteTemplateValue: database
      .get(NoteTemplateVersion.table)
      .findAndObserve(session.noteTemplateVersionId),
  })),
  withObservables(['authentication'], ({authentication, database}: any) => ({
    organization: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
  withObservables([], ({patient}: any) => ({
    careTeam: patient.careTeam,
  })),
)(SessionRecap);
