import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const setFilters = createAsyncThunk(
  'schedulingFilters/setFilters',
  async (args: any) => {
    return args;
  },
);

const schedulingFilters = createSlice({
  name: 'schedulingFilters',
  initialState: {
    selectedFilters: {
      scheduleBy: 'status',
      locations: [],
      types: [],
      participants: [],
    },
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setFilters.fulfilled, (state, {payload}) => {
      Object.assign(state, {selectedFilters: payload});
    });
  },
});

export default schedulingFilters.reducer;
