import styled from '@emotion/styled';

export default styled.div`
  .fc-toolbar-title {
    font-family: 'Campton-Book';
    align-self: center;
  }

  .fc .fc-col-header-cell-cushion {
    font-family: 'Campton-Book';
  }

  .fc .fc-timegrid-slot-label-cushion {
    font-family: 'Campton-Book';
  }

  .fc .fc-button {
    font-family: 'Campton-Book';
  }
  .fc-event-title {
    font-family: 'Campton-Book';
    text-overflow: ellipsis;
    margin-right: 3px;
  }

  .fc-event-time {
    font-family: 'Campton-Book';
  }

  .body {
    font-family: 'Campton-Book';
  }

  .fc-daygrid-day-number {
    font-family: 'Campton-Book';
  }

  .fc-popover-title {
    font-family: 'Campton-Book';
  }

  .fc .fc-daygrid-day-bottom {
    font-family: 'Campton-Book';
  }

  .fc-view-harness {
    height: auto !important;
  }

  .fc {
    flex: 1;
  }
  .fc-v-event {
    border: none !important;
    background-color: rgba(0, 0, 0, 100) !important;
  }

  .fc .fc-next-button:hover {
    color: none !important;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-radius: 0;
  }

  .fc .fc-button-primary:not(:disabled).fc-button-active {
    color: #1a252f;
    background-color: #fff;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 2px;
    border-color: #151e27;
  }

  .fc .fc-button-primary {
    color: #1a252f;
    background-color: #fff;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .fc-button {
    outline: none !important;
    box-shadow: none !important;
    background-color: none;
  }
  .fc-button-primary:hover {
    color: none !important;
  }
  .fc .fc-toolbar-title {
    font-size: 1.75em;
    margin: 0;
    padding-top: 0;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 8px;
  }
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    color: #1a252f;
    background-color: #fff;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 2px;
    border-color: #151e27;
  }
  .fc-dayGridMonth-button {
    border-radius: 0;
  }
  .fc-timeGridDay-button {
    border-radius: 0;
  }
  .fc-today-button .fc-button .fc-button-primary:hover {
    border-width: 0px;
  }
  .fc-day-today {
    background: #ffffff !important;
  }
  .fc-day-today .fc-daygrid-day-top {
    background-color: #3144f3;
    color: white;
    border-radius: 50%;
    margin-left: auto;
    margin-top: 2px;
    margin-right: 2px;
    width: 25px;
    height: 25px;
    justify-content: center;
  }
  .fc-day-today .fc-col-header-cell-cushion {
    font-family: 'Campton-Book';
    background-color: #3144f3;
    color: white;
    width: 100%;
  }

  .fc-list-event-time {
    font-family: 'Campton';
    font-style: normal;
    font-size: 14px;
    letter-spacing: 20px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: black;
  }

  .fc .fc-list-event-dot {
    color: black;
    background-color: black;
  }

  .fc-list-day-text {
    font-family: 'Campton';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #25262f;
  }

  .fc-list-day-side-text {
    font-family: 'Campton';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #25262f;
  }

  .fc-list-event-dot {
    display: none;
  }
`;
