import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import _ from 'lodash';
import {IDeveloperField} from 'dromo-uploader-react';
import RadioInput from 'src/hook-form-wrapper/radio-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
  label?: string;
}

interface Props {
  name?: string;
  label?: string;
}

export const genderValues = [
  {value: 'male', title: 'Male', label: 'Male'},
  {value: 'female', title: 'Female', label: 'Female'},
  {value: 'other', title: 'Other', label: 'Other'},
];

export const Import = (
  key: string = 'gender',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Gender (${genderValues.map(value => value.label).join(', ')})`,
  key,
  type: 'select',
  selectOptions: genderValues,
  validators: [],
});

export const Value = (value: string) => {
  return _.capitalize(value);
};

export const Display = ({value, label}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>
        {label ? label : translations('gender')}
      </Text>
      <Text style={[Typography.P3]}>{Value(value)}</Text>
    </DataItem>
  );
};

export const Input = ({name, label}: Props) => {
  const translations = useTranslations();

  return (
    <RadioInput
      name={(name ? name + '.' : '') + 'gender'}
      label={label ? label : translations('gender')}
      required={false}
      items={genderValues}
    />
  );
};

export const validation = () => Yup.string().required('Gender is required');

export default Input;
