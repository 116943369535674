import React from 'react';
import {PayerFormDisplay} from 'src/modules/billing/components/payer-form';
import {PayerPlansDisplay} from 'src/modules/billing/components/payer-plan-form';
import {PayerStaffDisplay} from 'src/modules/billing/components/payer-staff-form';
import {ScrollView} from 'react-native';
import {useStyle} from 'src/providers/style';

const Review = ({values}: any) => {
  const styles = useStyle();

  return (
    <ScrollView contentContainerStyle={[styles.padding]}>
      <PayerFormDisplay values={values} />
      <PayerPlansDisplay values={values} />
      <PayerStaffDisplay values={values} />
    </ScrollView>
  );
};

export default Review;
