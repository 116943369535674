import React from 'react';
import {Text, FlatList} from 'react-native';
import {useNavigation} from '@react-navigation/core';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Typography} from 'src/styles';
import {
  RenderAppointment,
  APPOINTMENTCARDTYPE,
} from 'src/modules/scheduling/components';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';
import moment from 'moment';
import NoResults from 'src/common-components/noResults';
import {useStyle} from 'src/providers/style';

interface Props {
  user: any;
  appointments: any[];
  role: any;
}

const Sessions = ({user, appointments, role}: Props) => {
  const navigation = useNavigation();
  const styles = useStyle();

  const goToNewSession = () => {
    navigation.navigate('ScheduleAppointment', {
      practitioner: user,
    });
  };

  return (
    <>
      {appointments?.length === 0 ? (
        <NoResults
          iconName={'calendar-today'}
          secondaryMessage={'No sessions today'}
        />
      ) : (
        <>
          <Text style={[Typography.P1, styles.textColorSecondary]}>
            Next Session
          </Text>
          <FlatList
            data={appointments}
            keyExtractor={item => item.id}
            renderItem={({item}: any) => {
              return (
                <RenderAppointment
                  date={moment(item.date).toDate()}
                  appointment={item}
                  type={APPOINTMENTCARDTYPE.USER_CARD}
                  canEdit={role?.appointmentEdit}
                  canDelete={role?.appointmentDelete}
                  deleteCallback={() => {}}
                />
              );
            }}
          />
        </>
      )}
      <RHButton
        style={[styles.marginVertical]}
        secondary
        onPress={goToNewSession}
        mode={'contained'}>
        SCHEDULE A SESSION
      </RHButton>
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(['user'], ({user}: any) => ({
    user,
    appointments: user.futureAppointments,
  })),
)(Sessions);
