import React from 'react';
import {Colors} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {NoteTemplateVersion, Session} from 'src/models';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {styles} from 'src/modules/scheduling/components';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {Q} from '@nozbe/watermelondb';

const AppointmentCardButton = ({
  appointment,
  organization,
  date,
  session,
  navigation,
  isSupervision,
  patients,
}: any) => {
  const database = useDatabase();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  let sessionFinished =
    session?.startTimestamp !== undefined &&
    session?.endTimestamp !== undefined &&
    session?.startTimestamp !== null &&
    session?.endTimestamp !== null;
  let sessionCompleted =
    session?.submissionTimestamp !== undefined &&
    session?.submissionTimestamp !== null;

  let buttonLabel = 'SESSION';
  let buttonIcon = 'launch';
  let background = Colors.RAVEN_BLACK;

  if (sessionFinished && !sessionCompleted) {
    buttonLabel = 'FINISH NOTE';
    buttonIcon = 'clipboard-text-outline';
    background = Colors.TORCH_RED_DARK;
  } else if (sessionCompleted) {
    buttonLabel = 'VIEW RECAP';
  }

  const sessionDate = date
    ? date
    : session?.date
    ? session?.date
    : appointment?.date;
  let sessionStartTime = new Date(sessionDate);
  sessionStartTime.setHours(appointment?.start.getHours() - 1);
  sessionStartTime.setMinutes(appointment?.start.getMinutes());
  sessionStartTime.setSeconds(0);
  let sessionEndTime = new Date(sessionDate);
  sessionEndTime.setHours(appointment?.end.getHours() + 1);
  sessionEndTime.setMinutes(appointment?.end.getMinutes());
  sessionEndTime.setSeconds(0);

  return (
    <RHButton
      secondary
      mode="contained"
      color={background}
      onPress={async () => {
        if (!session) {
          let noteTemplate: any;
          if (appointment.noteTemplateId) {
            const noteTemplateVersions = await database
              .get(NoteTemplateVersion.table)
              .query(
                Q.where('note_template_id', appointment.noteTemplateId),
                Q.sortBy('published_at', 'desc'),
                Q.take(1),
              );
            if (noteTemplateVersions.length) {
              noteTemplate = noteTemplateVersions[0];
            }
          }
          session = await database?.write(async () => {
            return database?.get(Session.table).create(entity => {
              entity.partition = selectedGroup;
              entity.type = isSupervision ? 'supervision' : 'session';
              entity.date = date;
              entity.sessionDate = moment(date).format('YYYY-MM-DD');
              entity.startTimestamp = null;
              entity.endTimestamp = null;
              entity.editedStartTimestamp = null;
              entity.editedEndTimestamp = null;
              entity.note = noteTemplate
                ? noteTemplate.template
                : organization.noteTemplate;
              entity.noteTemplateId = appointment.noteTemplateId;
              entity.noteTemplateVersionId = noteTemplate.id;
              entity.therapistSignature = null;
              entity.therapistSignature2 = null;
              entity.parentSignature1 = null;
              entity.parentSignature2 = null;
              entity.attestationTimestamp = null;
              entity.submissionTimestamp = null;
              entity.appointment.id = appointment.id;
              entity.patient.id = patients[0].id;
              entity.user.id = null;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
        if (sessionFinished && !sessionCompleted) {
          navigation.navigate('SessionMessage', {
            id: session.id,
          });
        } else if (sessionCompleted) {
          navigation.navigate('SessionRecap', {
            id: session.id,
          });
        } else {
          navigation.navigate('SessionCollect', {
            id: session.id,
          });
        }
      }}>
      <Icon
        name={buttonIcon}
        size={14}
        color={Colors.RAVEN_WHITE}
        style={styles.icon}
      />
      {buttonLabel}
    </RHButton>
  );
};

export default AppointmentCardButton;
