import React from 'react';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Formik} from 'formik';
import {Colors} from 'src/styles';
import {View} from 'react-native';
import {Error, Separator, Success} from 'src/common-components/atoms';
import {useStyle} from 'src/providers/style';

interface Props {
  error?: string;
  alert?: string;
  enableReinitialize?: boolean;
  handleSubmit?: (payload: any, formik: any) => void;
  cancelled?: (formik: any) => void;
  initialValues: any;
  validationSchema: any;
  saveText?: string;
  cancelText?: string;
  children?: JSX.Element[] | JSX.Element;
  style?: any;
}

const Form = ({
  error = undefined,
  alert = undefined,
  enableReinitialize = true,
  handleSubmit = async (values, formik) => console.log(values, formik),
  cancelled = () => {},
  initialValues,
  validationSchema,
  children,
  saveText = 'Save',
  cancelText = 'Cancel',
  style = {},
}: Props) => {
  const styles = useStyle();

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formik => {
        return (
          <View style={[styles.flex, style]}>
            {error ? (
              <Error title={'Invalid Request'} message={error} />
            ) : (
              <></>
            )}
            {alert ? (
              <Success title={'Processing Request'} message={alert} />
            ) : (
              <></>
            )}
            {children}
            <View
              style={[
                styles.flex,
                styles.row,
                styles.justifySpaceBetween,
                styles.alignCenter,
              ]}>
              <RHButton
                onPress={() => cancelled(formik)}
                textColor={Colors.RAVEN_BLACK}
                color="black"
                mode="outlined">
                {cancelText}
              </RHButton>
              <Separator width={10} />
              <RHButton mode="contained" onPress={formik.submitForm}>
                {saveText}
              </RHButton>
            </View>
          </View>
        );
      }}
    </Formik>
  );
};

export default Form;
