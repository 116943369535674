import {Q} from '@nozbe/watermelondb';
import {
  text,
  field,
  relation,
  lazy,
  children,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Program extends Base {
  static table = 'programs';
  static associations = {
    programs: {type: 'belongs_to', key: 'program_id'},
    patients: {type: 'belongs_to', key: 'patient_id'},
    targets: {type: 'has_many', foreignKey: 'program_id'},
    program_prompt: {type: 'has_many', foreignKey: 'program_id'},
    program_tag: {type: 'has_many', foreignKey: 'program_id'},
    sets: {type: 'has_many', foreignKey: 'program_id'},
    session_programs: {type: 'has_many', foreignKey: 'program_id'},
  };

  @relation('program', 'program_id') program;
  @relation('patients', 'patient_id') patient;
  // @relation('users', 'created_by') createdBy;

  @lazy
  prompts = this.collections
    .get('prompts')
    .query(
      Q.where('enabled', true),
      Q.where('deleted_at', null),
      Q.on(
        'program_prompt',
        Q.and(Q.where('program_id', this.id), Q.where('deleted_at', null)),
      ),
    );

  @lazy
  behaviors = this.collections
    .get('prompts')
    .query(
      Q.where('enabled', true),
      Q.where('deleted_at', null),
      Q.on(
        'program_prompt',
        Q.and(Q.where('program_id', this.id), Q.where('deleted_at', null)),
      ),
      Q.or(
        Q.where('prompt_type', 'Consequences'),
        Q.where('prompt_type', 'Antecedents'),
      ),
    );

  @lazy
  programPrompts = this.collections
    .get('program_prompt')
    .query(Q.where('program_id', this.id));

  @lazy
  tags = this.collections
    .get('tags')
    .query(
      Q.where('enabled', true),
      Q.where('deleted_at', null),
      Q.on(
        'program_tag',
        Q.and(Q.where('program_id', this.id), Q.where('deleted_at', null)),
      ),
    );

  @lazy
  programTags = this.collections
    .get('program_tag')
    .query(Q.where('program_id', this.id));

  @children('targets') programTargets;

  @lazy targets = this.programTargets.extend(Q.sortBy('rank', Q.asc));

  @lazy activeTargets = this.targets.extend(
    Q.where('deleted_at', Q.eq(null)),
    Q.sortBy('rank', Q.asc),
  );

  @lazy enabledTargets = this.targets.extend(
    Q.where('enabled', Q.eq(true)),
    Q.where('deleted_at', Q.eq(null)),
    Q.sortBy('rank', Q.asc),
  );

  @children('sets') sets;

  @lazy
  sessions = this.collections
    .get('sessions')
    .query(Q.on('session_programs', 'program_id', this.id));

  @lazy
  sessionPrograms = this.collections
    .get('session_programs')
    .query(Q.where('program_id', this.id));

  @text('type') type;
  @text('patient_id') patientId;
  @text('method') method;
  @text('unit') unit;
  @text('name') name;
  @text('objective') objective;
  @text('stimulus_description') stimulusDescription;
  @text('teaching_instructions') teachingInstructions;
  @text('materials_needed') materialsNeeded;
  @field('number_of_trials') numberOfTrials;
  @field('interval') interval;
  @field('interval_automation') intervalAutomation;
  @field('interval_occurs') intervalOccurs;
  @field('interval_recurrences') intervalRecurrences;
  @field('intensity') intensity;
  @field('baseline') baseline;
  @field('baseline_probes') baselineProbes;
  @field('baseline_correct_probes') baselineCorrectProbes;
  @field('baseline_sessions') baselineSessions;
  @field('mastery_value') masteryValue;
  @field('mastery_sessions') masterySessions;
  @field('mastery_staff') masteryStaff;
  @field('mastery_staff_minimum') masteryStaffMinimum;
  @field('maintenance') maintenance;
  @field('maintenance_value') maintenanceValue;
  @field('maintenance_sessions') maintenanceSessions;
  @field('maintenance_cadence_weekly') maintenanceCadenceWeekly;
  @field('maintenance_cadence_monthly') maintenanceCadenceMonthly;
  @field('maintenance_allowance') maintenanceAllowance;
  @text('maintenance_cadence') maintenanceCadence;
  @text('state') state;
  @text('adjustment') adjustment;
}
