import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import React, {useState} from 'react';
import SessionRecap from 'src/modules/session/components/session-recap';
import {BaseScreen, TabBar} from 'src/design-system';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';
import {ScrollView} from 'react-native';
import {Session, User} from 'src/models';
import {Q} from '@nozbe/watermelondb';
import {useStyle} from 'src/providers/style';

const Navigator = createMaterialTopTabNavigator();

const SessionRecapScreen = ({
  session,
  supervisedSession,
  route,
  role,
  profile,
}: any) => {
  const styles = useStyle();
  const [filter, setFilter] = useState<any>({
    types: [],
    programs: [],
  });

  const isSupervision = session.type === 'supervision';

  return (
    <BaseScreen header={false}>
      {isSupervision ? (
        <TabBar navigator={Navigator} location={'SessionRecap'} route={route}>
          <Navigator.Screen name="Supervision">
            {() => (
              <ScrollView
                style={[styles.width, styles.paddingHorizontal]}
                contentContainerStyle={[styles.affixPadding]}>
                <SessionRecap
                  filter={filter}
                  setFilter={setFilter}
                  route={route}
                  isSupervision={true}
                  canEdit={role?.sessionEdit}
                  profile={profile}
                  session={session}
                />
              </ScrollView>
            )}
          </Navigator.Screen>
          <Navigator.Screen name="Direct Therapy">
            {() => (
              <ScrollView
                style={[styles.width, styles.paddingHorizontal]}
                contentContainerStyle={[styles.affixPadding]}>
                <SessionRecap
                  filter={filter}
                  setFilter={setFilter}
                  route={route}
                  canEdit={role?.sessionEdit}
                  profile={profile}
                  session={supervisedSession}
                />
              </ScrollView>
            )}
          </Navigator.Screen>
        </TabBar>
      ) : (
        <ScrollView
          style={[styles.width, styles.paddingHorizontal]}
          contentContainerStyle={[styles.affixPadding]}>
          <SessionRecap
            filter={filter}
            setFilter={setFilter}
            route={route}
            canEdit={role?.sessionEdit}
            profile={profile}
            session={session}
          />
        </ScrollView>
      )}
    </BaseScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({route, database}: any) => ({
    session: database.get(Session.table).findAndObserve(route.params.id),
  })),
  withObservables([], ({database, session}: any) => ({
    supervisedSessions: database
      .get(Session.table)
      .query(
        Q.where('type', 'session'),
        Q.where('appointment_id', session.appointmentId),
        Q.where('session_date', session.sessionDate),
        Q.where('deleted_at', null),
      ),
  })),
  withObservables([], ({supervisedSessions}: any) => ({
    supervisedSession: supervisedSessions.length
      ? supervisedSessions[0]
      : of({}),
  })),
  withObservables([], ({authentication, database}: any) => ({
    profile: authentication.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
)(SessionRecapScreen);
