import React, {useEffect, useState} from 'react';
import Modal from 'src/design-system/modal';
import WebView from 'react-native-webview';
import {toDataURL} from 'src/common-utils/toDataURL';
import {ActivityIndicator} from 'react-native-paper';
import {readRemoteFile} from 'react-papaparse';
import CsvViewer from './CsvViewer';
import {useStyle} from 'src/providers/style';

const FileViewer = ({file, show, accessToken}: any) => {
  const [fileURL, setFileURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCsv, setIsCsv] = useState(false);
  const [csvData, setCsvData] = useState<any>([]);
  const styles = useStyle();

  useEffect(() => {
    setIsLoading(true);
    const fetchDataURL = async () => {
      await toDataURL(file.fileLocation, setFileURL);
      setIsLoading(false);
    };
    if (file.fileLocation) {
      if (file.fileLocation.endsWith('.csv')) {
        setIsCsv(true);
        readRemoteFile(file.fileLocation, {
          downloadRequestHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
          download: true,
          complete: ({data}) => {
            setCsvData(data);
          },
        });
      }
      fetchDataURL();

      return () => {
        setIsCsv(false);
      };
    }
  }, [file?.fileLocation, accessToken]);

  return (
    <Modal show={show} fullScreen={true} title={file.fileName}>
      {isLoading ? (
        <ActivityIndicator />
      ) : isCsv ? (
        <CsvViewer csvData={csvData} />
      ) : (
        <WebView source={{uri: fileURL}} style={[styles.windowHeight75]} />
      )}
    </Modal>
  );
};

export default FileViewer;
