import React from 'react';
import {View, Text} from 'react-native';
import HookFormCheckboxInput from 'src/hook-form-wrapper/checkbox-input';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';
import {
  InputProps,
  OutputProps,
  PreviewProps,
} from 'src/modules/form-builder/utils/types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {DataItem} from 'src/design-system';
import {Separator} from 'src/common-components/atoms';
import {uuid} from 'src/common-utils/uuid';

export const Details = ({required, label, options}: any) => {
  const styles = useStyle();

  return (
    <View>
      <View style={[styles.paddingMVertical]}>
        <Text style={[Typography.P3]}>{required ? label + '*' : label}</Text>
      </View>
      {options?.map((item, index) => (
        <View key={`option-index-${index}`} style={[styles.row]}>
          <Icon
            name={index === 0 ? 'checkbox-marked' : 'checkbox-blank-outline'}
            size={20}
          />
          <Separator width={10} />
          <View
            style={[
              styles.paddingSMVertical,
              styles.paddingRight,
              styles.width,
            ]}>
            <Text style={[Typography.LABEL]}>{item.label}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

export const Definition = () => ({
  name: 'Checklist',
  label: 'Label',
  placeholder: '',
  componentType: 'CHECKLIST',
  icon: 'checkbox-outline',
  options: [
    {
      value: uuid(),
      label: 'Option 1',
    },
  ],
  helper: '',
  required: false,
});

export const Input = ({item, name = ''}: InputProps) => {
  const styles = useStyle();
  return (
    <View>
      <Text
        style={[
          Typography.LABEL,
          styles.textColorSecondary,
          styles.marginSMBottom,
        ]}>
        {item?.required ? item?.label + '*' : item?.label}
      </Text>
      <HookFormCheckboxInput
        name={name}
        items={item.options}
        required={item.required}
        rules={
          item.required
            ? {
                required: 'This field is required.',
              }
            : {}
        }
      />
    </View>
  );
};

export const Output = ({item, value}: OutputProps) => {
  const styles = useStyle();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>{item.label}</Text>
      <View>
        {item?.options?.map(checklistItem => (
          <View style={[styles.row]}>
            <Icon
              name={
                value?.includes(checklistItem.value)
                  ? 'checkbox-marked'
                  : 'checkbox-blank-outline'
              }
              size={20}
              style={[styles.paddingMRight]}
            />
            <Text style={[Typography.P3, styles.paddingXSTop]}>
              {checklistItem.label}
            </Text>
          </View>
        ))}
      </View>
    </DataItem>
  );
};

export const Preview = ({item}: PreviewProps) => {
  const styles = useStyle();

  return (
    <View>
      <View style={[styles.paddingMVertical]}>
        <Text style={[Typography.P3]}>{item.label}</Text>
      </View>
      {item.options?.map((option: any, index: any) => (
        <View style={[styles.row]}>
          <Icon
            name={index === 0 ? 'checkbox-marked' : 'checkbox-blank-outline'}
            size={20}
            style={[styles.paddingMRight]}
          />
          <View
            style={[
              styles.paddingSMVertical,
              styles.paddingRight,
              styles.width,
            ]}>
            <Text style={[Typography.LABEL]}>{option.label}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};
