import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
  label?: string;
}

interface Props {
  name?: string;
  label?: string;
}

export const Import = (
  key: string = 'TIN',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'TIN',
  key,
});

const resolveDisplayValue = (value: string) => {
  return value;
};

const TINDisplay = ({value, label}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <DataItem>
      <Text style={[Typography.H6]}>{label ? label : translations('tin')}</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const TINInput = ({name, label}: Props) => {
  const translations = useTranslations();

  return (
    <HookFormInput
      name={(name ? name + '.' : '') + 'tin'}
      label={label ? label : translations('tin')}
      required={false}
    />
  );
};

export const validation = () =>
  Yup.string().required('Tax Identification Number is required');

export const Display = TINDisplay;
export const Input = TINInput;
export const Value = resolveDisplayValue;

export default TINInput;
