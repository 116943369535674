import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {IconButton} from 'react-native-paper';
import Collapsible from 'react-native-collapsible';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {PlanDisplay} from 'src/modules/billing/components/payer-plan-form';
import {Payer} from 'src/models';

interface Props {
  item: any;
  canEdit: boolean;
  editSelected: (value: any) => void;
  deleteCallback: (value: any) => void;
  deletePlanCallback: (value: any) => void;
  canDelete: boolean;
  plans: any;
}

export const PayerItemDisplay = compose(
  withDatabase,
  withObservables([], ({database, payer}: any) => ({
    payer: database.get(Payer.table).findAndObserve(payer),
  })),
  withObservables([], ({payer}: any) => ({
    plans: payer.activePlans,
  })),
)(({plans, payer}: any) => {
  const styles = useStyle();

  return (
    <>
      <View style={[styles.paddingTop, styles.flex]}>
        <Text style={Typography.P2_MEDIUM}>{payer.name}</Text>
      </View>
      {plans.length ? (
        <>
          {plans.map((plan: any, index: number) => {
            return (
              <View
                key={`plan-${plan.id}-${index}`}
                style={[styles.paddingBottom]}>
                <PlanDisplay value={plan} />
              </View>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
});

const PayerItem = ({
  item,
  canEdit,
  editSelected,
  canDelete,
  deleteCallback,
  plans,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const styles = useStyle();

  return (
    <View style={[styles.card, styles.paddingHorizontal, styles.justifyCenter]}>
      <TouchableOpacity
        style={[styles.justifyCenter]}
        onPress={() => setCollapsed(!collapsed)}>
        <View style={[styles.row]}>
          <View style={[styles.paddingVertical, styles.flex]}>
            <Text style={Typography.P2_MEDIUM}>{item.name}</Text>
            <Text style={[Typography.CAPTION, {color: Colors.TEXT_SECONDARY}]}>
              {plans.length} plan{plans.length !== 1 ? 's' : ''}
            </Text>
          </View>
          <View
            style={[
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
            ]}>
            {canEdit ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="pencil"
                onPress={editSelected}
              />
            ) : (
              <></>
            )}
            {canDelete ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="trash-can"
                onPress={deleteCallback}
              />
            ) : (
              <></>
            )}
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={!collapsed ? 'chevron-up' : 'chevron-down'}
              onPress={() => setCollapsed(!collapsed)}
            />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible collapsed={collapsed} style={[styles.paddingVertical]}>
        {plans.length ? (
          <>
            {plans.map((plan: any, index: number) => {
              return (
                <View
                  key={`plan-${plan.id}-${index}`}
                  style={[styles.paddingSMBottom]}>
                  <PlanDisplay value={plan} />
                </View>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </Collapsible>
    </View>
  );
};

export default compose(
  withObservables([], ({item}: any) => ({
    plans: item?.activePlans,
  })),
)(PayerItem);
