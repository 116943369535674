import {View} from 'react-native';
import {Separator} from 'src/common-components/atoms';
import React, {ReactNode} from 'react';
import {useStyle} from 'src/providers/style';

interface Props {
  left: ReactNode;
  right: ReactNode;
  orientation?: string;
}

const InputGroup = ({
  left = <></>,
  right = <></>,
  orientation = 'container',
}: Props) => {
  const styles = useStyle();

  return (
    <View style={[styles[orientation]]}>
      <View style={styles.flex}>{left}</View>
      <Separator width={20} />
      <View style={styles.flex}>{right}</View>
    </View>
  );
};

export default InputGroup;
