import {relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Medication extends Base {
  static table = 'medications';

  static associations = {
    patients: {type: 'belongs_to', key: 'patient_id'},
  };

  @relation('patients', 'patient_id') patient;

  @text('medication') medication;
  @text('dosage') dosage;
  @text('frequency') frequency;
  @text('archived') archived;
}
