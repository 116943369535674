import React from 'react';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Remit} from 'src/models/remit';
import BaseScreen from 'src/design-system/base-screen';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import RemitClaimListItem from 'src/modules/billing/components/remit-claim-list-item';
import {FormSectionHeader} from 'src/design-system';

const RemitProfileScreen = ({remit, remitClaims}: any) => {
  const styles = useStyle();

  return (
    <BaseScreen header={false}>
      <View style={[styles.padding, styles.backgroundColorWhite]}>
        <FormSectionHeader title={'Remit Details - Claim ID: ????'} />
        {/* <Text>{JSON.stringify(remit.response)}</Text> */}
        {remitClaims.map((remitClaim: any, index: number) => {
          return (
            <View style={[styles.paddingTop]}>
              <Text>Remit Claim {index}</Text>
              <RemitClaimListItem remitClaim={remitClaim} remit={remit} />
            </View>
          );
        })}
      </View>
    </BaseScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['route'], ({route, database}: any) => ({
    remit: database.get(Remit.table).findAndObserve(route.params.id),
  })),
  withObservables([], ({remit}: any) => ({
    remitClaims: remit.remitClaims,
  })),
)(RemitProfileScreen);
