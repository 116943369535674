import React from 'react';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {Platform, Text, TouchableOpacity, View} from 'react-native';
import DraggableFlatList from 'react-native-draggable-flatlist';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {DataItem, FormSectionHeader} from 'src/design-system';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {Colors, Typography} from 'src/styles';
import Severity, {
  Display as SeverityDisplay,
} from 'src/hook-form-inputs/severity';
import EnabledDiagnosisCodes from 'src/hook-form-inputs/enabled-diagnosis-codes';
import DiagnosisDateInput, {
  Display as DiagnosisDateDisplay,
} from 'src/hook-form-inputs/diagnosis-date';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {InstanceDiagnosis} from 'src/models';
import {Separator} from 'src/common-components/atoms';

const isWeb = Platform.OS === 'web';

export const defaultDiagnosis = {
  diagnosis: '',
  severity: '',
  instanceDiagnosisId: '',
  diagnosisDate: new Date(),
};

const DiagnosesForm = ({
  hideExtraFields = false,
  showTitle = true,
  showBoxNumbers = false,
}) => {
  const styles = useStyle();
  const {control, setValue} = useFormContext();

  const {
    fields: diagnosisFields,
    append: diagnosisAppend,
    remove: diagnosisRemove,
    replace: diagnosisReplace,
  } = useFieldArray({
    name: 'diagnoses',
    control,
  });

  const watchedDiagnoses = useWatch({
    name: 'diagnoses',
    control,
  });

  const reorderDiagnoses = useWatch({
    name: 'reorderDiagnoses',
    control,
  });

  return (
    <>
      <View
        style={[styles.row, styles.alignCenter, styles.justifySpaceBetween]}>
        {showTitle ? <FormSectionHeader title={'Diagnoses'} /> : <></>}
        {Platform.OS === 'web' ? (
          <HookFormSwitchInput
            name={'reorderDiagnoses'}
            label={'Drag to Reorder'}
            showHelper={false}
          />
        ) : null}
      </View>
      {reorderDiagnoses ? (
        <DraggableFlatList
          data={watchedDiagnoses}
          keyExtractor={(item, index) => `item-${index}`}
          onDragEnd={({data}) => {
            setValue('diagnoses', data);
          }}
          renderItem={({item, drag, getIndex}) => {
            const index = getIndex();
            return (
              <DiagnosisDragItem
                item={item}
                index={index}
                drag={drag}
                hideExtraFields={hideExtraFields}
              />
            );
          }}
        />
      ) : (
        <>
          {diagnosisFields.map((item: any, index: number) => {
            if (item?.deletedAt) {
              return null;
            }
            return (
              <View key={item.id}>
                <View style={[styles.row]}>
                  <FormSectionHeader
                    title={`Diagnosis ${index + 1} ${
                      showBoxNumbers
                        ? `(Box 21${String.fromCharCode(
                            '@'.charCodeAt(0) + (index + 1),
                          )})`
                        : ''
                    }`}
                  />
                  {index !== 0 && (
                    <View style={[styles.row, styles.marginLeft]}>
                      <RHButton
                        icon="minus-circle-outline"
                        mode="outlined"
                        color={Colors.RAVEN_WHITE}
                        textColor={Colors.PRIMARY_300}
                        onPress={async () => {
                          if (item?._id) {
                            const diagFields = [...diagnosisFields];
                            diagFields[index] = {
                              ...item,
                              deletedAt: new Date(),
                            };
                            diagnosisReplace(diagFields);
                          } else {
                            diagnosisRemove(index);
                          }
                        }}>
                        REMOVE
                      </RHButton>
                    </View>
                  )}
                </View>
                <View style={[styles.width, styles.container]}>
                  <View style={[styles.flex]}>
                    <EnabledDiagnosisCodes name={`diagnoses.${index}`} />
                  </View>
                  {hideExtraFields ? (
                    <></>
                  ) : (
                    <>
                      <Separator width={20} height={20} />
                      <View style={[styles.flex]}>
                        <Severity name={`diagnoses.${index}`} />
                      </View>
                      <Separator width={20} height={20} />
                      <View style={[styles.flex]}>
                        <DiagnosisDateInput name={`diagnoses.${index}`} />
                      </View>
                    </>
                  )}
                </View>
              </View>
            );
          })}
          <View style={[styles.row, styles.marginBottom]}>
            <RHButton
              icon="plus-circle-outline"
              mode="outlined"
              color={Colors.RAVEN_WHITE}
              textColor={Colors.RAVEN_BLACK}
              onPress={() => {
                diagnosisAppend(defaultDiagnosis);
              }}>
              ADD MORE
            </RHButton>
          </View>
        </>
      )}
    </>
  );
};

export const DiagnosisDragItem = compose(
  withDatabase,
  withObservables(['item'], ({item, database}: any) => {
    return {
      diagnosis: item.instanceDiagnosisId
        ? database
            .get(InstanceDiagnosis.table)
            .findAndObserve(item?.instanceDiagnosisId)
        : of({}),
    };
  }),
)(({item, index, drag, diagnosis, hideExtraFields = false}: any) => {
  const styles = useStyle();
  return (
    <View
      key={`item-${index}`}
      style={[styles.row, styles.backgroundColorWhite, styles.alignCenter]}>
      <TouchableOpacity
        style={[styles.paddingRight, styles.justifyCenter]}
        onPressIn={isWeb ? drag : () => {}}
        onLongPress={!isWeb ? drag : () => {}}
        delayLongPress={50}>
        <Icon
          color={Colors.GRAY_400}
          style={[styles.padding]}
          name="reorder-horizontal"
          size={20}
        />
      </TouchableOpacity>
      <View style={[styles.justifyCenter]}>
        <Text style={[Typography.H6]}>{`Diagnosis ${index + 1}:`}</Text>
      </View>
      <View
        style={[
          styles.container,
          styles.marginHorizontal,
          styles.alignSelfCenter,
          styles.justifyEvenly,
          styles.flex,
        ]}>
        <View style={[styles.flex05]}>
          <DataItem>
            <Text style={[Typography.H6]}>Diagnosis Code</Text>
            <Text style={[Typography.P3]}>{diagnosis.name || ''}</Text>
          </DataItem>
        </View>
        {hideExtraFields ? (
          <></>
        ) : (
          <>
            <View style={[styles.flex05]}>
              <SeverityDisplay value={item?.severity} />
            </View>
            <View style={[styles.flex05]}>
              <DiagnosisDateDisplay value={item?.diagnosisDate} />
            </View>
          </>
        )}
      </View>
    </View>
  );
});

export default DiagnosesForm;
