import React, {useEffect, useRef, useState} from 'react';
import {Linking, ScrollView, Text, View} from 'react-native';
import {Snackbar} from 'react-native-paper';
import ViewShot from 'react-native-view-shot';
import ClientSelect from 'src/hook-form-inputs/client';
import {Typography} from 'src/styles';
import {BaseScreen} from 'src/design-system';
import ClientReport from 'src/modules/reports/components/client-report-beta';
import moment from 'moment';
// import {RHButton} from 'src/common-components/custom-ui-helpers';
// import AntDesignIcon from 'react-native-vector-icons/AntDesign';
// import {exportGraph} from 'src/common-utils/export-graph';
import {subtractNMonths} from 'src/common-utils/subtractNMonths';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useStyle} from 'src/providers/style';

const emailURL = `mailto:support@ravenhealth.com?subject=${encodeURIComponent(
  'Beta Reporting Feedback',
)}`;

const Reports = ({}: any) => {
  const screenRef = useRef();
  const styles = useStyle();

  // const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [exportName, setExportName] = useState<string>('');
  const [showExportDlg, setShowExportDlg] = useState<string | undefined>(
    undefined,
  );

  const defaultValues = {
    startDate: subtractNMonths(1),
    endDate: new Date(),
    program: '',
    target: '',
    clients: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const watchedStartDate = useWatch({
    control: methods.control,
    name: 'startDate',
  });
  const watchedEndDate = useWatch({control: methods.control, name: 'endDate'});
  const watchedClient = useWatch({control: methods.control, name: 'clients'});
  useEffect(() => {
    if (watchedClient) {
      methods.reset({
        ...defaultValues,
        clients: watchedClient,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedClient]);

  // const exportPDF = async () => {
  //   setLoading(true);
  //   const fileName =
  //     Platform.OS === 'web' ? exportName : exportName.replaceAll('/', '-');
  //   await exportGraph(screenRef, fileName, 'pdf');
  //   setShowExportDlg(`${fileName}.pdf`);
  //   setLoading(false);
  // };

  // const exportJpeg = async () => {
  //   setLoading(true);
  //   await exportGraph(screenRef, exportName, 'jpg');
  //   setShowExportDlg(`${exportName}.jpg`);
  //   setLoading(false);
  // };

  // const exportPng = async () => {
  //   setLoading(true);
  //   await exportGraph(screenRef, exportName, 'png');
  //   setShowExportDlg(`${exportName}.png`);
  //   setLoading(false);
  // };

  // const options = [
  //   {
  //     onPress: exportPDF,
  //     title: 'PDF',
  //     icon: 'file-png-box',
  //   },
  //   {
  //     onPress: exportJpeg,
  //     title: 'JPEG',
  //     icon: 'file-jpg-box',
  //   },
  //   {
  //     onPress: exportPng,
  //     title: 'PNG',
  //     icon: 'file-png-box',
  //   },
  // ];

  return (
    <BaseScreen
      title="Reports"
      // right={
      //   watchedClient ? (
      //     <View style={[styles.row, styles.alignCenter, styles.justifyEnd]}>
      //       {Platform.OS === 'web' ? (
      //         <Menu
      //           anchor={
      //             <RHButton
      //               secondary
      //               textColor={Colors.RAVEN_BLACK}
      //               mode="outlined">
      //               EXPORT{'  '}
      //               {<AntDesignIcon name={'download'} size={20} />}
      //             </RHButton>
      //           }
      //           options={options}
      //         />
      //       ) : (
      //         <IconButton
      //           loading={loading}
      //           icon={'download'}
      //           type={'outlined'}
      //           color={Colors.RAVEN_BLACK}
      //           onPress={() => exportPDF()}
      //         />
      //       )}
      //     </View>
      //   ) : (
      //     <></>
      //   )
      // }
      snackbar={
        <Snackbar
          visible={showExportDlg !== undefined}
          onDismiss={() => setShowExportDlg(undefined)}
          duration={3000}
          action={{
            label: 'Dismiss',
            onPress: () => {},
          }}>
          {showExportDlg} was downloaded.
        </Snackbar>
      }>
      <FormProvider {...methods}>
        <ScrollView style={[]}>
          <View
            style={[
              styles.flex,
              styles.card,
              styles.padding,
              styles.marginTop,
              styles.backgroundColorSecondary400,
            ]}>
            <Text style={[Typography.P3_BOLD, styles.paddingMBottom]}>
              Introducing Our New Beta Feature: ABA Therapy Data Visualization!
            </Text>
            <View style={[styles.row, styles.alignCenter]}>
              <Text style={[Typography.P3]}>
                We’re excited to launch this beta version and need your
                feedback! Tell us what you love, what you don’t, and how we can
                make it better:
                <Text
                  onPress={() => Linking.openURL(emailURL)}
                  style={[
                    Typography.P3_BOLD,
                    styles.textColorLink,
                    styles.paddingMLeft,
                  ]}>
                  Tell us!
                </Text>
              </Text>
            </View>
            <Text style={[Typography.P3]}>
              Additional enhancements will be launched weekly. Thank you for
              your help!
            </Text>
          </View>
          <View style={[styles.marginLHorizontal]}>
            <View style={[styles.marginL, styles.flex]}>
              <ClientSelect multiple={false} label={'Select Client'} />
            </View>
          </View>
          {watchedClient ? (
            <ViewShot ref={screenRef}>
              <ClientReport
                client={watchedClient}
                startDate={moment(watchedStartDate).format()}
                endDate={moment(watchedEndDate).format()}
                setExportName={setExportName}
              />
            </ViewShot>
          ) : (
            <></>
          )}
        </ScrollView>
      </FormProvider>
    </BaseScreen>
  );
};

export default Reports;
