import React, {createContext, useEffect, useState} from 'react';
import {Field} from '../../utils/types';
import {
  FormProvider as HookFormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';

interface FormContexType {
  editField: Field | undefined | null;
  handleSetEditField: (
    arg0: Field | null,
    arg1?: string | undefined,
    arg2?: number | undefined,
    arg3?: boolean | undefined,
  ) => void;
  removeField: (arg1: string) => void;
}

export const FormContext = createContext<FormContexType>({
  editField: null,
  handleSetEditField: () => {},
  removeField: () => {},
});

const FormProvider = ({
  children,
  name = '',
}: {
  children: React.ReactNode;
  name: string;
}) => {
  const [editField, setEditField] = useState<Field | null>();
  const formContext = useFormContext();
  const setValue = formContext ? formContext.setValue : () => {};

  const methods = useForm({
    defaultValues: formContext ? formContext.getValues(name) : {},
  });

  const watchedFormValues = useWatch({control: methods.control});

  useEffect(() => {
    if (name) {
      setValue(name, watchedFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFormValues]);

  useEffect(() => {
    if (editField) {
      const updatedRows = Object.keys(watchedFormValues)
        .filter(key => key !== editField?.id)
        .reduce((obj, key) => {
          obj[key] = {
            header: watchedFormValues[key].header,
            items: watchedFormValues[key].items,
          };
          return obj;
        }, {});
      methods.reset(updatedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editField]);

  const handleSetEditField = (
    value: Field | null,
    rowId: string | undefined,
    index: number | undefined,
    isRow: boolean | undefined = false,
  ) => {
    if (!value) {
      setEditField(null);
      return;
    }
    if (isRow) {
      setEditField({
        ...value,
        rowId,
        isRow: true,
      });
    } else {
      setEditField({
        ...value,
        rowId,
        index,
      });
    }
  };

  const removeField = (id: string) => {
    if (id in watchedFormValues) {
      const updatedRows = Object.keys(watchedFormValues)
        .filter(key => key !== id)
        .reduce((obj, key) => {
          obj[key] = {
            header: watchedFormValues[key].header,
            items: watchedFormValues[key].items,
          };
          return obj;
        }, {});
      methods.reset(updatedRows);
      setEditField(null);
      return;
    }

    if (editField?.id === id) {
      setEditField(null);
    }

    const container = Object.keys(watchedFormValues).find(group => {
      return watchedFormValues[group].items.find(item => item.id === id);
    });

    const updatedItems = {
      ...watchedFormValues,
      [container]: {
        header: watchedFormValues[container].header,
        items: watchedFormValues[container].items.filter(
          item => item.id !== id,
        ),
      },
    };

    methods.reset(
      Object.keys(updatedItems).reduce((obj: any, key: string) => {
        obj[key] = {
          header: watchedFormValues[key].header,
          items: updatedItems[key].items,
        };
        return obj;
      }, {}),
    );
  };

  return (
    <HookFormProvider {...methods}>
      <FormContext.Provider
        value={{
          editField,
          handleSetEditField,
          removeField,
        }}>
        {children}
      </FormContext.Provider>
    </HookFormProvider>
  );
};

export default FormProvider;
