import React from 'react';
import {
  FlatList,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {Typography} from 'src/styles';
import NotificationComponent from '../notification';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import PaperMenu from 'src/design-system/paper-menu';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import {useNavigation} from '@react-navigation/native';
import {useStyle} from 'src/providers/style';
import Pushy from 'pushy-react-native';
import {useTranslations} from 'src/providers/translation';
import NotificationSeparator from 'src/modules/home/components/notification-separator';

const Notifications = ({notifications = []}) => {
  const navigation = useNavigation();
  const styles = useStyle();
  const translations = useTranslations();

  //Set iOS Badge Count
  if (Platform.OS === 'ios') {
    Pushy.setBadge(notifications.length);
  }

  const markAsRead = () => {
    notifications.forEach(element => {
      element.read();
    });
    closeModal();
  };

  const [visible, setOpenModal] = React.useState(false);
  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  return (
    <PaperMenu
      visible={visible}
      onDismiss={closeModal}
      anchor={
        <TouchableOpacity onPress={openModal}>
          <View style={[styles.positionRelative, styles.marginMRight]}>
            {notifications.length !== 0 ? (
              <View style={[styles.circle]}>
                <Text
                  style={[
                    styles.alignCenter,
                    styles.alignSelfCenter,
                    styles.textColorWhite,
                  ]}>
                  {notifications.length >= 9 ? '9+' : notifications.length}
                </Text>
              </View>
            ) : (
              <></>
            )}
            <View style={[styles.paddingM]}>
              <Icon name="bell" size={30} />
            </View>
          </View>
        </TouchableOpacity>
      }>
      <SafeAreaView>
        <View
          style={[
            styles.flex,
            styles.padding,
            styles.menuMaxWidth,
            styles.menuMinWidth,
          ]}>
          <View style={[styles.row, styles.paddingBottom]}>
            <Text
              style={[
                styles.flex,
                Typography.P3,
                styles.marginSMBottom,
                styles.marginRight,
              ]}>
              {translations('notifications')}
            </Text>
            <TouchableOpacity
              onPress={() => {
                markAsRead();
              }}>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                {translations('mark_all_read')}
              </Text>
            </TouchableOpacity>
          </View>
          {notifications?.length > 0 ? (
            <FlatList
              keyExtractor={item => item.id}
              data={notifications}
              renderItem={({item}) => (
                <NotificationComponent
                  key={item.id}
                  notification={item}
                  navigation={navigation}
                />
              )}
              ItemSeparatorComponent={NotificationSeparator}
            />
          ) : (
            <View style={[styles.column, styles.alignCenter]}>
              <Icon name="bell" size={30} color={'grey'} />
              <Text
                style={[
                  Typography.P3,
                  styles.textColorPrimary,
                  styles.marginMTop,
                ]}>
                {translations('no_new_notifications')}
              </Text>
            </View>
          )}
          {/*<View style={styles.underline} />*/}
          {/*{notifications?.length > 100 ? (*/}
          {/*  <TouchableOpacity onPress={() => {}} style={[styles.paddingLVertical]}>*/}
          {/*    <Text style={[Typograph.H6]}>{'VIEW ALL NOTIFICATIONS >'}</Text>*/}
          {/*  </TouchableOpacity>*/}
          {/*) : (*/}
          {/*  <Text />*/}
          {/*)}*/}
        </View>
      </SafeAreaView>
    </PaperMenu>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => {
    return {
      profile: authentication?.userId
        ? database.get('users').findAndObserve(authentication.userId)
        : of(),
    };
  }),
  withObservables([], ({profile}: any) => {
    return {
      notifications: profile.unreadNotifications,
    };
  }),
)(Notifications);
