import React from 'react';
import AppleLogin from 'react-apple-login';
import AppleButton from '../apple-button';

const AppleSignIn = ({submit}: any) => {
  const login = (response: any) => {
    if (response?.authorization?.code) {
      submit({code: response.authorization.code});
    }
  };

  return (
    <AppleLogin
      clientId="com.ravenhealth.app.client"
      // @ts-ignore
      redirectURI={window.location.origin}
      usePopup={true}
      callback={login}
      scope="email name"
      responseMode="query"
      responseType="code"
      render={renderProps => <AppleButton login={renderProps.onClick} />}
    />
  );
};

export default AppleSignIn;
