import React, {useState} from 'react';
import {Modal, Portal, RadioButton, Checkbox} from 'react-native-paper';
import {Text, TouchableOpacity, View, ScrollView} from 'react-native';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, Typography} from 'src/styles';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import moment from 'moment';
import Collapsible from 'react-native-collapsible';
import _ from 'lodash';
import {useStyle} from 'src/providers/style';

const intensityArray = [
  {title: 'Mild', value: 'mild'},
  {title: 'Moderate', value: 'moderate'},
  {title: 'Severe', value: 'severe'},
];

const IntervalModal = ({
  program,
  currentSet,
  events,
  event,
  programPrompts,
}: any) => {
  let delta =
    (currentSet.endTimestamp.getTime() - currentSet.startTimestamp.getTime()) /
    1000;

  const prompts = _.groupBy(programPrompts, 'promptType') || [];

  const styles = useStyle();

  const [intensity, setIntensity] = useState(event?.intensity);
  const [eventPrompts, setEventPrompts] = useState(event?.prompts || []);

  const [hours] = useState(Math.floor(delta / 3600) % 24);
  const [minutes] = useState(Math.floor(delta / 60) % 60);
  const [seconds] = useState(Math.floor(delta % 60));

  const timeStr = () => {
    let str = '';
    if (hours > 0) {
      str = hours + ' hour ';
    }
    if (minutes > 0) {
      str += minutes + ' min ';
    }
    if (seconds > 0) {
      str += seconds + ' sec';
    }
    return str;
  };

  const submitBehaviorValue = async (behavior: string) => {
    event.updateEntity({
      value: behavior,
      intensity: behavior !== 'Y' ? '' : null,
      prompts: behavior !== 'Y' ? '' : null,
    });
  };

  const leadingZero = (value: number) => {
    return /^\d$/.test(value) ? '0' + value : value;
  };

  const checkPrompt = (id: any) => {
    const index = [...eventPrompts].findIndex((v: any) => {
      return v === id;
    });
    let values = [...eventPrompts];
    if (index === -1) {
      values.push(id);
    } else {
      values.splice(index, 1);
    }
    setEventPrompts(values);
  };

  const undo = () => {
    if (eventPrompts.length > 0) {
      setEventPrompts(eventPrompts.slice(0, -1));
    } else if (intensity !== '') {
      setIntensity('');
    } else {
      event.updateEntity({
        value: '',
      });
    }
  };
  const persistValues = () => {
    event.updateEntity({
      prompts: eventPrompts,
      intensity: intensity,
    });
    setEventPrompts([]);
    setIntensity('');
  };

  return (
    <Portal>
      <Modal
        dismissable={false}
        visible={!!events.length}
        onDismiss={() => {}}
        contentContainerStyle={[
          styles.borderRadius,
          styles.border,
          styles.borderColorGray,
          styles.marginLBottom,
          styles.backgroundColorGray,
          styles.width300,
          styles.alignSelfCenter,
        ]}>
        <View style={[styles.padding]}>
          <View style={[styles.row, styles.justifySpaceBetween]}>
            <Text
              style={[
                Typography.P2_BOLD,
                styles.textColorWhite,
                styles.marginSMBottom,
              ]}>
              {program?.name}
              {/*{sets?.length - 1 > 0 ? `- ${sets.length - 1}` : ''}*/}
            </Text>
            {event?.value !== '' ? (
              <TouchableOpacity onPress={undo}>
                <MaterialCommunityIcon
                  name={'undo'}
                  size={22}
                  color={Colors.RAVEN_WHITE}
                />
              </TouchableOpacity>
            ) : (
              <></>
            )}
          </View>
          <Text style={[Typography.CAPTION, styles.textColorSecondary]}>
            {program?.interval === 1
              ? `Did this behavior occur for the whole timespan of ${moment(
                  currentSet.startTimestamp,
                ).format('h:mm:ss')} - ${moment(currentSet.endTimestamp).format(
                  'h:mm:ss A',
                )} (${timeStr()})?`
              : program?.interval === 2
              ? `Did this behavior occur anytime during the span of ${moment(
                  currentSet.startTimestamp,
                ).format('h:mm:ss')} - ${moment(currentSet.endTimestamp).format(
                  'h:mm:ss A',
                )} (${timeStr()})?`
              : program?.interval === 3
              ? 'Is this behavior occurring now?'
              : ''}
          </Text>
          {event?.value === '' ? (
            <View style={[styles.column, styles.marginTop, styles.zIndex]}>
              <View
                style={[
                  styles.row,
                  styles.justifySpaceBetween,
                  styles.alignCenter,
                ]}>
                <Text style={[Typography.P2_MEDIUM, styles.textColorWhite]}>
                  {leadingZero(hours)}:{leadingZero(minutes)}:
                  {leadingZero(seconds)}
                </Text>
                <TouchableOpacity onPress={() => submitBehaviorValue('Y')}>
                  <View
                    style={[
                      styles.width40,
                      styles.height40,
                      styles.borderRadius20,
                      styles.alignCenter,
                      styles.justifyCenter,
                      styles.backgroundColorSuccessDark,
                      styles.border,
                      styles.borderColorSuccess,
                    ]}>
                    <MaterialCommunityIcon
                      size={20}
                      name="check"
                      color={Colors.RAVEN_WHITE}
                    />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => submitBehaviorValue('-')}>
                  <View
                    style={[
                      styles.width40,
                      styles.height40,
                      styles.borderRadius20,
                      styles.alignCenter,
                      styles.justifyCenter,
                      styles.backgroundColorPrimary800,
                      styles.border,
                      styles.borderColorGray400,
                    ]}>
                    <MaterialCommunityIcon
                      size={20}
                      name="minus"
                      color={Colors.RAVEN_WHITE}
                    />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => submitBehaviorValue('N')}>
                  <View
                    style={[
                      styles.width40,
                      styles.height40,
                      styles.borderRadius20,
                      styles.alignCenter,
                      styles.justifyCenter,
                      styles.backgroundColorErrorDark,
                      styles.border,
                      styles.borderColorError,
                    ]}>
                    <MaterialCommunityIcon
                      size={20}
                      name="close"
                      color={Colors.RAVEN_WHITE}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
        <Collapsible collapsed={event?.value === ''}>
          <View style={[styles.column]}>
            <View
              style={[
                styles.width,
                styles.borderBottomWidth,
                styles.borderColorGray,
              ]}
            />
            <ScrollView
              style={[styles.maxHeight250]}
              contentContainerStyle={[
                styles.paddingHorizontal,
                styles.marginLTop,
              ]}>
              {program?.intensity ? (
                <View
                  style={[
                    styles.positionRelative,
                    styles.zIndex100,
                    styles.paddingBottom,
                  ]}>
                  <View style={[styles.row, styles.justifySpaceBetween]}>
                    <Text
                      style={[
                        Typography.P2,
                        styles.textColorSecondary,
                        styles.marginSMVertical,
                      ]}>
                      Intensity
                    </Text>
                    <Text
                      style={[
                        Typography.P2,
                        styles.marginSMVertical,
                        styles.textTransformCapitalize,
                        intensity === 'mild'
                          ? styles.mildColor
                          : intensity === 'moderate'
                          ? styles.moderateColor
                          : styles.severeColor,
                      ]}>
                      {_.capitalize(intensity)}
                    </Text>
                  </View>
                  <RadioButton.Group
                    onValueChange={setIntensity}
                    value={intensity}>
                    <View style={[styles.row, styles.justifySpaceBetween]}>
                      {intensityArray.map((button: any, index: number) => {
                        return (
                          <View
                            key={`radio-key-${button?.value}-${index}`}
                            style={[styles.column, styles.alignCenter]}>
                            <View style={styles.justifyCenter}>
                              <RadioButton.Android
                                value={button.value}
                                color={
                                  intensity === 'mild'
                                    ? '#4FF5AF'
                                    : intensity === 'moderate'
                                    ? '#F7AA4F'
                                    : '#F12E5D'
                                }
                                uncheckedColor={'#909093'}
                              />
                            </View>
                            <Text
                              style={[
                                Typography.P2,
                                button.title === _.capitalize(intensity)
                                  ? button.title === 'Mild'
                                    ? styles.mildColor
                                    : button.title === 'Moderate'
                                    ? styles.moderateColor
                                    : styles.severeColor
                                  : styles.textColorSecondary,
                              ]}>
                              {button.title}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  </RadioButton.Group>
                </View>
              ) : (
                <></>
              )}
              {Object.keys(prompts).map((promptType: any) => {
                return (
                  <View
                    style={[styles.positionRelative]}
                    key={`promptType-key-${promptType}`}>
                    <Text
                      style={[
                        Typography.P2,
                        styles.marginSMVertical,
                        styles.textColorDisabled,
                      ]}>
                      {promptType}
                    </Text>
                    {prompts[promptType].map((prompt: any) => {
                      return (
                        <TouchableOpacity
                          key={`prompt-key-${promptType}-${prompt?.id}`}
                          style={[
                            styles.row,
                            styles.alignCenter,
                            styles.marginSMVertical,
                          ]}
                          onPress={() => {
                            checkPrompt(prompt?.id);
                          }}>
                          <Checkbox.Android
                            status={
                              (eventPrompts || []).findIndex((v: any) => {
                                return v === prompt?.id;
                              }) !== -1
                                ? 'checked'
                                : 'unchecked'
                            }
                            color={Colors.RAVEN_WHITE}
                            uncheckedColor={Colors.RAVEN_WHITE}
                          />
                          <Text style={[Typography.P2, styles.textColorWhite]}>
                            {prompt?.name}
                          </Text>
                        </TouchableOpacity>
                      );
                    })}
                  </View>
                );
              })}
            </ScrollView>
            <TouchableOpacity
              style={[
                styles.padding,
                styles.backgroundColorBlue,
                styles.borderBottomLeftRadius,
                styles.borderBottomRightRadius,
              ]}
              onPress={() => persistValues()}>
              <Text
                style={[
                  Typography.PRIMARY_BUTTON,
                  styles.textColorWhite,
                  styles.textAlignCenter,
                ]}>
                FINISHED
              </Text>
            </TouchableOpacity>
          </View>
        </Collapsible>
      </Modal>
    </Portal>
  );
};

export default compose(
  withObservables(['events'], ({program, events}: any) => ({
    event: events?.[0],
    programPrompts: program?.prompts || of([]),
  })),
  withObservables(['event'], ({event}: any) => ({
    currentSet: event?.set || of({}),
  })),
)(IntervalModal);
