import React from 'react';
import {NoteTemplateVersion} from 'src/models';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import _ from 'lodash';

interface Props {
  name: string;
  items: any[];
  label?: string;
}

const NoteTemplateSelect = ({
  name,
  items,
  label = 'Select Note Template',
}: Props) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'noteTemplateVersionId'}
      label={label}
      required={false}
      multiple={false}
      selectAll={false}
      items={items}
      showHelper={false}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => {
    return {
      noteTemplateVersions: database
        .get(NoteTemplateVersion.table)
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
          Q.where('published_at', Q.notEq(0)),
        ),
    };
  }),
  withObservables(['noteTemplateVersions'], ({noteTemplateVersions}: any) => {
    const groupedVersions = _.groupBy(
      noteTemplateVersions,
      (item: any) => item.noteTemplateId,
    );
    const newestVersions = [];
    for (const key in groupedVersions) {
      newestVersions.push(
        groupedVersions[key].sort((a: any, b: any) => b.version - a.version)[0],
      );
    }
    return {
      items: of(
        newestVersions.map((template: NoteTemplateVersion) => {
          return {
            label: `${template.title}`,
            value: template.id,
          };
        }),
      ),
    };
  }),
)(NoteTemplateSelect);
