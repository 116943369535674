import {Text} from 'react-native';
import {Typography} from 'src/styles';
import React from 'react';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {User} from 'src/models';

const UserName = ({participant}: any) => {
  const styles = useStyle();

  return (
    <Text
      style={[
        Typography.CAPTION,
        styles.textColorPrimary,
        styles.paddingSMVertical,
      ]}>
      {participant?.firstName} {participant?.lastName}
    </Text>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({database, id}: any) => {
    return {
      participant: database.get(User.table).findAndObserve(id),
    };
  }),
)(UserName);
