import moment from 'moment/moment';
import _ from 'lodash';

export const exportDuration = async (database: any, sets: any[]) => {
  let _exportData = [
    ['Date', 'Time Stamp', 'Instance', 'Intensity', 'Total', 'ABC'],
  ];
  for (const [index, set] of sets.entries()) {
    const duration = moment.duration(
      moment(set?.endTimestamp).diff(moment(set?.startTimestamp)),
    );
    const min = duration.minutes();
    const sec = duration.seconds();
    let durationTime = min > 0 ? min.toString() + ' min ' : '';
    if (sec > 0) {
      durationTime += sec.toString() + ' sec';
    }

    let abcStr = '';
    for (const promptId of set?.prompts) {
      const prompt = await database.get('prompts').find(promptId);
      abcStr += prompt.name.replaceAll('"', "'") + '\n';
    }

    _exportData.push([
      moment(set?.startTimestamp).format('MM/DD/YY'),
      moment(set?.startTimestamp).format('HH:mm:ss') +
        ' - ' +
        (set?.endTimestamp
          ? moment(set?.endTimestamp).format('HH:mm:ss')
          : 'Incomplete'),
      `${index + 1}`,
      _.capitalize(set?.intensity),
      durationTime,
      abcStr,
    ]);
  }
  return _exportData;
};
