import React from 'react';
import {Text} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';
import {
  InputProps,
  OutputProps,
  PreviewProps,
} from 'src/modules/form-builder/utils/types';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {TextInput} from 'react-native-paper';
import {DataItem} from 'src/design-system';

export const Details = ({required, label}: any) => {
  return (
    <TextInput
      disabled
      mode="outlined"
      label={required ? label + '*' : label}
      value={required ? label + '*' : label}
      placeholder={required ? label + '*' : label}
      onChangeText={() => {}}
      multiline={true}
      numberOfLines={3}
    />
  );
};

export const Definition = () => ({
  name: 'Form Field',
  label: '',
  placeholder: '',
  componentType: 'INPUT',
  icon: 'format-letter-case',
  options: [],
  helper: '',
  required: false,
});

export const Input = ({item, name = ''}: InputProps) => {
  return (
    <HookFormInput
      name={name}
      label={item.label}
      required={item.required}
      numberOfLines={3}
      rules={
        item.required
          ? {
              required: 'This field is required.',
            }
          : {}
      }
    />
  );
};

export const Output = ({item, value}: OutputProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>{item.label}</Text>
      <Text style={[Typography.P3]}>{value || ''}</Text>
    </DataItem>
  );
};

export const Preview = ({item}: PreviewProps) => {
  const styles = useStyle();

  return (
    <TextInput
      style={[styles.marginMBottom]}
      disabled
      mode="outlined"
      label={item.label || ''}
      value={''}
      placeholder={item.label || ''}
      numberOfLines={3}
      multiline={true}
    />
  );
};
