import React from 'react';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {Text, View} from 'react-native';
import {Typography} from 'src/styles';
import {NestableDraggableFlatList} from 'react-native-draggable-flatlist';
import TagListItem from 'src/modules/organization/components/tag-list-item';
import {updateRank} from 'src/common-utils/rank';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useStyle} from 'src/providers/style';

const TagTypeList = ({
  tagType,
  tags,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
}: any) => {
  const database = useDatabase();
  const styles = useStyle();

  return (
    <View key={'tag-type-' + tagType} style={[styles.marginBottom]}>
      <Text
        style={[Typography.P2, styles.marginLBottom, styles.paddingHorizontal]}>
        {tagType}
      </Text>
      <NestableDraggableFlatList
        nestedScrollEnabled={true}
        data={tags}
        renderItem={({item, index, drag, isActive}): any => (
          <TagListItem
            key={'tag-' + index}
            tag={item}
            drag={drag}
            isActive={isActive}
            canEdit={canEdit}
            editCallback={() => editCallback(item)}
            canDelete={canDelete}
            deleteCallback={() => deleteCallback(item)}
          />
        )}
        keyExtractor={(item, index) => `draggable-item-${item.id}-${index}`}
        onDragEnd={async ({data}: any) => updateRank(database, data)}
      />
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({tagType, database, authentication}: any) => ({
    tags: database
      ?.get('tags')
      .query(
        Q.where('deleted_at', null),
        Q.where('tag_type', tagType),
        Q.where('_partition', authentication.selectedGroup),
        Q.sortBy('rank', Q.asc),
      ),
  })),
)(TagTypeList);
