import moment from 'moment';
import React, {useState} from 'react';
import {Text, View} from 'react-native';
import {IconButton, Menu} from 'src/design-system';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {Colors, Typography} from 'src/styles';
import FilterChip from 'src/design-system/filter-chip';
import {statusArray} from 'src/hook-form-inputs/status-checkbox';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {Patient, User} from 'src/models';
import Location from 'src/modules/scheduling/components/location';

const SessionHistoryListItem = ({
  viewCallback,
  canDelete,
  deleteCallback,
  item,
  patient,
  user,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const options = [
    {
      onPress: () => viewCallback(item),
      title: translations('view_session'),
      icon: 'eye',
    },
  ];

  const [label] = useState(
    statusArray.find(sessionStatus => sessionStatus.value === item.status)
      ?.label ?? 'Unknown Status',
  );
  const [backgroundColor] = useState(
    statusArray.find(sessionStatus => sessionStatus.value === item.status)
      ?.color || Colors.RAVEN_BLACK,
  );

  if (canDelete) {
    options.push({
      onPress: () => deleteCallback(item.id),
      title: translations('delete_session'),
      icon: 'trash-can',
    });
  }

  return (
    <View
      style={[
        styles.row,
        styles.justifySpaceBetween,
        styles.paddingLHorizontal,
      ]}>
      <View style={[styles.row, styles.paddingLRight, styles.marginLVertical]}>
        <View style={[styles.paddingLLeft, styles.justifyCenter]}>
          <Text style={[Typography.P3, styles.textColorSecondary]}>
            {`${moment(item.date).format('MM/DD/YYYY')} ${moment(
              item.start,
            ).format('h:mmA')} - ${moment(item.end).format('h:mmA')}`}
            {' | '}
            {patient.firstName} {patient.lastName}
            {' | '}
            {user.firstName} {user.lastName}
          </Text>
        </View>
      </View>

      {canDelete ? (
        <View style={[styles.alignCenter, styles.justifyCenter, styles.row]}>
          <View style={[styles.marginMRight]}>
            <FilterChip
              label={label}
              backgroundColor={backgroundColor}
              textColor={Colors.RAVEN_BLACK}
            />
          </View>
          <Location value={item?.location} />
          <Menu
            anchor={<IconButton type={'icon'} icon={'dots-vertical'} />}
            options={options}
          />
        </View>
      ) : null}
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, item}: any) => ({
    patient: database.get(Patient.table).findAndObserve(item.patient),
    user: database.get(User.table).findAndObserve(item.user),
  })),
)(SessionHistoryListItem);
