import {Text, View, ScrollView, Platform, Keyboard} from 'react-native';
import React, {Dispatch, SetStateAction} from 'react';
import {IconButton} from 'src/design-system';
import {Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {Dialog, Portal} from 'react-native-paper';

interface Props {
  show: [boolean, Dispatch<SetStateAction<boolean>>];
  anchor?: JSX.Element;
  title: string;
  children?: JSX.Element[] | JSX.Element;
  footer?: JSX.Element[] | JSX.Element;
  closeButton?: boolean;
  closeCallback?: () => void;
  fullScreen?: boolean;
  scrollEnabled?: boolean;
  style?: any;
}

const ReactNativeModal = ({
  show: [showModal, setShowModal],
  anchor,
  title,
  children,
  footer = undefined,
  closeButton = true,
  closeCallback = () => {},
  fullScreen = false,
  scrollEnabled = true,
}: Props) => {
  const styles = useStyle();
  const [bottom, setBottom] = React.useState(0);

  React.useEffect(() => {
    function onKeyboardChange(e) {
      if (e?.endCoordinates?.screenY <= e?.startCoordinates?.screenY) {
        setBottom(e.endCoordinates.height / 2);
      } else {
        setBottom(0);
      }
    }

    if (Platform.OS === 'ios') {
      const subscription = Keyboard.addListener(
        'keyboardWillChangeFrame',
        onKeyboardChange,
      );
      return () => subscription.remove();
    }

    const subscriptions = [
      Keyboard.addListener('keyboardDidHide', onKeyboardChange),
      Keyboard.addListener('keyboardDidShow', onKeyboardChange),
    ];
    return () => subscriptions.forEach(subscription => subscription.remove());
  }, []);

  return (
    <>
      <Portal>
        <Dialog
          visible={showModal}
          onDismiss={() => {
            setShowModal(false);
            closeCallback();
          }}
          style={[
            styles.backgroundColorWhite,
            styles.borderRadius,
            styles.alignSelfCenter,
            styles.width,
            fullScreen
              ? [styles.windowHeight, {top: -10}]
              : [styles.maxModalWidth, styles.maxHeight],
            styles.margin0,
            {bottom},
          ]}>
          <ScrollView scrollEnabled={scrollEnabled}>
            <View style={[styles.row, styles.alignCenter, styles.padding]}>
              <Text style={[Typography.H4, styles.flex]}>{title}</Text>
              {closeButton ? (
                <IconButton
                  onPress={() => {
                    setShowModal(false);
                    closeCallback();
                  }}
                  icon={'close'}
                  type={'icon'}
                />
              ) : (
                <></>
              )}
            </View>
            <View
              style={[
                styles.paddingHorizontal,
                styles.paddingBottom,
                fullScreen ? styles.height : {},
              ]}>
              {children}
            </View>
            {footer ? (
              <View
                style={[
                  styles.row,
                  styles.alignCenter,
                  styles.justifyEnd,
                  styles.paddingHorizontal,
                  styles.paddingBottom,
                ]}>
                {footer}
              </View>
            ) : (
              <></>
            )}
          </ScrollView>
        </Dialog>
      </Portal>
      {anchor ? (
        React.cloneElement(anchor, {onPress: () => setShowModal(true)})
      ) : (
        <></>
      )}
    </>
  );
};

export default ReactNativeModal;
