import React, {useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import AntDesign from 'react-native-vector-icons/AntDesign';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, Typography} from 'src/styles';
import {Pressable, Text, TouchableOpacity, View} from 'react-native';
import _ from 'lodash';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {of} from 'rxjs';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Event, Set} from 'src/models';
import ProgramMenu from 'src/design-system/program-menu';
import ProgramInstructionModal from '../program-instruction-modal';
import {Modal} from 'src/design-system';
import ProgramProgress from 'src/modules/programs/components/program-progress-legacy';
import {FrequencyTable} from 'src/modules/session/components';
import FrequencyEdit from './frequency-edit';
import BehaviorABC from 'src/modules/session/components/behavior-abc';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';
import BehaviorProgramTooltip from '../behavior-program-tooltip';

const Frequency = ({program, session, sets, events, programPrompts}: any) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const database = useDatabase();
  const styles = useStyle();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const event = events?.[0] || {prompts: []};
  const currentSet = sets?.[0] || {
    startTimestamp: null,
    endTimestamp: null,
  };
  const [expandABCView, setExpandABCView] = useState(false);
  const [intensity, setIntensity] = useState<string>('mild');
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [editEvents, setEvents] = useState('');
  const [instanceNumber, setInstance] = useState('');
  const translations = useTranslations();

  const prompts = _.groupBy(programPrompts, 'promptType') || [];

  const onValue = async (value: string) => {
    if (
      currentSet === undefined ||
      currentSet?.startTimestamp === null ||
      currentSet?.endTimestamp !== null
    ) {
      await database?.write(async () => {
        const set = await database?.get(Set.table).create(entity => {
          entity.partition = selectedGroup;
          entity.session.id = session.id;
          entity.program.id = program.id;
          entity.startTimestamp = new Date();
          entity.endTimestamp = null;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
        await database.get(Event.table).create(entity => {
          entity.partition = selectedGroup;
          entity.set.id = set.id;
          entity.value = value;
          entity.prompts = [];
          entity.intensity = program?.intensity ? 'mild' : '';
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
        return set;
      });
    } else {
      await database?.write(async () => {
        await database.get(Event.table).create(entity => {
          entity.partition = selectedGroup;
          entity.set.id = currentSet.id;
          entity.value = value;
          entity.prompts = [];
          entity.intensity = program?.intensity ? 'mild' : '';
        });
      });
    }
    setIntensity('mild');
    setExpandABCView(true);
  };

  const checkPrompt = (id: any) => {
    const index = [...event?.prompts].findIndex((v: any) => {
      return v === id;
    });

    if (index === -1) {
      const values = [...event?.prompts];
      values.push(id);
      event.updateEntity({
        prompts: values,
      });
    } else {
      const values = [...event?.prompts];
      values.splice(index, 1);
      event.updateEntity({
        prompts: values,
      });
    }
    forceUpdate();
  };

  const undo = async () => {
    if (currentSet.endTimestamp !== null) {
      currentSet.updateEntity({
        endTimestamp: null,
      });
    } else {
      if (currentSet.startTimestamp !== null) {
        const orderedEvents = await currentSet.orderedEvents.fetch();

        if (orderedEvents.length > 1) {
          const previousEventIntensity =
            orderedEvents[orderedEvents.length - 2]?.intensity;
          setIntensity(previousEventIntensity);
          orderedEvents[0].delete();
        } else {
          setIntensity('mild');

          orderedEvents[0].delete();
          event.delete();
          currentSet.delete();
        }
        setExpandABCView(false);
      }
    }
  };

  const showPrompts = () => {
    if (sets.length > 0) {
      setExpandABCView(!expandABCView);
    }
  };

  const changeIntensity = async (val: string) => {
    setIntensity(val);
    const orderedEvents = await currentSet.orderedEvents.fetch();
    const lastEvent = orderedEvents[0];
    lastEvent.updateEntity({
      intensity: val,
    });
  };

  const viewInstructions = () => {
    setInstructionsModal(!instructionsModal);
  };
  const viewHistory = () => {
    setHistoryModal(!historyModal);
  };
  const viewData = () => {
    setDataModal(!dataModal);
  };

  return (
    <View style={[styles.flex]}>
      <View
        style={[
          styles.borderRadius,
          styles.border,
          styles.borderColorGray,
          styles.marginLBottom,
          styles.backgroundColorGray,
          styles.width300,
        ]}>
        <Pressable onPress={showPrompts}>
          <View style={[styles.paddingVertical, styles.paddingLeft]}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={styles.flex}>
                <View
                  style={[
                    styles.row,
                    styles.alignCenter,
                    styles.marginSBottom,
                  ]}>
                  <BehaviorProgramTooltip name={program?.name} />
                  {sets.length > 0 ? (
                    <MaterialCommunityIcons
                      name={'undo'}
                      size={22}
                      color={Colors.RAVEN_WHITE}
                      onPress={undo}
                      style={styles.marginLLeft}
                    />
                  ) : (
                    <></>
                  )}
                </View>
                <Text style={[Typography.CAPTION, styles.textColorDisabled]}>
                  Frequency
                </Text>
              </View>
              <ProgramMenu
                viewInstructions={viewInstructions}
                viewHistory={viewHistory}
                viewData={viewData}
                color={Colors.RAVEN_WHITE}
              />
            </View>
            <View style={[styles.column, styles.zIndex, styles.marginTop]}>
              <View style={[styles.row, styles.justifyEnd, styles.alignCenter]}>
                {!(sets?.length === 0) ? (
                  <Text
                    style={[
                      Typography.P2_MEDIUM,
                      styles.paddingLHorizontal,
                      styles.textColorWhite,
                    ]}>
                    {events?.length}
                  </Text>
                ) : (
                  <></>
                )}
                <TouchableOpacity
                  style={[styles.alignCenter, styles.marginRight]}
                  onPress={onValue}>
                  <View
                    style={[
                      styles.width40,
                      styles.height40,
                      styles.borderRadius20,
                      styles.alignCenter,
                      styles.justifyCenter,
                      styles.backgroundColorBlack,
                      styles.border,
                      !(sets?.length === 0)
                        ? styles.borderColorBlue
                        : styles.borderColorWhite,
                    ]}>
                    <AntDesign
                      name="plus"
                      size={22}
                      color={Colors.RAVEN_WHITE}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Pressable>
        <BehaviorABC
          program={program}
          expandABCView={expandABCView}
          setExpandABCView={setExpandABCView}
          intensity={intensity}
          changeIntensity={changeIntensity}
          prompts={prompts}
          checkPrompt={checkPrompt}
          currentSet={event}
        />
      </View>
      {instructionsModal ? (
        <ProgramInstructionModal
          show={[instructionsModal, setInstructionsModal]}
          program={program}
        />
      ) : null}
      {historyModal ? (
        <Modal
          show={[historyModal, setHistoryModal]}
          title={'Progress'}
          footer={
            <InformationTooltip
              value={translations('program_progress_month')}
            />
          }>
          <ProgramProgress program={program} />
        </Modal>
      ) : null}
      {dataModal ? (
        <Modal show={[dataModal, setDataModal]} title={`${program.name} Data`}>
          <FrequencyTable
            setEvents={chosenSet => {
              setEvents(chosenSet);
            }}
            setInstance={idx => {
              setInstance(idx);
            }}
            type={'edit-modal'}
            program={program}
            sets={sets}
          />
          {editEvents ? (
            <FrequencyEdit
              setEvents={show => {
                setEvents(show);
              }}
              instance={instanceNumber}
              set={currentSet}
              event={editEvents}
              previousPrompts={editEvents?.prompts}
              prompts={prompts}
              session={session}
              program={program}
            />
          ) : (
            <></>
          )}
        </Modal>
      ) : null}
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({session, program, database}: any) => ({
    sets: database
      .get('sets')
      .query(
        Q.and(
          Q.where('program_id', program.id),
          Q.where('session_id', session.id),
          Q.where('deleted_at', null),
        ),
        Q.sortBy('created_at', Q.desc),
      ),
    programPrompts: program?.prompts,
  })),
  withObservables(['sets'], ({sets}: any) => {
    if (sets.length !== 0) {
      return {
        events: sets?.[0]?.orderedEvents,
      };
    }
    return {
      events: of([]),
    };
  }),
)(Frequency);
