import {children, json, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Remit extends Base {
  static table = 'remits';
  static associations = {
    payers: {type: 'belongs_to', foreignKey: 'payer_id'},
    remit_claim: {type: 'has_many', foreignKey: 'remit_id'},
  };

  @children('remit_claim') remitClaims;

  @text('payer_id') payerId;
  @text('inovalon_id') inovalonId;
  @json('response', value => value) response;
}
