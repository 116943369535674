import React, {useState} from 'react';
import {View, ScrollView} from 'react-native';
import _ from 'lodash';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import {of} from 'rxjs';
import {
  EnvironmentalFactor,
  Event,
  Program,
  Session,
  Set,
  Target,
} from 'src/models';
import {TrialByTrialProgress} from 'src/modules/reports/components';
import {
  DurationTable,
  FrequencyTable,
  IntervalTable,
  RateTable,
  TaskAnalysisTable,
} from 'src/modules/session/components';
import {useStyle} from 'src/providers/style';
import {subtractNDays} from 'src/common-utils/subtractNDays';

interface Props {
  program: Program;
  sets: Set[];
  targets: Target[];
  envs: EnvironmentalFactor[];
  events: Event[];
  programSessions: Session[];
}

const Progress = ({
  program,
  sets,
  targets,
  envs,
  events,
  programSessions = [],
}: Props) => {
  const styles = useStyle();
  const [currentTarget, setCurrentTarget] = useState(targets?.[0]?.id);

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          styles.backgroundColorWhite,
          styles.padding,
          styles.borderRadius,
        ]}>
        <View style={[]}>
          {program?.method === 'task_analysis' ? (
            <TaskAnalysisTable
              program={program}
              sets={sets}
              targets={targets}
            />
          ) : program?.method === 'trial_by_trial' ? (
            <TrialByTrialProgress
              program={program}
              sets={sets}
              recentSets={sets}
              events={events}
              sessions={programSessions}
              targets={targets}
              currentTarget={currentTarget}
              setCurrentTarget={setCurrentTarget}
              envs={envs}
              table={true}
              graph={false}
              showGraphs={true}
            />
          ) : program?.method === 'rate' ? (
            <RateTable program={program} sets={sets} />
          ) : program?.method === 'frequency' ? (
            <FrequencyTable program={program} sets={sets} />
          ) : program?.method === 'duration' ? (
            <DurationTable program={program} sets={sets} />
          ) : program?.method === 'interval' ? (
            <IntervalTable program={program} sets={sets} events={events} />
          ) : (
            <></>
          )}
        </View>
      </ScrollView>
    </>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({database, program}: any) => {
    return {
      targets: program.activeTargets,
      sets: database
        .get('sets')
        .query(
          Q.where('program_id', program.id),
          Q.where('deleted_at', null),
          Q.where('start_timestamp', Q.gt(subtractNDays(5).getTime())),
          Q.sortBy('start_timestamp', Q.asc),
        ),
      patient: program.patient,
    };
  }),
  withObservables(['sets'], ({program, patient, sets, database}: any) => {
    const setIds = _.map(sets, 'id');
    return {
      events: database
        .get('events')
        .query(
          Q.where('set_id', Q.oneOf(setIds)),
          Q.where('deleted_at', null),
          Q.sortBy('created_at', Q.asc),
        ),
      envs: patient?.activeEnvironmentalFactors || of([]),
      programSessions:
        program.type === 'skill' || program.method === 'interval'
          ? database
              .get('sessions')
              .query(
                Q.on(
                  'sets',
                  Q.and(
                    Q.where('program_id', program.id),
                    Q.where('deleted_at', null),
                  ),
                ),
                Q.where('patient_id', patient?.id),
                Q.where('start_timestamp', Q.notEq(null)),
                Q.sortBy('start_timestamp', Q.asc),
                Q.where('deleted_at', null),
              )
          : database
              .get('sessions')
              .query(
                Q.where('start_timestamp', Q.gt(program.createdAt.getTime())),
                Q.where('patient_id', patient?.id),
                Q.where('start_timestamp', Q.notEq(null)),
                Q.sortBy('start_timestamp', Q.asc),
                Q.where('deleted_at', null),
              ),
    };
  }),
)(Progress);
